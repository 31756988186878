import React, { useEffect, useState, useRef, useMemo } from "react";
import { connect } from "react-redux";
import DownloadIcon from "./assets/images/icons/download.svg";
import DownloadWhiteIcon from "./assets/images/icons/download-white.svg";
import EditIcon from "./assets/images/icons/edit-pencil.svg";
import EditWhiteIcon from "./assets/images/icons/edit-pencil-white.svg";
import SummaryWhiteIcon from "./assets/images/icons/summary-white.svg";
import SummaryBlackIcon from "./assets/images/icons/summary-black.svg";
import Slider from "react-slick";

import ApprovalImage from "../component/assets/images/approval.png";
import ExcelImage from "../component/assets/images/xlsx.png";
import DownloadPdfImage from "../component/assets/images/download-pdf.png";
import DownloadXvlImage from "../component/assets/images/download-xvl.png";
import AddPerson from "../component/assets/images/learning/add-person.png";
import ButtonCalculator from "../component/assets/images/learning/learning-new/button calculator.png";
import CalculatorPopup from "../component/assets/images/learning/learning-new/calculator-popup.png";
import Approval from "../component/assets/images/learning/learning-new/approval.png";
import SendApproval from "../component/assets/images/learning/learning-new/send-approval.png";
import AddPersonNew from "../component/assets/images/learning/learning-new/add-person.png";
import CreateQuoteNew from "../component/assets/images/learning/create-quote.png";
import EnterPay from "../component/assets/images/learning/learning-new/enter-pay.png";
import DrugScreen from "../component/assets/images/learning/learning-new/healthcare-plan.png";
import HeathCare from "../component/assets/images/learning/learning-new/drug-screen.png";
import QuoteButton from "../component/assets/images/learning/learning-new/quote-button.png";
import SummaryButton from "../component/assets/images/learning/learning-new/summary-button.png";
import EmployeeBurden from "../component/assets/images/learning/learning-new/burden.png";
import TestApproved from "../component/assets/images/learning/learning-new/test-approved.png";
import AssignmentDetails from "../component/assets/images/learning/learning-new/assignment-details.png";
import * as Constants from "../core/Constants";
import "react-datepicker/dist/react-datepicker.css";

import DatePicker from "react-datepicker";

import { toast } from "react-toastify";
import {
  Button,
  Row,
  Col,
  Container,
  Form,
  Tabs,
  Tab,
  Accordion,
  ListGroup,
  Nav,
  Modal,
  FormControl,
  Table,
  Dropdown,
} from "react-bootstrap";
import Select from "react-select";
import SimpleReactValidator from "simple-react-validator";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import BadgeIcon from "../component/assets/images/icons/badge.svg";
import GraphIcon from "../component/assets/images/icons/graph-icon.svg";
import PersentIcon from "../component/assets/images/icons/persent-icon.svg";
import WalletIcon from "../component/assets/images/icons/calculator-2.svg";
import BagIcon from "../component/assets/images/icons/bag.svg";
import { BiChevronDown } from "react-icons/bi";
import {
  getSettings,
  getOrganizationSettingAttempt,
  getStatesAttempt,
  getCalculation,
  sendCalToApproval,
  createQuote,
  downloadCalFile,
  editCalculation,
} from "./data/globalAction";
import { useHistory, useParams, NavLink } from "react-router-dom";
import {
  abbreviateBigNumber,
  numberWithCommas,
} from "../utils/formattingUtils";
import Payment from "./Payment";
import { MenuItem } from "@material-ui/core";
import { lightGreen } from "@material-ui/core/colors";
import GetSummayModalContent from "./data/summeryEdit";
function nFormatter(num, digits) {
  if (num !== undefined) {
    num = parseFloat(num.replaceAll(",", ""));
    var si = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var i;
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break;
      }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
  }
}
function CalculationDetail(props) {
  const { id } = useParams();
  const [organizationSetting, setOrganizationSetting] = useState();
  const history = useHistory();
  const [login, setLogin] = useState();
  const [states, setStates] = useState();
  const [stateSelect, setStateSelect] = useState([]);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [userData, setUserData] = useState();
  const [forceShow, forceUpdate] = useState();
  const [settings, setSettings] = useState([]);
  const [isPaymentShowing, setIsPaymentShowing] = useState(false);
  const [personList, setPersonList] = useState([]);
  const [status, setStatus] = useState("Not approved");
  const [calculations, setCalculations] = useState();
  const [statesOptions, setStateOptions] = useState();
  const [disabled, setDisabled] = useState(false);
  const [quoteFormData, setQuoteFormData] = useState({});
  const [key, setKey] = useState("project-cost-breakdown");
  const [declineFormData, setDeclineFormData] = useState({
    decline_reason: "",
    reason_choice: "",
  });
  const [declineFormErrors, setDeclineFormErrors] = useState({
    decline_reason: "",
    reason_choice: "",
  });
  const [quotePersonList, setQuotePersonList] = useState({});
  const [selectAllCheck, setSelectAllCheck] = useState(false);
  const [personSelection, setPersonSelection] = useState([0]);
  const [grossProfitPercent, setGrossProfitPercent] = useState(0);
  const [baseGrossProfitPercent, setBaseGrossProfitPercent] = useState(0);
  const [baseHourCalculations, setBaseHourCalculations] = useState([]);
  const [quoteSelectAllCheck, setQuoteSelectAllCheck] = useState(false);
  const [showSendToApprovalModal, setShowSendToApprovalModal] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [demoModalShow, setDemoModalShow] = useState(false);
  const [showFinishedButton, setShowFinishedButton] = useState(false);

const [personsBaseHourCalculations, setPersonsBaseHourCalculations] =
    useState({});


  const [personName, setPersonName] = useState([]);
  const [profitabilityChart, setProfitabilityChart] = useState([]);
  const [totalProfitabilityChartAmount, setTotalProfitabilityChartAmount] = useState({});





  const [filterSelectedOption, setFilterSelectedOption] = useState({
    value: "suta",
    label: "Suta",
  });
  const [revenueFilter, setRevenueFilter] = useState("total");
  const [costbreakdowndata, setCostbreakdownData] = useState({});
  const [suta_hours_taxed_gh, setSutaHoursTaxedGh] = useState([]);
  const [futa_hours_taxed_gh, setFutaHoursTaxedGh] = useState([]);
  const [editPersonList, setEditPersonList] = useState([]);

  // handlepersonchanges
  const handlePersonChanges = (index, category, key, value) => {
    setEditPersonList((prevState) => {
      const newState = [...prevState];
      newState[index][category][key] = value;
      return newState;
    });
    
  };

  const handleCheckboxChange = async (ele, idx, e, key) => {
    ele.preventDefault();
    const value = JSON.stringify(e.id);

    const updatedPersonList = editPersonList.map((personitem, index) => {
      if (index === idx) {
        const costTypesAndIds = { ...personitem.othercost.costTypes_and_ids };
        const interestArray = costTypesAndIds[key] || [];

        // Check if value is already present in the interest array
        const interestIndex = interestArray.indexOf(value);
        if (interestIndex !== -1) {
          // If found, remove it
          interestArray.splice(interestIndex, 1);
        } else {
          // If not found, add it
          interestArray.push(value);
        }

        // Update the interest array in the state
        costTypesAndIds[key] = interestArray;
        return {
          ...personitem,
          othercost: {
            ...personitem.othercost,
            costTypes_and_ids: costTypesAndIds,
          },
        };
      }
      return personitem;
    });

    // Update the state with the updated person list
    setEditPersonList(updatedPersonList);
    setOrganizationSetting((prevState) => ({
      ...prevState,
      organizationSetting,
    }));

    // let data = {
    //   person_list: editPersonList,
    //   name: "Healthcare test",
    //   status: "New calculation",
    // };
    // let result;

    //   data = { ...data, ["id"]: id };
    //   result = await editCalculation(JSON.stringify(data));

    // if (result.status === 200) {
    //   if (
    //     result &&
    //     result.data &&
    //     result.data.calculation &&
    //     result.data.calculation.id
    //   ) {
    //     history.push("/calculations/" + result.data.calculation.id);
    //   }
    // } else {
    //   toast.error(Constants.update_error_message);
    // }
  };

  const [startDate, setStartDate] = useState(new Date());
  const [startDate2, setStartDate2] = useState(new Date());

  const [othercost, setOthercost] = useState({
    costTypes_and_ids: {
      background_check_cost: ["23"],
      custom_cost_list: {},
      drug_screen_cost: [],
      interest: [],
      liability_insurance: [],
      manage_service_provider: [],
      payrolling_cost: [],
      vendor_management_system: [],
    },
    number_of_days: "",
  });

  const [othercosttoggle, setOthercosttoggle] = useState({
    background_check_cost: false,
    custom_cost_list: {},
    drug_screen_cost: false,
    interest: false,
    liability_insurance: false,
    manage_service_provider: false,
    payrolling_cost: false,
    vendor_management_system: false,
    number_of_days: false,
  });

  const [social_security_hours_taxed_gh, setSocialSecurityHoursTaxedGh] =
    useState([]);






  useEffect(() => {
    // Initialize an object to hold data for each category
    const categoryData = {
      name: [],
      Assignment: [],
      Hour: [],
      Day: [],
      Week: [],
      Month: []
    };

    // Iterate over each nurse data object

    personList.forEach((ele, index) => {
      if (personSelection.includes(index) && baseHourCalculations[index]) {

        // Update data for each category

        categoryData.Assignment.push(parseFloat(
          baseHourCalculations[index]?.[0].data?.assignment_total_profit?.replace(
            /,/g,
            ""
          )
        ));
        categoryData.name.push(ele.job_name);
        categoryData.Hour.push(parseFloat(
          baseHourCalculations[index]?.[0]?.data?.assignment_profit_per_hour?.replace(
            /,/g,
            ""
          )
        ));
        categoryData.Day.push(parseFloat(
          baseHourCalculations[index]?.[0]?.data?.assignment_profit_per_day?.replace(
            /,/g,
            ""
          )
        )); // Assuming 7 hours in a workday
        categoryData.Week.push(parseFloat(
          baseHourCalculations[index]?.[0]?.data?.assignment_profit_per_week?.replace(
            /,/g,
            ""
          )
        )); // Total hours in a week for the entire assignment period
        categoryData.Month.push(parseFloat(
          baseHourCalculations[index]?.[0]?.data?.assignment_profit_per_month?.replace(
            /,/g,
            ""
          )
        )); // Total hours in a month assuming 4 weeks
      }
    });

    // Create the new data array based on the aggregated data for each category
    const newData = [
      { name: 'Assignment', data: categoryData.Assignment },
      { name: 'Hour', data: categoryData.Hour },
      { name: 'Day', data: categoryData.Day },
      { name: 'Week', data: categoryData.Week },
      { name: 'Month', data: categoryData.Month },
      { name: 'Name', data: categoryData.name }

    ];


    // Set the newData array to the state variable
    setProfitabilityChart(newData);
  }, [personList, personSelection]);





  const ProfitablityValue = {
    chart: {
      type: 'bar'
    },
    title: {
      text: 'Assignment Profitability',
      align: 'center'
    },
    subtitle: {
      text: '',
      align: 'left'
    },
    xAxis: {
      categories: profitabilityChart?.[5]?.data,
      title: {
        text: null
      },
      gridLineWidth: 1,
      lineWidth: 0
    },
    yAxis: {
      min: 0,
      title: {
        text: "Hour's",
        align: 'high'
      },
      labels: {
        overflow: 'justify'
      },
      gridLineWidth: 0
    },
    tooltip: {
      valueSuffix: '  '
    },
    plotOptions: {
      bar: {
        borderRadius: '1%',
        dataLabels: {
          enabled: true
        },
        groupPadding: 0.1
      }
    },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'top',
      x: 0,
      y: 0,
      floating: true,
      borderWidth: 1,
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
      shadow: true
    },
    credits: {
      enabled: false
    },
    series: [{
      name: "Profit Per Hour",
      data: profitabilityChart?.[1]?.data
    }, {
      name: 'Profit Per Day',
      data: profitabilityChart?.[2]?.data
    }, {
      name: 'Profit Per Week',
      data: profitabilityChart?.[3]?.data
    }, {
      name: 'Profit Per Month',
      data: profitabilityChart?.[4]?.data
    },
    {
      name: 'Profit Total',
      data: profitabilityChart?.[0]?.data
    },]
  };

  // const handleModalClose = () => setShow(false);
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        Next
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        Back
      </div>
    );
  }

  const surveySettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    afterChange: function (currentSlide) {
      if (currentSlide == 5) {
        setShowFinishedButton(true);
      } else {
        setShowFinishedButton(false);
      }
    },
  };

  const costbreakdown_option = [
    { value: "suta", label: "Suta" },
    { value: "futa", label: "Futa" },
    { value: "social_security", label: "Social Security" },
  ];
  const handleSelect = async (info) => {
    setFilterSelectedOption(info);
    const newValue = info.value;
    setRevenueFilter(newValue);
  };

  const customTableStyles = {
    header: {
      style: {
        padding: "0",
        minHeight: "inherit",
      },
    },
    headRow: {
      style: {
        color: "#43536D",
        borderColor: "#E5E8F5",
        borderStyle: "solid",
        borderWidth: "1px",
        borderLeftWidth: "0",
        borderRightWidth: "0",
        backgroundColor: "#FCFCFF",
      },
    },
    headCells: {
      style: {
        fontSize: "13px",
        fontWeight: "normal",
        color: "#767778",
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: "#fff",
        },
      },
    },
    rows: {
      style: {
        minHeight: "65px",
        cursor: "pointer",
        "&:not(:last-of-type)": {
          borderBottomStyle: "solid",
          borderBottomWidth: "1px",
          borderBottomColor: "#E5E8F5",
        },
      },
    },
    cells: {
      style: {
        minHeight: "65px",
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: "#fff",
        },
      },
    },
    pagination: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: "#E5E8F5",
      },
    },
  };


  useEffect(() => {
    if (profitabilityChart) {
      const totals = profitabilityChart.map(series => {
        const total = series.data.reduce((acc, curr) => acc + curr, 0);
        return { name: series.name, total };
      });
      setTotalProfitabilityChartAmount(totals);
    }
  }, [profitabilityChart]);


  const handleClose = () => {
    setDemoModalShow(false);
    setShowFinishedButton(false);
    localStorage.setItem("calculationDetailDemo", false);
  };
  const handleSendToApprovalModal = () =>
    setShowSendToApprovalModal(!showSendToApprovalModal);
  const handleDeclineModal = () => setShowDeclineModal(!showDeclineModal);
  const handleSummaryModal = () => setShowSummaryModal(!showSummaryModal);
  const handleEditModal = () => setShowEditModal(!showEditModal);


  const handleDownloadModal = () => {
    setDisabled(!disabled);
    setShowDownloadModal(!showDownloadModal);
  };
  const [totalAssignmentCalculations, setTotalAssignmentCalculations] =
    useState([]);
  

  useEffect(() => {

    let names = personList?.map((e) => e?.job_name)
    // setPersonName()
  }, [personList])

  const [
    personTotalAssignmentCalcualtions,
    setPersonTotalAssignmentCalculations,
  ] = useState({});

  const NumberFormat = (number) => {
    let nf = new Intl.NumberFormat("en-US");
    return nf.format(number);
    // return number.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  };

  const customGraphControlStyles = {
    control: (base, state) => ({
      ...base,
      minHeight: 40,
      marginBottom: "15px",
      background: "#fff",
      borderRadius: "8px",
      borderWidth: 1,
      borderColor: state.isFocused ? "#bbc0d3" : "#E5E8F5",
      boxShadow: "none",
      fontSize: "13px",
      "&:hover": {
        borderColor: state.isFocused ? null : null,
      },
    }),
    dropdownIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    menu: (base) => ({
      ...base,
      fontSize: "13px",
      border: "1px solid #f1f3f6",
      boxShadow: "none",
    }),
    option: (base, state) => ({
      ...base,
      background: state.isSelected ? "#f1f3f6" : "#fff",
      color: "#151B26",
      "&:hover": {
        background: "#f1f3f6",
        color: "#151B26",
      },
    }),
    clearIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
  };

  const chartRef = useRef(null);

  const options = {
    chart: {
      height: 400,
      renderTo: "container",
      type: "spline",
    },
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      type: "datetime",
      month: "%e. %b",
      year: "%b",
      tickWidth: 0,
      lineWidth: 0,
      crosshair: {
        width: 1,
        color: "#43536D",
        dashStyle: "shortdot",
      },
      labels: {
        style: {
          color: "#43536D",
          textTransform: "uppercase",
          fontWeight: "300",
          fontFamily: "Formular",
        },
      },
    },
    yAxis: {
      gridLineColor: "#E5E8F5",
      gridLineDashStyle: "longdash",
      title: {
        text: "",
      },
      labels: {
        style: {
          color: "#43536D",
          textTransform: "uppercase",
          fontWeight: "300",
          fontFamily: "Formular",
        },
      },
    },
    tooltip: {
      shadow: false,
      backgroundColor: "none",
      borderColor: "none",
      padding: 0,
      borderRadius: "8px",
      className: "chart-tooltip",
      useHTML: true,
      formatter: function () {
        if (this.total) {
          return (
            "<div class='custom-graph-tooltip'><div class='px-3 py-2 d-flex'><div><span>Revenue</span>$" +
            NumberFormat(this.y.toFixed(2)) +
            "</div></div><div class='px-3 py-2 d-flex align-items-end'><div><span>Gross Profit</span>$" +
            NumberFormat(this.total.toFixed(2)) +
            "</div><small class='percent-up ml-3'>" +
            NumberFormat(((this.total / this.y) * 100).toFixed(2)) +
            " %</small></div></div>"
          );
        }
      },
    },
    noData: {
      attr: null,
      position: { x: 0, y: 0, align: "center", verticalAlign: "middle" },
      style: { fontSize: "12px", fontWeight: "bold", color: "#60606a" },
    },
    plotOptions: {
      series: {
        marker: {
          fillColor: "#1e8f38",
          shadow: false,
          lineWidth: 2,
          width: 30,
          height: 30,
          lineColor: "#fff",
          enabled: false,
        },
      },
    },
    series: [
      {
        showInLegend: false,
        type: "area",
        lineWidth: 2,
        lineColor: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, "#1E8F38"], // start
            [0.5, "#1E8F38"], // middle
            [1, "#1E8F38"], // end
          ],
        },
        fillColor: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, "rgba(30, 142, 56, 0.2)"], // start
            [0.5, "rgba(237, 250, 240, 0.03)"], // middle
            [1, "rgba(255, 255, 255, 0.01)"], // end
          ],
        },
        data: [
          {
            x: Date.UTC(2023, 0, 1),
            y: 300000,
            total: 30000,
          },
          {
            x: Date.UTC(2023, 1, 1),
            y: 350000,
            total: 35000,
          },
          {
            x: Date.UTC(2023, 2, 1),
            y: 300000,
            total: 30000,
          },
          {
            x: Date.UTC(2023, 3, 1),
            y: 450000,
            total: 45000,
          },
          {
            x: Date.UTC(2023, 4, 1),
            y: 320000,
            total: 32000,
          },
          {
            x: Date.UTC(2023, 5, 1),
            y: 440000,
            total: 44000,
          },
          {
            x: Date.UTC(2023, 6, 1),
            y: 380000,
            total: 38000,
          },
          {
            x: Date.UTC(2023, 7, 1),
            y: 480000,
            total: 48000,
          },
          {
            x: Date.UTC(2023, 8, 1),
            y: 310000,
            total: 31000,
          },
          {
            x: Date.UTC(2023, 9, 1),
            y: 400000,
            total: 40000,
          },
          {
            x: Date.UTC(2023, 10, 1),
            y: 310000,
            total: 31000,
          },
          {
            x: Date.UTC(2023, 11, 1),
            y: 290000,
            total: 29000,
          },
        ],
      },
    ],
  };

  const stackPercentage= {
    chart: {
        type: 'column'
    },
    title: {
      text: "",
    },
    xAxis: {
        categories: ['Suta', 'Futa', 'Social security']
    },
    yAxis: {
        min: 0,
        title: {
            text: 'Percent'
        }
    },
    tooltip: {
        pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
        shared: true
    },
    plotOptions: {
        column: {
            stacking: 'percent',
            dataLabels: {
                enabled: true,
                format: '{point.percentage:.0f}%'
            }
        }
    },
    series: [{
        name: 'Total Hours Tax',
        data: [
        (parseInt(personsBaseHourCalculations?.suta_hours_taxed?.replace(/,/g, "")) / parseInt(personsBaseHourCalculations?.total_earnings?.replace(/,/g, "")))*100,
        (parseInt(personsBaseHourCalculations?.futa_hours_taxed?.replace(/,/g, "")) / parseInt(personsBaseHourCalculations?.total_earnings?.replace(/,/g, "")))*100,
        (parseInt(personsBaseHourCalculations?.social_security_hours_taxed?.replace(/,/g, "")) / parseInt(personsBaseHourCalculations?.total_earnings?.replace(/,/g, "")))*100
        ]
    }, {
        name: 'Total Post Tax',
        data: [
        (parseInt(personsBaseHourCalculations?.suta_post_hours_taxed?.replace(/,/g, "")) / parseInt(personsBaseHourCalculations?.total_earnings?.replace(/,/g, "")))*100, 
        (parseInt(personsBaseHourCalculations?.futa_post_hours_taxed?.replace(/,/g, "")) / parseInt(personsBaseHourCalculations?.total_earnings?.replace(/,/g, "")))*100,
        (parseInt(personsBaseHourCalculations?.social_security_post_hours_taxed?.replace(/,/g, "")) / parseInt(personsBaseHourCalculations?.total_earnings?.replace(/,/g, "")))*100
      ]
    } ]
}


  const stackedOption = {
    chart: {
      type: "column",
      height: 350,
    },
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: personsBaseHourCalculations.categories?.split(","),
      tickWidth: 0,
      lineWidth: 0,
      crosshair: {
        width: 0,
        color: "#fff",
        dashStyle: "shortdot",
      },
      labels: {
        style: {
          color: "#43536D",
          textTransform: "uppercase",
          fontWeight: "300",
          fontFamily: "Formular",
        },
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "",
      },
      stackLabels: {
        enabled: false,
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      shadow: false,
      backgroundColor: "none",
      borderColor: "none",
      padding: 0,
      borderRadius: "8px",
      className: "chart-tooltip",
      useHTML: true,
      formatter: function () {


        if (this.point.customdata) {
          return (
            "<div class='custom-graph-tooltip stacked-tooltip'><div class='font-weight-normal p-2 top-title'>" +
            this.x +
            " week</div><div class='px-3 py-2 d-flex'><div><span>SUTA</span>$" +
            NumberFormat(this.point.customdata.suta.toFixed(2)) +
            "</div></div><div class='px-3 py-2 d-flex align-items-end'><div><span>FUTA</span>$" +
            NumberFormat(this.point.customdata.futa.toFixed(2)) +
            "</div></div><div class='px-3 py-2 d-flex align-items-end'><div><span>Social Security</span>$" +
            NumberFormat(this.point.customdata.social_security.toFixed(2)) +
            "</div></div>"
          );
        }
      },
    },
    noData: {
      attr: null,
      position: { x: 0, y: 0, align: "center", verticalAlign: "middle" },
      style: { fontSize: "12px", fontWeight: "bold", color: "#60606a" },
    },
    plotOptions: {
      column: {
        stacking: "normal",
        borderRadius: 8,
        pointWidth: 13,
        pointPadding: 0.6,
      },
    },
    colors: ["#4BC0C0", "#FF4E2F", "#80B3FF"],
    series: [
      {
        name: "SUTA",
        data: suta_hours_taxed_gh,
      },
      {
        name: "FUTA",
        data: futa_hours_taxed_gh,
      },
      {
        name: "Social Security",
        data: social_security_hours_taxed_gh,
      },
    ],
  };




  const customColors = [
    "#FFBAFF",
    "#3E1AAA",
    "#000547",
    "#3E1AAA",
    "#0036CD",
    "#795CFF",
    "#24CFE2",
  ];

  const statutoryChartOpt = {
    chart: {
      type: "pie",
      height: "300px",
    },
    title: {
      text: null,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        innerSize: "70%",
        allowPointSelect: true,
        borderWidth: 0,
        dataLabels: {
          enabled: false,
          format: "<b>{point.name}</b>: {point.percentage:.1f} %",
        },
      },
      series: {
        colors: customColors,
        point: {
          events: {
            mouseOver: function () {
              // Highlight the corresponding list item when hovering over the chart
              const listItemIndex = categoriesWithData.findIndex(
                (item) => item.category === this.name
              );
              highlightListItem(listItemIndex, true);
            },
            mouseOut: function () {
              // Remove highlight when leaving the chart
              const listItemIndex = categoriesWithData.findIndex(
                (item) => item.category === this.name
              );
              highlightListItem(listItemIndex, false);
            },
          },
        },
      },
    },
    tooltip: {
      shadow: false,
      backgroundColor: "none",
      borderColor: "none",
      padding: 0,
      borderRadius: "8px",
      className: "chart-tooltip",
      useHTML: true,
      formatter: function () {
        return (
          "<div class='custom-graph-tooltip'><div class='px-3 py-2 d-flex'><div><span>Total wages</span>$" +
          personTotalAssignmentCalcualtions.total_wages.toLocaleString() +
          "</div></div><div class='px-3 py-2 d-flex align-items-end'><div><span>" +
          this.point.name +
          "</span>$" +
          this.point.y.toLocaleString() +
          "</div><small class='percent-up ml-3'>" +
          Math.round(this.point.percentage) +
          " %</small></div></div>"
        );
      },
    },
    series: [
      {
        name: "Cost",
        data: [
          [
            "Social Security",
            parseFloat(
              personTotalAssignmentCalcualtions.social_security?.replace(
                /,/g,
                ""
              )
            ),
          ],
          [
            "Medicare",
            parseFloat(
              personTotalAssignmentCalcualtions.medicare?.replace(/,/g, "")
            ),
          ],
          [
            "FUTA",
            parseFloat(
              personTotalAssignmentCalcualtions.futa?.replace(/,/g, "")
            ),
          ],
          [
            "SUTA",
            parseFloat(
              personTotalAssignmentCalcualtions.suta?.replace(/,/g, "")
            ),
          ],
          [
            "Risk",
            parseFloat(
              personTotalAssignmentCalcualtions.risk?.replace(/,/g, "")
            ),
          ],
          [
            "Benefits",
            parseFloat(
              personTotalAssignmentCalcualtions.benefits?.replace(/,/g, "")
            ),
          ],
          [
            "Other Costs",
            parseFloat(
              personTotalAssignmentCalcualtions.other_costs?.replace(/,/g, "")
            ),
          ],
        ],
      },
    ],
  };

  const breakdownChartOpt = {
    chart: {
      type: "pie",
      height: "300px",
    },
    title: {
      text: null,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        innerSize: "70%",
        allowPointSelect: true,
        borderWidth: 0,
        dataLabels: {
          enabled: false,
          format: "<b>{point.name}</b>: {point.percentage:.1f} %",
        },
      },
      series: {
        colors: customColors,
        point: {
          events: {
            mouseOver: function () {
              // Highlight the corresponding list item when hovering over the chart
              const listItemIndex = categoriesBreakdownWithData.findIndex(
                (item) => item.category === this.name
              );
              highlightBreakdownListItem(listItemIndex, true);
            },
            mouseOut: function () {
              // Remove highlight when leaving the chart
              const listItemIndex = categoriesBreakdownWithData.findIndex(
                (item) => item.category === this.name
              );
              highlightBreakdownListItem(listItemIndex, false);
            },
          },
        },
      },
    },
    tooltip: {
      shadow: false,
      backgroundColor: "none",
      borderColor: "none",
      padding: 0,
      borderRadius: "8px",
      className: "chart-tooltip",
      useHTML: true,
      formatter: function () {
        return (
          "<div class='custom-graph-tooltip'><div class='px-3 py-2 d-flex'><div><span>Revenue</span>$" +
          personTotalAssignmentCalcualtions.total_revenue.toLocaleString() +
          "</div></div><div class='px-3 py-2 d-flex align-items-end'><div><span>" +
          this.point.name +
          "</span>$" +
          this.point.y.toLocaleString() +
          "</div><small class='percent-up ml-3'>" +
          Math.round(this.point.percentage) +
          " %</small></div></div>"
        );
      },
    },
    series: [
      {
        name: "Cost",
        data: [
          [
            "Total wages",
            parseFloat(
              personTotalAssignmentCalcualtions.total_wages?.replace(/,/g, "")
            ),
          ],
          [
            "Total Employers Burden",
            parseFloat(
              personTotalAssignmentCalcualtions.total_employers_burden?.replace(
                /,/g,
                ""
              )
            ),
          ],
          [
            "Gross Profit",
            parseFloat(
              personTotalAssignmentCalcualtions["gross_profit"]?.replace(
                /,/g,
                ""
              )
            ),
          ],
        ],
      },
    ],
  };

  const categoriesWithData = statutoryChartOpt.series[0].data.map(
    ([category, value], index) => ({
      category,
      value,
      color: customColors[index % customColors.length],
    })
  );

  const categoriesBreakdownWithData = breakdownChartOpt.series[0].data.map(
    ([category, value], index) => ({
      category,
      value,
      color: customColors[index % customColors.length],
    })
  );

  const highlightListItem = (index, highlight) => {
    const listItem = document.getElementById(`listItem-${index}`);
    if (listItem) {
      listItem.style.fontWeight = highlight ? "bold" : "normal";
    }
  };
  const highlightBreakdownListItem = (index, highlight) => {
    const listItem = document.getElementById(`b-listItem-${index}`);
    if (listItem) {
      listItem.style.fontWeight = highlight ? "bold" : "normal";
    }
  };

  const changeError = (message, className) => {
    if (message.includes("company_name") || message.includes("quote_number"))
      return <div className="text-danger">{message.replace(/[0-9]/, "")}</div>;
    else return <div className="text-danger">{message}</div>;
  };
  const [validator] = useState(
    new SimpleReactValidator(
      new SimpleReactValidator({
        element: (message, className) => changeError(message, className),
      })
    )
  );

  const [showModalFour, setShowModalFour] = useState(false);
  const handleShowFour = () => setShowModalFour(true);
  const handleCloseFour = () => setShowModalFour(false);
  const handleClosePayment = () => {
    localStorage.removeItem("expire");
    setIsPaymentShowing(false);
  };

  const defaultFunctions = () => {
    async function fetchSettings() {
      setSettings(await getSettings());
    }
    fetchSettings();

    async function getCal() {
      let data = {
        id: id,
      };
      let response = await getCalculation(JSON.stringify(data));
      setCalculations(response);
      setEditPersonList(response.data.calculation.person_list);
    }
    getCal();

    async function getStates() {
      setStates(await getStatesAttempt(Constants.default_county.value));
    }
    getStates();
  };

  useEffect(() => {
    document.querySelector("html").classList.add("calculation-details-page");
    document.querySelector("html").classList.remove("homepage");
    if (localStorage.getItem("calculationDetailDemo") === "true") {
      setTimeout(() => {
        setDemoModalShow(true);
      }, 500);
    }
  }, []);

  useEffect(() => {
    if (settings.expire && settings.expire === true) {
      setIsPaymentShowing(true);
    }
  }, [settings]);

  useEffect(() => {
    if (Object.keys(props.login).length > 0) {
      setLogin(props.login.data);
    }
  }, [props.login]);

  useEffect(() => {
    async function getOrganizationSetting() {
      if (
        settings.data &&
        settings.data.organization_users &&
        settings.data.organization_users.length !== 0 &&
        settings.data.organization_users[0].organization
      ) {
        let organization = settings.data.organization_users[0].organization;
        let data = {
          organization: organization.id,
        };
        let result = await getOrganizationSettingAttempt(JSON.stringify(data));
        if (result.status === 200) {
          setOrganizationSetting(result.data);
        }
      }
    }
    getOrganizationSetting();
  }, [settings]);

  useEffect(() => {
    defaultFunctions();
  }, [id]);

  useEffect(() => {
    if (states != null && personList.length > 0) {
      let stateOptionsCopy = [];
      let uniqueStateIds = [];
      personList.forEach((p) => {
        p.wc_states.forEach((s) => {
          if (!uniqueStateIds.includes(s.state_id)) {
            uniqueStateIds.push(s.state_id);
          }
        });
      });
      uniqueStateIds.forEach((s) => {
        let d = states.filter((item) => {
          return item.id === s;
        });
        stateOptionsCopy.push({ label: d[0].name, value: d[0].id });
      });
      setStateOptions(stateOptionsCopy);
    }
  }, [states, personList]);

  useEffect(() => {
    if (calculations) {
      if (calculations.status === 200) {
        let data = calculations.data;
        setPersonList([...data.calculation.person_list]);
        setQuotePersonList({
          0: { count: 1, data: { ...data.calculation.person_list[0] } },
        });
        setBaseHourCalculations(data.calculation.base_hour_calculation);
        setTotalAssignmentCalculations(
          data.calculation.total_assignment_calculation
        );
        setStatus(data.calculation.status);
        setUserData({ ...data.calculation.user });
      }
    }
  }, [calculations]);

  useEffect(() => {
    let personsBaseHourCalculationsCopy = {
      additional_benefits: 0,
      background_check: 0,
      benefits: 0,
      bonus: 0,
      bonus_tax: 0,
      custom_cost: 0,
      discount: 0,
      drug_screening: 0,
      employer_taxes: 0,
      federal_taxes: 0,
      financing: 0,
      futa: 0,
      gross_profit: 0,
      healthcare: 0,
      holidays: 0,
      hours_per_shift: 0,
      insurance: 0,
      interest: 0,
      k_401: 0,
      lodging: 0,
      m_i: 0,
      mandatory_benefits: 0,
      medicare: 0,
      msp: 0,
      non_taxable_wages: 0,
      number_of_shifts: 0,
      other_costs: 0,
      other_insurance: 0,
      othercost_items: [],
      overtime_pay: 0,
      payrolling_cost: 0,
      pto_vacation_sick: 0,
      regular_pay: 0,
      revenue: 0,
      overtime_revenue: 0,
      total_revenue: 0,
      risk: 0,
      screening: 0,
      social_security: 0,
      social_security_rate: 0,
      social_security_wage_rate: 0,

      social_security_hours_taxed: 0,
      futa_hours_taxed: 0,
      suta_hours_taxed: 0,
      social_security_post_hours_taxed: 0,
      futa_post_hours_taxed: 0,
      suta_post_hours_taxed: 0,
      total_earnings: 0,
      categories: [],
      social_security_inital_rate: 0,
      social_security_diff: 0,
      medicare_rate: 0,
      medicare_wage_rate: 0,
      medicare_inital_rate: 0,
      medicare_diff: 0,
      futa_rate: 0,
      futa_wage_rate: 0,
      futa_inital_rate: 0,
      futa_diff: 0,
      suta_rate: 0,
      suta_wage_rate: 0,
      suta_inital_rate: 0,
      suta_diff: 0,
      state_taxes: 0,
      statutory_pto: 0,
      stipend_per_hour: 0,
      stipend_per_shift: 0,
      stipend_total: 0,
      stipends: 0,
      suta: 0,
      taxable_wages: 0,
      total_assignment: 0,
      total_daily: 0,
      total_employers_burden: 0,
      total_numbers_of_hours: 0,
      total_project_cost: 0,
      total_wages: 0,
      vms: 0,
      weekly: 0,
      weeks: 0,
      workers_comp: 0,
      assignment_profit_per_hour: 0,
      assignment_profit_per_day: 0,
      assignment_profit_per_week: 0,
      assignment_profit_per_month: 0,
      assignment_total_profit: 0,
    };
    let personTotalAssignmentCalcualtionsCopy = {
      additional_benefits: 0,
      background_check: 0,
      benefits: 0,
      bonus: 0,
      bonus_tax: 0,
      custom_cost: 0,
      drug_screening: 0,
      employer_taxes: 0,
      federal_taxes: 0,
      financing: 0,
      futa: 0,
      gross_profit: 0,
      healthcare: 0,
      holidays: 0,
      insurance: 0,
      interest: 0,
      k_401: 0,
      mandatory_benefits: 0,
      medicare: 0,
      msp: 0,
      non_taxable_wages: 0,
      other_costs: 0,
      other_insurance: 0,
      othercost_items: [],
      overtime_pay: 0,
      payrolling_cost: 0,
      pto_vacation_sick: 0,
      regular_pay: 0,
      revenue: 0,
      overtime_revenue: 0,
      total_revenue: 0,
      risk: 0,
      screening: 0,
      social_security: 0,
      social_security_rate: 0,
      social_security_wage_rate: 0,
      social_security_inital_rate: 0,
      social_security_diff: 0,
      medicare_rate: 0,
      medicare_wage_rate: 0,
      medicare_inital_rate: 0,
      medicare_diff: 0,
      futa_rate: 0,
      futa_wage_rate: 0,
      futa_inital_rate: 0,
      futa_diff: 0,
      suta_rate: 0,
      suta_wage_rate: 0,
      suta_inital_rate: 0,
      suta_diff: 0,
      state_taxes: 0,
      statutory_pto: 0,
      stipends: 0,
      suta: 0,
      taxable_wages: 0,
      total_employers_burden: 0,
      total_project_cost: 0,
      total_wages: 0,
      vms: 0,
      workers_comp: 0,
      assignment_profit_per_hour: 0,
      assignment_profit_per_day: 0,
      assignment_profit_per_week: 0,
      assignment_profit_per_month: 0,
      assignment_total_profit_: 0,
    };
    let t = [];

    let t2 = [];

    personSelection.forEach((i, idx) => {
      if (baseHourCalculations.length > 0) {
        baseHourCalculations[i].forEach((pBHour) => {
          if (
            stateSelect.includes(pBHour.state_id) ||
            stateSelect.length == 0
          ) {
            Object.keys(pBHour.data).forEach((key) => {
              if (key === "categories") {
                personsBaseHourCalculationsCopy[key] = pBHour.data[key]?.split(',');
              }
              else if (key === "social_security_rate" || key === "social_security_wage_rate" || key === "medicare_rate" || key === "medicare_wage_rate"
                || key === "futa_rate" || key === "futa_wage_rate" || key === "suta_rate" || key === "suta_wage_rate") {
                personsBaseHourCalculationsCopy[key] = pBHour.data[key]
              }
              else if (key === "suta_hours_taxed_gh") {
                let temp = []
                JSON.parse(pBHour.data[key]).forEach((item, index) => {
                  temp.push(item);
                });
                setSutaHoursTaxedGh(temp);
              } else if (key === "futa_hours_taxed_gh") {
                let temp = [];
                JSON.parse(pBHour.data[key]).forEach((item, index) => {
                  temp.push(item);
                });
                setFutaHoursTaxedGh(temp);
              } else if (key === "social_security_hours_taxed_gh") {
                let temp = [];
                JSON.parse(pBHour.data[key]).forEach((item, index) => {
                  temp.push(item);
                });
                setSocialSecurityHoursTaxedGh(temp);
              } else if (
                key === "othercost_items" ||
                key === "custom_cost_info"
              ) {
                try {
                  pBHour.data[key] = JSON.parse(pBHour.data[key]);
                } catch { }

                pBHour.data[key].forEach((item, index) => {
                  if (t.length > 0) {
                    let check = false;
                    t.forEach((item2, index2) => {
                      if (item2.name === item.name) {
                        let value =
                          item2.value +
                          parseFloat(item?.value?.replaceAll(",", ""));
                        value = Math.round(value * 100) / 100;
                        t[index2] = { ...item2, ["value"]: value };
                        check = true;
                      }
                    });
                    if (!check) {
                      item = {
                        ...item,
                        ["value"]: parseFloat(item?.value?.replaceAll(",", "")),
                      };
                      t.push(item);
                    }
                  } else {
                    item = {
                      ...item,
                      ["value"]: parseFloat(item?.value?.replaceAll(",", "")),
                    };
                    t.push(item);
                  }
                });

                personsBaseHourCalculationsCopy[key] = t;
              } else {
                personsBaseHourCalculationsCopy[key] =
                  personsBaseHourCalculationsCopy[key] +
                  parseFloat(pBHour?.data[key]?.replaceAll(",", ""));
                personsBaseHourCalculationsCopy[key] =
                  Math.round(personsBaseHourCalculationsCopy[key] * 100) / 100;
                t = [];
              }
            });
          }
        });
      }
      if (totalAssignmentCalculations.length > 0) {
        totalAssignmentCalculations[i].forEach((pTHour) => {
          if (
            stateSelect.includes(pTHour.state_id) ||
            stateSelect.length == 0
          ) {
            Object.keys(pTHour.data).forEach((key) => {
              if (key === "othercost_items" || key === "custom_cost_info") {
                try {
                  pTHour.data[key] = JSON.parse(pTHour.data[key]);
                } catch { }
                pTHour.data[key].forEach((item, index) => {
                  if (t2.length > 0) {
                    let check = false;
                    t2.forEach((item2, index2) => {
                      if (item2.name === item.name) {
                        let value =
                          item2.value +
                          parseFloat(item.value.replaceAll(",", ""));
                        value = Math.round(value * 100) / 100;
                        t2 = { ...item2, ["value"]: value };
                        check = true;
                      }
                    });
                    if (!check) {
                      item = {
                        ...item,
                        ["value"]: parseFloat(item.value.replaceAll(",", "")),
                      };
                      t2.push(item);
                    }
                  } else {
                    item = {
                      ...item,
                      ["value"]: parseFloat(item.value.replaceAll(",", "")),
                    };
                    t2.push(item);
                  }
                });
                personTotalAssignmentCalcualtionsCopy[key] = t2;
              } else {
                personTotalAssignmentCalcualtionsCopy[key] =
                  personTotalAssignmentCalcualtionsCopy[key] +
                  parseFloat(pTHour.data[key].replaceAll(",", ""));
                personTotalAssignmentCalcualtionsCopy[key] =
                  Math.round(personTotalAssignmentCalcualtionsCopy[key] * 100) /
                  100;
                t2 = [];
              }
            });
          }
        });
      }
    });
    let formattedData = formatNumbers(
      personsBaseHourCalculationsCopy,
      personTotalAssignmentCalcualtionsCopy
    );
    setPersonsBaseHourCalculations({ ...formattedData[0] });
    setPersonTotalAssignmentCalculations({
      ...formattedData[1],
    });
  }, [
    personSelection,
    baseHourCalculations,
    totalAssignmentCalculations,
    stateSelect,
    editPersonList
  ]);

  useEffect(() => {
    if (Object.keys(personTotalAssignmentCalcualtions).length > 0) {
      let data = personTotalAssignmentCalcualtions;
      let result = 0;
      if (data.revenue != 0) {
        result =
          (parseFloat(data.gross_profit.replaceAll(",", "")) /
            parseFloat(data.revenue.replaceAll(",", ""))) *
          100;
      }
      setGrossProfitPercent(Math.round(result * 100) / 100);
    }
  }, [personTotalAssignmentCalcualtions]);

  useEffect(() => {
    if (Object.keys(personsBaseHourCalculations).length > 0) {
      let data = personsBaseHourCalculations;
      let result = 0;
      if (data.revenue != 0) {
        result =
          (parseFloat(data.gross_profit.replaceAll(",", "")) /
            parseFloat(data.revenue.replaceAll(",", ""))) *
          100;
      }
      setBaseGrossProfitPercent(Math.round(result * 100) / 100);
    }
  }, [personsBaseHourCalculations]);

  const handleStateSelect = (e) => {
    let ids = e.map((s) => {
      return s.value;
    });
    setStateSelect([...ids]);
  };

  const formatNumbers = (bh, th) => {
    let PBHCal = bh;
    Object.keys(bh).forEach((k, i) => {
      if (k === "othercost_items" || k === "custom_cost_info") {
        let temp = [];
        bh[k].forEach((item) => {
          let r = abbreviateBigNumber(item.value);
          temp.push({ ...item, ["value"]: r[0] + r[1] });
        });
        PBHCal[k] = temp;
      } else {
        let abbrevData = abbreviateBigNumber(bh[k]);
        PBHCal[k] = numberWithCommas(abbrevData[0]) + abbrevData[1];
      }
    });
    let PTHCal = th;
    Object.keys(th).forEach((k, i) => {
      if (k === "othercost_items" || k === "custom_cost_info") {
        let temp = [];
        th[k].forEach((item) => {
          let r = abbreviateBigNumber(item.value);
          temp.push({ ...item, ["value"]: r[0] + r[1] });
        });
        PTHCal[k] = temp;
      } else {
        let abbrevData = abbreviateBigNumber(th[k]);
        PTHCal[k] = numberWithCommas(abbrevData[0]) + abbrevData[1];
      }
    });
    return [PBHCal, PTHCal];
  };

  const handlePersonSelection = (e, index) => {
    setSelectAllCheck(false);
    let personSelectionCopy = personSelection;
    if (personSelection.includes(index)) {
      if (personSelection.length === 1) {
      } else {
        personSelectionCopy = personSelection.filter((value, idx) => {
          return value !== index;
        });
        setPersonSelection([...personSelectionCopy]);
      }
    } else {
      personSelectionCopy.push(index);
      setPersonSelection([...personSelectionCopy]);
    }
  };

  const handleSelectAll = () => {
    let personSelectionCopy = [];
    personList.forEach((person, i) => {
      personSelectionCopy.push(i);
    });
    setSelectAllCheck(true);
    setPersonSelection([...personSelectionCopy]);
  };

  // const handleEditClick = () => {
  //   // window.open("/calculations/edit/" + id, "_blank").focus();
  //   history.push(`edit/${id}`);
  // };

  const healthCarePlan = (health_plans) => {
    return (
      <ul>
        {" "}
        {health_plans.map((plan) => (
          <li>
            {" "}
            {organizationSetting.packages.find(
              (value) => value.id === plan.package
            ).package_name +
              " (" +
              plan.package_type.replaceAll("_", " ") +
              ")"}{" "}
          </li>
        ))}{" "}
      </ul>
    );
  };



  const getSummayModalContent = () => {
    return (
      <Modal
        animation={false}
        className="summary-modal"
        size="lg"
        centered
        show={showSummaryModal}
        onHide={handleSummaryModal}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="summary-body custom-tabs mt-1">
            {calculations
              && calculations.data
              && calculations.data.calculation.person_list
              && calculations.data.calculation.person_list.length > 0 ?
              (
                <Tabs defaultActiveKey="person_0" id="uncontrolled-tab-example" className="mb-3">

                  {calculations.data.calculation.person_list.map((person, idx) => {
                    return (
                      <Tab eventKey={'person_' + idx} title={person.job_name}>
                        <div className="summary-pay box">
                          <Tab.Container id="left-tabs-example" defaultActiveKey={"first_" + idx}>
                            <Row>
                              <Col sm={3} className="custom-bg-nav">
                                <Nav variant="pills" className="flex-column">
                                  <Nav.Item>
                                    <Nav.Link eventKey={"first_" + idx}>Pay</Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link eventKey={"second_" + idx}>Bill</Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link eventKey={"third_" + idx}>Assigment</Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link eventKey={"four_" + idx}>Benifits</Nav.Link>
                                  </Nav.Item>

                                  <Nav.Item>
                                    <Nav.Link eventKey={"five_" + idx}>Other Costs</Nav.Link>
                                  </Nav.Item>

                                </Nav>
                              </Col>
                              <Col sm={9}>
                                <Tab.Content>
                                  <Tab.Pane eventKey={"first_" + idx}>
                                    <h4>Pay</h4>
                                    <ListGroup variant="flush">
                                      <ListGroup.Item>
                                        <strong>Compensation type:</strong>
                                        {person.pay.compensation_type}
                                      </ListGroup.Item>
                                      <ListGroup.Item>
                                        <strong>Pay Rate:</strong>
                                        {person.pay.pay_rate}
                                      </ListGroup.Item>
                                      <ListGroup.Item>
                                        <strong>Add stipend:</strong>
                                        {person.pay.stepend ? "On" : "Off"}
                                      </ListGroup.Item>
                                      <ListGroup.Item>
                                        <strong>Discount:</strong>
                                        {person.pay.discount === '' ? 0 : person.pay.discount}
                                      </ListGroup.Item>
                                      <ListGroup.Item>
                                        <strong>Exempt:</strong>
                                        {person.pay.exampt ? "On" : "Off"}
                                      </ListGroup.Item>
                                      <ListGroup.Item>
                                        <strong>Pay frequency:</strong>
                                        {person.pay.pay_frequency}
                                      </ListGroup.Item>
                                    </ListGroup>
                                  </Tab.Pane>
                                  <Tab.Pane eventKey={"second_" + idx}>
                                    <h4>Bill</h4>
                                    <ListGroup variant="flush">
                                      <ListGroup.Item>
                                        <strong>Compensation type:</strong>
                                        {person.bill.compensation_type}
                                      </ListGroup.Item>
                                      {
                                        person.bill.compensation_type === "markup" ? <ListGroup.Item>
                                          <strong>Markup:</strong>
                                          {person.bill.markup}
                                        </ListGroup.Item> : <ListGroup.Item>
                                          <strong>Bill Rate:</strong>
                                          {person.bill.bill_rate}
                                        </ListGroup.Item>
                                      }
                                      {person.pay.exampt ? "" : (
                                        <ListGroup.Item>
                                          <strong> Overtime Markup:</strong>
                                          {person.bill.overtime}
                                        </ListGroup.Item>
                                      )}

                                    </ListGroup>
                                  </Tab.Pane>
                                  <Tab.Pane eventKey={"third_" + idx}>
                                    <h4>Assigment</h4>
                                    <ListGroup variant="flush">
                                      <ListGroup.Item>
                                        <strong>Have start / end date:</strong>
                                        {person.assignment.have_start_end_date === "no" ?
                                          <span className="badge badge-danger">{person.assignment.have_start_end_date}</span> : <span className="badge badge-success">{person.assignment.have_start_end_date}</span>

                                        }
                                      </ListGroup.Item>

                                      {
                                        person.assignment.have_start_end_date === "no" ? <ListGroup.Item>
                                          <strong>Estimated assigment length:</strong>
                                          {person.assignment.estimated_assigment_length} {person.assignment.estimated_assigment_period}
                                        </ListGroup.Item> : <><ListGroup.Item>
                                          <strong>Start date:</strong>
                                          {person.assignment.start_date}
                                        </ListGroup.Item>
                                          <ListGroup.Item>
                                            <strong>End date:</strong>
                                            {person.assignment.end_date}
                                          </ListGroup.Item>
                                        </>
                                      }

                                      <ListGroup.Item>
                                        <strong>Schedule:</strong>
                                        {person.assignment.shadule}
                                      </ListGroup.Item>

                                      {
                                        person.assignment.shadule === "shift" ?
                                          <>
                                            <ListGroup.Item>
                                              <strong>Hours per shift:</strong>
                                              {person.assignment.hours_per_week}
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                              <strong>Shift per week:</strong>
                                              {person.assignment.shift_per_week}
                                            </ListGroup.Item>
                                          </> : person.assignment.shadule === "part-time" ?
                                            <ListGroup.Item>
                                              <strong>Hours per week:</strong>
                                              {person.assignment.hours_per_week}
                                            </ListGroup.Item>
                                            : <></>
                                      }



                                    </ListGroup>
                                  </Tab.Pane>
                                  <Tab.Pane eventKey={"four_" + idx}>
                                    <h4>Benifits</h4>
                                    <ListGroup variant="flush">
                                      <ListGroup.Item>
                                        <div className="d-flex flex-column w-100">
                                          <div className="d-flex justify-content-between">
                                            <strong>Holiday pay:</strong>
                                            {person.benifits.holiday_pay ? person.benifits.holiday_paid_time : <span className="badge badge-danger">No</span>}
                                          </div>
                                          <div className={`d-flex justify-content-between ${person?.benifits?.holiday_type ? "mt-2" : ""}`}>
                                            {person?.benifits?.holiday_type ? <strong>Type:</strong> : ""}
                                            {person?.benifits?.holiday_type ? person?.benifits?.holiday_type : <span className="badge badge-danger"></span>}
                                          </div>
                                        </div>
                                      </ListGroup.Item>

                                      <ListGroup.Item>
                                        <div className="d-flex flex-column w-100">
                                          <div className="d-flex justify-content-between">
                                            <strong>PTO/Vacation/Sick:</strong>
                                            {person.benifits.paid_time ? person.benifits.paid_time_number_of_days : <span className="badge badge-danger">No</span>}
                                          </div>
                                          <div className={`d-flex justify-content-between ${person?.benifits?.pto_type ? "mt-2" : ""}`}>
                                            {person?.benifits?.pto_type ? <strong>Type:</strong> : ""}
                                            {person?.benifits?.pto_type ? person?.benifits?.pto_type : <span className="badge badge-danger"></span>}
                                          </div>
                                        </div>

                                      </ListGroup.Item>

                                      <ListGroup.Item>
                                        <strong>401 k:</strong>
                                        {person.benifits.k_401 ? person.benifits.k_401_number_of_days : <span className="badge badge-danger">No</span>}
                                      </ListGroup.Item>

                                      <ListGroup.Item>
                                        <strong>Bonus:</strong>
                                        {person.benifits.bonus ? person.benifits.bonus_pay + person.benifits.bonus_type : <span className="badge badge-danger">No</span>}
                                      </ListGroup.Item>

                                      <ListGroup.Item>
                                        <strong>Healthcare plan:</strong>
                                        {person.benifits.healthcare_plan && organizationSetting && organizationSetting.packages && organizationSetting.packages.length > 0 ? healthCarePlan(person.benifits.health_plans) : <span className="badge badge-danger">No</span>}
                                      </ListGroup.Item>


                                    </ListGroup>
                                  </Tab.Pane>
                                  <Tab.Pane eventKey={"five_" + idx}>
                                    <h4>Other Costs</h4>
                                    <ListGroup variant="flush">
                                      <ListGroup.Item>
                                        <strong>Payment terms:</strong>
                                        {person.othercost.number_of_days !== "" ? person.othercost.number_of_days : <span className="badge badge-danger">No</span>}
                                      </ListGroup.Item>


                                      {Object.keys(person.othercost.costTypes_and_ids).map((cost) => {
                                        if (cost !== "custom_cost_list" && organizationSetting && organizationSetting.costs && organizationSetting.costs[cost] && organizationSetting.costs[cost].length > 0) {
                                          let item = person.othercost.costTypes_and_ids[cost];
                                          return (<ListGroup.Item className="flex-column">
                                            <div className="d-flex justify-content-between">
                                              <strong>{cost.replaceAll('_', ' ')}:</strong>
                                              {item.length > 0 ? <span className="badge badge-success">Yes</span> : <span className="badge badge-danger">No</span>}
                                            </div>
                                            <div className="inner-list">
                                              <ul className="list-unstyled m-0">
                                                {item.map((i) => {
                                                  let cost_info = organizationSetting.costs[cost].find((c) => c.id === parseInt(i))
                                                  return (<li className='d-flex justify-content-between'>
                                                    <div>{cost_info.name}:</div>
                                                    <div>
                                                      {cost_info.percent}
                                                      {cost === "interest" || cost === "vendor_management_system" || cost === "manage_service_provider" || cost === "manage_service_provider" || cost === "payrolling_cost" || cost === "liability_insurance" ? '%' : typeof cost_info.cost_option !== "undefined" && cost_info.cost_option === "percentage" ? '%' : cost_info.cost_option === "flat" ? '$' : cost === "drug_screen_cost" || cost === "background_check_cost" ? '$' : ''}
                                                      <span>{cost_info.from === "1" ? " (from Revenue)" : " (from Total Wage)"}</span>
                                                    </div>
                                                  </li>
                                                  )
                                                })}
                                              </ul>
                                            </div>
                                          </ListGroup.Item>)




                                        }
                                      })}
                                    </ListGroup>
                                  </Tab.Pane>
                                </Tab.Content>
                              </Col>
                            </Row>
                          </Tab.Container>
                        </div>
                      </Tab>
                    )
                  })}
                </Tabs>
              )
              : (<></>)}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => setShowSummaryModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>


    )
  };
  const handleSummaryClick = () => {
    setShowSummaryModal(true);
  };

  const handleEditClick = () => {
    setShowEditModal(true);
  };

  const sendToApproval = async () => {
    let data = {
      id: id,
      status: "Sent to approval",
    };
    let result = await sendCalToApproval(JSON.stringify(data));
    if (result.status === 200) {
      setCalculations({ ...result });
      // setStatus("Approved");
      handleSendToApprovalModal();
    } else {
      toast.error("Error! Please try again");
    }
  };

  const handleQuoteSelectAll = () => {
    setQuoteSelectAllCheck(true);
    let quotePersonListCopy = quotePersonList;
    personList.forEach((p, i) => {
      let check = 0;
      Object.keys(quotePersonListCopy).forEach((key) => {
        if (key == i) {
          check = 1;
        }
      });
      if (!check) {
        quotePersonListCopy[`${i}`] = { count: 1, data: { ...p } };
      }
    });
    setQuotePersonList({ ...quotePersonListCopy });
  };

  const handleQuotePersonSelection = (index) => {
    setQuoteSelectAllCheck(false);
    let check = 0;
    let idx;
    let quotePersonListCopy = quotePersonList;
    Object.keys(quotePersonListCopy).forEach((key, i) => {
      if (key == index) {
        check = 1;
        idx = key;
      }
    });
    if (check) {
      if (Object.keys(quotePersonListCopy).length > 1) {
        delete quotePersonListCopy[idx];
      }
    } else {
      quotePersonListCopy[`${index}`] = {
        count: 1,
        data: { ...personList[index] },
      };
    }
    setQuotePersonList({ ...quotePersonListCopy });
    return quotePersonListCopy;
  };

  const decrease = (index) => {
    let check = 0;
    let quotePersonListCopy = quotePersonList;
    Object.keys(quotePersonListCopy).forEach((key) => {
      if (key == index) {
        check = 1;
      }
    });
    if (!check) {
      quotePersonListCopy = handleQuotePersonSelection(index);
    }
    if (quotePersonListCopy[`${index}`]["count"] > 0) {
      quotePersonListCopy[`${index}`] = {
        ...quotePersonListCopy[`${index}`],
        ["count"]: quotePersonListCopy[`${index}`]["count"] - 1,
      };
    }
    setQuotePersonList({ ...quotePersonListCopy });
  };

  const increase = (index) => {
    let check = 0;
    let quotePersonListCopy = quotePersonList;
    Object.keys(quotePersonListCopy).forEach((key) => {
      if (key == index) {
        check = 1;
      }
    });
    if (!check) {
      quotePersonListCopy = handleQuotePersonSelection(index);
    }
    quotePersonListCopy[`${index}`] = {
      ...quotePersonListCopy[`${index}`],
      ["count"]: quotePersonListCopy[`${index}`]["count"] + 1,
    };
    setQuotePersonList({ ...quotePersonListCopy });
  };

  const handleQuoteForm = (e) => {
    if (Array.isArray(e)) {
      setQuoteFormData({
        ...quoteFormData,
        ["quote_state"]: e,
      });
    } else {
      setQuoteFormData({ ...quoteFormData, [e.target.name]: e.target.value });
    }
  };

  const handleDeclineForm = async (e) => {
    let error = false;
    let declineFormErrorsCopy = {};
    if (e === "submit") {
      setDisabled(true);
      Object.keys(declineFormData).forEach((k, idx) => {
        if (declineFormData[k] == "") {
          declineFormErrorsCopy[k] = "Please fill this out";
          error = true;
        } else {
          declineFormErrorsCopy[k] = "";
        }
      });
      setDeclineFormErrors({ ...declineFormErrorsCopy });
      if (!error) {
        // Our declining API Call
        let data = {
          id: id,
          status: "Not approved",
          reasonData: declineFormData,
        };
        let result = await sendCalToApproval(JSON.stringify(data));
        if (result.status === 200) {
          setCalculations({ ...result });
          setShowDeclineModal(false);
          toast.success("Status updated Successfully!");
        } else {
          toast.error("Error updating the status");
        }
      }
      setDisabled(false);
    } else {
      setDeclineFormData({
        ...declineFormData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleApproveCal = async () => {
    let data = {
      id: id,
      status: "Approved",
    };
    let result = await sendCalToApproval(JSON.stringify(data));
    if (result.status === 200) {
      setCalculations({ ...result });
      toast.success("Status updated Successfully!");
    } else {
      toast.error("Error updating the status");
    }
  };

  const createQuoteData = async (e) => {
    setDisabled(true);
    validator.hideMessages();
    forceUpdate(0);
    let valid = 1;
    if (!validator.fieldValid("company_name")) {
      valid = 0;
    }
    if (!validator.fieldValid("quote_number")) {
      valid = 0;
    }
    if (!validator.fieldValid("quote_state")) {
      valid = 0;
    }
    if (!valid) {
      validator.showMessages();
      forceUpdate(1);
    } else {
      let data = {
        ...quoteFormData,
        person_selection_data: quotePersonList,
        id: id,
      };
      let result = await createQuote(JSON.stringify(data));
      if (result.status === 200) {
        toast.success(Constants.update_message);
        // window.open("/quote/" + result.data.quotation.id, "_blank").focus();
        history.push("/quote/" + result.data.quotation.id);
      } else {
        toast.error(Constants.update_error_message);
      }
    }
    setDisabled(false);
  };

  const downloadRequest = (type) => {
    let data = {
      id: id,
      type: type,
    };
    async function getDownload() {
      window
        .open(
          `${process.env.REACT_APP_API_URL}/calculations/download/${data.id}/${data.type}`
        )
        .focus();
    }
    getDownload();
  };

  const customControlStyles = {
    control: (base, state) => ({
      ...base,
      minHeight: 50,
      marginBottom: "15px",
      background: state.isFocused ? "#fff" : "#fff",
      borderWidth: 1,
      borderRadius: "8px",
      borderColor: state.isFocused ? "#7848FF" : "#E5E8F5",
      boxShadow: state.isFocused ? null : null,
      fontSize: "14px",
      "&:hover": {
        borderColor: state.isFocused ? "#7848FF" : null,
      },
    }),
    dropdownIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
      marginRight: "5px",
    }),
    menu: (base) => ({
      ...base,
      fontSize: "13px",
      border: "1px solid #f1f3f6",
      boxShadow: "none",
    }),
    option: (base, state) => ({
      ...base,
      background: state.isSelected ? "#f1f3f6" : "#fff",
      color: "#151B26",
      "&:hover": {
        background: "#f1f3f6",
        color: "#151B26",
      },
    }),
    placeholder: (base) => ({
      ...base,
      color: "#A8B9CD",
    }),
    clearIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
  };

  return (
    <div className="calculation-details">
      <div className="top-details d-flex justify-content-between align-items-center">
        <div>
          <strong>
            {calculations
              ? calculations.data
                ? calculations.data.calculation.name
                  ? calculations.data.calculation.name
                  : "Calculation Project"
                : "Calculation Project"
              : "Calculation Project"}
          </strong>
          <span
            className={`badge ${status === `Approved`
              ? "badge-success"
              : status === `Not approved`
                ? "badge-danger"
                : "badge-info"
              } ml-3`}
          >
            {status}
          </span>
        </div>
        <>
          <>
            <Button
              className="btn-edit-custom mr-3"
              variant="outline-dark"
              onClick={handleSummaryClick}
            >
              <div className="icon mr-2">
                <img
                  src={SummaryBlackIcon}
                  alt="Summary Icon"
                  className="icon-black"
                />
                <img
                  src={SummaryWhiteIcon}
                  alt="SummaryIcon Icon"
                  className="icon-white"
                />
              </div>
              Summary
            </Button>

            {getSummayModalContent()}

            <Button
              className="btn-edit-custom mr-3"
              variant="outline-dark"
              onClick={handleEditClick}
            >
              <div className="icon mr-2">
                <img src={EditIcon} alt="Edit Icon" className="icon-black" />
                <img
                  src={EditWhiteIcon}
                  alt="Edit Icon"
                  className="icon-white"
                />
              </div>
              Edit
            </Button>

            <GetSummayModalContent
              showSummaryModal={showEditModal}
              handleSummaryModal={handleEditModal}
              calculations={calculations}
              editPersonList={editPersonList}
              handlePersonChanges={handlePersonChanges}
              Constants={Constants}
              startDate={startDate}
              setStartDate={setStartDate}
              setStartDate2={setStartDate2}
              startDate2={startDate2}
              setEditPersonList={setEditPersonList}
              organizationSetting={organizationSetting}
              handleCheckboxChange={handleCheckboxChange}
              setShowSummaryModal={setShowEditModal}
              id={id}
              editCalculation={editCalculation}
              setOrganizationSetting={setOrganizationSetting}
              personsBaseHourCalculations={personsBaseHourCalculations}
              defaultFunctions={defaultFunctions}
            />

            <Button
              className="btn-edit-custom mr-3"
              variant="outline-dark"
              onClick={handleDownloadModal}
            >
              <div className="icon mr-2">
                <img
                  src={DownloadIcon}
                  alt="Trash Icon"
                  className="icon-black"
                />
                <img
                  src={DownloadWhiteIcon}
                  alt="Trash Icon"
                  className="icon-white"
                />
              </div>
              Download
            </Button>
          </>
          {settings?.plan_name !== "Essential" ? (
            status === "New calculation" || status === "Not approved" ? (
              <Button
                className="btn-edit-custom mr-3"
                onClick={handleSendToApprovalModal}
              >
                Send for Approval
              </Button>
            ) : status === "Approved" ? (
              <Button className="btn-edit-custom mr-3" onClick={handleShowFour}>
                Create Qoute
              </Button>
            ) : null
          ) : null}
        </>
        <>
          {/* to be showed to manager. hidden for demo purpose */}
          {/* <Button
                className="btn-edit-custom mr-3"
                variant="outline-dark"
                onClick={handleDownloadModal}
              >
                <div className="icon mr-2">
                  <img
                    src={DownloadIcon}
                    alt="Trash Icon"
                    className="icon-black"
                  />
                  <img
                    src={DownloadWhiteIcon}
                    alt="Trash Icon"
                    className="icon-white"
                  />
                </div>
                Download
              </Button> */}
          {login && login.data && login.data.user_role !== "Team Member" ? (
            <>
              {status === "Sent to approval" ? (
                <>
                  <Button
                    className="btn-edit-custom mr-3"
                    variant="outline-dark"
                    onClick={handleDeclineModal}
                  >
                    Decline
                  </Button>
                  <Button
                    className="btn-edit-custom mr-3"
                    onClick={handleApproveCal}
                  >
                    Approve
                  </Button>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </>
        {/* Decline Modal */}
        <Modal
          animation={false}
          className="decline-modal"
          size="md"
          centered
          show={showDeclineModal}
          onHide={handleDeclineModal}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="details">
              <div className="title">Decline reason</div>
              <Form>
                <Form.Group className="decline-form">
                  {Constants.decline_reasons.map((reason, idx) => {
                    return (
                      <label class="custom-radio">
                        {reason.label}
                        <input
                          type="radio"
                          name="reason_choice"
                          value={reason.value}
                          checked={
                            declineFormData.reason_choice == reason.value
                          }
                          onChange={handleDeclineForm}
                        />
                        <span class="checkmark"></span>
                      </label>
                    );
                  })}
                  {declineFormErrors.reason_choice ? (
                    <div className="text-danger">
                      {declineFormErrors.reason_choice}
                    </div>
                  ) : null}
                </Form.Group>
              </Form>
            </div>
          </Modal.Body>
          <Form.Group className="decline-form decline-form-reason">
            <Form.Label>Reason</Form.Label>
            <Form.Control
              as="textarea"
              name="decline_reason"
              onChange={handleDeclineForm}
              value={declineFormData.decline_reason}
              rows={6}
              style={{ height: "160px" }}
            />
            {declineFormErrors.decline_reason ? (
              <div className="text-danger">
                {declineFormErrors.decline_reason}
              </div>
            ) : null}
          </Form.Group>
          <Modal.Footer>
            <Button variant="outline-light" onClick={handleDeclineModal}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => handleDeclineForm("submit")}
              disabled={disabled}
            >
              Decline
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Download Modal */}
        <Modal
          animation={false}
          className="import-states-code-modal"
          size="md"
          centered
          show={showDownloadModal}
          onHide={handleDownloadModal}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="details">
              <div className="title">Download Calculation</div>
              <div className="download-btns">
                <a
                  className="download-btn-content"
                  onClick={() => downloadRequest("pdf")}
                >
                  <svg
                    width="90"
                    height="101"
                    viewBox="0 0 90 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M64.2103 1H5C2.79086 1 1 2.79086 1 5V96C1 98.2091 2.79086 100 5 100H76.5C78.7091 100 80.5 98.2091 80.5 96V16.3677C80.5 15.8142 80.2706 15.2854 79.8663 14.9072L65.5767 1.53946C65.2061 1.19284 64.7177 1 64.2103 1Z"
                      stroke="#142433"
                      stroke-width="2"
                    />
                    <path
                      d="M80.5 15.5H66.5C65.3954 15.5 64.5 14.6046 64.5 13.5V1"
                      stroke="#142433"
                      stroke-width="2"
                    />
                    <rect
                      x="47"
                      y="67.5"
                      width="43"
                      height="20"
                      rx="2"
                      fill="#FF4859"
                    />
                    <path
                      d="M52.328 72.076H57.544C58.856 72.076 59.8693 72.396 60.584 73.036C61.3093 73.676 61.672 74.5667 61.672 75.708C61.672 76.8707 61.3093 77.7773 60.584 78.428C59.8693 79.0787 58.856 79.404 57.544 79.404H54.712V83.5H52.328V72.076ZM54.712 77.308H57.208C57.9227 77.308 58.4347 77.18 58.744 76.924C59.064 76.6573 59.224 76.2627 59.224 75.74C59.224 75.2173 59.064 74.828 58.744 74.572C58.4347 74.3053 57.9227 74.172 57.208 74.172H54.712V77.308ZM63.7655 72.076H68.5335C70.2508 72.076 71.5628 72.588 72.4695 73.612C73.3762 74.636 73.8295 76.028 73.8295 77.788C73.8295 79.548 73.3762 80.94 72.4695 81.964C71.5628 82.988 70.2508 83.5 68.5335 83.5H63.7655V72.076ZM66.1495 81.372H68.2295C69.3175 81.372 70.1122 81.0467 70.6135 80.396C71.1255 79.7347 71.3815 78.8653 71.3815 77.788C71.3815 76.7107 71.1255 75.8467 70.6135 75.196C70.1122 74.5347 69.3175 74.204 68.2295 74.204H66.1495V81.372ZM84.3694 74.204H78.3214V76.828H83.0894V78.908H78.3214V83.5H75.9374V72.076H84.3694V74.204Z"
                      fill="white"
                    />
                  </svg>
                  <p>Download pdf.</p>
                </a>
                <a
                  className="download-btn-content"
                  onClick={() => downloadRequest("xlsx")}
                >
                  <img
                    src={ExcelImage}
                    width="90"
                    height="101"
                    viewBox="0 0 90 101"
                    fill="none"
                  />
                  {/* <svg
                      width="90"
                      height="101"
                      viewBox="0 0 90 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M64.2103 1H5C2.79086 1 1 2.79086 1 5V96C1 98.2091 2.79086 100 5 100H76.5C78.7091 100 80.5 98.2091 80.5 96V16.3677C80.5 15.8142 80.2706 15.2854 79.8663 14.9072L65.5767 1.53946C65.2061 1.19284 64.7177 1 64.2103 1Z"
                        stroke="#142433"
                        stroke-width="2"
                      />
                      <path
                        d="M80.5 15.5H66.5C65.3954 15.5 64.5 14.6046 64.5 13.5V1"
                        stroke="#142433"
                        stroke-width="2"
                      />
                      <rect
                        x="47"
                        y="67.5"
                        width="43"
                        height="20"
                        rx="2"
                        fill="#2CD58E"
                      />
                      <path
                        d="M61.176 83.5L58.792 78.796H58.296L55.864 83.5H53.304L56.456 77.5L53.736 72.076H56.184L58.312 76.268H58.808L61 72.076H63.416L60.648 77.436L63.8 83.5H61.176ZM67.7734 83.5L64.3974 72.076H66.8774L69.4054 81.196H70.1414L72.6694 72.076H75.1174L71.7414 83.5H67.7734ZM79.1183 81.324H85.1663V83.5H76.7343V72.076H79.1183V81.324Z"
                        fill="white"
                      />
                    </svg> */}

                  <p>Download excel.</p>
                </a>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* Send to Approval Modal */}
        <Modal
          animation={false}
          className="import-states-code-modal"
          size="md"
          centered
          show={showSendToApprovalModal}
          onHide={handleSendToApprovalModal}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="details">
              <div className="icon">
                <img src={ApprovalImage} alt="Icon Image" />
              </div>
              <div className="title">Send to approval</div>
              <p>
                Are you sure you want to submit the <br /> estimate for
                approval?
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-light" onClick={handleSendToApprovalModal}>
              No
            </Button>
            <Button
              variant="primary"
              onClick={sendToApproval}
            // disabled={disabled}
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Quote Modal */}
        <Modal
          animation={false}
          className="create-quote-modal"
          size="md"
          centered
          show={showModalFour}
          onHide={handleCloseFour}
        >
          <Modal.Header closeButton className="with-title normal">
            <Modal.Title>Create Quote</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Company name</Form.Label>
              <FormControl
                placeholder="Uber"
                name="company_name"
                value={
                  quoteFormData["company_name"]
                    ? quoteFormData["company_name"]
                    : ""
                }
                onChange={handleQuoteForm}
              />
              {validator.message(
                "company_name",
                quoteFormData["company_name"],
                "required"
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label>Quote number</Form.Label>
              <FormControl
                name="quote_number"
                value={
                  quoteFormData["quote_number"]
                    ? quoteFormData["quote_number"]
                    : ""
                }
                onChange={handleQuoteForm}
              />
              {validator.message(
                "quote_number",
                quoteFormData["quote_number"],
                "required"
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label>State</Form.Label>
              <Select
                isMulti={true}
                styles={customControlStyles}
                options={statesOptions}
                name="quote_state"
                value={
                  statesOptions &&
                  statesOptions.find((s) => {
                    return s.label === quoteFormData["quote_state"];
                  })
                }
                onChange={handleQuoteForm}
              />
              {validator.message(
                "quote_state",
                quoteFormData["quote_state"],
                "required"
              )}
            </Form.Group>
            {personList.length > 1 && (
              <>
                <div className="individuals mt-4">
                  <strong className="d-flex justify-content-between align-items-center">
                    Individuals
                    <div className="custom-checkbox">
                      <input
                        type="checkbox"
                        id="s-all"
                        checked={quoteSelectAllCheck ? true : false}
                      />
                      <label for="s-all" onClick={handleQuoteSelectAll}>
                        Select all
                      </label>
                    </div>
                  </strong>
                  <div className="holder mt-2">
                    {personList.length > 0
                      ? personList.map((person, index) => {
                        return (
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="custom-checkbox">
                              <input
                                type="checkbox"
                                id={`ind-quote-name-${index}`}
                                checked={
                                  Object.keys(quotePersonList).includes(
                                    `${index}`
                                  )
                                    ? true
                                    : false
                                }
                              />
                              <label
                                for={`ind-quote-name-${index}`}
                                onClick={() =>
                                  handleQuotePersonSelection(index)
                                }
                              >
                                {person.job_name}
                              </label>
                            </div>
                            <div className="d-flex align-items-center">
                              <Button
                                variant="outline-light"
                                onClick={() => decrease(index)}
                              >
                                -
                              </Button>
                              <div className="ind-value">
                                {quotePersonList[`${index}`]
                                  ? Object.keys(quotePersonList[`${index}`])
                                    .length > 0
                                    ? quotePersonList[`${index}`]["count"]
                                    : 1
                                  : 1}
                              </div>
                              <Button
                                variant="outline-light"
                                onClick={() => increase(index)}
                              >
                                +
                              </Button>
                            </div>
                          </div>
                        );
                      })
                      : "No Persons"}
                  </div>
                </div>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-light" onClick={handleCloseFour}>
              Cancel
            </Button>
            <Button
              variant="primary"
              disabled={disabled}
              onClick={createQuoteData}
            >
              Create
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <Container fluid className="h-100">
        <Row className="h-100 calculations-layout">
          <Col xl={3} lg={3} className="pl-0">
            <div className="bg-white h-100 filters border-right">
              {status === "Not approved" ? (
                <div className="members">
                  <div className="reason-description">
                    <strong>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="12"
                          cy="12"
                          r="11"
                          stroke="#FF4859"
                          stroke-width="1.6"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12 11V16"
                          stroke="#FF4859"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12 7.5V7.51"
                          stroke="#FF4859"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      Reason Description
                    </strong>
                    <div className="mb-5">
                      <small className="mb-2 d-block">
                        <strong className="mb-0">Reason Choice:</strong>{" "}
                        {calculations?.data?.calculation?.reason_choice}
                      </small>
                      <small className="mb-2 d-block">
                        <strong className="mb-0">Decline Reason:</strong>{" "}
                        {calculations?.data?.calculation?.decline_reason}
                      </small>
                    </div>
                    {/* <p>
                      Are you sure you want to submit the estimate for approval?
                    </p> */}
                    <Button onClick={handleEditClick}>Edit Calculation</Button>
                  </div>
                </div>
              ) : null}
              <div className="members">
                <strong>Member</strong>
                <div className="member">
                  <div className="member-name-short">
                    {userData ? (
                      userData.image ? (
                        <div className="generic-user-pic">
                          <div className="user-pic">
                            <img src={userData.image} alt="Image" />
                          </div>
                        </div>
                      ) : (
                        userData.first_name.charAt(0).toUpperCase() +
                        userData.last_name.charAt(0).toUpperCase()
                      )
                    ) : (
                      "AI"
                    )}
                  </div>
                  {userData
                    ? userData.first_name + " " + userData.last_name
                    : "Allen Iverson"}
                </div>
              </div>
              <div className="choose-state">
                <Form.Group>
                  <Form.Label>State</Form.Label>
                  <Select
                    isMulti={true}
                    styles={customControlStyles}
                    options={statesOptions}
                    // name="quote_state"
                    value={
                      statesOptions &&
                      statesOptions.find((s) => {
                        return s.label === stateSelect;
                      })
                    }
                    onChange={handleStateSelect}
                  />
                </Form.Group>
              </div>
              <div className="individuals">
                <div className="holder">
                  {personList.length > 1 ? (
                    <>
                      <strong>Individuals</strong>


                      <div className="custom-checkbox">
                        <input
                          type="checkbox"
                          id="select-all"
                          checked={selectAllCheck ? true : false}
                        />
                        <label for="select-all" onClick={handleSelectAll}>
                          Select All
                        </label>
                      </div>
                      {personList.map((person, index) => {
                        return (
                          <div className="custom-checkbox" key={index}>
                            <input
                              type="checkbox"
                              id={`ind-name-${index}`}
                              checked={
                                personSelection.includes(index) ? true : false
                              }
                            />
                            <label
                              for={`ind-name-${index}`}
                              onClick={(e) => handlePersonSelection(e, index)}
                            >
                              {person.job_name}
                            </label>
                          </div>
                        );
                      })}
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </Col>
          <Col xl={9} lg={9} className="pl-0">
            <div className="main-details-section">
              <div className="graph-box-holder d-flex flex-column h-100">
                <Container fluid>
                  <Row>
                    <Col xl={5} lg={6}>
                      <div className="graph-box d-flex align-items-center">
                        <div className="icon-image info-icon">
                          <img src={BagIcon} alt="Icon" />
                        </div>
                        <div>
                          <span className="title">Project cost</span>
                          <div className="g-value">
                            <span className="text-success mr-1">$</span>{" "}
                            {nFormatter(
                              personTotalAssignmentCalcualtions.total_project_cost
                            )}
                          </div>
                        </div>
                        <div className="d-flex box-holder">
                          <div>
                            <span className="title">Total Wages</span>
                            <div className="g-value">
                              <span className="text-success mr-1">$</span>{" "}
                              {nFormatter(
                                personTotalAssignmentCalcualtions.total_wages
                              )}
                            </div>
                          </div>
                          <div>
                            <span className="title">Total burden cost</span>
                            <div className="g-value">
                              <span className="text-success mr-1">$</span>{" "}
                              {nFormatter(
                                personTotalAssignmentCalcualtions.total_employers_burden
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xl={7} lg={6}>
                      <Row>
                        <Col lg={6}>
                          <div className="graph-box d-flex align-items-center">
                            <div className="icon-image success-icon">
                              <img src={PersentIcon} alt="Icon" />
                            </div>
                            <div>
                              <span className="title">Gross Profit %</span>
                              <div className="g-value">
                                <span className="text-success mr-1">$</span>{" "}
                                {nFormatter(
                                  personTotalAssignmentCalcualtions.gross_profit
                                )}
                                <small
                                  className={`badge ${grossProfitPercent < 0
                                    ? "badge-danger percent-up-red"
                                    : "badge-success percent-up"
                                    }`}
                                >
                                  {grossProfitPercent}%
                                </small>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="graph-box d-flex align-items-center">
                            <div className="icon-image primary-icon">
                              <img src={WalletIcon} alt="Icon" />
                            </div>
                            <div>
                              <span className="title">Revenue</span>
                              <div className="g-value">
                                <span className="text-success mr-1">$</span>
                                {nFormatter(
                                  personTotalAssignmentCalcualtions.total_revenue
                                )}
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={8}>
                      {personsBaseHourCalculations &&
                        Object.keys(personsBaseHourCalculations).length > 0 ? (
                        <div className="card mt-4">
                          <div className="card-body p-0">
                            <div className="custom-tabs">
                              <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
                                <Tab
                                  eventKey="project-cost-breakdown"
                                  title="Project Cost Breakdown"
                                >
                                  <div className="collapse-header ">
                                    <div className="d-flex justify-content-between">
                                      <span style={{ width: "auto" }}>Cost name:</span>
                                      <span style={{ width: "auto" }}>Base hour:</span>
                                      <span style={{ width: "auto" }}>Total assigment:</span>
                                    </div>
                                  </div>
                                  {/* Revenue */}
                                  <Accordion defaultActiveKey="1">
                                    <Accordion.Toggle
                                      className="collapse-btn"
                                      as={Button}
                                      variant="link"
                                      eventKey="0"
                                    >
                                      <strong>
                                        {" "}
                                        <BiChevronDown /> Total Revenue
                                      </strong>
                                      <div className="before-caps">
                                        <div className="d-flex">
                                          <span className="text-success mr-1">
                                            $
                                          </span>{" "}
                                          {personsBaseHourCalculations.revenue}
                                        </div>
                                        <div></div>
                                      </div>
                                      <div>
                                        <div className="d-flex">
                                          <span className="text-success mr-1">
                                            $
                                          </span>{" "}
                                          {
                                            personTotalAssignmentCalcualtions.total_revenue
                                          }
                                        </div>
                                        <div></div>
                                      </div>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse
                                      eventKey="0"
                                      className="main-box ml-0"
                                    >
                                      <div>
                                        <div className="inner-table">
                                          <span className="title">Revenue</span>
                                          <div className="before-caps">
                                            <div className="d-flex">
                                              <span className="text-success mr-1">
                                                $
                                              </span>{" "}
                                              {
                                                personsBaseHourCalculations.revenue
                                              }
                                            </div>
                                            <div></div>
                                          </div>
                                          <div>
                                            <div className="d-flex">
                                              <span className="text-success mr-1">
                                                $
                                              </span>{" "}
                                              {
                                                personTotalAssignmentCalcualtions.revenue
                                              }
                                            </div>
                                            <div></div>
                                          </div>
                                        </div>
                                      </div>
                                    </Accordion.Collapse>
                                    <Accordion.Collapse
                                      eventKey="0"
                                      className="main-box ml-0"
                                    >
                                      <div>
                                        <div className="inner-table">
                                          <span className="title">
                                            Overtime Revenue
                                          </span>
                                          <div className="before-caps">
                                            <div className="d-flex">
                                              <span className="text-success mr-1">
                                                &nbsp;
                                              </span>{" "}
                                              {/* {
                                                personsBaseHourCalculations.overtime_revenue
                                              } */}
                                            </div>
                                            <div></div>
                                          </div>
                                          <div>
                                            <div className="d-flex">
                                              <span className="text-success mr-1">
                                                $
                                              </span>{" "}
                                              {
                                                personTotalAssignmentCalcualtions.overtime_revenue
                                              }
                                            </div>
                                            <div></div>
                                          </div>
                                        </div>
                                      </div>
                                    </Accordion.Collapse>
                                  </Accordion>
                                  {/* Total Wages */}
                                  <Accordion defaultActiveKey="1">
                                    <Accordion.Toggle
                                      className="collapse-btn"
                                      as={Button}
                                      variant="link"
                                      eventKey="0"
                                    >
                                      <strong>
                                        {" "}
                                        <BiChevronDown /> Total wages
                                      </strong>
                                      <div className="before-caps">
                                        <div className="d-flex">
                                          <span className="text-success mr-1">
                                            $
                                          </span>{" "}
                                          {
                                            personsBaseHourCalculations.total_wages
                                          }
                                        </div>
                                        <div></div>
                                      </div>
                                      <div>
                                        <div className="d-flex">
                                          <span className="text-success mr-1">
                                            $
                                          </span>{" "}
                                          {
                                            personTotalAssignmentCalcualtions.total_wages
                                          }
                                        </div>
                                        <div></div>
                                      </div>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse
                                      eventKey="0"
                                      className="main-box"
                                    >
                                      <div>
                                        {/* Taxable Wages */}
                                        <Accordion defaultActiveKey="2">
                                          <Accordion.Toggle
                                            className="collapse-btn"
                                            as={Button}
                                            variant="link"
                                            eventKey="1"
                                          >
                                            <span className="title">
                                              {" "}
                                              <BiChevronDown /> Taxable wages
                                            </span>
                                            <div className="before-caps">
                                              <div className="d-flex">
                                                <span className="text-success mr-1">
                                                  $
                                                </span>{" "}
                                                {
                                                  personsBaseHourCalculations.regular_pay
                                                }
                                              </div>
                                              <div></div>
                                            </div>
                                            <div>
                                              <div>
                                                <span className="text-success">
                                                  $
                                                </span>{" "}
                                                {
                                                  personTotalAssignmentCalcualtions.taxable_wages
                                                }
                                              </div>
                                              <div></div>
                                            </div>
                                          </Accordion.Toggle>
                                          <Accordion.Collapse
                                            className="main-box"
                                            eventKey="1"
                                          >
                                            <>
                                              {/* Regular pay */}
                                              <div className="inner-table">
                                                <span className="title">
                                                  Regular Pay
                                                </span>
                                                <div className="before-caps">
                                                  <div className="d-flex">
                                                    <span className="text-success mr-1">
                                                      $
                                                    </span>{" "}
                                                    {
                                                      personsBaseHourCalculations.regular_pay
                                                    }
                                                  </div>
                                                  <div></div>
                                                </div>
                                                <div>
                                                  <div className="d-flex">
                                                    <span className="text-success mr-1">
                                                      $
                                                    </span>{" "}
                                                    {
                                                      personTotalAssignmentCalcualtions.regular_pay
                                                    }
                                                  </div>
                                                  <div></div>
                                                </div>
                                              </div>
                                              {/* Overtime pay */}
                                              <div className="inner-table">
                                                <span className="title">
                                                  Overtime Pay
                                                </span>
                                                <div className="before-caps">
                                                  <div className="d-flex">
                                                    <span className="text-success mr-1">
                                                      &nbsp;
                                                    </span>{" "}
                                                    {/* {
                                                      personsBaseHourCalculations.overtime_pay
                                                    } */}
                                                  </div>
                                                  <div></div>
                                                </div>
                                                <div>
                                                  <div className="d-flex">
                                                    <span className="text-success mr-1">
                                                      $
                                                    </span>{" "}
                                                    {
                                                      personTotalAssignmentCalcualtions.overtime_pay
                                                    }
                                                  </div>
                                                  <div></div>
                                                </div>
                                              </div>
                                              {/* Bonus */}
                                              <div className="inner-table">
                                                <span className="title">
                                                  Bonus
                                                </span>
                                                <div className="before-caps">
                                                  <div className="d-flex">
                                                    <span className="text-success mr-1">
                                                      &nbsp;
                                                    </span>{" "}
                                                    {/* {
                                                            personsBaseHourCalculations.bonus
                                                          } */}
                                                  </div>
                                                  <div></div>
                                                </div>
                                                <div>
                                                  <div className="d-flex">
                                                    <span className="text-success mr-1">
                                                      $
                                                    </span>{" "}
                                                    {
                                                      personTotalAssignmentCalcualtions.bonus
                                                    }
                                                  </div>
                                                  <div></div>
                                                </div>
                                              </div>
                                            </>
                                          </Accordion.Collapse>
                                        </Accordion>
                                        {/* Non Taxable Wages */}
                                        <Accordion defaultActiveKey="2">
                                          <Accordion.Toggle
                                            className="collapse-btn"
                                            as={Button}
                                            variant="link"
                                            eventKey="1"
                                          >
                                            <span className="title">
                                              {" "}
                                              <BiChevronDown /> Non Taxable
                                              wages
                                            </span>
                                            <div className="before-caps">
                                              <div className="d-flex">
                                                <span className="text-success mr-1">
                                                  $
                                                </span>{" "}
                                                {
                                                  personsBaseHourCalculations.non_taxable_wages
                                                }
                                              </div>
                                              <div></div>
                                            </div>
                                            <div>
                                              <div className="d-flex">
                                                <span className="text-success mr-1">
                                                  $
                                                </span>{" "}
                                                {
                                                  personTotalAssignmentCalcualtions.non_taxable_wages
                                                }
                                              </div>
                                              <div></div>
                                            </div>
                                          </Accordion.Toggle>
                                          <Accordion.Collapse
                                            className="main-box"
                                            eventKey="1"
                                          >
                                            <>
                                              {/* Stipends */}
                                              <div className="inner-table">
                                                <span className="title">
                                                  Stipends
                                                </span>
                                                <div className="before-caps">
                                                  <div className="d-flex">
                                                    <span className="text-success mr-1">
                                                      $
                                                    </span>{" "}
                                                    {
                                                      personsBaseHourCalculations.stipends
                                                    }
                                                  </div>
                                                  <div></div>
                                                </div>
                                                <div>
                                                  <div className="d-flex">
                                                    <span className="text-success mr-1">
                                                      $
                                                    </span>{" "}
                                                    {
                                                      personTotalAssignmentCalcualtions.stipends
                                                    }
                                                  </div>
                                                  <div></div>
                                                </div>
                                              </div>
                                            </>
                                          </Accordion.Collapse>
                                        </Accordion>
                                      </div>
                                    </Accordion.Collapse>
                                  </Accordion>
                                  {/* Total Employers Burden */}
                                  <Accordion defaultActiveKey="2">
                                    <Accordion.Toggle
                                      className="collapse-btn"
                                      as={Button}
                                      variant="link"
                                      eventKey="0"
                                    >
                                      <strong>
                                        {" "}
                                        <BiChevronDown /> Total Employers Burden
                                      </strong>
                                      <div className="before-caps">
                                        <div className="d-flex">
                                          <span className="text-success mr-1">
                                            $
                                          </span>{" "}
                                          {
                                            personsBaseHourCalculations.total_employers_burden
                                          }
                                        </div>
                                        <div></div>
                                      </div>
                                      <div>
                                        <div className="d-flex">
                                          <span className="text-success mr-1">
                                            $
                                          </span>{" "}
                                          {
                                            personTotalAssignmentCalcualtions.total_employers_burden
                                          }
                                        </div>
                                        <div></div>
                                      </div>
                                    </Accordion.Toggle>
                                    {/* Employer Taxes */}
                                    <Accordion.Collapse
                                      eventKey="0"
                                      className="main-box"
                                    >
                                      <div>
                                        <Accordion defaultActiveKey="2">
                                          <Accordion.Toggle
                                            className="collapse-btn"
                                            as={Button}
                                            variant="link"
                                            eventKey="1"
                                          >
                                            <span className="title">
                                              {" "}
                                              <BiChevronDown /> Employer Taxes
                                            </span>
                                            <div className="before-caps">
                                              <div className="d-flex">
                                                <span className="text-success mr-1">
                                                  $
                                                </span>{" "}
                                                {
                                                  personsBaseHourCalculations.employer_taxes
                                                }
                                              </div>
                                              <div></div>
                                            </div>
                                            <div>
                                              <div className="d-flex">
                                                <span className="text-success mr-1">
                                                  $
                                                </span>{" "}
                                                {
                                                  personTotalAssignmentCalcualtions.employer_taxes
                                                }
                                              </div>
                                              <div></div>
                                            </div>
                                          </Accordion.Toggle>
                                          <Accordion.Collapse
                                            className="main-box"
                                            eventKey="1"
                                          >
                                            <>
                                              {/* Federal Taxes */}
                                              <Accordion defaultActiveKey="2">
                                                <Accordion.Toggle
                                                  className="collapse-btn"
                                                  as={Button}
                                                  variant="link"
                                                  eventKey="1"
                                                >
                                                  <span className="title">
                                                    {" "}
                                                    <BiChevronDown /> Federal
                                                    taxes
                                                  </span>
                                                  <div className="before-caps">
                                                    <div className="d-flex">
                                                      <span className="text-success mr-1">
                                                        $
                                                      </span>{" "}
                                                      {
                                                        personsBaseHourCalculations.federal_taxes
                                                      }
                                                    </div>
                                                    <div></div>
                                                  </div>
                                                  <div>
                                                    <div className="d-flex">
                                                      <span className="text-success mr-1">
                                                        $
                                                      </span>{" "}
                                                      {
                                                        personTotalAssignmentCalcualtions.federal_taxes
                                                      }
                                                    </div>
                                                    <div></div>
                                                  </div>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse
                                                  className="main-box"
                                                  eventKey="1"
                                                >
                                                  <>
                                                    {/* Social Security */}
                                                    <div className="inner-table">
                                                      <span className="title">
                                                        Social Security
                                                      </span>
                                                      <div className="before-caps">
                                                        <div className="d-flex">
                                                          <span className="text-success mr-1">
                                                            $
                                                          </span>{" "}
                                                          {
                                                            personsBaseHourCalculations.social_security
                                                          }
                                                        </div>
                                                        <div></div>
                                                      </div>
                                                      <div>
                                                        <div className="d-flex">
                                                          <span className="text-success mr-1">
                                                            $
                                                          </span>{" "}
                                                          {
                                                            personTotalAssignmentCalcualtions.social_security
                                                          }
                                                        </div>
                                                        <div></div>
                                                      </div>
                                                    </div>
                                                    {/* Medicare */}
                                                    <div className="inner-table">
                                                      <span className="title">
                                                        Medicare
                                                      </span>
                                                      <div className="before-caps">
                                                        <div className="d-flex">
                                                          <span className="text-success mr-1">
                                                            $
                                                          </span>{" "}
                                                          {
                                                            personsBaseHourCalculations.medicare
                                                          }
                                                        </div>
                                                        <div></div>
                                                      </div>
                                                      <div>
                                                        <div className="d-flex">
                                                          <span className="text-success mr-1">
                                                            $
                                                          </span>{" "}
                                                          {
                                                            personTotalAssignmentCalcualtions.medicare
                                                          }
                                                        </div>
                                                        <div></div>
                                                      </div>
                                                    </div>
                                                    {/* FUTA */}
                                                    <div className="inner-table">
                                                      <span className="title">
                                                        FUTA
                                                      </span>
                                                      <div className="before-caps">
                                                        <div className="d-flex">
                                                          <span className="text-success mr-1">
                                                            $
                                                          </span>{" "}
                                                          {
                                                            personsBaseHourCalculations.futa
                                                          }
                                                        </div>
                                                        <div></div>
                                                      </div>
                                                      <div>
                                                        <div className="d-flex">
                                                          <span className="text-success mr-1">
                                                            $
                                                          </span>{" "}
                                                          {
                                                            personTotalAssignmentCalcualtions.futa
                                                          }
                                                        </div>
                                                        <div></div>
                                                      </div>
                                                    </div>
                                                  </>
                                                </Accordion.Collapse>
                                              </Accordion>
                                              {/* State Taxes */}
                                              <Accordion defaultActiveKey="2">
                                                <Accordion.Toggle
                                                  className="collapse-btn"
                                                  as={Button}
                                                  variant="link"
                                                  eventKey="1"
                                                >
                                                  <span className="title">
                                                    {" "}
                                                    <BiChevronDown /> State
                                                    taxes
                                                  </span>
                                                  <div className="before-caps">
                                                    <div className="d-flex">
                                                      <span className="text-success mr-1">
                                                        $
                                                      </span>{" "}
                                                      {
                                                        personsBaseHourCalculations.state_taxes
                                                      }
                                                    </div>
                                                    <div></div>
                                                  </div>
                                                  <div>
                                                    <div className="d-flex">
                                                      <span className="text-success mr-1">
                                                        $
                                                      </span>{" "}
                                                      {
                                                        personTotalAssignmentCalcualtions.state_taxes
                                                      }
                                                    </div>
                                                    <div></div>
                                                  </div>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse
                                                  className="main-box"
                                                  eventKey="1"
                                                >
                                                  <>
                                                    {/* SUTA */}
                                                    <div className="inner-table">
                                                      <span className="title">
                                                        SUTA
                                                      </span>
                                                      <div className="before-caps">
                                                        <div className="d-flex">
                                                          <span className="text-success mr-1">
                                                            $
                                                          </span>{" "}
                                                          {
                                                            personsBaseHourCalculations.suta
                                                          }
                                                        </div>
                                                        <div></div>
                                                      </div>
                                                      <div>
                                                        <div className="d-flex">
                                                          <span className="text-success mr-1">
                                                            $
                                                          </span>{" "}
                                                          {
                                                            personTotalAssignmentCalcualtions.suta
                                                          }
                                                        </div>
                                                        <div></div>
                                                      </div>
                                                    </div>

                                                    {/* Bonus Tax */}
                                                    {/* <div className="inner-table">
                                                      <span className="title">
                                                        Bonus Tax
                                                      </span>
                                                      <div className="before-caps">
                                                        <div className="d-flex">
                                                          <span className="text-success mr-1">
                                                            $
                                                          </span>{" "}
                                                          {
                                                            personsBaseHourCalculations.bonus_tax
                                                          }
                                                        </div>
                                                        <div></div>
                                                      </div>
                                                      <div>
                                                        <div className="d-flex">
                                                          <span className="text-success mr-1">
                                                            $
                                                          </span>{" "}
                                                          {
                                                            personTotalAssignmentCalcualtions.bonus_tax
                                                          }
                                                        </div>
                                                        <div></div>
                                                      </div>
                                                    </div> */}
                                                  </>
                                                </Accordion.Collapse>
                                              </Accordion>
                                            </>
                                          </Accordion.Collapse>
                                        </Accordion>
                                      </div>
                                    </Accordion.Collapse>
                                    <Accordion.Collapse
                                      eventKey="0"
                                      className="main-box"
                                    >
                                      <div>
                                        {/* Risk */}
                                        <Accordion defaultActiveKey="2">
                                          <Accordion.Toggle
                                            className="collapse-btn"
                                            as={Button}
                                            variant="link"
                                            eventKey="1"
                                          >
                                            <span className="title">
                                              {" "}
                                              <BiChevronDown /> Risk
                                            </span>
                                            <div className="before-caps">
                                              <div className="d-flex">
                                                <span className="text-success mr-1">
                                                  $
                                                </span>{" "}
                                                {
                                                  personsBaseHourCalculations.risk
                                                }
                                              </div>
                                              <div></div>
                                            </div>
                                            <div>
                                              <div className="d-flex">
                                                <span className="text-success mr-1">
                                                  $
                                                </span>{" "}
                                                {
                                                  personTotalAssignmentCalcualtions.risk
                                                }
                                              </div>
                                              <div></div>
                                            </div>
                                          </Accordion.Toggle>
                                          <Accordion.Collapse
                                            className="main-box"
                                            eventKey="1"
                                          >
                                            <>
                                              {/* Insurance */}
                                              <Accordion defaultActiveKey="2">
                                                <Accordion.Toggle
                                                  className="collapse-btn"
                                                  as={Button}
                                                  variant="link"
                                                  eventKey="1"
                                                >
                                                  <span className="title">
                                                    {" "}
                                                    <BiChevronDown /> Insurance
                                                  </span>
                                                  <div className="before-caps">
                                                    <div className="d-flex">
                                                      <span className="text-success mr-1">
                                                        $
                                                      </span>{" "}
                                                      {
                                                        personsBaseHourCalculations.insurance
                                                      }
                                                    </div>
                                                    <div></div>
                                                  </div>
                                                  <div>
                                                    <div className="d-flex">
                                                      <span className="text-success mr-1">
                                                        $
                                                      </span>{" "}
                                                      {
                                                        personTotalAssignmentCalcualtions.insurance
                                                      }
                                                    </div>
                                                    <div></div>
                                                  </div>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse
                                                  className="main-box"
                                                  eventKey="1"
                                                >
                                                  <>
                                                    {/* Workers Comp */}
                                                    <div className="inner-table">
                                                      <span className="title">
                                                        Workers Comp
                                                      </span>
                                                      <div className="before-caps">
                                                        <div className="d-flex">
                                                          <span className="text-success mr-1">
                                                            $
                                                          </span>{" "}
                                                          {
                                                            personsBaseHourCalculations.workers_comp
                                                          }
                                                        </div>
                                                        <div></div>
                                                      </div>
                                                      <div>
                                                        <div className="d-flex">
                                                          <span className="text-success mr-1">
                                                            $
                                                          </span>{" "}
                                                          {
                                                            personTotalAssignmentCalcualtions.workers_comp
                                                          }
                                                        </div>
                                                        <div></div>
                                                      </div>
                                                    </div>
                                                    {/* Other Insurance */}
                                                    <Accordion defaultActiveKey="2">
                                                      <Accordion.Toggle
                                                        className="collapse-btn"
                                                        as={Button}
                                                        variant="link"
                                                        eventKey="1"
                                                      >
                                                        <span className="title">
                                                          {" "}
                                                          <BiChevronDown />{" "}
                                                          Other Insurance
                                                        </span>
                                                        <div className="before-caps">
                                                          <div className="d-flex">
                                                            <span className="text-success mr-1">
                                                              $
                                                            </span>{" "}
                                                            {
                                                              personsBaseHourCalculations.other_insurance
                                                            }
                                                          </div>
                                                          <div></div>
                                                        </div>
                                                        <div>
                                                          <div className="d-flex">
                                                            <span className="text-success mr-1">
                                                              $
                                                            </span>{" "}
                                                            {
                                                              personTotalAssignmentCalcualtions.other_insurance
                                                            }
                                                          </div>
                                                          <div></div>
                                                        </div>
                                                      </Accordion.Toggle>
                                                      <Accordion.Collapse
                                                        className="main-box"
                                                        eventKey="1"
                                                      >
                                                        <>
                                                          {personsBaseHourCalculations[
                                                            "othercost_items"
                                                          ].map(
                                                            (item, idx2) => {
                                                              return (
                                                                <div className="inner-table">
                                                                  <span className="title">
                                                                    {item.name}
                                                                  </span>
                                                                  <div className="before-caps">
                                                                    <div className="d-flex">
                                                                      <span className="text-success mr-1">
                                                                        $
                                                                      </span>{" "}
                                                                      {
                                                                        item.value
                                                                      }
                                                                    </div>
                                                                    <div></div>
                                                                  </div>
                                                                  <div>
                                                                    <div className="d-flex">
                                                                      <span className="text-success mr-1">
                                                                        $
                                                                      </span>{" "}
                                                                      {personTotalAssignmentCalcualtions[
                                                                        "othercost_items"
                                                                      ].map(
                                                                        (
                                                                          item2,
                                                                          index3
                                                                        ) => {
                                                                          if (
                                                                            item2.name ===
                                                                            item.name
                                                                          ) {
                                                                            return item2.value;
                                                                          }
                                                                        }
                                                                      )}
                                                                    </div>
                                                                    <div></div>
                                                                  </div>
                                                                </div>
                                                              );
                                                            }
                                                          )}

                                                          {/* {personsBaseHourCalculations.map((person, index) => {})} */}
                                                        </>
                                                      </Accordion.Collapse>
                                                    </Accordion>

                                                    {/* {personsBaseHourCalculations.map((person, index) => {})} */}
                                                  </>
                                                </Accordion.Collapse>
                                              </Accordion>
                                            </>
                                          </Accordion.Collapse>
                                        </Accordion>
                                      </div>
                                    </Accordion.Collapse>
                                    {/* Benefits */}
                                    <Accordion.Collapse
                                      eventKey="0"
                                      className="main-box"
                                    >
                                      <div>
                                        <Accordion defaultActiveKey="2">
                                          <Accordion.Toggle
                                            className="collapse-btn"
                                            as={Button}
                                            variant="link"
                                            eventKey="1"
                                          >
                                            <span className="title">
                                              {" "}
                                              <BiChevronDown /> Benefits
                                            </span>
                                            <div className="before-caps">
                                              <div className="d-flex">
                                                <span className="text-success mr-1">
                                                  $
                                                </span>{" "}
                                                {
                                                  personsBaseHourCalculations.benefits
                                                }
                                              </div>
                                              <div></div>
                                            </div>
                                            <div>
                                              <div className="d-flex">
                                                <span className="text-success mr-1">
                                                  $
                                                </span>{" "}
                                                {
                                                  personTotalAssignmentCalcualtions.benefits
                                                }
                                              </div>
                                              <div></div>
                                            </div>
                                          </Accordion.Toggle>
                                          <Accordion.Collapse
                                            className="main-box"
                                            eventKey="1"
                                          >
                                            <>
                                              {/* Mandatory benefits */}
                                              <Accordion defaultActiveKey="2">
                                                <Accordion.Toggle
                                                  className="collapse-btn"
                                                  as={Button}
                                                  variant="link"
                                                  eventKey="1"
                                                >
                                                  <span className="title">
                                                    {" "}
                                                    <BiChevronDown /> Mandatory
                                                    benefits
                                                  </span>
                                                  <div className="before-caps">
                                                    <div className="d-flex">
                                                      <span className="text-success mr-1">
                                                        $
                                                      </span>{" "}
                                                      {
                                                        personsBaseHourCalculations.mandatory_benefits
                                                      }
                                                    </div>
                                                    <div></div>
                                                  </div>
                                                  <div>
                                                    <div className="d-flex">
                                                      <span className="text-success mr-1">
                                                        $
                                                      </span>{" "}
                                                      {
                                                        personTotalAssignmentCalcualtions.mandatory_benefits
                                                      }
                                                    </div>
                                                    <div></div>
                                                  </div>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse
                                                  className="main-box"
                                                  eventKey="1"
                                                >
                                                  <>
                                                    {/* Healthcare */}
                                                    <div className="inner-table">
                                                      <span className="title">
                                                        Healthcare
                                                      </span>
                                                      <div className="before-caps">
                                                        <div className="d-flex">
                                                          <span className="text-success mr-1">
                                                            $
                                                          </span>{" "}
                                                          {
                                                            personsBaseHourCalculations.healthcare
                                                          }
                                                        </div>
                                                        <div></div>
                                                      </div>
                                                      <div>
                                                        <div className="d-flex">
                                                          <span className="text-success mr-1">
                                                            $
                                                          </span>{" "}
                                                          {
                                                            personTotalAssignmentCalcualtions.healthcare
                                                          }
                                                        </div>
                                                        <div></div>
                                                      </div>
                                                    </div>
                                                    {/* Statutory PTO */}
                                                    <div className="inner-table">
                                                      <span className="title">
                                                        Statutory PTO
                                                      </span>
                                                      <div className="before-caps">
                                                        <div className="d-flex">
                                                          <span className="text-success mr-1">
                                                            $
                                                          </span>{" "}
                                                          {
                                                            personsBaseHourCalculations.statutory_pto
                                                          }
                                                        </div>
                                                        <div></div>
                                                      </div>
                                                      <div>
                                                        <div className="d-flex">
                                                          <span className="text-success mr-1">
                                                            $
                                                          </span>{" "}
                                                          {
                                                            personTotalAssignmentCalcualtions.statutory_pto
                                                          }
                                                        </div>
                                                        <div></div>
                                                      </div>
                                                    </div>
                                                  </>
                                                </Accordion.Collapse>
                                              </Accordion>
                                              {/* Additional benefits */}
                                              <Accordion defaultActiveKey="2">
                                                <Accordion.Toggle
                                                  className="collapse-btn"
                                                  as={Button}
                                                  variant="link"
                                                  eventKey="1"
                                                >
                                                  <span className="title">
                                                    {" "}
                                                    <BiChevronDown /> Additional
                                                    benefits
                                                  </span>
                                                  <div className="before-caps">
                                                    <div className="d-flex">
                                                      <span className="text-success mr-1">
                                                        $
                                                      </span>{" "}
                                                      {
                                                        personsBaseHourCalculations.additional_benefits
                                                      }
                                                    </div>
                                                    <div></div>
                                                  </div>
                                                  <div>
                                                    <div className="d-flex">
                                                      <span className="text-success mr-1">
                                                        $
                                                      </span>{" "}
                                                      {
                                                        personTotalAssignmentCalcualtions.additional_benefits
                                                      }
                                                    </div>
                                                    <div></div>
                                                  </div>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse
                                                  className="main-box"
                                                  eventKey="1"
                                                >
                                                  <>
                                                    {/* PTO/Vacation/Sick */}
                                                    <div className="inner-table">
                                                      <span className="title">
                                                        PTO/Vacation/Sick
                                                      </span>
                                                      <div className="before-caps">
                                                        <div className="d-flex">
                                                          <span className="text-success mr-1">
                                                            $
                                                          </span>{" "}
                                                          {
                                                            personsBaseHourCalculations.pto_vacation_sick
                                                          }
                                                        </div>
                                                        <div></div>
                                                      </div>
                                                      <div>
                                                        <div className="d-flex">
                                                          <span className="text-success mr-1">
                                                            $
                                                          </span>{" "}
                                                          {
                                                            personTotalAssignmentCalcualtions.pto_vacation_sick
                                                          }
                                                        </div>
                                                        <div></div>
                                                      </div>
                                                    </div>
                                                    {/* Holidays */}
                                                    <div className="inner-table">
                                                      <span className="title">
                                                        Holidays
                                                      </span>
                                                      <div className="before-caps">
                                                        <div className="d-flex">
                                                          <span className="text-success mr-1">
                                                            $
                                                          </span>{" "}
                                                          {
                                                            personsBaseHourCalculations.holidays
                                                          }
                                                        </div>
                                                        <div></div>
                                                      </div>
                                                      <div>
                                                        <div className="d-flex">
                                                          <span className="text-success mr-1">
                                                            $
                                                          </span>{" "}
                                                          {
                                                            personTotalAssignmentCalcualtions.holidays
                                                          }
                                                        </div>
                                                        <div></div>
                                                      </div>
                                                    </div>
                                                    {/* 401K */}
                                                    <div className="inner-table">
                                                      <span className="title">
                                                        401K
                                                      </span>
                                                      <div className="before-caps">
                                                        <div className="d-flex">
                                                          <span className="text-success mr-1">
                                                            $
                                                          </span>{" "}
                                                          {
                                                            personsBaseHourCalculations.k_401
                                                          }
                                                        </div>
                                                        <div></div>
                                                      </div>
                                                      <div>
                                                        <div className="d-flex">
                                                          <span className="text-success mr-1">
                                                            $
                                                          </span>{" "}
                                                          {
                                                            personTotalAssignmentCalcualtions.k_401
                                                          }
                                                        </div>
                                                        <div></div>
                                                      </div>
                                                    </div>
                                                  </>
                                                </Accordion.Collapse>
                                              </Accordion>
                                            </>
                                          </Accordion.Collapse>
                                        </Accordion>
                                      </div>
                                    </Accordion.Collapse>
                                    {/* Other Costs */}
                                    <Accordion.Collapse
                                      eventKey="0"
                                      className="main-box"
                                    >
                                      <div>
                                        <Accordion defaultActiveKey="2">
                                          <Accordion.Toggle
                                            className="collapse-btn"
                                            as={Button}
                                            variant="link"
                                            eventKey="1"
                                          >
                                            <span className="title">
                                              {" "}
                                              <BiChevronDown /> Other Costs
                                            </span>
                                            <div className="before-caps">
                                              <div className="d-flex">
                                                <span className="text-success mr-1">
                                                  $
                                                </span>{" "}
                                                {
                                                  personsBaseHourCalculations.other_costs
                                                }
                                              </div>
                                              <div></div>
                                            </div>
                                            <div>
                                              <div className="d-flex">
                                                <span className="text-success mr-1">
                                                  $
                                                </span>{" "}
                                                {
                                                  personTotalAssignmentCalcualtions.other_costs
                                                }
                                              </div>
                                              <div></div>
                                            </div>
                                          </Accordion.Toggle>
                                          <Accordion.Collapse
                                            className="main-box"
                                            eventKey="1"
                                          >
                                            <>
                                              {/* Screening */}
                                              <Accordion defaultActiveKey="2">
                                                <Accordion.Toggle
                                                  className="collapse-btn"
                                                  as={Button}
                                                  variant="link"
                                                  eventKey="1"
                                                >
                                                  <span className="title">
                                                    {" "}
                                                    <BiChevronDown /> Screening
                                                  </span>
                                                  <div className="before-caps">
                                                    <div className="d-flex">
                                                      <span className="text-success mr-1">
                                                        $
                                                      </span>{" "}
                                                      {
                                                        personsBaseHourCalculations.screening
                                                      }
                                                    </div>
                                                    <div></div>
                                                  </div>
                                                  <div>
                                                    <div className="d-flex">
                                                      <span className="text-success mr-1">
                                                        $
                                                      </span>{" "}
                                                      {
                                                        personTotalAssignmentCalcualtions.screening
                                                      }
                                                    </div>
                                                    <div></div>
                                                  </div>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse
                                                  className="main-box"
                                                  eventKey="1"
                                                >
                                                  <>
                                                    {/* Background Check */}
                                                    <div className="inner-table">
                                                      <span className="title">
                                                        Background Check
                                                      </span>
                                                      <div className="before-caps">
                                                        <div className="d-flex">
                                                          <span className="text-success mr-1">
                                                            $
                                                          </span>{" "}
                                                          {
                                                            personsBaseHourCalculations.background_check
                                                          }
                                                        </div>
                                                        <div></div>
                                                      </div>
                                                      <div>
                                                        <div>
                                                          <span className="text-success">
                                                            $
                                                          </span>{" "}
                                                          {
                                                            personTotalAssignmentCalcualtions.background_check
                                                          }
                                                        </div>
                                                        <div></div>
                                                      </div>
                                                    </div>
                                                    {/* Drug Screening */}
                                                    <div className="inner-table">
                                                      <span className="title">
                                                        Drug Screening
                                                      </span>
                                                      <div className="before-caps">
                                                        <div className="d-flex">
                                                          <span className="text-success mr-1">
                                                            $
                                                          </span>{" "}
                                                          {
                                                            personsBaseHourCalculations.drug_screening
                                                          }
                                                        </div>
                                                        <div></div>
                                                      </div>
                                                      <div>
                                                        <div className="d-flex">
                                                          <span className="text-success mr-1">
                                                            $
                                                          </span>{" "}
                                                          {
                                                            personTotalAssignmentCalcualtions.drug_screening
                                                          }
                                                        </div>
                                                        <div></div>
                                                      </div>
                                                    </div>
                                                  </>
                                                </Accordion.Collapse>
                                              </Accordion>
                                              {/* Financing */}
                                              <Accordion defaultActiveKey="2">
                                                <Accordion.Toggle
                                                  className="collapse-btn"
                                                  as={Button}
                                                  variant="link"
                                                  eventKey="1"
                                                >
                                                  <span className="title">
                                                    {" "}
                                                    <BiChevronDown /> Financing
                                                  </span>
                                                  <div className="before-caps">
                                                    <div className="d-flex">
                                                      <span className="text-success mr-1">
                                                        $
                                                      </span>{" "}
                                                      {
                                                        personsBaseHourCalculations.financing
                                                      }
                                                    </div>
                                                    <div></div>
                                                  </div>
                                                  <div>
                                                    <div className="d-flex">
                                                      <span className="text-success mr-1">
                                                        $
                                                      </span>{" "}
                                                      {
                                                        personTotalAssignmentCalcualtions.financing
                                                      }
                                                    </div>
                                                    <div></div>
                                                  </div>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse
                                                  className="main-box"
                                                  eventKey="1"
                                                >
                                                  <>
                                                    {/* Interest */}
                                                    <div className="inner-table">
                                                      <span className="title">
                                                        Interest
                                                      </span>
                                                      <div className="before-caps">
                                                        <div className="d-flex">
                                                          <span className="text-success mr-1">
                                                            $
                                                          </span>{" "}
                                                          {
                                                            personsBaseHourCalculations.interest
                                                          }
                                                        </div>
                                                        <div></div>
                                                      </div>
                                                      <div>
                                                        <div className="d-flex">
                                                          <span className="text-success mr-1">
                                                            $
                                                          </span>{" "}
                                                          {
                                                            personTotalAssignmentCalcualtions.interest
                                                          }
                                                        </div>
                                                        <div></div>
                                                      </div>
                                                    </div>
                                                    {/* MSP Fee */}
                                                    <div className="inner-table">
                                                      <span className="title">
                                                        MSP Fee
                                                      </span>
                                                      <div className="before-caps">
                                                        <div className="d-flex">
                                                          <span className="text-success mr-1">
                                                            $
                                                          </span>{" "}
                                                          {
                                                            personsBaseHourCalculations.msp
                                                          }
                                                        </div>
                                                        <div></div>
                                                      </div>
                                                      <div>
                                                        <div className="d-flex">
                                                          <span className="text-success mr-1">
                                                            $
                                                          </span>{" "}
                                                          {
                                                            personTotalAssignmentCalcualtions.msp
                                                          }
                                                        </div>
                                                        <div></div>
                                                      </div>
                                                    </div>
                                                    {/* VMS Fee */}
                                                    <div className="inner-table">
                                                      <span className="title">
                                                        VMS Fee
                                                      </span>
                                                      <div className="before-caps">
                                                        <div className="d-flex">
                                                          <span className="text-success mr-1">
                                                            $
                                                          </span>{" "}
                                                          {
                                                            personsBaseHourCalculations.vms
                                                          }
                                                        </div>
                                                        <div></div>
                                                      </div>
                                                      <div>
                                                        <div className="d-flex">
                                                          <span className="text-success mr-1">
                                                            $
                                                          </span>{" "}
                                                          {
                                                            personTotalAssignmentCalcualtions.vms
                                                          }
                                                        </div>
                                                        <div></div>
                                                      </div>
                                                    </div>
                                                    {/* Payroll Fee */}
                                                    <div className="inner-table">
                                                      <span className="title">
                                                        Payroll Fee
                                                      </span>
                                                      <div className="before-caps">
                                                        <div className="d-flex">
                                                          <span className="text-success mr-1">
                                                            $
                                                          </span>{" "}
                                                          {
                                                            personsBaseHourCalculations.payrolling_cost
                                                          }
                                                        </div>
                                                        <div></div>
                                                      </div>
                                                      <div>
                                                        <div className="d-flex">
                                                          <span className="text-success mr-1">
                                                            $
                                                          </span>{" "}
                                                          {
                                                            personTotalAssignmentCalcualtions.payrolling_cost
                                                          }
                                                        </div>
                                                        <div></div>
                                                      </div>
                                                    </div>
                                                  </>
                                                </Accordion.Collapse>
                                              </Accordion>
                                              {/* Custom Cost */}
                                              <Accordion defaultActiveKey="2">
                                                <Accordion.Toggle
                                                  className="collapse-btn"
                                                  as={Button}
                                                  variant="link"
                                                  eventKey="1"
                                                >
                                                  <span className="title">
                                                    {" "}
                                                    Custom Cost
                                                  </span>
                                                  <div className="before-caps">
                                                    <div className="d-flex">
                                                      <span className="text-success mr-1">
                                                        $
                                                      </span>{" "}
                                                      {
                                                        personsBaseHourCalculations.custom_cost
                                                      }
                                                    </div>
                                                    <div></div>
                                                  </div>
                                                  <div>
                                                    <div className="d-flex">
                                                      <span className="text-success mr-1">
                                                        $
                                                      </span>{" "}
                                                      {
                                                        personTotalAssignmentCalcualtions.custom_cost
                                                      }
                                                    </div>
                                                    <div></div>
                                                  </div>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse
                                                  className="main-box"
                                                  eventKey="1"
                                                >
                                                  <>
                                                    {personsBaseHourCalculations &&
                                                      personsBaseHourCalculations.custom_cost_info
                                                      ? personsBaseHourCalculations.custom_cost_info.map(
                                                        (item, idx2) => {
                                                          return (
                                                            <div className="inner-table">
                                                              <span className="title">
                                                                {
                                                                  personsBaseHourCalculations
                                                                    .custom_cost_info[
                                                                    idx2
                                                                  ].name
                                                                }
                                                              </span>
                                                              <div className="before-caps">
                                                                <div className="d-flex">
                                                                  <span className="text-success mr-1">
                                                                    $
                                                                  </span>{" "}
                                                                  {
                                                                    personsBaseHourCalculations
                                                                      .custom_cost_info[
                                                                      idx2
                                                                    ].value
                                                                  }
                                                                </div>
                                                                <div></div>
                                                              </div>
                                                              <div>
                                                                <div className="d-flex">
                                                                  <span className="text-success mr-1">
                                                                    $
                                                                  </span>{" "}
                                                                  {
                                                                    personTotalAssignmentCalcualtions
                                                                      .custom_cost_info[
                                                                      idx2
                                                                    ].value
                                                                  }
                                                                </div>
                                                                <div></div>
                                                              </div>
                                                            </div>
                                                          );
                                                        }
                                                      )
                                                      : ""}
                                                    {/* custom_cost */}
                                                  </>
                                                </Accordion.Collapse>
                                              </Accordion>
                                            </>
                                          </Accordion.Collapse>
                                        </Accordion>
                                      </div>
                                    </Accordion.Collapse>
                                  </Accordion>
                                  {/* Total Project Cost */}
                                  <Accordion defaultActiveKey="1">
                                    <Accordion.Toggle
                                      className="collapse-btn"
                                      as={Button}
                                      variant="link"
                                      eventKey="0"
                                    >
                                      <strong>
                                        {" "}
                                        <BiChevronDown /> Total Project Cost
                                      </strong>
                                      <div className="before-caps">
                                        <div className="d-flex">
                                          <span className="text-success mr-1">
                                            $
                                          </span>{" "}
                                          {
                                            personsBaseHourCalculations.total_project_cost
                                          }
                                        </div>
                                        <div></div>
                                      </div>
                                      <div>
                                        <div className="d-flex">
                                          <span className="text-success mr-1">
                                            $
                                          </span>{" "}
                                          {
                                            personTotalAssignmentCalcualtions.total_project_cost
                                          }
                                        </div>
                                        <div></div>
                                      </div>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse
                                      eventKey="0"
                                      className="main-box ml-0"
                                    >
                                      <div>
                                        <div className="inner-table">
                                          <span className="title">
                                            Total Project Cost
                                          </span>
                                          <div className="before-caps">
                                            <div className="d-flex">
                                              <span className="text-success mr-1">
                                                $
                                              </span>{" "}
                                              {
                                                personsBaseHourCalculations.total_project_cost
                                              }
                                            </div>
                                            <div></div>
                                          </div>
                                          <div>
                                            <div className="d-flex">
                                              <span className="text-success mr-1">
                                                $
                                              </span>{" "}
                                              {
                                                personTotalAssignmentCalcualtions.total_project_cost
                                              }
                                            </div>
                                            <div></div>
                                          </div>
                                        </div>
                                      </div>
                                    </Accordion.Collapse>
                                  </Accordion>
                                  {/* Gross Profit */}
                                  <Accordion defaultActiveKey="1">
                                    <Accordion.Toggle
                                      className="collapse-btn"
                                      as={Button}
                                      variant="link"
                                      eventKey="0"
                                    >
                                      <strong>
                                        {" "}
                                        <BiChevronDown /> Gross Profit
                                      </strong>
                                      <div className="before-caps">
                                        <div className="d-flex">
                                          <span className="text-success mr-1">
                                            $
                                          </span>{" "}
                                          {
                                            personsBaseHourCalculations[
                                            "gross_profit"
                                            ]
                                          }
                                        </div>
                                        <div></div>
                                      </div>
                                      <div>
                                        <div className="d-flex">
                                          <span className="text-success mr-1">
                                            $
                                          </span>{" "}
                                          {
                                            personTotalAssignmentCalcualtions[
                                            "gross_profit"
                                            ]
                                          }
                                        </div>
                                        <div></div>
                                      </div>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse
                                      eventKey="0"
                                      className="main-box ml-0"
                                    >
                                      <div>
                                        <div className="inner-table">
                                          <span className="title">
                                            Gross Profit
                                          </span>
                                          <div className="before-caps">
                                            <div className="d-flex">
                                              <span className="text-success mr-1">
                                                $
                                              </span>{" "}
                                              {
                                                personsBaseHourCalculations[
                                                "gross_profit"
                                                ]
                                              }
                                            </div>
                                            <div></div>
                                          </div>
                                          <div>
                                            <div className="d-flex">
                                              <span className="text-success mr-1">
                                                $
                                              </span>{" "}
                                              {
                                                personTotalAssignmentCalcualtions[
                                                "gross_profit"
                                                ]
                                              }
                                            </div>
                                            <div></div>
                                          </div>
                                        </div>
                                      </div>
                                    </Accordion.Collapse>
                                  </Accordion>
                                </Tab>

                                {false ?
                                  <Tab
                                    eventKey="Employer Burden Breakdown"
                                    title="Employer Burden Breakdown"
                                  >
                                    <Tabs
                                      defaultActiveKey="innerTab"
                                      id="uncontrolled-tab-example"
                                      className="mb-3"
                                    >
                                      <Tab
                                        eventKey="innerTab1"
                                        title="Employers Burden"
                                      >
                                        {/* <div className="collapse-header"> */}
                                        <div className="d-flex">

                                          <span>Cost name:</span>
                                          <span>Base hour (%):</span>
                                          <span>Total assigment (%):</span>
                                        </div>

                                        <Accordion defaultActiveKey="1">
                                          <Accordion.Toggle
                                            className="collapse-btn"
                                            as={Button}
                                            variant="link"
                                            eventKey="0"
                                          >
                                            <strong>
                                              {" "}
                                              <BiChevronDown /> Employers Tax
                                            </strong>
                                            <div className="before-caps">
                                              <div className="d-flex">
                                                {nFormatter(
                                                  (
                                                    (parseFloat(
                                                      personsBaseHourCalculations.social_security.replace(
                                                        /,/g,
                                                        ""
                                                      )
                                                    ) /
                                                      parseFloat(
                                                        personsBaseHourCalculations.taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) +
                                                      parseFloat(
                                                        personsBaseHourCalculations.medicare.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) /
                                                      parseFloat(
                                                        personsBaseHourCalculations.taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) +
                                                      parseFloat(
                                                        personsBaseHourCalculations.futa.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) /
                                                      parseFloat(
                                                        personsBaseHourCalculations.taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) +
                                                      parseFloat(
                                                        personsBaseHourCalculations.suta.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) /
                                                      parseFloat(
                                                        personsBaseHourCalculations.taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      )) *
                                                    100
                                                  ).toString(),
                                                  2
                                                )}
                                                <span className="text-success ml-1">
                                                  {" "}
                                                  %{" "}
                                                </span>
                                              </div>
                                              <div></div>
                                            </div>
                                            <div>
                                              <div className="d-flex">
                                                {nFormatter(
                                                  (
                                                    (parseFloat(
                                                      personTotalAssignmentCalcualtions.social_security.replace(
                                                        /,/g,
                                                        ""
                                                      )
                                                    ) /
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) +
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.medicare.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) /
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) +
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.futa.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) /
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) +
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.suta.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) /
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      )) *
                                                    100
                                                  ).toString(),
                                                  2
                                                )}
                                                <span className="text-success ml-1">
                                                  {" "}
                                                  %{" "}
                                                </span>
                                              </div>
                                              <div></div>
                                            </div>
                                          </Accordion.Toggle>
                                          <Accordion.Collapse
                                            eventKey="0"
                                            className="main-box ml-0"
                                          >
                                            <div>
                                              <div className="inner-table">
                                                <span className="title">
                                                  Social Security
                                                </span>
                                                <div className="before-caps">
                                                  <div className="d-flex">
                                                    {nFormatter(
                                                      (
                                                        (parseFloat(
                                                          personsBaseHourCalculations.social_security.replace(
                                                            /,/g,
                                                            ""
                                                          )
                                                        ) /
                                                          parseFloat(
                                                            personsBaseHourCalculations.taxable_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          )) *
                                                        100
                                                      ).toString(),
                                                      2
                                                    )}
                                                    <span className="text-success ml-1">
                                                      {" "}
                                                      %{" "}
                                                    </span>
                                                  </div>
                                                  <div></div>
                                                </div>
                                                <div>
                                                  <div className="d-flex">
                                                    {nFormatter(
                                                      (
                                                        (parseFloat(
                                                          personTotalAssignmentCalcualtions.social_security.replace(
                                                            /,/g,
                                                            ""
                                                          )
                                                        ) /
                                                          parseFloat(
                                                            personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          )) *
                                                        100
                                                      ).toString(),
                                                      2
                                                    )}
                                                    <span className="text-success ml-1">
                                                      {" "}
                                                      %{" "}
                                                    </span>
                                                  </div>
                                                  <div></div>
                                                </div>
                                              </div>
                                            </div>
                                          </Accordion.Collapse>
                                          <Accordion.Collapse
                                            eventKey="0"
                                            className="main-box ml-0"
                                          >
                                            <div>
                                              <div className="inner-table">
                                                <span className="title">
                                                  Medicare
                                                </span>
                                                <div className="before-caps">
                                                  <div className="d-flex">
                                                    <span className="text-success ml-1">
                                                      &nbsp;
                                                    </span>{" "}
                                                    {nFormatter(
                                                      (
                                                        (parseFloat(
                                                          personsBaseHourCalculations.medicare.replace(
                                                            /,/g,
                                                            ""
                                                          )
                                                        ) /
                                                          parseFloat(
                                                            personsBaseHourCalculations.taxable_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          )) *
                                                        100
                                                      ).toString(),
                                                      2
                                                    )}
                                                    <span className="text-success ml-1">
                                                      {" "}
                                                      %{" "}
                                                    </span>
                                                  </div>
                                                  <div></div>
                                                </div>
                                                <div>
                                                  <div className="d-flex">
                                                    {nFormatter(
                                                      (
                                                        (parseFloat(
                                                          personTotalAssignmentCalcualtions.medicare.replace(
                                                            /,/g,
                                                            ""
                                                          )
                                                        ) /
                                                          parseFloat(
                                                            personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          )) *
                                                        100
                                                      ).toString(),
                                                      2
                                                    )}
                                                    <span className="text-success ml-1">
                                                      {" "}
                                                      %{" "}
                                                    </span>
                                                  </div>
                                                  <div></div>
                                                </div>
                                              </div>
                                            </div>
                                          </Accordion.Collapse>
                                          <Accordion.Collapse
                                            eventKey="0"
                                            className="main-box ml-0"
                                          >
                                            <div>
                                              <div className="inner-table">
                                                <span className="title">
                                                  FUTA
                                                </span>
                                                <div className="before-caps">
                                                  <div className="d-flex">
                                                    <span className="text-success ml-1">
                                                      &nbsp;
                                                    </span>{" "}
                                                    {nFormatter(
                                                      (
                                                        (parseFloat(
                                                          personsBaseHourCalculations.futa.replace(
                                                            /,/g,
                                                            ""
                                                          )
                                                        ) /
                                                          parseFloat(
                                                            personsBaseHourCalculations.taxable_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          )) *
                                                        100
                                                      ).toString(),
                                                      2
                                                    )}
                                                    <span className="text-success ml-1">
                                                      {" "}
                                                      %{" "}
                                                    </span>
                                                  </div>
                                                  <div></div>
                                                </div>
                                                <div>
                                                  <div className="d-flex">
                                                    {nFormatter(
                                                      (
                                                        (parseFloat(
                                                          personTotalAssignmentCalcualtions.futa.replace(
                                                            /,/g,
                                                            ""
                                                          )
                                                        ) /
                                                          parseFloat(
                                                            personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          )) *
                                                        100
                                                      ).toString(),
                                                      2
                                                    )}
                                                    <span className="text-success ml-1">
                                                      {" "}
                                                      %{" "}
                                                    </span>
                                                  </div>
                                                  <div></div>
                                                </div>
                                              </div>
                                            </div>
                                          </Accordion.Collapse>
                                          <Accordion.Collapse
                                            eventKey="0"
                                            className="main-box ml-0"
                                          >
                                            <div>
                                              <div className="inner-table">
                                                <span className="title">
                                                  SUTA
                                                </span>
                                                <div className="before-caps">
                                                  <div className="d-flex">
                                                    <span className="text-success ml-1">
                                                      &nbsp;
                                                    </span>{" "}
                                                    {nFormatter(
                                                      (
                                                        (parseFloat(
                                                          personsBaseHourCalculations.suta.replace(
                                                            /,/g,
                                                            ""
                                                          )
                                                        ) /
                                                          parseFloat(
                                                            personsBaseHourCalculations.taxable_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          )) *
                                                        100
                                                      ).toString(),
                                                      2
                                                    )}
                                                    <span className="text-success ml-1">
                                                      {" "}
                                                      %{" "}
                                                    </span>
                                                  </div>
                                                  <div></div>
                                                </div>
                                                <div>
                                                  <div className="d-flex">
                                                    <span className="text-success ml-1"></span>{" "}
                                                    {nFormatter(
                                                      (
                                                        (parseFloat(
                                                          personTotalAssignmentCalcualtions.suta.replace(
                                                            /,/g,
                                                            ""
                                                          )
                                                        ) /
                                                          parseFloat(
                                                            personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          )) *
                                                        100
                                                      ).toString(),
                                                      2
                                                    )}
                                                    <span className="text-success ml-1">
                                                      {" "}
                                                      %{" "}
                                                    </span>
                                                  </div>
                                                  <div></div>
                                                </div>
                                              </div>
                                            </div>
                                          </Accordion.Collapse>
                                          <Accordion.Toggle
                                            className="collapse-btn"
                                            as={Button}
                                            variant="link"
                                            eventKey="1"
                                          >
                                            <strong>
                                              {" "}
                                              <BiChevronDown /> Employers Risk
                                            </strong>
                                            <div className="before-caps">
                                              <div className="d-flex">
                                                <span className="text-success ml-1"></span>{" "}
                                                {nFormatter(
                                                  (
                                                    (parseFloat(
                                                      personsBaseHourCalculations.workers_comp.replace(
                                                        /,/g,
                                                        ""
                                                      )
                                                    ) /
                                                      parseFloat(
                                                        personsBaseHourCalculations.taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) +
                                                      parseFloat(
                                                        personsBaseHourCalculations.other_insurance.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) /
                                                      parseFloat(
                                                        personsBaseHourCalculations.taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      )) *
                                                    100
                                                  ).toString(),
                                                  2
                                                )}
                                                <span className="text-success ml-1">
                                                  {" "}
                                                  %{" "}
                                                </span>
                                              </div>
                                              <div></div>
                                            </div>
                                            <div>
                                              <div className="d-flex">
                                                <span className="text-success ml-1"></span>{" "}
                                                {nFormatter(
                                                  (
                                                    (parseFloat(
                                                      personTotalAssignmentCalcualtions.workers_comp.replace(
                                                        /,/g,
                                                        ""
                                                      )
                                                    ) /
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) +
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.other_insurance.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) /
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      )) *
                                                    100
                                                  ).toString(),
                                                  2
                                                )}
                                                <span className="text-success ml-1">
                                                  {" "}
                                                  %{" "}
                                                </span>
                                              </div>
                                              <div></div>
                                            </div>
                                          </Accordion.Toggle>
                                          <Accordion.Collapse
                                            eventKey="1"
                                            className="main-box ml-0"
                                          >
                                            <div>
                                              <div className="inner-table">
                                                <span className="title">
                                                  Workers comp
                                                </span>
                                                <div className="before-caps">
                                                  <div className="d-flex">
                                                    {nFormatter(
                                                      (
                                                        (parseFloat(
                                                          personsBaseHourCalculations.workers_comp.replace(
                                                            /,/g,
                                                            ""
                                                          )
                                                        ) /
                                                          parseFloat(
                                                            personsBaseHourCalculations.taxable_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          )) *
                                                        100
                                                      ).toString(),
                                                      2
                                                    )}
                                                    <span className="text-success ml-1">
                                                      {" "}
                                                      %{" "}
                                                    </span>
                                                  </div>
                                                  <div></div>
                                                </div>
                                                <div>
                                                  <div className="d-flex">
                                                    {nFormatter(
                                                      (
                                                        (parseFloat(
                                                          personTotalAssignmentCalcualtions.workers_comp.replace(
                                                            /,/g,
                                                            ""
                                                          )
                                                        ) /
                                                          parseFloat(
                                                            personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          )) *
                                                        100
                                                      ).toString(),
                                                      2
                                                    )}
                                                    <span className="text-success ml-1">
                                                      {" "}
                                                      %{" "}
                                                    </span>
                                                  </div>
                                                  <div></div>
                                                </div>
                                              </div>
                                            </div>
                                          </Accordion.Collapse>
                                          <Accordion.Collapse
                                            eventKey="1"
                                            className="main-box ml-0"
                                          >
                                            <div>
                                              <div className="inner-table">
                                                <span className="title">
                                                  Insurance
                                                </span>
                                                <div className="before-caps">
                                                  <div className="d-flex">
                                                    <span className="text-success ml-1">
                                                      &nbsp;
                                                    </span>{" "}
                                                    {nFormatter(
                                                      (
                                                        (parseFloat(
                                                          personsBaseHourCalculations.other_insurance.replace(
                                                            /,/g,
                                                            ""
                                                          )
                                                        ) /
                                                          parseFloat(
                                                            personsBaseHourCalculations.taxable_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          )) *
                                                        100
                                                      ).toString(),
                                                      2
                                                    )}
                                                    <span className="text-success ml-1">
                                                      {" "}
                                                      %{" "}
                                                    </span>
                                                  </div>
                                                  <div></div>
                                                </div>
                                                <div>
                                                  <div className="d-flex">
                                                    {nFormatter(
                                                      (
                                                        (parseFloat(
                                                          personTotalAssignmentCalcualtions.other_insurance.replace(
                                                            /,/g,
                                                            ""
                                                          )
                                                        ) /
                                                          parseFloat(
                                                            personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          )) *
                                                        100
                                                      ).toString(),
                                                      2
                                                    )}
                                                    <span className="text-success ml-1">
                                                      {" "}
                                                      %{" "}
                                                    </span>
                                                  </div>
                                                  <div></div>
                                                </div>
                                              </div>
                                            </div>
                                          </Accordion.Collapse>
                                          <Accordion.Toggle
                                            className="collapse-btn"
                                            as={Button}
                                            variant="link"
                                            eventKey="2"
                                          >
                                            <strong>
                                              {" "}
                                              <BiChevronDown /> Benefits
                                            </strong>
                                            <div className="before-caps">
                                              <div className="d-flex">
                                                <span className="text-success ml-1"></span>{" "}
                                                {nFormatter(
                                                  (
                                                    (parseFloat(
                                                      personsBaseHourCalculations.healthcare.replace(
                                                        /,/g,
                                                        ""
                                                      )
                                                    ) /
                                                      parseFloat(
                                                        personsBaseHourCalculations.taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) +
                                                      parseFloat(
                                                        personsBaseHourCalculations.statutory_pto.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) /
                                                      parseFloat(
                                                        personsBaseHourCalculations.taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) +
                                                      parseFloat(
                                                        personsBaseHourCalculations.pto_vacation_sick.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) /
                                                      parseFloat(
                                                        personsBaseHourCalculations.taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) +
                                                      parseFloat(
                                                        personsBaseHourCalculations.k_401.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) /
                                                      parseFloat(
                                                        personsBaseHourCalculations.taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) +
                                                      parseFloat(
                                                        personsBaseHourCalculations.holidays.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) /
                                                      parseFloat(
                                                        personsBaseHourCalculations.taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      )) *
                                                    100
                                                  ).toString(),
                                                  2
                                                )}
                                                <span className="text-success ml-1">
                                                  {" "}
                                                  %{" "}
                                                </span>
                                              </div>
                                              <div></div>
                                            </div>
                                            <div>
                                              <div className="d-flex">
                                                <span className="text-success ml-1"></span>{" "}
                                                {nFormatter(
                                                  (
                                                    (parseFloat(
                                                      personTotalAssignmentCalcualtions.healthcare.replace(
                                                        /,/g,
                                                        ""
                                                      )
                                                    ) /
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) +
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.statutory_pto.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) /
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) +
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.pto_vacation_sick.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) /
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) +
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.k_401.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) /
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) +
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.holidays.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) /
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      )) *
                                                    100
                                                  ).toString(),
                                                  2
                                                )}
                                                <span className="text-success ml-1">
                                                  {" "}
                                                  %{" "}
                                                </span>
                                              </div>
                                              <div></div>
                                            </div>
                                          </Accordion.Toggle>
                                          <Accordion.Collapse
                                            eventKey="2"
                                            className="main-box ml-0"
                                          >
                                            <div>
                                              <div className="inner-table">
                                                <span className="title">
                                                  Healthcare
                                                </span>
                                                <div className="before-caps">
                                                  <div className="d-flex">
                                                    {nFormatter(
                                                      (
                                                        (parseFloat(
                                                          personsBaseHourCalculations.healthcare.replace(
                                                            /,/g,
                                                            ""
                                                          )
                                                        ) /
                                                          parseFloat(
                                                            personsBaseHourCalculations.taxable_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          )) *
                                                        100
                                                      ).toString(),
                                                      2
                                                    )}
                                                    <span className="text-success ml-1">
                                                      {" "}
                                                      %{" "}
                                                    </span>
                                                  </div>
                                                  <div></div>
                                                </div>
                                                <div>
                                                  <div className="d-flex">
                                                    {nFormatter(
                                                      (
                                                        (parseFloat(
                                                          personTotalAssignmentCalcualtions.healthcare.replace(
                                                            /,/g,
                                                            ""
                                                          )
                                                        ) /
                                                          parseFloat(
                                                            personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          )) *
                                                        100
                                                      ).toString(),
                                                      2
                                                    )}
                                                    <span className="text-success ml-1">
                                                      {" "}
                                                      %{" "}
                                                    </span>
                                                  </div>
                                                  <div></div>
                                                </div>
                                              </div>
                                            </div>
                                          </Accordion.Collapse>
                                          <Accordion.Collapse
                                            eventKey="2"
                                            className="main-box ml-0"
                                          >
                                            <div>
                                              <div className="inner-table">
                                                <span className="title">
                                                  Statutory PTO
                                                </span>
                                                <div className="before-caps">
                                                  <div className="d-flex">
                                                    <span className="text-success ml-1">
                                                      &nbsp;
                                                    </span>{" "}
                                                    {nFormatter(
                                                      (
                                                        (parseFloat(
                                                          personsBaseHourCalculations.statutory_pto.replace(
                                                            /,/g,
                                                            ""
                                                          )
                                                        ) /
                                                          parseFloat(
                                                            personsBaseHourCalculations.taxable_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          )) *
                                                        100
                                                      ).toString(),
                                                      2
                                                    )}
                                                    <span className="text-success ml-1">
                                                      {" "}
                                                      %{" "}
                                                    </span>
                                                  </div>
                                                  <div></div>
                                                </div>
                                                <div>
                                                  <div className="d-flex">
                                                    {nFormatter(
                                                      (
                                                        (parseFloat(
                                                          personTotalAssignmentCalcualtions.statutory_pto.replace(
                                                            /,/g,
                                                            ""
                                                          )
                                                        ) /
                                                          parseFloat(
                                                            personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          )) *
                                                        100
                                                      ).toString(),
                                                      2
                                                    )}
                                                    <span className="text-success ml-1">
                                                      {" "}
                                                      %{" "}
                                                    </span>
                                                  </div>
                                                  <div></div>
                                                </div>
                                              </div>
                                            </div>
                                          </Accordion.Collapse>
                                          <Accordion.Collapse
                                            eventKey="2"
                                            className="main-box ml-0"
                                          >
                                            <div>
                                              <div className="inner-table">
                                                <span className="title">
                                                  PTO Vacation Sick
                                                </span>
                                                <div className="before-caps">
                                                  <div className="d-flex">
                                                    <span className="text-success ml-1">
                                                      &nbsp;
                                                    </span>{" "}
                                                    {nFormatter(
                                                      (
                                                        (parseFloat(
                                                          personsBaseHourCalculations.pto_vacation_sick.replace(
                                                            /,/g,
                                                            ""
                                                          )
                                                        ) /
                                                          parseFloat(
                                                            personsBaseHourCalculations.taxable_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          )) *
                                                        100
                                                      ).toString(),
                                                      2
                                                    )}
                                                    <span className="text-success ml-1">
                                                      {" "}
                                                      %{" "}
                                                    </span>
                                                  </div>
                                                  <div></div>
                                                </div>
                                                <div>
                                                  <div className="d-flex">
                                                    {nFormatter(
                                                      (
                                                        (parseFloat(
                                                          personTotalAssignmentCalcualtions.pto_vacation_sick.replace(
                                                            /,/g,
                                                            ""
                                                          )
                                                        ) /
                                                          parseFloat(
                                                            personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          )) *
                                                        100
                                                      ).toString(),
                                                      2
                                                    )}
                                                    <span className="text-success ml-1">
                                                      {" "}
                                                      %{" "}
                                                    </span>
                                                  </div>
                                                  <div></div>
                                                </div>
                                              </div>
                                            </div>
                                          </Accordion.Collapse>
                                          <Accordion.Collapse
                                            eventKey="2"
                                            className="main-box ml-0"
                                          >
                                            <div>
                                              <div className="inner-table">
                                                <span className="title">
                                                  Holidays
                                                </span>
                                                <div className="before-caps">
                                                  <div className="d-flex">
                                                    <span className="text-success ml-1">
                                                      &nbsp;
                                                    </span>{" "}
                                                    {nFormatter(
                                                      (
                                                        (parseFloat(
                                                          personsBaseHourCalculations.holidays.replace(
                                                            /,/g,
                                                            ""
                                                          )
                                                        ) /
                                                          parseFloat(
                                                            personsBaseHourCalculations.taxable_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          )) *
                                                        100
                                                      ).toString(),
                                                      2
                                                    )}
                                                    <span className="text-success ml-1">
                                                      {" "}
                                                      %{" "}
                                                    </span>
                                                  </div>
                                                  <div></div>
                                                </div>
                                                <div>
                                                  <div className="d-flex">
                                                    <span className="text-success ml-1"></span>{" "}
                                                    {nFormatter(
                                                      (
                                                        (parseFloat(
                                                          personTotalAssignmentCalcualtions.holidays.replace(
                                                            /,/g,
                                                            ""
                                                          )
                                                        ) /
                                                          parseFloat(
                                                            personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          )) *
                                                        100
                                                      ).toString(),
                                                      2
                                                    )}
                                                    <span className="text-success ml-1">
                                                      {" "}
                                                      %{" "}
                                                    </span>
                                                  </div>
                                                  <div></div>
                                                </div>
                                              </div>
                                            </div>
                                          </Accordion.Collapse>
                                          <Accordion.Collapse
                                            eventKey="2"
                                            className="main-box ml-0"
                                          >
                                            <div>
                                              <div className="inner-table">
                                                <span className="title">
                                                  401K
                                                </span>
                                                <div className="before-caps">
                                                  <div className="d-flex">
                                                    <span className="text-success ml-1">
                                                      &nbsp;
                                                    </span>{" "}
                                                    {nFormatter(
                                                      (
                                                        (parseFloat(
                                                          personsBaseHourCalculations.k_401.replace(
                                                            /,/g,
                                                            ""
                                                          )
                                                        ) /
                                                          parseFloat(
                                                            personsBaseHourCalculations.taxable_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          )) *
                                                        100
                                                      ).toString(),
                                                      2
                                                    )}
                                                    <span className="text-success ml-1">
                                                      {" "}
                                                      %{" "}
                                                    </span>
                                                  </div>
                                                  <div></div>
                                                </div>
                                                <div>
                                                  <div className="d-flex">
                                                    <span className="text-success ml-1"></span>{" "}
                                                    {nFormatter(
                                                      (
                                                        (parseFloat(
                                                          personTotalAssignmentCalcualtions.k_401.replace(
                                                            /,/g,
                                                            ""
                                                          )
                                                        ) /
                                                          parseFloat(
                                                            personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          )) *
                                                        100
                                                      ).toString(),
                                                      2
                                                    )}
                                                    <span className="text-success ml-1">
                                                      {" "}
                                                      %{" "}
                                                    </span>
                                                  </div>
                                                  <div></div>
                                                </div>
                                              </div>
                                            </div>
                                          </Accordion.Collapse>
                                          <Accordion.Toggle
                                            className="collapse-btn"
                                            as={Button}
                                            variant="link"
                                            eventKey="3"
                                          >
                                            <strong>
                                              {" "}
                                              <BiChevronDown /> Other Costs
                                            </strong>
                                            <div className="before-caps">
                                              <div className="d-flex">
                                                <span className="text-success ml-1"></span>{" "}
                                                {nFormatter(
                                                  (
                                                    (parseFloat(
                                                      personsBaseHourCalculations.screening.replace(
                                                        /,/g,
                                                        ""
                                                      )
                                                    ) /
                                                      parseFloat(
                                                        personsBaseHourCalculations.taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) +
                                                      parseFloat(
                                                        personsBaseHourCalculations.financing.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) /
                                                      parseFloat(
                                                        personsBaseHourCalculations.taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) +
                                                      parseFloat(
                                                        personsBaseHourCalculations.custom_cost.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) /
                                                      parseFloat(
                                                        personsBaseHourCalculations.taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      )) *
                                                    // (parseFloat(personsBaseHourCalculations.total_employers_burden.replace(/,/g, '')) / parseFloat(personsBaseHourCalculations.taxable_wages.replace(/,/g, ''))) +
                                                    // (parseFloat(personsBaseHourCalculations.other_costs.replace(/,/g, '')) / parseFloat(personsBaseHourCalculations.taxable_wages.replace(/,/g, '')))
                                                    100
                                                  ).toString(),
                                                  2
                                                )}
                                                <span className="text-success ml-1">
                                                  {" "}
                                                  %{" "}
                                                </span>
                                              </div>
                                              <div></div>
                                            </div>
                                            <div>
                                              <div className="d-flex">
                                                <span className="text-success ml-1"></span>{" "}
                                                {nFormatter(
                                                  (
                                                    (parseFloat(
                                                      personTotalAssignmentCalcualtions.screening.replace(
                                                        /,/g,
                                                        ""
                                                      )
                                                    ) /
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) +
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.financing.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) /
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) +
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.custom_cost.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) /
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      )) *
                                                    // (parseFloat(personTotalAssignmentCalcualtions.total_employers_burden.replace(/,/g, '')) / parseFloat(personTotalAssignmentCalcualtions.taxable_wages.replace(/,/g, ''))) +
                                                    // (parseFloat(personTotalAssignmentCalcualtions.other_costs.replace(/,/g, '')) / parseFloat(personTotalAssignmentCalcualtions.taxable_wages.replace(/,/g, '')))
                                                    100
                                                  ).toString(),
                                                  2
                                                )}
                                                <span className="text-success ml-1">
                                                  {" "}
                                                  %{" "}
                                                </span>
                                              </div>
                                              <div></div>
                                            </div>
                                          </Accordion.Toggle>
                                          <Accordion.Collapse
                                            eventKey="3"
                                            className="main-box ml-0"
                                          >
                                            <div>
                                              <div className="inner-table">
                                                <span className="title">
                                                  Screening
                                                </span>
                                                <div className="before-caps">
                                                  <div className="d-flex">
                                                    {nFormatter(
                                                      (
                                                        (parseFloat(
                                                          personsBaseHourCalculations.screening.replace(
                                                            /,/g,
                                                            ""
                                                          )
                                                        ) /
                                                          parseFloat(
                                                            personsBaseHourCalculations.taxable_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          )) *
                                                        100
                                                      ).toString(),
                                                      2
                                                    )}
                                                    <span className="text-success ml-1">
                                                      {" "}
                                                      %{" "}
                                                    </span>
                                                  </div>
                                                  <div></div>
                                                </div>
                                                <div>
                                                  <div className="d-flex">
                                                    {nFormatter(
                                                      (
                                                        (parseFloat(
                                                          personTotalAssignmentCalcualtions.screening.replace(
                                                            /,/g,
                                                            ""
                                                          )
                                                        ) /
                                                          parseFloat(
                                                            personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          )) *
                                                        100
                                                      ).toString(),
                                                      2
                                                    )}
                                                    <span className="text-success ml-1">
                                                      {" "}
                                                      %{" "}
                                                    </span>
                                                  </div>
                                                  <div></div>
                                                </div>
                                              </div>
                                            </div>
                                          </Accordion.Collapse>
                                          <Accordion.Collapse
                                            eventKey="3"
                                            className="main-box ml-0"
                                          >
                                            <div>
                                              <div className="inner-table">
                                                <span className="title">
                                                  Financing
                                                </span>
                                                <div className="before-caps">
                                                  <div className="d-flex">
                                                    <span className="text-success ml-1">
                                                      &nbsp;
                                                    </span>{" "}
                                                    {nFormatter(
                                                      (
                                                        (parseFloat(
                                                          personsBaseHourCalculations.financing.replace(
                                                            /,/g,
                                                            ""
                                                          )
                                                        ) /
                                                          parseFloat(
                                                            personsBaseHourCalculations.taxable_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          )) *
                                                        100
                                                      ).toString(),
                                                      2
                                                    )}
                                                    <span className="text-success ml-1">
                                                      {" "}
                                                      %{" "}
                                                    </span>
                                                  </div>
                                                  <div></div>
                                                </div>
                                                <div>
                                                  <div className="d-flex">
                                                    {nFormatter(
                                                      (
                                                        (parseFloat(
                                                          personTotalAssignmentCalcualtions.financing.replace(
                                                            /,/g,
                                                            ""
                                                          )
                                                        ) /
                                                          parseFloat(
                                                            personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          )) *
                                                        100
                                                      ).toString(),
                                                      2
                                                    )}
                                                    <span className="text-success ml-1">
                                                      {" "}
                                                      %{" "}
                                                    </span>
                                                  </div>
                                                  <div></div>
                                                </div>
                                              </div>
                                            </div>
                                          </Accordion.Collapse>
                                          <Accordion.Collapse
                                            eventKey="3"
                                            className="main-box ml-0"
                                          >
                                            <div>
                                              <div className="inner-table">
                                                <span className="title">
                                                  Custom Cost
                                                </span>
                                                <div className="before-caps">
                                                  <div className="d-flex">
                                                    <span className="text-success ml-1">
                                                      &nbsp;
                                                    </span>{" "}
                                                    {nFormatter(
                                                      (
                                                        (parseFloat(
                                                          personsBaseHourCalculations.custom_cost.replace(
                                                            /,/g,
                                                            ""
                                                          )
                                                        ) /
                                                          parseFloat(
                                                            personsBaseHourCalculations.taxable_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          )) *
                                                        100
                                                      ).toString(),
                                                      2
                                                    )}
                                                    <span className="text-success ml-1">
                                                      {" "}
                                                      %{" "}
                                                    </span>
                                                  </div>
                                                  <div></div>
                                                </div>
                                                <div>
                                                  <div className="d-flex">
                                                    {nFormatter(
                                                      (
                                                        (parseFloat(
                                                          personTotalAssignmentCalcualtions.custom_cost.replace(
                                                            /,/g,
                                                            ""
                                                          )
                                                        ) /
                                                          parseFloat(
                                                            personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          )) *
                                                        100
                                                      ).toString(),
                                                      2
                                                    )}
                                                    <span className="text-success ml-1">
                                                      {" "}
                                                      %{" "}
                                                    </span>
                                                  </div>
                                                  <div></div>
                                                </div>
                                              </div>
                                            </div>
                                          </Accordion.Collapse>
                                          <Accordion.Toggle
                                            className="collapse-btn"
                                            as={Button}
                                            variant="link"
                                            eventKey="4"
                                          >
                                            <strong>
                                              {" "}
                                              <BiChevronDown /> Total Employers
                                              Burden
                                            </strong>
                                            <div className="before-caps">
                                              <div className="d-flex">
                                                <span className="text-success ml-1"></span>{" "}
                                                {nFormatter(
                                                  (
                                                    (parseFloat(
                                                      personsBaseHourCalculations.social_security.replace(
                                                        /,/g,
                                                        ""
                                                      )
                                                    ) /
                                                      parseFloat(
                                                        personsBaseHourCalculations.taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) +
                                                      parseFloat(
                                                        personsBaseHourCalculations.medicare.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) /
                                                      parseFloat(
                                                        personsBaseHourCalculations.taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) +
                                                      parseFloat(
                                                        personsBaseHourCalculations.futa.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) /
                                                      parseFloat(
                                                        personsBaseHourCalculations.taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) +
                                                      parseFloat(
                                                        personsBaseHourCalculations.suta.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) /
                                                      parseFloat(
                                                        personsBaseHourCalculations.taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) +
                                                      parseFloat(
                                                        personsBaseHourCalculations.workers_comp.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) /
                                                      parseFloat(
                                                        personsBaseHourCalculations.taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) +
                                                      parseFloat(
                                                        personsBaseHourCalculations.other_insurance.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) /
                                                      parseFloat(
                                                        personsBaseHourCalculations.taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) +
                                                      parseFloat(
                                                        personsBaseHourCalculations.healthcare.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) /
                                                      parseFloat(
                                                        personsBaseHourCalculations.taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) +
                                                      parseFloat(
                                                        personsBaseHourCalculations.statutory_pto.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) /
                                                      parseFloat(
                                                        personsBaseHourCalculations.taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) +
                                                      parseFloat(
                                                        personsBaseHourCalculations.pto_vacation_sick.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) /
                                                      parseFloat(
                                                        personsBaseHourCalculations.taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) +
                                                      parseFloat(
                                                        personsBaseHourCalculations.k_401.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) /
                                                      parseFloat(
                                                        personsBaseHourCalculations.taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) +
                                                      parseFloat(
                                                        personsBaseHourCalculations.holidays.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) /
                                                      parseFloat(
                                                        personsBaseHourCalculations.taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) +
                                                      parseFloat(
                                                        personsBaseHourCalculations.screening.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) /
                                                      parseFloat(
                                                        personsBaseHourCalculations.taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) +
                                                      parseFloat(
                                                        personsBaseHourCalculations.financing.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) /
                                                      parseFloat(
                                                        personsBaseHourCalculations.taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) +
                                                      parseFloat(
                                                        personsBaseHourCalculations.custom_cost.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) /
                                                      parseFloat(
                                                        personsBaseHourCalculations.taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      )) *
                                                    // (parseFloat(personsBaseHourCalculations.total_employers_burden.replace(/,/g, '')) / parseFloat(personsBaseHourCalculations.taxable_wages.replace(/,/g, ''))) +
                                                    // (parseFloat(personsBaseHourCalculations.other_costs.replace(/,/g, '')) / parseFloat(personsBaseHourCalculations.taxable_wages.replace(/,/g, '')))
                                                    100
                                                  ).toString(),
                                                  2
                                                )}
                                                <span className="text-success ml-1">
                                                  {" "}
                                                  %{" "}
                                                </span>
                                              </div>
                                              <div></div>
                                            </div>
                                            <div>
                                              <div className="d-flex">
                                                <span className="text-success ml-1"></span>{" "}
                                                {nFormatter(
                                                  (
                                                    (parseFloat(
                                                      personTotalAssignmentCalcualtions.social_security.replace(
                                                        /,/g,
                                                        ""
                                                      )
                                                    ) /
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) +
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.medicare.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) /
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) +
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.futa.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) /
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) +
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.suta.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) /
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) +
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.workers_comp.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) /
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) +
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.other_insurance.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) /
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) +
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.healthcare.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) /
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) +
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.statutory_pto.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) /
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) +
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.pto_vacation_sick.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) /
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) +
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.k_401.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) /
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) +
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.holidays.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) /
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) +
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.screening.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) /
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) +
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.financing.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) /
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) +
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.custom_cost.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) /
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      )) *
                                                    // (parseFloat(personTotalAssignmentCalcualtions.total_employers_burden.replace(/,/g, '')) / parseFloat(personTotalAssignmentCalcualtions.taxable_wages.replace(/,/g, ''))) +
                                                    // (parseFloat(personTotalAssignmentCalcualtions.other_costs.replace(/,/g, '')) / parseFloat(personTotalAssignmentCalcualtions.taxable_wages.replace(/,/g, '')))
                                                    100
                                                  ).toString(),
                                                  2
                                                )}
                                                <span className="text-success ml-1">
                                                  {" "}
                                                  %{" "}
                                                </span>
                                              </div>
                                              <div></div>
                                            </div>
                                          </Accordion.Toggle>
                                          <Accordion.Collapse
                                            eventKey="4"
                                            className="main-box ml-0"
                                          >
                                            <div>
                                              <div className="inner-table">
                                                <span className="title">
                                                  Total Employers Burden
                                                </span>
                                                <div className="before-caps">
                                                  <div className="d-flex">
                                                    {nFormatter(
                                                      (
                                                        (parseFloat(
                                                          personsBaseHourCalculations.social_security.replace(
                                                            /,/g,
                                                            ""
                                                          )
                                                        ) /
                                                          parseFloat(
                                                            personsBaseHourCalculations.taxable_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ) +
                                                          parseFloat(
                                                            personsBaseHourCalculations.medicare.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ) /
                                                          parseFloat(
                                                            personsBaseHourCalculations.taxable_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ) +
                                                          parseFloat(
                                                            personsBaseHourCalculations.futa.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ) /
                                                          parseFloat(
                                                            personsBaseHourCalculations.taxable_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ) +
                                                          parseFloat(
                                                            personsBaseHourCalculations.suta.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ) /
                                                          parseFloat(
                                                            personsBaseHourCalculations.taxable_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ) +
                                                          parseFloat(
                                                            personsBaseHourCalculations.workers_comp.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ) /
                                                          parseFloat(
                                                            personsBaseHourCalculations.taxable_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ) +
                                                          parseFloat(
                                                            personsBaseHourCalculations.other_insurance.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ) /
                                                          parseFloat(
                                                            personsBaseHourCalculations.taxable_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ) +
                                                          parseFloat(
                                                            personsBaseHourCalculations.healthcare.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ) /
                                                          parseFloat(
                                                            personsBaseHourCalculations.taxable_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ) +
                                                          parseFloat(
                                                            personsBaseHourCalculations.statutory_pto.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ) /
                                                          parseFloat(
                                                            personsBaseHourCalculations.taxable_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ) +
                                                          parseFloat(
                                                            personsBaseHourCalculations.pto_vacation_sick.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ) /
                                                          parseFloat(
                                                            personsBaseHourCalculations.taxable_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ) +
                                                          parseFloat(
                                                            personsBaseHourCalculations.k_401.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ) /
                                                          parseFloat(
                                                            personsBaseHourCalculations.taxable_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ) +
                                                          parseFloat(
                                                            personsBaseHourCalculations.holidays.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ) /
                                                          parseFloat(
                                                            personsBaseHourCalculations.taxable_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ) +
                                                          parseFloat(
                                                            personsBaseHourCalculations.screening.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ) /
                                                          parseFloat(
                                                            personsBaseHourCalculations.taxable_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ) +
                                                          parseFloat(
                                                            personsBaseHourCalculations.financing.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ) /
                                                          parseFloat(
                                                            personsBaseHourCalculations.taxable_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ) +
                                                          parseFloat(
                                                            personsBaseHourCalculations.custom_cost.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ) /
                                                          parseFloat(
                                                            personsBaseHourCalculations.taxable_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          )) *
                                                        // (parseFloat(personsBaseHourCalculations.total_employers_burden.replace(/,/g, '')) / parseFloat(personsBaseHourCalculations.taxable_wages.replace(/,/g, ''))) +
                                                        // (parseFloat(personsBaseHourCalculations.other_costs.replace(/,/g, '')) / parseFloat(personsBaseHourCalculations.taxable_wages.replace(/,/g, '')))
                                                        100
                                                      ).toString(),
                                                      2
                                                    )}
                                                    <span className="text-success ml-1">
                                                      {" "}
                                                      %{" "}
                                                    </span>
                                                  </div>
                                                  <div></div>
                                                </div>
                                                <div>
                                                  <div className="d-flex">
                                                    {nFormatter(
                                                      (
                                                        (parseFloat(
                                                          personTotalAssignmentCalcualtions.social_security.replace(
                                                            /,/g,
                                                            ""
                                                          )
                                                        ) /
                                                          parseFloat(
                                                            personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ) +
                                                          parseFloat(
                                                            personTotalAssignmentCalcualtions.medicare.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ) /
                                                          parseFloat(
                                                            personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ) +
                                                          parseFloat(
                                                            personTotalAssignmentCalcualtions.futa.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ) /
                                                          parseFloat(
                                                            personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ) +
                                                          parseFloat(
                                                            personTotalAssignmentCalcualtions.suta.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ) /
                                                          parseFloat(
                                                            personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ) +
                                                          parseFloat(
                                                            personTotalAssignmentCalcualtions.workers_comp.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ) /
                                                          parseFloat(
                                                            personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ) +
                                                          parseFloat(
                                                            personTotalAssignmentCalcualtions.other_insurance.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ) /
                                                          parseFloat(
                                                            personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ) +
                                                          parseFloat(
                                                            personTotalAssignmentCalcualtions.healthcare.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ) /
                                                          parseFloat(
                                                            personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ) +
                                                          parseFloat(
                                                            personTotalAssignmentCalcualtions.statutory_pto.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ) /
                                                          parseFloat(
                                                            personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ) +
                                                          parseFloat(
                                                            personTotalAssignmentCalcualtions.pto_vacation_sick.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ) /
                                                          parseFloat(
                                                            personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ) +
                                                          parseFloat(
                                                            personTotalAssignmentCalcualtions.k_401.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ) /
                                                          parseFloat(
                                                            personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ) +
                                                          parseFloat(
                                                            personTotalAssignmentCalcualtions.holidays.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ) /
                                                          parseFloat(
                                                            personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ) +
                                                          parseFloat(
                                                            personTotalAssignmentCalcualtions.screening.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ) /
                                                          parseFloat(
                                                            personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ) +
                                                          parseFloat(
                                                            personTotalAssignmentCalcualtions.financing.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ) /
                                                          parseFloat(
                                                            personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ) +
                                                          parseFloat(
                                                            personTotalAssignmentCalcualtions.custom_cost.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ) /
                                                          parseFloat(
                                                            personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          )) *
                                                        // (parseFloat(personTotalAssignmentCalcualtions.total_employers_burden.replace(/,/g, '')) / parseFloat(personTotalAssignmentCalcualtions.taxable_wages.replace(/,/g, ''))) +
                                                        // (parseFloat(personTotalAssignmentCalcualtions.other_costs.replace(/,/g, '')) / parseFloat(personTotalAssignmentCalcualtions.taxable_wages.replace(/,/g, '')))
                                                        100
                                                      ).toString(),
                                                      2
                                                    )}
                                                    <span className="text-success ml-1">
                                                      {" "}
                                                      %{" "}
                                                    </span>
                                                  </div>
                                                  <div></div>
                                                </div>
                                              </div>
                                            </div>
                                          </Accordion.Collapse>
                                          {/* <Accordion.Collapse
                                          eventKey="3"
                                          className="main-box ml-0"
                                        >
                                          <div>
                                            <div className="inner-table">
                                              <span className="title">Other Costs</span>
                                              <div className="before-caps">
                                                <div className="d-flex">
                                                  <span className="text-success ml-1">
                                                    &nbsp;
                                                  </span>{" "}
                                                  { nFormatter((parseFloat(personsBaseHourCalculations.other_costs.replace(/,/g, '')) / parseFloat(personsBaseHourCalculations.taxable_wages.replace(/,/g, ''))).toString(),3)}
                                                    <span className="text-success ml-1"> % </span>
                                                </div>
                                                <div></div>
                                              </div>
                                              <div>
                                                <div className="d-flex">
                                                  <span className="text-success ml-1">
                                                    
                                                  </span>{" "}
                                                  { nFormatter((parseFloat(personTotalAssignmentCalcualtions.other_costs.replace(/,/g, '')) / parseFloat(personTotalAssignmentCalcualtions.taxable_wages.replace(/,/g, ''))).toString(),3)}
                                                    <span className="text-success ml-1"> % </span>
                                                </div>
                                                <div></div>
                                              </div>
                                            </div>
                                          </div>
                                          </Accordion.Collapse>
                                          <Accordion.Collapse
                                          eventKey="3"
                                          className="main-box ml-0"
                                        >
                                          <div>
                                            <div className="inner-table">
                                              <span className="title">Employers Burden</span>
                                              <div className="before-caps">
                                                <div className="d-flex">
                                                  <span className="text-success ml-1">
                                                    &nbsp;
                                                  </span>{" "}
                                                  { nFormatter((parseFloat(personsBaseHourCalculations.total_employers_burden.replace(/,/g, '')) / parseFloat(personsBaseHourCalculations.taxable_wages.replace(/,/g, ''))).toString(),3)}
                                                    <span className="text-success ml-1"> % </span>
                                                </div>
                                                <div></div>
                                              </div>
                                              <div>
                                                <div className="d-flex">
                                                  <span className="text-success ml-1">
                                                    
                                                  </span>{" "}
                                                  { nFormatter((parseFloat(personTotalAssignmentCalcualtions.total_employers_burden.replace(/,/g, '')) / parseFloat(personTotalAssignmentCalcualtions.taxable_wages.replace(/,/g, ''))).toString(),3)}
                                                    <span className="text-success ml-1"> % </span>
                                                </div>
                                                <div></div>
                                              </div>
                                            </div>
                                          </div>
                                          </Accordion.Collapse> */}
                                        </Accordion>
                                      </Tab>
                                      <Tab eventKey="innerTab" title="Profit">
                                        {/* <div className="collapse-header"> */}
                                        <div className="d-flex">

                                          <span>Cost name:</span>
                                          <span>Base hour (%):</span>
                                          <span>Total assigment (%):</span>
                                        </div>
                                        <Accordion defaultActiveKey="1">
                                          <Accordion.Toggle
                                            className="collapse-btn"
                                            as={Button}
                                            variant="link"
                                            eventKey="0"
                                          >
                                            <strong>
                                              {" "}
                                              <BiChevronDown /> Burdened Wages
                                              Cost
                                            </strong>
                                            <div className="before-caps">
                                              <div className="d-flex">
                                                <span className="text-success ml-1"></span>{" "}
                                                {nFormatter(
                                                  (
                                                    (parseFloat(
                                                      personsBaseHourCalculations.taxable_wages.replace(
                                                        /,/g,
                                                        ""
                                                      )
                                                    ) /
                                                      parseFloat(
                                                        personsBaseHourCalculations.revenue.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) +
                                                      parseFloat(
                                                        personsBaseHourCalculations.non_taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) /
                                                      parseFloat(
                                                        personsBaseHourCalculations.revenue.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) +
                                                      parseFloat(
                                                        personsBaseHourCalculations.total_employers_burden.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) /
                                                      parseFloat(
                                                        personsBaseHourCalculations.revenue.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      )) *
                                                    100
                                                  ).toString(),
                                                  2
                                                )}
                                                <span className="text-success ml-1">
                                                  {" "}
                                                  %{" "}
                                                </span>
                                              </div>
                                              <div></div>
                                            </div>
                                            <div>
                                              <div className="d-flex">
                                                <span className="text-success ml-1"></span>{" "}
                                                {nFormatter(
                                                  (
                                                    (parseFloat(
                                                      personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                        /,/g,
                                                        ""
                                                      )
                                                    ) /
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.revenue.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) +
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.non_taxable_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) /
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.revenue.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) +
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.total_employers_burden.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) /
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.revenue.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      )) *
                                                    100
                                                  ).toString(),
                                                  2
                                                )}
                                                <span className="text-success ml-1">
                                                  {" "}
                                                  %{" "}
                                                </span>
                                              </div>
                                              <div></div>
                                            </div>
                                          </Accordion.Toggle>
                                          <Accordion.Collapse
                                            eventKey="0"
                                            className="main-box ml-0"
                                          >
                                            <div>
                                              <div className="inner-table">
                                                <span className="title">
                                                  Taxable
                                                </span>
                                                <div className="before-caps">
                                                  <div className="d-flex">
                                                    {nFormatter(
                                                      (
                                                        (parseFloat(
                                                          personsBaseHourCalculations.taxable_wages.replace(
                                                            /,/g,
                                                            ""
                                                          )
                                                        ) /
                                                          parseFloat(
                                                            personsBaseHourCalculations.revenue.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          )) *
                                                        100
                                                      ).toString(),
                                                      2
                                                    )}
                                                    <span className="text-success ml-1">
                                                      {" "}
                                                      %{" "}
                                                    </span>
                                                  </div>
                                                  <div></div>
                                                </div>
                                                <div>
                                                  <div className="d-flex">
                                                    {nFormatter(
                                                      (
                                                        (parseFloat(
                                                          personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                            /,/g,
                                                            ""
                                                          )
                                                        ) /
                                                          parseFloat(
                                                            personTotalAssignmentCalcualtions.revenue.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          )) *
                                                        100
                                                      ).toString(),
                                                      2
                                                    )}
                                                    <span className="text-success ml-1">
                                                      {" "}
                                                      %{" "}
                                                    </span>
                                                  </div>
                                                  <div></div>
                                                </div>
                                              </div>
                                            </div>
                                          </Accordion.Collapse>
                                          <Accordion.Collapse
                                            eventKey="0"
                                            className="main-box ml-0"
                                          >
                                            <div>
                                              <div className="inner-table">
                                                <span className="title">
                                                  Non taxable
                                                </span>
                                                <div className="before-caps">
                                                  <div className="d-flex">
                                                    {nFormatter(
                                                      (
                                                        (parseFloat(
                                                          personsBaseHourCalculations.non_taxable_wages.replace(
                                                            /,/g,
                                                            ""
                                                          )
                                                        ) /
                                                          parseFloat(
                                                            personsBaseHourCalculations.revenue.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          )) *
                                                        100
                                                      ).toString(),
                                                      2
                                                    )}
                                                    <span className="text-success ml-1">
                                                      {" "}
                                                      %{" "}
                                                    </span>
                                                  </div>
                                                  <div></div>
                                                </div>
                                                <div>
                                                  <div className="d-flex">
                                                    {nFormatter(
                                                      (
                                                        (parseFloat(
                                                          personTotalAssignmentCalcualtions.non_taxable_wages.replace(
                                                            /,/g,
                                                            ""
                                                          )
                                                        ) /
                                                          parseFloat(
                                                            personTotalAssignmentCalcualtions.revenue.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          )) *
                                                        100
                                                      ).toString(),
                                                      2
                                                    )}
                                                    <span className="text-success ml-1">
                                                      {" "}
                                                      %{" "}
                                                    </span>
                                                  </div>
                                                  <div></div>
                                                </div>
                                              </div>
                                            </div>
                                          </Accordion.Collapse>
                                          <Accordion.Collapse
                                            eventKey="0"
                                            className="main-box ml-0"
                                          >
                                            <div>
                                              <div className="inner-table">
                                                <span className="title">
                                                  Employers Burden
                                                </span>
                                                <div className="before-caps">
                                                  <div className="d-flex">
                                                    {nFormatter(
                                                      (
                                                        (parseFloat(
                                                          personsBaseHourCalculations.total_employers_burden.replace(
                                                            /,/g,
                                                            ""
                                                          )
                                                        ) /
                                                          parseFloat(
                                                            personsBaseHourCalculations.revenue.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          )) *
                                                        100
                                                      ).toString(),
                                                      2
                                                    )}
                                                    <span className="text-success ml-1">
                                                      {" "}
                                                      %{" "}
                                                    </span>
                                                  </div>
                                                  <div></div>
                                                </div>
                                                <div>
                                                  <div className="d-flex">
                                                    {nFormatter(
                                                      (
                                                        (parseFloat(
                                                          personTotalAssignmentCalcualtions.total_employers_burden.replace(
                                                            /,/g,
                                                            ""
                                                          )
                                                        ) /
                                                          parseFloat(
                                                            personTotalAssignmentCalcualtions.revenue.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          )) *
                                                        100
                                                      ).toString(),
                                                      2
                                                    )}
                                                    <span className="text-success ml-1">
                                                      {" "}
                                                      %{" "}
                                                    </span>
                                                  </div>
                                                  <div></div>
                                                </div>
                                              </div>
                                            </div>
                                          </Accordion.Collapse>

                                          {/* <Accordion.Toggle
                                            className="collapse-btn"
                                            as={Button}
                                            variant="link"
                                            eventKey="1"
                                          >
                                            <strong>
                                              {" "}
                                              <BiChevronDown /> Employers Burden
                                            </strong>
                                            <div className="before-caps">
                                              <div className="d-flex">
                                                <span className="text-success ml-1">
                                                  
                                                </span>{" "}
                                                { nFormatter(((parseFloat(personsBaseHourCalculations.total_employers_burden.replace(/,/g, '')) / parseFloat(personsBaseHourCalculations.revenue.replace(/,/g, '')))*100).toString(),2)}
                                                    <span className="text-success ml-1"> % </span>
                                              </div>
                                              <div></div>
                                            </div>
                                            <div>
                                              <div className="d-flex">
                                                <span className="text-success ml-1">
                                                  
                                                </span>{" "}
                                                { nFormatter(((parseFloat(personTotalAssignmentCalcualtions.total_employers_burden.replace(/,/g, '')) / parseFloat(personTotalAssignmentCalcualtions.revenue.replace(/,/g, '')))*100).toString(),2)}
                                                    <span className="text-success ml-1"> % </span>
                                              </div>
                                              <div></div>
                                            </div>
                                          </Accordion.Toggle>
                                          <Accordion.Collapse
                                            eventKey="1"
                                            className="main-box ml-0"
                                          >
                                            <div>
                                              <div className="inner-table">
                                                <span className="title">Employers Burden</span>
                                                <div className="before-caps">
                                                  <div className="d-flex">
                                                    { nFormatter(((parseFloat(personsBaseHourCalculations.total_employers_burden.replace(/,/g, '')) / parseFloat(personsBaseHourCalculations.revenue.replace(/,/g, '')))*100).toString(),2)}
                                                    <span className="text-success ml-1"> % </span>
                                                  </div>
                                                  <div></div>
                                                </div>
                                                <div>
                                                  <div className="d-flex">
                                                    { nFormatter(((parseFloat(personTotalAssignmentCalcualtions.total_employers_burden.replace(/,/g, '')) / parseFloat(personTotalAssignmentCalcualtions.revenue.replace(/,/g, '')))*100).toString(),2)}
                                                    <span className="text-success ml-1"> % </span>
                                                  </div>
                                                  <div></div>
                                                </div>
                                              </div>
                                            </div>
                                          </Accordion.Collapse> */}

                                          <Accordion.Toggle
                                            className="collapse-btn"
                                            as={Button}
                                            variant="link"
                                            eventKey="2"
                                          >
                                            <strong>
                                              {" "}
                                              <BiChevronDown /> Gross Profit
                                            </strong>
                                            <div className="before-caps">
                                              <div className="d-flex">
                                                <span className="text-success ml-1"></span>{" "}
                                                {baseGrossProfitPercent}
                                                <span className="text-success ml-1">
                                                  {" "}
                                                  %{" "}
                                                </span>
                                              </div>
                                              <div></div>
                                            </div>
                                            <div>
                                              <div className="d-flex">
                                                <span className="text-success ml-1"></span>{" "}
                                                {grossProfitPercent}
                                                <span className="text-success ml-1">
                                                  {" "}
                                                  %{" "}
                                                </span>
                                              </div>
                                              <div></div>
                                            </div>
                                          </Accordion.Toggle>
                                          <Accordion.Collapse
                                            eventKey="2"
                                            className="main-box ml-0"
                                          >
                                            <div>
                                              <div className="inner-table">
                                                <span className="title">
                                                  Gross Profit
                                                </span>
                                                <div className="before-caps">
                                                  <div className="d-flex">
                                                    <span className="text-success ml-1"></span>{" "}
                                                    {baseGrossProfitPercent}
                                                    <span className="text-success ml-1">
                                                      {" "}
                                                      %{" "}
                                                    </span>
                                                  </div>
                                                  <div></div>
                                                </div>
                                                <div>
                                                  <div className="d-flex">
                                                    {grossProfitPercent}
                                                    <span className="text-success ml-1">
                                                      {" "}
                                                      %{" "}
                                                    </span>
                                                  </div>
                                                  <div></div>
                                                </div>
                                              </div>
                                            </div>
                                          </Accordion.Collapse>

                                          <Accordion.Toggle
                                            className="collapse-btn"
                                            as={Button}
                                            variant="link"
                                            eventKey="3"
                                          >
                                            <strong>
                                              {" "}
                                              <BiChevronDown /> Effective Markup
                                            </strong>
                                            <div className="before-caps">
                                              <div className="d-flex">
                                                <span className="text-success ml-1"></span>{" "}
                                                {nFormatter(
                                                  (
                                                    ((parseFloat(
                                                      personsBaseHourCalculations.revenue.replace(
                                                        /,/g,
                                                        ""
                                                      )
                                                    ) -
                                                      parseFloat(
                                                        personsBaseHourCalculations.total_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      )) /
                                                      parseFloat(
                                                        personsBaseHourCalculations.total_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      )) *
                                                    100
                                                  ).toString(),
                                                  2
                                                )}
                                                <span className="text-success ml-1">
                                                  {" "}
                                                  %{" "}
                                                </span>
                                              </div>
                                              <div></div>
                                            </div>
                                            <div>
                                              <div className="d-flex">
                                                <span className="text-success ml-1"></span>{" "}
                                                {nFormatter(
                                                  (
                                                    ((parseFloat(
                                                      personTotalAssignmentCalcualtions.revenue.replace(
                                                        /,/g,
                                                        ""
                                                      )
                                                    ) -
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.total_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      )) /
                                                      parseFloat(
                                                        personTotalAssignmentCalcualtions.total_wages.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      )) *
                                                    100
                                                  ).toString(),
                                                  2
                                                )}
                                                <span className="text-success ml-1">
                                                  {" "}
                                                  %{" "}
                                                </span>
                                              </div>
                                              <div></div>
                                            </div>
                                          </Accordion.Toggle>
                                          <Accordion.Collapse
                                            eventKey="3"
                                            className="main-box ml-0"
                                          >
                                            <div>
                                              <div className="inner-table">
                                                <span className="title">
                                                  Effective Markup
                                                </span>
                                                <div className="before-caps">
                                                  <div className="d-flex">
                                                    {nFormatter(
                                                      (
                                                        ((parseFloat(
                                                          personsBaseHourCalculations.revenue.replace(
                                                            /,/g,
                                                            ""
                                                          )
                                                        ) -
                                                          parseFloat(
                                                            personsBaseHourCalculations.total_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          )) /
                                                          parseFloat(
                                                            personsBaseHourCalculations.total_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          )) *
                                                        100
                                                      ).toString(),
                                                      2
                                                    )}
                                                    <span className="text-success ml-1">
                                                      {" "}
                                                      %{" "}
                                                    </span>
                                                  </div>
                                                  <div></div>
                                                </div>
                                                <div>
                                                  <div className="d-flex">
                                                    {nFormatter(
                                                      (
                                                        ((parseFloat(
                                                          personTotalAssignmentCalcualtions.revenue.replace(
                                                            /,/g,
                                                            ""
                                                          )
                                                        ) -
                                                          parseFloat(
                                                            personTotalAssignmentCalcualtions.total_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          )) /
                                                          parseFloat(
                                                            personTotalAssignmentCalcualtions.total_wages.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          )) *
                                                        100
                                                      ).toString(),
                                                      2
                                                    )}
                                                    <span className="text-success ml-1">
                                                      {" "}
                                                      %{" "}
                                                    </span>
                                                  </div>
                                                  <div></div>
                                                </div>
                                              </div>
                                            </div>
                                          </Accordion.Collapse>

                                          <Accordion.Toggle
                                            className="collapse-btn"
                                            as={Button}
                                            variant="link"
                                            eventKey="4"
                                          >
                                            <strong>
                                              {" "}
                                              <BiChevronDown /> Total Assignment
                                              Arbitrage
                                            </strong>
                                            <div className="before-caps">
                                              <div className="d-flex">
                                                <span className="text-success ml-1">
                                                  &nbsp;
                                                </span>{" "}
                                                {/* { nFormatter((((parseFloat(personsBaseHourCalculations.revenue.replace(/,/g, '')) - parseFloat(personsBaseHourCalculations.total_wages.replace(/,/g, '')) )/ parseFloat(personsBaseHourCalculations.total_wages.replace(/,/g, '')))*100).toString(),2)}
                                                    <span className="text-success ml-1"> % </span> */}
                                              </div>
                                              <div></div>
                                            </div>
                                            <div>
                                              <div className="d-flex">
                                                <span className="text-success ml-1"></span>{" "}
                                                {nFormatter(
                                                  (
                                                    parseFloat(
                                                      grossProfitPercent
                                                    ) -
                                                    parseFloat(
                                                      baseGrossProfitPercent
                                                    )
                                                  ).toString(),
                                                  2
                                                )}
                                                <span className="text-success ml-1">
                                                  {" "}
                                                  %{" "}
                                                </span>
                                              </div>
                                              <div></div>
                                            </div>
                                          </Accordion.Toggle>
                                          <Accordion.Collapse
                                            eventKey="4"
                                            className="main-box ml-0"
                                          >
                                            <div>
                                              <div className="inner-table">
                                                <span className="title">
                                                  Total Assignment Arbitrage
                                                </span>
                                                <div className="before-caps">
                                                  <div className="d-flex">
                                                    <span className="text-success ml-1">
                                                      &nbsp;
                                                    </span>{" "}
                                                    {/* { nFormatter((((parseFloat(personsBaseHourCalculations.revenue.replace(/,/g, '')) - parseFloat(personsBaseHourCalculations.total_wages.replace(/,/g, '')) )/ parseFloat(personsBaseHourCalculations.total_wages.replace(/,/g, '')))*100).toString(),2)}
                                                    <span className="text-success ml-1"> % </span> */}
                                                  </div>
                                                  <div></div>
                                                </div>
                                                <div>
                                                  <div className="d-flex">
                                                    {nFormatter(
                                                      (
                                                        parseFloat(
                                                          grossProfitPercent
                                                        ) -
                                                        parseFloat(
                                                          baseGrossProfitPercent
                                                        )
                                                      ).toString(),
                                                      2
                                                    )}
                                                    <span className="text-success ml-1">
                                                      {" "}
                                                      %{" "}
                                                    </span>
                                                  </div>
                                                  <div></div>
                                                </div>
                                              </div>
                                            </div>
                                          </Accordion.Collapse>
                                        </Accordion>
                                      </Tab>
                                    </Tabs>
                                  </Tab>

                                  : null}
                                <Tab
                                  eventKey="Employer Burden Breakdown"
                                  title="Employer Burden Breakdown"
                                >
                                  <div className="pt-2 pb-4 px-4 breakdown-section table-overflow-set">
                                    <Table
                                      responsive="sm"
                                      className="mt-2 mb-5 "
                                    >
                                      <thead>
                                        <tr>
                                          <th className="bg-white border-top-0 text-dark">
                                            Tax
                                          </th>
                                          <th className="bg-white border-top-0 text-dark">
                                            Tax Rate
                                          </th>
                                          <th className="bg-white border-top-0 text-dark">
                                            Wage Rate (Max)
                                          </th>
                                          <th className="bg-white border-top-0 text-dark">
                                            Effective Burden %
                                          </th>
                                          <th className="bg-white border-top-0 text-dark">
                                            Effective Burden
                                          </th>
                                          <th className="bg-white border-top-0 text-dark">
                                            Difference
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>Social Security</td>
                                          <td>
                                            <div className="d-inline-block">
                                              {
                                                personsBaseHourCalculations.social_security_rate
                                              }
                                            </div>{" "}
                                            <span className="text-info mr-1">
                                              %
                                            </span>
                                          </td>
                                          <td>
                                            <span className="text-success mr-1">
                                              $
                                            </span>
                                            {
                                              personsBaseHourCalculations.social_security_wage_rate
                                            }
                                          </td>
                                          <td>
                                            {nFormatter(
                                              (
                                                (parseFloat(
                                                  personTotalAssignmentCalcualtions.social_security.replace(
                                                    /,/g,
                                                    ""
                                                  )
                                                ) /
                                                  parseFloat(
                                                    personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                      /,/g,
                                                      ""
                                                    )
                                                  )) *
                                                100
                                              ).toString(),
                                              2
                                            )}
                                            <span className="text-success">
                                              %
                                            </span>
                                          </td>
                                          <td>
                                            <div className="d-flex">
                                              <span className="text-success mr-1">
                                                $
                                              </span>{" "}
                                              {
                                                personTotalAssignmentCalcualtions.social_security
                                              }
                                            </div>
                                          </td>
                                          <td>
                                            <span className="text-success mr-1">
                                              $
                                            </span>

                                            {
                                              personsBaseHourCalculations.social_security_diff
                                            }
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Medicare</td>
                                          <td>
                                            <div className="d-inline-block">
                                              {
                                                personsBaseHourCalculations.medicare_rate
                                              }
                                            </div>{" "}
                                            <span className="text-info mr-1">
                                              %
                                            </span>
                                          </td>
                                          <td>
                                            <span className="text-success mr-1">
                                              $
                                            </span>
                                            {
                                              personsBaseHourCalculations.medicare_wage_rate
                                            }
                                          </td>
                                          <td>
                                            {nFormatter(
                                              (
                                                (parseFloat(
                                                  personTotalAssignmentCalcualtions.medicare.replace(
                                                    /,/g,
                                                    ""
                                                  )
                                                ) /
                                                  parseFloat(
                                                    personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                      /,/g,
                                                      ""
                                                    )
                                                  )) *
                                                100
                                              ).toString(),
                                              2
                                            )}
                                            <span className="text-success">
                                              %
                                            </span>
                                          </td>
                                          <td>
                                            <div className="d-flex">
                                              <span className="text-success mr-1">
                                                $
                                              </span>{" "}
                                              {
                                                personTotalAssignmentCalcualtions.medicare
                                              }
                                            </div>
                                          </td>
                                          <td>
                                            <span className="text-success mr-1">
                                              $
                                            </span>

                                            {
                                              personsBaseHourCalculations.medicare_diff
                                            }
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>FUTA</td>
                                          <td>
                                            <div className="d-inline-block">
                                              {
                                                personsBaseHourCalculations.futa_rate
                                              }
                                            </div>{" "}
                                            <span className="text-info mr-1">
                                              %
                                            </span>
                                          </td>
                                          <td>
                                            <span className="text-success mr-1">
                                              $
                                            </span>
                                            {
                                              personsBaseHourCalculations.futa_wage_rate
                                            }
                                          </td>
                                          <td>
                                            {nFormatter(
                                              (
                                                (parseFloat(
                                                  personTotalAssignmentCalcualtions.futa.replace(
                                                    /,/g,
                                                    ""
                                                  )
                                                ) /
                                                  parseFloat(
                                                    personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                      /,/g,
                                                      ""
                                                    )
                                                  )) *
                                                100
                                              ).toString(),
                                              2
                                            )}
                                            <span className="text-success">
                                              %
                                            </span>
                                          </td>
                                          <td>
                                            <div className="d-flex">
                                              <span className="text-success mr-1">
                                                $
                                              </span>{" "}
                                              {
                                                personTotalAssignmentCalcualtions.futa
                                              }
                                            </div>
                                          </td>
                                          <td>
                                            <span className="text-success mr-1">
                                              $
                                            </span>

                                            {
                                              personsBaseHourCalculations.futa_diff
                                            }
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>SUTA</td>
                                          <td>
                                            <div className="d-inline-block">
                                              {
                                                personsBaseHourCalculations.suta_rate
                                              }
                                            </div>{" "}
                                            <span className="text-info mr-1">
                                              %
                                            </span>
                                          </td>
                                          <td>
                                            <span className="text-success mr-1">
                                              $
                                            </span>
                                            {
                                              personsBaseHourCalculations.suta_wage_rate
                                            }
                                          </td>
                                          <td>
                                            {nFormatter(
                                              (
                                                (parseFloat(
                                                  personTotalAssignmentCalcualtions.suta.replace(
                                                    /,/g,
                                                    ""
                                                  )
                                                ) /
                                                  parseFloat(
                                                    personTotalAssignmentCalcualtions.taxable_wages.replace(
                                                      /,/g,
                                                      ""
                                                    )
                                                  )) *
                                                100
                                              ).toString(),
                                              2
                                            )}
                                            <span className="text-success">
                                              %
                                            </span>
                                          </td>
                                          <td>
                                            <div className="d-flex">
                                              <span className="text-success mr-1">
                                                $
                                              </span>{" "}
                                              {
                                                personTotalAssignmentCalcualtions.suta
                                              }
                                            </div>
                                          </td>
                                          <td>
                                            <span className="text-success mr-1">
                                              $
                                            </span>

                                            {
                                              personsBaseHourCalculations.suta_diff
                                            }
                                          </td>
                                        </tr>
                                      </tbody>
                                    </Table>
                                    <Row>
                                      <Col lg={4}>
                                        <div className="cost-breakdown-data">
                                          <div className="">
                                            <span className="title">
                                              Cost Breakdown
                                            </span>
                                            <div className="filter-graph">
                                              <Select
                                                styles={
                                                  customGraphControlStyles
                                                }
                                                isSearchable={false}
                                                defaultValue={
                                                  filterSelectedOption
                                                }
                                                onChange={(value) => {
                                                  handleSelect(value);
                                                }}
                                                options={costbreakdown_option}
                                              />
                                            </div>
                                          </div>
                                          <ul className="list-unstyled">
                                            <li>
                                              <div>Hours Taxed:</div>
                                              <div>
                                                {/* <span className="text-success mr-1">
                                                  $
                                                </span> */}
                                                {
                                                  personsBaseHourCalculations[
                                                  filterSelectedOption.value +
                                                  "_hours_taxed"
                                                  ]
                                                }
                                              </div>
                                            </li>
                                            <li>
                                              <div>Hours Post-Tax:</div>
                                              <div>
                                                {/* <span className="text-success mr-1">
                                                  $
                                                </span> */}
                                                {
                                                  personsBaseHourCalculations[
                                                  filterSelectedOption.value +
                                                  "_post_hours_taxed"
                                                  ]
                                                }
                                              </div>
                                            </li>
                                            <li>
                                              <div>Total Earnings:</div>
                                              <div>
                                                {/* <span className="text-success mr-1">
                                                  $
                                                </span> */}
                                                {
                                                  personsBaseHourCalculations.total_earnings
                                                }
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </Col>
                                      <Col lg={8}>
                                        {/* <HighchartsReact
                                          highcharts={Highcharts}
                                          options={stackedOption}
                                        /> */}
                                        {personsBaseHourCalculations?.total_earnings&&
                                        <HighchartsReact
                                          highcharts={Highcharts}
                                          options={stackPercentage}
                                        />
}
                                      </Col>
                                    </Row>
                                  </div>
                                </Tab>
                                <Tab
                                  eventKey="Profitability"
                                  title="Profitability"
                                >
                                  <div className="p-4">
                                    <HighchartsReact
                                      highcharts={Highcharts}
                                      options={ProfitablityValue}
                                    />
                                    <div>
                                      
                                      {totalProfitabilityChartAmount?.map(ele => (
                                          ele.name === "Hour" || ele.name === "Day" || ele.name === "Week" || ele.name === "Month" || ele.name === "Assignment"? (
                                            <div key={ele.name} className="d-flex justify-content-between">
                                              {ele.name!="Assignment"?<p className="font-weight-bold">Total {ele.name}</p>:<p className="font-weight-bold">Total Profit</p>}
                                              <p>{ele.total.toLocaleString()}</p>
                                            </div>
                                          ) : null
                                        ))
                                      }


                                    </div>

                                  </div>
                                </Tab>
                              </Tabs>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <p>Loading</p>
                      )}
                    </Col>
                    <Col lg={4}>
                      <div className="card mt-4">
                        <div class="card-title h6 p-4">
                          Total Statutory Cost
                        </div>
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={statutoryChartOpt}
                          ref={chartRef}
                          callback={(chart) => {
                            chartRef.current = chart;
                          }}
                        />
                        <div className="p-4">
                          <ul className="list-unstyled">
                            {categoriesWithData.map(
                              ({ category, value, color }, index) => (
                                <li
                                  key={index}
                                  className="d-flex justify-content-between mb-2"
                                  id={`listItem-${index}`}
                                  onMouseEnter={() => {
                                    const chartSeries =
                                      chartRef.current.chart.series[0];
                                    const pointIndex =
                                      chartSeries.data.findIndex(
                                        (point) => point.name === category
                                      );
                                    if (pointIndex !== -1) {
                                      chartSeries.data[
                                        pointIndex
                                      ].onMouseOver();
                                    }
                                  }}
                                  onMouseLeave={() => {
                                    const chartSeries =
                                      chartRef.current.chart.series[0];
                                    const pointIndex =
                                      chartSeries.data.findIndex(
                                        (point) => point.name === category
                                      );
                                    if (pointIndex !== -1) {
                                      chartSeries.data[pointIndex].onMouseOut();
                                    }
                                  }}
                                >
                                  <div>
                                    <span
                                      style={{
                                        backgroundColor: color,
                                        width: "10px",
                                        height: "10px",
                                        display: "inline-block",
                                        marginRight: "5px",
                                        borderRadius: "50%",
                                      }}
                                    ></span>
                                    {category}:
                                  </div>
                                  <div>${value.toLocaleString()}</div>
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      </div>
                      <div className="card my-4">
                        <div class="card-title h6 p-4">
                          Total Breakdown Cost
                        </div>
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={breakdownChartOpt}
                          ref={chartRef}
                          callback={(chart) => {
                            chartRef.current = chart;
                          }}
                        />
                        <div className="p-4">
                          <ul className="list-unstyled">
                            {categoriesBreakdownWithData.map(
                              ({ category, value, color }, index) => (
                                <li
                                  key={index}
                                  className="d-flex justify-content-between mb-2"
                                  id={`b-listItem-${index}`}
                                  onMouseEnter={() => {
                                    const chartSeries =
                                      chartRef.current.chart.series[0];
                                    const pointIndex =
                                      chartSeries.data.findIndex(
                                        (point) => point.name === category
                                      );
                                    if (pointIndex !== -1) {
                                      chartSeries.data[
                                        pointIndex
                                      ].onMouseOver();
                                    }
                                  }}
                                  onMouseLeave={() => {
                                    const chartSeries =
                                      chartRef.current.chart.series[0];
                                    const pointIndex =
                                      chartSeries.data.findIndex(
                                        (point) => point.name === category
                                      );
                                    if (pointIndex !== -1) {
                                      chartSeries.data[pointIndex].onMouseOut();
                                    }
                                  }}
                                >
                                  <div>
                                    <span
                                      style={{
                                        backgroundColor: color,
                                        width: "10px",
                                        height: "10px",
                                        display: "inline-block",
                                        marginRight: "5px",
                                        borderRadius: "50%",
                                      }}
                                    ></span>
                                    {category}:
                                  </div>
                                  <div>${value.toLocaleString()}</div>
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </Col>
        </Row>
        {isPaymentShowing ? (
          <Payment onClose={handleClosePayment} isShowing={isPaymentShowing} />
        ) : null}
      </Container>

      {/* Modal */}
      <Modal
        size="lg"
        show={demoModalShow}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="survey-modal"
      >
        <Modal.Body>
          <Slider {...surveySettings}>
            <div>
              <div className="image-holder">
                <img src={TestApproved} />
              </div>
              <div className="text-holder">
                Once you finish the calculator process you will be brought to
                the calculation. This is where you will see the breakdown and
                profitable of each person or multiple persons. You can filter by
                persons or states.
              </div>
            </div>
            <div>
              <div className="image-holder">
                <img src={SummaryButton} />
              </div>
              <div className="text-holder">
                At any time to see your selections for the calculation click
                summary. Edit will allow you to change selections. The
                calculation change be downloaded in different formats.
              </div>
            </div>
            <div>
              <div className="image-holder">
                <img src={EmployeeBurden} />
              </div>
              <div className="text-holder">
                Click on a selection of the calculation to further drill into
                the costs.
              </div>
            </div>
            <div>
              <div
                className="image-holder"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <img src={SendApproval} style={{ maxWidth: "150px" }} />
                <img src={Approval} style={{ maxWidth: "198px" }} />
              </div>
              <div className="text-holder">
                Send for approval and the manager of the group will receive a
                notification to review the calculation. The manager can approve
                or decline with commentary.
              </div>
            </div>
            <div>
              <div
                className="image-holder"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <img src={QuoteButton} style={{ maxWidth: "150px" }} />
              </div>
              <div className="text-holder">
                Once approved, a notification will be sent that the calculation
                is ready for quotation.
              </div>
            </div>
            <div>
              <div className="image-holder">
                <img src={CreateQuoteNew} />
              </div>
              <div className="text-holder">
                Enter in the company name, quote number and the states in which
                you are quoting. The quote will be branded with your logo (setup
                in the system settings). Quotes can be manually approved or
                declined. The quote can be sent to the client via email or other
                apps. Client will receive the quote, review, and approve the
                quote via the system.
              </div>
            </div>
          </Slider>
          {showFinishedButton ? (
            <div className="btn-finish" onClick={handleClose}>
              Finish
            </div>
          ) : (
            ""
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {};
};
const mapStateToProps = (state) => {
  return {
    login: state.login.login,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CalculationDetail);
