import React, { useState, useEffect, useReducer } from "react";
import { Container, Row, Tabs, Tab, Nav } from "react-bootstrap";
import { Parallax } from "react-scroll-parallax";
import Fade from "react-reveal/Fade";
import { BiCheckDouble } from "react-icons/bi";
import { NavLink } from "react-router-dom";
import DashImage from "../component/assets/images/learning/dashboard.png";
import TeamPopup from "../component/assets/images/learning/team-popup.png";
import TeamAdded from "../component/assets/images/learning/team-added.png";
import AddPerson from "../component/assets/images/learning/add-person.png";
import CalculationsImage from "../component/assets/images/learning/calculations.png";
import CalOptions from "../component/assets/images/learning/cal-options.png";
import CalList from "../component/assets/images/learning/cal-list.png";
import ButtonImage from "../component/assets/images/learning/buttons.png";
import QuoteBtn from "../component/assets/images/learning/quote-btn.png";
import CreateQuote from "../component/assets/images/learning/create-quote.png";
import AccountImage from "../component/assets/images/learning/account-image.png";
import Footer from "./layout/Footer";
import EnterPay from "../component/assets/images/learning/learning-new/enter-pay.png";
import AssignmentDetails from "../component/assets/images/learning/learning-new/assignment-details.png";
import DrugScreen from "../component/assets/images/learning/learning-new/healthcare-plan.png";
import HeathCare from "../component/assets/images/learning/learning-new/drug-screen.png";

function LearningCenter() {
  const [key, setKey] = useState("getting-started");

  useEffect(() => {
    document.querySelector('html').classList.add('calculation-details-page');
  }, [])

  return (
    <>
      <div className="learning-center">
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <div className="top-header">
            <Container>
              <h1>ReCalc Learning Center</h1>
              <Nav variant="pills">
                <Nav.Item>
                  <Nav.Link eventKey="first">Terms</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second">Calculations</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="third">Resources</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="four">Tutorials</Nav.Link>
                </Nav.Item>
              </Nav>
            </Container>
          </div>
          <div className="content-details">
            <Container>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <ul className="list-unstyled">
                    <li>
                      <h2>Terms</h2>
                      <ul className="list-unstyled information">
                        <li>
                          <strong>SUTA</strong>
                          <p>State employment tax </p>
                        </li>
                        <li>
                          <strong>FUTA</strong>
                          <p>Federal unemployment tax </p>
                        </li>
                        <li>
                          <strong>Wage Cap</strong>
                          <p>
                            The point at which taxation stops on gross wages
                            when hit or exceeded
                          </p>
                        </li>
                        <li>
                          <strong>SPTO</strong>
                          <p>
                            Statutory paid time off also known as sick leave
                            laws{" "}
                          </p>
                        </li>
                        <li>
                          <strong>Pay Frequency</strong>
                          <p>how often a paycheck/payroll is run </p>
                        </li>
                        <li>
                          <strong>Markup</strong>
                          <p>The % increase on pay to get to a bill rate </p>
                        </li>
                        <li>
                          <strong>Stipend Discount</strong>
                          <p>
                            reduction in money paid to worker from total
                            allotment{" "}
                          </p>
                        </li>
                        <li>
                          <strong>Payment Terms</strong>
                          <p>number of days agreed to until client pays </p>
                        </li>
                        <li>
                          <strong>Base Hour</strong>
                          <p>Cost on the first hour of work </p>
                        </li>
                        <li>
                          <strong>Total Assignment</strong>
                          <p>Cost on the total time worked </p>
                        </li>
                        <li>
                          <strong>Employers Burden</strong>
                          <p>total of employer's costs</p>
                        </li>
                        <li>
                          <strong>Revenue</strong>
                          <p>Bill rate or total assignment bill </p>
                        </li>
                        <li>
                          <strong>Gross Profit</strong>
                          <p>earnings on the assignment</p>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <ul className="list-unstyled">
                    <li>
                      <h2>How it's Calculated</h2>
                      <ul className="list-unstyled information">
                        <li>
                          <strong>Revenue </strong>
                          <p>
                            {" "}
                            The bill rate for 1 hour or multiplied by the length
                            in hours for total assignment{" "}
                          </p>
                        </li>
                        <li>
                          <strong>Taxable Wages </strong>
                          <p>
                            {" "}
                            The pay rate for 1 hour or multiplied by the length
                            in hours for total assignment{" "}
                          </p>
                        </li>
                        <li>
                          <strong>Nontaxable Wages </strong>
                          <p> stipends value per hour or in total assignment</p>
                        </li>
                        <li>
                          <strong>Social Security </strong>
                          <p> 6.2% of taxable wages until the yearly cap</p>
                        </li>
                        <li>
                          <strong>Medicare </strong>
                          <p> 1.45% of taxable wages </p>
                        </li>
                        <li>
                          <strong>FUTA </strong>
                          <p>
                            {" "}
                            each state has a FUTA rate. Take that rate on
                            taxable wages only on/up to $7,000
                          </p>
                        </li>
                        <li>
                          <strong>SUTA </strong>
                          <p>
                            {" "}
                            each state has SUTA rate and wage cap. Take the rate
                            against wages only up to the cap.
                          </p>
                        </li>
                        <li>
                          <strong>Workers Compensation </strong>
                          <p>
                            {" "}
                            % of wages or hourly charge in WA for insurance.{" "}
                          </p>
                        </li>
                        <li>
                          <strong>Other insurances </strong>
                          <p> % of revenue or % of wages</p>
                        </li>
                        <li>
                          <strong>Healthcare </strong>
                          <p>
                            {" "}
                            Monthly premium broken down to hourly. If dates are
                            selected it will know if the next month’s healthcare
                            applies.{" "}
                          </p>
                        </li>
                        <li>
                          <strong>SPTO </strong>
                          <p>
                            {" "}
                            accruals are setup by state. Hours worked will
                            accrual a certain number of hours for time off. This
                            is then fully burdened by taxes and other costs.
                          </p>
                        </li>
                        <li>
                          <strong>PTO/Vacation/Sick </strong>
                          <p> Hours of regular wages fully burdened </p>
                        </li>
                        <li>
                          <strong>Holidays </strong>
                          <p> Hours of regular wages fully burdened </p>
                        </li>
                        <li>
                          <strong>401k </strong>
                          <p> % of regular pay </p>
                        </li>
                        <li>
                          <strong>Screening costs </strong>
                          <p> total cost converted to hourly cost </p>
                        </li>
                        <li>
                          <strong>Interest </strong>
                          <p>
                            {" "}
                            the interest % divided by 360 than multiplied by the
                            terms in days. This % will be applied to sum of
                            total wages plus employer’s taxes and risk.{" "}
                          </p>
                        </li>
                        <li>
                          <strong>MSP </strong>
                          <p> % of wages or revenue</p>
                        </li>
                        <li>
                          <strong>VMS </strong>
                          <p> % of wages or revenue</p>
                        </li>
                        <li>
                          <strong>Payroll Fee </strong>
                          <p> % of wages or revenue</p>
                        </li>
                        <li>
                          <strong>Custom Costs </strong>
                          <p>
                            {" "}
                            created costs that can be % of wages/revenue or flat
                            cost in $
                          </p>
                        </li>
                        <li>
                          <strong>Total Project Cost </strong>
                          <p> Total of wages and employer's burden</p>
                        </li>
                      </ul>
                      <div className="bottom-section">
                        <div className="gross-profit">Gross Profit</div>
                        <div className="white">
                          <span>Revenue</span> <span>Wages</span>{" "}
                          <span>Employers Burden</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </Tab.Pane>
                <Tab.Pane eventKey="third">
                  <h2>Resources</h2>
                  <div className="links">
                    <strong>SPTO</strong>
                    <a
                      href="https://sbshrs.adpinfo.com/blog/paid-sick-leave-vs-vacation-vs-pto-what-you-need-to-know"
                      target="_blank"
                    >
                      https://sbshrs.adpinfo.com/blog/paid-sick-leave-vs-vacation-vs-pto-what-you-need-to-know
                    </a>
                    <a
                      href="https://sbshrs.adpinfo.com/blog/paid-sick-leave-vs-vacation-vs-pto-what-you-need-to-know"
                      target="_blank"
                    >
                      https://sbshrs.adpinfo.com/blog/paid-sick-leave-vs-vacation-vs-pto-what-you-need-to-know
                    </a>
                    <a
                      href="https://www.paycor.com/resource-center/articles/pto-payout-laws-by-state/"
                      target="_blank"
                    >
                      https://www.paycor.com/resource-center/articles/pto-payout-laws-by-state/
                    </a>
                    <a
                      href="https://www.xperthr.com/fifty-state-charts/leave-laws-by-state-and-municipality/20973/"
                      target="_blank"
                    >
                      https://www.xperthr.com/fifty-state-charts/leave-laws-by-state-and-municipality/20973/
                    </a>
                  </div>
                  <div className="links">
                    <strong>SUTA Tax</strong>
                    <a
                      href="https://www.paycom.com/resources/blog/suta-taxes-heres-what-you-need-to-know/"
                      target="_blank"
                    >
                      https://www.paycom.com/resources/blog/suta-taxes-heres-what-you-need-to-know/
                    </a>
                    <a
                      href="https://www.123paystubs.com/resources/suta-tax-rate-and-wage-base-limit-by-state"
                      target="_blank"
                    >
                      https://www.123paystubs.com/resources/suta-tax-rate-and-wage-base-limit-by-state
                    </a>
                  </div>
                  <div className="links">
                    <strong>FUTA Tax </strong>
                    <a
                      href="https://www.123paystubs.com/resources/what-is-futa-tax"
                      target="_blank"
                    >
                      https://www.123paystubs.com/resources/what-is-futa-tax
                    </a>
                  </div>
                  <div className="links">
                    <strong>Sales Tax</strong>
                    <a
                      href="https://www.avalara.com/taxrates/en/state-rates.html"
                      target="_blank"
                    >
                      https://www.avalara.com/taxrates/en/state-rates.html
                    </a>
                  </div>
                  <div className="links">
                    <strong>Overtime Regulations</strong>
                    <a
                      href="https://www.wrapbook.com/blog/complete-list-overtime-laws-by-state"
                      target="_blank"
                    >
                      https://www.wrapbook.com/blog/complete-list-overtime-laws-by-state
                    </a>
                    <a
                      href="https://www.minimum-wage.org/overtime"
                      target="_blank"
                    >
                      https://www.minimum-wage.org/overtime
                    </a>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="four">
                  <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="inner-tabs"
                  >
                    <Tab
                      className="inner-tabs-content"
                      eventKey="getting-started"
                      title="Getting Started"
                    >
                      <h2>Getting Started</h2>
                      <iframe
                        className="custom-video"
                        src="https://www.youtube.com/embed/5V-dhyB2z7c"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                      <ul className="steps">
                        <li>
                          Remember to setup your calculator before you try your
                          first calculation.
                        </li>
                        <li>
                          You can find the setup under the account name in the
                          top right under <strong>Settings</strong> {">"}{" "}
                          <strong>Calculator Settings</strong>
                        </li>
                        <li>
                          At a minimum you need to input{" "}
                          <strong>SUTA Rates</strong> and{" "}
                          <strong>Workers Compensation Rates</strong> for those
                          states selected. <br /> You can add each state or mass
                          update using our .csv templates.
                        </li>
                        <li>
                          For <strong>SUTA</strong> click{" "}
                          <strong>Add States {">"} Choose your states </strong>{" "}
                          {">"} and click Done at the bottom.
                        </li>
                        <li>
                          Please enter a rate for each state accordingly {">"}{" "}
                          click continue/update at the bottom.
                        </li>
                        <li>
                          For <strong>Workers Compensation</strong> next to each
                          state on the right click{" "}
                          <div className="button-style">WC settings</div> {">"}{" "}
                          create a nickname for the code <br /> {">"} choose the
                          industry {">"} code {">"} enter rates {">"} add more
                          or click ok to finish.{" "}
                        </li>
                        <li>
                          <strong> SPTO</strong> and{" "}
                          <streong>Sales Tax</streong> are built in. No need to
                          worry. Feel free to add{" "}
                          <strong>Healthcare Plans</strong> and other costs
                        </li>
                        <li>
                          Creating a custom cost in other costs click create{" "}
                          <div className="button-style">custom cost</div> in
                          bottom left {">"} Add cost title {">"} click OK {">"}{" "}
                          scroll down <br /> and find the new cost you created{" "}
                          {">"} click the + add cost {">"} name it {">"} choose
                          industry (maps to WC) {">"} input cost $ or %. <br />
                          You're now ready for your first calculation. Just
                          click{" "}
                          <div className="primary-button-style">
                            Calculator +
                          </div>{" "}
                          button at the top and you're ready to go. The
                          calculator <br /> flow will guide you through the
                          process.{" "}
                        </li>
                      </ul>
                    </Tab>
                    <Tab eventKey="dashboard" title="Dashboard">
                      <h2>Dashboard</h2>
                      <ul className="steps">
                        <li>
                          This is where a company can check their progress.
                          <div
                            className="custom-image"
                            style={{ width: "70%" }}
                          >
                            <img
                              className=""
                              src={DashImage}
                              alt="Dashboard image"
                            />
                          </div>
                          <strong>Actual revenue</strong> is quotes that have
                          been approved. <strong>Projected revenue</strong> is
                          quotes out that have yet to be approved. You can
                          filter <br /> by total, year to date, the last 30 days
                          or the last 7 days.
                          <br />
                        </li>
                        <li>
                          You can create a team{" "}
                          <div className="grey-button-style">+ Create Team</div>{" "}
                          to track your recruiters.
                          <div
                            className="custom-image"
                            style={{ width: "30%" }}
                          >
                            <img
                              className=""
                              src={TeamPopup}
                              alt="Team popup"
                            />
                          </div>
                          Enter in the name of the team in{" "}
                          <strong>Team Name</strong>. You can invite co-workers
                          to the team by entering their email or if they already
                          have a setup account; you can use their name. Inviting
                          team members will change the number of users to your
                          subscription if they are new. It may take a minute to
                          verify that members have been added.
                          <div
                            className="custom-image"
                            style={{ width: "70%" }}
                          >
                            <img src={TeamAdded} alt="Team added" />
                          </div>
                          Once a team has been created you will be able to see
                          their progress here. You can click on the team and
                          drill down from team, all members, or a single member.
                          You can see calculations and quotes associated with
                          the selections made.
                        </li>
                      </ul>
                    </Tab>
                    <Tab eventKey="calculator" title="Calculator">
                      <h2>Calculator</h2>
                      <ul className="steps">
                        <li>
                          Click this button to start a calculation{" "}
                          <div className="primary-button-style">
                            Calculator +
                          </div>
                        </li>
                        <li>
                          Name the calculation so that you know what it is if
                          you return to it later. Add a person or multiple
                          persons.
                          <div
                            className="custom-image"
                            style={{ width: "50%" }}
                          >
                            <img
                              className=""
                              src={AddPerson}
                              alt="Add person popup"
                            />
                          </div>
                          Enter in the job title or name, location, and workers
                          compensation nickname{" "}
                          <strong>(created in the settings)</strong>. If the
                          workers compensation nickname/code is not listed, you
                          can create a new one. The same job can be calculated
                          in multiple states.
                        </li>
                        <li>
                          Enter in the Pay, Bill, and assignment details.
                          <div
                            className="custom-image"
                            style={{ width: "50%" }}
                          >
                            <img
                              className=""
                              src={EnterPay}
                              alt="Enter Pay popup"
                            />
                          </div>
                        </li>
                        <li>
                          If you know the assignment dates, you can enter them,
                          and thecalculator will automatically calculate length
                          of assignment. If not, you can enter in estimated
                          length in weeks, months, or years. Choose a type of
                          work schedule. Full time is equal to 40 hours per
                          week.
                          <div
                            className="custom-image"
                            style={{ width: "50%" }}
                          >
                            <img
                              className=""
                              src={AssignmentDetails}
                              alt="Assignment Details popup"
                            />
                          </div>
                        </li>
                        <li>
                          For any benefits and other costs click on the toggle
                          and enter or select the information you want to add to
                          the calculation.
                          <div
                            className="custom-image"
                            style={{ width: "50%" }}
                          >
                            <img
                              className=""
                              src={HeathCare}
                              alt="Assignment Details popup"
                            />
                          </div>
                          <div
                            className="custom-image"
                            style={{ width: "50%" }}
                          >
                            <img
                              className=""
                              src={DrugScreen}
                              alt="Assignment Details popup"
                            />
                          </div>
                        </li>
                      </ul>
                    </Tab>
                    <Tab eventKey="calculations" title="Calculations">
                      <h2>Calculations</h2>
                      <ul className="steps">
                        <li>
                          Once you finish the calculator process you will be
                          brought to the calculation. This is where you will see
                          the breakdown and profitable of each person or
                          multiple persons. You can filter by persons or states.
                          <div
                            className="custom-image"
                            style={{ width: "70%" }}
                          >
                            <img
                              className=""
                              src={CalculationsImage}
                              alt="Calculations"
                            />
                          </div>
                        </li>
                        <li>
                          At any time to see your selections for the calculation
                          click summary. Edit will allow you to change
                          selections. The calculation change be downloaded in
                          different formats.
                          <div
                            className="custom-image"
                            style={{ width: "40%" }}
                          >
                            <img
                              className=""
                              src={CalOptions}
                              alt="Calculations"
                            />
                          </div>
                        </li>
                        <li>
                          Click on a selection of the calculation to further
                          drill into the costs.
                          <div
                            className="custom-image"
                            style={{ width: "60%" }}
                          >
                            <img
                              className=""
                              src={CalList}
                              alt="Calculations"
                            />
                          </div>
                        </li>
                        <li>
                          Send for approval and the manager of the group will
                          receive a notification to review the calculation. The
                          manager can approve or decline with commentary.
                          <div
                            className="custom-image"
                            style={{ width: "40%" }}
                          >
                            <img
                              className=""
                              src={ButtonImage}
                              alt="Calculations"
                            />
                          </div>
                        </li>
                        <li>
                          Once approved, a notification will be sent that the
                          calculation is ready for quotation.
                          <div
                            className="custom-image"
                            style={{ width: "15%" }}
                          >
                            <img className="" src={QuoteBtn} alt="Quote" />
                          </div>
                        </li>
                        <li>
                          Enter in the company name, quote number and the states
                          in which you are quoting. The quote will be branded
                          with your logo (setup in the system settings). Quotes
                          can be manually approved or declined. The quote can be
                          sent to the client via email or other apps. Client
                          will receive the quote, review, and approve the quote
                          via the system.
                          <div
                            className="custom-image"
                            style={{ width: "50%" }}
                          >
                            <img className="" src={CreateQuote} alt="Quote" />
                          </div>
                        </li>
                        <li>
                          To reach the learning center or settings, just click
                          the username in the top right corner.
                          <div
                            className="custom-image"
                            style={{ width: "15%" }}
                          >
                            <img
                              className=""
                              src={AccountImage}
                              alt="Account Image"
                            />
                          </div>
                        </li>
                      </ul>
                    </Tab>
                  </Tabs>
                </Tab.Pane>
              </Tab.Content>
            </Container>
          </div>
        </Tab.Container>
        {/* <div id="footer" className="footer">
                    <div className="footer-top">
                    <Parallax className="box-holder-1" y={[20, -20]}><div className="box-1"></div></Parallax>
                    <Parallax className="box-holder-2" y={[100, -40]}><div className="box-2"></div></Parallax>
                    <Parallax className="box-holder-3" y={[90, -100]}><div className="box-3"></div></Parallax>
                    <Parallax className="box-holder-4" y={[10, -40]}><div className="box-4"></div></Parallax>
                    <Parallax className="box-holder-5" y={[2, -50]}><div className="box-5"></div></Parallax>
                    <Fade bottom>
                        <strong>Try ReCalc for free</strong>
                    </Fade>
                    <Fade bottom cascade>
                        <ul>
                        <li><BiCheckDouble /> 7 days free trial</li>
                        <li><BiCheckDouble /> No credit card needed</li>
                        <li><BiCheckDouble /> Cancel anytime</li>
                        </ul>
                    </Fade>
                    <Fade>
                        <div className="d-flex custom-width">
                        <NavLink to="/signup" className="btn btn-primary">
                            Try for free
                        </NavLink>
                        <Nav.Link
                            as={NavLink}
                            className="btn btn-light ml-3 d-inline-block"
                            to={"/signin?id="+process.env.REACT_APP_DEMO_ID}
                        >
                            Demo
                        </Nav.Link>
                        </div>
                    </Fade>
                    </div>
                </div> */}
        <Footer />
      </div>
    </>
  );
}

export default LearningCenter;
