import axios from "axios";
import { toast } from "react-toastify";

export const API_URL = process.env.REACT_APP_API_URL;

axios.defaults.headers = {
  "content-type": "application/json",
};
export function cities(data) {
  if (!data) {
    data = 0;
  }
  return axios.get(`${API_URL}/cities?state_id=${data}`);
}
export function get_notifications(data) {
  return axios.get(`${API_URL}/notifications`, getHeaders());
}
export function read_all_notification(data) {
  return axios.get(`${API_URL}/notification/read_all`, getHeaders());
}
export function read_notification(data) {
  return axios.get(`${API_URL}/notification/read/${data.id}`, getHeaders());
}
export function download_cal_file(data) {
  return axios.get(
    `${API_URL}/calculations/download/${data.id}/${data.type}`,
    getHeaders()
  );
}
export function states(data) {
  return axios.get(`${API_URL}/states?country_id=${data}`);
}
export function countries(data) {
  return axios.get(`${API_URL}/countries`, data);
}
export function plans(data) {
  return axios.get(`${API_URL}/plans`, data);
}
export function roles(data) {
  return axios.get(`${API_URL}/roles`, data);
}
export function industry(data) {
  return axios.get(`${API_URL}/industries`, data);
}

export function auth(data) {
  return axios.post(`${API_URL}/auth`, data);
}
export function sign_in(data) {
  return axios.post(`${API_URL}/auth/sign_in`, data);
}
export function sign_in_by_uuid(data) {
  return axios.post(`${API_URL}/auth/sign_in_by_uuid`, data);
}
export function sign_up_by_uuid(data) {
  return axios.post(`${API_URL}/auth/sign_up_by_uuid`, data);
}
export function sign_in_by_invitation(data) {
  return axios.post(`${API_URL}/auth/sign_in_by_invitation`, data);
}
export function sign_auth_invitation(data) {
  return axios.put(`${API_URL}/auth/invitation`, data);
}
export function forgot(data) {
  return axios.post(`${API_URL}/auth/password`, data);
}
export function reset(data) {
  let info = JSON.parse(data);
  const options = {
    headers: info.params,
  };
  console.log("options", options);
  return axios.put(`${API_URL}/auth/password`, data, options);
}
export function company(data) {
  return axios.post(`${API_URL}/organization/create`, data);
}
export function team(data) {
  return axios.post(`${API_URL}/organization/team`, data);
}
export async function delete_team(data) {
  return await axios
    .post(`${API_URL}/organization/team/delete_team`, data, getHeaders())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}

export function resend(data) {
  return axios.post(`${API_URL}/auth/resend_email`, data);
}
export function settings(data) {
  return axios

    .post(`${API_URL}/settings`, data, getHeaders())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}

export function bill_settings(data) {
  return axios
    .post(`${API_URL}/bill_settings`, data, getHeaders())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}

export function update_billing_info(data) {
  return axios
    .post(`${API_URL}/payment_method/billing_info/update`, data, getHeaders())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}
export function billing_info(data) {
  return axios
    .post(`${API_URL}/payment_method/billing_info`, data, getHeaders())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}
export async function company_update(data) {
  return axios
    .post(`${API_URL}/organization/update`, data, getHeaders())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}

export async function payment_method_delete(data) {
  return axios
    .post(`${API_URL}/payment_method/delete_payment_method`, data, getHeaders())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}
export async function payment_method_set_default(data) {
  return axios
    .post(
      `${API_URL}/payment_method/set_default_payment_method`,
      data,
      getHeaders()
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}
export async function payment_method_add(data) {
  return axios
    .post(`${API_URL}/payment_method/add_payment_method`, data, getHeaders())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}
export async function name_update(data) {
  return await axios
    .post(`${API_URL}/user/update_name`, data, getHeaders())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}
export async function password_update(data) {
  return await axios
    .post(`${API_URL}/user/update_password`, data, getHeaders())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}

export async function verify_email_update(data) {
  return await axios
    .post(`${API_URL}/user/verify_email`, data, getHeaders())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}
export async function email_update(data) {
  return await axios
    .post(`${API_URL}/user/update_email`, data, getHeaders())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}

export async function organization_settings(data) {
  return await axios
    .post(`${API_URL}/organization_settings`, data, getHeaders())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}

export async function subscription(data) {
  return await axios
    .post(`${API_URL}/subscription/create`, data, getHeaders())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}
export async function subscriptionUpdate(data) {
  return await axios
    .post(`${API_URL}/subscription/update`, data, getHeaders())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}
export async function subscriptionCancel(data) {
  return await axios
    .post(`${API_URL}/subscription/cancel`, data, getHeaders())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}
export async function subscriptionAddPlan(data) {
  return await axios
    .post(`${API_URL}/subscription/create/payment_method`, data, getHeaders())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}
export async function organization_settings_suta(data) {
  return await axios
    .post(`${API_URL}/organization_settings/suta`, data, getHeaders())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}
export async function organization_settings_import_suta(data) {
  return await axios
    .post(`${API_URL}/organization_settings/import_suta`, data, getHeaders())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}
export async function organization_settings_tax_status(data) {
  return await axios
    .post(`${API_URL}/organization_settings/tax_status`, data, getHeaders())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}
export async function organization_settings_sales_tax(data) {
  return await axios
    .post(`${API_URL}/organization_settings/sales_tax`, data, getHeaders())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}
export async function organization_settings_import_wc(data) {
  return await axios
    .post(`${API_URL}/organization_settings/import_wc`, data, getHeaders())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}
export async function organization_settings_update_spto(data) {
  return await axios
    .post(`${API_URL}/organization_settings/update_spto`, data, getHeaders())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}
export async function organization_settings_wc_settings(data) {
  return await axios
    .post(`${API_URL}/organization_settings/wc_settings`, data, getHeaders())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}
export async function organization_settings_always_accrue(data) {
  return await axios
    .post(`${API_URL}/organization_settings/always_accrue`, data, getHeaders())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}
export async function organization_settings_exempt_sales_tax(data) {
  return await axios
    .post(
      `${API_URL}/organization_settings/exempt_sales_tax`,
      data,
      getHeaders()
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}
export async function organization_settings_package_delete(data) {
  return await axios
    .post(`${API_URL}/organization_settings/package_delete`, data, getHeaders())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}
export async function organization_settings_cost_delete(data) {
  return await axios
    .post(`${API_URL}/organization_settings/cost_delete`, data, getHeaders())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}
export async function organization_settings_package(data) {
  return await axios
    .post(`${API_URL}/organization_settings/package`, data, getHeaders())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}
export async function organization_settings_packages(data) {
  return await axios
    .post(`${API_URL}/organization_settings/packages`, data, getHeaders())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}
export async function organization_settings_update_cost(data) {
  return await axios
    .post(`${API_URL}/organization_settings/update_cost`, data, getHeaders())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}
export async function organization_team_update(data) {
  return await axios
    .post(`${API_URL}/organization_teams/update`, data, getHeaders())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}
export async function organization_team_save(data) {
  return await axios
    .post(`${API_URL}/organization_teams/create`, data, getHeaders())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}
export async function organization_spto_settings(data) {
  return await axios
    .post(`${API_URL}/organization_spto_settings`, data, getHeaders())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}
export async function organization_teams(data) {
  return await axios
    .post(`${API_URL}/organization_teams`, data, getHeaders())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}
export async function organization_team(data) {
  return await axios
    .post(`${API_URL}/organization_team`, data, getHeaders())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}
export async function set_profile_image(data) {
  return await axios
    .post(`${API_URL}/user/image`, data, getHeaders())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}
export async function set_company_image(data) {
  return await axios
    .post(`${API_URL}/organization/change_image`, data, getHeaders())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}
export async function organization_members(data) {
  return await axios
    .post(`${API_URL}/organization/members`, data, getHeaders())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}
export async function remove_member(data) {
  return await axios
    .post(`${API_URL}/organization/members/delete`, data, getHeaders())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}
export async function set_team_logo(data) {
  return await axios
    .post(`${API_URL}/organization_teams/team_logo`, data, getHeaders())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}
export async function calculate_stipend(data) {
  return await axios
    .post(`${API_URL}/organization_team/calculation/stipend`, data, getHeaders())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}
export async function create_calculation(data) {
  return await axios
    .post(`${API_URL}/organization_team/calculation/create`, data, getHeaders())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}
export async function edit_calculation(data) {
  return await axios
    .post(`${API_URL}/organization_team/calculation/update`, data, getHeaders())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}
export async function delete_calculation(data) {
  return await axios
    .post(`${API_URL}/calculations/delete/${data.id}`, getHeaders())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}

export async function get_calculation(data) {
  return await axios
    .post(`${API_URL}/organization_team/calculation`, data, getHeaders())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}
export async function get_calculations(data) {
  return await axios
    .get(
      `${API_URL}/organization_team/calculations/${
        data?.member_id ? data.member_id : ""
      }`,
      getHeaders()
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}
export async function get_quotation(data) {
  return await axios
    .post(`${API_URL}/organization_team/quotation`, data, getHeaders())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}
export async function get_quotation_approve(data) {
  return await axios
    .post(`${API_URL}/quotation/approve`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}
export async function get_quotations(data) {
  return await axios
    .get(
      `${API_URL}/organization_team/quotations/${
        data?.member_id ? data.member_id : ""
      }`,
      getHeaders()
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}
export async function get_approved_quotations(data) {
  return await axios
    .get(
      `${API_URL}/organization_team/approved-quotations/${
        data?.member_id ? data.member_id : ""
      }${data?.filter ? "?filter="+data.filter : ""}`,
      getHeaders()
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}
export async function get_members_approved_quotations(data) {
  return await axios
    .get(
      `${API_URL}/organization_team/members-approved-quotations/${
        data?.member_id ? data.member_id : ""
      }`,
      getHeaders()
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}

export async function team_quotations(data) {
  return await axios
    .post(
      `${API_URL}/organization_team/quotations/`,
      data,
      getHeaders()
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}

export async function calculation_send_to_approval(data) {
  return await axios
    .post(
      `${API_URL}/organization_team/calculation/update/status`,
      data,
      getHeaders()
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}

export async function send_enquiry(data) {
  return await axios
    .post(
      `${API_URL}/send_enquiry`,
      data,
      getHeaders()
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}

export async function send_support(data) {
  return await axios
    .post(
      `${API_URL}/send_support`,
      data,
      getHeaders()
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}

export async function update_quote_status(data) {
  return await axios
    .post(
      `${API_URL}/organization_team/quote/update/status`,
      data,
      getHeaders()
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}
export async function update_quote_status_approve(data) {
  return await axios
    .post(`${API_URL}/quote/update/status`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}

export async function create_quote(data) {
  return await axios
    .post(`${API_URL}/organization_team/quote/create`, data, getHeaders())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}

export async function send_quote_via_email(data) {
  return await axios
    .post(`${API_URL}/organization_team/quote/send`, data, getHeaders())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      checkLogout(error);
      return error.response;
    });
}

function getHeaders() {
  const headers = localStorage.getItem("headers");
  localStorage.removeItem("expire");
  if (headers !== null) {
    return { headers: JSON.parse(headers) };
  }
}
function checkLogout(error) {
  if (typeof error.response === "undefined") {
    window.location.href = process.env.REACT_APP_URL + "/logout";
  }
  if (error && error.response.statusText === "Unauthorized") {
    window.location.href = process.env.REACT_APP_URL + "/logout";
  }
  if (error.response.status === 401) {
    window.location.href = process.env.REACT_APP_URL + "/logout";
  }
  if (
    error.response &&
    error.response.status === 400 &&
    error.response.data &&
    error.response.data.errors &&
    error.response.data.errors.length > 0
  ) {
    if (error.response.data.expire) {
      localStorage.setItem("expire", error.response.data.expire);
    } else {
      toast.error(error.response.data.errors[0]);
    }

    console.log("error.response.data.errors[0]", error.response.data.errors);
  }
  if (
    error.response &&
    error.response.status === 400 &&
    error.response.data &&
    error.response.data.status &&
    error.response.data.status === "error"
  ) {
    toast.error(error.response.data.message);
  }
  console.log("error", error.response);
}
