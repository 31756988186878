import {React, useEffect, useState} from "react";
import { NavLink } from "react-router-dom";
import { Row, Col, Button, Modal, Form } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import Logo from "../assets/images/logo.svg";
import { toast } from "react-toastify";
import {sendEnquiry, sendSupport} from "../data/globalAction";
import SimpleReactValidator from "simple-react-validator";

function Footer() {
  const [validator, setValidator] = useState(new SimpleReactValidator());
  const [showEnquireModal, setShowEnquireModal] = useState(false);
  const [showSupportModal, setShowSupportModal] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [, forceUpdate] = useState();
  const [enquireFormData, setEnquireFormData] = useState({
    name: "",
    companyName: "",
    email: "",
    message: "",
  });
  const [supportFormData, setSupportFormData] = useState({
    name: "",
    company_name: "",
    email: "",
    message: "",
  });

  useEffect(()=>{
    if(window.location.pathname.includes("/support"))
      setShowSupportModal(true);
    },[])

    const handleClose = () => {
      setShowEnquireModal(false);
    }
    
    const handleCloseSupport = () => {
      window.history.replaceState({}, document.title, "/");
      setShowSupportModal(false);
  }

  const handleShow = () => setShowEnquireModal(true);

  const handleEnquireForm = async (e) => {
    if (e === "submit") {
      setDisabled(true);
      if (!validator.allValid()) {
          validator.showMessages();
          forceUpdate(1);
      } else {
        let data = {
          enquireData: enquireFormData,
        };
        let result = await sendEnquiry(JSON.stringify(data));
        if (result.status === 200) {
          setShowEnquireModal(false);
          toast.success("Enquiry Sent Successfully!");
        } else {
          toast.error("Error while Sending Enquiry");
        }       
      }
    } else {
      setEnquireFormData({
        ...enquireFormData,
        [e.target.name]: e.target.value,
      });
    }
    setDisabled(false);
  };

  const handleSupportForm = async (e) => {
    if (e === "submit") {
      setDisabled(true);
      if (!validator.allValid()) {
          validator.showMessages();
          forceUpdate(1);
      } else {
        let data = {
          supportData: supportFormData,
        };
        let result = await sendSupport(JSON.stringify(data));
        if (result.status === 200) {
          toast.success("Support Email sent successfully!");
          handleCloseSupport();
        } else {
          toast.error("Error while sending Support Email.");
        }       
      }
    } else {
      setSupportFormData({
        ...supportFormData,
        [e.target.name]: e.target.value,
      });
    }
    setDisabled(false);
  };

  return (
    <div>

      <div id="footer" className="footer">
        <Row>
          <Col md={3}>
            <div className="company-details">
              <div className="logo">
                <img src={Logo} alt="Logo" />
              </div>
            </div>
          </Col>
          <Col md={3}></Col>
          <Col md={2}>
            <div className="footer-nav">
              <strong>Company</strong>
              <ul>
                <li>
                  <NavLink to="/privacy">
                    Privacy
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/terms">
                    Terms
                  </NavLink>
                </li>
              </ul>
            </div>
          </Col>
          <Col md={2}>
            <div className="footer-nav">
              <strong>Connect</strong>
              <ul>
                <li>
                  <div onClick={handleShow}>
                    Inquiry
                  </div>
                </li>
                <li>
                  <NavLink to="/signin">
                    Login
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/signup">
                    Sign up
                  </NavLink>
                </li>
              </ul>
            </div>
          </Col>
          <Col sm={2}>
            <div className="footer-nav">
              <strong>Media</strong>
              <ul>
                <li>
                  <a href="https://www.youtube.com/channel/UCV9cJqi1j8yvUqfDhb5VLMg" target="_blank">
                    Youtube
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/recalc" target="_blank">
                    Linkedin
                  </a>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <div className="footer-bottom">
          <p>Copyright &copy; 2021 - ReCalc</p>
        </div>
        <Modal show={showEnquireModal} onHide={handleClose}>
          <Modal.Header className="with-title normal" closeButton>
          <Modal.Title>Enquiries</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control 
                name="name"
                type="text" 
                placeholder="Allen Iverson"
                value={enquireFormData.name}
                onChange={handleEnquireForm}  
              />              
              {validator.message(
                "name",
                enquireFormData.name,
                "required",
                {
                  className: "text-danger",
                }
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Company Name</Form.Label>
              <Form.Control 
                name="companyName"
                type="text" 
                placeholder="XYZ Organization" 
                value={enquireFormData.companyName}
                onChange={handleEnquireForm} 
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control 
                name="email"
                type="email" 
                placeholder="alleniverson@gmail.com"
                value={enquireFormData.email}
                onChange={handleEnquireForm}  
              />              
              {validator.message(
                "email",
                enquireFormData.email,
                "required|email",
                {
                  className: "text-danger",
                }
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <Form.Label>Enter Message</Form.Label>
              <Form.Control 
                name="message"
                as="textarea" 
                rows={3}
                value={enquireFormData.message}
                onChange={handleEnquireForm} 
              />             
              {validator.message(
                "message",
                enquireFormData.message,
                "required",
                {
                  className: "text-danger",
                }
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-light" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={() => handleEnquireForm("submit") } disabled={disabled} >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showSupportModal} onHide={handleCloseSupport}>
          <Modal.Header className="with-title normal" closeButton>
          <Modal.Title>Support</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control 
                name="name"
                type="text" 
                placeholder="Allen Iverson"
                value={supportFormData.name}
                onChange={handleSupportForm}  
              />              
              {validator.message(
                "name",
                supportFormData.name,
                "required",
                {
                  className: "text-danger",
                }
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Company Name</Form.Label>
              <Form.Control 
                name="company_name"
                type="text" 
                placeholder="XYZ Organization" 
                value={supportFormData.company_name}
                onChange={handleSupportForm} 
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control 
                name="email"
                type="email" 
                placeholder="alleniverson@gmail.com"
                value={supportFormData.email}
                onChange={handleSupportForm}  
              />              
              {validator.message(
                "email",
                supportFormData.email,
                "required|email",
                {
                  className: "text-danger",
                }
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <Form.Label>Enter Message</Form.Label>
              <Form.Control 
                name="message"
                as="textarea" 
                rows={3}
                value={supportFormData.message}
                onChange={handleSupportForm} 
              />             
              {validator.message(
                "message",
                supportFormData.message,
                "required",
                {
                  className: "text-danger",
                }
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-light" onClick={handleCloseSupport}>
              Close
            </Button>
            <Button variant="primary" onClick={() => handleSupportForm("submit") } disabled={disabled} >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}
export default Footer;