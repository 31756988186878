import React, { useEffect, useState, useCallback, useReducer } from "react";
import {
  getSettings,
  getTeam,
  getCalculations,
  saveOrganizationTeamAttempt,
  updateOrganizationTeamAttempt,
  getRolesAttempt,
  setTeamLogo,
  deleteCalculation,
} from "./data/globalAction";
import {
  Container,
  Button,
  Form,
  InputGroup,
  FormControl,
  Modal,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useHistory, useParams, NavLink } from "react-router-dom";
import TrashIcon from "./assets/images/icons/trash.svg";
import TrashWhiteIcon from "./assets/images/icons/trashWhite.svg";
import DownloadIcon from "../component/assets/images/icons/download.svg";
import SearchIcon from "../component/assets/images/icons/search.svg";
import Select from "react-select";
import DatePicker from "react-datepicker";
import moment from "moment";
import { toast } from "react-toastify";
import Payment from "./Payment";
import Slider from "react-slick";
import CalOptions from '../component/assets/images/learning/cal-options.png';
import CreateQuote from '../component/assets/images/learning/create-quote.png';
import ButtonImage from '../component/assets/images/learning/buttons.png';
import QuoteBtn from '../component/assets/images/learning/quote-btn.png';
import AccountImage from '../component/assets/images/learning/account-image.png';
import CalList from '../component/assets/images/learning/cal-list.png';
import CalculationTab from "../component/assets/images/learning/learning-new/calculation.png";

function CalculationsPage({ member_id, toggleNav }) {
  const { id } = useParams();
  const [calculations, setCalculations] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [settings, setSettings] = useState([]);
  const [isPaymentShowing, setIsPaymentShowing] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [qStatus, setQstatus] = useState("");
  const [activeClass, setActiveClass] = useState(true);
  const [filterText, setFilterText] = React.useState("");
  const [filterVal, setFilterVal] = useState("");
  const handleTeamComponent = useCallback((state) => handleRowClick(state));
  const [demoModalShow, setDemoModalShow] = useState(false);
  const [showFinishedButton, setShowFinishedButton] = useState(true);

  const [teamData, setTeamData] = useState([]);
  const [revenues, setRevenues] = useState([]);
  const [members, setMembers] = useState([]);
  const [filters, setFilters] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      status: "",
      team: "",
      member: "",
      revenue: "",
      date: "",
      search: "",
    }
  );

  const statuses = [
    { label: "Approved", value: "Approved" },
    { label: "Not approved", value: "Not approved" },
    { label: "New calculation", value: "New calculation" },
    { label: "Sent to approval", value: "Sent to approval" },
  ];

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}
      >Next</div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}
      >Back</div>
    );
  }

  const surveySettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    // afterChange: function (currentSlide) {
    //   if(currentSlide == 0) {
    //     setShowFinishedButton(true)
    //   } else {
    //     setShowFinishedButton(false)
    //   }
    // }
  };
  
  const handleClose = () => {
    setDemoModalShow(false);
    setShowFinishedButton(false);    
    localStorage.setItem("calculationDemo", false);
  }

  let history = useHistory();
  const handleRowClick = (row) => {
    history.push({
      pathname: `/calculations/${row.id}`,
      state: {
        data: row,
      },
    });
  };
  const customTableStyles = {
    header: {
      style: {
        padding: "0",
        minHeight: "inherit",
      },
    },
    subHeader: {
      style: {
        padding: "0",
        justifyContent: "flex-start",
        display: "block",
        border: "1px solid #E5E8F5",
        borderBottom: "none",
        borderRadius: "6px 6px 0 0",
      },
    },
    tableWrapper: {
      style: {
        border: "1px solid #E5E8F5",
        borderTop: "none",
      },
    },
    headRow: {
      style: {
        color: "#43536D",
        borderColor: "#E5E8F5",
        borderStyle: "solid",
        borderWidth: "1px",
        borderLeftWidth: "0",
        borderRightWidth: "0",
        backgroundColor: "#FCFCFF",
      },
    },
    headCells: {
      style: {
        fontSize: "13px",
        fontWeight: "normal",
        color: "#767778",
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: "#fff",
        },
      },
    },
    rows: {
      style: {
        minHeight: "65px",
        "&:not(:last-of-type)": {
          borderBottomStyle: "solid",
          borderBottomWidth: "1px",
          borderBottomColor: "#E5E8F5",
        },
      },
    },
    cells: {
      style: {
        minHeight: "65px",
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: "#fff",
        },
      },
    },
    pagination: {
      style: {
        border: "1px solid #E5E8F5",
        borderTop: "none",
        borderRadius: "0 0 6px 6px",
      },
    },
  };
  const customControlStyles = {
    control: (base, state) => ({
      ...base,
      minHeight: 40,
      background: state.isFocused ? "#fff" : "#fff",
      borderWidth: 1,
      borderRadius: "8px",
      borderColor: state.isFocused ? "#7848FF" : "#E5E8F5",
      boxShadow: state.isFocused ? null : null,
      fontSize: "14px",
      "&:hover": {
        borderColor: state.isFocused ? "#7848FF" : null,
      },
    }),
    dropdownIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
      width: "32px",
      color: "#142433",
    }),
    menu: (base) => ({
      ...base,
      fontSize: "13px",
      border: "1px solid #f1f3f6",
      boxShadow: "none",
    }),
    option: (base, state) => ({
      ...base,
      background: state.isSelected ? "#f1f3f6" : "#fff",
      color: "#151B26",
      "&:hover": {
        background: "#f1f3f6",
        color: "#151B26",
      },
    }),
    placeholder: (base) => ({
      ...base,
      color: "#142433",
    }),
    clearIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
  };

  const handleDatepickerFocus = (e) => {
    e.target.parentNode.parentNode.parentNode.classList.add("active");
  };
  const handleDatepickerBlur = (e) => {
    e.target.parentNode.parentNode.parentNode.classList.remove("active");
  };
  const handleDateChange = (date, e) => {
    setStartDate(date);
    e.target.closest(".input-group").classList.remove("active");
  };
  const handleClosePayment = () => {
    localStorage.removeItem("expire");
    setIsPaymentShowing(false);
  };

  const columns = [
    {
      name: "Title:",
      selector: "title",
      sortable: true,
    },
    {
      name: "Revenue:",
      selector: "revenue",
      sortable: true,
      cell: (row) => (
        <div>
          <span className="text-success">$</span> {row.revenue}
        </div>
      ),
    },
    {
      name: "Team:",
      selector: "organization_team",
      sortable: true,
      cell: (row) => (
        <div className="d-flex align-items-center">
          {row && row.organization_team && row.organization_team.image ? (
            <>
              <div className="user-pic">
                <img src={row.organization_team.image} alt="Image" />
              </div>
              {row.organization_team.name}
            </>
          ) : row && row.organization_team && row.organization_team.name ? (
            <>
              <div className="team-name-short mr-2">
                {row.organization_team.name
                  .replace(/[^a-zA-Z- ]/g, "")
                  .toUpperCase()
                  .match(/\b\w/g)
                  .join("")}
              </div>
              {row.organization_team.name}{" "}
            </>
          ) : null}
        </div>
      ),
    },
    {
      name: "Member:",
      selector: "user",
      sortable: true,
      cell: (row) => (
        <div className="d-flex align-items-center">
          {row && row.user && row.user.image ? (
            <>
              <div className="generic-user-pic">
                <div className="user-pic">
                  <img src={row.user.image} alt="Image" />
                </div>
              </div>
              <span>{row.user.first_name} {row.user.last_name}</span>
            </>
          ) : row && row.user && row.user.first_name ? (
            <>
              <div className="team-name-short mr-2">
                {row.user.first_name
                  .replace(/[^a-zA-Z-0-9 ]/g, "")
                  .match(/\b\w/g) +
                  row.user.last_name
                    .replace(/[^a-zA-Z-0-9 ]/g, "")
                    .match(/\b\w/g)}
              </div>
              <span>{row.user.first_name} {row.user.last_name}</span>
            </>
          ) : null}
        </div>
      ),
    },
    {
      name: "Date:",
      selector: "date",
      sortable: true,
      cell: (row) => moment(row.date).format("MM/DD/YYYY"),
    },
    {
      name: "Status:",
      selector: "status",
      cell: (row) => (
        <div className="d-flex align-items-center w-100 q-status-section justify-content-end">
          <div
            className={
              row.status === "New calculation" ||
              row.status === "Sent to approval"
                ? "badge badge-info mr-2"
                : row.status === "Not approved"
                ? "badge badge-danger mr-2"
                : "badge badge-success mr-2"
            }
          >
            {row.status}
          </div>
          <Button
            className="btn-icon m-0"
            variant="outline-light"
            onClick={() => deleteCal(row.id)}
          >
            <img src={TrashIcon} alt="Trash Icon" className="icon-black" />
          </Button>
        </div>
      ),
    },
  ];

  async function fetchTeam(TeamId) {
    // console.log("props.id", id);
    // setFilterVal(id);

    let team = await getTeam(
      JSON.stringify({
        team_id: TeamId,
      })
    );
    if (
      team &&
      team.data &&
      team.data.organization_team_users &&
      team.data.organization_team_users.length > 0
    ) {
      // setTeamData(team.data.organization_team_users);
      // console.log(
      //   "team.data.organization_team_users",
      //   team.data.organization_team_users
      // );
      return team.data.organization_team_users;
    }
  }

  const handleFilter = (index, e, eTarget = null) => {
    let filtersCopy = filters;
    if (index === "date") {
      filtersCopy[index] = e;
      eTarget.target.closest(".input-group").classList.remove("active");
    } else {
      if (e) {
        filtersCopy[index] = e.value;
      } else {
        filtersCopy[index] = "";
      }
    }
    setFilters({ ...filtersCopy });
  };

  const deleteCal = async (id) => {
    let data = {
      id: id,
    };
    let result = await deleteCalculation(data);
    if (result.data.status == true) {
      toast.success("Calculation Deleted Successfully");
      fetchCalculations();
    } else if (result.data.status == false) {
      toast.warn("Calculation with Quotation can not be deleted.");
    } else {
      toast.error("Error, please try again");
    }
  };

  async function fetchCalculations() {
    // console.log("props.id", id);
    setFilterVal(id);
    let data = [];
    let data2 = {};
    if (member_id != null) {
      data2 = {
        member_id: member_id,
      };
    }
    let result = await getCalculations(data2);
    if (
      result &&
      result.data &&
      result.data.calculation &&
      result.data.calculation.length > 0
    ) {
      result.data.calculation.forEach((element) => {
        // console.log("result", element.id);
        data.push({
          id: element.id,
          title: element.name,
          revenue: element.revenue,
          date: element.created_at,
          organization_team: element.organization_team,
          user: element.user,
          status: element.status,
        });
      });
      setCalculations(data);
    }
  }

  useEffect(() => {
    document.querySelector('html').classList.remove('calculation-details-page');
    document.querySelector("html").classList.remove("homepage");
    fetchCalculations();

    async function fetchSettings() {
      setSettings(await getSettings());
    }
    fetchSettings();

    if(localStorage.getItem("calculationDemo") === "true"){
      setTimeout(() => {
        setDemoModalShow(true);
      }, 500);
    }
  }, []);

  useEffect(() => {
    if (settings.expire && settings.expire === true) {
      setIsPaymentShowing(true);
    }
  }, [settings]);

  useEffect(() => {
    console.log("filters", filters);
    let calculationsCopy = calculations;
    let filteredDataCopy = [];
    // Filtering based on status
    if (filters.status) {
      calculationsCopy.forEach((c, idx) => {
        if (c.status == filters.status) {
          filteredDataCopy.push(c);
        }
      });
      calculationsCopy = [...filteredDataCopy];
      filteredDataCopy = [];
    }
    if (filters.team) {
      calculationsCopy.forEach((c, idx) => {
        if (c.organization_team) {
          if (c.organization_team.id == filters.team) {
            filteredDataCopy.push(c);
          }
        }
      });
      calculationsCopy = [...filteredDataCopy];
      filteredDataCopy = [];
    }
    if (filters.member) {
      calculationsCopy.forEach((c, idx) => {
        if (c.user) {
          if (c.user.id == filters.member) {
            filteredDataCopy.push(c);
          }
        }
      });
      calculationsCopy = [...filteredDataCopy];
      filteredDataCopy = [];
    }
    if (filters.revenue) {
      calculationsCopy.forEach((c, idx) => {
        if (c.revenue == filters.revenue) {
          filteredDataCopy.push(c);
        }
      });
      calculationsCopy = [...filteredDataCopy];
      filteredDataCopy = [];
    }
    if (filters.date) {
      calculationsCopy.forEach((c, idx) => {
        if (
          moment(c.date).format("MM/DD/YYYY") ==
          moment(filters.date).format("MM/DD/YYYY")
        ) {
          filteredDataCopy.push(c);
        }
      });
      calculationsCopy = [...filteredDataCopy];
      filteredDataCopy = [];
    }
    if (filters.search) {
      let searchField = filters.search.toLowerCase();
      // console.log("searchField", searchField);
      calculationsCopy.forEach((c, idx) => {
        let check = false;
        if (c.title) {
          if (c.title.toLowerCase().includes(searchField)) {
            check = true;
          }
        }
        if (c.revenue) {
          if (c.revenue.toString().startsWith(searchField)) {
            check = true;
          }
        }
        if (c.organization_team) {
          if (c.organization_team.name.includes(searchField)) {
            check = true;
          }
        }
        if (c.user) {
          if (c.user.first_name) {
            let full_name = (
              c.user.first_name +
              " " +
              c.user.last_name
            ).toLowerCase();
            if (full_name.includes(filters.search)) {
              check = true;
            }
          }
        }
        if (check) {
          filteredDataCopy.push(c);
        }
      });
      calculationsCopy = [...filteredDataCopy];
      filteredDataCopy = [];
    }
    setFilteredData([...calculationsCopy]);
  }, [filters]);

  useEffect(() => {
    console.log("calculations", calculations);
    let teamsCopy = [];
    let revenuesCopy = [];
    calculations.forEach(async (c, idx) => {
      if (c.organization_team != undefined) {
        if (isUnique(teamsCopy, c.organization_team.id)) {
          teamsCopy.push({
            value: c.organization_team.id,
            label: c.organization_team.name,
          });
        }
      }
      if (c.revenue != null) {
        if (isUnique(revenuesCopy, c.revenue)) {
          revenuesCopy.push({ value: c.revenue, label: `$ ${c.revenue}` });
        }
      }
    });
    // console.log("revenuesCopy", revenuesCopy);
    setRevenues([...revenuesCopy]);
    setTeamData([...teamsCopy]);
  }, [calculations]);

  useEffect(() => {
    let membersCopy = [];
    if (!filters.team) {
      if (teamData) {
        // console.log("teamData", teamData);
        teamData.forEach((t, idx) => {
          fetchTeam(t.value).then((result) => {
            // console.log("result", result);
            if (Array.isArray(result)) {
              result.forEach((r, i) => {
                if (isUnique(membersCopy, r.info.id)) {
                  membersCopy.push({ value: r.info.id, label: r.name });
                }
              });
            }
            setMembers([...membersCopy]);
          });
        });
      }
    } else {
      fetchTeam(filters.team).then((result) => {
        // console.log("result", result);
        if (Array.isArray(result)) {
          result.forEach((r, i) => {
            if (isUnique(membersCopy, r.info.id)) {
              membersCopy.push({ value: r.info.id, label: r.name });
            }
          });
        }
        setMembers([...membersCopy]);
      });
    }
    // console.log("teamData", teamData);
  }, [teamData, filters]);

  const isUnique = (data, revenue) => {
    let valueIsUnique = true;
    data.forEach((r, idx) => {
      if (r.value == revenue) {
        valueIsUnique = false;
      }
    });
    return valueIsUnique;
  };

  const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <div className="d-flex align-items-center justify-content-between tableHead">
      <div className="table-filters">
        <Form.Group className="mb-0">
          <Select
            styles={customControlStyles}
            options={statuses}
            placeholder="Status"
            isClearable={true}
            onChange={(e) => handleFilter("status", e)}
            value={statuses.find((s) => {
              return s.value === filters.status;
            })}
          />
        </Form.Group>
        <Form.Group className="mb-0">
          <Select
            styles={customControlStyles}
            options={teamData}
            placeholder="Team"
            isClearable={true}
            onChange={(e) => handleFilter("team", e)}
            value={teamData.find((t) => {
              return t.value === filters.team;
            })}
          />
        </Form.Group>
        <Form.Group className="mb-0">
          <Select
            styles={customControlStyles}
            options={members}
            placeholder="Member"
            isClearable={true}
            onChange={(e) => handleFilter("member", e)}
            value={members.find((m) => {
              return m.value === filters.member;
            })}
          />
        </Form.Group>
        <Form.Group className="mb-0">
          <Select
            styles={customControlStyles}
            options={revenues}
            placeholder="Revenue"
            isClearable={true}
            onChange={(e) => handleFilter("revenue", e)}
            value={revenues.find((r) => {
              return r.value === filters.revenue;
            })}
          />
        </Form.Group>
        <Form.Group className="datepicker mb-0">
          <InputGroup className={`${activeClass ? "" : "input-group"}`}>
            <InputGroup.Prepend>
              <InputGroup.Text className="pr-0">
                <span className="icon"></span>
              </InputGroup.Text>
            </InputGroup.Prepend>
            <DatePicker
              selected={filters.date}
              isClearable={true}
              // minDate={new Date()}
              className="datepicker-form-control"
              onChange={(date, e) => handleFilter("date", date, e)}
              onFocus={handleDatepickerFocus}
              onBlur={handleDatepickerBlur}
            />
          </InputGroup>
        </Form.Group>
      </div>
      <div className="table-filters justify-content-end">
        <InputGroup>
          <Form.Control
            type="text"
            autoFocus={true}
            id="employee_only"
            name="employee_only"
            placeholder="Search"
            value={filters.search}
            onFocus={(e) => e.target.parentNode.classList.add("active")}
            onBlur={(e) => {
              e.target.parentNode.classList.remove("active");
            }}
            onChange={(e) => setFilters({ ...filters, search: e.target.value })}
          />
          <InputGroup.Append>
            <InputGroup.Text>
              <img src={SearchIcon} />
            </InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
      </div>
      {isPaymentShowing ? (
        <Payment onClose={handleClosePayment} isShowing={isPaymentShowing} />
      ) : null}
    </div>
  );
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, teamData, members, filters, revenues]);

  const filtersIsSet = () => {
    let set = false;
    Object.keys(filters).forEach((k, idx) => {
      if (filters[k]) {
        set = true;
      }
    });
    return set;
  };

  return (
    <div className="calculations-page mt-2 mb-4">
      <Container fluid>
        <h1 className="h4 mb-4">Calculations</h1>
        <DataTable
          customStyles={customTableStyles}
          responsive
          fixedHeader={true}
          columns={columns}
          data={filtersIsSet() ? filteredData : calculations}
          onRowClicked={handleTeamComponent}
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          pagination
          persistTableHead
        />
      </Container>

      <Modal
        size="lg"
        show={demoModalShow}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="survey-modal"
      >
        <Modal.Body>
          <Slider {...surveySettings}>

            <div>
              <div className="image-holder">
                <img src={CalculationTab} />
              </div>
              <div className="text-holder">
              This is where all the calculations will be stored. Can filter by status, team, team member, revenue, or date. Can even search use any custom criteria. Calculation status will define new calculations, waiting for approval, approved, and declined. You can delete any calculation from here. Click a calculation and it will bring you to calculation breakdown to show more details.  
              </div>
            </div>
           
          </Slider>
          {showFinishedButton ? 
            <div className='btn-finish' onClick={handleClose}>Finish</div>
          : ""}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default CalculationsPage;
