import React, { useState, useEffect, useReducer } from "react";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Card,
  FormControl,
} from "react-bootstrap";
import { useHistory, NavLink } from "react-router-dom";
import { CheckoutForm } from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { addPaymentMethod } from "./data/globalAction";
import * as Constants from "../core/Constants";
import AfterLogin from "./middleware/AfterLogin";

function PaymentMethod() {
  const history = useHistory();
  const [cardInfo, setCardInfo] = useState([]);
  const [stripePromise, setStripePromise] = useState(() =>
    loadStripe(process.env.REACT_APP_STRIPE_PKEY)
  );
  const [formInput, setFormInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      pm_id: "",
    }
  );
  const [disabled, setDisabled] = useState(false);
  const [defaultStatus, setDefaultStatus] = useState(false);
  const handleDefaultStatus = async (evt) => {
    setDefaultStatus(evt.target.checked);
  };
  const verifyCard = async (error, info) => {
    if (error && error.message) {
      toast.error(error.message);
      setDisabled(false);
    } else {
      const name = "pm_id";
      const newValue = info.id;
      setFormInput({ [name]: newValue });
      let data = {
        pm_id: info.id,
        default_status: defaultStatus,
      };
      let result = await addPaymentMethod(JSON.stringify(data));
      if (result.status === 200) {
        toast.success(Constants.update_message);
        history.push("/settings/billing-payment");
      }
      setDisabled(false);
    }
  };
  return (
    <div className="payment-method-page">
      <Container>
        <Row className="justify-content-md-center">
          <Col lg={6}>
            <h2 className="title">Add Payment Method</h2>
            <Card className="mb-4">
              <Card.Body className="p-4">
                <div className="stripe-card-info">
                  <Form.Label>Payment Card Details</Form.Label>
                  <Form.Check
                    custom
                    className="p-0"
                    type="checkbox"
                    id="default-payment"
                    label="Use as default payment method"
                    defaultChecked={defaultStatus}
                    onChange={(e) => handleDefaultStatus(e)}
                  />
                  <Elements stripe={stripePromise}>
                    <CheckoutForm
                      verifyCard={verifyCard}
                      disabled={disabled}
                      buttonText="Add"
                      setDisabled={setDisabled}
                      setCardInfo={setCardInfo}
                    />
                  </Elements>
                </div>
                <div className="card-bottom">
                  <NavLink
                    to="/settings/billing-payment"
                    className="btn btn-light btn-block"
                  >
                    Back
                  </NavLink>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <AfterLogin />
    </div>
  );
}

export default PaymentMethod;
