export const signInAttempt = (payload) => ({
  type: "SIGNIN_ATTEMPT",
  payload: payload,
});
export const signInAttemptResponse = (payload) => ({
  type: "SIGNIN_ATTEMPT_RESPONSE",
  payload: payload,
});
export const signInError = (payload) => ({
  type: "SIGNIN_SET_ERROR",
  payload: payload,
});
export const signInMessage = (payload) => ({
  type: "SIGNIN_SET_MESSAGE",
  payload: payload,
});
export const signOutAttempt = () => ({
  type: "SIGNOUT_ATTEMPT",
});
export const signInAttemptByUUID = (payload) => ({
  type: "SIGNIN_ATTEMPT_BY_UUID",
  payload: payload,
});
