import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Route, Redirect } from "react-router-dom";
import { unauthorized } from "../core/Constants";
import { isOwner, isAdmin } from "../core/UserRoleCheck";

function AdminPrivateRoute({ children, login, ...rest }) {
  const user_role = login.data.data.user_role;
  return (
    <Route
      {...rest}
      render={() => {
        if (isOwner || isAdmin) {
          return children;
        } else {
          console.log("window.location.pathname", window.location.pathname);
          toast.error(unauthorized);
          return <Redirect to="/dashboard" />;
        }
      }}
    ></Route>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {};
};
const mapStateToProps = (state) => {
  return {
    login: state.login.login,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminPrivateRoute);
