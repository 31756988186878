import React, {useState} from "react";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import { useHistory, NavLink } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "react-loader-spinner";

import * as Constants from "./core/Constants";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import CookieConsent from "react-cookie-consent";


import "./component/assets/scss/main.scss";
import SignIn from "./component/SignIn";
import SignUp from "./component/SignUp";
import SignOut from "./component/SignOut";
import Dashboard from "./component/Dashboard";
import ForgotPassword from "./component/ForgotPassword";
import ResetPassword from "./component/ResetPassword";
import Homepage from "./component/Homepage";
import Privacy from "./component/Privacy";

import Settings from "./component/Settings";
import SingleCalculator from "./component/SingleCalculator";

import Navigation from "./component/layout/Navigation";

import axios from "axios";
import AccountSecurity from "./component/AccountSecurity";
import CalculationSettings from "./component/CalculationSettings";
import BillingPayment from "./component/BillingPayment";
import ChangePlan from "./component/ChangePlan";
import PaymentMethod from "./component/PaymentMethod";
import BillingInformation from "./component/BillingInformation";
import Company from "./component/Company";
import AdminPrivateRoute from "./privateRoutes/adminPrivateRoute";
import ManagerPrivateRoute from "./privateRoutes/ManagerPrivateRoute";
import Teams from "./component/Teams";
import TeamsMembers from "./component/TeamMembers";
import CalculationsPage from "./component/CalculationsPage";
import QuotesPage from "./component/QuotesPage";
import CalculationDetail from "./component/CalculationDetail";
import QuotationDetail from "./component/QuotationDetail";
import NotificationsPage from "./component/NotificationsPage";

import './component/assets/js/custom.js';
import Terms from "./component/Terms";
import LearningCenter from "./component/LearningCenter";
import CompanyInformation from "./component/CompanyInformation";

function App(props) {
  const history = useHistory();
  const checkDomain = () => {
    axios.defaults.headers = {
      "content-type": "application/json",
    };
    axios
      .post(process.env.REACT_APP_API_URL + "/check_domain", {
        domain: window.location.host,
      })
      .then(function (response) {
        console.log("response",response, response.data, response.data.status);
        if (response.data.status === "ignore") {
          if (props.login && props.login.length !== 0) {
            window.location.href =
              process.env.REACT_APP_SUBDOMIAN_URL.replace(
                "{url}",
                props.login.data.organization_users[0].organization
                  .business_domain
              ) + "/dashboard";
          }
        } else if (response.data.status === "success") {
          if (props.login && props.login.length !== 0) {
            window.location.href =
              process.env.REACT_APP_SUBDOMIAN_URL.replace(
                "{url}",
                props.login.data.organization_users[0].organization
                  .business_domain
              ) + "/dashboard";
          }
        } else {
          window.location.href = process.env.REACT_APP_URL;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  if (
    window.location.pathname !== "/" &&
    window.location.pathname !== "/signup" &&
    window.location.pathname !== "/forgot-password" &&
    window.location.pathname !== "/reset-password" &&
    window.location.pathname !== "/signin" &&
    window.location.pathname !== "/privacy" &&
    window.location.pathname !== "/terms" &&
    !window.location.pathname.includes("/quote/approve/") &&
    !window.location.pathname.includes("/support")
  ) {
    if (props.login && props.login.length === 0) {
      history.push("/signin");
    }
  } else {
    checkDomain();
    console.log("here");
  }

  // if (
  //   window.location.pathname !== "/" &&
  //   window.location.pathname !== "/signup" &&
  //   window.location.pathname !== "/forgot-password" &&
  //   window.location.pathname !== "/reset-password" &&
  //   window.location.pathname !== "/signin" &&
  //   window.location.pathname !== "/privacy" &&
  //   window.location.pathname !== "/terms" &&
  //   !window.location.pathname.includes("/support")
  // ) {
  //   if (props.login && props.login.length === 0) {
  //     history.push("/signin");
  //   }
  // } else {
  //   checkDomain();
  // }

  return (
    <div>
      <Loader
          type="Oval"
          color="#000"
          className="loader-main"
          height={50}
          width={50}
          timeout={4000} //2 secs
        >
      </Loader>
      {!window.location.pathname.startsWith(
        Constants.path_for_approve_quote
      ) && <Navigation />}
      {/* <Navigation /> */}
      <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
      />
      <Switch>
        <Route path="/terms">
          <Terms />
        </Route>
        <Route path="/company">
          <Company />
        </Route>
        <ManagerPrivateRoute path="/settings/calculation-settings">
          <CalculationSettings />
        </ManagerPrivateRoute>
        <ManagerPrivateRoute path="/settings/company-information">
          <CompanyInformation />
        </ManagerPrivateRoute>
        <AdminPrivateRoute path="/settings/billing-payment/billing-information">
          <BillingInformation />
        </AdminPrivateRoute>
        <AdminPrivateRoute path="/settings/billing-payment/payment-method">
          <PaymentMethod />
        </AdminPrivateRoute>
        <AdminPrivateRoute path="/settings/billing-payment">
          <BillingPayment />
        </AdminPrivateRoute>
        <AdminPrivateRoute path="/change-plan">
          <ChangePlan />
        </AdminPrivateRoute>
        <Route path="/settings/account-security">
          <AccountSecurity />
        </Route>
        <Route path="/learning-center">
          <LearningCenter />
        </Route>
        <Route path="/settings">
          <Settings />
        </Route>
        <Route exact path="/calculator">
          <SingleCalculator />
        </Route>

        <Route path="/dashboard/team/:id/members/:member_id">
          <TeamsMembers />
        </Route>

        <Route path="/dashboard/team/:id">
          <Teams />
        </Route>

        <Route exact path="/quotes">
          <QuotesPage />
        </Route>
        <Route exact path="/quote/:id">
          <QuotationDetail />
        </Route>
        <Route exact path="/quote/approve/:id">
          <QuotationDetail />
        </Route>

        <Route exact path="/calculations">
          <CalculationsPage />
        </Route>
        <Route exact path="/calculations/:id">
          <CalculationDetail  />
        </Route>
        <Route exact path="/calculations/edit/:id">
          <SingleCalculator />
        </Route>

        <Route path="/dashboard">
          <Dashboard />
        </Route>

        <Route path="/notifications">
          <NotificationsPage />
        </Route>

        <Route path="/logout">
          <SignOut />
        </Route>

        <Route path="/reset-password">
          <ResetPassword />
        </Route>

        <Route path="/forgot-password">
          <ForgotPassword />
        </Route>

        <Route path="/signin">
          <SignIn />
        </Route>

        <Route path="/signup">
          <SignUp />
        </Route>
        
        <Route path="/privacy">
          <Privacy logged={props.login} />
        </Route>
        <Route path="/">
          <Homepage logged={props.login} />
        </Route>
      </Switch>
      <CookieConsent
        location="bottom"
        buttonText="I agree"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#000", fontSize: "12px" }}
        buttonStyle={{ color: "#fff", fontSize: "14px", background: "#6a45d3", paddingTop: "6px",
        paddingBottom: "6px", paddingLeft: "18px", paddingRight: "18px", borderRadius: "4px", margin: "10px" }}
        expires={150}
      >
        By using our website you are consenting to our use of cookies in accordance with our policy
      </CookieConsent>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    login: state.login.login,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
