export const signup_complete_message =
  "Signup completed please try login. Thanks!";
export const settings_billing_trail_days = "days remaining in trial";
export const settings_billing_trail_days_expire = "Trial days expired";
export const employees_options = [
  { label: "Less than 10", value: "<10" },
  { label: "More than 10", value: "10+" },
  { label: "More than 50", value: "50+" },
  { label: "More than 100", value: "100+" },
  { label: "More than 200", value: "200+" },
  { label: "More than 300", value: "300+" },
  { label: "More than 400", value: "40+" },
];
export const decline_reasons = [
  {
    label: "Error in Rates",
    value: "Error in Rates",
  },
  {
    label: "No Revenue",
    value: "No Revenue",
  },
  {
    label: "Gross Profit in negative",
    value: "Gross Profit in negative",
  },
  {
    label: "Declined without any reason",
    value: "Declined without any reason",
  },
  {
    label: "Other reasons",
    value: "Other reasons",
  },
];
export const path_for_approve_quote = "/quote/approve";
export const default_county = { label: "United States", value: 1 };
export const default_role = { label: "Admin", value: 2 };
export const update_message = "Update Successfully";
export const login_first = "You need to login first!";
export const update_error_message = "Update Not Successful";
export const logout_message = "You need to login with new email again!";
export const default_tax_type = ["Total Invoice", "Gross Profit"];
export const fetching_data = "Fetching data please wait ....";
export const no_data = "There are no records to display";
export const err_billing_informeation = "Please add payment method";
export const unauthorized = "You Are Not Authorized";
export const bonusOptions = [
  { label: "%", value: "%" },
  { label: "$", value: "$" },
];
export const daysOptions = [
  { label: "Per Year", value: "Per Year" },
  { label: "Total Assignment", value: "Total Assignment" },
];
export const package_type = [
  { label: "Employee Only Monthly Premium", value: "employee_only" },
  { label: "Employee + Spouse Monthly Premium", value: "employee_spouse" },
  {
    label: "Employee + Dependent Monthly Premium",
    value: "employee_dependent",
  },
  { label: "Family Monthly Premium", value: "family" },
];
export const estimated_assigment_period = [
  { label: "Week", value: "week" },
  { label: "Month", value: "month" },
  { label: "Year", value: "year" },
];
export const salary_type = [
  { value: 'annual', label: 'Annual' },
  { value: 'monthly', label: 'Monthly' },
];
export const update_plan_message = "You need to upgrade your plan to use this feature.";
export const plans = {
  "1" : {"price": 49, "yearly_price": 43.12},
  "2" : {"price": 48, "yearly_price": 42.24},
  "3" : {"price": 48, "yearly_price": 42.24},
  "4" : {"price": 47, "yearly_price": 41.36},
  "5" : {"price": 47, "yearly_price": 41.36},
  "6" : {"price": 46, "yearly_price": 40.48},
  "7" : {"price": 46, "yearly_price": 40.48},
  "8" : {"price": 45, "yearly_price": 39.60},
  "9" : {"price": 45, "yearly_price": 39.60},
  "10" : {"price": 44, "yearly_price": 38.72},
  "11" : {"price": 44, "yearly_price": 38.72},
  "12" : {"price": 43, "yearly_price": 37.84},
  "13" : {"price": 43, "yearly_price": 37.84},
  "14" : {"price": 42, "yearly_price": 36.96},
  "15" : {"price": 42, "yearly_price": 36.96},
  "16" : {"price": 41, "yearly_price": 36.08},
  "17" : {"price": 41, "yearly_price": 36.08},
  "18" : {"price": 40, "yearly_price": 35.20},
  "19" : {"price": 40, "yearly_price": 35.20},
  "20" : {"price": 39, "yearly_price": 34.32},
}
