import {
  roles,
  states,
  cities,
  settings,
  company_update,
  name_update,
  password_update,
  verify_email_update,
  email_update,
  create_calculation,
  edit_calculation,
  get_calculation,
  organization_members,
  organization_settings,
  organization_settings_suta,
  organization_settings_import_suta,
  organization_settings_tax_status,
  organization_settings_sales_tax,
  organization_settings_import_wc,
  organization_settings_update_spto,
  organization_settings_wc_settings,
  organization_settings_always_accrue,
  organization_settings_exempt_sales_tax,
  organization_settings_package_delete,
  organization_settings_cost_delete,
  organization_settings_package,
  organization_settings_update_cost,
  industry,
  organization_spto_settings,
  organization_teams,
  organization_team_update,
  organization_team_save,
  plans,
  download_cal_file,
  subscriptionUpdate,
  bill_settings,
  payment_method_update,
  payment_method_add,
  payment_method_delete,
  payment_method_set_default,
  billing_info,
  update_billing_info,
  subscription,
  subscriptionCancel,
  subscriptionAddPlan,
  set_profile_image,
  set_company_image,
  set_team_logo,
  organization_settings_packages,
  remove_member,
  organization_team,
  get_calculations,
  calculation_send_to_approval,
  create_quote,
  get_notifications,
  read_notification,
  read_all_notification,
  get_quotations,
  get_quotation,
  update_quote_status,
  update_quote_status_approve,
  get_quotation_approve,
  delete_calculation,
  delete_team,
  get_approved_quotations,
  team_quotations,
  send_quote_via_email,
  get_members_approved_quotations,
  calculate_stipend,
  send_enquiry,
  send_support,
} from "../../core/Service";

export const getRolesAttempt = async (payload) => {
  const response = await roles(payload);
  if (response.data) return response.data;
  else return [];
};

export const getPlans = async (payload) => {
  const response = await plans(payload);
  if (response.data) return response.data;
  else return [];
};
export const getIndustriesAttempt = async (payload) => {
  const response = await industry(payload);
  if (response.data) return response.data;
  else return [];
};
export const getStatesAttempt = async (payload) => {
  const response = await states(payload);
  if (response.data) return response.data;
  else return [];
};
export const getCitiesAttempt = async (payload) => {
  const response = await cities(payload);
  if (response.data) return response.data;
  else return [];
};
export const getSettings = async (payload) => {
  const response = await settings(payload);
  if (response.data) return response.data;
  else return [];
};

export const simpleSubscription = async (payload) => {
  const response = await subscription(payload);
  return response;
};
export const updateSubscription = async (payload) => {
  const response = await subscriptionUpdate(payload);
  return response;
};
export const cancelSubscription = async (payload) => {
  const response = await subscriptionCancel(payload);
  return response;
};
export const createSubscription = async (payload) => {
  const response = await subscriptionAddPlan(payload);
  return response;
};
export const updateCompanyAttempt = async (payload) => {
  const response = await company_update(payload);
  return response;
};
export const updateNameAttempt = async (payload) => {
  const response = await name_update(payload);
  return response;
};
export const updatePasswordAttempt = async (payload) => {
  const response = await password_update(payload);
  return response;
};
export const verifyEmailAttempt = async (payload) => {
  const response = await verify_email_update(payload);
  return response;
};
export const updateEmailAttempt = async (payload) => {
  const response = await email_update(payload);
  return response;
};

export const addPaymentMethod = async (payload) => {
  const response = await payment_method_add(payload);
  return response;
};
export const setDefaultPaymentMethod = async (payload) => {
  const response = await payment_method_set_default(payload);
  return response;
};
export const deletePaymentMethod = async (payload) => {
  const response = await payment_method_delete(payload);
  return response;
};
export const getOrganizationSettingAttempt = async (payload) => {
  const response = await organization_settings(payload);
  return response;
};
export const sutaOrganizationSettingAttempt = async (payload) => {
  const response = await organization_settings_suta(payload);
  return response;
};
export const importSutaOrganizationSettingAttempt = async (payload) => {
  const response = await organization_settings_import_suta(payload);
  return response;
};
export const taxStatusOrganizationSettingAttempt = async (payload) => {
  const response = await organization_settings_tax_status(payload);
  return response;
};
export const salesTaxOrganizationSettingAttempt = async (payload) => {
  const response = await organization_settings_sales_tax(payload);
  return response;
};
export const importWcOrganizationSettingAttempt = async (payload) => {
  const response = await organization_settings_import_wc(payload);
  return response;
};
export const updateSptoOrganizationSettingAttempt = async (payload) => {
  const response = await organization_settings_update_spto(payload);
  return response;
};
export const wcSettingsOrganizationSettingAttempt = async (payload) => {
  const response = await organization_settings_wc_settings(payload);
  return response;
};
export const alwaysAccureOrganizationSettingAttempt = async (payload) => {
  const response = await organization_settings_always_accrue(payload);
  return response;
};
export const exemptSalesTaxOrganizationSettingAttempt = async (payload) => {
  const response = await organization_settings_exempt_sales_tax(payload);
  return response;
};
export const packageDeleteOrganizationSettingAttempt = async (payload) => {
  const response = await organization_settings_package_delete(payload);
  return response;
};
export const costDeleteOrganizationSettingAttempt = async (payload) => {
  const response = await organization_settings_cost_delete(payload);
  return response;
};
export const packageOrganizationSettingAttempt = async (payload) => {
  const response = await organization_settings_package(payload);
  return response;
};
export const packageOrganization = async (payload) => {
  const response = await organization_settings_packages(payload);
  return response;
};
export const updateCostOrganizationSettingAttempt = async (payload) => {
  const response = await organization_settings_update_cost(payload);
  return response;
};
export const saveOrganizationTeamAttempt = async (payload) => {
  const response = await organization_team_save(payload);
  return response;
};
export const updateOrganizationTeamAttempt = async (payload) => {
  const response = await organization_team_update(payload);
  return response;
};
export const getSPTOSettings = async (payload) => {
  const response = await organization_spto_settings(payload);
  return response.data;
};
export const getTeams = async (payload) => {
  const response = await organization_teams(payload);
  return response;
};
export const getTeam = async (payload) => {
  const response = await organization_team(payload);
  return response;
};
export const getTeamQuotations = async (payload) => {
  const response = await team_quotations(payload);
  return response;
};
export const getBillSettings = async (payload) => {
  const response = await bill_settings(payload);
  return response.data;
};

export const getBillingInfo = async (payload) => {
  const response = await billing_info(payload);
  return response.data;
};

export const updateBillingInfo = async (payload) => {
  const response = await update_billing_info(payload);
  return response;
};
export const setProfileImage = async (payload) => {
  const response = await set_profile_image(payload);
  return response;
};
export const setCompanyImage = async (payload) => {
  const response = await set_company_image(payload);
  return response;
};
export const setTeamLogo = async (payload) => {
  const response = await set_team_logo(payload);
  return response;
};
export const getOrganizationMembers = async (payload) => {
  const response = await organization_members(payload);
  return response.data;
};
export const removeMemberCall = async (payload) => {
  const response = await remove_member(payload);
  return response;
};
export const createCalculation = async (payload) => {
  const response = await create_calculation(payload);
  return response;
};
export const calculateStipend = async (payload) => {
  const response = await calculate_stipend(payload);
  return response;
};
export const deleteCalculation = async (payload) => {
  const response = await delete_calculation(payload);
  return response;
};
export const deleteTeam = async (payload) => {
  const response = await delete_team(payload);
  return response;
};
export const editCalculation = async (payload) => {
  const response = await edit_calculation(payload);
  return response;
};
export const getCalculation = async (payload) => {
  const response = await get_calculation(payload);
  return response;
};

export const getCalculations = async (payload) => {
  const response = await get_calculations(payload);
  return response;
};
export const getQuotation = async (payload) => {
  const response = await get_quotation(payload);
  return response;
};
export const getQuotationApprove = async (payload) => {
  const response = await get_quotation_approve(payload);
  return response;
};
export const getQuotations = async (payload) => {
  const response = await get_quotations(payload);
  return response;
};
export const getApprovedQuotations = async (payload) => {
  const response = await get_approved_quotations(payload);
  return response;
};
export const getMembersApprovedQuotations = async (payload) => {
  const response = await get_members_approved_quotations(payload);
  return response;
};
export const sendCalToApproval = async (payload) => {
  const response = await calculation_send_to_approval(payload);
  return response;
};
export const sendEnquiry = async (payload) => {
  const response = await send_enquiry(payload);
  return response;
};
export const sendSupport = async (payload) => {
  const response = await send_support(payload);
  return response;
};
export const updateQuoteStatus = async (payload) => {
  const response = await update_quote_status(payload);
  return response;
};
export const updateQuoteStatusApprove = async (payload) => {
  const response = await update_quote_status_approve(payload);
  return response;
};
export const createQuote = async (payload) => {
  const response = await create_quote(payload);
  return response;
};
export const sendQuoteViaEmail = async (payload) => {
  const response = await send_quote_via_email(payload);
  return response;
};
export const downloadCalFile = async (payload) => {
  const response = await download_cal_file(payload);
  return response;
};
export const getNotifications = async (payload) => {
  const response = await get_notifications(payload);
  return response;
};
export const readNotification = async (payload) => {
  const response = await read_notification(payload);
  return response;
};
export const readAllNotification = async (payload) => {
  const response = await read_all_notification(payload);
  return response;
};
