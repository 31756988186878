import createSagaMiddleware from "redux-saga";
import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./reducer";
import rootSaga from "./saga";

function saveToLocalStorage(state) {
  try {
    const serializationState = JSON.stringify(state);
    document.cookie =
      "serializationState=" +
      serializationState.replace(";", "||") +
      ";path=/;domain=." +
      process.env.REACT_APP_COOKIES_URL;
  } catch (error) {
    console.log(error);
  }
}
function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
function loadFromLocalStorage() {
  try {
    const serializationState = getCookie("serializationState").replace(
      "||",
      ";"
    );
    if (serializationState === null) return undefined;
    return JSON.parse(serializationState);
  } catch (error) {
    console.log(error);
    return undefined;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();
const presistedState = loadFromLocalStorage();
const configureStore = composeEnhancers(applyMiddleware(sagaMiddleware))(
  createStore
);

const createAppStore = () => {
  let store = configureStore(rootReducer, presistedState);
  store.subscribe(() => saveToLocalStorage(store.getState()));
  return { store };
};
export default createAppStore();
sagaMiddleware.run(rootSaga);
