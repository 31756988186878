import React, { useState, useEffect, useCallback } from "react";
import {
  getSettings,
  getTeam,
  getTeamQuotations,
  saveOrganizationTeamAttempt,
  updateOrganizationTeamAttempt,
  getRolesAttempt,
  setTeamLogo,
} from "./data/globalAction";
import { BsPlus } from "react-icons/bs";
import { RiCloseLine } from "react-icons/ri";
import { useHistory, useParams, NavLink } from "react-router-dom";
import DataTable from "react-data-table-component";
import { RiArrowUpSLine } from "react-icons/ri";
import DatePicker from "react-datepicker";
import Select from "react-select";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import BadgeIcon from "../component/assets/images/icons/badge.svg";
import GraphIcon from "../component/assets/images/icons/graph-icon.svg";
import chevLeft from "../component/assets/images/icons/cheveron-left.svg";
import PersentIcon from "../component/assets/images/icons/persent-icon.svg";
import DownloadIcon from "../component/assets/images/icons/download.svg";
import {
  Container,
  Card,
  Tab,
  Tabs,
  Col,
  Row,
  Form,
  FormControl,
  InputGroup,
  ProgressBar,
  Button,
} from "react-bootstrap";
import { isAdmin, isOwner } from "../core/UserRoleCheck";
import Payment from "./Payment";

function Teams(props) {
  const { id } = useParams();
  const [team, setTeam] = useState([]);
  const [teamData, setTeamData] = useState([]);
  const [approvedQuotations, setApprovedQuotations] = useState([]);
  const [quotations, setQuotations] = useState([]);
  const [revenue, setRevenue] = useState(null);
  const [grossProfit, setGrossProfit] = useState(null);
  const [settings, setSettings] = useState([]);
  const [isPaymentShowing, setIsPaymentShowing] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [activeClass, setActiveClass] = useState(true);
  const [key, setKey] = useState("actual-revenue");
  const [qStatus, setQstatus] = useState("");
  const [filterText, setFilterText] = React.useState("");
  const [filterVal, setFilterVal] = useState("");
  const handleTeamComponent = useCallback((state) => handleRowClick(state));

  const handleRowClick = (row) => {
    history.push({
      pathname: `/dashboard/team/${id}/members/${row.info.id}`,
      state: {
        data: row,
      },
    });
  };

  function quoteStatus() {
    switch (setQstatus) {
      case "approved":
        return "primary";
      case "not-approved":
        return "danger";
      case "waitiong-for-approval":
        return "primary";
      case "submitted-for-approval":
        return "info";
      default:
        return "";
    }
  }
  
  useEffect(()=>{
    async function fetchQuotations() {
      const quotes = await getTeamQuotations(
        JSON.stringify({
          team_id: id,
        })
      );
      setQuotations(quotes.data.team_quotations);
      calculateRevenue(quotes.data.team_quotations);
    }
    if(key === "actual-revenue")
      calculateRevenue(approvedQuotations);
    else if (key === "projected-form")
      fetchQuotations();
  }, [key]);

  let quotesStatus = quoteStatus();
  const customGraphControlStyles = {
    control: (base, state) => ({
      ...base,
      minHeight: 40,
      marginBottom: "15px",
      background: "#fff",
      borderRadius: "8px",
      borderWidth: 1,
      borderColor: state.isFocused ? "#bbc0d3" : "#E5E8F5",
      boxShadow: "none",
      fontSize: "13px",
      "&:hover": {
        borderColor: state.isFocused ? null : null,
      },
    }),
    dropdownIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    menu: (base) => ({
      ...base,
      fontSize: "13px",
      border: "1px solid #f1f3f6",
      boxShadow: "none",
    }),
    option: (base, state) => ({
      ...base,
      background: state.isSelected ? "#f1f3f6" : "#fff",
      color: "#151B26",
      "&:hover": {
        background: "#f1f3f6",
        color: "#151B26",
      },
    }),
    clearIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
  };

  const customControlStyles = {
    control: (base, state) => ({
      ...base,
      minHeight: 50,
      background: state.isFocused ? "#fff" : "#fff",
      borderWidth: 1,
      borderRadius: "8px",
      borderColor: state.isFocused ? "#7848FF" : "#E5E8F5",
      boxShadow: state.isFocused ? null : null,
      fontSize: "14px",
      "&:hover": {
        borderColor: state.isFocused ? "#7848FF" : null,
      },
    }),
    dropdownIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
      marginRight: "5px",
    }),
    menu: (base) => ({
      ...base,
      fontSize: "13px",
      border: "1px solid #f1f3f6",
      boxShadow: "none",
    }),
    option: (base, state) => ({
      ...base,
      background: state.isSelected ? "#f1f3f6" : "#fff",
      color: "#151B26",
      "&:hover": {
        background: "#f1f3f6",
        color: "#151B26",
      },
    }),
    placeholder: (base) => ({
      ...base,
      color: "#A8B9CD",
    }),
    clearIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
  };
  const customTableStyles = {
    header: {
      style: {
        padding: "0",
        minHeight: "inherit",
      },
    },
    subHeader: {
      style: {
        padding: "0",
        justifyContent: "flex-start",
        display: "block",
      },
    },
    headRow: {
      style: {
        color: "#43536D",
        borderColor: "#E5E8F5",
        borderStyle: "solid",
        borderWidth: "1px",
        borderLeftWidth: "0",
        borderRightWidth: "0",
        backgroundColor: "#FCFCFF",
      },
    },
    headCells: {
      style: {
        fontSize: "13px",
        fontWeight: "normal",
        color: "#767778",
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: "#fff",
        },
      },
    },
    rows: {
      style: {
        minHeight: "65px",
        "&:not(:last-of-type)": {
          borderBottomStyle: "solid",
          borderBottomWidth: "1px",
          borderBottomColor: "#E5E8F5",
        },
      },
    },
    cells: {
      style: {
        minHeight: "65px",
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: "#fff",
        },
      },
    },
    pagination: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: "#E5E8F5",
      },
    },
  };

  const dataMember = [
    {
      name: "Member 1",
      calcs: "33",
      invoices: "55",
      revenue: "30",
      performance: "30",
    },
    {
      name: "Member 2",
      calcs: "33",
      invoices: "55",
      revenue: "30",
      performance: "40",
    },
    {
      name: "Member 3",
      calcs: "33",
      invoices: "55",
      revenue: "30",
      performance: "69",
    },
    {
      name: "Member 4",
      calcs: "33",
      invoices: "55",
      revenue: "30",
      performance: "85",
    },
  ];
  const columns = [
    {
      name: "Name",
      selector: "name",
      sortable: true,
      cell: (row) => (
        <div className="d-flex align-items-center">
          {row && row.info && row.info.image ? (
            <div className="user-pic">
              <img src={row.info.image} alt="Image" />
            </div>
          ) : row && row.name && row.name.trim() !== "" ? (
            <div className="team-name-short mr-2">
              {row.info.first_name.charAt(0).toUpperCase() +
                row.info.last_name.charAt(0).toUpperCase()}
            </div>
          ) : (
            <div className="team-name-short mr-2">
              {row && row.name && row.name.trim() === ""
                ? row.email.charAt(0).toUpperCase()
                : null}
            </div>
          )}
          {row && row.name && row.name.trim() !== "" ? (
            <>
              {row.name}
              <small>{row.email}</small>
            </>
          ) : (
            row.email
          )}
        </div>
      ),
    },
    {
      name: "Calcs",
      selector: "calcs",
      sortable: true,
    },
    {
      name: "Quotes",
      selector: "invoices",
      sortable: true,
    },
    {
      name: "Revenue",
      selector: "revenue",
      sortable: true,
    },
    {
      name: "Member perfomance:",
      selector: "performance",
      cell: (row) => (
        <div className="d-flex align-items-center w-100">
          <div className="d-flex align-items-center w-100">
            <div className="persent mr-3">{row?.performance ? (row.performance).toFixed(0) : 0}%</div>
            <ProgressBar now={row?.performance ? (row.performance).toFixed(0) : 0} />
          </div>
        </div>
      ),
    },
  ];

  const columns2 = [
    {
      name: "Title",
      selector: "title",
      sortable: true,
      cell: (row) => (
        <div className="d-flex align-items-center">
          {row && row.info && row.info.image ? (
            <div className="user-pic">
              <img src={row.info.image} alt="Image" />
            </div>
          ) : (
            <div className="team-name-short mr-2">{row.name}</div>
          )}
          {row.name}
        </div>
      ),
    },
    {
      name: "Client",
      selector: "client",
      sortable: true,
    },
    {
      name: "Total",
      selector: "total",
      sortable: true,
    },
    {
      name: "Date",
      selector: "date",
      sortable: true,
    },
    {
      name: "Status:",
      selector: "performance",
      cell: (row) => (
        <div className="d-flex align-items-center w-100 q-status-section">
          <div className={`badge badge-success mr-2`}>{row.status}</div>
          <Button className="btn-icon" variant="outline-light">
            <img src={DownloadIcon} alt="Icon" />
          </Button>
          <Button className="ml-2" variant="outline-light">
            Send
          </Button>
        </div>
      ),
    },
  ];
  const data2 = [
    {
      name: "Member 1",
      client: "Microsoft",
      total: "1000000",
      date: "27.02.2021, 03:52 AM",
      status: "approved",
    },
    {
      name: "Member 2",
      client: "Microsoft",
      total: "1000000",
      date: "27.02.2021, 03:52 AM",
      status: "approved",
    },
  ];

  const demoGraphData = [
    {
      x: Date.UTC(2018, 4, 1),
      y: 300000,
      total: 30000
    },
    {
      x: Date.UTC(2018, 4, 2),
      y: 350000,
      total: 35000
    },
    {
      x: Date.UTC(2018, 4, 3),
      y: 300000,
      total: 30000
    },
    {
      x: Date.UTC(2018, 4, 4),
      y: 450000,
      total: 45000
    },
    {
      x: Date.UTC(2018, 4, 5),
      y: 320000,
      total: 32000
    },
    {
      x: Date.UTC(2018, 4, 6),
      y: 440000,
      total: 44000
    },
    {
      x: Date.UTC(2018, 4, 7),
      y: 380000,
      total: 38000
    },
    {
      x: Date.UTC(2018, 4, 8),
      y: 480000,
      total: 48000
    },
    {
      x: Date.UTC(2018, 4, 9),
      y: 310000,
      total: 31000
    },
    {
      x: Date.UTC(2018, 4, 10),
      y: 400000,
      total: 40000
    },
    {
      x: Date.UTC(2018, 4, 11),
      y: 310000,
      total: 31000
    },
    {
      x: Date.UTC(2018, 4, 12),
      y: 290000,
      total: 29000
    }
  ]

  const graphData = () => {
    var data = [];
    if(approvedQuotations.length > 0){
      approvedQuotations?.forEach((quote) => {
        data.push( 
          {
            x: Date.parse(quote.issue_date),
            y: quote.revenue,
            total: quote.gross_profit
          }
        )
      })
      return data;
    }else{
      return demoGraphData;
    }
  }

  const options = {
    chart: {
      height: 320,
      renderTo: "container",
      type: "spline",
    },
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      type: "datetime",
      month: "%e. %b",
      year: "%b",
      tickWidth: 0,
      lineWidth: 0,
      crosshair: {
        width: 2,
        color: "#7848FF",
        dashStyle: "shortdot",
      },
      labels: {
        style: {
          color: "#43536D",
          textTransform: "uppercase",
          fontWeight: "300",
          fontFamily: "Formular",
        },
      },
    },
    yAxis: {
      gridLineColor: "#E5E8F5",
      gridLineDashStyle: "longdash",
      title: {
        text: "",
      },
      labels: {
        style: {
          color: "#43536D",
          textTransform: "uppercase",
          fontWeight: "300",
          fontFamily: "Formular",
        },
      },
    },
    tooltip: {
      shadow: false,
      backgroundColor: "none",
      borderColor: "none",
      padding: 0,
      borderRadius: "8px",
      className: "chart-tooltip",
      useHTML: true,
      formatter: function () {
        if(this.total){
          return (
            "<div class='custom-graph-tooltip'><div class='px-3 py-2 d-flex'><div><span>Revenue</span>$" +
            this.y.toFixed(2) +
            "</div></div><div class='px-3 py-2 d-flex align-items-end'><div><span>Gross Profit</span>$" +
            this.total.toFixed(2) +
            "</div><small class='percent-up ml-3'>"+((this.total/this.y)*100).toFixed(2)+" %</small></div></div>"
          );
        }
      },
    },
    noData: {
      attr: null,
      position: { x: 0, y: 0, align: "center", verticalAlign: "middle" },
      style: { fontSize: "12px", fontWeight: "bold", color: "#60606a" },
    },
    plotOptions: {
      series: {
        marker: {
          fillColor: "#7848FF",
          shadow: false,
          lineWidth: 2,
          width: 30,
          height: 30,
          lineColor: "#fff",
          enabled: false,
        },
      },
    },
    series: [
      {
        showInLegend: false,
        type: "area",
        lineWidth: 2,
        lineColor: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, "#e80556"], // start
            [0.5, "#95009a"], // middle
            [1, "#6c00dd"], // end
          ],
        },
        fillColor: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, "rgba(149, 0, 154, 0.2)"], // start
            [0.5, "rgba(149, 0, 154, 0.03)"], // middle
            [1, "rgba(255, 255, 255, 0.01)"], // end
          ],
        },
        data: graphData(),
      },
    ],
  };

  let history = useHistory();

  const calculateRevenue = (quotes) => {
    const revenue = quotes?.reduce((a,b) => {
      return a + b.revenue;
    },0)
    setRevenue(revenue);

    const gross_profit = quotes?.reduce((a,b) => {
      return a + b.gross_profit;
    },0)
    setGrossProfit(gross_profit);
  }

  const handleDatepickerFocus = (e) => {
    e.target.parentNode.parentNode.parentNode.classList.add("active");
  };
  const handleDatepickerBlur = (e) => {
    e.target.parentNode.parentNode.parentNode.classList.remove("active");
  };
  const handleDateChange = (date, e) => {
    setStartDate(date);
    e.target.closest(".input-group").classList.remove("active");
  };
  const handleClosePayment = () => {
    localStorage.removeItem("expire");
    setIsPaymentShowing(false);
  };
  const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <div className="d-flex align-items-center justify-content-between px-4">
      <div className="table-filters">
        <Form.Group>
          <Select styles={customControlStyles} placeholder="Status" />
        </Form.Group>
        <Form.Group>
          <Select styles={customControlStyles} placeholder="Revenue" />
        </Form.Group>
        <Form.Group className="datepicker">
          <InputGroup className={`${activeClass ? "" : "input-group"}`}>
            <InputGroup.Prepend>
              <InputGroup.Text className="pr-0">
                <span className="icon"></span>
              </InputGroup.Text>
            </InputGroup.Prepend>
            <DatePicker
              selected={startDate}
              minDate={new Date()}
              className="datepicker-form-control"
              onChange={(date, e) => handleDateChange(date, e)}
              onFocus={handleDatepickerFocus}
              onBlur={handleDatepickerBlur}
            />
          </InputGroup>
        </Form.Group>
      </div>
      <div className="table-filters justify-content-end">
        <Form.Group>
          <FormControl />
        </Form.Group>
      </div>
    </div>
  );
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText]);

  useEffect(() => {
    async function fetchTeam() {
      console.log("props.id", id);
      setFilterVal(id);

      let team = await getTeam(
        JSON.stringify({
          team_id: id,
        })
      );
      if (
        team &&
        team.data &&
        team.data.organization_team_users &&
        team.data.organization_team_users.length > 0
      ) {
        setTeamData(team.data.organization_team_users);
        setTeam(team.data.organization_team);
        setApprovedQuotations(team.data.organization_team_quotations);
        calculateRevenue(team.data.organization_team_quotations);
        console.log(
          "team.data.organization_team_users",
          team.data.organization_team_users,
          team.data.organization_team
        );
      }
    }
    fetchTeam();
    async function fetchSettings() {
      setSettings(await getSettings());
    }
    fetchSettings();
  }, []);

  useEffect(() => {
    if (settings.expire && settings.expire === true) {
      setIsPaymentShowing(true);
    }
  }, [settings]);

  return (
    <div>
      <div className="dashboard-main pb-5">
        <div className="company-info mb-4">
          <Container fluid>
            <div className="d-flex align-items-center mb-4">
              <NavLink to="/dashboard" className="back-btn">
                <img src={chevLeft} alt="icon" />
              </NavLink>

              {settings.data &&
              settings.data.organization_users &&
              settings.data.organization_users.length !== 0 &&
              settings.data.organization_users[0].organization ? (
                <div className="company-name">
                  {settings &&
                  settings.data.organization_users[0].organization.image ? (
                    <div className="user-pic">
                      <img
                        src={
                          settings.data.organization_users[0].organization.image
                        }
                        alt="Image"
                      />
                    </div>
                  ) : (
                    <div className="company-name-short">
                      {settings.data.organization_users[0].organization.name
                        .replace(/[^a-zA-Z- ]/g, "")
                        .match(/\b\w/g)}
                    </div>
                  )}
                  <div>
                    {settings.data.organization_users[0].organization.name}
                    <small className="text-primary">
                      {team && team.name ? team.name : null}
                    </small>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="company-revenue-tabs">
              <Card>
                <Card.Body>
                  {(approvedQuotations.length > 0 && key === "actual-revenue") || (quotations.length > 0 && key === "projected-form") ? "" : <span class="text-note">*Data displayed is just for Demo.</span>}
                  <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
                    <Tab eventKey="actual-revenue" title="Actual Revenue">
                      <Row>
                        <Col lg={4}>
                          <div className="graph-box-holder border d-flex flex-column h-100">
                            <div className="graph-box d-flex align-items-center">
                              <div className="icon-image primary-icon">
                                <img src={BadgeIcon} alt="Icon" />
                              </div>
                              <div>
                                <span className="title">Revenue</span>
                                <div className="g-value">
                                  <span className="text-success mr-1">$</span>
                                {revenue ? revenue.toFixed(2) : <span class="text-muted">1000000</span>}
                                </div>
                              </div>
                            </div>
                            <div className="graph-box d-flex align-items-center">
                              <div className="icon-image info-icon">
                                <img src={GraphIcon} alt="Icon" />
                              </div>
                              <div>
                                <span className="title">Gross Profit</span>
                                <div className="g-value">
                                  <span className="text-success mr-1">$</span>
                                {grossProfit ? grossProfit.toFixed(2) : <span class="text-muted">100000</span>}
                                </div>
                              </div>
                            </div>
                            <div className="graph-box d-flex align-items-center">
                              <div className="icon-image success-icon">
                                <img src={PersentIcon} alt="Icon" />
                              </div>
                              <div>
                                <span className="title">Gross Profit %</span>
                                <div className="g-value">
                                  {grossProfit ? ((grossProfit / revenue)*100).toFixed(2) : <span class="text-muted">10</span>}<span className="text-info"> %</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col lg={8}>
                          {/* <div className="filter-graph">
                            <Select
                              styles={customGraphControlStyles}
                              placeholder="Last Month"
                              isSearchable={false}
                            />
                          </div> */}
                          <div className="graph-box h-100">
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={options}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Tab>
                    <Tab eventKey="projected-form" title="Projected Revenue">
                      <Row>
                        <Col lg={4}>
                          <div className="graph-box d-flex align-items-center">
                            <div className="icon-image primary-icon">
                              <img src={BadgeIcon} alt="Icon" />
                            </div>
                            <div>
                              <span className="title">Revenue</span>
                              <div className="g-value">
                                <span className="text-success mr-1">$</span>
                                {revenue ? revenue.toFixed(2) : <span class="text-muted">1000000</span>}
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="graph-box d-flex align-items-center">
                            <div className="icon-image info-icon">
                              <img src={GraphIcon} alt="Icon" />
                            </div>
                            <div>
                              <span className="title">Gross Profit</span>
                              <div className="g-value">
                                <span className="text-success mr-1">$</span>
                                {grossProfit ? grossProfit.toFixed(2) : <span class="text-muted">100000</span>}
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="graph-box d-flex align-items-center">
                            <div className="icon-image success-icon">
                              <img src={PersentIcon} alt="Icon" />
                            </div>
                            <div>
                              <span className="title">Gross Profit %</span>
                              <div className="g-value">
                                {grossProfit ? ((grossProfit / revenue)*100).toFixed(2) : <span class="text-muted">10</span>}<span className="text-info"> %</span>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Tab>
                  </Tabs>
                </Card.Body>
              </Card>
            </div>
          </Container>
        </div>

        {isAdmin || isOwner ? (
          <Container fluid className="mb-4">
            <div className="datatable-box">
              <div className="datatable-holder">
                <div className="team-list py-3 px-4">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-block team-info">
                      {teamData.length} Members
                    </div>
                  </div>
                </div>
                <DataTable
                  columns={columns}
                  data={teamData}
                  customStyles={customTableStyles}
                  responsive
                  fixedHeader={true}
                  onRowClicked={handleTeamComponent}
                  pagination
                  paginationPerPage={5}
                  paginationRowsPerPageOptions={[5, 15, 25, 50]}
                  paginationComponentOptions={{
                    rowsPerPageText: "Records per page:",
                    rangeSeparatorText: "out of",
                  }}
                />
              </div>
            </div>
          </Container>
        ) : null}
      </div>
      {isPaymentShowing ? (
        <Payment onClose={handleClosePayment} isShowing={isPaymentShowing} />
      ) : null}
    </div>
  );
}

export default Teams;
