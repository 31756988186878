export const abbreviateBigNumber = (num) => {
  return [num, ""];
  let lt_0 = false;
  if (num < 0) {
    lt_0 = true;
    num = Math.abs(num);
  }
  if (num > 999 && num < 1000000) {
    return [lt_0 ? (num / 1000).toFixed(2) * -1 : (num / 1000).toFixed(2), "K"]; // convert to K for number from > 1000 < 1 million
  } else if (num > 1000000) {
    return [
      lt_0 ? (num / 1000000).toFixed(2) * -1 : (num / 1000000).toFixed(2),
      "M",
    ]; // convert to M for number from > 1 million
  } else if (num <= 999) {
    return [lt_0 ? num * -1 : num, ""]; // if value < 1000, nothing to do
  }
};

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
