import { put, takeEvery, all, call } from "redux-saga/effects";
import {
  cities,
  states,
  countries,
  plans,
  roles,
  auth,
  sign_in,
  forgot,
  reset,
  company,
  team,
  sign_in_by_uuid,
  sign_in_by_invitation,
  sign_auth_invitation,
  sign_up_by_uuid,
  resend,
} from "../../core/Service";
import {
  signUpAttemptResponse,
  signUpAttemptResendResponse,
  signUpError,
  companyAttemptResponse,
  signUpInvitationAttemptResponse,
  signUpAuthInvitationAttemptResponse,
  teamAttemptResponse,
  signUpAttemptByUUIDResponse,
} from "./../data/authAction";

import { signInAttemptResponse, signInError } from "./../data/loginAction";
import {
  forgotPasswordAttemptResponse,
  forgotPasswordError,
  resetPasswordError,
  resetPasswordAttemptResponse,
} from "./../data/forgotAction";

function* attemptRegister(action) {
  try {
    const response = yield call(auth, action.payload);
    yield put(signUpAttemptResponse(response.data));
  } catch (error) {
    yield put(signUpError(error.response.data.errors));
  }
}

function* attemptResendEmail(action) {
  try {
    const response = yield call(resend, action.payload);
    yield put(signUpAttemptResendResponse(response.data));
  } catch (error) {
    yield put(signUpError(error.response.data.errors));
  }
}
function* attemptInvitation(action) {
  try {
    const response = yield call(sign_in_by_invitation, action.payload);
    yield put(signUpInvitationAttemptResponse(response.data));
  } catch (error) {
    yield put(signUpError(error.response.data.errors));
  }
}
function* attemptAuthInvitation(action) {
  try {
    const response = yield call(sign_auth_invitation, action.payload);
    yield put(signUpAuthInvitationAttemptResponse(response.data));
  } catch (error) {
    yield put(signUpError(error.response.data.errors));
  }
}
function* attemptCompany(action) {
  try {
    const response = yield call(company, action.payload);
    yield put(companyAttemptResponse(response.data));
  } catch (error) {
    yield put(signUpError(error.response.data.errors));
  }
}
function* attemptAuthUUID(action) {
  try {
    const response = yield call(sign_up_by_uuid, action.payload);
    yield put(signUpAttemptByUUIDResponse(response.data));
  } catch (error) {
    yield put(signUpError(error.response.data.errors));
  }
}

function* attemptTeam(action) {
  try {
    const response = yield call(team, action.payload);
    yield put(teamAttemptResponse(response.data));
  } catch (error) {
    yield put(signUpError(error.response.data.errors));
  }
}
function* attemptLogin(action) {
  try {
    const response = yield call(sign_in, action.payload);
    yield put(signInAttemptResponse(response));
  } catch (error) {
    yield put(signInError(error.response.data.errors));
  }
}
function* attemptLoginByUUID(action) {
  try {
    const response = yield call(sign_in_by_uuid, action.payload);
    yield put(signInAttemptResponse(response));
  } catch (error) {
    yield put(signInError(error.response.data.errors));
  }
}

function* attemptForgot(action) {
  try {
    const response = yield call(forgot, action.payload);
    yield put(forgotPasswordAttemptResponse(response));
  } catch (error) {
    yield put(forgotPasswordError(error.response.data.errors));
  }
}

function* attemptReset(action) {
  try {
    const response = yield call(reset, action.payload);
    yield put(resetPasswordAttemptResponse(response));
  } catch (error) {
    yield put(resetPasswordError(error.response.data.errors));
  }
}

function* attemptRegisterClear() {
  try {
    yield put(signUpAttemptResponse([]));
    yield put(companyAttemptResponse([]));
    yield put(teamAttemptResponse([]));
    yield put(signUpInvitationAttemptResponse([]));
    yield put(signUpAuthInvitationAttemptResponse([]));
    yield put(signUpAttemptByUUIDResponse([]));
    yield put(signUpAttemptResendResponse([]));
  } catch (error) {}
}
function* attemptLogout() {
  try {
    yield put(signInAttemptResponse([]));
    yield put(forgotPasswordAttemptResponse([]));
  } catch (error) {}
}

function* actionWatcher() {
  yield all([takeEvery("SIGNUP_ATTEMPT", attemptRegister)]);
  yield all([takeEvery("SIGNUP_ATTEMPT_RESEND", attemptResendEmail)]);
  yield all([takeEvery("SIGNUP_ATTEMPT_RESPONSE_CLEAR", attemptRegisterClear)]);
  yield all([takeEvery("SIGNUP_INVITATION_ATTEMPT", attemptInvitation)]);
  yield all([
    takeEvery("SIGNUP_AUTH_INVITATION_ATTEMPT", attemptAuthInvitation),
  ]);
  yield all([takeEvery("SIGNUP_ATTEMPT_BY_UUID", attemptAuthUUID)]);
  yield all([takeEvery("COMPANY_ATTEMPT", attemptCompany)]);
  yield all([takeEvery("TEAM_ATTEMPT", attemptTeam)]);
  yield all([takeEvery("SIGNIN_ATTEMPT", attemptLogin)]);
  yield all([takeEvery("SIGNIN_ATTEMPT_BY_UUID", attemptLoginByUUID)]);
  yield all([takeEvery("FORGOT_PASSWORD_ATTEMPT", attemptForgot)]);
  yield all([takeEvery("RESET_PASSWORD_ATTEMPT", attemptReset)]);
  yield all([takeEvery("SIGNOUT_ATTEMPT", attemptLogout)]);
}
export default function* authSaga() {
  yield all([actionWatcher()]);
}
