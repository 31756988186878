import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import { useHistory, useParams, NavLink } from "react-router-dom";
import '../assets/css/signup.css'
import {
  Button,
  Row,
  Col,
  Tabs,
  Tab,
  Accordion,
  ListGroup,
  Nav,
  Modal,
  FormControl,
  Table,
  Dropdown,
} from "react-bootstrap";
import { calculateStipend } from "./globalAction";


const GetSummayModalContent = ({
  showSummaryModal,
  handleSummaryModal,
  calculations,
  editPersonList,
  handlePersonChanges,
  Constants,
  startDate,
  setStartDate,
  setStartDate2,
  startDate2,
  setEditPersonList,
  organizationSetting,
  handleCheckboxChange,
  setShowSummaryModal,
  id,
  editCalculation,
  defaultFunctions,
  personsBaseHourCalculations
}) => {
  const history = useHistory();


  const [stipendata, setStipendData] = useState({})

  useEffect(() => {
    testAPi()
  }, [editPersonList])

  const testAPi = async () => {
    try {
      let data = {
        person_list: editPersonList,
        name: editPersonList[0].job_name,
      };

      let result = await calculateStipend(JSON.stringify(data));
      setStipendData(result?.data?.stipend)
      console.log(result?.data?.stipend);
    } catch (error) {
      console.error("An error occurred while processing the API request:", error);
    }
  };

  return (
    <Modal
      animation={false}
      className="summary-modal summary-modal-css"
      size="lg"
      centered
      show={showSummaryModal}
      onHide={handleSummaryModal}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="summary-body custom-tabs mt-1">
          {calculations &&
            calculations.data &&
            calculations.data.calculation.person_list &&
            calculations.data.calculation.person_list.length > 0 ? (
            <Tabs
              defaultActiveKey="person_0"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              {editPersonList?.map((person, idx) => {
                return (
                  <Tab eventKey={"person_" + idx} title={person.job_name}>
                    <div className="summary-pay box">
                      <Tab.Container
                        id="left-tabs-example"
                        defaultActiveKey={"first_" + idx}
                      >
                        <Row>
                          <Col sm={3} className="custom-bg-nav">
                            <Nav variant="pills" className="flex-column">
                              <Nav.Item>
                                <Nav.Link eventKey={"first_" + idx}>
                                  Pay
                                </Nav.Link>

                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey={"second_" + idx}>
                                  Bill
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey={"third_" + idx}>
                                  Assigment
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey={"four_" + idx}>
                                  Benifits
                                </Nav.Link>
                              </Nav.Item>

                              <Nav.Item>
                                <Nav.Link eventKey={"five_" + idx}>
                                  Other Costs
                                </Nav.Link>
                              </Nav.Item>
                            </Nav>
                          </Col>
                          <Col sm={9}>
                            <Tab.Content>
                              <Tab.Pane eventKey={"first_" + idx}>
                                <h4>Pay</h4>


                                <ListGroup variant="flush">
                                  <ListGroup.Item>
                                    <strong>Compensation type:</strong>
                                    <select
                                      name="compensation_type"
                                      value={person.pay.compensation_type}
                                      style={{ width: "130px", height: "27px" }}
                                      onChange={(e) =>
                                        handlePersonChanges(
                                          idx,
                                          "pay",
                                          "compensation_type",
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="hourly">hourly</option>
                                      <option value="salary">Salary</option>
                                    </select>

                                  </ListGroup.Item>
                                  <ListGroup.Item>

                                    {person.pay.compensation_type ==
                                      "hourly" ? (
                                      <strong>Pay Rate ($):</strong>
                                    ) : (
                                      <strong>Salary ($):</strong>
                                    )}
                                    <input
                                    type="number"
                                      name="pay_rate"
                                      value={person.pay.pay_rate}
                                      onChange={(e) =>
                                        handlePersonChanges(
                                          idx,
                                          "pay",
                                          "pay_rate",
                                          e.target.value
                                        )
                                      }
                                      style={{ width: "130px", height: "27px" }}
                                    />
                                  </ListGroup.Item>

                                  <ListGroup.Item>
                                    <div className="d-flex flex-column" style={{ width: "100%" }}>
                                      <div className="d-flex justify-content-between">
                                        <strong>Add stipend:</strong>

                                        <select
                                          name="stepend"
                                          value={person.pay.stepend}
                                          onChange={(e) =>
                                            handlePersonChanges(
                                              idx,
                                              "pay",
                                              "stepend",
                                              e.target.value==="true"
                                            )
                                          }
                                          style={{ width: "130px", height: "27px" }}
                                        >
                                          <option value="true">ON</option>
                                          <option value="false">OFF</option>
                                        </select>
                                      </div>
                                      
                                      {person?.pay?.stepend === "true" || person?.pay?.stepend === true ? (
                                        <>
                                          <div className="d-flex flex-column p-2" style={{ background: "#F8F9FF" }}>
                                            <div className="d-flex justify-content-between">
                                              <p>M&I:</p>
                                              <p>$ {stipendata[idx]?.[0]?.data?.m_i} USD</p>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                              <p>Lodging:</p>
                                              <p>$ {stipendata[idx]?.[0]?.data?.lodging} USD</p>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                              <p>Total Daily:</p>
                                              <p>$ {stipendata[idx]?.[0]?.data?.total_daily} USD</p>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                              <p>Weekly:</p>
                                              <p>$ {stipendata[idx]?.[0]?.data?.weekly} USD</p>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                              <p>Total Assignment:</p>
                                              <p>$ {stipendata[idx]?.[0]?.data?.total_assignment} USD</p>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                              <p>No. of Shifts in Assignment:</p>
                                              <p>$ {stipendata[idx]?.[0]?.data?.number_of_shifts}</p>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                              <p>Hours per Shift:</p>
                                              <p>$ {stipendata[idx]?.[0]?.data?.hours_per_shift}</p>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                              <p>Total No. of Hours:</p>
                                              <p>$ {stipendata[idx]?.[0]?.data?.total_numbers_of_hours}</p>
                                            </div>
                                          </div>
                                        </>
                                      ) : null}

                                    </div>


                                  </ListGroup.Item>
                                  {person.pay.compensation_type != "hourly" ? (
                                    <>
                                      <ListGroup.Item>
                                        <strong>Salary Type:</strong>
                                        <select
                                          name="salary_type"
                                          value={person.pay.salary_type}
                                          onChange={(e) =>
                                            handlePersonChanges(
                                              idx,
                                              "pay",
                                              "salary_type",
                                              e.target.value
                                            )
                                          }
                                          style={{
                                            width: "130px",
                                            height: "27px",
                                          }}
                                        >
                                          {Constants.salary_type.map((e) => {
                                            return (
                                              <option value={e.value}>
                                                {e.label}
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </ListGroup.Item>
                                    </>
                                  ) : null}

                                  <ListGroup.Item>

                                    <div className="d-flex flex-column" style={{ width: "100%" }}>
                                      <div className="d-flex justify-content-between">
                                        <strong>Discount:</strong>
                                        <input
                                          name="discount"
                                          value={
                                            person?.pay?.discount
                                          }
                                          onChange={(e) => {
                                            const newValue = e.target.value;
                                            // Check if the entered value is a valid number and less than or equal to 100
                                            if (!isNaN(newValue) && newValue <= 100) {
                                              handlePersonChanges(idx, "pay", "discount", newValue);
                                              testAPi();
                                            }
                                          }}
                                          onKeyDown={(e) => {
                                            // Prevent the user from entering more than 100
                                            if (e.key === '.' || e.key === ',' || e.key === '-' || e.key === '+' || e.key === 'e' || e.key === 'E') {
                                              // Allow specific characters that are allowed in numbers
                                              return;
                                            }
                                            if ((e.key < '0' || e.key > '9') && e.key !== 'Backspace' && e.key !== 'Delete' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight' && e.key !== 'Tab') {
                                              e.preventDefault();
                                            }
                                          }}
                                          style={{ width: "130px", height: "27px" }}
                                        />
                                      </div>

                                      {person?.pay?.stepend == "true" || person?.pay?.stepend === true  ?
                                        <div className="d-flex flex-column p-2" style={{ background: "#F8F9FF" }}>
                                          <div className="d-flex justify-content-between">
                                            <p>Total Stipend:</p>
                                            <p>$ {stipendata[idx]?.[0]?.data?.stipend_total} USD</p>
                                          </div>
                                          {stipendata[idx]?.[0]?.data?.stipend_per_shift != "Infinity" && stipendata[idx]?.[0]?.data?.stipend_per_shift != "NaN" ?
                                            <div className="d-flex justify-content-between">
                                              <p>Stipend per shift:</p>
                                              <p>$ {stipendata[idx]?.[0]?.data?.stipend_per_shift} USD</p>
                                            </div> : null}
                                          {stipendata[idx]?.[0]?.data?.stipend_per_hour != "0.00" ?
                                            <div className="d-flex justify-content-between">
                                              <p>Stipend per Hour:</p>
                                              <p>$ {stipendata[idx]?.[0]?.data?.stipend_per_hour} USD</p>
                                            </div> :
                                            <div className="d-flex justify-content-between">
                                              <p>Stipend per Week:</p>
                                              <p>$ {(parseInt(stipendata[idx]?.[0]?.data?.weekly.replace(/,/g, '')) - (parseInt(stipendata[idx]?.[0]?.data?.weekly.replace(/,/g, '')) * ((person?.pay?.discount || 0) / 100))).toFixed(2)} USD</p>


                                            </div>}
                                        </div> : null}
                                    </div>
                                  </ListGroup.Item>
                                  <ListGroup.Item>
                                    <strong>Exempt:</strong>
                                    <select
                                      name="exampt"
                                      value={person.pay.exampt}
                                      onChange={(e) =>
                                        handlePersonChanges(
                                          idx,
                                          "pay",
                                          "exampt",
                                          e.target.value==="true"
                                        )
                                      }
                                      style={{ width: "130px", height: "27px" }}
                                    >
                                      <option value="true">ON</option>
                                      <option value="false">OFF</option>
                                    </select>
                                  </ListGroup.Item>
                                  <ListGroup.Item>
                                    <strong>Pay frequency:</strong>
                                    <select
                                      name="pay_frequency"
                                      value={person.pay.pay_frequency}
                                      onChange={(e) =>
                                        handlePersonChanges(
                                          idx,
                                          "pay",
                                          "pay_frequency",
                                          e.target.value
                                        )
                                      }
                                      style={{ width: "130px", height: "27px" }}
                                    >
                                      <option value="monthly">Monthly</option>
                                      <option value="semi-monthly">
                                        Semi Monthly
                                      </option>
                                      <option value="bi-weekly">
                                        Bi Weekly
                                      </option>
                                      <option value="weekly">Weekly</option>
                                    </select>
                                    {/* {person.pay.pay_frequency} */}
                                  </ListGroup.Item>
                                </ListGroup>
                              </Tab.Pane>

                              <Tab.Pane eventKey={"second_" + idx}>
                                <h4>Bill</h4>
                                <ListGroup variant="flush">
                                  <ListGroup.Item>
                                    <strong>Compensation type:</strong>

                                    <select
                                      name="compensation_type"
                                      style={{ width: "130px", height: "27px" }}
                                      value={person.bill.compensation_type}
                                      onChange={(e) =>
                                        handlePersonChanges(
                                          idx,
                                          "bill",
                                          "compensation_type",
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="markup">Markup</option>
                                      <option value="bill_rate">
                                        Bill Rate
                                      </option>
                                    </select>
                                  </ListGroup.Item>

                                  {person.bill.compensation_type ===
                                    "markup" ? (
                                    <ListGroup.Item>
                                      <strong>Markup:</strong>
                                      <input
                                        name="markup"
                                        value={person.bill.markup}
                                        onChange={(e) =>
                                          handlePersonChanges(
                                            idx,
                                            "bill",
                                            "markup",
                                            e.target.value
                                          )
                                        }
                                        style={{ width: "130px", height: "27px" }}
                                      />
                                    </ListGroup.Item>
                                  ) : (
                                    <ListGroup.Item>
                                      <strong>Bill Rate:</strong>
                                      <input
                                        name="bill_rate"
                                        value={person.bill.bill_rate}
                                        style={{ width: "130px", height: "27px" }}
                                        onChange={(e) =>
                                          handlePersonChanges(
                                            idx,
                                            "bill",
                                            "bill_rate",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </ListGroup.Item>
                                  )}
                                  {person.pay.exampt ? (
                                    ""
                                  ) : (
                                    <ListGroup.Item>
                                      <strong> Overtime Markup:</strong>
                                      <input
                                      type="number"
                                        name="overtime"
                                        value={person.bill.overtime}
                                        style={{ width: "130px", height: "27px" }}
                                        onChange={(e) =>
                                          handlePersonChanges(
                                            idx,
                                            "bill",
                                            "overtime",
                                            e.target.value
                                          )
                                        }
                                      />
                                      {/* {person.bill.overtime} */}
                                    </ListGroup.Item>
                                  )}
                                </ListGroup>
                              </Tab.Pane>

                              <Tab.Pane eventKey={"third_" + idx}>
                                <h4>Assigment</h4>
                                <ListGroup variant="flush">
                                  <ListGroup.Item>
                                    <strong>Have start / end date:</strong>
                                    <select
                                      name="have_start_end_date"
                                      value={
                                        person.assignment.have_start_end_date
                                      }
                                      onChange={(e) =>
                                        handlePersonChanges(
                                          idx,
                                          "assignment",
                                          "have_start_end_date",
                                          e.target.value
                                        )
                                      }
                                      style={{ width: "130px" }}
                                    >
                                      <option value="yes">Yes</option>
                                      <option value="no">No</option>
                                    </select>
                                  </ListGroup.Item>

                                  {person.assignment.have_start_end_date ===
                                    "no" ? (
                                    <ListGroup.Item>
                                      <strong>
                                        Estimated assigment length:
                                      </strong>
                                      <input
                                        name="estimated_assigment_length"
                                        type="number"
                                        value={
                                          person.assignment
                                            .estimated_assigment_length
                                        }
                                        style={{ width: "80px" }}
                                        onChange={(e) =>
                                          handlePersonChanges(
                                            idx,
                                            "assignment",
                                            "estimated_assigment_length",
                                            e.target.value
                                          )
                                        }
                                      />

                                      <select
                                        name="estimated_assigment_period"
                                        value={
                                          person.assignment
                                            .estimated_assigment_period
                                        }
                                        onChange={(e) =>
                                          handlePersonChanges(
                                            idx,
                                            "assignment",
                                            "estimated_assigment_period",
                                            e.target.value
                                          )
                                        }
                                      >
                                        <option value="week">Week</option>
                                        <option value="month">Month</option>
                                        <option value="year">Year</option>
                                      </select>
                                    </ListGroup.Item>
                                  ) : (
                                    <>
                                      <ListGroup.Item className="datepicker">
                                        <strong>Start date:</strong>
                                        <DatePicker
                                          selected={startDate}
                                          onChange={(date) => {
                                            setStartDate(date);
                                            console.log(date);
                                            handlePersonChanges(
                                              idx,
                                              "assignment",
                                              "start_date",
                                              date
                                            );
                                          }}
                                        />
                                      </ListGroup.Item>
                                      <ListGroup.Item>
                                        <strong>End date:</strong>

                                        <DatePicker
                                          selected={startDate2}
                                          onChange={(date) => {
                                            setStartDate2(date);
                                            handlePersonChanges(
                                              idx,
                                              "assignment",
                                              "end_date",
                                              date
                                            );
                                          }}
                                        />
                                      </ListGroup.Item>
                                    </>
                                  )}

                                  <ListGroup.Item>
                                    <strong>Schedule:</strong>
                                    <select
                                      name="shadule"
                                      value={person.assignment.shadule}
                                      onChange={(e) =>
                                        handlePersonChanges(
                                          idx,
                                          "assignment",
                                          "shadule",
                                          e.target.value
                                        )
                                      }
                                      style={{ width: "130px" }}
                                    >
                                      <option value="shift">Shift</option>
                                      <option value="part-time">
                                        Part Time
                                      </option>
                                      <option value="full-time">
                                        Full Time
                                      </option>
                                    </select>
                                  </ListGroup.Item>

                                  {person.assignment.shadule === "shift" ? (
                                    <>
                                      <ListGroup.Item>
                                        <strong>Hours per shift:</strong>
                                        <input
                                          type="number"
                                          name="hours_per_week"
                                          value={
                                            person.assignment.hours_per_week
                                          }
                                          style={{ width: "130px" }}
                                          onChange={(e) =>
                                            handlePersonChanges(
                                              idx,
                                              "assignment",
                                              "hours_per_week",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </ListGroup.Item>
                                      <ListGroup.Item>
                                        <strong>Shift per week:</strong>
                                        <input
                                          type="number"
                                          name="shift_per_week"
                                          value={
                                            person.assignment.shift_per_week
                                          }
                                          style={{ width: "130px" }}
                                          onChange={(e) =>
                                            handlePersonChanges(
                                              idx,
                                              "assignment",
                                              "shift_per_week",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </ListGroup.Item>
                                    </>
                                  ) : person.assignment.shadule ===
                                    "part-time" ? (
                                    <ListGroup.Item>
                                      <strong>Hours per week:</strong>
                                      <input
                                        type="number"
                                        name="hours_per_week"
                                        value={person.assignment.hours_per_week}
                                        style={{ width: "130px" }}
                                        onChange={(e) =>
                                          handlePersonChanges(
                                            idx,
                                            "assignment",
                                            "hours_per_week",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </ListGroup.Item>
                                  ) : (
                                    <></>
                                  )}
                                </ListGroup>
                              </Tab.Pane>

                              <Tab.Pane eventKey={"four_" + idx}>
                                <h4>Benifits</h4>
                                <ListGroup variant="flush">
                                  <ListGroup.Item>
                                    <div className="d-flex flex-column w-100">
                                      <div className="d-flex justify-content-between">
                                        <strong>Holiday pay:</strong>
                                        <select
                                          name="holiday_pay"
                                          value={person.benifits.holiday_pay}
                                          onChange={(e) => {
                                            if (e.target.value == "false")
                                              setEditPersonList((prevState) => {
                                                const newState = [...prevState];
                                                newState[idx]["benifits"][
                                                  "holiday_paid_time"
                                                ] = "";
                                                newState[idx]["benifits"][
                                                  "holiday_type"
                                                ] = "";
                                                return newState;
                                              });
                                            handlePersonChanges(
                                              idx,
                                              "benifits",
                                              "holiday_pay",
                                              e.target.value==="true"
                                            );}}
                                          style={{ width: "130px" }}
                                        >
                                          <option value="false">No</option>
                                          <option value="true">Yes</option>
                                        </select>
                                      </div>

                                      {person.benifits.holiday_pay == "true" ? (
                                        <div className="d-flex flex-column w-100 mt-2">
                                          <div className="d-flex justify-content-between">
                                            <strong>
                                              Number of Holiday Days:
                                            </strong>

                                            <input
                                              type="number"
                                              name="holiday_paid_time"
                                              value={
                                                person.benifits
                                                  .holiday_paid_time
                                              }
                                              style={{ width: "130px" }}
                                              onChange={(e) =>
                                                handlePersonChanges(
                                                  idx,
                                                  "benifits",
                                                  "holiday_paid_time",
                                                  e.target.value
                                                )
                                              }
                                            />
                                            <select
                                              name="holiday_type"
                                              value={
                                                person.benifits.holiday_type
                                              }
                                              onChange={(e) =>
                                                handlePersonChanges(
                                                  idx,
                                                  "benifits",
                                                  "holiday_type",
                                                  e.target.value
                                                )
                                              }
                                              style={{ width: "130px" }}
                                              placeholder="Per year"
                                            >
                                              <option value="" disabled>
                                                Select
                                              </option>
                                              <option value="per-year">
                                                Per Year
                                              </option>
                                              <option value="per">
                                                Total Assignment
                                              </option>
                                            </select>
                                          </div>
                                        </div>
                                      ) : null}
                                    </div>
                                  </ListGroup.Item>

                                  <ListGroup.Item>
                                    <div className="d-flex flex-column w-100">
                                      <div className="d-flex justify-content-between">
                                        <strong>PTO/Vacation/Sick:</strong>

                                        <select
                                          name="paid_time"
                                          value={person.benifits.paid_time}
                                          onChange={(e) => {
                                            if (e.target.value == "false")
                                              setEditPersonList((prevState) => {
                                                const newState = [...prevState];
                                                newState[idx]["benifits"][
                                                  "paid_time_number_of_days"
                                                ] = "";
                                                newState[idx]["benifits"][
                                                  "pto_type"
                                                ] = "";
                                                return newState;
                                              });
                                            handlePersonChanges(
                                              idx,
                                              "benifits",
                                              "paid_time",
                                              e.target.value==="true"
                                            );
                                          }}
                                          style={{ width: "130px" }}
                                        >
                                          <option value="false">No</option>
                                          <option value="true">Yes</option>
                                        </select>
                                      </div>

                                      {person.benifits.paid_time == "true" ? (
                                        <div className="d-flex flex-column w-100 mt-2">
                                          <div className="d-flex justify-content-between">
                                            <strong>
                                              Number of Holiday Days:
                                            </strong>

                                            <input
                                              type="number"
                                              name="paid_time_number_of_days"
                                              value={
                                                person.benifits
                                                  .paid_time_number_of_days
                                              }
                                              style={{ width: "130px" }}
                                              onChange={(e) =>
                                                handlePersonChanges(
                                                  idx,
                                                  "benifits",
                                                  "paid_time_number_of_days",
                                                  e.target.value
                                                )
                                              }
                                            />
                                            <select
                                              name="pto_type"
                                              value={person.benifits.pto_type}
                                              onChange={(e) =>
                                                handlePersonChanges(
                                                  idx,
                                                  "benifits",
                                                  "pto_type",
                                                  e.target.value
                                                )
                                              }
                                              style={{ width: "130px" }}
                                              placeholder="Per year"
                                            >
                                              <option value="" disabled>
                                                Select
                                              </option>
                                              <option value="per-year">
                                                Per Year
                                              </option>
                                              <option value="per-assignment">
                                                Total Assignment
                                              </option>
                                            </select>
                                          </div>
                                        </div>
                                      ) : null}
                                    </div>
                                  </ListGroup.Item>

                                  <ListGroup.Item>
                                    <div className="d-flex flex-column w-100">
                                      <div className="d-flex justify-content-between">
                                        <strong>401 k:</strong>
                                        <select
                                          name="k_401"
                                          value={person.benifits.k_401}
                                          onChange={(e) => {
                                            if (e.target.value == "false")
                                              setEditPersonList((prevState) => {
                                                const newState = [...prevState];
                                                newState[idx]["benifits"][
                                                  "k_401_number_of_days"
                                                ] = "";
                                                return newState;
                                              });
                                            handlePersonChanges(
                                              idx,
                                              "benifits",
                                              "k_401",
                                              e.target.value==="true"
                                            );
                                          }}
                                          style={{ width: "130px" }}
                                        >
                                          <option value="false">No</option>
                                          <option value="true">Yes</option>
                                        </select>
                                      </div>

                                      {person.benifits.k_401 == "true" ? (
                                        <div className="d-flex flex-column w-100 mt-2">
                                          <div className="d-flex justify-content-between">
                                            <strong>
                                              Employer Contribution (%):
                                            </strong>

                                            <input
                                              type="number"
                                              name="k_401_number_of_days"
                                              value={
                                                person.benifits
                                                  .k_401_number_of_days
                                              }
                                              style={{ width: "130px" }}
                                              onChange={(e) =>
                                                handlePersonChanges(
                                                  idx,
                                                  "benifits",
                                                  "k_401_number_of_days",
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      ) : null}
                                    </div>
                                  </ListGroup.Item>

                                  <ListGroup.Item>
                                    <div className="d-flex flex-column w-100">
                                      <div className="d-flex justify-content-between">
                                        <strong>SPTO (Sick Leave Laws):</strong>
                                        <select
                                          name="spto"
                                          value={person.benifits.spto}
                                          onChange={(e) =>
                                            handlePersonChanges(
                                              idx,
                                              "benifits",
                                              "spto",
                                              e.target.value==="true"
                                            )
                                          }
                                          style={{ width: "130px" }}
                                        >
                                          <option value="false">No</option>
                                          <option value="true">Yes</option>
                                        </select>
                                      </div>
                                    </div>
                                  </ListGroup.Item>

                                  <ListGroup.Item>
                                    <div className="d-flex flex-column w-100">
                                      <div className="d-flex justify-content-between">
                                        <strong>Bonus:</strong>

                                        <select
                                          name="bonus"
                                          value={person.benifits.bonus}
                                          onChange={(e) => {
                                            if (e.target.value == "false")
                                              setEditPersonList((prevState) => {
                                                const newState = [...prevState];
                                                newState[idx]["benifits"][
                                                  "bonus_type"
                                                ] = "";
                                                newState[idx]["benifits"][
                                                  "bonus_pay"
                                                ] = "";
                                                return newState;
                                              });
                                            handlePersonChanges(
                                              idx,
                                              "benifits",
                                              "bonus",
                                              e.target.value==="true"
                                            );
                                          }}
                                          style={{ width: "130px" }}
                                        >
                                          <option value="false">No</option>
                                          <option value="true">Yes</option>
                                        </select>
                                      </div>

                                      {person.benifits.bonus == "true" ? (
                                        <div className="d-flex flex-column w-100 mt-2">
                                          <div className="d-flex justify-content-between">
                                            <strong>Pay:</strong>

                                            <input
                                              type="number"
                                              name="bonus_pay"
                                              value={person.benifits.bonus_pay}
                                              style={{ width: "130px" }}
                                              onChange={(e) =>
                                                handlePersonChanges(
                                                  idx,
                                                  "benifits",
                                                  "bonus_pay",
                                                  e.target.value
                                                )
                                              }
                                            />
                                            <select
                                              name="bonus_type"
                                              value={person.benifits.bonus_type}
                                              onChange={(e) =>
                                                handlePersonChanges(
                                                  idx,
                                                  "benifits",
                                                  "bonus_type",
                                                  e.target.value
                                                )
                                              }
                                              style={{ width: "130px" }}
                                              placeholder="Per year"
                                            >
                                              <option value="" disabled>
                                                Select
                                              </option>
                                              <option value="%">%</option>
                                              <option value="$">$</option>
                                            </select>
                                          </div>
                                        </div>
                                      ) : null}
                                    </div>
                                  </ListGroup.Item>

                                  <ListGroup.Item>
                                    <div className="d-flex flex-column w-100">
                                      <div className="d-flex justify-content-between">
                                        <strong>Healthcare plan:</strong>

                                        <select
                                          name="healthcare_plan"
                                          value={
                                            person.benifits.healthcare_plan
                                          }
                                          onChange={(e) => {
                                            if (e.target.value == "false")
                                              setEditPersonList((prevState) => {
                                                const newState = [...prevState];
                                                newState[idx]["benifits"][
                                                  "healthcare_package"
                                                ] = "";
                                                newState[idx]["benifits"][
                                                  "healthcare_package_type"
                                                ] = "";
                                                return newState;
                                              });
                                            handlePersonChanges(
                                              idx,
                                              "benifits",
                                              "healthcare_plan",
                                              e.target.value==="true"
                                            );
                                          }}
                                          style={{ width: "130px" }}
                                        >
                                          <option value="false">No</option>
                                          <option value="true">Yes</option>
                                        </select>
                                      </div>

                                      {person.benifits.healthcare_plan ==
                                        "true" ? (
                                        <>
                                          <div className="d-flex flex-column w-100 mt-2">
                                            <div className="d-flex justify-content-between">
                                              <select
                                                name="healthcare_package"
                                                value={
                                                  person.benifits
                                                    .healthcare_package
                                                }
                                                onChange={(e) =>
                                                  handlePersonChanges(
                                                    idx,
                                                    "benifits",
                                                    "healthcare_package",
                                                    e.target.value
                                                  )
                                                }
                                                style={{ width: "48%" }}
                                              >
                                                <option value="" disabled>
                                                  Select
                                                </option>
                                                <option value="%">
                                                  Package 1
                                                </option>
                                                <option value="$">
                                                  Package 2
                                                </option>
                                              </select>

                                              <select
                                                name="healthcare_package_type"
                                                value={
                                                  person.benifits
                                                    .healthcare_package_type
                                                }
                                                onChange={(e) =>
                                                  handlePersonChanges(
                                                    idx,
                                                    "benifits",
                                                    "healthcare_package_type",
                                                    e.target.value
                                                  )
                                                }
                                                style={{ width: "48%" }}
                                              >
                                                <option value="" disabled>
                                                  Select
                                                </option>
                                                <option value="1">
                                                  Employee Only Monthly
                                                </option>
                                                <option value="2">
                                                  Family Monthly Premium
                                                </option>
                                              </select>
                                            </div>
                                          </div>
                                        </>
                                      ) : null}
                                    </div>
                                  </ListGroup.Item>
                                </ListGroup>
                              </Tab.Pane>

                              <Tab.Pane eventKey={"five_" + idx}>
                                <h4>Other Costs</h4>

                                <ListGroup variant="flush">
                                  <ListGroup.Item>
                                    <div className="d-flex flex-column w-100">
                                      <div className="d-flex justify-content-between">
                                        <strong>Payment Terms:</strong>
                                      </div>
                                      <div className="d-flex flex-column w-100 mt-2">
                                        <div className="d-flex justify-content-between">
                                          <strong>No of Days</strong>
                                          <input
                                            type="number"
                                            name="number_of_days"
                                            value={
                                              person?.othercost?.number_of_days
                                            }
                                            style={{ width: "130px" }}
                                            onChange={(e) =>
                                              handlePersonChanges(
                                                idx,
                                                "othercost",
                                                "number_of_days",
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </ListGroup.Item>

                                  <ListGroup.Item>
                                    <div className="d-flex flex-column w-100">
                                      <div className="d-flex justify-content-between">
                                        <strong>Interest:</strong>
                                      </div>

                                      <div className="d-flex flex-column w-100 mt-2">
                                        <div className="d-flex flex-wrap">
                                          {organizationSetting?.costs?.interest?.map(
                                            (e, index) => {
                                              return (
                                                <>
                                                  <label className="d-flex  align-items-center w-50">
                                                    <input
                                                      type="checkbox"
                                                      value={e.id}
                                                      checked={person?.othercost?.costTypes_and_ids?.interest?.includes(
                                                        JSON.stringify(e.id)
                                                      )}
                                                      onChange={(ele) => {
                                                        handleCheckboxChange(
                                                          ele,
                                                          idx,
                                                          e,
                                                          "interest"
                                                        );
                                                      }}
                                                    />
                                                    <p className="pl-2 m-0">
                                                      {" "}
                                                      {`${e.name} (${e.percent}%)`}
                                                    </p>
                                                  </label>
                                                </>
                                              );
                                            }
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </ListGroup.Item>

                                  <ListGroup.Item>
                                    <div className="d-flex flex-column w-100">
                                      <div className="d-flex justify-content-between">
                                        <strong>Payrolling Cost:</strong>
                                      </div>

                                      <div className="d-flex flex-column w-100 mt-2">
                                        <div className="d-flex flex-wrap">
                                          {organizationSetting?.costs?.payrolling_cost?.map(
                                            (e, index) => {
                                              return (
                                                <>
                                                  <label className="d-flex  align-items-center w-50">
                                                    <input
                                                      type="checkbox"
                                                      checked={person?.othercost?.costTypes_and_ids?.payrolling_cost?.includes(
                                                        JSON.stringify(e.id)
                                                      )}
                                                      onChange={(ele) => {
                                                        handleCheckboxChange(
                                                          ele,
                                                          idx,
                                                          e,
                                                          "payrolling_cost"
                                                        );
                                                      }}
                                                    />
                                                    <p className="pl-2 m-0">
                                                      {" "}
                                                      {`${e.name} (${e.percent}%)`}
                                                    </p>
                                                  </label>
                                                </>
                                              );
                                            }
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </ListGroup.Item>

                                  <ListGroup.Item>
                                    <div className="d-flex flex-column w-100">
                                      <div className="d-flex justify-content-between">
                                        <strong>Drug Screen Cost:</strong>
                                      </div>

                                      <div className="d-flex flex-column w-100 mt-2">
                                        <div className="d-flex flex-wrap">
                                          {organizationSetting?.costs?.drug_screen_cost?.map(
                                            (e, index) => {
                                              return (
                                                <>
                                                  <label className="d-flex  align-items-center w-50">
                                                    <input
                                                      type="checkbox"
                                                      checked={person?.othercost?.costTypes_and_ids?.drug_screen_cost?.includes(
                                                        JSON.stringify(e.id)
                                                      )}
                                                      onChange={(ele) => {
                                                        handleCheckboxChange(
                                                          ele,
                                                          idx,
                                                          e,
                                                          "drug_screen_cost"
                                                        );
                                                      }}
                                                    />
                                                    <p className="pl-2 m-0">
                                                      {" "}
                                                      {`${e.name} (${e.percent}%)`}
                                                    </p>
                                                  </label>
                                                </>
                                              );
                                            }
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </ListGroup.Item>

                                  <ListGroup.Item>
                                    <div className="d-flex flex-column w-100">
                                      <div className="d-flex justify-content-between">
                                        <strong>Liability Insurance:</strong>
                                      </div>

                                      <div className="d-flex flex-column w-100 mt-2">
                                        <div className="d-flex flex-wrap">


                                          {
                                            organizationSetting?.costs?.liability_insurance?.map((e, index) => {
                                              const isChecked = person?.othercost?.costTypes_and_ids?.liability_insurance?.includes(JSON.stringify(e.id));

                                              return (
                                                <label className="d-flex align-items-center w-50" key={index}>
                                                  <input
                                                    type="checkbox"
                                                    checked={isChecked}
                                                    onChange={(ele) => {
                                                      console.log(ele.target.checked)
                                                      console.log(isChecked)

                                                      handleCheckboxChange(
                                                        ele,
                                                        idx,
                                                        e,
                                                        "liability_insurance"
                                                      )
                                                    }}
                                                  />
                                                  <p className="pl-2 m-0">{`${e.name} (${e.percent}%)`}</p>
                                                </label>
                                              );
                                            })
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </ListGroup.Item>

                                  <ListGroup.Item>
                                    <div className="d-flex flex-column w-100">
                                      <div className="d-flex justify-content-between">
                                        <strong>Background Check Cost:</strong>
                                      </div>

                                      <div className="d-flex flex-column w-100 mt-2">
                                        <div className="d-flex flex-wrap">
                                          {organizationSetting?.costs?.background_check_cost?.map(
                                            (e, index) => {
                                              return (
                                                <>
                                                  <label className="d-flex  align-items-center w-50">
                                                    <input
                                                      type="checkbox"
                                                      checked={person?.othercost?.costTypes_and_ids?.background_check_cost?.includes(
                                                        JSON.stringify(e.id)
                                                      )}
                                                      onChange={(ele) => {
                                                        handleCheckboxChange(
                                                          ele,
                                                          idx,
                                                          e,
                                                          "background_check_cost"
                                                        );
                                                      }}
                                                    />
                                                    <p className="pl-2 m-0">
                                                      {" "}
                                                      {`${e.name} (${e.percent}%)`}
                                                    </p>
                                                  </label>
                                                </>
                                              );
                                            }
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </ListGroup.Item>
                                </ListGroup>
                              </Tab.Pane>
                            </Tab.Content>
                          </Col>
                        </Row>
                      </Tab.Container>
                    </div>
                  </Tab>
                );
              })}
            </Tabs>
          ) : (
            <></>
          )}
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" onClick={() => setShowSummaryModal(false)}>
          Close
        </Button>
        <Button
          variant="success"
          style={{ color: "white" }}
          onClick={async () => {
            let data = {
              person_list: editPersonList,
              name: "Healthcare test",
              status: "New calculation",
            };
            let result;

            data = { ...data, ["id"]: id };
            result = await editCalculation(JSON.stringify(data));

            if (result.status === 200) {
              toast.success(Constants.update_message);
              setShowSummaryModal(false)
              defaultFunctions()
              if (
                result &&
                result.data &&
                result.data.calculation &&
                result.data.calculation.id
              ) {
                const calculationId = result.data.calculation.id;
                // window.location.href = "/calculations/" + calculationId;
                // history.push("/calculations/" + calculationId);
              }
            } else {
              toast.error(Constants.update_error_message);
            }
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default GetSummayModalContent;
