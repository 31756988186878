function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
function loadFromLocalStorage() {
  try {
    const serializationState = getCookie("serializationState").replace(
      "||",
      ";"
    );
    if (serializationState === null) return undefined;
    return JSON.parse(serializationState);
  } catch (error) {
    console.log(error);
    return undefined;
  }
}
const data = loadFromLocalStorage()
let role = {};
if(data && data.login && data.login.login && data.login.login.length != 0) {
  role = data.login.login.data.data?.user_role
}
export const isAdmin = role == `Admin` ? true : false
export const isOwner = role == `Owner` ? true : false
export const isManager = role == `Manager` ? true : false
export const isTeamMember = role == `Team Member` ? true : false