import React, { useState, useEffect, useReducer } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Badge,
  Form,
  FormControl,
} from "react-bootstrap";
import { HiCheck } from "react-icons/hi";
import { useHistory, Link } from "react-router-dom";

import {
  getBillSettings,
  getPlans,
  updateSubscription,
  createSubscription,
  simpleSubscription,
  getSettings,
} from "./data/globalAction";
import Moment from "react-moment";
import { toast } from "react-toastify";
import * as Constants from "../core/Constants";
import { CheckoutForm } from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import AfterLogin from "./middleware/AfterLogin";
import ImageOne from "./assets/images/made-for-teams.png";
import SimpleReactValidator from "simple-react-validator";

function ChangePlan(props) {
  const [settings, setSettings] = useState([]);
  const headers = localStorage.getItem("headers");
  const [year_amount, setYearAmount] = useState(0);
  const [member_show, setMemberShow] = useState(false);

  const [value, setValue] = useState(1);
  const [amount, setAmount] = useState(0);
  const history = useHistory();
  const [stripePromise, setStripePromise] = useState(() =>
    loadStripe(process.env.REACT_APP_STRIPE_PKEY)
  );
  const [cardInfo, setCardInfo] = useState([]);
  const [tab, setTab] = useState("");
  const [planDuration, setPlanDuration] = useState(true);
  const handleChangePlansType = () => {
    setPlanDuration(!planDuration);
  };
  const [billSettings, setBillSettings] = useState([]);
  const [plans, setPlans] = useState([]);
  const [rowPlan, setRowPlans] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [confirmDisabled, setConfirmDisabled] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [termsCheck, setTermsCheck] = useState(false);
  const [validator, setValidator] = useState(new SimpleReactValidator());
  const [, forceUpdate] = useState();

  const [formInput, setFormInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      promocode: "",
      pm_id: "",
      plan_members: 1,
    }
  );
  const getAmount = (val = 1,type= 'add') => {
    if(val > 20)
    {
      setMemberShow(true)
    }
    else
    {
      if(type === 'add')
      {
        let p = Constants.plans[val].price;
        let py = Constants.plans[val].yearly_price;
        setAmount(p)
        setYearAmount(py)
      }
      else
      {
        let p = Constants.plans[val+1].price;
        let py = Constants.plans[val+1].yearly_price;
        setAmount(p)
        setYearAmount(py)
      }
     
    }
    
  };
  useEffect(() => {
    if (headers !== null) {
      async function fetchSettings() {
        setSettings(await getSettings());
      }
      fetchSettings();
      async function fetchBillSettings() {
        setBillSettings(await getBillSettings());
      }
      fetchBillSettings();
    }
    async function fetchPlans() {
      setPlans(await getPlans());
    }
    fetchPlans();
    getAmount();
  }, []);
  useEffect(() => {
    if (
      billSettings &&
      billSettings.subscription &&
      billSettings.subscription.cancel_at !== null
    ) {
      setBtnDisabled(true);
    }
    
  }, [billSettings]);

  const addition = () => {
    setValue(formInput.plan_members + 1 );
    getAmount(formInput.plan_members + 1,'add');
    setFormInput({ ["plan_members"]: formInput.plan_members + 1 });
  };
  const subtraction = () => {
    if (formInput.plan_members - 1 > 0)
    {
      setValue(formInput.plan_members - 1 );
      getAmount(formInput.plan_members - 1,'sub')
      setFormInput({ ["plan_members"]: formInput.plan_members - 1 });

    }
  };
  const handlememberClose = () => {
    setMemberShow(false);
  }
  
  const verifyCard = async (error, info) => {
    if (error && error.message) {
      toast.error(error.message);
      setDisabled(false);
    } else {
      const name = "pm_id";
      const newValue = info.id;
      setFormInput({ [name]: newValue });
      let data = {
        pm_id: info.id,
        plan_id: rowPlan.stripe_id,
        plan_members: formInput.plan_members,
        promocode: formInput.promocode,

      };
      let result = await simpleSubscription(JSON.stringify(data));
      if (result.status === 200) {
        toast.success(Constants.update_message);
        history.push("/settings/billing-payment");
      }
      setDisabled(false);
    }
  };
  const handleConfirmPlan = async () => {
    if (!validator.allValid()) {
      validator.showMessages();
      forceUpdate(1);
    } else {
      setDisabled(true);
      let data = {
        plan_id: rowPlan.stripe_id,
        option: "update_plan",
        promocode: formInput.promocode,
      };

      let result = await updateSubscription(JSON.stringify(data));
      if (result.status === 200) {
        toast.success(Constants.update_message);
        history.push("/settings/billing-payment");
      }
    }
    setDisabled(false);
  };
  const handleSelectPlan = async (plan, index) => {
    if(headers === null)
    {
      toast.error(Constants.login_first);
      history.push("/signin");
    }
    else
    {
      setRowPlans(plan);
      setTab(index);
    }
  };

  const handleConfirmWithPaymentMethod = async () => {
    if (!validator.allValid()) {
      validator.showMessages();
      forceUpdate(1);
    } else {
      setConfirmDisabled(true);
      let data = {
        plan_id: rowPlan.stripe_id,
        plan_members: formInput.plan_members,
        promocode: formInput.promocode,
      };

      let result = await createSubscription(JSON.stringify(data));
      if (result.status === 200) {
        toast.success(Constants.update_message);
        history.push("/settings/billing-payment");
      }
    }
    setConfirmDisabled(false);
  };
  const handleInput = (evt) => {
    const name = evt.target.name;
    const newValue = evt.target.value;
    setFormInput({ [name]: newValue });
  };
  var title = "Choose Plan";
  if (billSettings && billSettings.plan) {
    title = "Change Plan";
  }
  let plans_list = "";

  if (plans && plans.length > 0) {
    plans_list = (
      <Row>
        <Col md={4}>
          <Card>
            <Card.Body>
              <div className="d-flex justify-content-between mb-2">
                <div className="plan-title">
                  <strong>{plans[0].name}</strong>
                </div>
                {planDuration ? (
                  <div className="price">
                    {amount}
                    <span className="text-success"> $</span>
                    <small>/mo.</small>
                  </div>
                ) : (
                  <div className="price">
                    <u>{amount}$</u> {year_amount}
                    <span className="text-success"> $</span>
                    <small>/mo.</small>
                  </div>
                )}
              </div>
              {planDuration ? (
                billSettings &&
                billSettings.plan &&
                billSettings.plan.id === plans[0].id ? (
                  <Button variant="outline-dark" block className="mb-3">
                    <HiCheck /> Actual Plan
                  </Button>
                ) : (
                  <Button
                    onClick={() => handleSelectPlan(plans[0], "select_plan")}
                    variant="primary"
                    block
                    className="mb-3"
                    disabled={btnDisabled}
                  >
                    Select Plan
                  </Button>
                )
              ) : billSettings &&
                billSettings.plan &&
                billSettings.plan.id === plans[1].id ? (
                <Button variant="outline-dark" block className="mb-3">
                  <HiCheck /> Actual Plan
                </Button>
              ) : (
                <Button
                  onClick={() => handleSelectPlan(plans[1], "select_plan")}
                  variant="primary"
                  block
                  className="mb-3"
                  disabled={btnDisabled}
                >
                  Select Plan
                </Button>
              )}
              <ul className="list-unstyled m-0">
                <li>Social Security Calculation</li>
                <li>Medicare Calculation</li>
                <li>FUTA (Federal Unemployment)</li>
                <li>SUTA (State Unemployment)</li>
                <li>Workers Compensation Calculation</li>
                <li>Wage Cap Calculations</li>
                <li>Unlimited Calculations</li>
                <li>Dashboard Tracking</li>
                <li>Quote Creation</li>
                <li>Statutory PTO Calculation</li>
                <li>Sales Tax Compliance</li>
                <li>Healthcare Calculation</li>
                <li>Custom Cost Calculations</li>
                <li>Stipend Calculation (GSA Data)</li>
                <li>Overtime Compliance</li>
                <li>Multiple Persons Calculation</li>
                <li>Unlimited Quotes</li>
                <li>Create Teams</li>
                <li>Manager Views</li>
                <li>Manager Approvals</li>
                <li>Track Performance</li>
                <li>Open API (Coming Soon!)</li>
              </ul>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }
  function getTabContent() {
    switch (tab) {
      case "select_plan":
        return (
          <Container>
            <Row className="justify-content-md-center">
              <Col lg={6}>
                <div className="confirm-plan">
                  <h2 className="title">
                    Confirm <br /> new plan selection
                  </h2>
                  {billSettings && billSettings.plan && billSettings.plan.id ? (
                    <Card>
                      <Card.Body className="p-4">
                        <div className="plan-header">
                          <Badge variant="success">New plan</Badge>
                          <h4>{rowPlan.name}</h4>
                          <div className="d-flex justify-content-between">
                            {billSettings && billSettings.subscription ? (
                              <p>
                                The amount you will pay monthly starting from{" "}
                                <Moment format="MMMM DD, YYYY">
                                  {billSettings.subscription.end_date}
                                </Moment>
                              </p>
                            ) : null}

                            <div className="price">
                            {rowPlan.frequency === "2" ? year_amount.toFixed(2) : amount.toFixed(2)}
                              {" "}
                              <span className="text-success mr-1">$</span>
                              <small>
                                for{" "}
                                {rowPlan.frequency === "2" ? "year" : "month"}
                              </small>
                            </div>
                          </div>
                        </div>
                        <Form.Group>
                          <Form.Label>Add promocode</Form.Label>
                          <FormControl
                            id="promocode"
                            name="promocode"
                            value={formInput.promocode}
                            onChange={handleInput}
                          />
                          <br/>
                          <Form.Check  
                            name="terms_check"
                            onChange={(e)=>setTermsCheck(!termsCheck)}
                            label={`By confirm a plan, you agree to the Recalc`}
                          />
                          {validator.message(
                            "terms_check",
                            termsCheck,
                            "accepted",
                            {
                              className: "text-danger",
                            }
                          )}
                          <p>
                            <Link to="/terms">Terms of Service</Link> and{" "}
                            <Link to="/privacy">Privacy Policy</Link>.
                          </p>
                        </Form.Group>
                        <div className="card-bottom">
                          <Button
                            disabled={disabled}
                            onClick={() => handleConfirmPlan()}
                            variant="primary"
                            block
                            className="mb-3"
                          >
                            Confirm
                          </Button>
                          <Button variant="outline-light" onClick={() => setTab("")}  block>
                            Back
                          </Button>
                        </div>
                      </Card.Body>
                    </Card>
                  ) : billSettings &&
                    billSettings.payment_methods &&
                    billSettings.payment_methods.length > 0 ? (
                    <Card>
                      <Card.Body className="p-4">
                        <div className="plan-header">
                          <Badge variant="success">New pland</Badge>
                          <h4>{rowPlan.name}</h4>
                          <div className="d-flex justify-content-between">
                            <div className="price">
                              {rowPlan.frequency === "2" ? year_amount.toFixed(2) : amount.toFixed(2)}
                              <span className="text-success mr-1">$</span>
                              <small>
                                for{" "}
                                {rowPlan.frequency === "2" ? "year" : "month"}
                              </small>
                            </div>
                          </div>
                        </div>
                        <div className="payment-plan-details">
                          <Form.Group>
                            <Form.Label>Member</Form.Label>
                            <div className="d-flex align-items-center justify-content-between">
                              <FormControl
                                type="number"
                                id="plan_members"
                                name="plan_members"
                                disabled
                                value={formInput.plan_members}
                                onChange={handleInput}
                              />
                              <div className="d-flex align-items-center">
                                <Button
                                  variant="outline-light"
                                  onClick={subtraction}
                                >
                                  -
                                </Button>
                                <div className="member-value">
                                  {formInput.plan_members}
                                </div>
                                <Button
                                  variant="outline-light"
                                  onClick={addition}
                                >
                                  +
                                </Button>
                              </div>
                            </div>
                          </Form.Group>
                        </div>
                        <Form.Group>
                          <Form.Label>Add promocode</Form.Label>
                          <FormControl
                            id="promocode"
                            name="promocode"
                            value={formInput.promocode}
                            onChange={handleInput}
                          />
                          <br/>
                          <Form.Check  
                            name="terms_check"
                            onChange={(e)=>setTermsCheck(!termsCheck)}
                            label={`By confirm a plan, you agree to the Recalc`}
                          />
                          {validator.message(
                            "terms_check",
                            termsCheck,
                            "accepted",
                            {
                              className: "text-danger",
                            }
                          )}
                          <p>
                            <Link to="/terms">Terms of Service</Link> and{" "}
                            <Link to="/privacy">Privacy Policy</Link>.
                          </p>
                        </Form.Group>
                        <Button
                          onClick={() => handleConfirmWithPaymentMethod()}
                          variant="primary"
                          block
                          className="mb-3"
                          disabled={confirmDisabled}
                        >
                          Confirm plan
                        </Button>
                      </Card.Body>
                    </Card>
                  ) : (
                    <Card>
                      <Card.Body className="p-4">
                        <div className="plan-header">
                          <Badge variant="success">New plan</Badge>
                          <h4>{rowPlan.name}</h4>
                          <div className="d-flex justify-content-between">
                            <div className="price">
                              {rowPlan.frequency === "2" ? year_amount.toFixed(2) : amount.toFixed(2)}{" "}
                              <span className="text-success mr-1">$</span>
                              <small>
                                for{" "}
                                {rowPlan.frequency === "2" ? "year" : "month"}
                              </small>
                            </div>
                          </div>
                        </div>
                        <div className="payment-plan-details">
                          <Form.Group className="border-bottom-0">
                            <Form.Label>Members</Form.Label>
                            <div className="d-flex align-items-center justify-content-between">
                              <FormControl
                                type="number"
                                id="plan_members"
                                name="plan_members"
                                disabled
                                value={formInput.plan_members}
                                onChange={handleInput}
                              />
                              <div className="d-flex align-items-center">
                                <Button
                                  variant="outline-light"
                                  onClick={subtraction}
                                >
                                  -
                                </Button>
                                <div className="member-value">
                                  {formInput.plan_members}
                                </div>
                                <Button
                                  variant="outline-light"
                                  onClick={addition}
                                >
                                  +
                                </Button>
                              </div>
                            </div>
                          </Form.Group>
                          
                        </div>
                          <Form.Group>
                            <Form.Label>Add promocode</Form.Label>
                            <FormControl
                              id="promocode"
                              name="promocode"
                              value={formInput.promocode}
                              onChange={handleInput}
                            />
                          </Form.Group>
                        <div className="stripe-card-trial-info">
                          <Elements stripe={stripePromise}>
                            <CheckoutForm
                              verifyCard={verifyCard}
                              buttonText="Pay Invoice"
                              disabled={disabled}
                              setDisabled={setDisabled}
                              setCardInfo={setCardInfo}
                            />
                          </Elements>
                        </div>
                      </Card.Body>
                    </Card>
                  )}
                </div>
              </Col>
            </Row>{" "}
          </Container>
        );
      default:
        return (
          <Container>
            <Row className="justify-content-md-center">
              <Col lg={11}>
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <h2 className="title">{title}</h2>
                  <div className="toggle-switch">
                    <div className="text pr-4">Monthly / Yearly</div>
                    <label className="switch m-0">
                      <input
                        type="checkbox"
                        onChange={handleChangePlansType}
                        defaultChecked={false}
                      />
                      <span className="slider"></span>
                    </label>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center">
              <Col lg={11}>{plans_list}</Col>
            </Row>
          </Container>
        );
    }
  }
  return (
    <div className="change-plan-page">
      {getTabContent()} <AfterLogin />{" "}
    </div>
  );
}

export default ChangePlan;
