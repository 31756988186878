import React, { useReducer, useState, useCallback, useEffect } from "react";
import {
  Row,
  Col,
  Container,
  Card,
  Button,
  Modal,
  Form,
  FormControl,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import Select from "react-select";
import BadgeIcon from "../component/assets/images/icons/badge.svg";
import CalculatorIcon from "../component/assets/images/icons/calculator.svg";
import { connect } from "react-redux";
import * as Constants from "../core/Constants";
import {
  getSettings,
  getStatesAttempt,
  getCitiesAttempt,
  updateCompanyAttempt,
  getBillSettings,
  setCompanyImage,
  getOrganizationMembers,
  removeMemberCall,
} from "./data/globalAction";
import SimpleReactValidator from "simple-react-validator";
import TrashIcon from "./assets/images/icons/trash.svg";
import ViewIcon from "./assets/images/icons/view.svg";
import UserImage from "./assets/images/user.png";
import { toast } from "react-toastify";
import Payment from "./Payment";
import Moment from "react-moment";
import AfterLogin from "./middleware/AfterLogin";
import moment from "moment";
import { isAdmin, isManager, isOwner } from "../core/UserRoleCheck";
import { organization_members } from "../core/Service";

const customControlStyles = {
  control: (base, state) => ({
    ...base,
    minHeight: 50,
    marginBottom: "15px",
    background: state.isFocused ? "#fff" : "#fff",
    borderWidth: 1,
    borderRadius: "8px",
    borderColor: state.isFocused ? "#7848FF" : "#E5E8F5",
    boxShadow: state.isFocused ? null : null,
    fontSize: "14px",
    "&:hover": {
      borderColor: state.isFocused ? "#7848FF" : null,
    },
  }),
  dropdownIndicator: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
    marginRight: "5px",
  }),
  menu: (base) => ({
    ...base,
    fontSize: "13px",
    border: "1px solid #f1f3f6",
    boxShadow: "none",
  }),
  option: (base, state) => ({
    ...base,
    background: state.isSelected ? "#f1f3f6" : "#fff",
    color: "#151B26",
    "&:hover": {
      background: "#f1f3f6",
      color: "#151B26",
    },
  }),
  placeholder: (base) => ({
    ...base,
    color: "#A8B9CD",
  }),
  clearIndicator: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
};

function Settings(props) {
  const [formInputCompany, setFormInputCompany] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      company_id: "",
      company_name: "",
      country: Constants.default_county.value,
      state: "",
      city: "",
      address: "",
      employees: "",
      zip_code: "",
    }
  );
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [settings, setSettings] = useState([]);
  const [show, setShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [disabled, setDisabled] = useState(false);
  const [isPaymentShowing, setIsPaymentShowing] = useState(false);
  const [billSettings, setBillSettings] = useState([]);
  const [organizationMembers, setOrganizationMembers] = useState([]);

  const handleClosePayment = () => {
    localStorage.removeItem("expire");
    setIsPaymentShowing(false);
  };
  const [validator] = useState(new SimpleReactValidator());
  const [, forceUpdate] = useState();

  useEffect(() => {
    async function fetchSettings() {
      setSettings(await getSettings());
    }
    fetchSettings();
    async function fetchBillSettings() {
      setBillSettings(await getBillSettings());
    }
    fetchBillSettings();

    members();
  }, []);

  async function members() {
    setOrganizationMembers(
      (await getOrganizationMembers()).organization_members
    );
  }

  useEffect(() => {
    if (localStorage.getItem("expire")) {
      setIsPaymentShowing(true);
      console.log("expire", localStorage.getItem("expire"));
    }
  });
  useEffect(() => {
    document.querySelector("html").classList.remove("calculation-details-page");
    document.querySelector("html").classList.remove("homepage");
    if (settings.expire && settings.expire === true) {
      setIsPaymentShowing(true);
      console.log("expire", localStorage.getItem("expire"));
    }
  }, [settings]);

  var trailDays = 3;
  if (settings.data && settings.data.created_at) {
    var date1 = new Date(settings.data.created_at);
    var date2 = new Date();
    var Difference_In_Time = date2.getTime() - date1.getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    trailDays = trailDays - Math.round(Difference_In_Days);
  }

  const handleInputCompany = (evt) => {
    const name = evt.target.name;
    const newValue = evt.target.value;
    setFormInputCompany({ [name]: newValue });
  };
  const handleSelectCompany = async (info, selectName) => {
    const name = selectName;
    const newValue = info.value;
    setFormInputCompany({ [name]: newValue });
    if (selectName === "state") {
      setCities(await getCitiesAttempt(newValue));
    }
  };

  const handleCompanySave = async () => {
    console.log("here", formInputCompany);
    setDisabled(true);
    if (!validator.allValid()) {
      validator.showMessages();
      forceUpdate(1);
      setDisabled(false);
    } else {
      let result = await updateCompanyAttempt(JSON.stringify(formInputCompany));
      if (result.status === 200) {
        setSettings(result.data);
        setShow(false);
        toast.success(Constants.update_message);
      } else {
        setDisabled(false);
      }

      setDisabled(false);
    }
  };
  const customTableStyles = {
    header: {
      style: {
        padding: "0",
        minHeight: "inherit",
      },
    },
    subHeader: {
      style: {
        padding: "0",
        justifyContent: "flex-start",
        display: "block",
        border: "1px solid #E5E8F5",
        borderBottom: "none",
        borderRadius: "6px 6px 0 0",
      },
    },
    tableWrapper: {
      style: {
        border: "1px solid #E5E8F5",
        borderTop: "none",
      },
    },
    headRow: {
      style: {
        color: "#43536D",
        borderColor: "#E5E8F5",
        borderStyle: "solid",
        borderWidth: "1px",
        borderLeftWidth: "0",
        borderRightWidth: "0",
        backgroundColor: "#FCFCFF",
      },
    },
    headCells: {
      style: {
        fontSize: "13px",
        fontWeight: "normal",
        color: "#767778",
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: "#fff",
        },
      },
    },
    rows: {
      style: {
        minHeight: "65px",
        "&:not(:last-of-type)": {
          borderBottomStyle: "solid",
          borderBottomWidth: "1px",
          borderBottomColor: "#E5E8F5",
        },
      },
    },
    cells: {
      style: {
        minHeight: "65px",
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: "#fff",
        },
      },
    },
    pagination: {
      style: {
        border: "1px solid #E5E8F5",
        borderTop: "none",
        borderRadius: "0 0 6px 6px",
      },
    },
  };
  const columns = [
    {
      name: "Image:",
      selector: "image",
      sortable: true,
    },
    {
      name: "Member:",
      selector: "member",
      sortable: true,
    },
    {
      name: "Email:",
      selector: "member_email",
      sortable: true,
    },
    {
      name: "Team:",
      selector: "team",
      sortable: true,
    },
    {
      name: "Date Added:",
      selector: "date",
      sortable: true,
      cell: (row) => moment(row.date).format("MM/DD/YYYY"),
    },
    {
      name: "Status:",
      selector: "status",
      cell: (row) => (
        <div className="d-flex align-items-center w-100 q-status-section">
          <div
            className={
              row.status === "New calculation" ||
              row.status === "Sent to approval"
                ? "badge badge-info mr-2"
                : row.status === "Not approved"
                ? "badge badge-danger mr-2"
                : "badge badge-success mr-2"
            }
          >
            {row.status}
          </div>
          <div className=" ml-4">
            <Button className="btn-icon m-0" variant="outline-light">
              <img src={ViewIcon} alt="View Icon" className="icon-black" />
            </Button>
            <Button className="btn-icon m-0 ml-2" variant="outline-light">
              <img src={TrashIcon} alt="Trash Icon" className="icon-black" />
            </Button>
          </div>
        </div>
      ),
    },
  ];

  const data = [
    {
      id: 1,
      member: "John Doe",
      member_email: "john@email.com",
      team: "Accounts",
      date: "02/01/1999",
      status: "Admin",
    },
    {
      id: 2,
      member: "John Doe",
      member_email: "john@email.com",
      team: "Accounts",
      date: "02/01/1999",
      status: "Admin",
    },
    {
      id: 3,
      member: "John Doe",
      member_email: "john@email.com",
      team: "Accounts",
      date: "02/01/1999",
      status: "Admin",
    },
    {
      id: 4,
      member: "John Doe",
      member_email: "john@email.com",
      team: "Accounts",
      date: "02/01/1999",
      status: "Admin",
    },
    {
      id: 5,
      member: "John Doe",
      member_email: "john@email.com",
      team: "Accounts",
      date: "02/01/1999",
      status: "Admin",
    },
  ];

  const mappedArray = organizationMembers.map((apiItem) => ({
    id: apiItem.id,
    Image: apiItem.image,
    member: apiItem?.first_name + " " + apiItem?.last_name,
    member_email: apiItem.email,
    team: apiItem.user_role,
    date: apiItem.created_at
      ? new Date(apiItem.created_at).toLocaleDateString()
      : "",
    status: apiItem.user_role,
  }));

  const handleClose = () => setShow(false);
  const handleDeleteModalClose = () => setDeleteModalShow(false);
  const handleShow = () => {
    if (isAdmin || isOwner) {
      if (
        settings.data &&
        settings.data.organization_users &&
        settings.data.organization_users.length !== 0 &&
        settings.data.organization_users[0].organization
      ) {
        let organization = settings.data.organization_users[0].organization;

        setFormInputCompany({ ["company_id"]: organization.id });

        setFormInputCompany({ ["company_name"]: organization.name });

        setFormInputCompany({ ["state"]: organization.state_id });

        async function fetchStates() {
          setStates(await getStatesAttempt(Constants.default_county.value));
        }
        fetchStates();

        setFormInputCompany({ ["city"]: organization.city_id });

        if (organization.city_id !== "" && cities !== "") {
          async function fetchCities() {
            setCities(await getCitiesAttempt(organization.state_id));
          }
          fetchCities();
        }

        setFormInputCompany({ ["employees"]: organization.employees });

        setFormInputCompany({ ["address"]: organization.address });

        setFormInputCompany({ ["zip_code"]: organization.zip });
      }
      setShow(true);
    } else {
      toast.error(Constants.unauthorized);
    }
  };

  let statesOptions = [];
  let stateDisabe = true;
  let stateSelected = "";
  if (states && states.length !== 0) {
    {
      states &&
        states.map((state) => {
          let row = { label: state.name, value: state.id };
          if (state.id === formInputCompany.state) {
            stateSelected = row;
          }
          statesOptions.push(row);
        });
    }
    stateDisabe = false;
  }
  let citiesOptions = [];
  let citiesDisabe = true;
  let citiesSelected = "";
  if (cities && cities.length !== 0) {
    {
      cities &&
        cities.map((city) => {
          let row = { label: city.name, value: city.id };
          if (city.id === formInputCompany.city) {
            citiesSelected = row;
          }
          citiesOptions.push(row);
        });
    }
    citiesDisabe = false;
  }
  let employeeSelected = "";
  Constants.employees_options.forEach((element) => {
    if (element.value === formInputCompany.employees) {
      employeeSelected = element;
    }
  });

  const handleCompanyImage = async (e) => {
    let formImage = new FormData();
    formImage.append("image", e.target.files[0]);

    let result = await setCompanyImage(formImage);
    if (result.status === 200) {
      async function fetchSettings() {
        setSettings(await getSettings());
      }
      await fetchSettings();
      toast.success(Constants.update_message);
    }
  };

  const removeMember = async (id) => {
    let data = {
      user_id: id,
    };

    let result = await removeMemberCall(JSON.stringify(data));
    if (result.status === 200) {
      toast.success(Constants.update_message);
      setDeleteModalShow(false);
      members();
    } else toast.error(result.data);
    setDeleteModalShow(false);
  };

  return (
    <div className="settings-page">
      <Container fluid>
        <h1 className="h4 mb-4">Settings</h1>
        {isAdmin || isOwner ? (
          <Link to="/settings/billing-payment">
            <Card className="billing-section mb-2">
              <div className="card-image-holder">
                <Card.Img variant="top" src={BadgeIcon} alt="Icon" />
              </div>
              <Card.Body>
                <Card.Title>Billing and payment</Card.Title>
                <Card.Text>
                  {settings.data && settings.data.is_enterprise === "1" ? (
                    <div className="mt-1">
                      <strong className="mr-2">Plan:</strong>Enterprise
                    </div>
                  ) : (
                    <div className="mt-1">
                      <strong className="mr-2">Plan:</strong>Single
                    </div>
                  )}
                  <span className="badge badge-primary">
                    {billSettings && billSettings.subscription ? (
                      <div>
                        Plan is valid till{" "}
                        <Moment format="MMMM DD, YYYY">
                          {billSettings.subscription.end_date}
                        </Moment>
                      </div>
                    ) : trailDays > -1 ? (
                      <p className="m-0">
                        {trailDays} {Constants.settings_billing_trail_days}
                      </p>
                    ) : (
                      <p className="m-0">
                        {Constants.settings_billing_trail_days_expire}
                      </p>
                    )}
                  </span>
                  {}
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>
        ) : null}
        {isAdmin || isOwner || isManager ? (
          <Link to="/settings/calculation-settings">
            <Card className="calculation-section mb-2">
              <div className="card-image-holder">
                <Card.Img variant="top" src={CalculatorIcon} alt="Icon" />
              </div>
              <Card.Body>
                <Card.Title>Calculation Settings</Card.Title>
                <Card.Text></Card.Text>
              </Card.Body>
            </Card>
          </Link>
        ) : null}
        {isAdmin || isOwner ? (
          <>
            {settings.data &&
            settings.data.organization_users &&
            settings.data.organization_users.length !== 0 &&
            settings.data.organization_users[0].organization &&
            settings.data.organization_users[0].organization.name ? (
              <div>
                {/* <Link onClick={handleShow}> */}
                <Link to="/settings/company-information">
                  <Card className="mb-2">
                    {settings &&
                    settings.data.organization_users[0].organization.image ? (
                      <div className="user-pic">
                        <img
                          src={
                            settings.data.organization_users[0].organization
                              .image
                          }
                          alt="Image"
                        />
                      </div>
                    ) : (
                      <div className="name-char">
                        {settings.data.organization_users[0].organization.name
                          .charAt(0)
                          .toUpperCase()}
                      </div>
                    )}
                    <Card.Body>
                      <Card.Title>Company information</Card.Title>
                      <Card.Text>
                        <div className="d-flex align-items-center mt-1">
                          <strong className="mr-2">Company:</strong>
                          <p className="m-0">
                            {
                              settings.data.organization_users[0].organization
                                .name
                            }
                          </p>
                          <p className="m-0 ml-3">
                            {
                              settings.data.organization_users[0].organization
                                .address
                            }
                          </p>
                        </div>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Link>
                {/* <Modal
                        animation={false}
                        className="company-settings-modal"
                        centered
                        size="sm"
                        show={show}
                        onHide={handleClose}
                      >
                        <Modal.Header></Modal.Header>
                        <Modal.Body>
                          <div className="modal-head">
                            <label htmlFor="company_image">
                              {settings.data.organization_users[0].organization
                                .image ? (
                                <div className="user-pic">
                                  <img
                                    src={
                                      settings.data.organization_users[0]
                                        .organization.image
                                    }
                                    alt="Image"
                                  />
                                </div>
                              ) : (
                                <div className="name-char">
                                  {settings.data.organization_users[0].organization.name
                                    .charAt(0)
                                    .toUpperCase()}
                                </div>
                              )}
                            </label>
                            <input
                              type="file"
                              id="company_image"
                              style={{ display: "none" }}
                              onChange={handleCompanyImage}
                            />
                            <div className="title">
                              {
                                settings.data.organization_users[0].organization
                                  .name
                              }
                            </div>
                          </div>
                          <form className="form">
                            <Form.Group>
                              <Form.Label>Company Name</Form.Label>
                              <FormControl
                                required
                                id="company_name"
                                value={formInputCompany.company_name}
                                name="company_name"
                                onChange={handleInputCompany}
                              />
                              {validator.message(
                                "company_name",
                                formInputCompany.company_name,
                                "required",
                                { className: "text-danger" }
                              )}
                            </Form.Group>
                            <Row>
                              <Col lg={6}>
                                <Form.Group>
                                  <Form.Label className="optional">
                                    State
                                  </Form.Label>
                                  <Select
                                    name="state"
                                    placeholder="Alaska"
                                    value={stateSelected}
                                    onChange={(value) => {
                                      handleSelectCompany(value, "state");
                                    }}
                                    options={statesOptions}
                                    isDisabled={stateDisabe}
                                    styles={customControlStyles}
                                  />
                                </Form.Group>
                              </Col>
                              <Col lg={6}>
                                <Form.Group>
                                  <Form.Label className="optional">
                                    City
                                  </Form.Label>
                                  <Select
                                    name="city"
                                    placeholder="Chevak"
                                    value={citiesSelected}
                                    onChange={(value) => {
                                      handleSelectCompany(value, "city");
                                    }}
                                    options={citiesOptions}
                                    isDisabled={citiesDisabe}
                                    styles={customControlStyles}
                                  />
                                </Form.Group>
                              </Col>
                              <Col lg={8}>
                                <Form.Group>
                                  <Form.Label className="optional">
                                    Company Address
                                  </Form.Label>
                                  <FormControl
                                    required
                                    value={formInputCompany.address}
                                    name="address"
                                    type="text"
                                    id="address"
                                    placeholder="33 3rd Ave, New York, NY 10003, USA"
                                    onChange={handleInputCompany}
                                  />
                                </Form.Group>
                              </Col>
                              <Col lg={4}>
                                <Form.Group>
                                  <Form.Label className="optional">
                                    Zip Code
                                  </Form.Label>
                                  <FormControl
                                    required
                                    value={formInputCompany.zip_code}
                                    name="zip_code"
                                    placeholder="10017"
                                    type="text"
                                    id="zip_code"
                                    onChange={handleInputCompany}
                                  />
                                </Form.Group>
                              </Col>
                            </Row>

                            <Form.Group>
                              <Form.Label className="optional">
                                No of employees
                              </Form.Label>
                              <Select
                                name="employees"
                                menuPlacement="top"
                                placeholder="More than 50"
                                value={employeeSelected}
                                onChange={(value) => {
                                  handleSelectCompany(value, "employees");
                                }}
                                options={Constants.employees_options}
                                styles={customControlStyles}
                              />
                            </Form.Group>
                          </form>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="outline-light" onClick={handleClose}>
                            Cancel
                          </Button>
                          <Button
                            variant="primary"
                            disabled={disabled}
                            onClick={handleCompanySave}
                          >
                            Save
                          </Button>
                        </Modal.Footer>
                      </Modal> */}
              </div>
            ) : null}
          </>
        ) : null}
        {settings.data && settings.data.first_name ? (
          <Link to="/settings/account-security">
            <Card className="mb-3">
              {settings.data.image ? (
                <div className="user-pic">
                  <img src={settings.data.image} alt="Image" />
                </div>
              ) : (
                <div className="name-char">
                  {settings.data.first_name.charAt(0).toUpperCase() +
                    settings.data.last_name.charAt(0).toUpperCase()}
                </div>
              )}
              <Card.Body>
                <Card.Title>Account and Security</Card.Title>
                <Card.Text>
                  <div className="d-flex align-items-center mt-1">
                    <p className="m-0">
                      {settings.data.first_name} {settings.data.last_name}
                    </p>
                    <p className="m-0 ml-4">{settings.data.email}</p>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>
        ) : null}
        <div className="member-information p-4 bg-white border">
          <h5 className="mb-4 d-block">Member information</h5>

          <div className="table-responsive">
            <table class="table border  rounded-4">
              <thead>
                <tr>
                  <th scope="col">Image</th>
                  <th scope="col">ID</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Date Added</th>
                  <th scope="col">User Role</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {organizationMembers?.map((e) => (
                  <tr key={e?.id}>
                    <td scope="row" style={{ borderTop: "1px solid #E5E8F5" }}>
                      {e?.image ? (
                        <img
                          src={e?.image}
                          style={{
                            height: "40px",
                            width: "40px",
                            borderRadius: "50%",
                          }}
                        />
                      ) : (
                        <>
                          <label htmlFor="profile_image">
                            <div
                              class="name-spell d-flex justify-content-center align-items-center"
                              style={{
                                height: "40px",
                                width: "40px",
                                borderRadius: "50%",
                                border: "1px solid #E5E8F5",
                              }}
                            >
                              {e?.first_name?.charAt(0)?.toUpperCase() +
                                e?.last_name?.charAt(0)?.toUpperCase()}
                            </div>
                          </label>
                        </>
                      )}
                    </td>
                    <td scope="row" style={{ borderTop: "1px solid #E5E8F5" }}>
                      {e?.id}
                    </td>
                    <td
                      scope="row"
                      style={{ borderTop: "1px solid #E5E8F5" }}
                    >{`${e?.first_name} ${e?.last_name}`}</td>
                    <td style={{ borderTop: "1px solid #E5E8F5" }}>
                      {e?.email}
                    </td>
                    <td style={{ borderTop: "1px solid #E5E8F5" }}>
                      {e?.created_at
                        ? new Date(e.created_at).toLocaleDateString()
                        : ""}
                    </td>
                    <td style={{ borderTop: "1px solid #E5E8F5" }}>
                      {" "}
                      <div
                        className="py-2 text-center"
                        style={{ background: "#ebfbf4", color: "#2CD48E" }}
                      >
                        {e?.user_role}
                      </div>
                    </td>

                    <td
                      className="d-flex gap-2 h-100"
                      style={{ borderTop: "1px solid #E5E8F5" }}
                    >
                      {e.user_role != "Owner" ? (
                        <button
                          className="mr-2"
                          style={{
                            width: "45px",
                            background: "white",
                            border: "0px",
                          }}
                          onClick={() => {
                            setDeleteModalShow(true);
                            setDeleteId(e?.id);

                            // removeMember(e?.id)
                          }}
                        >
                          <img src={TrashIcon} alt="Trash Icon" />
                        </button>
                      ) : (
                        <button
                          className="mr-2"
                          style={{
                            width: "45px",
                            background: "white",
                            border: "0px",
                          }}
                        ></button>
                      )}


                      {/* <button
                        className=""
                        style={{
                          width: "45px",
                          background: "white",
                          border: "0px",
                        }}
                      >
                        <img
                          src={ViewIcon}
                          alt="View Icon"
                          className="icon-black pt-1"
                          style={{ height: "100%", width: "90%" }}
                        />
                      </button> */}

                    </td>
                  </tr>
                ))}

                <Modal
                  animation={false}
                  className="company-settings-modal"
                  centered
                  size="sm"
                  show={deleteModalShow}
                  onHide={handleClose}
                >
                  <Modal.Body>
                    <div className="">
                      <h2>Are you sure you want to delete this User?</h2>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="outline-light"
                      onClick={handleDeleteModalClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="danger"
                      disabled={disabled}
                      onClick={() => removeMember(deleteId)}
                    >
                      Delete
                    </Button>
                  </Modal.Footer>
                </Modal>
              </tbody>
            </table>
          </div>
          {/* <DataTable
                  customStyles={customTableStyles}
                  responsive
                  fixedHeader={true}
                  data={data}
                  columns={columns}
                  pagination
                  persistTableHead
                /> */}
        </div>
      </Container>
      {isPaymentShowing ? (
        <Payment onClose={handleClosePayment} isShowing={isPaymentShowing} />
      ) : null}
      <AfterLogin />
    </div>
  );
}
const mapDispatchToProps = (dispatch) => {
  return {};
};
const mapStateToProps = (state) => {
  return {
    login: state.login.login,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
