export const signUpAttempt = (payload) => ({
  type: "SIGNUP_ATTEMPT",
  payload: payload,
});
export const signUpAttemptResponse = (payload) => ({
  type: "SIGNUP_ATTEMPT_RESPONSE",
  payload: payload,
});
export const signUpAttemptResponseClear = (payload) => ({
  type: "SIGNUP_ATTEMPT_RESPONSE_CLEAR",
  payload: payload,
});
export const signUpError = (payload) => ({
  type: "SIGNUP_SET_ERROR",
  payload: payload,
});
export const signUpMessage = (payload) => ({
  type: "SIGNUP_SET_MESSAGE",
  payload: payload,
});
export const companyAttempt = (payload) => ({
  type: "COMPANY_ATTEMPT",
  payload: payload,
});
export const companyAttemptResponse = (payload) => ({
  type: "COMPANY_ATTEMPT_RESPONSE",
  payload: payload,
});
export const teamAttempt = (payload) => ({
  type: "TEAM_ATTEMPT",
  payload: payload,
});
export const teamAttemptResponse = (payload) => ({
  type: "TEAM_ATTEMPT_RESPONSE",
  payload: payload,
});
export const signUpInvitationAttempt = (payload) => ({
  type: "SIGNUP_INVITATION_ATTEMPT",
  payload: payload,
});
export const signUpInvitationAttemptResponse = (payload) => ({
  type: "SIGNUP_INVITATION_ATTEMPT_RESPONSE",
  payload: payload,
});

export const signUpAuthInvitationAttempt = (payload) => ({
  type: "SIGNUP_AUTH_INVITATION_ATTEMPT",
  payload: payload,
});
export const signUpAuthInvitationAttemptResponse = (payload) => ({
  type: "SIGNUP_AUTH_INVITATION_ATTEMPT_RESPONSE",
  payload: payload,
});

export const signUpAttemptByUUID = (payload) => ({
  type: "SIGNUP_ATTEMPT_BY_UUID",
  payload: payload,
});
export const signUpAttemptByUUIDResponse = (payload) => ({
  type: "SIGNUP_ATTEMPT_BY_UUID_RESPONSE",
  payload: payload,
});

export const signUpAttemptResend = (payload) => ({
  type: "SIGNUP_ATTEMPT_RESEND",
  payload: payload,
});
export const signUpAttemptResendResponse = (payload) => ({
  type: "SIGNUP_ATTEMPT_RESEND_RESPONSE",
  payload: payload,
});
