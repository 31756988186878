import React, { useState, useReducer, useEffect } from "react";
import {
  Row,
  Col,
  Container,
  Card,
  Form,
  FormControl,
  InputGroup,
  Button,
  Modal,
  Table,
  Tab,
  Nav,
  ButtonGroup,
  ToggleButton,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";
import { HiOutlinePlusSm } from "react-icons/hi";
import PersonIcon from "./assets/images/icons/persons.svg";
import { RiCloseLine } from "react-icons/ri";
import { HiCheck } from "react-icons/hi";
import Select from "react-select";
import EditIcon from "./assets/images/icons/edit-pencil.svg";
import EditWhiteIcon from "./assets/images/icons/edit-pencil-white.svg";
import TrashIcon from "./assets/images/icons/trash.svg";
import TrashWhiteIcon from "./assets/images/icons/trashWhite.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BsPlus } from "react-icons/bs";
import * as Constants from "../core/Constants";
import moment from "moment";
import {
  getSettings,
  getStatesAttempt,
  getCalculation,
  editCalculation,
  getCitiesAttempt,
  deleteCalculation,
  createCalculation,
  packageOrganization,
  getIndustriesAttempt,
  getOrganizationSettingAttempt,
  packageOrganizationSettingAttempt,
  updateCostOrganizationSettingAttempt,
  importWcOrganizationSettingAttempt,
  wcSettingsOrganizationSettingAttempt,
  calculateStipend,
  getBillSettings,
} from "./data/globalAction";
import { confirmAlert } from "react-confirm-alert";
import SelectInput from "@material-ui/core/Select/SelectInput";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";
import { isTeamMember } from "../core/UserRoleCheck";
import Payment from "./Payment";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import Slider from "react-slick";
import V4 from "../component/assets/images/v4.png";
import AddPerson from "../component/assets/images/learning/add-person.png";
import ButtonCalculator from "../component/assets/images/learning/learning-new/button calculator.png";
import CalculatorPopup from "../component/assets/images/learning/learning-new/calculator-popup.png";
import Approval from "../component/assets/images/learning/learning-new/approval.png";
import SendApproval from "../component/assets/images/learning/learning-new/send-approval.png";
import AddPersonNew from "../component/assets/images/learning/learning-new/add-person.png";
import CreateQuoteNew from "../component/assets/images/learning/learning-new/create-quote.png";
import EnterPay from "../component/assets/images/learning/learning-new/enter-pay.png";
import DrugScreen from "../component/assets/images/learning/learning-new/healthcare-plan.png";
import HeathCare from "../component/assets/images/learning/learning-new/drug-screen.png";
import QuoteButton from "../component/assets/images/learning/learning-new/quote-button.png";
import SummaryButton from "../component/assets/images/learning/learning-new/summary-button.png";
import EmployeeBurden from "../component/assets/images/learning/learning-new/burden.png";
import TestApproved from "../component/assets/images/learning/learning-new/test-approved.png";
import AssignmentDetails from "../component/assets/images/learning/learning-new/assignment-details.png";

let statesOptions = [];
// let citiesOptions = [];
let packagesOptions = [];
let bonusOptions = Constants.bonusOptions;
let daysOptions = Constants.daysOptions;

function SingleCalculator() {
  const { id } = useParams();
  const [disabledCalculator, setDisabledCalculator] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [calculations, setCalculations] = useState();
  const history = useHistory();
  const [personList, setPersonList] = useState([]);
  const [step, setStep] = useState("add_name");
  const [show, setShow] = useState(false);
  const [stipendData, setStipendData] = useState(null);
  const [showStepend, setShowStepend] = useState(false);
  const [alwaysAccrue, setAlwaysAccrue] = useState(false);
  const [allIndustries, setAllIndustries] = useState([]);
  const [billSettings, setBillSettings] = useState([]);
  const [demoModalShow, setDemoModalShow] = useState(false);
  const [showFinishedButton, setShowFinishedButton] = useState(false);

  const handleModalClose = () => setShow(false);
  const radios = [
    { label: "from Revenue", value: "1" },
    { label: "from Total Wage", value: "2" },
  ];
  const handleModalShow = () => {
    validator.hideMessages();
    forceUpdate(0);
    setFormInput({
      ["person_list"]: [
        {
          job_name: "",
          number_of_employees: 1,
          pay: [],
          bill: [],
          assignment: [],
          benifits: [],
          othercost: [],
          wc_states: [
            {
              state_id: null,
              city_id: null,
            },
          ],
        },
      ],
    });
    // setPersonList([
    //   {
    //     job_name: "",
    //     pay: [],
    //     bill: [],
    //     assignment: [],
    //     benifits: [],
    //     wc_states: [
    //       {
    //         state_id: null,
    //         city_id: null,
    //       },
    //     ],
    //   },
    // ]);
    setShow(true);
    console.log("personList", personList);
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        Next
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        Back
      </div>
    );
  }

  const surveySettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    afterChange: function (currentSlide) {
      if (currentSlide == 5) {
        setShowFinishedButton(true);
      } else {
        setShowFinishedButton(false);
      }
    },
  };

  const handleClose = () => {
    setDemoModalShow(false);
    setShowFinishedButton(false);
    localStorage.setItem("calculatorDemo", false);
  };
  // WC States
  const [organizationSettings, setOrganizationSettings] = useState();
  const [wcNicknamesModal, setWcNicknamesModal] = useState([]);
  const [editPersonIndex, setEditPersonIndex] = useState(null);
  const [nicknameOptions, setNicknameOptions] = useState({});
  const [wcStatesOptions, setWcStatesOptions] = useState([]);
  const [customCostTypes, setCustomCostTypes] = useState();
  const [citiesOptions, setCitiesOptions] = useState({});
  const [showWcModal, setShowWcModal] = useState(false);
  const [wcStateModal, setWcStateModal] = useState(0);
  const [nicknames, setNicknames] = useState({});
  const [wcStates, setWcStates] = useState([]);
  const [settings, setSettings] = useState([]);
  const [isPaymentShowing, setIsPaymentShowing] = useState(false);

  const handleCloseWcModal = () => {
    setShow(true);
    setWcNicknamesModal([]);
    setWcStateModal(0);
    setShowWcModal(false);
  };

  const [radioValue, setRadioValue] = useState("hourly");
  const [radioValueTwo, setRadioValueTwo] = useState("monthly");
  const [radioValueThree, setRadioValueThree] = useState("bill_rate");
  const [radioValueFour, setRadioValueFour] = useState("yes");
  const [radioValueFive, setRadioValueFive] = useState("shift");
  const [defaultActiveKey, setDefaultActiveKey] = useState("pay");
  const [defaultActiveKeyId, setDefaultActiveKeyId] = useState(0);
  const [activePerson, setActivePerson] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [radio6Value, setRadio6Value] = useState("1");

  const [industries, setIndustries] = useState([]);
  const [industryValue, setIndustryValue] = useState(null);
  const [optionValue, setOptionValue] = useState("flat");

  const [startDate, setStartDate] = useState(new Date());
  const [startDateTwo, setStartDateTwo] = useState(new Date());
  const [activeClass, setActiveClass] = useState(true);
  const [toggleBenefits1, setToggleBenefits1] = useState(false);
  const [toggleBenefits2, setToggleBenefits2] = useState(false);
  const [toggleBenefits3, setToggleBenefits3] = useState(false);
  const [toggleBenefits4, setToggleBenefits4] = useState(false);
  const [toggleBenefits5, setToggleBenefits5] = useState(false);
  const [toggleBenefits6, setToggleBenefits6] = useState(false);
  const [toggleOtherC1, setToggleOtherC1] = useState(false);
  const [toggleOtherC2, setToggleOtherC2] = useState(false);
  const [toggleOtherC3, setToggleOtherC3] = useState(false);
  const [toggleOtherC4, setToggleOtherC4] = useState(false);
  const [toggleOtherC5, setToggleOtherC5] = useState(false);
  const [toggleOtherC6, setToggleOtherC6] = useState({});
  const [toggleOtherC7, setToggleOtherC7] = useState(false);
  const [toggleOtherC8, setToggleOtherC8] = useState(false);
  const [toggleOtherC9, setToggleOtherC9] = useState(false);

  const [toggleOtherC12, setToggleOtherC12] = useState(false);
  const [toggleOtherC22, setToggleOtherC22] = useState(false);
  const [toggleOtherC32, setToggleOtherC32] = useState(false);
  const [toggleOtherC42, setToggleOtherC42] = useState(false);
  const [toggleOtherC52, setToggleOtherC52] = useState(false);
  const [toggleOtherC62, setToggleOtherC62] = useState({});
  const [toggleOtherC72, setToggleOtherC72] = useState(false);
  const [toggleOtherC82, setToggleOtherC82] = useState(false);
  const [toggleOtherC92, setToggleOtherC92] = useState(false);

  const [otherCostErrors, setOtherCostErrors] = useState({
    background_check_cost: "",
    drug_screen_cost: "",
    manage_service_provider: "",
    vendor_management_system: "",
    payrolling_cost: "",
    interest: "",
    liability_insurance: "",
  });

  const [costData, setCostData] = useState({
    background_check_cost: [],
    drug_screen_cost: [],
    manage_service_provider: [],
    vendor_management_system: [],
    payrolling_cost: [],
    interest: [],
    liability_insurance: [],
  });

  const [disabled, setDisabled] = useState(false);
  const [disabled2, setDisabled2] = useState(false);
  const [disabled3, setDisabled3] = useState(false);
  const [stepend, setStepend] = useState(false);
  const [exampt, setExampt] = useState(false);
  const handleToggleExampt = () => setExampt(!exampt);
  const handlePersonChange = async (e, index, opt = "") => {
    let name, value;
    if (opt !== "") {
      name = opt;
      value = e.value;
    } else {
      name = e.target.name;
      value = e.target.value;
    }

    const list = [...formInput.person_list];
    list[index][name] = value;
    setFormInput({ ["person_list"]: list });
    if (opt === "state") {
      console.log("value", value);
      citiesOptions = [];
      setCities(await getCitiesAttempt(value));
    }
  };
  const handleSelect = async (info, selectName) => {
    const name = selectName;
    const newValue = info.value;
    console.log("newValue", newValue);
    setFormInput({ [name]: newValue });
  };
  const nextTab = (currentTab) => {
    validator.hideMessages();
    forceUpdate(0);
    if (currentTab === "pay") {
      setDisabled(true);
      var valid = 1;

      if (!validator.fieldValid("pay_rate")) {
        valid = 0;
      }
      if (!valid) {
        validator.showMessages();
        forceUpdate(1);
      } else {
        const list = personList;
        list[activePerson].pay = {
          compensation_type: radioValue,
          pay_rate: formInput.pay_rate,
          stepend: stepend,
          exampt: exampt,
          discount: formInput.discount,
          pay_frequency: radioValueTwo,
          salary_type: formInput.salary_type,
        };

        setPersonList([...list]);
        if (id === undefined) {
          if (list[activePerson].pay.compensation_type === "salary") {
            setRadioValueFive("full-time");
          } else {
            setRadioValueFive("shift");
          }
        }
        console.log("activePerson", list[activePerson].pay);
        setDefaultActiveKey("bill");
        setDefaultActiveKeyId(1);
      }
      setDisabled(false);
    } else if (currentTab === "bill") {
      setDisabled(true);
      var valid = 1;

      if (radioValueThree === "bill_rate") {
        if (!validator.fieldValid("bill_rate")) {
          valid = 0;
        }
      } else {
        if (!validator.fieldValid("markup")) {
          valid = 0;
        }
      }
      console.log("validator", validator);
      if (!exampt) {
        if (!validator.fieldValid("overtime")) {
          valid = 0;
        }
      }

      if (!valid) {
        validator.showMessages();
        forceUpdate(1);
      } else {
        const list = personList;
        list[activePerson].bill = {
          compensation_type: radioValueThree,
          [radioValueThree]: formInput[radioValueThree],
          overtime: formInput.overtime,
        };
        console.log("list", list);
        setPersonList([...list]);
        console.log("activePerson", list[activePerson]?.bill);
        setDefaultActiveKey("assignment-details");
        setDefaultActiveKeyId(2);
      }
      setDisabled(false);
    } else if (currentTab === "assignment-details") {
      setDisabled(true);
      var valid = 1;
      if (radioValueFour === "yes") {
        if (radioValueFive === "shift") {
          if (
            !validator.fieldValid("work_shadule") ||
            !validator.fieldValid("shift_per_week")
          ) {
            valid = 0;
          }
        }
        if (radioValueFive === "part-time") {
          if (!validator.fieldValid("hours_per_week")) {
            console.log(123456);
            valid = 0;
          }
        }
      } else if (radioValueFour === "no") {
        if (
          !validator.fieldValid("estimated_assigment_length") ||
          !validator.fieldValid("estimated_assigment_period")
        ) {
          valid = 0;
        }

        if (radioValueFive === "shift") {
          if (
            !validator.fieldValid("hours_per_week") ||
            !validator.fieldValid("shift_per_week")
          ) {
            valid = 0;
          }
        }
        if (radioValueFive === "part-time") {
          if (!validator.fieldValid("hours_per_week")) {
            valid = 0;
          }
        }
      }
      if (!valid) {
        validator.showMessages();
        forceUpdate(1);
      } else {
        const list = personList;
        list[activePerson].assignment = {
          have_start_end_date: radioValueFour,
          start_date: startDate,
          end_date: startDateTwo,
          shadule: radioValueFive,
          work_shadule: formInput.work_shadule,
          shift_per_week: formInput.shift_per_week,
          hours_per_week: formInput.hours_per_week,
          estimated_assigment_length: formInput.estimated_assigment_length,
          estimated_assigment_period: formInput.estimated_assigment_period,
        };

        list[activePerson].pay.discount = formInput.discount;

        setPersonList([...list]);
        console.log("activePerson", list[activePerson].assignment);
        console.log("pass");
        if (stepend) {
          calculateStipendData();
        }
        if (!stepend) {
          setDefaultActiveKey("benifits");
          setDefaultActiveKeyId(3);
        }
      }
      console.log(
        "assignmentTab",
        radioValueFour,
        startDate,
        startDateTwo,
        radioValueFive,
        formInput.work_shadule,
        formInput.shift_per_week,
        formInput.hours_per_week
      );
      //   setDefaultActiveKey("benifits");
    } else if (currentTab === "benifits") {
      setDisabled(true);
      var valid = 1;
      console.log(
        "benifitsTab",
        toggleBenefits1,
        toggleBenefits2,
        toggleBenefits3,
        toggleBenefits4,
        toggleBenefits5
      );
      if (toggleBenefits1) {
        if (
          !validator.fieldValid("paid_time") ||
          !validator.fieldValid("holiday")
        ) {
          console.log("error");
          valid = 0;
        }
      }
      if (toggleBenefits2) {
        if (
          !validator.fieldValid("pay_time_number_of_days") ||
          !validator.fieldValid("pto")
        ) {
          console.log("error");
          valid = 0;
        }
      }
      if (toggleBenefits3) {
        if (!validator.fieldValid("number_of_days")) {
          valid = 0;
        }
      }
      if (toggleBenefits4) {
        if (!validator.fieldValid("pay") || !validator.fieldValid("bonus")) {
          valid = 0;
        }
      }
      if (toggleBenefits5) {
        if (
          !validator.fieldValid("package") ||
          !validator.fieldValid("package_type")
        ) {
          valid = 0;
        }
      }

      if (!valid) {
        validator.showMessages();
        forceUpdate(1);
      } else {
        const list = personList;
        list[activePerson].benifits = {
          holiday_pay: toggleBenefits1,
          holiday_paid_time: formInput.paid_time,
          holiday_type: formInput.holiday,

          paid_time: toggleBenefits2,
          paid_time_number_of_days: formInput.pay_time_number_of_days,
          pto_type: formInput.pto,

          k_401: toggleBenefits3,
          k_401_number_of_days: formInput.number_of_days,

          bonus: toggleBenefits4,
          bonus_pay: formInput.pay,
          bonus_type: formInput.bonus,

          healthcare_plan: toggleBenefits5,
          healthcare_package: formInput.package,
          healthcare_package_type: formInput.package_type,
          health_plans: formInput.health_plan,

          spto: toggleBenefits6,
        };

        setPersonList([...list]);
        console.log("activePerson", list[activePerson].benifits);
        setDefaultActiveKey("othercost");
        setDefaultActiveKeyId(4);
        console.log("pass");
      }
    } else if (currentTab === "othercost") {
      var valid = 1;
      if (toggleOtherC7) {
        if (!validator.fieldValid("othercost_number_of_days")) {
          valid = 0;
        }
      }
      if (!valid) {
        validator.showMessages();
        forceUpdate(1);
      } else {
        let go = true;
        let costDataCopy = costData;
        let allToggles = {
          ...toggleOtherC6,
          ["background_check_cost"]: toggleOtherC1,
          ["drug_screen_cost"]: toggleOtherC2,
          ["manage_service_provider"]: toggleOtherC3,
          ["vendor_management_system"]: toggleOtherC4,
          ["payrolling_cost"]: toggleOtherC5,
          ["interest"]: toggleOtherC8,
          ["liability_insurance"]: toggleOtherC9,
        };
        console.log("allToggles", allToggles);
        // checking custom
        let otherCostErrorsCopy = otherCostErrors;
        let otherCostErrorsCopy2 = otherCostErrors;
        // Removing previous Errors
        Object.keys(otherCostErrorsCopy).map((type, idx) => {
          otherCostErrorsCopy2[type] = "";
        });
        setOtherCostErrors({ ...otherCostErrorsCopy2 });

        console.log("costDataCopy", costDataCopy);
        // Adding new errors if present
        Object.keys(allToggles).map((type, idx) => {
          if (!allToggles[type]) {
            costDataCopy[type] = [];
          } else {
            if (costDataCopy[type].length === 0) {
              go = false;
              otherCostErrorsCopy[type] = "No option Selected";
              // toast.error(`Cost Type is elected but no option checked`);
            }
          }
        });
        setOtherCostErrors({ ...otherCostErrorsCopy });
        setCostData({ ...costDataCopy });
        if (go) {
          const list = personList;
          list[activePerson].othercost = {
            number_of_days: formInput["othercost_number_of_days"],
            costTypes_and_ids: costDataCopy,
          };
          setPersonList([...list]);
          console.log("othercost list", list);
          if (personList.length === 1) {
            submitCalculator();
          } else if (personList[activePerson + 1]) {
            changePerson(activePerson + 1);
          } else if (activePerson === personList.length - 1) {
            submitCalculator();
          }
          // console.log("activePerson",activePerson,personList.length -1)
        }
      }
    }
    checkDisabledCalculator();
    setRefresh(!refresh);
  };

  const backTab = (currentTab) => {
    validator.hideMessages();
    forceUpdate(0);
    setDefaultActiveKey(currentTab);
    // setDefaultActiveKeyId(defaultActiveKeyId - 1);
    setRefresh(!refresh);
  };

  const [formInput, setFormInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      calculator_name: "",
      pay_rate: "",
      salary_type: "annual",
      person_list: [],
      discount: 0,
      overtime: 1.5,
      bill_rate: "",
      markup: "",
      work_shadule: "",
      shift_per_week: "",
      hours_per_week: "",
      paid_time: "",
      holiday: "",
      pay_time_number_of_days: "",
      pto: "",
      number_of_days: "",
      pay: "",
      bonus: "",
      health_plan: [],
      package: "",
      package_type: "",
      package_name: "",
      employee_only: "",
      employee_spouse: "",
      employee_dependent: "",
      family: "",
      bc_name: "",
      bc_cost: "",
      drug_screen_name: "",
      drug_screen_cost: "",
      msp_name: "",
      msp_percent: "",
      msp_revenueWage: "",
      vms_name: "",
      vms_percent: "",
      vms_revenueWage: "",
      payrolling_name: "",
      payrolling_percent: "",
      payrolling_revenueWage: "",
      interest_name: "",
      interest_percent: "",
      interest_revenueWage: "",
      liability_insurance_name: "",
      liability_insurance_percent: "",
      liability_insurance_revenueWage: "",
      custom_name: "",
      custom_cost: "",
      custom_industry: "",
      othercost_number_of_days: "",
      estimated_assigment_length: "",
      estimated_assigment_period: "",
    }
  );
  const handleInput = (evt) => {
    const name = evt.target.name;
    const newValue = evt.target.value;
    setFormInput({ [name]: newValue });
    console.log("input", formInput);

    if (name === "discount") {
      const list = personList;
      list[activePerson].pay.discount = newValue;
      setPersonList([...list]);
      if (newValue >= 0 && newValue <= 100) calculateStipendData();
    }
  };

  const checkDisabledCalculator = () => {
    let check = false;
    personList.map((person, i) => {
      if (person.pay && person.pay.length === 0) {
        check = true;
      } else if (person.bill && person.bill.length === 0) {
        check = true;
      } else if (person.assignment && person.assignment.length === 0) {
        check = true;
      } else if (person.benifits && person.benifits.length === 0) {
        check = true;
      } else if (person.othercost && person.othercost.length === 0) {
        check = true;
      }
    });
    setDisabledCalculator(check);
  };
  const changeError = (message, className) => {
    if (
      message.includes("job name") ||
      message.includes("state") ||
      message.includes("city")
    )
      return <div className="text-danger">{message.replace(/[0-9]/, "")}</div>;
    else return <div className="text-danger">{message}</div>;
  };
  const [validator] = useState(
    new SimpleReactValidator(
      new SimpleReactValidator({
        element: (message, className) => changeError(message, className),
      })
    )
  );
  const [forceShow, forceUpdate] = useState();

  const [showModalSix, setShowModalSix] = useState(false);
  const handleShowSix = () => {
    validator.hideMessages();
    forceUpdate(0);
    setDisabled(false);
    setFormInput({ ["package_name"]: "" });
    setFormInput({ ["employee_only"]: "" });
    setFormInput({ ["employee_spouse"]: "" });
    setFormInput({ ["employee_dependent"]: "" });
    setFormInput({ ["family"]: "" });
    setShowModalSix(true);
  };
  const handleCloseSix = () => setShowModalSix(false);

  const removePersonAdded = (index) => {
    confirmAlert({
      title: "Are you sure",
      message: (
        <p className="mb-0">Are you sure you want to delete this person?</p>
      ),
      buttons: [
        {
          label: "Yes",
          className: "btn btn-primary",
          onClick: () => {
            const list = personList;
            list.splice(index, 1);
            // setFormInput({ ["person_list"]: list });
            setPersonList([...list]);
          },
        },
        {
          label: "No",
          className: "btn btn-outline-light",
          onClick: () => {},
        },
      ],
    });
  };
  const handleShow = async (evt, index, id = -1) => {
    validator.hideMessages();
    forceUpdate(0);
    if (index === "add_package") {
      setDisabled(true);
      var valid = 1;

      if (
        !validator.fieldValid("package_name") ||
        !validator.fieldValid("employee_only") ||
        !validator.fieldValid("employee_spouse") ||
        !validator.fieldValid("employee_dependent") ||
        !validator.fieldValid("family")
      ) {
        valid = 0;
      }
      if (!valid) {
        validator.showMessages();
        forceUpdate(1);
        setDisabled(false);
      } else {
        let data = {
          data: {
            package_name: formInput.package_name,
            employee_only: formInput.employee_only,
            employee_spouse: formInput.employee_spouse,
            employee_dependent: formInput.employee_dependent,
            family: formInput.family,
          },
        };

        let result = await packageOrganizationSettingAttempt(
          JSON.stringify(data)
        );
        if (result.status === 200) {
          setPackages(await packageOrganization());
          setShowModalSix(false);
          toast.success(Constants.update_message);
        }
        setDisabled(false);
      }
    } else if (index === "add_persons") {
      setDisabled(true);
      var valid = 1;

      if (!validator.fieldValid("calculator_name")) {
        valid = 0;
      }
      if (!valid) {
        validator.showMessages();
        forceUpdate(1);
      } else {
        setStep(index);
      }
      setDisabled(false);
    } else if (index === "add_job") {
      setDisabled(true);
      var valid = 1;

      formInput.person_list.forEach((element, ind) => {
        if (!validator.fieldValid("job_name_" + ind)) {
          valid = 0;
        }
        if (!validator.fieldValid("number_of_employees_" + ind)) {
          valid = 0;
        }
        for (var j = 0; j < element["wc_states"].length; j++) {
          if (
            !validator.fieldValid("wc_state_" + j) ||
            !validator.fieldValid("wc_city_" + j) ||
            !validator.fieldValid("wc_state_nickname_" + j)
          ) {
            valid = 0;
          }
        }
      });

      if (!valid) {
        validator.showMessages();
        forceUpdate(1);
        setDisabled(false);
      } else {
        if (editPersonIndex != null) {
          let p = personList;
          p[editPersonIndex] = formInput.person_list[0];
          setPersonList([...personList]);
          setEditPersonIndex(null);
        } else {
          const list = personList;
          for (
            var j = 0;
            j < parseInt(formInput.person_list[0]["number_of_employees"]);
            j++
          ) {
            list.push(formInput.person_list[0]);
          }

          setPersonList([...list]);
        }
        setShow(false);
      }
    } else if (index === "job_detail") {
      console.log("list", personList);
      setStep(index);
    } else {
      setStep(index);
    }
    setDisabledCalculator(true);
  };

  const handleStependAdd = () => {
    setDisabled(true);
    var valid = 1;

    if (!validator.fieldValid("discount")) {
      valid = 0;
    }
    if (!valid) {
      validator.showMessages();
      forceUpdate(1);
    } else {
      setShowStepend(false);
    }
    setDisabled(false);
    setDefaultActiveKey("benifits");
    setDefaultActiveKeyId(3);
  };
  const handleStependHide = () => {
    // setStepend(!stepend);
    setShowStepend(false);
  };

  // useEffect(()=>{
  //   calculateStipendData()
  // }, [formInput.discount])

  const radios1 = [
    { name: "Hourly", value: "hourly" },
    { name: "Salary", value: "salary" },
  ];
  const radios2 = [
    { name: "Monthly", value: "monthly" },
    { name: "Semi Monthly", value: "semi-monthly" },
    { name: "Bi weekly", value: "bi-weekly" },
    { name: "Weekly", value: "weekly" },
  ];
  const radios3 = [
    { name: "Bill rate", value: "bill_rate" },
    { name: "Markup", value: "markup" },
  ];
  const radios4 = [
    { name: "Yes", value: "yes" },
    { name: "No", value: "no" },
  ];
  const radios5 = [
    { name: "Shift", value: "shift" },
    { name: "Full time", value: "full-time" },
    { name: "Part time", value: "part-time" },
  ];
  const radios6 = [
    { name: "from Revenue", value: "1" },
    { name: "from Total Wage", value: "2" },
  ];
  const radios7 = [
    { name: "Flat", value: "flat" },
    { name: "Percentage", value: "percentage" },
  ];
  const handleDatepickerFocus = (e) => {
    e.target.parentNode.parentNode.parentNode.classList.add("active");
  };
  const handleDatepickerBlur = (e) => {
    e.target.parentNode.parentNode.parentNode.classList.remove("active");
  };
  const handleDateChange = (date, e) => {
    setStartDate(date);
    e.target.closest(".input-group").classList.remove("active");
  };
  const handleDateChangeTwo = (date, e) => {
    setStartDateTwo(date);
    e.target.closest(".input-group").classList.remove("active");
  };
  const handleFocus = (e) => {
    e.target.parentNode.classList.add("active");
  };
  const handleBlur = (e) => {
    e.target.parentNode.classList.remove("active");
  };
  const handleStependModal = (evt) => {
    console.log("here Stepend");
    setStepend(evt.target.checked);
    // if (evt.target.checked) setShowStepend(true);
  };
  const handleToggleBenefits1 = () => {
    if (billSettings?.plan?.name === "Essential") {
      setToggleBenefits1(false);
      toast.info(Constants.update_plan_message);
      return "";
    }
    if (personList[activePerson].pay.compensation_type != "salary") {
      setToggleBenefits1(!toggleBenefits1);
    }
  };
  const handleClosePayment = () => {
    localStorage.removeItem("expire");
    setIsPaymentShowing(false);
  };
  const handleToggleBenefits2 = () => {
    if (billSettings?.plan?.name === "Essential") {
      setToggleBenefits2(false);
      toast.info(Constants.update_plan_message);
      return "";
    }
    setToggleBenefits2(!toggleBenefits2);
  };
  const handleToggleBenefits3 = () => {
    if (billSettings?.plan?.name === "Essential") {
      setToggleBenefits3(false);
      toast.info(Constants.update_plan_message);
      return "";
    }
    setToggleBenefits3(!toggleBenefits3);
  };
  const handleToggleBenefits4 = () => {
    if (billSettings?.plan?.name === "Essential") {
      setToggleBenefits4(false);
      toast.info(Constants.update_plan_message);
      return "";
    }
    setToggleBenefits4(!toggleBenefits4);
  };
  const handleToggleBenefits5 = () => {
    if (billSettings?.plan?.name === "Essential") {
      setToggleBenefits5(false);
      toast.info(Constants.update_plan_message);
      return "";
    }
    setFormInput({
      ["health_plan"]: [
        {
          package: null,
          package_type: null
        },
      ],
    })
    setToggleBenefits5(!toggleBenefits5);
  };
  const handleToggleOtherC1 = () => {
    if (billSettings?.plan?.name === "Essential") {
      setToggleOtherC1(false);
      toast.info(Constants.update_plan_message);
      return "";
    }
    setToggleOtherC1(!toggleOtherC1);
  };
  const handleToggleOtherC2 = () => {
    if (billSettings?.plan?.name === "Essential") {
      setToggleOtherC2(false);
      toast.info(Constants.update_plan_message);
      return "";
    }
    setToggleOtherC2(!toggleOtherC2);
  };
  const handleToggleOtherC3 = () => {
    if (billSettings?.plan?.name === "Essential") {
      setToggleOtherC3(false);
      toast.info(Constants.update_plan_message);
      return "";
    }
    setToggleOtherC3(!toggleOtherC3);
  };
  const handleToggleOtherC4 = () => {
    if (billSettings?.plan?.name === "Essential") {
      setToggleOtherC4(false);
      toast.info(Constants.update_plan_message);
      return "";
    }
    setToggleOtherC4(!toggleOtherC4);
  };
  const handleToggleOtherC5 = () => {
    if (billSettings?.plan?.name === "Essential") {
      setToggleOtherC5(false);
      toast.info(Constants.update_plan_message);
      return "";
    }
    setToggleOtherC5(!toggleOtherC5);
  };
  const handleToggleOtherC6 = (key) => {
    if (billSettings?.plan?.name === "Essential") {
      setToggleOtherC6({ ...toggleOtherC6, [key]: false });
      toast.info(Constants.update_plan_message);
      return "";
    }
    setToggleOtherC6({ ...toggleOtherC6, [key]: !toggleOtherC6[key] });
  };
  const handleToggleOtherC7 = () => {
    if (billSettings?.plan?.name === "Essential") {
      setToggleOtherC7(false);
      toast.info(Constants.update_plan_message);
      return "";
    }
    console.log("toggleOtherC7 before", toggleOtherC7);
    setToggleOtherC7(!toggleOtherC7);
    console.log("toggleOtherC7 after", !toggleOtherC7);
  };
  const handleToggleOtherC8 = () => {
    if (billSettings?.plan?.name === "Essential") {
      setToggleOtherC8(false);
      toast.info(Constants.update_plan_message);
      return "";
    }
    setToggleOtherC8(!toggleOtherC8);
  };
  const handleToggleOtherC9 = () => {
    if (billSettings?.plan?.name === "Essential") {
      setToggleOtherC9(false);
      toast.info(Constants.update_plan_message);
      return "";
    }
    setToggleOtherC9(!toggleOtherC9);
  };

  const handleToggleOtherC12 = () => setToggleOtherC12(!toggleOtherC12);
  const handleToggleOtherC22 = () => setToggleOtherC22(!toggleOtherC22);
  const handleToggleOtherC32 = () => setToggleOtherC32(!toggleOtherC32);
  const handleToggleOtherC42 = () => setToggleOtherC42(!toggleOtherC42);
  const handleToggleOtherC52 = () => setToggleOtherC52(!toggleOtherC52);
  const handleToggleOtherC62 = (key) =>
    setToggleOtherC62({ ...toggleOtherC62, [key]: !toggleOtherC62[key] });
  const handleToggleOtherC72 = () => setToggleOtherC72(!toggleOtherC72);
  const handleToggleOtherC82 = () => setToggleOtherC82(!toggleOtherC82);
  const handleToggleOtherC92 = () => setToggleOtherC92(!toggleOtherC92);

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [packages, setPackages] = useState([]);

  const customControlStyles = {
    control: (base, state) => ({
      ...base,
      minHeight: 50,
      marginBottom: "15px",
      background: state.isFocused ? "#fff" : "#fff",
      borderWidth: 1,
      borderRadius: "8px",
      borderColor: state.isFocused ? "#7848FF" : "#E5E8F5",
      boxShadow: state.isFocused ? null : null,
      fontSize: "14px",
      "&:hover": {
        borderColor: state.isFocused ? "#7848FF" : null,
      },
    }),
    dropdownIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
      marginRight: "5px",
    }),
    menu: (base) => ({
      ...base,
      fontSize: "13px",
      border: "1px solid #f1f3f6",
      boxShadow: "none",
    }),
    option: (base, state) => ({
      ...base,
      background: state.isSelected ? "#f1f3f6" : "#fff",
      color: "#151B26",
      "&:hover": {
        background: "#f1f3f6",
        color: "#151B26",
      },
    }),
    placeholder: (base) => ({
      ...base,
      color: "#A8B9CD",
    }),
    clearIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
  };

  // const changeOptionValue = (value) => {
  //   setOptionValue(value);
  // };

  const handleModalRequest = async (e) => {
    setDisabled2(true);
    var valid = 1;
    if (!validator.fieldValid("bc_name")) {
      valid = 0;
    }
    if (!validator.fieldValid("bc_cost")) {
      valid = 0;
    }
    if (!valid) {
      validator.showMessages();
      forceUpdate(1);
    } else {
      let data = {
        organization: 0,
        // option: "update_cost",
        data: {
          id: 0,
          cost_list_id: 0,
          cost_name: formInput.bc_name,
          percent: formInput.bc_cost,
          is_from: false,
          from: "1",
          cost_option: "flat",
          cost_type: "background_check_cost",
          industry: null,
        },
      };
      let result = await updateCostOrganizationSettingAttempt(
        JSON.stringify(data)
      );
      if (result.status === 200) {
        setOrganizationSettings(result);
        toast.success(Constants.update_message);
      } else {
        toast.error(Constants.update_error_message);
      }
      handleToggleOtherC12();
    }
    setDisabled2(false);
  };

  const handleDrugScreenCostModal = async (e) => {
    setDisabled2(true);
    var valid = 1;
    if (!validator.fieldValid("drug_screen_name")) {
      valid = 0;
    }
    if (!validator.fieldValid("drug_screen_cost")) {
      valid = 0;
    }
    if (!valid) {
      validator.showMessages();
      forceUpdate(1);
    } else {
      let data = {
        organization: 0,
        // option: "update_cost",
        data: {
          id: 0,
          cost_list_id: 0,
          cost_name: formInput.drug_screen_name,
          percent: formInput.drug_screen_cost,
          is_from: false,
          from: "1",
          cost_option: "flat",
          cost_type: "drug_screen_cost",
          industry: null,
        },
      };
      let result = await updateCostOrganizationSettingAttempt(
        JSON.stringify(data)
      );
      if (result.status === 200) {
        setOrganizationSettings(result);
        toast.success(Constants.update_message);
      } else {
        toast.error(Constants.update_error_message);
      }
      handleToggleOtherC22();
    }
    setDisabled2(false);
  };

  const handleMspModal = async (e) => {
    setDisabled2(true);
    var valid = 1;
    if (!validator.fieldValid("msp_name")) {
      valid = 0;
    }
    if (!validator.fieldValid("msp_percent")) {
      valid = 0;
    }
    if (!valid) {
      validator.showMessages();
      forceUpdate(1);
    } else {
      let data = {
        organization: 0,
        // option: "update_cost",
        data: {
          id: 0,
          cost_list_id: 0,
          cost_name: formInput.msp_name,
          percent: formInput.msp_percent,
          is_from: true,
          from: radio6Value,
          cost_option: "flat",
          cost_type: "manage_service_provider",
          industry: null,
        },
      };
      let result = await updateCostOrganizationSettingAttempt(
        JSON.stringify(data)
      );
      if (result.status === 200) {
        setOrganizationSettings(result);
        toast.success(Constants.update_message);
      } else {
        toast.error(Constants.update_error_message);
      }
      handleToggleOtherC32();
    }
    setDisabled2(false);
  };
  const handleVmsModal = async (e) => {
    setDisabled2(true);
    var valid = 1;
    if (!validator.fieldValid("vms_name")) {
      valid = 0;
    }
    if (!validator.fieldValid("vms_percent")) {
      valid = 0;
    }
    if (!valid) {
      validator.showMessages();
      forceUpdate(1);
    } else {
      let data = {
        organization: 0,
        // option: "update_cost",
        data: {
          id: 0,
          cost_list_id: 0,
          cost_name: formInput.vms_name,
          percent: formInput.vms_percent,
          is_from: true,
          from: radio6Value,
          cost_option: "flat",
          cost_type: "vendor_management_system",
          industry: null,
        },
      };
      let result = await updateCostOrganizationSettingAttempt(
        JSON.stringify(data)
      );
      if (result.status === 200) {
        setOrganizationSettings(result);
        toast.success(Constants.update_message);
      } else {
        toast.error(Constants.update_error_message);
      }
      handleToggleOtherC42();
    }
    setDisabled2(false);
  };
  const handlePayrollingModal = async (e) => {
    setDisabled2(true);
    var valid = 1;
    if (!validator.fieldValid("payrolling_name")) {
      valid = 0;
    }
    if (!validator.fieldValid("payrolling_percent")) {
      valid = 0;
    }
    if (!valid) {
      validator.showMessages();
      forceUpdate(1);
    } else {
      let data = {
        organization: 0,
        // option: "update_cost",
        data: {
          id: 0,
          cost_list_id: 0,
          cost_name: formInput.payrolling_name,
          percent: formInput.payrolling_percent,
          is_from: true,
          from: radio6Value,
          cost_option: "flat",
          cost_type: "payrolling_cost",
          industry: null,
        },
      };
      let result = await updateCostOrganizationSettingAttempt(
        JSON.stringify(data)
      );
      if (result.status === 200) {
        setOrganizationSettings(result);
        toast.success(Constants.update_message);
      } else {
        toast.error(Constants.update_error_message);
      }
      handleToggleOtherC52();
    }
    setDisabled2(false);
  };

  const handleInterestModal = async (e) => {
    setDisabled2(true);
    var valid = 1;
    if (!validator.fieldValid("interest_name")) {
      valid = 0;
    }
    if (!validator.fieldValid("interest_percent")) {
      valid = 0;
    }
    if (!valid) {
      validator.showMessages();
      forceUpdate(1);
    } else {
      let data = {
        organization: 0,
        // option: "update_cost",
        data: {
          id: 0,
          cost_list_id: 0,
          cost_name: formInput.interest_name,
          percent: formInput.interest_percent,
          is_from: true,
          from: radio6Value,
          cost_option: "percentage",
          cost_type: "interest",
          industry: null,
        },
      };
      let result = await updateCostOrganizationSettingAttempt(
        JSON.stringify(data)
      );
      if (result.status === 200) {
        setOrganizationSettings(result);
        toast.success(Constants.update_message);
      } else {
        toast.error(Constants.update_error_message);
      }
      handleToggleOtherC82();
    }
    setDisabled2(false);
  };

  const handleLiabilityModal = async (e) => {
    setDisabled2(true);
    var valid = 1;
    if (!validator.fieldValid("liability_insurance_name")) {
      valid = 0;
    }
    if (!validator.fieldValid("liability_insurance_percent")) {
      valid = 0;
    }
    if (!valid) {
      validator.showMessages();
      forceUpdate(1);
    } else {
      let data = {
        organization: 0,
        // option: "update_cost",
        data: {
          id: 0,
          cost_list_id: 0,
          cost_name: formInput.liability_insurance_name,
          percent: formInput.liability_insurance_percent,
          is_from: true,
          from: radio6Value,
          cost_option: "percentage",
          cost_type: "liability_insurance",
          industry: industryValue.value,
        },
      };
      let result = await updateCostOrganizationSettingAttempt(
        JSON.stringify(data)
      );
      if (result.status === 200) {
        setOrganizationSettings(result);
        toast.success(Constants.update_message);
      } else {
        toast.error(Constants.update_error_message);
      }
      handleToggleOtherC92();
    }
    setDisabled2(false);
  };

  const submitCalculator = async () => {
    setDisabled3(true);
    console.log("submit personslist", personList);

    // formatting data
    let personListCopy = personList;
    personList.forEach((person, idx) => {
      let custom_cost_list = {};
      Object.keys(toggleOtherC6).forEach((key, idx2) => {
        custom_cost_list[key] = [];
        custom_cost_list[key] = person.othercost.costTypes_and_ids[key];
      });
      personListCopy[idx]["othercost"]["costTypes_and_ids"][
        "custom_cost_list"
      ] = custom_cost_list;
    });
    setPersonList([...personListCopy]);
    let status = "Approved";
    if (settings?.plan_name === "Enterprise") status = "New calculation";

    let data = {
      person_list: personList,
      name: formInput.calculator_name,
      status: status,
    };
    
    let result;
    if (id != undefined) {
      data = { ...data, ["id"]: id };
      result = await editCalculation(JSON.stringify(data));
    } else {
      result = await createCalculation(JSON.stringify(data));
    }
    if (result.status === 200) {
      toast.success(Constants.update_message);
      if (
        result &&
        result.data &&
        result.data.calculation &&
        result.data.calculation.id
      ) {
        // window
        //   .open("/calculations/" + result.data.calculation.id, "_blank")
        //   .focus();
        history.push("/calculations/" + result.data.calculation.id);
      }
    } else {
      toast.error(Constants.update_error_message);
    }
    setDisabled3(false);
  };

  const calculateStipendData = async () => {
    let personListCopy = personList;

    setPersonList([...personListCopy]);
    let data = {
      person_list: [personList[activePerson]],
      name: formInput.calculator_name,
    };
      debugger
    let result = await calculateStipend(JSON.stringify(data));
    if (result.status === 200) {
      setStipendData(result.data.stipend[0][0].data);
      setShowStepend(true);
    }
  };
  const handleCustomModal = async (e, type) => {
    setDisabled2(true);
    let id = customCostTypes[type].id;
    var valid = 1;
    if (!validator.fieldValid("custom_name")) {
      valid = 0;
    }
    if (!validator.fieldValid("custom_cost")) {
      valid = 0;
    }
    if (!valid) {
      validator.showMessages();
      forceUpdate(1);
    } else {
      let data = {
        organization: 0,
        // option: "update_cost",
        data: {
          id: id,
          cost_list_id: 0,
          cost_name: formInput.custom_name,
          percent: formInput.custom_cost,
          is_from: optionValue == "percentage" ? true : false,
          from: radio6Value,
          cost_option: optionValue,
          cost_type: "custom_cost_list",
          industry: industryValue.value,
        },
      };
      let result = await updateCostOrganizationSettingAttempt(
        JSON.stringify(data)
      );
      if (result.status === 200) {
        setFormInput({ ["custom_name"]: "", ["custom_cost"]: 0 });
        setOrganizationSettings(result);
        toast.success(Constants.update_message);
      } else {
        toast.error(Constants.update_error_message);
      }
      handleToggleOtherC62(type);
    }
    setDisabled2(false);
  };

  const getFromData = (fromId) => {
    let result = radios6.filter((item) => item.value == fromId);
    return result[0].name;
  };

  const formatCustomName = (name) => {
    let parsedData = name.split("_");
    for (var i = 0; i < parsedData.length; i++) {
      parsedData[i] = parsedData[i].replace(/^\w/, (c) => c.toUpperCase());
    }
    return parsedData.join(" ");
  };

  const deleteCal = async (id) => {
    let data = {
      id: id,
    };
    let result = await deleteCalculation(data);
    if (result.status == 200) {
      toast.success("Calculation Deleted Successfully");
      history.push("/calculations");
    } else {
      toast.error("Error, please try again");
    }
  };

  const handleCheckboxSelection = (id) => {
    let parsedData = id.split("-");
    let costType = parsedData[0];
    let parsedId = parsedData[1];

    let costDataCopy = costData;
    if (!costDataCopy[costType]) {
      costDataCopy[costType] = [];
      costDataCopy[costType].push(parsedId);
    } else {
      if (costDataCopy[costType].includes(parsedId)) {
        costDataCopy[costType] = costDataCopy[costType].filter((item) => {
          return item != parsedId;
        });
      } else {
        costDataCopy[costType].push(parsedId);
      }
    }
    setCostData({ ...costDataCopy });
  };

  const addNewWcState = (index) => {
    // console.log("cities options => ", citiesOptions)
    let list = [...formInput.person_list];
    list[index]["wc_states"].push({ state_id: null, city_id: null });
    setFormInput({ ["person_list"]: list });
  }; 
  
  const addNewHealthCarePlan = (index) => {
    let plan = [...formInput.health_plan];
    plan.push({ package: null, package_type: null });
    setFormInput({ ["health_plan"]: plan });
  };

  const handlePackageChange = (stateData, which) => {
    let list = [...formInput.health_plan];
    list = list.map(
      (state, idx) => {
        if (idx === which) {
          return { ...state, package: stateData.value }
        } else {
          return state;
        }
      }
    );
    setFormInput({ ["health_plan"]: list });
  };

  const handlePackageTypeChange = (stateData, which) => {
    let list = [...formInput.health_plan];
    list = list.map(
      (state, idx) => {
        if (idx === which) {
          return { ...state, package_type: stateData.value }
        } else {
          return state;
        }
      }
    );
    setFormInput({ ["health_plan"]: list });
  };

  const handleWcStateChange = (stateData, personId, which) => {
    let list = [...formInput.person_list];
    list[personId]["wc_states"] = list[personId]["wc_states"].map(
      (state, idx) => {
        if (idx === which) {
          return { state_id: stateData.value, wc_settings: [], city_id: null };
        } else {
          return state;
        }
      }
    );
    setFormInput({ ["person_list"]: list });
  };

  const handleWcCityChange = (cityData, personId, which) => {
    let list = [...formInput.person_list];
    list[personId]["wc_states"] = list[personId]["wc_states"].map(
      (state, idx) => {
        if (idx === which) {
          return { ...state, city_id: cityData.value };
        } else {
          return state;
        }
      }
    );
    setFormInput({ ["person_list"]: list });
  };

  const handleWcNicknameChange = (nicknameData, personId, which, stateId) => {
    if (nicknameData.value !== `add`) {
      let list = [...formInput.person_list];
      list[personId]["wc_states"] = list[personId]["wc_states"].map(
        (state, idx) => {
          if (idx === which) {
            let n = nicknames[stateId];
            let nick = n.filter((item) => {
              return item.code === nicknameData.value;
            });
            return {
              ...state,
              wc_settings: {
                nick_name: nicknameData.label,
                code: nicknameData.value,
                industry: nick[0].industry,
                rate: nick[0].rate,
              },
            };
          } else {
            return state;
          }
        }
      );
      setFormInput({ ["person_list"]: list });
    } else {
      setDisabled(false);
      setWcStateModal(stateId);
    }
  };

  const stateHavingId = (id) => {
    return states.find((item) => item.id === id);
  };

  const handleWcModalChange = (e, index) => {
    let wcNicknamesModalCopy = wcNicknamesModal;
    if (e.value) {
      wcNicknamesModalCopy[index]["industry"] = e.value;
    } else {
      if (e.target.name === `nick_name`) {
        wcNicknamesModalCopy[index]["nick_name"] = e.target.value;
      } else if (e.target.name === `code`) {
        wcNicknamesModalCopy[index]["code"] = e.target.value;
      } else if (e.target.name === `rate`) {
        wcNicknamesModalCopy[index]["rate"] = e.target.value;
      }
    }
    setWcNicknamesModal([...wcNicknamesModal]);
  };

  const handleRemoveWcModal = (index) => {
    let wcNicknamesModalCopy = wcNicknamesModal.filter((x, idx) => {
      return idx !== index;
    });
    setWcNicknamesModal([...wcNicknamesModalCopy]);
  };

  const handleAddWcNickname = () => {
    let newNickname = {
      nick_name: "",
      industry: 1,
      code: "",
      rate: "",
    };
    setWcNicknamesModal([...wcNicknamesModal, newNickname]);
  };

  const findIndustry = (id) => {
    let industry = industriesOptions.find((item) => {
      return item.value == id;
    });
    return industry.label;
  };

  const addIndustryLabel = (data) => {
    data = data.map((d, idx) => {
      return { ...d, ["industry_label"]: findIndustry(d.industry) };
    });
    setWcNicknamesModal([...data]);
  };

  const changePerson = async (i) => {
    console.log("hereeeee", personList[i].pay.length, personList[i]);
    if (personList[i].pay && personList[i].pay.compensation_type) {
      setRadioValue(personList[i].pay.compensation_type);
      setFormInput({ ["pay_rate"]: personList[i].pay.pay_rate });
      setFormInput({ ["salary_type"]: personList[i].pay.salary_type });
      setStepend(personList[i].pay.stepend);
      setFormInput({ ["discount"]: personList[i].pay.discount });
      setExampt(personList[i].pay.exampt);
      setRadioValueTwo(personList[i].pay.pay_frequency);
    } else {
      setRadioValue("hourly");
      setFormInput({ ["pay_rate"]: "" });
      setFormInput({ ["salary_type"]: "annual" });
      setStepend(false);
      setFormInput({ ["discount"]: "" });
      setExampt(false);
      setRadioValueTwo("monthly");
    }

    if (personList[i].bill && personList[i].bill.bill_rate) {
      setRadioValueThree(personList[i].bill.compensation_type);
      setFormInput({ ["bill_rate"]: personList[i].bill.bill_rate });
      setFormInput({ ["overtime"]: personList[i].bill.overtime });
    } else if (personList[i].bill && personList[i].bill.markup) {
      setRadioValueThree(personList[i].bill.compensation_type);
      setFormInput({ ["markup"]: personList[i].bill.markup });
      setFormInput({ ["overtime"]: personList[i].bill.overtime });
    } else {
      setRadioValueThree("bill_rate");
      setFormInput({ ["bill_rate"]: "" });
      setFormInput({ ["markup"]: "" });
      setFormInput({ ["overtime"]: 1.5 });
    }

    if (
      personList[i].assignment &&
      personList[i].assignment.have_start_end_date
    ) {
      setRadioValueFour(personList[i].assignment.have_start_end_date);

      setStartDate(
        new Date(moment(personList[i].assignment.start_date).toDate())
      );
      setStartDateTwo(
        new Date(moment(personList[i].assignment.end_date).toDate())
      );
      setRadioValueFive(personList[i].assignment.shadule);
      setFormInput({ ["work_shadule"]: personList[i].assignment.work_shadule });
      setFormInput({
        ["shift_per_week"]: personList[i].assignment.shift_per_week,
      });
      setFormInput({
        ["hours_per_week"]: personList[i].assignment.hours_per_week,
      });
      setFormInput({
        ["estimated_assigment_length"]:
          personList[i].assignment.estimated_assigment_length,
      });
      setFormInput({
        ["estimated_assigment_period"]:
          personList[i].assignment.estimated_assigment_period,
      });
    } else {
      setRadioValueFour("yes");
      setStartDate(new Date());
      setStartDateTwo(new Date());
      setRadioValueFive("shift");
      setFormInput({ ["work_shadule"]: "" });
      setFormInput({ ["shift_per_week"]: "" });
      setFormInput({ ["hours_per_week"]: "" });
      setFormInput({ ["estimated_assigment_length"]: "" });
      setFormInput({ ["estimated_assigment_period"]: "" });
    }

    if (
      personList[i].benifits &&
      typeof personList[i].benifits.length === "undefined"
    ) {
      setToggleBenefits1(personList[i].benifits.holiday_pay);
      setToggleBenefits2(personList[i].benifits.paid_time);
      setToggleBenefits3(personList[i].benifits.k_401);
      setToggleBenefits4(personList[i].benifits.bonus);
      setToggleBenefits5(personList[i].benifits.healthcare_plan);
      setToggleBenefits6(personList[i].benifits.spto);
      setFormInput({ ["paid_time"]: personList[i].benifits.holiday_paid_time });
      setFormInput({ ["holiday"]: personList[i].benifits.holiday_type });
      setFormInput({
        ["pay_time_number_of_days"]:
          personList[i].benifits.paid_time_number_of_days,
      });
      setFormInput({ ["pto"]: personList[i].benifits.pto_type });
      setFormInput({
        ["number_of_days"]: personList[i].benifits.k_401_number_of_days,
      });
      setFormInput({ ["pay"]: personList[i].benifits.bonus_pay });
      setFormInput({ ["bonus"]: personList[i].benifits.bonus_type });
      setFormInput({ ["package"]: personList[i].benifits.healthcare_package });
      setFormInput({
        ["package_type"]: personList[i].benifits.healthcare_package_type,
      });
    } else {
      console.log(5555, typeof personList[i].benifits.length === "undefined");
      setToggleBenefits1(false);
      setToggleBenefits2(false);
      setToggleBenefits3(false);
      setToggleBenefits4(false);
      setToggleBenefits5(false);
      setToggleBenefits6(false);
      setFormInput({ ["paid_time"]: "" });
      setFormInput({ ["holiday"]: "" });
      setFormInput({ ["pay_time_number_of_days"]: "" });
      setFormInput({ ["pto"]: "" });
      setFormInput({ ["number_of_days"]: "" });
      setFormInput({ ["pay"]: "" });
      setFormInput({ ["bonus"]: "" });
      setFormInput({ ["package"]: "" });
      setFormInput({ ["package_type"]: "" });
    }
    if (
      personList[i].othercost &&
      typeof personList[i].othercost.length === "undefined"
    ) {
      console.log("personList[i].othercost", personList[i].othercost);
      setToggleOtherC1(false);
      setToggleOtherC2(false);
      setToggleOtherC3(false);
      setToggleOtherC4(false);
      setToggleOtherC5(false);
      setToggleOtherC6({});
      setToggleOtherC7(false);
      setToggleOtherC8(false);
      setToggleOtherC9(false);

      setFormInput({
        ["othercost_number_of_days"]: personList[i].othercost.number_of_days,
      });

      let otherCostErrorsCopy;
      let toggles6Copy = {};
      Object.keys(personList[i].othercost.costTypes_and_ids).map(
        (element, idx) => {
          if (personList[i].othercost.costTypes_and_ids[element].length > 0) {
            if (element === "background_check_cost") {
              setToggleOtherC1(true);
            } else if (element === "drug_screen_cost") {
              setToggleOtherC2(true);
            } else if (element === "manage_service_provider") {
              setToggleOtherC3(true);
            } else if (element === "payrolling_cost") {
              setToggleOtherC5(true);
            } else if (element === "vendor_management_system") {
              setToggleOtherC4(true);
            } else if (element === "interest") {
              setToggleOtherC8(true);
            } else if (element === "liability_insurance") {
              setToggleOtherC9(true);
            } else {
              toggles6Copy = { ...toggles6Copy, [element]: true };
            }
          }
          otherCostErrorsCopy = { ...otherCostErrorsCopy, [element]: "" };

          console.log(
            "element,indexelement,index",
            personList[i].othercost.costTypes_and_ids[element],
            idx
          );
        }
      );
      setToggleOtherC6({ ...toggles6Copy });
      setOtherCostErrors({ ...otherCostErrorsCopy });
      setCostData({ ...personList[i].othercost.costTypes_and_ids });
    } else {
      setToggleOtherC1(false);
      setToggleOtherC2(false);
      setToggleOtherC3(false);
      setToggleOtherC4(false);
      setToggleOtherC5(false);
      setToggleOtherC6({});
      setToggleOtherC7(false);
      setToggleOtherC8(false);
      setToggleOtherC9(false);

      let otherCost;
      let otherCostErrorsCopy;
      Object.keys(otherCostErrors).map((element, idx) => {
        otherCostErrorsCopy = { ...otherCostErrorsCopy, [element]: "" };
        otherCost = { ...otherCost, [element]: [] };
      });
      setOtherCostErrors({ ...otherCostErrorsCopy });
      setCostData({
        ...otherCost,
      });
    }

    setDefaultActiveKey("pay");
    setDefaultActiveKeyId(0);
    setActivePerson(i);
  };

  const submitWcModal = async (e) => {
    setDisabled(true);
    var valid = 1;

    addIndustryLabel(wcNicknamesModal);

    wcNicknamesModal.forEach((element, ind) => {
      if (
        !validator.fieldValid("code_" + ind) ||
        !validator.fieldValid("nick_name_" + ind) ||
        !validator.fieldValid("rate_" + ind)
      ) {
        valid = 0;
      }
    });

    if (!valid) {
      validator.showMessages();
      forceUpdate(1);
      setDisabled(false);
    } else {
      let organization = settings.data.organization_users[0].organization;

      let data = {
        organization: organization.id,
        state: wcStateModal,
        wc_codes: wcNicknamesModal,
      };
      let result = await wcSettingsOrganizationSettingAttempt(
        JSON.stringify(data)
      );
      if (result.status === 200) {
        setOrganizationSettings(result);
        toast.success(Constants.update_message);
        handleCloseWcModal();
      } else {
        toast.error(Constants.update_error_message);
      }

      setDisabled(false);
    }
  };

  const getCitiesByState = (stateId, citiesData) => {
    let citiesofStateId = citiesData.filter((item) => {
      return item.state_id === stateId;
    });
    citiesofStateId = citiesofStateId.map((item, idx) => {
      return { label: item.name, value: item.id };
    });
    return [...citiesofStateId];
  };

  const handleRemoveWcState = (personId, wc_state_index) => {
    let list = [...formInput.person_list];
    list[personId]["wc_states"] = list[personId]["wc_states"].filter(
      (item, idx) => {
        return idx !== wc_state_index;
      }
    );
    setFormInput({ ["person_list"]: list });
  };

  const handleRemoveHealthPlan = (plan_index) => {
    let list = [...formInput.health_plan];
    list = list.filter(
      (item, idx) => {
        return idx !== plan_index;
      }
    );
    setFormInput({ ["health_plan"]: list });
  };
  
  const renderCostDataFormat = (c, percent = 0) => {
    return (
      <>
        {percent ? (
          <>
            {/* for % */}
            <span className="text-info mr-1">{c.percent}% </span>
            {c.from ? getFromData(c.from) : ""}
          </>
        ) : (
          <>
            {/* for $ */}
            <span className="text-success">$</span>
            {c.percent} {c.from ? getFromData(c.from) : ""}
          </>
        )}
      </>
    );
  };

  useEffect(() => {
    document.querySelector('html').classList.add('calculation-details-page');
    document.querySelector("html").classList.remove("homepage");
    async function getCal() {
      let data = {
        id: id,
      };
      setCalculations(await getCalculation(JSON.stringify(data)));
    }
    if (id != undefined) getCal();

    async function fetchStates() {
      statesOptions = [];
      setStates(await getStatesAttempt(Constants.default_county.value));
    }
    fetchStates();

    async function fetchPackages() {
      statesOptions = [];
      setPackages(await packageOrganization());
    }
    fetchPackages();

    async function fetchIndustries() {
      setIndustries(await getIndustriesAttempt());
    }
    fetchIndustries();

    async function fetchSettings() {
      setSettings(await getSettings());
    }
    fetchSettings();

    async function getOrganizationSettings() {
      let result = await getOrganizationSettingAttempt();
      setOrganizationSettings(result);
    }
    getOrganizationSettings();

    async function fetchCities() {
      setCities(await getCitiesAttempt());
    }
    fetchCities();
    async function fetchBillSettings() {
      setBillSettings(await getBillSettings());
    }
    fetchBillSettings();

    if (localStorage.getItem("calculatorDemo") === "true") {
      setTimeout(() => {
        setDemoModalShow(true);
      }, 500);
    }
  }, []);

  useEffect(() => {
    if (settings.expire && settings.expire === true) {
      setIsPaymentShowing(true);
    }
    setAlwaysAccrue(
      settings?.data?.organization_users[0]?.organization?.always_accrue
    );
  }, [settings]);

  useEffect(() => {
    if (id != undefined) {
      if (calculations) {
        if (calculations.status === 200) {
          let data = calculations.data;
          console.log("data", data);
          setPersonList([...data.calculation.person_list]);
          setFormInput({ ["calculator_name"]: data.calculation.name });
        }
      }
    }
  }, [calculations]);

  useEffect(() => {
    if (id != undefined) {
      if (personList.length > 0 && loaded === false) {
        changePerson(activePerson);
        setLoaded(true);
      }
    }
  }, [personList]);

  useEffect(() => {
    wcStates.forEach((state) => {
      if (state.state_id === wcStateModal) {
        if (state["wc_settings"]) {
          setWcNicknamesModal([...state["wc_settings"]]);
        } else {
          handleAddWcNickname();
        }
        handleModalClose();
        setShowWcModal(true);
      }
    });
  }, [wcStateModal]);

  useEffect(() => {
    // Setting options
    let statesCopy = [];
    wcStates.forEach((s) => {
      if (s.state_id > 0) {
        let result =stateHavingId(s.state_id);
        console.log(result)
        if (result != null) {
          statesCopy.push({ label: result.name, value: result.id });
        }
      }
    });
    console.log("statesCopy", statesCopy);
    setWcStatesOptions([...statesCopy]);

    // setting nicknames
    let nicknameOptionsCopy = {};
    let citiesOptionsCopy = {};
    let nicknamesCopy = {};
    for (let i = 0; i < wcStates.length; i++) {
      if (wcStates[i]["wc_settings"]) {
        for (let j = 0; j < wcStates[i]["wc_settings"].length; j++) {
          if (!nicknamesCopy[wcStates[i]["state_id"]]) {
            nicknamesCopy[wcStates[i]["state_id"]] = [];
          }
          nicknamesCopy[wcStates[i]["state_id"]].push(
            wcStates[i]["wc_settings"][j]
          );
        }
      }
      nicknameOptionsCopy[wcStates[i]["state_id"]] = [];
      let customButton = (
        <button type="button" class="btn btn-light btn-block">
          <svg
            stroke="currentColor"
            fill="currentColor"
            stroke-width="0"
            viewBox="0 0 16 16"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M8 3.5a.5.5 0 01.5.5v4a.5.5 0 01-.5.5H4a.5.5 0 010-1h3.5V4a.5.5 0 01.5-.5z"
              clip-rule="evenodd"
            ></path>
            <path
              fill-rule="evenodd"
              d="M7.5 8a.5.5 0 01.5-.5h4a.5.5 0 010 1H8.5V12a.5.5 0 01-1 0V8z"
              clip-rule="evenodd"
            ></path>
          </svg>{" "}
          Add Workers Comp Code{" "}
        </button>
      );
      if (!isTeamMember) {
        nicknameOptionsCopy[wcStates[i]["state_id"]].push({
          label: customButton,
          value: "add",
        });
      }
    }
    setCitiesOptions({ ...citiesOptionsCopy });
    setNicknameOptions({ ...nicknameOptionsCopy });
    setNicknames({ ...nicknamesCopy });
  }, [wcStates,states]);

  useEffect(() => {
    let citiesOptionsCopy = {};

    if (wcStates.length > 0 && cities.length > 0) {
      for (let i = 0; i < wcStates.length; i++) {
        if (wcStates[i]["state_id"]) {
          if (!citiesOptionsCopy[wcStates[i]["state_id"]]) {
            citiesOptionsCopy[wcStates[i]["state_id"]] = [
              ...getCitiesByState(wcStates[i]["state_id"], cities),
            ];
          }
        }
      }
    }
    setCitiesOptions({ ...citiesOptionsCopy });
  }, [cities, wcStates]);

  useEffect(() => {
    let nicknameOptionsCopy = nicknameOptions;
    Object.keys(nicknames).map((stateId, idx) => {
      for (var i = 0; i < nicknames[stateId].length; i++) {
        if (!nicknameOptionsCopy[stateId]) {
          nicknameOptionsCopy[stateId] = [];
        }
        nicknameOptionsCopy[stateId].unshift({
          label: nicknames[stateId][i].nick_name,
          value: nicknames[stateId][i].code,
        });
      }
    });
    setNicknameOptions({ ...nicknameOptionsCopy });
  }, [nicknames]);

  useEffect(() => {
    if (
      organizationSettings &&
      organizationSettings.data &&
      organizationSettings.data.costs
    ) {
      let costTypes = organizationSettings.data.costs;
      let otherCostErrorsCopy = otherCostErrors;
      let customToggles6Copy = toggleOtherC6;
      let costDataCopy = costData;
      let customCostTypesCopy;
      // console.log("cossts => ", organizationSettings.data.costs);
      for (var key in costTypes) {
        if (costTypes.hasOwnProperty(key)) {
          let singleType = costTypes[key];
          for (var i = 0; i < singleType.length; i++) {
            if (singleType[i].cost_type === "custom_cost_list") {
              customCostTypesCopy = {
                ...customCostTypesCopy,
                [key]: {
                  data: singleType,
                  id: singleType[i].organization_other_cost_id,
                },
              };
              if (!customToggles6Copy[key]) {
                customToggles6Copy = { ...customToggles6Copy, [key]: false };
              }
              if (!otherCostErrorsCopy[key]) {
                otherCostErrorsCopy = { ...otherCostErrorsCopy, [key]: "" };
              }
              if (!costDataCopy[key]) {
                costDataCopy = { ...costDataCopy, [key]: [] };
              }
              break;
            }
          }
        }
      }
      setCostData({ ...costDataCopy });
      setOtherCostErrors({ ...otherCostErrorsCopy });
      setToggleOtherC6({ ...customToggles6Copy });
      setCustomCostTypes({ ...customCostTypesCopy });
      // console.log("All Custom Costs => ", customCostTypesCopy);
      setWcStates(organizationSettings.data.organization_settings);
    }
  }, [organizationSettings]);

  useEffect(() => {
    // Filtration of liability insurance cost based on nickname industries
    if (
      organizationSettings &&
      organizationSettings.data &&
      organizationSettings.data.costs &&
      organizationSettings.data.costs["liability_insurance"]
    ) {
      let allIndustriesCopy = [];
      if (
        personList &&
        personList[activePerson] &&
        personList[activePerson]["wc_states"].length > 0
      ) {
        let wc_states = personList[activePerson]["wc_states"];
        wc_states.forEach((s, i) => {
          if (s.wc_settings.industry == 1) {
            industries.forEach((ind) => {
              allIndustriesCopy.push(ind.id);
            });
          } else {
            allIndustriesCopy.push(s.wc_settings.industry);
          }
        });
        setAllIndustries([...allIndustriesCopy]);
      }
    }
  }, [organizationSettings, activePerson, personList]);

  let industriesOptions = [];
  {
    industries &&
      industries.map((industry) =>
        industriesOptions.push({ label: industry.name, value: industry.id })
      );
  }

  useEffect(() => {
    if (
      packages &&
      packages.data &&
      packages.data.packages &&
      packages.data.packages.length !== 0
    ) {
      {
        packagesOptions = [];
        packages.data.packages.map((element, index) => {
          packagesOptions.push({
            label: element.package_name,
            value: element.id,
          });
        });
        console.log(packagesOptions);
      }
    }
  }, [packages]);

  useEffect(() => {
    if (states && states.length !== 0) {
      {
        states &&
          states.map((state) =>
            statesOptions.push({ label: state.name, value: state.id })
          );
      }
    }
  }, [states]);

  function chooseShadule() {
    switch (radioValueFive) {
      case "shift":
        return (
          <>
            {radioValueFour === "yes" ? (
              <Row>
                <Col lg={6}>
                  <Form.Group className="datepicker">
                    <Form.Label>Hours per Shift</Form.Label>
                    <InputGroup>
                      <FormControl
                        id="work_shadule"
                        name="work_shadule"
                        type="number"
                        value={formInput.work_shadule}
                        onChange={handleInput}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                      />
                      <InputGroup.Append>
                        <InputGroup.Text className="pl-0">
                          <span className="icon"></span>
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                    {validator.message(
                      "work_shadule",
                      formInput.work_shadule,
                      "required|numeric|min:0,num"
                    )}
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="datepicker">
                    <Form.Label>Shift per week</Form.Label>
                    <InputGroup>
                      <FormControl
                        id="shift_per_week"
                        name="shift_per_week"
                        type="number"
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        value={formInput.shift_per_week}
                        onChange={handleInput}
                      />
                      <InputGroup.Append>
                        <InputGroup.Text className="pl-0">
                          <span className="icon"></span>
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                    {validator.message(
                      "shift_per_week",
                      formInput.shift_per_week,
                      "required|numeric|min:0,num"
                    )}
                  </Form.Group>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col lg={6}>
                  <Form.Group>
                    <Form.Label>Hours per shift</Form.Label>
                    <FormControl
                      id="hours_per_week"
                      name="hours_per_week"
                      value={formInput.hours_per_week}
                      onChange={handleInput}
                      type="number"
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                    />
                    {validator.message(
                      "hours_per_week",
                      formInput.hours_per_week,
                      "required|numeric|min:0,num"
                    )}
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group>
                    <Form.Label>Shift per week</Form.Label>
                    <FormControl
                      id="shift_per_week"
                      name="shift_per_week"
                      value={formInput.shift_per_week}
                      onChange={handleInput}
                      type="number"
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                    />
                    {validator.message(
                      "shift_per_week",
                      formInput.shift_per_week,
                      "required|numeric|min:0,num"
                    )}
                  </Form.Group>
                </Col>
              </Row>
            )}
          </>
        );
      case "full-time":
        return "";
      case "part-time":
        return (
          <Row>
            <Col lg={12}>
              <Form.Group>
                <Form.Label>Hours per week</Form.Label>
                <FormControl
                  id="hours_per_week"
                  name="hours_per_week"
                  value={formInput.hours_per_week}
                  onChange={handleInput}
                  type="number"
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                />
                {validator.message(
                  "hours_per_week",
                  formInput.hours_per_week,
                  "required|numeric|min:0,num"
                )}
              </Form.Group>
            </Col>
          </Row>
        );
      default:
        return "";
    }
  }

  function chooseHaveDate() {
    return (
      <>
        {radioValueFour === "no" ? (
          <Row>
            <Col lg={8}>
              <Form.Group className="datepicker">
                <Form.Label>Estimated assigment length</Form.Label>
                <FormControl
                  id="estimated_assigment_length"
                  name="estimated_assigment_length"
                  type="number"
                  value={formInput.estimated_assigment_length}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onChange={handleInput}
                />
                {validator.message(
                  "estimated_assigment_length",
                  formInput.estimated_assigment_length,
                  "required",
                  {
                    className: "text-danger",
                  }
                )}
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="datepicker">
                <Select
                  className="custommargin"
                  options={Constants.estimated_assigment_period}
                  styles={customControlStyles}
                  value={Constants.estimated_assigment_period.find(
                    ({ value }) =>
                      value === formInput.estimated_assigment_period
                  )}
                  name="estimated_assigment_period"
                  onChange={(e) => {
                    handleSelect(e, "estimated_assigment_period");
                  }}
                />
                {validator.message(
                  "estimated_assigment_period",
                  formInput.estimated_assigment_period,
                  "required",
                  {
                    className: "text-danger",
                  }
                )}
              </Form.Group>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col lg={6}>
              <Form.Group className="datepicker">
                <Form.Label>Start date</Form.Label>
                <InputGroup className={`${activeClass ? "" : "input-group"}`}>
                  <DatePicker
                    minDate={new Date("01-01-2020")}
                    selected={startDate}
                    className="datepicker-form-control"
                    onChange={(date, e) => handleDateChange(date, e)}
                    onFocus={handleDatepickerFocus}
                    onBlur={handleDatepickerBlur}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text className="pl-0">
                      <span className="icon"></span>
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="datepicker">
                <Form.Label>End date</Form.Label>
                <InputGroup className={`${activeClass ? "" : "input-group"}`}>
                  <DatePicker
                    minDate={startDate}
                    selected={startDateTwo}
                    className="datepicker-form-control"
                    onChange={(date, e) => handleDateChangeTwo(date, e)}
                    onFocus={handleDatepickerFocus}
                    onBlur={handleDatepickerBlur}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text className="pl-0">
                      <span className="icon"></span>
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
        )}
      </>
    );
  }

  let haveDate = chooseHaveDate();

  let shadule = chooseShadule();

  function getStepContent() {
    switch (step) {
      case "add_persons":
        return (
          <div className="d-flex align-items-center justify-content-center calculator-page">
            <Container>
              <Row className="justify-content-center">
                <Col lg={6}>
                  <Card className="p-4">
                    <Card.Header>
                      <Card.Title className="with-icon">
                        <div className="d-flex align-items-center">
                          <div className="icon">
                            <img src={PersonIcon} alt="person icon" />
                          </div>
                          <div className="text-details">
                            <h3>Persons</h3>
                            <p>Add persons and select locations for them</p>
                          </div>
                        </div>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body className="pt-0">
                      <div className="person-lists">
                        {personList.map((person, i) => {
                          return (
                            <div className="person-list">
                              <div
                                className="title"
                                onClick={() => {
                                  setFormInput({
                                    ["person_list"]: [personList[i]],
                                  });
                                  setEditPersonIndex(i);
                                  setShow(true);
                                }}
                              >
                                {person?.job_name}
                              </div>
                              <Button
                                className="btn-icon"
                                variant="outline-dark"
                                onClick={() => removePersonAdded(i)}
                              >
                                <RiCloseLine />
                              </Button>
                            </div>
                          );
                        })}
                      </div>
                      <Button
                        variant="light"
                        block
                        onClick={handleModalShow}
                        hidden={
                          personList.length === 1 &&
                          settings.plan_name === "Essential"
                            ? true
                            : false
                        }
                      >
                        <BsPlus /> Add Person
                      </Button>
                      
                      <Button
                        variant="light"
                        block
                        disabled={personList.length > 0 ? false : true}
                        onClick={(e) => handleShow(e, "job_detail")}
                      >
                        Continue
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        );
      case "job_detail":
        return (
          <div className="calculator-page">
            {/* {console.log("organizationSettings => ", organizationSettings.data)} */}
            <Row className="h-100">
              {personList.length > 1 ? (
                <Col xl={3} lg={4} className="pr-0">
                  <div className="multiple-persons-tab h-100 bg-white border-right">
                    <div className="top-details d-flex justify-content-between align-items-center">
                      <strong>{formInput.calculator_name}</strong>
                      <span className="ml-4 text-muted">
                        {personList.length > 1 ? "Multiple" : "Single"}
                      </span>
                    </div>
                    {personList.length > 1 ? (
                      <>
                        <div className="lists">
                          <div className="header">
                            <div className="details">
                              <strong>Individuals</strong>
                              <p>
                                Fill in the calculation for each person in turn
                              </p>
                            </div>
                            <Button
                              variant="primary"
                              className="btn-add"
                              onClick={handleModalShow}
                            >
                              <BsPlus />
                            </Button>
                          </div>
                          <div className="holder">
                            <div className="mobile-persons-list">Persons</div>
                            <div className="list-holder">
                              {personList.map((person, i) => {
                                return (
                                  <div className="d-flex">
                                    {console.log(personList)}
                                    <div
                                      className={
                                        activePerson === i
                                          ? "list active"
                                          : "list "
                                      }
                                      onClick={(e) =>{
                                         changePerson(i)
                                        
                                      }}
                                    >
                                      <div className="title">
                                        {person?.job_name}
                                      </div>
                                      <ul className="list-unstyled">
                                        <li
                                          className={
                                            person?.pay &&
                                            person?.pay.compensation_type
                                              ? "completed"
                                              : null
                                          }
                                        >
                                          <HiCheck />
                                        </li>
                                        {console.log("person.bill", person?.bill)}
                                        <li
                                          className={
                                            person.bill && person.bill.bill_rate
                                              ? "completed"
                                              : person.bill &&
                                                person.bill.markup
                                              ? "completed"
                                              : null
                                          }
                                        >
                                          <HiCheck />
                                        </li>
                                        <li
                                          className={
                                            person?.assignment &&
                                            person?.assignment.have_start_end_date
                                              ? "completed"
                                              : null
                                          }
                                        >
                                          <HiCheck />
                                        </li>
                                        <li
                                          className={
                                            person?.benifits &&
                                            typeof person?.benifits.length ===
                                              "undefined"
                                              ? "completed"
                                              : null
                                          }
                                        >
                                          <HiCheck />
                                        </li>
                                        <li
                                          className={
                                            person?.othercost &&
                                            typeof person?.othercost.length ===
                                              "undefined"
                                              ? "completed"
                                              : null
                                          }
                                        >
                                          <HiCheck />
                                        </li>
                                      </ul>
                                      <Button
                                        className="btn-icon btn-cancel"
                                        variant="danger"
                                        onClick={() => removePersonAdded(i)}
                                      >
                                        <RiCloseLine />
                                      </Button>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                        <div className="px-4">
                          <Button
                            block
                            variant="primary"
                            onClick={submitCalculator}
                            disabled={disabledCalculator || disabled3}
                          >
                            {id != undefined
                              ? "Update calculation"
                              : "Create calculation"}
                          </Button>
                        </div>
                      </>
                    ) : null}
                  </div>
                </Col>
              ) : null}
              <Col
                xl={`${personList.length > 1 ? 9 : 12}`}
                lg={`${personList.length > 1 ? 8 : 12}`}
                className="pl-0"
              >
                <div className="top-details d-flex justify-content-between align-items-center">
                  <div
                    className={`text-left ${
                      personList.length > 1 ? "" : "pl-4"
                    }`}
                  >
                    <h3>{personList[activePerson]?.job_name}</h3>
                  </div>
                  {id != null ? (
                    <div className="text-right">
                      <Button
                        className="btn-edit-custom mr-3"
                        variant="outline-dark"
                        onClick={() => deleteCal(id)}
                      >
                        <div className="icon">
                          <img
                            src={TrashIcon}
                            alt="Trash Icon"
                            className="icon-black"
                          />
                          <img
                            src={TrashWhiteIcon}
                            alt="Trash Icon"
                            className="icon-white"
                          />
                        </div>
                        Delete
                      </Button>
                      {/* <Button
                        className="btn-edit-custom"
                        variant="outline-dark"
                      >
                        <div className="icon">
                          <img
                            src={EditIcon}
                            alt="Edit Icon"
                            className="icon-black"
                          />
                          <img
                            src={EditWhiteIcon}
                            alt="Edit Icon"
                            className="icon-white"
                          />
                        </div>
                        Edit
                      </Button> */}
                    </div>
                  ) : null}
                </div>
                <Row className="justify-content-center">
                  <Col
                    xl={`${personList.length > 1 ? 9 : 7}`}
                    lg={`${personList.length > 1 ? 10 : 11}`}
                  >
                    <Tab.Container activeKey={defaultActiveKey}>
                      <div className="custom-tabs-holder">
                        <Nav>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="pay"
                              className={
                                personList[activePerson]?.pay &&
                                personList[activePerson]?.pay.compensation_type
                                  ? "active"
                                  : null
                              }
                            >
                              <HiCheck /> Pay
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="bill"
                              className={
                                personList[activePerson]?.bill &&
                                personList[activePerson]?.bill?.bill_rate
                                  ? "active"
                                  : personList[activePerson]?.bill &&
                                    personList[activePerson]?.bill?.markup
                                  ? "active"
                                  : null
                              }
                            >
                              <HiCheck /> Bill
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="assignment-details"
                              className={
                                personList[activePerson]?.assignment &&
                                personList[activePerson]?.assignment
                                  .have_start_end_date
                                  ? "active"
                                  : null
                              }
                            >
                              <HiCheck /> Assignment Details
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="benifits"
                              className={
                                personList[activePerson]?.benifits &&
                                typeof personList[activePerson]?.benifits
                                  .length === "undefined"
                                  ? "active"
                                  : null
                              }
                            >
                              <HiCheck /> Benefits
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="othercost"
                              className={
                                personList[activePerson]?.othercost &&
                                typeof personList[activePerson]?.othercost
                                  .length === "undefined"
                                  ? "active"
                                  : null
                              }
                            >
                              <HiCheck /> Other Costs
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </div>
                      <Tab.Content>
                        <Tab.Pane eventKey="pay">
                          <div className="details-holder p-2">
                            <h4>Enter Pay</h4>
                            <Form.Group>
                              <Form.Label className="mb-3">
                                Compensation type
                              </Form.Label>
                              <ButtonGroup
                                className="w-100 custom-btn-holder"
                                toggle
                              >
                                {radios1.map((radio) => (
                                  <ToggleButton
                                    key={radio.value}
                                    id={`radio-${radio.value}`}
                                    variant="light"
                                    type="radio"
                                    name="radio"
                                    value={radio.value}
                                    checked={radioValue === radio.value}
                                    onChange={(e) =>
                                      setRadioValue(e.currentTarget.value)
                                    }
                                  >
                                    {radio.name}
                                  </ToggleButton>
                                ))}
                              </ButtonGroup>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>
                                {radioValue === `salary`
                                  ? radioValue.charAt(0).toUpperCase() +
                                    radioValue.slice(1)
                                  : "Pay rate"}
                              </Form.Label>
                              <InputGroup>
                                <FormControl
                                  id="pay_rate"
                                  name="pay_rate"
                                  type="number"
                                  value={formInput.pay_rate}
                                  onFocus={handleFocus}
                                  onBlur={handleBlur}
                                  onChange={handleInput}
                                />

                                <InputGroup.Append>
                                  <InputGroup.Text className="pl-0">
                                    $
                                  </InputGroup.Text>
                                </InputGroup.Append>
                              </InputGroup>
                              {validator.message(
                                "pay_rate",
                                formInput.pay_rate,
                                "required|numeric|min:0,num"
                              )}
                            </Form.Group>
                            {radioValue === `salary` ? (
                              <Form.Group>
                                <Form.Label>Salary Type</Form.Label>
                                <Select
                                  defaultValue={Constants.salary_type[0]}
                                  options={Constants.salary_type}
                                  styles={customControlStyles}
                                  value={Constants.salary_type.find(
                                    ({ value }) =>
                                      value === formInput.salary_type
                                  )}
                                  name="salary_type"
                                  onChange={(e) => {
                                    handleSelect(e, "salary_type");
                                  }}
                                />
                                {validator.message(
                                  "salary_type",
                                  formInput.salary_type,
                                  "required",
                                  {
                                    className: "text-danger",
                                  }
                                )}
                              </Form.Group>
                            ) : (
                              ""
                            )}

                            <div className="d-flex justify-content-between py-3 pb-4">
                              {settings?.plan_name !== "Essential" ? (
                                <div className="custom-checkbox">
                                  <input
                                    id="add-stepend"
                                    type="checkbox"
                                    checked={stepend ? "checked" : ""}
                                    onChange={(e) => handleStependModal(e)}
                                  />
                                  <label for="add-stepend">Add Stipend</label>
                                </div>
                              ) : (
                                <></>
                              )}
                              <Modal
                                animation={false}
                                className="add-stipend-modal"
                                size="lg"
                                centered
                                show={stepend && showStepend}
                                onHide={handleStependHide}
                              >
                                <Modal.Header closeButton></Modal.Header>
                                <Modal.Body className="p-0">
                                  <Container>
                                    <Row>
                                      <Col lg={6} className="p-0">
                                        <div className="h-100 bg-grey border-right box-holder">
                                          <h4>Add Stipend</h4>
                                          <ul className="m-0 p-0 list-unstyled">
                                            <li className="d-flex justify-content-between align-items-center">
                                              <span>M&amp;I:</span>
                                              <p className="price">
                                                <span className="text-success">
                                                  $
                                                </span>{" "}
                                                {stipendData?.m_i} USD
                                              </p>
                                            </li>
                                            <li className="d-flex justify-content-between align-items-center">
                                              <span>Lodging:</span>
                                              
                                              <p className="price">
                                                <span className="text-success">
                                                  $
                                                </span>{" "}
                                                {stipendData?.lodging} USD
                                              </p>
                                            </li>
                                            <li className="d-flex justify-content-between align-items-center">
                                              <span>Total Daily:</span>
                                              <p className="price">
                                                <span className="text-success">
                                                  $
                                                </span>{" "}
                                                {stipendData?.total_daily} USD
                                              </p>
                                            </li>
                                            <li className="d-flex justify-content-between align-items-center">
                                              <span>Weekly:</span>
                                              <p className="price">
                                                <span className="text-success">
                                                  $
                                                </span>{" "}
                                                {stipendData?.weekly} USD
                                              </p>
                                            </li>
                                            <li className="d-flex justify-content-between align-items-center">
                                              <span>Total Assignment:</span>
                                              <p className="price">
                                                <span className="text-success">
                                                  $
                                                </span>{" "}
                                                {stipendData?.total_assignment}{" "}
                                                USD
                                              </p>
                                            </li>
                                            {radioValueFive === "shift" ? (
                                              <>
                                                <li className="d-flex justify-content-between align-items-center">
                                                  <span>
                                                    No. of Shifts in Assignment:
                                                  </span>
                                                  <p className="price">
                                                    {
                                                      stipendData?.number_of_shifts
                                                    }
                                                  </p>
                                                </li>
                                                <li className="d-flex justify-content-between align-items-center">
                                                  <span>Hours per Shift:</span>
                                                  <p className="price">
                                                    {
                                                      stipendData?.hours_per_shift
                                                    }
                                                  </p>
                                                </li>
                                                <li className="d-flex justify-content-between align-items-center">
                                                  <span>
                                                    Total No. of Hours:
                                                  </span>
                                                  <p className="price">
                                                    {
                                                      stipendData?.total_numbers_of_hours
                                                    }
                                                  </p>
                                                </li>
                                              </>
                                            ) : radioValueFive ===
                                              "full-time" ? (
                                              <>
                                                <li className="d-flex justify-content-between align-items-center">
                                                  <span>No. of Weeks:</span>
                                                  <p className="price">
                                                    {stipendData?.weeks}
                                                  </p>
                                                </li>
                                              </>
                                            ) : (
                                              <>
                                                <li className="d-flex justify-content-between align-items-center">
                                                  <span>
                                                    No. of Hours per Week:
                                                  </span>
                                                  <p className="price">
                                                    {
                                                      stipendData?.hours_per_shift
                                                    }
                                                  </p>
                                                </li>
                                                <li className="d-flex justify-content-between align-items-center">
                                                  <span>No. of Weeks:</span>
                                                  <p className="price">
                                                    {stipendData?.weeks}
                                                  </p>
                                                </li>
                                                <li className="d-flex justify-content-between align-items-center">
                                                  <span>
                                                    Total No. of Hours:
                                                  </span>
                                                  <p className="price">
                                                    {
                                                      stipendData?.total_numbers_of_hours
                                                    }
                                                  </p>
                                                </li>
                                              </>
                                            )}
                                          </ul>
                                        </div>
                                      </Col>
                                      <Col lg={6} className="p-0">
                                        <div className="box-holder">
                                          <h4>Discount</h4>
                                          <div className="discount-box">
                                            <InputGroup>
                                              <FormControl
                                                id="discount"
                                                name="discount"
                                                value={formInput.discount}
                                                type="number"
                                                onFocus={handleFocus}
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                  handleInput(e);
                                                }}
                                              />
                                              <InputGroup.Append>
                                                <InputGroup.Text className="pl-0">
                                                  %
                                                </InputGroup.Text>
                                              </InputGroup.Append>
                                            </InputGroup>
                                            {validator.message(
                                              "discount",
                                              formInput.discount,
                                              "required|numeric|min:0,num|max:100,num"
                                            )}
                                          </div>
                                          <ul className="m-0 p-0 list-unstyled">
                                            <li className="d-flex justify-content-between align-items-center">
                                              <span>Total Stipend:</span>
                                              <p className="price">
                                                <span className="text-success">
                                                  $
                                                </span>{" "}
                                                {stipendData?.stipend_total} USD
                                              </p>
                                            </li>
                                            <li className="d-flex justify-content-between align-items-center">
                                              <span>
                                                Stipend per{" "}
                                                {radioValueFive === "shift"
                                                  ? "Shift"
                                                  : "Week"}
                                                :
                                              </span>
                                              <p className="price">
                                                <span className="text-success">
                                                  $
                                                </span>{" "}
                                                {radioValueFive === "shift"
                                                  ? stipendData?.stipend_per_shift
                                                  : (
                                                      parseFloat(
                                                        (stipendData?.stipend_total
                                                          ? stipendData.stipend_total
                                                          : "0"
                                                        ).replace(",", "")
                                                      ) /
                                                      parseFloat(
                                                        (stipendData?.weeks
                                                          ? stipendData.weeks
                                                          : "0"
                                                        ).replace(",", "")
                                                      )
                                                    ).toFixed(2)}{" "}
                                                USD
                                              </p>
                                            </li>
                                            {radioValueFive !== "full-time" ? (
                                              radioValueFive === "shift" ? (
                                                <li className="d-flex justify-content-between align-items-center">
                                                  <span>Stipend per Hour:</span>
                                                  <p className="price">
                                                    <span className="text-success">
                                                      $
                                                    </span>{" "}
                                                    {
                                                      stipendData?.stipend_per_hour
                                                    }{" "}
                                                    USD
                                                  </p>
                                                </li>
                                              ) : (
                                                <li className="d-flex justify-content-between align-items-center">
                                                  <span>Stipend per Hour:</span>
                                                  <p className="price">
                                                    <span className="text-success">
                                                      $
                                                    </span>{" "}
                                                    {(
                                                      parseFloat(
                                                        (stipendData?.stipend_total
                                                          ? stipendData.stipend_total
                                                          : "0"
                                                        ).replace(",", "")
                                                      ) /
                                                      parseFloat(
                                                        (stipendData?.total_numbers_of_hours
                                                          ? stipendData.total_numbers_of_hours
                                                          : "0"
                                                        ).replace(",", "")
                                                      )
                                                    ).toFixed(2)}{" "}
                                                    USD
                                                  </p>
                                                </li>
                                              )
                                            ) : (
                                              <></>
                                            )}
                                          </ul>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Container>
                                </Modal.Body>
                                <Modal.Footer>
                                  <Button
                                    variant="outline-light"
                                    onClick={handleStependHide}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    variant="primary"
                                    onClick={() => {
                                      handleStependAdd();
                                    }}
                                  >
                                    Apply
                                  </Button>
                                </Modal.Footer>
                              </Modal>
                              {radioValue !== `salary` ? (
                                <div className="toggle-switch">
                                  <div className="text pr-4">Exempt</div>
                                  <label className="switch m-0">
                                    <input
                                      type="checkbox"
                                      checked={exampt ? true : false}
                                      onChange={handleToggleExampt}
                                    />
                                    <span className="slider"></span>
                                  </label>
                                </div>
                              ) : null}
                            </div>
                            <Form.Group>
                              <Form.Label className="mb-3">
                                Pay frequency
                              </Form.Label>
                              <ButtonGroup
                                className="w-100 custom-btn-holder"
                                toggle
                              >
                                {radios2.map((radio) => (
                                  <ToggleButton
                                    key={radio.value}
                                    id={`radio-${radio.value}`}
                                    variant="light"
                                    type="radio"
                                    name="radio"
                                    value={radio.value}
                                    checked={radioValueTwo === radio.value}
                                    onChange={(e) =>
                                      setRadioValueTwo(e.currentTarget.value)
                                    }
                                  >
                                    {radio.name}
                                  </ToggleButton>
                                ))}
                              </ButtonGroup>
                            </Form.Group>
                            <div className="button-bottom-holder">
                              <Button
                                variant="primary"
                                onClick={() => nextTab("pay")}
                                block
                              >
                                Continue
                              </Button>
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="bill">
                          <div className="details-holder p-2">
                            <h4>Enter bill</h4>
                            <Form.Group>
                              {/* <Form.Label className="mb-3">
                                Compensation type
                              </Form.Label> */}
                              <ButtonGroup
                                className="w-100 custom-btn-holder"
                                toggle
                              >
                                {radios3.map((radio) => (
                                  <ToggleButton
                                    key={radio.value}
                                    id={`radio-${radio.value}`}
                                    variant="light"
                                    type="radio"
                                    name="radio"
                                    value={radio.value}
                                    checked={radioValueThree === radio.value}
                                    onChange={(e) =>
                                      setRadioValueThree(e.currentTarget.value)
                                    }
                                  >
                                    {radio.name}
                                  </ToggleButton>
                                ))}
                              </ButtonGroup>
                            </Form.Group>
                            {radioValueThree === `bill_rate` ? (
                              <Form.Group>
                                <Form.Label>
                                  Bill rate{" "}
                                  <span className="text-success">$</span>
                                </Form.Label>
                                <InputGroup>
                                  <FormControl
                                    id="bill_rate"
                                    name="bill_rate"
                                    type="number"
                                    value={formInput.bill_rate}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    onChange={handleInput}
                                  />
                                  <InputGroup.Append>
                                    <InputGroup.Text className="pl-0">
                                      $
                                    </InputGroup.Text>
                                  </InputGroup.Append>
                                </InputGroup>
                                {validator.message(
                                  "bill_rate",
                                  formInput.bill_rate,
                                  "required|numeric|min:0,num"
                                )}
                              </Form.Group>
                            ) : (
                              <Form.Group>
                                <Form.Label>
                                  Markup <span className="text-success">%</span>
                                </Form.Label>
                                <InputGroup>
                                  <FormControl
                                    id="markup"
                                    name="markup"
                                    type="number"
                                    value={formInput.markup}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    onChange={handleInput}
                                  />
                                  <InputGroup.Append>
                                    <InputGroup.Text className="pl-0">
                                      %
                                    </InputGroup.Text>
                                  </InputGroup.Append>
                                </InputGroup>
                                {validator.message(
                                  "markup",
                                  formInput.markup,
                                  "required|numeric|min:0,num"
                                )}
                              </Form.Group>
                            )}
                            {exampt ? (
                              ""
                            ) : radioValue === "salary" ? (
                              ""
                            ) : (
                              <div>
                                <Form.Group>
                                  <Form.Label>Overtime Markup</Form.Label>
                                  <InputGroup>
                                    <FormControl
                                      id="overtime"
                                      name="overtime"
                                      type="overtime"
                                      value={formInput.overtime}
                                      onFocus={handleFocus}
                                      onBlur={handleBlur}
                                      onChange={handleInput}
                                    />
                                  </InputGroup>
                                  {validator.message(
                                    "overtime",
                                    formInput.overtime,
                                    "required|numeric|min:0,num|max:100,num"
                                  )}
                                </Form.Group>
                              </div>
                            )}

                            <div className="button-bottom-holder d-flex justify-content-between">
                              <Button
                                className="mr-2"
                                variant="outline-light"
                                block
                                onClick={() => backTab("pay")}
                              >
                                Back
                              </Button>
                              <Button
                                className="ml-2"
                                variant="primary"
                                block
                                onClick={() => nextTab("bill")}
                              >
                                Continue
                              </Button>
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="assignment-details">
                          <div className="details-holder p-2">
                            <h4>Assigment details</h4>
                            <Form.Group>
                              <Form.Label className="mb-3">
                                Have start / end date
                              </Form.Label>
                              <ButtonGroup
                                className="w-100 custom-btn-holder"
                                toggle
                              >
                                {radios4.map((radio) => (
                                  <ToggleButton
                                    key={radio.value}
                                    id={`radio-${radio.value}`}
                                    variant="light"
                                    type="radio"
                                    name="radio"
                                    value={radio.value}
                                    checked={radioValueFour === radio.value}
                                    onChange={(e) =>
                                      setRadioValueFour(e.currentTarget.value)
                                    }
                                  >
                                    {radio.name}
                                  </ToggleButton>
                                ))}
                              </ButtonGroup>
                            </Form.Group>
                            {haveDate}
                            <Form.Group>
                              <Form.Label className="mb-3">
                                Work Schedule
                              </Form.Label>
                              <ButtonGroup
                                className="w-100 custom-btn-holder"
                                toggle
                              >
                                {radios5.map((radio) => (
                                  <ToggleButton
                                    disabled={
                                      radioValue === "salary" &&
                                      radio.value !== "full-time"
                                        ? true
                                        : false
                                    }
                                    key={radio.value}
                                    id={`radio-${radio.value}`}
                                    variant="light"
                                    type="radio"
                                    name="radio"
                                    value={radio.value}
                                    checked={radioValueFive === radio.value}
                                    onChange={(e) =>
                                      setRadioValueFive(e.currentTarget.value)
                                    }
                                  >
                                    {radio.name}
                                  </ToggleButton>
                                ))}
                              </ButtonGroup>
                            </Form.Group>
                            {shadule}
                            <div className="button-bottom-holder d-flex justify-content-between">
                              <Button
                                className="mr-2"
                                variant="outline-light"
                                block
                                onClick={() => backTab("bill")}
                              >
                                Back
                              </Button>
                              <Button
                                className="ml-2"
                                variant="primary"
                                block
                                onClick={() => {
                                  nextTab("assignment-details");
                                }}
                              >
                                Continue
                              </Button>
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="benifits">
                          <div className="details-holder p-2">
                            <h4>Benefits</h4>
                            <div className="benefits-lists">
                              <div className="benefits-list">
                                <div
                                  className="d-flex justify-content-between align-items-center"
                                  onClick={handleToggleBenefits1}
                                >
                                  <div className="title">Holiday Pay</div>
                                  <div className="toggle-switch">
                                    <label className="switch m-0">
                                      <input
                                        type="checkbox"
                                        checked={toggleBenefits1 ? true : false}
                                      />
                                      <span className="slider"></span>
                                    </label>
                                  </div>
                                </div>
                                {toggleBenefits1 && (
                                  <div className="pb-4">
                                    <Row>
                                      <Col lg={8}>
                                        <Form.Group className="mt-2 mb-1">
                                          <Form.Label>
                                            Number of Holiday Days
                                          </Form.Label>
                                          <FormControl
                                            id="paid_time"
                                            name="paid_time"
                                            type="number"
                                            value={formInput.paid_time}
                                            onFocus={handleFocus}
                                            onBlur={handleBlur}
                                            onChange={handleInput}
                                          />
                                          {validator.message(
                                            "paid_time",
                                            formInput.paid_time,
                                            "required|numeric|min:0,num"
                                          )}
                                        </Form.Group>
                                      </Col>
                                      <Col lg={4}>
                                        <Form.Group className="mt-2 mb-1">
                                          <Form.Label>&nbsp;</Form.Label>
                                          <Select
                                            styles={customControlStyles}
                                            options={daysOptions}
                                            name="holiday"
                                            value={daysOptions.find(
                                              ({ value }) =>
                                                value === formInput.holiday
                                            )}
                                            onChange={(e) =>
                                              handleSelect(e, "holiday")
                                            }
                                            placeholder="Per Year"
                                          />
                                        </Form.Group>
                                        {validator.message(
                                          "holiday",
                                          formInput.holiday,
                                          "required"
                                        )}
                                      </Col>
                                    </Row>
                                  </div>
                                )}
                              </div>
                              <div className="benefits-list">
                                <div
                                  className="d-flex justify-content-between align-items-center"
                                  onClick={handleToggleBenefits2}
                                >
                                  <div className="title">PTO/Vacation/Sick</div>
                                  <div className="toggle-switch">
                                    <label className="switch m-0">
                                      <input
                                        type="checkbox"
                                        checked={toggleBenefits2 ? true : false}
                                      />
                                      <span className="slider"></span>
                                    </label>
                                  </div>
                                </div>
                                {toggleBenefits2 && (
                                  <div className="pb-4">
                                    <Row>
                                      <Col lg={8}>
                                        <Form.Group className="mt-2 mb-1">
                                          <Form.Label>
                                            Number of days
                                          </Form.Label>
                                          <FormControl
                                            id="pay_time_number_of_days"
                                            name="pay_time_number_of_days"
                                            type="number"
                                            value={
                                              formInput.pay_time_number_of_days
                                            }
                                            onFocus={handleFocus}
                                            onBlur={handleBlur}
                                            onChange={handleInput}
                                          />
                                          {validator.message(
                                            "pay_time_number_of_days",
                                            formInput.pay_time_number_of_days,
                                            "required|numeric|min:0,num"
                                          )}
                                        </Form.Group>
                                      </Col>
                                      <Col lg={4}>
                                        <Form.Group className="mt-2 mb-1">
                                          <Form.Label>&nbsp;</Form.Label>
                                          <Select
                                            styles={customControlStyles}
                                            options={daysOptions}
                                            name="pto"
                                            value={daysOptions.find(
                                              ({ value }) =>
                                                value === formInput.pto
                                            )}
                                            onChange={(e) =>
                                              handleSelect(e, "pto")
                                            }
                                            placeholder="Per Year"
                                          />
                                        </Form.Group>
                                        {validator.message(
                                          "pto",
                                          formInput.pto,
                                          "required"
                                        )}
                                      </Col>
                                    </Row>
                                  </div>
                                )}
                              </div>
                              <div className="benefits-list">
                                <div
                                  className="d-flex justify-content-between align-items-center"
                                  onClick={handleToggleBenefits3}
                                >
                                  <div className="title">401 k</div>
                                  <div className="toggle-switch">
                                    <label className="switch m-0">
                                      <input
                                        type="checkbox"
                                        checked={toggleBenefits3 ? true : false}
                                      />
                                      <span className="slider"></span>
                                    </label>
                                  </div>
                                </div>
                                {toggleBenefits3 && (
                                  <div className="pb-4">
                                    <Form.Group className="mt-2 mb-1">
                                      <Form.Label>
                                        Employer Contribution
                                      </Form.Label>
                                      <InputGroup>
                                        <FormControl
                                          id="number_of_days"
                                          name="number_of_days"
                                          onChange={handleInput}
                                          value={formInput.number_of_days}
                                          type="number"
                                          onFocus={handleFocus}
                                          onBlur={handleBlur}
                                        />
                                        <InputGroup.Append>
                                          <InputGroup.Text className="pl-0">
                                            %
                                          </InputGroup.Text>
                                        </InputGroup.Append>
                                      </InputGroup>
                                      {validator.message(
                                        "number_of_days",
                                        formInput.number_of_days,
                                        "required|numeric|min:0,num|max:100,num"
                                      )}
                                    </Form.Group>
                                  </div>
                                )}
                              </div>
                              {alwaysAccrue ? (
                                ""
                              ) : (
                                <div className="benefits-list">
                                  <div
                                    className="d-flex justify-content-between align-items-center"
                                    onClick={() =>
                                      setToggleBenefits6(!toggleBenefits6)
                                    }
                                  >
                                    <div className="title">
                                      SPTO (Sick Leave Laws)
                                    </div>
                                    <div className="toggle-switch">
                                      <label className="switch m-0">
                                        <input
                                          type="checkbox"
                                          checked={
                                            toggleBenefits6 ? true : false
                                          }
                                        />
                                        <span className="slider"></span>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              )}
                              <div className="benefits-list">
                                <div
                                  className="d-flex justify-content-between align-items-center"
                                  onClick={handleToggleBenefits4}
                                >
                                  <div className="title">Bonus</div>
                                  <div className="toggle-switch">
                                    <label className="switch m-0">
                                      <input
                                        type="checkbox"
                                        checked={toggleBenefits4 ? true : false}
                                      />
                                      <span className="slider"></span>
                                    </label>
                                  </div>
                                </div>
                                {toggleBenefits4 && (
                                  <div className="pb-4">
                                    <Row>
                                      <Col lg={8}>
                                        <Form.Group className="mt-2 mb-1">
                                          <Form.Label>Pay</Form.Label>
                                          <FormControl
                                            id="pay"
                                            name="pay"
                                            type="number"
                                            onFocus={handleFocus}
                                            onBlur={handleBlur}
                                            onChange={handleInput}
                                            value={formInput.pay}
                                          />
                                        </Form.Group>
                                        {validator.message(
                                          "pay",
                                          formInput.pay,
                                          "required|numeric|min:0,num"
                                        )}
                                      </Col>
                                      <Col lg={4}>
                                        <Form.Group className="mt-2 mb-1">
                                          <Form.Label>&nbsp;</Form.Label>
                                          <Select
                                            styles={customControlStyles}
                                            options={bonusOptions}
                                            name="bonus"
                                            value={bonusOptions.find(
                                              ({ value }) =>
                                                value === formInput.bonus
                                            )}
                                            onChange={(e) =>
                                              handleSelect(e, "bonus")
                                            }
                                            placeholder="%"
                                          />
                                        </Form.Group>
                                        {validator.message(
                                          "bonus",
                                          formInput.bonus,
                                          "required"
                                        )}
                                      </Col>
                                    </Row>
                                  </div>
                                )}
                              </div>
                              <div className="benefits-list">
                                <div
                                  className="d-flex justify-content-between align-items-center"
                                  onClick={handleToggleBenefits5}
                                >
                                  <div className="title">Healthcare plan</div>
                                  <div className="toggle-switch">
                                    <label className="switch m-0">
                                      <input
                                        type="checkbox"
                                        checked={toggleBenefits5 ? true : false}
                                      />
                                      <span className="slider"></span>
                                    </label>
                                  </div>
                                </div>
                                {toggleBenefits5 && (
                                  <div className="pb-4">
                                    {formInput.health_plan.map((plan, idx) => {
                                      return(
                                      <Row>
                                        <Col lg={(idx === 0 ? "6" : "5")}>
                                          <Form.Group className="mt-2 mb-1">
                                            <Select
                                              className="mt-4"
                                              options={packagesOptions}
                                              styles={customControlStyles}
                                              value={packagesOptions.find(
                                                ({ value }) =>
                                                  value === plan.package
                                              )}
                                              name={"package"+idx}
                                              onChange={(e) => {
                                                handlePackageChange(e, idx);
                                              }}
                                            />
                                            {validator.message(
                                              "package",
                                              plan.package,
                                              "required"
                                            )}
                                          </Form.Group>
                                        </Col>
                                        <Col lg={(idx === 0 ? "6" : "5")}>
                                          <Select
                                            className="mt-4"
                                            options={Constants.package_type}
                                            styles={customControlStyles}
                                            value={Constants.package_type.find(
                                              ({ value }) =>
                                                value === plan.package_type
                                            )}
                                            name={"package_type_"+idx}
                                            onChange={(e) => {
                                              handlePackageTypeChange(e, idx);
                                            }}
                                          />
                                          {validator.message(
                                            "package_type",
                                            plan.package_type,
                                            "required"
                                          )}
                                        </Col>
                                        {idx !== 0 ? (
                                          <>
                                            <Col lg={2} className="flex-gap">
                                              
                                              <Button
                                                className="btn-icon remove mt-2"
                                                variant="outline-dark"
                                                onClick={() => handleRemoveHealthPlan(idx)}
                                              >
                                                <Form.Label>Remove</Form.Label>
                                              </Button>
                                            </Col>
                                          </>
                                        ) : null}
                                      </Row>
                                      );
                                    })}
                                    <Row>
                                      <Col lg={12}>
                                        <Button
                                          variant="light"
                                          block
                                          onClick={() => addNewHealthCarePlan()}
                                        >
                                          <BsPlus /> Add Another Healthcare plan
                                        </Button>
                                      </Col>
                                    </Row>
                                    <Button
                                      variant="light mt-3"
                                      block
                                      onClick={handleShowSix}
                                    >
                                      <BsPlus /> Add Packages
                                    </Button>
                                    <Modal
                                      animation={false}
                                      className="add-states-modal"
                                      size="sm"
                                      centered
                                      show={showModalSix}
                                      onHide={handleCloseSix}
                                    >
                                      <Modal.Header
                                        closeButton
                                        className="with-title normal"
                                      >
                                        <Modal.Title>
                                          Add Healthcare Package
                                        </Modal.Title>
                                      </Modal.Header>
                                      <Modal.Body>
                                        <Form.Group>
                                          <Form.Label>Package Name</Form.Label>
                                          <FormControl
                                            id="package_name"
                                            name="package_name"
                                            value={formInput.package_name}
                                            onChange={handleInput}
                                          />
                                          {validator.message(
                                            "package_name",
                                            formInput.package_name,
                                            "required"
                                          )}
                                        </Form.Group>
                                        <Form.Group>
                                          <Form.Label>
                                            Employee Only Monthly Premium
                                          </Form.Label>
                                          <InputGroup>
                                            <InputGroup.Prepend>
                                              <InputGroup.Text>
                                                $
                                              </InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <FormControl
                                              id="employee_only"
                                              name="employee_only"
                                              value={formInput.employee_only}
                                              onChange={handleInput}
                                              onFocus={(e) =>
                                                e.target.parentNode.classList.add(
                                                  "active"
                                                )
                                              }
                                              onBlur={(e) => {
                                                e.target.parentNode.classList.remove(
                                                  "active"
                                                );
                                              }}
                                            />
                                          </InputGroup>
                                          {validator.message(
                                            "employee_only",
                                            formInput.employee_only,
                                            "required|numeric|min:0,num"
                                          )}
                                        </Form.Group>
                                        <Form.Group>
                                          <Form.Label>
                                            Employee + Spouse Monthly Premium
                                          </Form.Label>
                                          <InputGroup>
                                            <InputGroup.Prepend>
                                              <InputGroup.Text>
                                                $
                                              </InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <FormControl
                                              id="employee_spouse"
                                              name="employee_spouse"
                                              value={formInput.employee_spouse}
                                              onChange={handleInput}
                                              onFocus={(e) =>
                                                e.target.parentNode.classList.add(
                                                  "active"
                                                )
                                              }
                                              onBlur={(e) => {
                                                e.target.parentNode.classList.remove(
                                                  "active"
                                                );
                                              }}
                                            />
                                          </InputGroup>
                                          {validator.message(
                                            "employee_spouse",
                                            formInput.employee_spouse,
                                            "required|numeric|min:0,num"
                                          )}
                                        </Form.Group>
                                        <Form.Group>
                                          <Form.Label>
                                            Employee + Dependent Monthly Premium
                                          </Form.Label>
                                          <InputGroup>
                                            <InputGroup.Prepend>
                                              <InputGroup.Text>
                                                $
                                              </InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <FormControl
                                              id="employee_dependent"
                                              name="employee_dependent"
                                              value={
                                                formInput.employee_dependent
                                              }
                                              onChange={handleInput}
                                              onFocus={(e) =>
                                                e.target.parentNode.classList.add(
                                                  "active"
                                                )
                                              }
                                              onBlur={(e) => {
                                                e.target.parentNode.classList.remove(
                                                  "active"
                                                );
                                              }}
                                            />
                                          </InputGroup>
                                          {validator.message(
                                            "employee_dependent",
                                            formInput.employee_dependent,
                                            "required|numeric|min:0,num"
                                          )}
                                        </Form.Group>
                                        <Form.Group>
                                          <Form.Label>
                                            Family Monthly Premium
                                          </Form.Label>
                                          <InputGroup>
                                            <InputGroup.Prepend>
                                              <InputGroup.Text>
                                                $
                                              </InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <FormControl
                                              id="family"
                                              name="family"
                                              value={formInput.family}
                                              onChange={handleInput}
                                              onFocus={(e) =>
                                                e.target.parentNode.classList.add(
                                                  "active"
                                                )
                                              }
                                              onBlur={(e) => {
                                                e.target.parentNode.classList.remove(
                                                  "active"
                                                );
                                              }}
                                            />
                                          </InputGroup>
                                          {validator.message(
                                            "family",
                                            formInput.family,
                                            "required|numeric|min:0,num"
                                          )}
                                        </Form.Group>
                                      </Modal.Body>
                                      <Modal.Footer>
                                        <Button
                                          variant="outline-light"
                                          onClick={handleCloseSix}
                                        >
                                          Cancel
                                        </Button>
                                        <Button
                                          variant="primary"
                                          disabled={disabled}
                                          onClick={(e) =>
                                            handleShow(e, "add_package")
                                          }
                                        >
                                          Add
                                        </Button>
                                      </Modal.Footer>
                                    </Modal>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="button-bottom-holder d-flex justify-content-between">
                              <Button
                                className="mr-2"
                                variant="outline-light"
                                block
                                onClick={() => backTab("assignment-details")}
                              >
                                Back
                              </Button>
                              <Button
                                className="ml-2"
                                variant="primary"
                                block
                                onClick={() => nextTab("benifits")}
                              >
                                Continue
                              </Button>
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="othercost">
                          <div className="details-holder p-2">
                            <h4>Other costs</h4>
                            {/* <button onClick={()=>console.log(organizationSettings)}>Check organization</button> */}
                            <div className="benefits-lists">
                              <div className="benefits-list">
                                <div
                                  className="d-flex justify-content-between align-items-center"
                                  onClick={handleToggleOtherC7}
                                >
                                  <div className="title">Payment terms</div>
                                  <div className="toggle-switch">
                                    <label className="switch m-0">
                                      <input
                                        type="checkbox"
                                        checked={toggleOtherC7 ? true : false}
                                      />
                                      <span className="slider"></span>
                                    </label>
                                  </div>
                                </div>
                                {toggleOtherC7 && (
                                  <div className="pb-4">
                                    <Form.Group className="mt-2 mb-1">
                                      <FormControl
                                        placeholder="No of days"
                                        type="num"
                                        id="othercost_number_of_days"
                                        name="othercost_number_of_days"
                                        value={
                                          formInput.othercost_number_of_days
                                        }
                                        onChange={handleInput}
                                      />
                                      {validator.message(
                                        "othercost_number_of_days",
                                        formInput.othercost_number_of_days,
                                        "required|numeric|min:0,num"
                                      )}
                                    </Form.Group>
                                  </div>
                                )}
                              </div>
                              <div className="benefits-list">
                                <div
                                  className="d-flex justify-content-between align-items-center"
                                  onClick={handleToggleOtherC8}
                                >
                                  <div className="title">Interest Cost</div>
                                  <div className="toggle-switch">
                                    <label className="switch m-0">
                                      <input
                                        type="checkbox"
                                        checked={toggleOtherC8 ? true : false}
                                      />
                                      <span className="slider"></span>
                                    </label>
                                  </div>
                                </div>
                                <div></div>
                                {toggleOtherC8 && (
                                  <div className="pb-4">
                                    {console.log(
                                      organizationSettings.data.costs
                                    )}
                                    {organizationSettings &&
                                    organizationSettings.data &&
                                    organizationSettings.data.costs &&
                                    organizationSettings.data.costs["interest"]
                                      ? organizationSettings.data.costs[
                                          "interest"
                                        ].map((c, idx) => {
                                          return (
                                            <div className="custom-checkbox">
                                              <input
                                                type="checkbox"
                                                checked={
                                                  costData &&
                                                  costData["interest"] &&
                                                  costData["interest"].includes(
                                                    `${c.id}`
                                                  )
                                                    ? true
                                                    : false
                                                }
                                                id={`interest-${c.id}`}
                                                onClick={(e) =>
                                                  handleCheckboxSelection(
                                                    e.target.id
                                                  )
                                                }
                                              />
                                              <label for={`interest-${c.id}`}>
                                                {c.name} <br />
                                                {renderCostDataFormat(c, 1)}
                                              </label>
                                            </div>
                                          );
                                        })
                                      : "No Data"}
                                    {otherCostErrors["interest"].length > 0 ? (
                                      <div className="text-danger">
                                        {otherCostErrors["interest"]}
                                      </div>
                                    ) : null}
                                    {!isTeamMember && (
                                      <Button
                                        variant="light"
                                        className="mt-4"
                                        block
                                        onClick={handleToggleOtherC82}
                                      >
                                        <BsPlus /> Add Interest Cost
                                      </Button>
                                    )}
                                    <Modal
                                      animation={false}
                                      className="add-states-salestax-modal other-cost-modal"
                                      size="lg"
                                      centered
                                      show={toggleOtherC82}
                                      onHide={handleToggleOtherC82}
                                    >
                                      <Modal.Header
                                        closeButton
                                        className="with-title normal"
                                      >
                                        <Modal.Title>
                                          Add new interest cost
                                        </Modal.Title>
                                      </Modal.Header>
                                      <Modal.Body>
                                        <Row className="align-items-start mb-4">
                                          <Col lg={12}>
                                            <Form.Group>
                                              <Form.Label>
                                                Interest Cost name
                                              </Form.Label>
                                              <FormControl
                                                id="interest_name"
                                                name="interest_name"
                                                value={formInput.interest_name}
                                                onChange={handleInput}
                                              />
                                              {validator.message(
                                                "interest_name",
                                                formInput.interest_name,
                                                "required"
                                              )}
                                            </Form.Group>
                                          </Col>
                                        </Row>
                                        <Row className="align-items-start mb-4">
                                          <Col lg={4}>
                                            <Form.Label>Percent</Form.Label>
                                            <InputGroup>
                                              <FormControl
                                                id="interest_percent"
                                                name="interest_percent"
                                                type="number"
                                                value={
                                                  formInput.interest_percent
                                                }
                                                onChange={handleInput}
                                                onFocus={handleFocus}
                                                onBlur={handleBlur}
                                              />
                                              <InputGroup.Append>
                                                <InputGroup.Text className="pl-0">
                                                  %
                                                  {/* {otherCostModalLabel2 === "Cost"
                                                      ? "$"
                                                      : "%"} */}
                                                </InputGroup.Text>
                                              </InputGroup.Append>
                                            </InputGroup>
                                            {validator.message(
                                              "interest_percent",
                                              formInput.interest_percent,
                                              "required|numeric|min:0,num|max:100,num"
                                            )}
                                          </Col>
                                          <Col lg={8}>
                                            <Form.Label>&nbsp;</Form.Label>
                                            <ButtonGroup
                                              className="w-100 custom-btn-holder"
                                              toggle
                                            >
                                              {radios6.map((radio, idx) => (
                                                <ToggleButton
                                                  key={idx}
                                                  type="radio"
                                                  variant="light"
                                                  name="radio"
                                                  value={radio.value}
                                                  checked={
                                                    radio6Value === radio.value
                                                  }
                                                  onChange={(e) =>
                                                    setRadio6Value(
                                                      e.currentTarget.value
                                                    )
                                                  }
                                                >
                                                  {radio.name}
                                                </ToggleButton>
                                              ))}
                                            </ButtonGroup>
                                          </Col>
                                        </Row>
                                      </Modal.Body>
                                      <Modal.Footer>
                                        <Button
                                          variant="outline-light"
                                          onClick={handleToggleOtherC82}
                                        >
                                          Cancel
                                        </Button>
                                        <Button
                                          variant="primary"
                                          disabled={disabled2}
                                          onClick={(e) => {
                                            handleInterestModal(e);
                                          }}
                                        >
                                          OK
                                        </Button>
                                      </Modal.Footer>
                                    </Modal>
                                  </div>
                                )}
                              </div>
                              <div className="benefits-list">
                                <div
                                  className="d-flex justify-content-between align-items-center"
                                  onClick={handleToggleOtherC1}
                                >
                                  <div className="title">Background Check</div>
                                  <div className="toggle-switch">
                                    <label className="switch m-0">
                                      <input
                                        type="checkbox"
                                        checked={toggleOtherC1 ? true : false}
                                      />
                                      <span className="slider"></span>
                                    </label>
                                  </div>
                                </div>
                                <div></div>
                                {toggleOtherC1 && (
                                  <div className="pb-4">
                                    {/* {console.log(organizationSettings.data.costs)} */}
                                    {organizationSettings &&
                                    organizationSettings.data &&
                                    organizationSettings.data.costs &&
                                    organizationSettings.data.costs[
                                      "background_check_cost"
                                    ]
                                      ? organizationSettings.data.costs[
                                          "background_check_cost"
                                        ].map((c, idx) => {
                                          return (
                                            <div className="custom-checkbox">
                                              <input
                                                type="checkbox"
                                                checked={
                                                  costData &&
                                                  costData[
                                                    "background_check_cost"
                                                  ] &&
                                                  costData[
                                                    "background_check_cost"
                                                  ].includes(`${c.id}`)
                                                    ? true
                                                    : false
                                                }
                                                id={`background_check_cost-${c.id}`}
                                                onClick={(e) =>
                                                  handleCheckboxSelection(
                                                    e.target.id
                                                  )
                                                }
                                              />
                                              {console.log(
                                                "costData",
                                                organizationSettings.data.costs
                                              )}
                                              <label
                                                for={`background_check_cost-${c.id}`}
                                              >
                                                {c.name} <br />
                                                {renderCostDataFormat(c)}
                                              </label>
                                            </div>
                                          );
                                        })
                                      : "No Data"}
                                    {otherCostErrors["background_check_cost"]
                                      .length > 0 ? (
                                      <div className="text-danger">
                                        {
                                          otherCostErrors[
                                            "background_check_cost"
                                          ]
                                        }
                                      </div>
                                    ) : null}
                                    {!isTeamMember && (
                                      <Button
                                        variant="light"
                                        className="mt-4"
                                        block
                                        onClick={handleToggleOtherC12}
                                      >
                                        <BsPlus /> Add background check
                                      </Button>
                                    )}
                                    <Modal
                                      animation={false}
                                      className="add-states-salestax-modal other-cost-modal"
                                      size="lg"
                                      centered
                                      show={toggleOtherC12}
                                      onHide={handleToggleOtherC12}
                                    >
                                      <Modal.Header
                                        closeButton
                                        className="with-title normal"
                                      >
                                        <Modal.Title>
                                          Background Check
                                        </Modal.Title>
                                      </Modal.Header>
                                      <Modal.Body>
                                        <Row className="align-items-start mb-4">
                                          <Col lg={12}>
                                            <Form.Group>
                                              <Form.Label>BC Name</Form.Label>
                                              <FormControl
                                                id="bc_name"
                                                name="bc_name"
                                                value={formInput.bc_name}
                                                onChange={handleInput}
                                              />
                                              {validator.message(
                                                "bc_name",
                                                formInput.bc_name,
                                                "required"
                                              )}
                                            </Form.Group>
                                          </Col>
                                        </Row>
                                        <Row className="align-items-start mb-4">
                                          <Col lg={4}>
                                            <Form.Label>Cost</Form.Label>
                                            <InputGroup>
                                              <FormControl
                                                id="bc_cost"
                                                name="bc_cost"
                                                type="number"
                                                value={formInput.bc_cost}
                                                onChange={handleInput}
                                                onFocus={handleFocus}
                                                onBlur={handleBlur}
                                              />
                                              <InputGroup.Append>
                                                <InputGroup.Text className="pl-0">
                                                  $
                                                  {/* {otherCostModalLabel2 === "Cost"
                                                      ? "$"
                                                      : "%"} */}
                                                </InputGroup.Text>
                                              </InputGroup.Append>
                                            </InputGroup>
                                            {validator.message(
                                              "bc_cost",
                                              formInput.bc_cost,
                                              "required|numeric|min:0,num"
                                            )}
                                          </Col>
                                        </Row>
                                      </Modal.Body>
                                      <Modal.Footer>
                                        <Button
                                          variant="outline-light"
                                          onClick={handleToggleOtherC12}
                                        >
                                          Cancel
                                        </Button>
                                        <Button
                                          variant="primary"
                                          disabled={disabled2}
                                          onClick={(e) => {
                                            handleModalRequest(e);
                                          }}
                                        >
                                          OK
                                        </Button>
                                      </Modal.Footer>
                                    </Modal>
                                  </div>
                                )}
                              </div>
                              <div className="benefits-list">
                                <div
                                  className="d-flex justify-content-between align-items-center"
                                  onClick={handleToggleOtherC2}
                                >
                                  <div className="title">Drug screen cost</div>
                                  <div className="toggle-switch">
                                    <label className="switch m-0">
                                      <input
                                        type="checkbox"
                                        checked={toggleOtherC2 ? true : false}
                                      />
                                      <span className="slider"></span>
                                    </label>
                                  </div>
                                </div>
                                {toggleOtherC2 && (
                                  <div className="pb-4">
                                    {organizationSettings &&
                                    organizationSettings.data &&
                                    organizationSettings.data.costs &&
                                    organizationSettings.data.costs[
                                      "drug_screen_cost"
                                    ]
                                      ? organizationSettings.data.costs[
                                          "drug_screen_cost"
                                        ].map((c, idx) => {
                                          return (
                                            <div className="custom-checkbox">
                                              <input
                                                type="checkbox"
                                                id={`drug_screen_cost-${c.id}`}
                                                checked={
                                                  costData &&
                                                  costData[
                                                    "drug_screen_cost"
                                                  ] &&
                                                  costData[
                                                    "drug_screen_cost"
                                                  ].includes(`${c.id}`)
                                                    ? true
                                                    : false
                                                }
                                                onClick={(e) =>
                                                  handleCheckboxSelection(
                                                    e.target.id
                                                  )
                                                }
                                              />
                                              <label
                                                for={`drug_screen_cost-${c.id}`}
                                              >
                                                {c.name} <br />
                                                {renderCostDataFormat(c)}
                                              </label>
                                            </div>
                                          );
                                        })
                                      : "No Data"}
                                    {otherCostErrors["drug_screen_cost"]
                                      .length > 0 ? (
                                      <div className="text-danger">
                                        {otherCostErrors["drug_screen_cost"]}
                                      </div>
                                    ) : null}
                                    {!isTeamMember && (
                                      <Button
                                        variant="light"
                                        className="mt-4"
                                        block
                                        onClick={handleToggleOtherC22}
                                      >
                                        <BsPlus /> Add new drug screen cost
                                      </Button>
                                    )}
                                    <Modal
                                      animation={false}
                                      className="add-states-salestax-modal other-cost-modal"
                                      size="lg"
                                      centered
                                      show={toggleOtherC22}
                                      onHide={handleToggleOtherC22}
                                    >
                                      <Modal.Header
                                        closeButton
                                        className="with-title normal"
                                      >
                                        <Modal.Title>
                                          Drug Screen Cost
                                        </Modal.Title>
                                      </Modal.Header>
                                      <Modal.Body>
                                        <Row className="align-items-start mb-4">
                                          <Col lg={12}>
                                            <Form.Group>
                                              <Form.Label>
                                                Drug Screen Name
                                              </Form.Label>
                                              <FormControl
                                                id="drug_screen_name"
                                                name="drug_screen_name"
                                                value={
                                                  formInput.drug_screen_name
                                                }
                                                onChange={handleInput}
                                              />
                                              {validator.message(
                                                "drug_screen_name",
                                                formInput.drug_screen_name,
                                                "required"
                                              )}
                                            </Form.Group>
                                          </Col>
                                        </Row>
                                        <Row className="align-items-start mb-4">
                                          <Col lg={4}>
                                            <Form.Label>Cost</Form.Label>
                                            <InputGroup>
                                              <FormControl
                                                id="drug_screen_cost"
                                                name="drug_screen_cost"
                                                type="number"
                                                value={
                                                  formInput.drug_screen_cost
                                                }
                                                onChange={handleInput}
                                                onFocus={handleFocus}
                                                onBlur={handleBlur}
                                              />
                                              <InputGroup.Append>
                                                <InputGroup.Text className="pl-0">
                                                  $
                                                  {/* {otherCostModalLabel2 === "Cost"
                                                      ? "$"
                                                      : "%"} */}
                                                </InputGroup.Text>
                                              </InputGroup.Append>
                                            </InputGroup>
                                            {validator.message(
                                              "drug_screen_cost",
                                              formInput.drug_screen_cost,
                                              "required|numeric|min:0,num"
                                            )}
                                          </Col>
                                        </Row>
                                      </Modal.Body>
                                      <Modal.Footer>
                                        <Button
                                          variant="outline-light"
                                          onClick={handleToggleOtherC22}
                                        >
                                          Cancel
                                        </Button>
                                        <Button
                                          variant="primary"
                                          disabled={disabled2}
                                          onClick={(e) => {
                                            handleDrugScreenCostModal(e);
                                          }}
                                        >
                                          OK
                                        </Button>
                                      </Modal.Footer>
                                    </Modal>
                                  </div>
                                )}
                              </div>
                              <div className="benefits-list">
                                <div
                                  className="d-flex justify-content-between align-items-center"
                                  onClick={handleToggleOtherC3}
                                >
                                  <div className="title">
                                    Managed Service Provider (MSP)
                                  </div>
                                  <div className="toggle-switch">
                                    <label className="switch m-0">
                                      <input
                                        type="checkbox"
                                        checked={toggleOtherC3 ? true : false}
                                      />
                                      <span className="slider"></span>
                                    </label>
                                  </div>
                                </div>
                                {toggleOtherC3 && (
                                  <div className="pb-4">
                                    {organizationSettings &&
                                    organizationSettings.data &&
                                    organizationSettings.data.costs &&
                                    organizationSettings.data.costs[
                                      "manage_service_provider"
                                    ]
                                      ? organizationSettings.data.costs[
                                          "manage_service_provider"
                                        ].map((c, idx) => {
                                          return (
                                            <div className="custom-checkbox">
                                              <input
                                                type="checkbox"
                                                id={`manage_service_provider-${c.id}`}
                                                checked={
                                                  costData &&
                                                  costData[
                                                    "manage_service_provider"
                                                  ] &&
                                                  costData[
                                                    "manage_service_provider"
                                                  ].includes(`${c.id}`)
                                                    ? true
                                                    : false
                                                }
                                                onClick={(e) =>
                                                  handleCheckboxSelection(
                                                    e.target.id
                                                  )
                                                }
                                              />
                                              <label
                                                for={`manage_service_provider-${c.id}`}
                                              >
                                                {c.name} <br />
                                                {renderCostDataFormat(c, 1)}
                                              </label>
                                            </div>
                                          );
                                        })
                                      : "No Data"}
                                    {otherCostErrors["manage_service_provider"]
                                      .length > 0 ? (
                                      <div className="text-danger">
                                        {
                                          otherCostErrors[
                                            "manage_service_provider"
                                          ]
                                        }
                                      </div>
                                    ) : null}
                                    {!isTeamMember && (
                                      <Button
                                        variant="light"
                                        className="mt-4"
                                        block
                                        onClick={handleToggleOtherC32}
                                      >
                                        <BsPlus /> Add new manage service
                                        provider
                                      </Button>
                                    )}
                                    <Modal
                                      animation={false}
                                      className="add-states-salestax-modal other-cost-modal"
                                      size="lg"
                                      centered
                                      show={toggleOtherC32}
                                      onHide={handleToggleOtherC32}
                                    >
                                      <Modal.Header
                                        closeButton
                                        className="with-title normal"
                                      >
                                        <Modal.Title>
                                          Add Managed Service Provider
                                        </Modal.Title>
                                      </Modal.Header>
                                      <Modal.Body>
                                        <Row className="align-items-start mb-4">
                                          <Col lg={12}>
                                            <Form.Group>
                                              <Form.Label>
                                                Managed Service Provider name
                                              </Form.Label>
                                              <FormControl
                                                id="msp_name"
                                                name="msp_name"
                                                value={formInput.msp_name}
                                                onChange={handleInput}
                                              />
                                              {validator.message(
                                                "msp_name",
                                                formInput.msp_name,
                                                "required"
                                              )}
                                            </Form.Group>
                                          </Col>
                                        </Row>
                                        <Row className="align-items-start mb-4">
                                          <Col lg={4}>
                                            <Form.Label>Percent</Form.Label>
                                            <InputGroup>
                                              <FormControl
                                                id="msp_percent"
                                                name="msp_percent"
                                                type="number"
                                                value={formInput.msp_percent}
                                                onChange={handleInput}
                                                onFocus={handleFocus}
                                                onBlur={handleBlur}
                                              />
                                              <InputGroup.Append>
                                                <InputGroup.Text className="pl-0">
                                                  %
                                                  {/* {otherCostModalLabel2 === "Cost"
                                                      ? "$"
                                                      : "%"} */}
                                                </InputGroup.Text>
                                              </InputGroup.Append>
                                            </InputGroup>
                                            {validator.message(
                                              "msp_percent",
                                              formInput.msp_percent,
                                              "required|numeric|min:0,num|max:100,num"
                                            )}
                                          </Col>
                                          <Col lg={8}>
                                            <Form.Label>&nbsp;</Form.Label>
                                            <ButtonGroup
                                              className="w-100 custom-btn-holder"
                                              toggle
                                            >
                                              {radios6.map((radio, idx) => (
                                                <ToggleButton
                                                  key={idx}
                                                  type="radio"
                                                  variant="light"
                                                  name="radio"
                                                  value={radio.value}
                                                  checked={
                                                    radio6Value === radio.value
                                                  }
                                                  onChange={(e) =>
                                                    setRadio6Value(
                                                      e.currentTarget.value
                                                    )
                                                  }
                                                >
                                                  {radio.name}
                                                </ToggleButton>
                                              ))}
                                            </ButtonGroup>
                                          </Col>
                                        </Row>
                                      </Modal.Body>
                                      <Modal.Footer>
                                        <Button
                                          variant="outline-light"
                                          onClick={handleToggleOtherC32}
                                        >
                                          Cancel
                                        </Button>
                                        <Button
                                          variant="primary"
                                          disabled={disabled2}
                                          onClick={(e) => {
                                            handleMspModal(e);
                                          }}
                                        >
                                          OK
                                        </Button>
                                      </Modal.Footer>
                                    </Modal>
                                  </div>
                                )}
                              </div>
                              <div className="benefits-list">
                                <div
                                  className="d-flex justify-content-between align-items-center"
                                  onClick={handleToggleOtherC4}
                                >
                                  <div className="title">
                                    Vendor management system
                                  </div>
                                  <div className="toggle-switch">
                                    <label className="switch m-0">
                                      <input
                                        type="checkbox"
                                        checked={toggleOtherC4 ? true : false}
                                      />
                                      <span className="slider"></span>
                                    </label>
                                  </div>
                                </div>
                                {toggleOtherC4 && (
                                  <div className="pb-4">
                                    {organizationSettings &&
                                    organizationSettings.data &&
                                    organizationSettings.data.costs &&
                                    organizationSettings.data.costs[
                                      "vendor_management_system"
                                    ]
                                      ? organizationSettings.data.costs[
                                          "vendor_management_system"
                                        ].map((c, idx) => {
                                          return (
                                            <div className="custom-checkbox">
                                              <input
                                                type="checkbox"
                                                id={`vendor_management_system-${c.id}`}
                                                checked={
                                                  costData &&
                                                  costData[
                                                    "vendor_management_system"
                                                  ] &&
                                                  costData[
                                                    "vendor_management_system"
                                                  ].includes(`${c.id}`)
                                                    ? true
                                                    : false
                                                }
                                                onClick={(e) =>
                                                  handleCheckboxSelection(
                                                    e.target.id
                                                  )
                                                }
                                              />
                                              <label
                                                for={`vendor_management_system-${c.id}`}
                                              >
                                                {c.name} <br />
                                                {renderCostDataFormat(c, 1)}
                                              </label>
                                            </div>
                                          );
                                        })
                                      : "No Data"}
                                    {otherCostErrors["vendor_management_system"]
                                      .length > 0 ? (
                                      <div className="text-danger">
                                        {
                                          otherCostErrors[
                                            "vendor_management_system"
                                          ]
                                        }
                                      </div>
                                    ) : null}
                                    {!isTeamMember && (
                                      <Button
                                        variant="light"
                                        className="mt-4"
                                        block
                                        onClick={handleToggleOtherC42}
                                      >
                                        <BsPlus /> Add new vendor management
                                        system
                                      </Button>
                                    )}
                                    <Modal
                                      animation={false}
                                      className="add-states-salestax-modal other-cost-modal"
                                      size="lg"
                                      centered
                                      show={toggleOtherC42}
                                      onHide={handleToggleOtherC42}
                                    >
                                      <Modal.Header
                                        closeButton
                                        className="with-title normal"
                                      >
                                        <Modal.Title>
                                          Add Vendor Management System
                                        </Modal.Title>
                                      </Modal.Header>
                                      <Modal.Body>
                                        <Row className="align-items-start mb-4">
                                          <Col lg={12}>
                                            <Form.Group>
                                              <Form.Label>VMS Name</Form.Label>
                                              <FormControl
                                                id="vms_name"
                                                name="vms_name"
                                                value={formInput.vms_name}
                                                onChange={handleInput}
                                              />
                                              {validator.message(
                                                "vms_name",
                                                formInput.vms_name,
                                                "required"
                                              )}
                                            </Form.Group>
                                          </Col>
                                        </Row>
                                        <Row className="align-items-start mb-4">
                                          <Col lg={4}>
                                            <Form.Label>Percent</Form.Label>
                                            <InputGroup>
                                              <FormControl
                                                id="vms_percent"
                                                name="vms_percent"
                                                type="number"
                                                value={formInput.vms_percent}
                                                onChange={handleInput}
                                                onFocus={handleFocus}
                                                onBlur={handleBlur}
                                              />
                                              <InputGroup.Append>
                                                <InputGroup.Text className="pl-0">
                                                  %
                                                  {/* {otherCostModalLabel2 === "Cost"
                                                      ? "$"
                                                      : "%"} */}
                                                </InputGroup.Text>
                                              </InputGroup.Append>
                                            </InputGroup>
                                            {validator.message(
                                              "vms_percent",
                                              formInput.vms_percent,
                                              "required|numeric|min:0,num|max:100,num"
                                            )}
                                          </Col>
                                          <Col lg={8}>
                                            <Form.Label>&nbsp;</Form.Label>
                                            <ButtonGroup
                                              className="w-100 custom-btn-holder"
                                              toggle
                                            >
                                              {radios6.map((radio, idx) => (
                                                <ToggleButton
                                                  key={idx}
                                                  type="radio"
                                                  variant="light"
                                                  name="radio"
                                                  value={radio.value}
                                                  checked={
                                                    radio6Value === radio.value
                                                  }
                                                  onChange={(e) =>
                                                    setRadio6Value(
                                                      e.currentTarget.value
                                                    )
                                                  }
                                                >
                                                  {radio.name}
                                                </ToggleButton>
                                              ))}
                                            </ButtonGroup>
                                          </Col>
                                        </Row>
                                      </Modal.Body>
                                      <Modal.Footer>
                                        <Button
                                          variant="outline-light"
                                          onClick={handleToggleOtherC42}
                                        >
                                          Cancel
                                        </Button>
                                        <Button
                                          variant="primary"
                                          disabled={disabled2}
                                          onClick={(e) => {
                                            handleVmsModal(e);
                                          }}
                                        >
                                          OK
                                        </Button>
                                      </Modal.Footer>
                                    </Modal>
                                  </div>
                                )}
                              </div>
                              <div className="benefits-list">
                                <div
                                  className="d-flex justify-content-between align-items-center"
                                  onClick={handleToggleOtherC5}
                                >
                                  <div className="title">Payrolling cost</div>
                                  <div className="toggle-switch">
                                    <label className="switch m-0">
                                      <input
                                        type="checkbox"
                                        checked={toggleOtherC5 ? true : false}
                                      />
                                      <span className="slider"></span>
                                    </label>
                                  </div>
                                </div>
                                {toggleOtherC5 && (
                                  <div className="pb-4">
                                    {organizationSettings &&
                                    organizationSettings.data &&
                                    organizationSettings.data.costs &&
                                    organizationSettings.data.costs[
                                      "payrolling_cost"
                                    ]
                                      ? organizationSettings.data.costs[
                                          "payrolling_cost"
                                        ].map((c, idx) => {
                                          return (
                                            <div className="custom-checkbox">
                                              <input
                                                type="checkbox"
                                                id={`payrolling_cost-${c.id}`}
                                                checked={
                                                  costData &&
                                                  costData["payrolling_cost"] &&
                                                  costData[
                                                    "payrolling_cost"
                                                  ].includes(`${c.id}`)
                                                    ? true
                                                    : false
                                                }
                                                onClick={(e) =>
                                                  handleCheckboxSelection(
                                                    e.target.id
                                                  )
                                                }
                                              />
                                              <label
                                                for={`payrolling_cost-${c.id}`}
                                              >
                                                {c.name} <br />
                                                {renderCostDataFormat(c, 1)}
                                              </label>
                                            </div>
                                          );
                                        })
                                      : "No Data"}
                                    {otherCostErrors["payrolling_cost"].length >
                                    0 ? (
                                      <div className="text-danger">
                                        {otherCostErrors["payrolling_cost"]}
                                      </div>
                                    ) : null}
                                    {!isTeamMember && (
                                      <Button
                                        variant="light"
                                        className="mt-4"
                                        block
                                        onClick={handleToggleOtherC52}
                                      >
                                        <BsPlus /> Add new payrolling cost
                                      </Button>
                                    )}
                                    <Modal
                                      animation={false}
                                      className="add-states-salestax-modal other-cost-modal"
                                      size="lg"
                                      centered
                                      show={toggleOtherC52}
                                      onHide={handleToggleOtherC52}
                                    >
                                      <Modal.Header
                                        closeButton
                                        className="with-title normal"
                                      >
                                        <Modal.Title>
                                          Add new payrolling cost
                                        </Modal.Title>
                                      </Modal.Header>
                                      <Modal.Body>
                                        <Row className="align-items-start mb-4">
                                          <Col lg={12}>
                                            <Form.Group>
                                              <Form.Label>
                                                Payrolling Cost name
                                              </Form.Label>
                                              <FormControl
                                                id="payrolling_name"
                                                name="payrolling_name"
                                                value={
                                                  formInput.payrolling_name
                                                }
                                                onChange={handleInput}
                                              />
                                              {validator.message(
                                                "payrolling_name",
                                                formInput.payrolling_name,
                                                "required"
                                              )}
                                            </Form.Group>
                                          </Col>
                                        </Row>
                                        <Row className="align-items-start mb-4">
                                          <Col lg={4}>
                                            <Form.Label>Percent</Form.Label>
                                            <InputGroup>
                                              <FormControl
                                                id="payrolling_percent"
                                                name="payrolling_percent"
                                                type="number"
                                                value={
                                                  formInput.payrolling_percent
                                                }
                                                onChange={handleInput}
                                                onFocus={handleFocus}
                                                onBlur={handleBlur}
                                              />
                                              <InputGroup.Append>
                                                <InputGroup.Text className="pl-0">
                                                  %
                                                  {/* {otherCostModalLabel2 === "Cost"
                                                      ? "$"
                                                      : "%"} */}
                                                </InputGroup.Text>
                                              </InputGroup.Append>
                                            </InputGroup>
                                            {validator.message(
                                              "payrolling_percent",
                                              formInput.payrolling_percent,
                                              "required|numeric|min:0,num|max:100,num"
                                            )}
                                          </Col>
                                          <Col lg={8}>
                                            <Form.Label>&nbsp;</Form.Label>
                                            <ButtonGroup
                                              className="w-100 custom-btn-holder"
                                              toggle
                                            >
                                              {radios6.map((radio, idx) => (
                                                <ToggleButton
                                                  key={idx}
                                                  type="radio"
                                                  variant="light"
                                                  name="radio"
                                                  value={radio.value}
                                                  checked={
                                                    radio6Value === radio.value
                                                  }
                                                  onChange={(e) =>
                                                    setRadio6Value(
                                                      e.currentTarget.value
                                                    )
                                                  }
                                                >
                                                  {radio.name}
                                                </ToggleButton>
                                              ))}
                                            </ButtonGroup>
                                          </Col>
                                        </Row>
                                      </Modal.Body>
                                      <Modal.Footer>
                                        <Button
                                          variant="outline-light"
                                          onClick={handleToggleOtherC52}
                                        >
                                          Cancel
                                        </Button>
                                        <Button
                                          variant="primary"
                                          disabled={disabled2}
                                          onClick={(e) => {
                                            handlePayrollingModal(e);
                                          }}
                                        >
                                          OK
                                        </Button>
                                      </Modal.Footer>
                                    </Modal>
                                  </div>
                                )}
                              </div>
                              <div className="benefits-list">
                                <div
                                  className="d-flex justify-content-between align-items-center"
                                  onClick={handleToggleOtherC9}
                                >
                                  <div className="title">
                                    Liability Insurance Cost
                                  </div>
                                  <div className="toggle-switch">
                                    <label className="switch m-0">
                                      <input
                                        type="checkbox"
                                        checked={toggleOtherC9 ? true : false}
                                      />
                                      <span className="slider"></span>
                                    </label>
                                  </div>
                                </div>
                                <div></div>
                                {toggleOtherC9 && (
                                  <div className="pb-4">
                                    {organizationSettings &&
                                    organizationSettings.data &&
                                    organizationSettings.data.costs &&
                                    organizationSettings.data.costs[
                                      "liability_insurance"
                                    ]
                                      ? organizationSettings.data.costs[
                                          "liability_insurance"
                                        ].map((c, idx) => {
                                          return (
                                            <>
                                              {allIndustries.includes(
                                                c.industry_id
                                              ) || c.industry_id == 1 ? (
                                                <div className="custom-checkbox">
                                                  <input
                                                    type="checkbox"
                                                    checked={
                                                      costData &&
                                                      costData[
                                                        "liability_insurance"
                                                      ] &&
                                                      costData[
                                                        "liability_insurance"
                                                      ].includes(`${c.id}`)
                                                        ? true
                                                        : false
                                                    }
                                                    id={`liability_insurance-${c.id}`}
                                                    onClick={(e) =>
                                                      handleCheckboxSelection(
                                                        e.target.id
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    for={`liability_insurance-${c.id}`}
                                                  >
                                                    {c.name} <br />
                                                    {renderCostDataFormat(c, 1)}
                                                  </label>
                                                </div>
                                              ) : null}
                                            </>
                                          );
                                        })
                                      : "No Data"}
                                    {otherCostErrors["liability_insurance"]
                                      .length > 0 ? (
                                      <div className="text-danger">
                                        {otherCostErrors["liability_insurance"]}
                                      </div>
                                    ) : null}
                                    {!isTeamMember && (
                                      <Button
                                        variant="light"
                                        className="mt-4"
                                        block
                                        onClick={handleToggleOtherC92}
                                      >
                                        <BsPlus /> Add liability insurance Cost
                                      </Button>
                                    )}
                                    <Modal
                                      animation={false}
                                      className="add-states-salestax-modal other-cost-modal"
                                      size="lg"
                                      centered
                                      show={toggleOtherC92}
                                      onHide={handleToggleOtherC92}
                                    >
                                      <Modal.Header
                                        closeButton
                                        className="with-title normal"
                                      >
                                        <Modal.Title>
                                          Add new liability insurance cost
                                        </Modal.Title>
                                      </Modal.Header>
                                      <Modal.Body>
                                        <Row className="align-items-start">
                                          <Col lg={12}>
                                            <Form.Group>
                                              <Form.Label>
                                                Liability Insurance Cost name
                                              </Form.Label>
                                              <FormControl
                                                id="liability_insurance_name"
                                                name="liability_insurance_name"
                                                value={
                                                  formInput.liability_insurance_name
                                                }
                                                onChange={handleInput}
                                              />
                                              {validator.message(
                                                "liability_insurance_name",
                                                formInput.liability_insurance_name,
                                                "required"
                                              )}
                                            </Form.Group>
                                          </Col>
                                        </Row>
                                        <Row className="mb-4">
                                          <Col lg={4}>
                                            <Form.Label>Industry</Form.Label>
                                            <Select
                                              styles={customControlStyles}
                                              className="no-margin"
                                              placeholder="Select"
                                              options={industriesOptions}
                                              value={industryValue}
                                              name="industry"
                                              onChange={(value) => {
                                                setIndustryValue(value);
                                              }}
                                            />
                                            {validator.message(
                                              "industry",
                                              industryValue,
                                              "required"
                                            )}
                                          </Col>
                                        </Row>
                                        <Row className="align-items-start mb-4">
                                          <Col lg={4}>
                                            <Form.Label>Percent</Form.Label>
                                            <InputGroup>
                                              <FormControl
                                                id="liability_insurance_percent"
                                                name="liability_insurance_percent"
                                                type="number"
                                                value={
                                                  formInput.liability_insurance_percent
                                                }
                                                onChange={handleInput}
                                                onFocus={handleFocus}
                                                onBlur={handleBlur}
                                              />
                                              <InputGroup.Append>
                                                <InputGroup.Text className="pl-0">
                                                  %
                                                  {/* {otherCostModalLabel2 === "Cost"
                                                      ? "$"
                                                      : "%"} */}
                                                </InputGroup.Text>
                                              </InputGroup.Append>
                                            </InputGroup>
                                            {validator.message(
                                              "liability_insurance_percent",
                                              formInput.liability_insurance_percent,
                                              "required|numeric|min:0,num|max:100,num"
                                            )}
                                          </Col>
                                          <Col lg={8}>
                                            <Form.Label>&nbsp;</Form.Label>
                                            <ButtonGroup
                                              className="w-100 custom-btn-holder"
                                              toggle
                                            >
                                              {radios6.map((radio, idx) => (
                                                <ToggleButton
                                                  key={idx}
                                                  type="radio"
                                                  variant="light"
                                                  name="radio"
                                                  value={radio.value}
                                                  checked={
                                                    radio6Value === radio.value
                                                  }
                                                  onChange={(e) =>
                                                    setRadio6Value(
                                                      e.currentTarget.value
                                                    )
                                                  }
                                                >
                                                  {radio.name}
                                                </ToggleButton>
                                              ))}
                                            </ButtonGroup>
                                          </Col>
                                        </Row>
                                      </Modal.Body>
                                      <Modal.Footer>
                                        <Button
                                          variant="outline-light"
                                          onClick={handleToggleOtherC92}
                                        >
                                          Cancel
                                        </Button>
                                        <Button
                                          variant="primary"
                                          disabled={disabled2}
                                          onClick={(e) => {
                                            handleLiabilityModal(e);
                                          }}
                                        >
                                          OK
                                        </Button>
                                      </Modal.Footer>
                                    </Modal>
                                  </div>
                                )}
                              </div>
                              {customCostTypes
                                ? Object.keys(customCostTypes).map(
                                    (type, idx) => {
                                      return (
                                        <div className="benefits-list">
                                          <div
                                            className="d-flex justify-content-between align-items-center"
                                            onClick={() =>
                                              handleToggleOtherC6(type)
                                            }
                                          >
                                            <div className="title">
                                              {formatCustomName(type)}
                                            </div>
                                            <div className="toggle-switch">
                                              <label className="switch m-0">
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    toggleOtherC6[type] === true
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                <span className="slider"></span>
                                              </label>
                                            </div>
                                          </div>
                                          {toggleOtherC6[type] === true && (
                                            <div className="pb-4">
                                              {organizationSettings &&
                                              organizationSettings.data &&
                                              organizationSettings.data.costs &&
                                              organizationSettings.data.costs[
                                                type
                                              ]
                                                ? organizationSettings.data.costs[
                                                    type
                                                  ].map((c, idx) => {
                                                    return (
                                                      <>
                                                        {allIndustries &&
                                                        (allIndustries.includes(
                                                          c.industry_id
                                                        ) ||
                                                          c.industry_id ==
                                                            1) ? (
                                                          <div className="custom-checkbox">
                                                            <input
                                                              type="checkbox"
                                                              checked={
                                                                costData &&
                                                                costData[
                                                                  type
                                                                ] &&
                                                                costData[
                                                                  type
                                                                ].includes(
                                                                  `${c.id}`
                                                                )
                                                                  ? true
                                                                  : false
                                                              }
                                                              id={`${type}-${c.id}`}
                                                              onClick={(e) =>
                                                                handleCheckboxSelection(
                                                                  e.target.id
                                                                )
                                                              }
                                                            />
                                                            <label
                                                              for={`${type}-${c.id}`}
                                                            >
                                                              {c.name} <br />
                                                              {renderCostDataFormat(
                                                                c,
                                                                c.cost_option ===
                                                                  `flat`
                                                                  ? 0
                                                                  : 1
                                                              )}
                                                            </label>
                                                          </div>
                                                        ) : null}
                                                      </>
                                                    );
                                                  })
                                                : "No Data"}
                                              {otherCostErrors[type].length >
                                              0 ? (
                                                <div className="text-danger">
                                                  {otherCostErrors[type]}
                                                </div>
                                              ) : null}
                                              {!isTeamMember && (
                                                <Button
                                                  variant="light"
                                                  className="mt-4"
                                                  block
                                                  onClick={() =>
                                                    handleToggleOtherC62(type)
                                                  }
                                                >
                                                  <BsPlus /> Add new{" "}
                                                  {formatCustomName(type)} cost
                                                </Button>
                                              )}
                                              <Modal
                                                animation={false}
                                                className="add-states-salestax-modal other-cost-modal"
                                                size="lg"
                                                centered
                                                show={toggleOtherC62[type]}
                                                onHide={() =>
                                                  handleToggleOtherC62(type)
                                                }
                                              >
                                                <Modal.Header
                                                  closeButton
                                                  className="with-title normal"
                                                >
                                                  <Modal.Title>
                                                    Add new{" "}
                                                    {formatCustomName(type)}{" "}
                                                    cost
                                                  </Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                  <Row className="align-items-start mb-4">
                                                    <Col lg={12}>
                                                      <Form.Group>
                                                        <Form.Label>
                                                          Custom name
                                                        </Form.Label>
                                                        <FormControl
                                                          id="custom_name"
                                                          name="custom_name"
                                                          value={
                                                            formInput.custom_name
                                                          }
                                                          onChange={handleInput}
                                                        />
                                                        {validator.message(
                                                          "custom_name",
                                                          formInput.custom_name,
                                                          "required"
                                                        )}
                                                      </Form.Group>
                                                    </Col>
                                                  </Row>
                                                  <Row>
                                                    <Col lg={4}>
                                                      <Form.Label>
                                                        Industry
                                                      </Form.Label>
                                                      <Select
                                                        styles={
                                                          customControlStyles
                                                        }
                                                        className="no-margin"
                                                        placeholder="Select"
                                                        options={
                                                          industriesOptions
                                                        }
                                                        value={industryValue}
                                                        name="industry"
                                                        onChange={(value) => {
                                                          setIndustryValue(
                                                            value
                                                          );
                                                        }}
                                                      />
                                                    </Col>
                                                    <Col lg={8}>
                                                      <Form.Label className="mb-2">
                                                        Type
                                                      </Form.Label>
                                                      <ButtonGroup
                                                        className="w-100 custom-btn-holder"
                                                        toggle
                                                      >
                                                        {radios7.map(
                                                          (option, idx) => (
                                                            <ToggleButton
                                                              key={idx}
                                                              type="radio"
                                                              variant="light"
                                                              name="radio"
                                                              value={
                                                                option.value
                                                              }
                                                              checked={
                                                                optionValue ===
                                                                option.value
                                                              }
                                                              onChange={(e) =>
                                                                setOptionValue(
                                                                  e
                                                                    .currentTarget
                                                                    .value
                                                                )
                                                              }
                                                            >
                                                              {option.name}
                                                            </ToggleButton>
                                                          )
                                                        )}
                                                      </ButtonGroup>
                                                    </Col>
                                                  </Row>
                                                  <Row>
                                                    <Col lg={4}>
                                                      <Form.Label>
                                                        {optionValue == "flat"
                                                          ? "Cost"
                                                          : "Precent"}
                                                      </Form.Label>
                                                      <InputGroup>
                                                        <FormControl
                                                          id="custom_cost"
                                                          name="custom_cost"
                                                          type="number"
                                                          value={
                                                            formInput.custom_cost
                                                          }
                                                          onChange={handleInput}
                                                          onFocus={handleFocus}
                                                          onBlur={handleBlur}
                                                        />
                                                        <InputGroup.Append>
                                                          <InputGroup.Text className="pl-0">
                                                            {optionValue ===
                                                            "flat"
                                                              ? "$"
                                                              : "%"}
                                                          </InputGroup.Text>
                                                        </InputGroup.Append>
                                                      </InputGroup>
                                                      {optionValue == "flat"
                                                        ? validator.message(
                                                            "custom_cost",
                                                            formInput.custom_cost,
                                                            "required|numeric|min:0,num"
                                                          )
                                                        : validator.message(
                                                            "custom_cost",
                                                            formInput.custom_cost,
                                                            "required|numeric|min:0,num|max:100,num"
                                                          )}
                                                    </Col>
                                                    {optionValue ==
                                                    "percentage" ? (
                                                      <Col lg={8}>
                                                        <Form.Label>
                                                          &nbsp;
                                                        </Form.Label>
                                                        <ButtonGroup
                                                          className="w-100 custom-btn-holder"
                                                          toggle
                                                        >
                                                          {radios6.map(
                                                            (radio, idx) => (
                                                              <ToggleButton
                                                                key={idx}
                                                                type="radio"
                                                                variant="light"
                                                                name="radio"
                                                                value={
                                                                  radio.value
                                                                }
                                                                checked={
                                                                  radio6Value ===
                                                                  radio.value
                                                                }
                                                                onChange={(e) =>
                                                                  setRadio6Value(
                                                                    e
                                                                      .currentTarget
                                                                      .value
                                                                  )
                                                                }
                                                              >
                                                                {radio.name}
                                                              </ToggleButton>
                                                            )
                                                          )}
                                                        </ButtonGroup>
                                                      </Col>
                                                    ) : null}
                                                  </Row>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                  <Button
                                                    variant="outline-light"
                                                    onClick={() =>
                                                      handleToggleOtherC62(type)
                                                    }
                                                  >
                                                    Cancel
                                                  </Button>
                                                  <Button
                                                    variant="primary"
                                                    disabled={disabled2}
                                                    onClick={(e) => {
                                                      handleCustomModal(
                                                        e,
                                                        type
                                                      );
                                                    }}
                                                  >
                                                    OK
                                                  </Button>
                                                </Modal.Footer>
                                              </Modal>
                                            </div>
                                          )}
                                        </div>
                                      );
                                    }
                                  )
                                : null}
                            </div>
                            <div className="button-bottom-holder d-flex justify-content-between">
                              <Button
                                className="mr-2"
                                variant="outline-light"
                                block
                                onClick={() => backTab("benifits")}
                              >
                                Back
                              </Button>
                              <Button
                                className="ml-2"
                                variant="primary"
                                block
                                disabled={disabled3}
                                onClick={() => nextTab("othercost")}
                              >
                                Continue
                              </Button>
                            </div>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        );
      case "add_name":
        return (
          <div className="d-flex align-items-center justify-content-center calculator-page">
            <Container>
              {isPaymentShowing ? (
                <Payment
                  onClose={handleClosePayment}
                  isShowing={isPaymentShowing}
                />
              ) : null}
              <Row className="justify-content-center">
                <Col lg={6}>
                  <Card className="p-4">
                    <Card.Header>
                      <Card.Title className="mb-0">Calculator</Card.Title>
                    </Card.Header>
                    <Card.Body className="pt-0">
                      <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <FormControl
                          id="name"
                          name="calculator_name"
                          value={formInput.calculator_name}
                          onChange={handleInput}
                        />
                      </Form.Group>
                      {validator.message(
                        "calculator_name",
                        formInput.calculator_name,
                        "required"
                      )}
                      <Button
                        variant="primary"
                        block
                        disabled={disabled}
                        onClick={(e) => handleShow(e, "add_persons")}
                      >
                        Continue
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        );
      default:
        return "";
    }
  }
  let content = (
    <>
      <Modal
        animation={false}
        className="add-person-modal"
        size="lg"
        centered
        show={show}
        onHide={handleModalClose}
      >
        <Modal.Header closeButton className="with-title normal">
          <Modal.Title>Add Persons</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {formInput.person_list.map((person, i) => {
            return (
              <>
                <Row>
                  <Col lg={12}>
                    <Form.Group>
                      <Form.Label>Job name</Form.Label>
                      <FormControl
                        name="job_name"
                        value={person?.job_name}
                        onChange={(e) => handlePersonChange(e, i)}
                      />
                      {validator.message(
                        "job_name_" + i,
                        person?.job_name,
                        "required",
                        {
                          className: "text-danger",
                        }
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                
                <Row>
                  {person.wc_states.map((s, idx) => {
                    return (
                      <>
                        <Col lg={6}>
                          <Form.Group>
                            <Form.Label>State</Form.Label>

                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip>
                                  {
                                    "Please add states in calculations settings."
                                  }
                                </Tooltip>
                              }
                            >
                              <HelpOutlineIcon className="helper-icon"></HelpOutlineIcon>
                            </OverlayTrigger>

                            <Select
                              styles={customControlStyles}
                              placeholder="Alaska"
                              options={wcStatesOptions}
                              value={wcStatesOptions.find(
                                ({ value }) => value === s.state_id
                              )}
                              name={"wc_state_" + idx}
                              onChange={(e) => {
                                handleWcStateChange(e, i, idx);
                              }}
                            />
                            {validator.message(
                              "wc_state_" + idx,
                              person.wc_states[idx].state_id,
                              "required",
                              {
                                className: "text-danger",
                              }
                            )}
                          </Form.Group>
                        </Col>
                        <Col lg={idx == 0 ? 6 : 4}>
                          <Form.Group>
                            <Form.Label>City</Form.Label>
                            <Select
                              styles={customControlStyles}
                              placeholder="San Francisco"
                              options={citiesOptions[s.state_id]}
                              value={
                                s.state_id !== null
                                  ? citiesOptions[s.state_id].find(
                                      ({ value }) => value === s.city_id
                                    )
                                  : {}
                              }
                              name={"wc_city_" + idx}
                              onChange={(e) => {
                                handleWcCityChange(e, i, idx);
                              }}
                            />
                            {validator.message(
                              "wc_city_" + idx,
                              person.wc_states[idx].city_id,
                              "required",
                              {
                                className: "text-danger",
                              }
                            )}
                          </Form.Group>
                        </Col>
                        {idx !== 0 ? (
                          <>
                            <Col lg={2}>
                              <Form.Label>Remove</Form.Label>
                              <Button
                                className="btn-icon"
                                variant="outline-dark"
                                onClick={() => handleRemoveWcState(i, idx)}
                              >
                                <RiCloseLine />
                              </Button>
                            </Col>
                          </>
                        ) : null}
                        <Col lg={12}>
                          <Form.Group>
                            <Form.Label>Workers Compensation</Form.Label>
                            <Select
                              styles={customControlStyles}
                              placeholder="Workers Compensation"
                              options={
                                nicknameOptions[s.state_id]
                                  ? nicknameOptions[s.state_id]
                                  : []
                              }
                              value={
                                nicknameOptions[s.state_id]
                                  ? nicknameOptions[s.state_id].find(
                                      ({ value }) =>
                                        value === s.wc_settings.code
                                    )
                                  : ""
                              }
                              name={"wc_state_nickname" + idx}
                              onChange={(e) => {
                                handleWcNicknameChange(e, i, idx, s.state_id);
                              }}
                            />
                            {validator.message(
                              "wc_state_nickname_" + idx,
                              person.wc_states[idx].wc_settings,
                              "required",
                              {
                                className: "text-danger",
                              }
                            )}
                          </Form.Group>
                        </Col>
                      </>
                    );
                  })}
                </Row>
                <Row>
                  <Col lg={12}>
                    <Button
                      variant="light"
                      block
                      onClick={() => addNewWcState(i)}
                    >
                      <BsPlus /> Add Another State
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <Form.Group>
                      <Form.Label>Number of employees</Form.Label>
                      <FormControl
                        type="number"
                        name="number_of_employees"
                        value={person.number_of_employees}
                        onChange={(e) => handlePersonChange(e, i)}
                      />
                      {validator.message(
                        "number_of_employees_" + i,
                        person.number_of_employees,
                        "required|numeric|min:1,num",
                        {
                          className: "text-danger",
                        }
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </>
            );
          })}
          {/* <Col lg={12}>
        <div className="add-more-states pt-4 py-2 mt-4">
          <Button
            variant="light"
            onClick={handleAddPersonStatesClick}
            block
          >
            <BsPlus /> Add another state
          </Button>
        </div>
      </Col> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-light" onClick={handleModalClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={(e) => handleShow(e, "add_job")}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        animation={false}
        className="import-states-code-modal wc-settings-modal"
        size="lg"
        centered
        show={showWcModal}
        onHide={handleCloseWcModal}
        backdrop="static"
      >
        <Modal.Header className="with-title normal" closeButton>
          {wcStateModal ? (
            <Modal.Title>{stateHavingId(wcStateModal).name}</Modal.Title>
          ) : null}
        </Modal.Header>
        <Modal.Body>
          <Table responsive="sm" className="wc-codes-setting-table">
            <thead>
              <tr>
                <th>Nickname</th>
                <th>Industry</th>
                <th>Code</th>
                <th>Rate</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {wcNicknamesModal.map((singleNickname, singleNicknameIndex) => {
                return (
                  <tr key={singleNicknameIndex}>
                    <td>
                      <Form.Group className="m-0">
                        <FormControl
                          name="nick_name"
                          value={singleNickname.nick_name}
                          onChange={(e) =>
                            handleWcModalChange(e, singleNicknameIndex)
                          }
                        />
                        {validator.message(
                          "nick_name_" + singleNicknameIndex,
                          singleNickname.nick_name,
                          "required",
                          {
                            className: "text-danger",
                          }
                        )}
                      </Form.Group>
                    </td>
                    <td>
                      <Select
                        styles={customControlStyles}
                        placeholder="Select"
                        options={industriesOptions}
                        value={{
                          label: findIndustry(singleNickname.industry),
                          value: singleNickname.industry,
                        }}
                        name="industry"
                        onChange={(e) => {
                          handleWcModalChange(e, singleNicknameIndex);
                        }}
                      />
                    </td>
                    <td>
                      <Form.Group className="m-0">
                        <FormControl
                          name="code"
                          value={singleNickname.code}
                          onChange={(e) =>
                            handleWcModalChange(e, singleNicknameIndex)
                          }
                        />
                        {validator.message(
                          "code_" + singleNicknameIndex,
                          singleNickname.code,
                          "required|numeric",
                          {
                            className: "text-danger",
                          }
                        )}
                      </Form.Group>
                    </td>
                    <td>
                      <InputGroup className="m-0">
                        <FormControl
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          name="rate"
                          value={singleNickname.rate}
                          onChange={(e) =>
                            handleWcModalChange(e, singleNicknameIndex)
                          }
                        />
                        <InputGroup.Append>
                          <InputGroup.Text>%</InputGroup.Text>
                        </InputGroup.Append>

                        {validator.message(
                          "rate_" + singleNicknameIndex,
                          singleNickname.rate,
                          "required|numeric|min:0,num|max:100,num",
                          {
                            className: "text-danger",
                          }
                        )}
                      </InputGroup>
                    </td>
                    <td>
                      <Button
                        className="btn-icon"
                        variant="outline-dark"
                        onClick={() => handleRemoveWcModal(singleNicknameIndex)}
                      >
                        <RiCloseLine />
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <Button
            block
            variant="light"
            className="p-3 mb-2 add-code-button"
            onClick={handleAddWcNickname}
          >
            <HiOutlinePlusSm /> Add code
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-light" onClick={handleCloseWcModal}>
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={disabled}
            onClick={(e) => submitWcModal(e)}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        size="lg"
        show={demoModalShow}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="survey-modal"
      >
        <Modal.Body>
          <Slider {...surveySettings}>
            <div>
              <div className="image-holder" style={{display: "flex" ,justifyContent: "center"}}>
                <img src={ButtonCalculator} style={{maxWidth: "150px"}} />
              </div>
              <div className="text-holder">
                Click this button to start a calculation Calculator +
              </div>
            </div>
            <div>
              <div className="image-holder">
                <img src={CalculatorPopup} />
              </div>
              <div className="text-holder">
                Name the calculation so that you know what it is if you return
                to it later. Add a person or multiple persons.
              </div>
            </div>
            <div>
              <div className="image-holder">
                <img src={AddPerson} />
              </div>
              <div className="text-holder">
                Enter in the job title or name, location, and workers
                compensation nickname (created in the settings). If the workers
                compensation nickname/code is not listed, you can create a new
                one. The same job can be calculated in multiple states.
              </div>
            </div>
            <div>
              <div className="image-holder">
                <img src={EnterPay} />
              </div>
              <div className="text-holder">
                Enter in the Pay, Bill, and assignment details.
              </div>
            </div>
            <div>
              <div className="image-holder">
                <img src={AssignmentDetails} />
              </div>
              <div className="text-holder">
                If you know the assignment dates, you can enter them, and the
                calculator will automatically calculate length of assignment. If
                not, you can enter in estimated length in weeks, months, or
                years. Choose a type of work schedule. Full time is equal to 40
                hours per week.
              </div>
            </div>
            <div>
              <div className="image-holder"  style={{height: "300px", overflow:"hidden"}}>
                <img src={HeathCare} style={{height: "auto"}}/>
                <img src={DrugScreen} style={{height: "auto"}}/>
              </div>
              <div className="text-holder">
                For any benefits and other costs click on the toggle and enter
                or select the information you want to add to the calculation.
              </div>
            </div>

          </Slider>
          {showFinishedButton ? (
            <div className="btn-finish" onClick={handleClose}>
              Finish
            </div>
          ) : (
            ""
          )}
        </Modal.Body>
      </Modal>
      {getStepContent()}
    </>
  );

  return <>{content}</>;
}

export default SingleCalculator;
