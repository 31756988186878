import { combineReducers } from "redux";
import authReducer from "./component/data/authReducer";
import loginReducer from "./component/data/loginReducer";
import forgotReducer from "./component/data/forgotReducer";
const appReducer = combineReducers({
  auth: authReducer,
  login: loginReducer,
  forgot: forgotReducer,
});
const rootReducer = (state, action) => {
  return appReducer(state, action);
};
export default rootReducer;
