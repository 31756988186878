import React, { useState, useEffect, useReducer } from "react";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Card,
  FormControl,
  Alert,
} from "react-bootstrap";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { getCitiesAttempt, getStatesAttempt } from "./data/globalAction";
import SimpleReactValidator from "simple-react-validator";
import * as Constants from "../core/Constants";
import { connect } from "react-redux";
import { companyAttempt, signUpError } from "./data/authAction";
import { signInAttemptByUUID } from "./data/loginAction";
import { toast } from "react-toastify";

function Company(props) {
  const customControlStyles = {
    control: (base, state) => ({
      ...base,
      minHeight: 50,
      marginBottom: "15px",
      background: state.isFocused ? "#fff" : "#fff",
      borderWidth: 1,
      borderRadius: "8px",
      borderColor: state.isFocused ? "#7848FF" : "#E5E8F5",
      boxShadow: state.isFocused ? null : null,
      fontSize: "14px",
      "&:hover": {
        borderColor: state.isFocused ? "#7848FF" : null,
      },
    }),
    dropdownIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
      marginRight: "5px",
    }),
    menu: (base) => ({
      ...base,
      fontSize: "13px",
      border: "1px solid #f1f3f6",
      boxShadow: "none",
    }),
    option: (base, state) => ({
      ...base,
      background: state.isSelected ? "#f1f3f6" : "#fff",
      color: "#151B26",
      "&:hover": {
        background: "#f1f3f6",
        color: "#151B26",
      },
    }),
    placeholder: (base) => ({
      ...base,
      color: "#A8B9CD",
    }),
    clearIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
  };
  const history = useHistory();
  const [validator] = useState(new SimpleReactValidator());
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [, forceUpdate] = useState();
  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    async function fetchStates() {
      setStates(await getStatesAttempt(Constants.default_county.value));
    }
    fetchStates();
    props.signUpError([]);
  }, []);

  useEffect(() => {
    if (
      props.company &&
      props.company.length !== 0 &&
      props.company.data &&
      props.company.data.user
    ) {
      setDisabled(true);
      // toast.success(Constants.update_message);
      if (props.login && props.login.length !== 0) {
        let data = { uuid: props.login.data.data.uuid };
        props.signInAttemptByUUID(JSON.stringify(data));
        setTimeout(function () {
          window.location.href =
            process.env.REACT_APP_SUBDOMIAN_URL.replace(
              "{url}",
              props.company.data.organization.business_domain
            ) + "/dashboard";
        }, 1000);
      }
    }
  });

  const [formInput, setFormInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      user_id: props.login.data.data.uuid,
      business_domain: "",
      company_name: "",
      country: Constants.default_county.value,
      state: "",
      city: "",
      address: "",
      employees: "",
    }
  );

  const createCompany = () => {
    props.signUpError([]);
    setDisabled(true);
    if (!validator.allValid()) {
      validator.showMessages();
      forceUpdate(1);
    } else {
      let data = { formInput };
      props.companyAttempt(JSON.stringify(data.formInput));
    }
    setDisabled(false);
  };
  const handleInput = (evt) => {
    const name = evt.target.name;
    const newValue = evt.target.value;
    setFormInput({ [name]: newValue });
  };
  const handleSelect = async (info, selectName) => {
    const name = selectName;
    const newValue = info.value;
    setFormInput({ [name]: newValue });
    if (selectName === "state") {
      setCities(await getCitiesAttempt(newValue));
    }
  };
  let statesOptions = [];
  let stateDisabe = true;

  if (states && states.length !== 0) {
    {
      states &&
        states.map((state) =>
          statesOptions.push({ label: state.name, value: state.id })
        );
    }
    stateDisabe = false;
  }
  let citiesOptions = [];
  let citiesDisabe = true;
  if (cities && cities.length !== 0) {
    {
      cities &&
        cities.map((city) =>
          citiesOptions.push({ label: city.name, value: city.id })
        );
    }
    citiesDisabe = false;
  }
  let ErrorlistItems = "";
  if (props.error) {
    console.log("props.error", props.error);
    let errors = props.error;
    if (errors.full_messages) errors = errors.full_messages;
    ErrorlistItems = errors.map((err) => <Alert variant="danger">{err}</Alert>);
  }
  return (
    <div className="billing-information-page">
      <Container>
        <Row className="justify-content-md-center">
          <Col lg={6}>
            <h2 className="title">Company information</h2>
            <Card className="mb-4">
              <Card.Body className="p-4">
                {props.error ? <div>{ErrorlistItems}</div> : null}
                <Form.Group>
                  <Form.Label>Company Name</Form.Label>
                  <FormControl
                    required
                    id="company_name"
                    value={formInput.company_name}
                    name="company_name"
                    placeholder="Ex: ReCalc etc"
                    onChange={handleInput}
                  />
                  {validator.message(
                    "company_name",
                    formInput.company_name,
                    "required",
                    { className: "text-danger" }
                  )}
                </Form.Group>
                <Row>
                  <Col lg={`${formInput.state !== "" ? 6 : 12}`}>
                    <Form.Group>
                      <Form.Label>State (optional)</Form.Label>
                      <Select
                        name="state"
                        placeholder="Select State"
                        onChange={(value) => {
                          handleSelect(value, "state");
                        }}
                        options={statesOptions}
                        isDisabled={stateDisabe}
                        styles={customControlStyles}
                      />
                    </Form.Group>
                  </Col>
                  {formInput.state !== "" ? (
                    <Col lg={6}>
                      <Form.Group>
                        <Form.Label>City (optional)</Form.Label>
                        <Select
                          name="city"
                          placeholder="Apple Valley"
                          onChange={(value) => {
                            handleSelect(value, "city");
                          }}
                          options={citiesOptions}
                          isDisabled={citiesDisabe}
                          styles={customControlStyles}
                        />
                      </Form.Group>
                    </Col>
                  ) : null}
                  {formInput.state !== "" ? (
                    <Col lg={12}>
                      <Row>
                        <Col lg={8}>
                          <Form.Group>
                            <Form.Label>Company Address (optional)</Form.Label>
                            <FormControl
                              required
                              value={formInput.address}
                              name="address"
                              type="text"
                              placeholder="33 3rd Ave, New York, NY 10003, USA"
                              id="address"
                              onChange={handleInput}
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={4}>
                          <Form.Group>
                            <Form.Label>Zip Code (optional)</Form.Label>
                            <FormControl
                              required
                              value={formInput.zip_code}
                              name="zip_code"
                              placeholder="10017"
                              type="text"
                              id="zip_code"
                              onChange={handleInput}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                  ) : null}
                </Row>
                <Form.Group>
                  <Form.Label>No of employees (optional)</Form.Label>
                  <Select
                    name="employees"
                    menuPlacement="top"
                    placeholder=""
                    onChange={(value) => {
                      handleSelect(value, "employees");
                    }}
                    options={Constants.employees_options}
                    styles={customControlStyles}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Business Domain</Form.Label>
                  <div className="form-holder">
                    <FormControl
                      required
                      id="business_domain"
                      name="business_domain"
                      value={formInput.business_domain}
                      onChange={handleInput}
                    />
                    <small>
                      . {process.env.REACT_APP_URL.replace(/(^\w+:|^)\/\//, "")}
                    </small>
                  </div>
                  {validator.message(
                    "business_domain",
                    formInput.business_domain,
                    "required|alpha_num_dash",
                    {
                      className: "text-danger",
                    }
                  )}
                </Form.Group>

                <Button
                  block
                  className="mt-4"
                  variant="primary"
                  onClick={(e) => createCompany()}
                >
                  Create Company
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
const mapDispatchToProps = (dispatch) => {
  return {
    companyAttempt: (data) => dispatch(companyAttempt(data)),
    signUpError: (data) => dispatch(signUpError(data)),
    signInAttemptByUUID: (data) => dispatch(signInAttemptByUUID(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    error: state.auth.authError,
    company: state.auth.company,
    login: state.login.login,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Company);
