import { React, useEffect, useState, useReducer } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Row, Col, Container, Tab, Nav, Modal,
  FormControl,
  Button,
  Form,
  Carousel,
  Alert, } from 'react-bootstrap';
import { useHistory, Link } from "react-router-dom";
import Logo from "../component/assets/images/logo.svg";
import { Parallax } from 'react-scroll-parallax';
import { BiCheckDouble } from "react-icons/bi";
import { HiOutlineArrowCircleRight } from "react-icons/hi";
import Client1 from '../component/assets/images/clients/1.png';
import Client2 from '../component/assets/images/clients/2.png';
import Client3 from '../component/assets/images/clients/3.svg';
import Client4 from '../component/assets/images/clients/4.png';
import Client5 from '../component/assets/images/clients/5.svg';
import Client6 from '../component/assets/images/clients/6.png';
import Client7 from '../component/assets/images/clients/7.png';
import FeaOne from '../component/assets/images/imageOne.png';
import FeaTwo from '../component/assets/images/imageTwo.png';
import FeaThree from '../component/assets/images/imageThree.png';
import FeaFour from '../component/assets/images/imageFour.png';
import VisBgLeft from '../component/assets/images/vis-shape.svg';
import VisualOne from '../component/assets/images/vis1.png';
import VisualTwo from '../component/assets/images/vis2.png';
import VisualThree from '../component/assets/images/visual.png';
import CalIcon from '../component/assets/images/icons/calculation.svg';
import V1 from '../component/assets/images/v1.png';
import V4 from '../component/assets/images/v4.png';
import DashImage from '../component/assets/images/learning/dashboard.png';
import TeamPopup from '../component/assets/images/learning/team-popup.png';
import TeamAdded from '../component/assets/images/learning/team-added.png';
import AddPerson from '../component/assets/images/learning/add-person.png';
import CalculationsImage from '../component/assets/images/learning/calculations.png';
import CalOptions from '../component/assets/images/learning/cal-options.png';
import CalList from '../component/assets/images/learning/cal-list.png';
import ButtonImage from '../component/assets/images/learning/buttons.png';
import QuoteBtn from '../component/assets/images/learning/quote-btn.png';
import CreateQuote from '../component/assets/images/learning/create-quote.png';
import AccountImage from '../component/assets/images/learning/account-image.png';
import ImageOne from '../component/assets/images/features/one.jpg';
import ImageTwo from '../component/assets/images/features/two.jpg';
import ImageThree from '../component/assets/images/features/three.jpg';
import ImageFour from '../component/assets/images/features/four.jpg';
import ImageFive from '../component/assets/images/features/five.jpg';
import ImageSix from '../component/assets/images/features/six.jpg';
import ImageSeven from '../component/assets/images/features/seven.jpg';
import AbsOne from '../component/assets/images/features/absolute-1.png';
import AbsTwo from '../component/assets/images/features/absolute-2.png';
import AbsThree from '../component/assets/images/features/absolute-3.png';
import AbsFour from '../component/assets/images/features/absolute-4.png';
import AbsFive from '../component/assets/images/features/absolute-5.png';
import AbsSix from '../component/assets/images/features/absolute-6.png';
import AbsEight from '../component/assets/images/features/absolute-8.png';
import AbsNine from '../component/assets/images/features/absolute-9.jpg';
import ImageEight from '../component/assets/images/features/eight.jpg';
import ImageNine from '../component/assets/images/features/nine.jpg';
import ImageTen from '../component/assets/images/features/quote-holder.png';
import ImageScroll from '../component/assets/images/features/quote-table.png';
import SimpleReactValidator from "simple-react-validator";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ChangePlan from "./ChangePlan";
import Slider from "react-slick";
import Com1 from './assets/images/compliance/money.svg';
import Com2 from './assets/images/compliance/time.svg';
import Com3 from './assets/images/compliance/agreement.svg';
import Com4 from './assets/images/compliance/tri.svg';
import Com5 from './assets/images/compliance/doc-detail.svg';
import { HashLink } from 'react-router-hash-link';
import Box from "@material-ui/core/Box";

import TextLoop from "react-text-loop";
import Footer from "./layout/Footer";

import { FiPlusCircle, FiMinusCircle } from "react-icons/fi";
import { toast } from "react-toastify";
import axios from "axios";
import * as Constants from "../core/Constants";

function Homepage(props) {


  const [month, setMonth] = useState(1);
  const [value, setValue] = useState(1);
  const [amount, setAmount] = useState(0);
  const [year_amount, setYearAmount] = useState(0);
  const [toggle, setToggle] = useState(true);
  const [show, setShow] = useState(false);
  const [member_show, setMemberShow] = useState(false);
  const [showTwo, setShowTwo] = useState(false);
  const [showThree, setShowThree] = useState(false);
  const [showFinishedButton, setShowFinishedButton] = useState(false);
  const [showFinishedButtonTwo, setShowFinishedButtonTwo] = useState(false);
  const [showFinishedButtonThree, setShowFinishedButtonThree] = useState(false);
  const [show2, setShow2] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [validator, setValidator] = useState(new SimpleReactValidator());
  const [messaage, setMessaage] = useState([]);
  const [submitdisable, setSubmitDisable] = useState(false);
  const [, forceUpdate] = useState();
  const [formInput, setFormInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      email: "",
      name: "",
      companyname:"",
      noOfEmployee:""
    }
  );
  const API_URL = process.env.REACT_APP_API_URL;

  const handleChangePlansType = () => {
    setMonth(!month)
  };

  const handleClose2 = () =>{
 setShow2(false);}
  const handleShow2 = () => {
   setShow2(true);
  } 
  const handleCloseVideo = () => {
    setShowVideo(false);
   } 
  const handleClose = () => {
    setShow(false);
    setShowFinishedButton(false)
  }
  const handlememberClose = () => {
    setMemberShow(false);
  }
  const handleCloseTwo = () => {
    setShowTwo(false);
    setShowFinishedButtonTwo(false)
  }
  const handleCloseThree = () => {
    setShowThree(false);
    setShowFinishedButtonThree(false)
  }
  const subtraction = () => {
    
    if (value - 1 > 0)
    {
      getAmount(value - 1,'sub')
      setValue(value - 1 );
    }
    else
    {
      getAmount(1,'sub')
    }
    
  };
  const addition = () => {
    
    getAmount(value + 1,'add');
    if((value + 1) <= 20)
    {
      setValue(value + 1 );
    }
    
  };
  const getAmount = (val = 1,type= 'add') => {
    if(val > 20)
    {
      setMemberShow(true)
    }
    else
    {
      if(type === 'add')
      {
        let p = Constants.plans[val].price;
        let py = Constants.plans[val].yearly_price;
        setAmount(p)
        setYearAmount(py)
      }
      else
      {
        let p = Constants.plans[val].price;
        let py = Constants.plans[val].yearly_price;
        setAmount(p)
        setYearAmount(py)
      }
     
    }
    
  };
  
  // const propsMessagelistItems = props.message.map((msg) => (
  //   <Alert variant="success">{msg}</Alert>
  // ));
  const handleInput = (evt) => {
    const name = evt.target.name;
    const newValue = evt.target.value;
    setFormInput({ [name]: newValue });
  };
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  }

  useEffect(() => {
    document.querySelector("html").classList.remove("signup-page");
    if (props.logged && props.logged.length !== 0) {
      document.querySelector("html").classList.add("signed-in");
      document.querySelector("html").classList.add("homepage");
      document.querySelector("html").classList.remove("signed-out");
    } else {
      document.querySelector("html").classList.remove("signed-in");
      document.querySelector("html").classList.remove("homepage");
      document.querySelector("html").classList.add("signed-out");
      window.addEventListener('scroll', () => {
        document.querySelector("#header").classList.remove("fixed");
        if (window.scrollY !== 0) {
          document.querySelector("#header").classList.add("fixed");
        }
      })
    }
    getAmount()
  }, []);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}
      >Next</div>
    );
  }
  const messagelistItems = messaage.map((msg) => (
    <Alert variant="success">{msg}</Alert>
  ));

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}
      >Back</div>
    );
  }
  const surveySettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    afterChange: function (currentSlide) {
      if (currentSlide == 2) {
        setShowFinishedButton(true)
      } else {
        setShowFinishedButton(false)
      }
    }
  };
  const surveySettingsTwo = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    afterChange: function (currentSlide) {
      if (currentSlide == 1) {
        setShowFinishedButtonTwo(true)
      } else {
        setShowFinishedButtonTwo(false)
      }
    }
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
   
    setMessaage([]);
  
    if (!validator.allValid()) {
      validator.showMessages();
      forceUpdate(1);
    } else {
      let data = { formInput };
      console.log(data);
  
      try {
        // Make Axios call
        setSubmitDisable(true)
        const response = await axios.post(`${API_URL}/get_a_demo`, data);

        // Assuming the response indicates success
        if (response.status === 200) {
          handleClose2()
          toast.success("Email sent successfully");
          setFormInput({
            email: "",
            name: "",
            companyname:"",
            noOfEmployee:""
          })
          setSubmitDisable(false)
          setShowVideo(true);

        } else {
          // Handle other statuses if needed
          toast.error("Failed to send email");
    setSubmitDisable(false)

        }
      } catch (error) {
        // Handle error
    setSubmitDisable(false)
        
        if (error.response) {
          // Request made and server responded with a status code
          // Handle response status code here
          console.error("Response status:", error.response.status);
          console.error("Response data:", error.response.data);
          toast.error("Failed to send email");
        } else if (error.request) {
          // The request was made but no response was received
          console.error("No response received:", error.request);
          toast.error("Failed to send email");
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error("Error setting up request:", error.message);
          toast.error("Failed to send email");
        }
      }
    }
  };

  const surveySettingsThree = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    afterChange: function (currentSlide) {
      if (currentSlide == 5) {
        setShowFinishedButtonThree(true)
      } else {
        setShowFinishedButtonThree(false)
      }
    }
  };
  const handlePriceDuration = () => setToggle(!toggle);
  const [calToggle, setCalToggle] = useState(false);
  const [calToggle2, setCalToggle2] = useState(false);
  return (
    <div className="main-homepage">
      <div className="visual">
        <div className="vis-bg-left"><img src={VisBgLeft} alt="" /></div>
        <div className="top-section">
          <div className="text-holder">
            <h1>Gross
              <TextLoop>
                <span> Profit  </span>
                <span> Margin  </span>
              </TextLoop>
              Simplified</h1>
            <div className="button-holder">
              {/* <NavLink to="/signup" className="btn btn-primary">
                Get a Demo
              </NavLink> */}
              <Button variant="primary" onClick={handleShow2}>
    Get a Demo
    </Button>
    <Modal show={show2} onHide={handleClose2} dialogClassName="modal-full-width">
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
      
 <form className="form" onSubmit={handleSubmit}>
                  {/* {props.error ? <Box mb={2}>{ErrorlistItems}</Box> : null} */}
                  {props.message ? <Box mb={2}>{messagelistItems}</Box> : null}
                  {/* {props.message ? (
                    <Box mb={2}>{propsMessagelistItems}</Box>
                  ) : null} */}
                  <Form.Group>
                    <Form.Label className="d-flex justify-content-between">
                      Name
                    </Form.Label>
                    <FormControl
                      name="name"
                      type="text"
                      value={formInput.name}
                      id="name"
                      placeholder="Enter your name"
                      onChange={handleInput}
                    />
                    {validator.message(
                      "name",
                      formInput.name,
                      "required",
                      {
                        className: "text-danger",
                      }
                    )}
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Email address</Form.Label>
                    <FormControl
                      id="email"
                      name="email"
                      value={formInput.email}
                      autoComplete="email"
                      placeholder="AllenIverson@gmail.com"
                      autoFocus
                      onChange={handleInput}
                    />
                    {validator.message(
                      "email",
                      formInput.email,
                      "required|email",
                      {
                        className: "text-danger",
                      }
                    )}
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="d-flex justify-content-between">
                      Company Name
                    </Form.Label>
                    <FormControl
                      name="companyname"
                      type="text"
                      value={formInput.companyname}
                      id="companyname"
                      placeholder="Enter company name"
                      onChange={handleInput}
                    />
                    {validator.message(
                      "company name",
                      formInput.companyname,
                      "required",
                      {
                        className: "text-danger",
                      }
                    )}
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="d-flex justify-content-between">
                      No of employee
                    </Form.Label>
                    <FormControl
                      name="noOfEmployee"
                      type="number"
                      value={formInput.noOfEmployee}
                      id="noOfEmployee"
                      placeholder="Enter number of employee"
                      onChange={handleInput}
                    />
                    {validator.message(
                      "No of employee",
                      formInput.noOfEmployee,
                      "required",
                      {
                        className: "text-danger",
                      }
                    )}
                  </Form.Group>
                  
                  
    
                 {submitdisable?<Button disabled variant="primary">submit</Button>: <Button type="submit" variant="primary">
                    Submit
                  </Button>
}
                  
                </form>
      </Modal.Body>
    </Modal>
              <HashLink
                smooth
                to="/#pay-intel"
                className="btn btn-ai ml-3 d-inline-block"
                scroll={scrollWithOffset}>
                <p>Pay Intel AI</p>
              </HashLink>
            </div>
          </div>
          <div className="image-holder">
            <img className="vis1" src={VisualOne} alt="Visual One" />
            <img src={VisualThree} alt="Visual" />
            <img className="vis2" src={VisualTwo} alt="Visual Two" />
          </div>
        </div>
        <div className="bottom-section">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="box">
                <img src={CalIcon} alt="cal-icon" />
                <h2>Gain a competitive advantage by understanding your cost to serve down to the penny.</h2>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="box">
                <img src={CalIcon} alt="cal-icon" />
                <h2>Enable your teams to work faster, smarter, with compliance and win exponentially more business with higher profits.</h2>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="box">
                <img src={CalIcon} alt="cal-icon" />
                <h2>The only workforce deployment profit strategy tool designed for {" "}
                  <TextLoop>
                    <span> Staffing</span>
                    <span> Recruiting</span>
                    <span> IT Consulting</span>
                  </TextLoop></h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="clients">
        <h2>Perfect fit into your tech stack</h2>
        <ul className="list-unstyled d-flex justify-content-center">
          <li><a href="https://www.bullhorn.com/" target="_blank"><img src={Client1} alt="Client" /></a></li>
          <li><a href="https://www.avionte.com/" target="_blank"><img src={Client2} alt="Client" /></a></li>
          <li><a href="https://www.ceipal.com/" target="_blank"><img src={Client3} alt="Client" /></a></li>
          <li><a href="https://www.jobdiva.com/" target="_blank"><img src={Client4} alt="Client" /></a></li>
          <li><a href="https://www.crelate.com/" target="_blank"><img src={Client5} alt="Client" /></a></li>
          <li><a href="https://www.vincere.io/" target="_blank"><img src={Client6} alt="Client" /></a></li>
          <li><a href="https://www.zoho.com/recruit/" target="_blank"><img src={Client7} alt="Client" /></a></li>
        </ul>
      </div>

      <div className="main-features">
        <div className="title-holder">
        <div id="platform"></div>
          <h3 className="mb-5">Staffing and  <br /> Recruiting firms <br /> can calculate:
          </h3>
        </div>
        <div className="text-holder">
          <p>Effortlessly compute revenue, employer burdens, benefit expenses, and gross profit for any project or task – regardless of industry or job type. ReCalc ensures your pricing accuracy, whether it's shift work, part-time, or full-time positions, whether hourly or salaried. With limitless calculation potential, ReCalc empowers your financial precision like never before.</p>
          <ul className="list-unstyled">
            <li>Hourly vs Salary</li>
            <li>Full Time vs Part Time</li>
            <li className="pt-3">Shift work</li>
            <li className="pt-3">Markup vs Bill Rate</li>
            <li className="pt-3">Length of assignment</li>
            <li className="pt-3">Taxable and non-taxable wages</li>
            <li className="pt-3">Base vs Effective rate cost</li>
            <li className="pt-3">Single hour cost</li>
            <li className="pt-3">Total assignment Cost</li>
          </ul>
        </div>
        <NavLink to="/signup" className="btn btn-primary mobile-btn">
          Get Started
        </NavLink>
      </div>
      <div className="manual-error">
        <div className="text-holder">
          <h3>No more manual error prone <br />excel sheets</h3>
          <p>
            Why grapple with cumbersome manual tools that are a maintenance nightmare? ReCalc liberates you from these burdens. Let us shoulder the heavy lifting, allowing you to focus on what you do best. Manage all your rates, compliance requirements, calculations, quotes, and teams effortlessly with ReCalc. Bid farewell to missed business opportunities due to pricing errors, and never compromise profitability for the sake of speed and efficiency again. ReCalc is poised to revolutionize your business operations, enabling you to calculate and quote faster than ever before. Give yourself and your teams the competitive edge you've been searching for.
          </p>
        </div>
        <div className="image-holder">
          <div className="excel-image"></div>
        </div>
      </div>
      <div className="compliance">
        <h3>Built with compliance at its core <span>So much more than a calculator</span>
        </h3>
        <div className="comp-list">
          <div className="image-text-holder">
            <div className="icon-holder green">
              <img src={Com1} alt="" />
            </div>
            <strong>Employer Taxation <br /> and Wage Compliance</strong>
          </div>
          <div className="image-text-holder">
            <div className="icon-holder purple">
              <img src={Com2} alt="" />
            </div>
            <strong>Overtime</strong>
          </div>
          <div className="image-text-holder">
            <div className="icon-holder yellow">
              <img src={Com3} alt="" />
            </div>
            <strong>Statutory Paid Time <br /> (Sick Leave Laws) </strong>
          </div>
          <div className="image-text-holder">
            <div className="icon-holder blue">
              <img src={Com4} alt="" />
            </div>
            <strong>Healthcare <br /> and other Benefits</strong>
          </div>
          <div className="image-text-holder">
            <div className="icon-holder purple">
              <img src={Com5} alt="" />
            </div>
            <strong>GSA Stipends</strong>
          </div>
          <div className="image-text-holder">
            <div className="icon-holder red">
              <img src={Com3} alt="" />
            </div>
            <strong>Sales Tax</strong>
          </div>
        </div>
      </div>


      <div className="how-it-works">
        <h3>Features</h3>
        <div className="box">
          <Row className="align-items-center">
            <Col xl={6} lg={6} md={6} sm={12}>
              <div className="text-holder">
                <h3>Customisable Calculation <br /> Settings</h3>
                <p>Effortless Setup Ensuring Full Employer Compliance, Eliminating Profit Shortfalls.</p>
              </div>
            </Col>
            <Col xl={6} lg={6} md={6} sm={12}>
              <div className="image-holder second-step-image-holder">
                <img src={FeaOne} alt="Calculation Settings" />
              </div>
            </Col>
          </Row>
          <Row className="align-items-center mt-5">
            <Col xl={6} lg={6} md={6} sm={12}>
              <div className="image-holder second-step-image-holder">
                <img src={FeaTwo} alt="Team Building" />
              </div>
            </Col>
            <Col xl={6} lg={6} md={6} sm={12}>
              <div className="text-holder">
                <h3>Team Building Made Simple</h3>
                <p>Build teams and track their progress. Approvals enable managers to have oversight of remote and local teams.</p>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center mt-5">
            <Col xl={6} lg={6} md={6} sm={12}>
              <div className="text-holder">
                <h3>Easy Revenue Tracking</h3>
                <p>Track and forecast all quotes across multiple regions or teams.</p>
              </div>
            </Col>
            <Col xl={6} lg={6} md={6} sm={12}>
              <div className="image-holder second-step-image-holder">
                <img src={FeaThree} alt="Revenue Tracking" />
              </div>
            </Col>
          </Row>
          <Row className="align-items-center mt-5">
            <Col xl={6} lg={6} md={6} sm={12}>
              <div className="image-holder second-step-image-holder">
                <img src={FeaFour} alt="Quote" />
              </div>
            </Col>
            <Col xl={6} lg={6} md={6} sm={12}>
              <div className="text-holder">
                <h3>Quick and Simple Quote Sending</h3>
                <p>One system, branded for your company and tech driven. Never misplace or lose out on an opportunity again.</p>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div id="pay-intel"></div>

      <div className="pay-intel">
        <span>Introducing</span>
        <div className="title-main"><h3>Pay Intel </h3>
          <small>AI</small></div>
        <p>Discover the future of market intelligence with our cutting-edge Pay Intel AI tool. Unleash the power of data-driven decisions like never before. Dive deep into real-time market data, harnessing the potential to uncover hidden opportunities and stay steps ahead of the competition. Elevate your business strategy with precision, efficiency, and confidence. Don't just navigate the market – master it, with Pay Intel AI. Try it today and revolutionize your market insights.</p>
        <NavLink to="/" className="btn btn-primary">
          Coming Soon
        </NavLink>
      </div>

      <div id="pricing"></div>

      <div className="pricing">
        <div className="text-holder">
          <h2>Ready to start <br /> with ReCalc?</h2>
          <div className="plan-holder">
            
            <div className="p-name">
              <div>Revolutionary</div>
              
              <div className="p-price">
                {month ? <p>$ <strong>{amount.toFixed(2)}</strong> / month</p> : 
                <p>$ <strong>{year_amount.toFixed(2)}</strong> / month</p>}
              </div>
            </div>
            <div className="toggle-switch pl-5">
                <div className="text pr-4">Monthly / Yearly</div>
                <label className="switch m-0">
                  <input
                    type="checkbox"
                    onChange={handleChangePlansType}
                    defaultChecked={false}
                  />
                  <span className="slider"></span>
                </label>
            </div>
            <div className="p-user">
              <p><Form.Group>
                            <Form.Label>Member</Form.Label>
                            <div className="d-flex align-items-center justify-content-between">
                              
                              <div className="d-flex align-items-center">
                                <Button
                                  variant="outline-light"
                                  onClick={subtraction}
                                >
                                  -
                                </Button>
                                <div className="member-value">
                                  {value}
                                </div>
                                <Button
                                  variant="outline-light"
                                  onClick={addition}
                                >
                                  +
                                </Button>
                              </div>
                            </div>
                          </Form.Group></p>
            </div>
            <div className="p-list">
              <ul className="two-column-list">
                <li>Social Security Calculation</li>
                <li>Medicare Calculation</li>
                <li>FUTA (Federal Unemployment)</li>
                <li>SUTA (State Unemployment)</li>
                <li>Workers Compensation Calculation</li>
                <li>Wage Cap Calculations</li>
                <li>Unlimited Calculations</li>
                <li>Dashboard Tracking</li>
                <li>Quote Creation</li>
                <li>Statutory PTO Calculation</li>
                <li>Sales Tax Compliance</li>
                <li>Healthcare Calculation</li>
                <li>Custom Cost Calculations</li>
                <li>Stipend Calculation (GSA Data)</li>
                <li>Overtime Compliance</li>
                <li>Multiple Persons Calculation</li>
                <li>Unlimited Quotes</li>
                <li>Create Teams</li>
                <li>Manager Views</li>
                <li>Manager Approvals</li>
                <li>Track Performance</li>
                <li>Open API (Coming Soon!)</li>
              </ul>
            </div>
            <NavLink to="/signup" className="btn btn-primary btn-started btn-block">
              Get Started
            </NavLink>
          </div>
        </div>
        {/* <ChangePlan famous="yes" /> */}
      </div>
      <div id="contact"></div>

      <Footer />

      <Modal
        size="sm"
        show={member_show}
        onHide={handlememberClose}
        backdrop="static"
        keyboard={false}
        className=""
      >
        <Modal.Header closeButton>
      </Modal.Header>
        <Modal.Body>
          
          <div className="text-center pt-4">For enterprise you need to contact with our team!</div>
          <div className="text-center pb-2"><a href="mailto:team@recalc.io">team@recalc.io</a></div>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="survey-modal"
      >
        <Modal.Body>
          <Slider {...surveySettings}>
            <div>
              <div className="image-holder">
                <img src={DashImage} />
              </div>
              <div className="text-holder">
                <strong>This is where a company can check their progress.</strong>
                Actual revenue is quotes that have been approved. Projected revenue is quotes out that have yet to be approved. You can filter
                by total, year to date, the last 30 days or the last 7 days.
              </div>
            </div>
            <div>
              <div className="image-holder">
                <img src={TeamPopup} />
              </div>
              <div className="text-holder">
                <strong>You can create a team to track your recruiters.</strong>
                Enter in the name of the team in Team Name. You can invite co-workers to the team by entering their email or if they already have a setup account; you can use their name. Inviting team members will change the number of users to your subscription if they are new. It may take a minute to verify that members have been added.
              </div>
            </div>
            <div>
              <div className="image-holder">
                <img src={V1} />
              </div>
              <div className="text-holder">
                <strong>You can check your team's progress</strong>
                Once a team has been created you will be able to see their progress here. You can click on the team and drill down from team, all members, or a single member. You can see calculations and quotes associated with the selections made.
              </div>
            </div>
          </Slider>
          {showFinishedButton ?
            <div className='btn-finish' onClick={handleClose}>Finish</div>
            : ""}
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={showTwo}
        onHide={handleCloseTwo}
        backdrop="static"
        keyboard={false}
        className="survey-modal"
      >
        <Modal.Body>
          <Slider {...surveySettingsTwo}>
            <div>
              <div className="image-holder">
                <img src={V4} />
              </div>
              <div className="text-holder">
                <strong>Create a Calculator</strong>
                Click this button to start a calculation Calculator +
              </div>
            </div>
            <div>
              <div className="image-holder">
                <img src={AddPerson} />
              </div>
              <div className="text-holder">
                <strong>Name the calculation so that you know what it is if you return to it later. Add a person or multiple persons.</strong>
                Enter in the job title or name, location, and workers compensation nickname (created in the settings). If the workers compensation nickname/code is not listed, you can create a new one. The same job can be calculated in multiple states.
              </div>
            </div>
          </Slider>
          {showFinishedButtonTwo ?
            <div className='btn-finish' onClick={handleCloseTwo}>Finish</div>
            : ""}
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={showThree}
        onHide={handleCloseThree}
        backdrop="static"
        keyboard={false}
        className="survey-modal"
      >
        <Modal.Body>
          <Slider {...surveySettingsThree}>
            <div>
              <div className="image-holder">
                <img src={CalOptions} />
              </div>
              <div className="text-holder">
                <strong>Calculations</strong>
                Once you finish the calculator process you will be brought to the calculation. This is where you will see the breakdown and profitable of each person or multiple persons. You can filter by persons or states.
              </div>
            </div>
            <div>
              <div className="image-holder">
                <img src={CalList} />
              </div>
              <div className="text-holder">
                <strong>Calculations</strong>
                At any time to see your selections for the calculation click summary. Edit will allow you to change selections. The calculation change be downloaded in different formats.
              </div>
            </div>
            <div>
              <div className="image-holder">
                <img src={ButtonImage} />
              </div>
              <div className="text-holder">
                <strong>Calculations</strong>
                Send for approval and the manager of the group will receive a notification to review the calculation. The manager can approve or decline with commentary.
              </div>
            </div>
            <div>
              <div className="image-holder">
                <img src={QuoteBtn} />
              </div>
              <div className="text-holder">
                <strong>Calculations</strong>
                Once approved, a notification will be sent that the calculation is ready for quotation.
              </div>
            </div>
            <div>
              <div className="image-holder">
                <img src={CreateQuote} />
              </div>
              <div className="text-holder">
                <strong>Calculations</strong>
                Enter in the company name, quote number and the states in which you are quoting. The quote will be branded with your logo (setup in the system settings). Quotes can be manually approved or declined. The quote can be sent to the client via email or other apps. Client will receive the quote, review, and approve the quote via the system.
              </div>
            </div>
            <div>
              <div className="image-holder">
                <img src={AccountImage} />
              </div>
              <div className="text-holder">
                <strong>Calculations</strong>
                To reach the learning center or settings, just click the username in the top right corner.
              </div>
            </div>
          </Slider>
          {showFinishedButtonThree ?
            <div className='btn-finish' onClick={handleCloseThree}>Finish</div>
            : ""}
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        show={showVideo}
        onHide={handleCloseVideo}
        backdrop="static"
        keyboard={false}
        className="survey-modal"
        centered>
      
        <Modal.Body>
          <div className="video-container" style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden', maxWidth: '100%', background: '#000' }}>
            <iframe
              src="https://drive.google.com/file/d/11RQ95elbxQqcJmLrZmEdsQI0W6taFZKu/preview"
              style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
              allow="autoplay"
              frameBorder="0"
              allowFullScreen
              title="Recalc Overview"
            ></iframe>
          </div>
          
        </Modal.Body>
        <Modal.Footer>
          <div style={{display: 'flex',justifyContent: 'space-between',width: '100%'}}>
            <span>
              <h4>Recalc Overview</h4>
            </span>
            <span className="float-right">
            <Button variant="primary" onClick={handleCloseVideo}>
            Close
          </Button>
            </span>
          </div>
          
      </Modal.Footer>
      </Modal>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(Homepage);