import React, { useReducer, useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import { connect } from "react-redux";
import {
  signInMessage,
  signInError,
  signInAttempt,
  signInAttemptByUUID,
} from "./data/loginAction";
import { signUpAttemptResponseClear } from "./data/authAction";
import { useHistory, Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import queryString from "query-string";
import Logo from "./assets/images/logo.svg";
import {
  Container,
  Row,
  Col,
  FormControl,
  Button,
  Form,
  Carousel,
  Alert,
} from "react-bootstrap";
import { RiFacebookFill, RiLinkedinFill } from "react-icons/ri";
import { FcGoogle } from "react-icons/fc";
import { toast } from "react-toastify";
import LoginSlider from "./layout/LoginSlider";

function SignIn(props) {
  const [validator, setValidator] = useState(new SimpleReactValidator());
  const [, forceUpdate] = useState();

  const history = useHistory();
  const [messaage, setMessaage] = useState([]);

  useEffect(() => {
    localStorage.removeItem("isShowing");
    if (props.login && props.login.data && props.login.data.data) {
      history.push("/dashboard");
    }
    let params = queryString.parse(window.location.search);
    if (params.id) {
      let data = { uuid: params.id };

      props.signInAttemptByUUID(JSON.stringify(data));
    }
    props.signUpAttemptResponseClear([]);
  }, []);
  useEffect(() => {
    let params = queryString.parse(window.location.search);
    if (params.message) {
      setMessaage([params.message]);
      history.replace({
        search: "",
      });
    }
    if (params.error) {
      toast.error(params.error);
      history.replace({
        search: "",
      });
    }
    if (props.login && props.login.length !== 0 && props.login.data) {
      if (
        props.login.data.data.organization_users.length > 0 &&
        props.login.data.data.organization_users[0].organization &&
        props.login.data.data.organization_users[0].organization.business_domain
      ) {
        window.location.href =
          process.env.REACT_APP_SUBDOMIAN_URL.replace(
            "{url}",
            props.login.data.data.organization_users[0].organization
              .business_domain
          ) + "/dashboard";
      } else {
        window.location.href = process.env.REACT_APP_URL + "/company";
      }
    }
  });
  const [formInput, setFormInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      email: "",
      password: "",
    }
  );

  const handleInput = (evt) => {
    const name = evt.target.name;
    const newValue = evt.target.value;
    setFormInput({ [name]: newValue });
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    props.signInError([]);
    props.signInMessage([]);
    setMessaage([]);
    if (!validator.allValid()) {
      validator.showMessages();
      forceUpdate(1);
    } else {
      let data = { formInput };
      props.signInAttempt(JSON.stringify(data.formInput));
      setTimeout(()=>{
          document.querySelector('html').classList.add('loading');
      }, 300)
      setTimeout(()=>{
        document.querySelector('html').classList.remove('loading');
    }, 2000)
    }
  };
  const selectAccountType = (evt, index) => {
    if (index === "google") {
      window.location.href =
        process.env.REACT_APP_API_URL + "/auth/google_oauth2?login=true";
    } else if (index === "facebook") {
      window.location.href =
        process.env.REACT_APP_API_URL + "/auth/facebook?login=true";
    } else if (index === "linkedin") {
      window.location.href =
        process.env.REACT_APP_API_URL + "/auth/linkedin?login=true";
    }
  };

  const messagelistItems = messaage.map((msg) => (
    <Alert variant="success">{msg}</Alert>
  ));
  const propsMessagelistItems = props.message.map((msg) => (
    <Alert variant="success">{msg}</Alert>
  ));
  const ErrorlistItems = props.error.map((err) => (
    <Alert variant="danger">{err}</Alert>
  ));

  document.querySelector("html").classList.add("signup-page");
  return (
    <div className="content-div">
      <div className="sign-in">
        <Container fluid>
          <Row>
            <Col lg={6}>
              <div className="login-holder">
                <div className="logo mt-5">
                  <img
                    src={Logo}
                    alt="ReCalc Logo"
                    onClick={() => {
                      history.push("/");
                    }}
                  />
                </div>
                <h2 className="title">Sign in</h2>
                <Button
                  block
                  variant="light"
                  onClick={(e) => selectAccountType(e, "google")}
                >
                  <FcGoogle /> Login with Google
                </Button>
                <Button
                  block
                  variant="light"
                  className="btn-facebook"
                  onClick={(e) => selectAccountType(e, "facebook")}
                >
                  <RiFacebookFill /> Login with Facebook
                </Button>
                <Button
                  block
                  className="btn-linkedin"
                  variant="light"
                  onClick={(e) => selectAccountType(e, "linkedin")}
                >
                  <RiLinkedinFill /> Login with Linkedin
                </Button>
                <div className="heading">
                  <span>or Sign in with your email</span>
                </div>
                <form className="form" onSubmit={handleSubmit}>
                  {props.error ? <Box mb={2}>{ErrorlistItems}</Box> : null}
                  {props.message ? <Box mb={2}>{messagelistItems}</Box> : null}
                  {props.message ? (
                    <Box mb={2}>{propsMessagelistItems}</Box>
                  ) : null}
                  <Form.Group>
                    <Form.Label>Email address</Form.Label>
                    <FormControl
                      id="email"
                      name="email"
                      autoComplete="email"
                      placeholder="AllenIverson@gmail.com"
                      autoFocus
                      onChange={handleInput}
                    />
                    {validator.message(
                      "email",
                      formInput.email,
                      "required|email",
                      {
                        className: "text-danger",
                      }
                    )}
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="d-flex justify-content-between">
                      Password
                    </Form.Label>
                    <FormControl
                      name="password"
                      type="password"
                      id="password"
                      placeholder="Min. 8 Character"
                      autoComplete="current-password"
                      onChange={handleInput}
                    />
                    {validator.message(
                      "password",
                      formInput.password,
                      "required",
                      {
                        className: "text-danger",
                      }
                    )}
                  </Form.Group>
                  <div className="d-flex align-items-center justify-content-between mt-4">
                    <Form.Check
                      custom
                      className="p-0"
                      type="checkbox"
                      id="remember-me"
                      label="Remember me"
                    />
                    <Link to="/forgot-password" className="forgot-link">
                      Forgot password?
                    </Link>
                  </div>
                  <Button type="submit" variant="primary">
                    Login
                  </Button>
                  <div className="login-option">
                    Not a member?
                    <Link to="/signup">Create account</Link>
                  </div>
                </form>
              </div>
            </Col>
            <Col lg={6}>
              <LoginSlider />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    signInMessage: (data) => dispatch(signInMessage(data)),
    signInError: (data) => dispatch(signInError(data)),
    signInAttempt: (data) => dispatch(signInAttempt(data)),
    signInAttemptByUUID: (data) => dispatch(signInAttemptByUUID(data)),
    signUpAttemptResponseClear: (data) =>
      dispatch(signUpAttemptResponseClear(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    message: state.auth.authMessage,
    error: state.login.loginError,
    login: state.login.login,
    auth: state.auth.auth,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
