export const forgotPasswordAttempt = (payload) => ({
  type: "FORGOT_PASSWORD_ATTEMPT",
  payload: payload,
});
export const forgotPasswordAttemptResponse = (payload) => ({
  type: "FORGOT_PASSWORD_ATTEMPT_RESPONSE",
  payload: payload,
});
export const forgotPasswordError = (payload) => ({
  type: "FORGOT_PASSWORD_SET_ERROR",
  payload: payload,
});
export const forgotPasswordMessage = (payload) => ({
  type: "FORGOT_PASSWORD_SET_MESSAGE",
  payload: payload,
});

export const resetPasswordAttempt = (payload) => ({
  type: "RESET_PASSWORD_ATTEMPT",
  payload: payload,
});
export const resetPasswordAttemptResponse = (payload) => ({
  type: "RESET_PASSWORD_ATTEMPT_RESPONSE",
  payload: payload,
});
export const resetPasswordError = (payload) => ({
  type: "RESET_PASSWORD_SET_ERROR",
  payload: payload,
});
