import React, { useReducer, useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import {
  Row,
  Col,
  Container,
  Button,
  Form,
  FormControl,
  Alert,
} from "react-bootstrap";
import { connect } from "react-redux";
import * as Constants from "../core/Constants";
import Logo from "./assets/images/logo.svg";
import {
  forgotPasswordMessage,
  forgotPasswordError,
  forgotPasswordAttempt,
  forgotPasswordAttemptResponse,
} from "./data/forgotAction";
import { signUpAttemptResponse } from "./data/authAction";
import { useHistory, Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import LoginSlider from "./layout/LoginSlider";

function ForgotPassword(props) {
  const [validator, setValidator] = useState(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const [disabled, setDisabled] = useState(false);

  const history = useHistory();
  const [messaage, setMessaage] = useState([]);
  const [error, setError] = useState([]);

  useEffect(() => {
    if (disabled && props.error && props.error.length > 0) {
      setError([props.error]);
      setDisabled(false);
      props.forgotPasswordAttemptResponse([]);
    }
    if (
      disabled &&
      props.message &&
      props.message.data &&
      props.message.data.message
    ) {
      setMessaage([props.message.data.message]);
      setDisabled(false);
      props.forgotPasswordAttemptResponse([]);
    }
  });
  useEffect(() => {
    if (props.login && props.login.data && props.login.data.data) {
      history.push("/dashboard");
    }
  }, []);
  const [formInput, setFormInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      email: "",
      redirect_url: window.location.origin + "/reset-password",
    }
  );

  const handleInput = (evt) => {
    const name = evt.target.name;
    const newValue = evt.target.value;
    setFormInput({ [name]: newValue });
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setMessaage([]);
    setError([]);
    if (!validator.allValid()) {
      validator.showMessages();
      forceUpdate(1);
    } else {
      let data = { formInput };
      setDisabled(true);
      props.forgotPasswordAttempt(JSON.stringify(data.formInput));
    }
  };

  const propsMessagelistItems = messaage.map((msg) => (
    <Alert variant="success">{msg}</Alert>
  ));

  const ErrorlistItems = error.map((err) => (
    <Alert variant="danger">{err}</Alert>
  ));
  document.querySelector("html").classList.add("signup-page");
  return (
    <div className="content-div">
      <div className="forgot-password-page">
        <Container fluid>
          <Row>
            <Col lg={6}>
              <div className="login-holder">
                <div className="logo">
                  <img
                    src={Logo}
                    alt="ReCalc Logo"
                    onClick={() => {
                      history.push("/");
                    }}
                  />
                </div>
                <h2 className="title">Forgot Password?</h2>
                <div className="mb-3">
                  <p>
                    Enter the email address you used when you joined and we’ll
                    send you instructions to reset your password.
                  </p>
                  <p>
                    For security reasons, we do NOT store your password. So rest
                    assured that we will never send your password via email.
                  </p>
                </div>
                <form className="form" onSubmit={handleSubmit}>
                  {error ? <Box mb={2}>{ErrorlistItems}</Box> : null}
                  {messaage ? <Box mb={2}>{propsMessagelistItems}</Box> : null}
                  <Form.Group>
                    <Form.Label>Email address</Form.Label>
                    <FormControl
                      fullWidth
                      id="email"
                      name="email"
                      autoComplete="email"
                      placeholder="AllenIverson@gmail.com"
                      autoFocus
                      onChange={handleInput}
                    />
                    {validator.message(
                      "email",
                      formInput.email,
                      "required|email",
                      {
                        className: "text-danger",
                      }
                    )}
                  </Form.Group>
                  <Button type="submit" variant="primary" disabled={disabled}>
                    Send Reset Instructions
                  </Button>
                  <div className="login-option">
                    I remember my password
                    <Link to="/signin">Login</Link>
                  </div>
                </form>
              </div>
            </Col>
            <Col lg={6}>
              <LoginSlider />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    forgotPasswordMessage: (data) => dispatch(forgotPasswordMessage(data)),
    forgotPasswordError: (data) => dispatch(forgotPasswordError(data)),
    forgotPasswordAttempt: (data) => dispatch(forgotPasswordAttempt(data)),
    forgotPasswordAttemptResponse: (data) =>
      dispatch(forgotPasswordAttemptResponse(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    message: state.forgot.forgot,
    error: state.forgot.forgotError,
    forgot: state.forgot.forgot,
    login: state.login.login,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
