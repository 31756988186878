import React, { useReducer, useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { connect } from "react-redux";
import * as Constants from "../core/Constants";
import {
  resetPasswordAttempt,
  resetPasswordAttemptResponse,
  resetPasswordError,
} from "./data/forgotAction";
import { signUpAttemptResponse } from "./data/authAction";
import { useHistory, Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import queryString from "query-string";
import Logo from "./assets/images/logo.svg";
import {
  Row,
  Col,
  Container,
  Button,
  Form,
  Alert,
  FormControl,
} from "react-bootstrap";
import LoginSlider from "./layout/LoginSlider";

function ResetPassword(props) {
  const [validator, setValidator] = useState(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const [disabled, setDisabled] = useState(false);

  const history = useHistory();
  const [messaage, setMessaage] = useState([]);
  const [error, setError] = useState([]);

  useEffect(() => {
    if (
      disabled &&
      props.error &&
      props.error.full_messages &&
      props.error.full_messages.length > 0
    ) {
      setError(Array.from(new Set(props.error.full_messages)));
      setDisabled(false);
      props.resetPasswordAttemptResponse([]);
    }
    if (
      disabled &&
      props.message &&
      props.message.data &&
      props.message.data.message
    ) {
      setMessaage([props.message.data.message]);
      setDisabled(false);
      props.resetPasswordAttemptResponse([]);
      history.push("/signin?message=" + props.message.data.message);
    }
  });
  useEffect(() => {
    if (props.login && props.login.data && props.login.data.data) {
      history.push("/dashboard");
    }
  }, []);
  let params = queryString.parse(window.location.search);
  console.log("params", params);
  const [formInput, setFormInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      password: "",
      password_confirmation: "",
      params: params,
    }
  );

  const handleInput = (evt) => {
    const name = evt.target.name;
    const newValue = evt.target.value;
    setFormInput({ [name]: newValue });
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setMessaage([]);
    setError([]);
    if (!validator.allValid()) {
      validator.showMessages();
      forceUpdate(1);
    } else {
      let data = { formInput };
      setDisabled(true);
      props.resetPasswordAttempt(JSON.stringify(data.formInput));
    }
  };
  const propsMessagelistItems = messaage.map((msg) => (
    <Alert variant="success">{msg}</Alert>
  ));
  const ErrorlistItems = error.map((err) => (
    <Alert variant="danger">{err}</Alert>
  ));
  document.querySelector("html").classList.add("signup-page");
  return (
    <div className="content-div">
      <div className="reset-password-page">
        <Container fluid>
          <Row>
            <Col lg={6}>
              <div className="login-holder">
                <h2 className="title">Reset Password</h2>
                <div className="logo">
                  <img src={Logo} alt="Recalc Logo" />
                </div>
                <form className="form" onSubmit={handleSubmit}>
                  {error ? <Box mb={2}>{ErrorlistItems}</Box> : null}
                  {messaage ? <Box mb={2}>{propsMessagelistItems}</Box> : null}
                  <Form.Group>
                    <Form.Label>New Password</Form.Label>
                    <FormControl
                      id="password"
                      type="password"
                      name="password"
                      autoComplete="password"
                      autoFocus
                      onChange={handleInput}
                    />
                    {validator.message(
                      "password",
                      formInput.password,
                      "required",
                      {
                        className: "text-danger",
                      }
                    )}
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Confirm Password</Form.Label>
                    <FormControl
                      id="password_confirmation"
                      type="password"
                      name="password_confirmation"
                      autoComplete="password_confirmation"
                      onChange={handleInput}
                    />
                    {validator.message(
                      "password_confirmation",
                      formInput.password_confirmation,
                      "required",
                      {
                        className: "text-danger",
                      }
                    )}
                  </Form.Group>
                  <Button
                    type="submit"
                    block
                    variant="primary"
                    disabled={disabled}
                  >
                    Reset and Continue
                  </Button>
                </form>
              </div>
            </Col>
            <Col lg={6}>
              <LoginSlider />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetPasswordError: (data) => dispatch(resetPasswordError(data)),
    resetPasswordAttempt: (data) => dispatch(resetPasswordAttempt(data)),
    resetPasswordAttemptResponse: (data) =>
      dispatch(resetPasswordAttemptResponse(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    message: state.forgot.reset,
    error: state.forgot.resetError,
    reset: state.forgot.reset,
    login: state.login.login,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
