import { React, useEffect, useState } from "react";
import { connect } from "react-redux";

function AfterLogin(props) {
  useEffect(() => {
    if (
      props.login &&
      props.login.data &&
      props.login.data.data &&
      props.login.data.data.organization_users &&
      props.login.data.data.organization_users.length === 0 &&
      window.location.pathname !== "/company"
    ) {
      window.location.href = process.env.REACT_APP_URL + "/company";
    }
  }, []);

  return <div></div>;
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    login: state.login.login,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AfterLogin);
