import React, { useReducer, useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Nav,
  Tab,
  Button,
  Modal,
  Form,
  FormControl,
  Table,
  InputGroup,
  Alert,
  ButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import SutaTemplate from "../component/assets/templates/SUTA-Rate-Template.csv";
import WCTemplate from "../component/assets/templates/Workers-Compensation-Template.csv";
import { BsPlus } from "react-icons/bs";
import UploadDocIcon from "../component/assets/images/icons/upload-document.svg";
import UploadDocWhiteIcon from "../component/assets/images/icons/upload-document-white.svg";
import chevLeft from "../component/assets/images/icons/cheveron-left.svg";
import ImportImageOne from "../component/assets/images/import-one.png";
import ImportImageTwo from "../component/assets/images/import-two.png";
import EditIcon from "./assets/images/icons/edit-pencil.svg";
import EditWhiteIcon from "./assets/images/icons/edit-pencil-white.svg";
import {
  getStatesAttempt,
  getOrganizationSettingAttempt,
  sutaOrganizationSettingAttempt,
  importSutaOrganizationSettingAttempt,
  taxStatusOrganizationSettingAttempt,
  salesTaxOrganizationSettingAttempt,
  importWcOrganizationSettingAttempt,
  updateSptoOrganizationSettingAttempt,
  wcSettingsOrganizationSettingAttempt,
  alwaysAccureOrganizationSettingAttempt,
  packageDeleteOrganizationSettingAttempt,
  costDeleteOrganizationSettingAttempt,
  packageOrganizationSettingAttempt,
  updateCostOrganizationSettingAttempt,
  exemptSalesTaxOrganizationSettingAttempt,
  getSettings,
  getIndustriesAttempt,
  getCitiesAttempt,
  getSPTOSettings,
  getBillSettings,
} from "./data/globalAction";
import * as Constants from "../core/Constants";
import { HiOutlinePlusSm, HiCheck } from "react-icons/hi";
import { RiCloseLine } from "react-icons/ri";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import { useHistory, useParams, NavLink } from "react-router-dom";
import CSVFileValidator from "csv-file-validator";
import DataTable from "react-data-table-component";
import { render } from "@testing-library/react";
import Payment from "./Payment";
import AfterLogin from "./middleware/AfterLogin";
import queryString from "query-string";

const customControlStyles = {
  control: (base, state) => ({
    ...base,
    minHeight: 50,
    background: state.isFocused ? "#fff" : "#fff",
    borderWidth: 1,
    borderRadius: "8px",
    borderColor: state.isFocused ? "#7848FF" : "#E5E8F5",
    boxShadow: state.isFocused ? null : null,
    fontSize: "14px",
    "&:hover": {
      borderColor: state.isFocused ? "#7848FF" : null,
    },
  }),
  dropdownIndicator: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
    marginRight: "5px",
  }),
  menu: (base) => ({
    ...base,
    fontSize: "13px",
    border: "1px solid #f1f3f6",
    boxShadow: "none",
  }),
  option: (base, state) => ({
    ...base,
    background: state.isSelected ? "#f1f3f6" : "#fff",
    color: "#151B26",
    "&:hover": {
      background: "#f1f3f6",
      color: "#151B26",
    },
  }),
  placeholder: (base) => ({
    ...base,
    color: "#A8B9CD",
  }),
  clearIndicator: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
};

const customTableStyles = {
  header: {
    style: {
      padding: "0",
      minHeight: "inherit",
    },
  },
  headRow: {
    style: {
      borderTopStyle: "solid",
      borderTopWidth: "1px",
      borderTopColor: "#E5E8F5",
      borderBottomStyle: "solid",
      borderBottomWidth: "1px",
      borderBottomColor: "#E5E8F5",
    },
  },
  headCells: {
    style: {
      fontSize: "14px",
      fontWeight: "normal",
      color: "#43536D",
      background: "#FCFCFF",
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "#fff",
      },
    },
  },
  rows: {
    style: {
      minHeight: "65px",
      "&:not(:last-of-type)": {
        borderBottomStyle: "solid",
        borderBottomWidth: "1px",
        borderBottomColor: "#f0f0f0",
      },
    },
  },
  cells: {
    style: {
      minHeight: "65px",
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "#fff",
      },
    },
  },
  pagination: {
    style: {
      borderTopStyle: "solid",
      borderTopWidth: "1px",
      borderTopColor: "#f0f0f0",
    },
  },
};

let taxOptions = [];
{
  Constants.default_tax_type &&
    Constants.default_tax_type.map((tax) => {
      let row = { label: tax, value: tax };

      taxOptions.push(row);
    });
}
export default function CalculationSettings() {
  const history = useHistory();
  const [step, setStep] = useState("");
  const [activeState, setActiveState] = useState();
  const [stateTitle, setStateTitle] = useState("");
  const [tabActiveKey, setTabActiveKey] = useState("srates");

  const [activeSalesTax, setActiveSalesTax] = useState();

  const [otherCostModalTitle, setOtherCostModalTitle] = useState();
  const [otherCostModalLabel1, setOtherCostModalLabel1] = useState();
  const [otherCostModalLabel2, setOtherCostModalLabel2] = useState();
  const [otherCostModalLabel3, setOtherCostModalLabel3] = useState(false);
  const [otherCostModalLabel4, setOtherCostModalLabel4] = useState(false);
  const [otherCostModalLabel5, setOtherCostModalLabel5] = useState(false);
  const [industryValue, setIndustryValue] = useState(null);
  const [radioValue, setRadioValue] = useState("1");
  const [optionValue, setOptionValue] = useState("flat");
  const [isPaymentShowing, setIsPaymentShowing] = useState(false);
  const handleClosePayment = () => {
    localStorage.removeItem("expire");
    setIsPaymentShowing(false);
  };
  const [costType, setCostType] = useState("");

  const changeError = (message, className) => {
    if (
      message.includes("suta rate") ||
      message.includes("nick name") ||
      message.includes("code") ||
      message.includes("rate")
    )
      return <div className="text-danger">{message.replace(/[0-9]/, "")}</div>;
    else return <div className="text-danger">{message}</div>;
  };

  const [organizationSetting, setOrganizationSetting] = useState();
  const [validator] = useState(
    new SimpleReactValidator({
      element: (message, className) => changeError(message, className),
    })
  );
  const [forceShow, forceUpdate] = useState();
  const [tab, setTab] = useState("");
  const [taxSelect, setTaxSelect] = useState("");

  const [checkedAll, setCheckedAll] = useState(false);
  const [industries, setIndustries] = useState([]);
  const [sutaImportError, setSutaImportError] = useState([]);

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [citiesInfo, setCitiesInfo] = useState([]);

  const [statesInfo, setStatesInfo] = useState([]);
  const [statesList, setStatesList] = useState([]);
  const [sutaList, setSutaList] = useState([]);
  const [sptoList, setSPTOList] = useState([]);

  const [settings, setSettings] = useState([]);
  const [billSettings, setBillSettings] = useState([]);
  const [formInput, setFormInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      city: "",
      tax_type: "",
      tax: "",
      import_file: "",
      import_data: "",
      accural_rate: "",
      accural_cap_per_year: "",
      total_cap: "",
      package_name: "",
      employee_only: "",
      employee_spouse: "",
      employee_dependent: "",
      family: "",
      name: "",
      percent: "",
    }
  );
  const [inputList, setInputList] = useState([]);
  let industriesOptions = [];
  {
    industries &&
      industries.map((industry) =>
        industriesOptions.push({ label: industry.name, value: industry.id })
      );
  }

  const [showModalOne, setShowModalOne] = useState(false);
  const handleCloseOne = () => {
    setStatesList([]);
    console.log('herrrrrreee',statesList)
    handleTabChange();
    setShowModalOne(false);
  }
  const handleShowOne = () => setShowModalOne(true);

  const [showModalTwo, setShowModalTwo] = useState(false);
  const handleShowTwo = () => setShowModalTwo(true);
  const handleCloseTwo = () => {
    setStep("");
    setShowModalTwo(false);
  };

  const [showModalThree, setShowModalThree] = useState(false);
  const handleShowThree = () => setShowModalThree(true);
  const handleCloseThree = () => setShowModalThree(false);

  const [showModalFour, setShowModalFour] = useState(false);
  const handleShowFour = () => setShowModalFour(true);
  const handleCloseFour = () => setShowModalFour(false);
  const [disabled, setDisabled] = useState(false);
  const [disabledCSV, setDisabledCSV] = useState(false);

  const [showModalFive, setShowModalFive] = useState(false);
  const handleShowFive = () => setShowModalFive(true);
  const handleCloseFive = () => setShowModalFive(false);

  const [showModalSix, setShowModalSix] = useState(false);
  const [showModalTutorial, setShowModalTutorial] = useState(false);

  const [sptoStatesSelect, setSptoStatesSelect] = useState({});
  const [sptoStateCitySelect, setSptoStateCitySelect] = useState({});
  const [sptoStatesData, setSptoStatesData] = useState([]);
  const [sptoStateCitiesName, setSptoStateCitiesName] = React.useState([]);
  let sptoDataBasedOnSelection = [];
  // let sptoStateCitiesName = [];

  const handleShowSix = () => {
    validator.hideMessages();
    forceUpdate(0);
    setPackageRow(0);
    setFormInput({ ["package_name"]: "" });
    setFormInput({ ["employee_only"]: "" });
    setFormInput({ ["employee_spouse"]: "" });
    setFormInput({ ["employee_dependent"]: "" });
    setFormInput({ ["family"]: "" });
    setShowModalSix(true);
  };
  const handleCloseSix = () => setShowModalSix(false);
  const handleCloseTutorial = () => {
    setShowModalTutorial(false);
    history.push({
      pathname: `/settings/calculation-settings`,
    });
  }

  const [showModalSeven, setShowModalSeven] = useState(false);

  const [sptoRow, setSptoRow] = useState();
  const [packageRow, setPackageRow] = useState(0);
  const [deleteItem, setDeleteItem] = useState("cost");
  const [costRow, setCostRow] = useState(0);
  const [costListRow, setCostListRow] = useState(0);

  const handleShowSeven = (row) => {
    validator.hideMessages();
    forceUpdate(0);
    setSptoRow(row);
    setFormInput({ ["accural_rate"]: row.accural_rate });
    setFormInput({ ["accural_cap_per_year"]: row.accural_cap_per_year });
    setFormInput({ ["total_cap"]: row.total_cap });
    setStateTitle(row.state);
    setShowModalSeven(true);
  };

  const handleAlwaysAccrue = async (evt) => {
    console.log("evt", evt.target.checked);
    let organization = settings.data.organization_users[0].organization;

    let data = {
      organization: organization.id,
      option: "always_accrue",
      value: evt.target.checked,
    };
    let result = await alwaysAccureOrganizationSettingAttempt(
      JSON.stringify(data)
    );
    if (result.status === 200) {
      setOrganizationSetting(result.data);
      toast.success(Constants.update_message);
    } else {
      if (localStorage.getItem("expire")) {
        setIsPaymentShowing(true);
        console.log("expire", localStorage.getItem("expire"));
      }
      evt.target.checked = organization.always_accrue;
    }
  };
  const handleSalesTaxExemptAll = async (evt) => {
    console.log("evt", evt.target.checked);
    let organization = settings.data.organization_users[0].organization;

    let data = {
      organization: organization.id,
      option: "sales_tax_exempt_all",
      value: evt.target.checked,
    };
    let result = await exemptSalesTaxOrganizationSettingAttempt(
      JSON.stringify(data)
    );
    if (result.status === 200) {
      setOrganizationSetting(result.data);
      toast.success(Constants.update_message);
    } else {
      if (localStorage.getItem("expire")) {
        setIsPaymentShowing(true);
        console.log("expire", localStorage.getItem("expire"));
      }
      evt.target.checked = organization.always_accrue;
    }
  };
  const handleCloseSeven = () => setShowModalSeven(false);

  const [showModalEight, setShowModalEight] = useState(false);
  const handleShowEight = () => setShowModalEight(true);
  const handleCloseEight = () => setShowModalEight(false);

  const [showModalNine, setShowModalNine] = useState(false);
  const handleShowNine = () => setShowModalNine(true);
  const handleCloseNine = () => setShowModalNine(false);

  const [showModalTen, setShowModalTen] = useState(false);
  const handleShowTen = () => setShowModalTen(true);
  const handleCloseTen = () => setShowModalTen(false);

  const [showModalEleven, setShowModalEleven] = useState(false);
  const handleShowEleven = () => setShowModalEleven(true);
  const handleCloseEleven = () => setShowModalEleven(false);

  const [showModalTwelve, setShowModalTwelve] = useState(false);
  const handleShowTwelve = () => setShowModalTwelve(true);
  const handleCloseTwelve = () => setShowModalTwelve(false);

  const [showModalThirteen, setShowModalThirteen] = useState(false);
  const handleShowThirteen = () => setShowModalThirteen(true);
  const handleCloseThirteen = () => setShowModalThirteen(false);

  const handleSutaRateChange = (e, index, state_id, field = "") => {
    const list = [...sutaList];
    list[index] = { state: state_id, value: e.target.value };
    setSutaList(list);
  };
  const handleInput = (evt) => {
    const name = evt.target.name;
    const newValue = evt.target.value;
    setFormInput({ [name]: newValue });
  };

  const changeOptionValue = (value) => {
    setOptionValue(value);
    if (value === "flat") {
      setOtherCostModalLabel2("Cost");
      setOtherCostModalLabel3(false);
    } else {
      setOtherCostModalLabel2("Percent");
      setOtherCostModalLabel3(true);
    }
  };
  const isRateValid = function (suta_rate) {
    let rate = suta_rate.replace("%");
    const reqExp = /^(100(\.00?)?|[1-9]?\d(\.\d\d?)?)$/;
    return reqExp.test(parseFloat(rate));
  };

  const isCodeValid = function (wc_code) {
    const reqExp = /^\d+$/;
    return reqExp.test(wc_code);
  };
  const isStateValid = function (state) {
    if (states.find((item) => item.name === state)) return true;
    else return false;
  };
  const isIndustryValid = function (industry) {
    if (industries.find((item) => item.name === industry)) return true;
    else return false;
  };
  const handleFileInput = (e, name) => {
    let config;
    if (name === "import_file") {
      config = {
        headers: [
          {
            name: "State",
            inputName: "state",
            required: true,
            unique: true,
            uniqueError: function (headerName) {
              return `Duplicate ${headerName} exist in csv.`;
            },
            validate: function (state) {
              return isStateValid(state);
            },
            validateError: function (headerName, rowNumber, columnNumber) {
              return `${headerName} is not valid in the ${rowNumber} row / ${columnNumber} column`;
            },
          },
          {
            name: "SUTA Rate",
            inputName: "suta_rate",
            required: true,
            validate: function (suta_rate) {
              return isRateValid(suta_rate);
            },
            validateError: function (headerName, rowNumber, columnNumber) {
              return `${headerName} is not valid in the ${rowNumber} row / ${columnNumber} column`;
            },
          },
        ],
      };
    } else if (name === "import_file_wc") {
      config = {
        headers: [
          {
            name: "State",
            inputName: "state",
            required: true,
            validate: function (state) {
              return isStateValid(state);
            },
            validateError: function (headerName, rowNumber, columnNumber) {
              return `${headerName} is not valid in the ${rowNumber} row / ${columnNumber} column`;
            },
          },
          {
            name: "Nickname",
            inputName: "nick_name",
            required: true,
          },
          {
            name: "Industry (Clerical, Light Industrial, Healthcare, Industrial, Other (custom)",
            inputName: "industry",
            required: true,
            validate: function (industry) {
              return isIndustryValid(industry);
            },
            validateError: function (headerName, rowNumber, columnNumber) {
              return `${headerName} is not valid in the ${rowNumber} row / ${columnNumber} column`;
            },
          },
          {
            name: "WC Code",
            inputName: "wc_code",
            required: true,
            validate: function (wc_code) {
              return isCodeValid(wc_code);
            },
            validateError: function (headerName, rowNumber, columnNumber) {
              return `${headerName} is not valid in the ${rowNumber} row / ${columnNumber} column`;
            },
          },
          {
            name: "WC Rate (%)",
            inputName: "wc_rate",
            required: true,
            validate: function (wc_rate) {
              return isRateValid(wc_rate);
            },
            validateError: function (headerName, rowNumber, columnNumber) {
              return `${headerName} is not valid in the ${rowNumber} row / ${columnNumber} column`;
            },
          },
        ],
      };
    }
    setDisabledCSV(true);
    setSutaImportError([]);
    setFormInput({ ["import_file"]: e.target.files[0] });

    CSVFileValidator(e.target.files[0], config)
      .then((csvData) => {
        if (csvData.inValidMessages && csvData.inValidMessages.length > 0) {
          setSutaImportError(csvData.inValidMessages);
        } else {
          setFormInput({ ["import_data"]: csvData.data });
          setDisabledCSV(false);
        }
      })
      .catch((err) => {});
  };
  let ErrorlistItems = "";
  if (sutaImportError) {
    ErrorlistItems = sutaImportError.map((err) => (
      <Alert variant="danger">{err}</Alert>
    ));
  }
  const handleInputChange = (e, index, opt = "") => {
    let name, value;
    if (opt !== "") {
      name = opt;
      value = e.value;
    } else {
      name = e.target.name;
      value = e.target.value;
    }

    const list = [...inputList];
    list[index][name] = value;
    if (opt === "industry") list[index]["industry_label"] = e.label;
    setInputList(list);
  };
  const radios = [
    { name: "from Revenue", value: "1" },
    { name: "from Total Wage", value: "2" },
  ];

  const costOptions = [
    { name: "Flat", value: "flat" },
    { name: "Percentage", value: "percentage" },
  ];
  const handleAddClick = () => {
    validator.hideMessages();
    forceUpdate(0);
    setInputList([
      ...inputList,
      {
        nick_name: "",
        industry_label: industriesOptions[0].label,
        industry: industriesOptions[0].value,
        code: "",
        rate: "",
      },
    ]);
  };

  const renderOtherCost = (index) => {
    if (
      index === "custom_cost" &&
      organizationSetting &&
      organizationSetting.costs &&
      organizationSetting.costs[index]
    ) {
      let list = organizationSetting.costs[index].map((element) => {
        let itemList = organizationSetting.costs[
          element.name.toLowerCase().replaceAll(" ", "_")
        ].map((item) => {
          return (
            <div className="d-flex justify-content-between border mb-3 custom-list align-items-center">
              <div>{item.name}</div>
              <div>
                {item.cost_option === "flat" ? (
                  <div className="d-inline-block">
                    <span className="text-success mr-1">$</span>
                    {`${item.percent}`}
                  </div>
                ) : (
                  <div className="d-inline-block">
                    {`${item.percent}`}
                    <span className="text-info mr-1">%</span>{" "}
                    {`${radios.find(({ value }) => value === item.from).name}`}
                  </div>
                )}
                <Button
                  variant="outline-dark"
                  className="ml-4 btn-edit"
                  onClick={() =>
                    handleOtherCost("add_custom_cost_list", element, 1, item)
                  }
                >
                  <div className="icon">
                    <img
                      src={EditIcon}
                      alt="Edit Icon"
                      className="icon-black"
                    />
                    <img
                      src={EditWhiteIcon}
                      alt="Edit Icon"
                      className="icon-white"
                    />
                  </div>
                  Edit
                </Button>
                <Button
                  variant="outline-danger"
                  className="ml-3"
                  onClick={() => handleOtherCost("delete", element, 1, item)}
                >
                  Delete
                </Button>
              </div>
            </div>
          );
        });

        return (
          <div className="button-options">
            <div className="sub-title">{element.name}</div>
            {itemList}
            <Button
              variant="light"
              block
              className="btn-add-states"
              onClick={() =>
                handleOtherCost("add_custom_cost_list", element, 1)
              }
            >
              <BsPlus /> Add {element.name}
            </Button>
          </div>
        );
      });
      return <div className="drug-screen-cost-list">{list}</div>;
    } else if (
      (index === "interest" ||
        index === "payrolling_cost" ||
        index === "vendor_management_system" ||
        index === "liability_insurance" ||
        index === "manage_service_provider") &&
      organizationSetting &&
      organizationSetting.costs &&
      organizationSetting.costs[index]
    ) {
      let list = organizationSetting.costs[index].map((element) => {
        return (
          <div className="d-flex justify-content-between border mb-3 custom-list align-items-center">
            <div>{element.name}</div>
            <div>
              {element.percent}
              <span className="text-info mr-1">%</span>{" "}
              {radios.find(({ value }) => value === element.from).name}
              <Button
                variant="outline-dark"
                className="ml-3 btn-edit"
                onClick={() => handleOtherCost("add_" + index, element)}
              >
                <div className="icon">
                  <img src={EditIcon} alt="Edit Icon" className="icon-black" />
                  <img
                    src={EditWhiteIcon}
                    alt="Edit Icon"
                    className="icon-white"
                  />
                </div>
                Edit
              </Button>
              <Button
                variant="outline-danger"
                className="ml-3"
                onClick={() => handleOtherCost("delete", element)}
              >
                Delete
              </Button>
            </div>
          </div>
        );
      });
      return <div className="drug-screen-cost-list">{list}</div>;
    } else if (
      (index === "background_check_cost" || index === "drug_screen_cost") &&
      organizationSetting &&
      organizationSetting.costs &&
      organizationSetting.costs[index]
    ) {
      let list = organizationSetting.costs[index].map((element) => {
        return (
          <div className="d-flex justify-content-between border mb-3 custom-list align-items-center">
            <div>{element.name}</div>
            <div>
              <span className="text-success mr-1">$</span>
              {element.percent}
              <Button
                variant="outline-dark btn-edit"
                className="ml-4"
                onClick={() => handleOtherCost("add_" + index, element)}
              >
                <div className="icon">
                  <img src={EditIcon} alt="Edit Icon" className="icon-black" />
                  <img
                    src={EditWhiteIcon}
                    alt="Edit Icon"
                    className="icon-white"
                  />
                </div>
                Edit
              </Button>
              <Button
                variant="outline-danger"
                className="ml-3"
                onClick={() => handleOtherCost("delete", element)}
              >
                Delete
              </Button>
            </div>
          </div>
        );
      });
      return <div className="drug-screen-cost-list">{list}</div>;
    }
  };
  const confirm_delete_cost = async () => {
    if (organizationSetting && organizationSetting.costs) {
      let organization = settings.data.organization_users[0].organization;
      let data = {
        organization: organization.id,
        // option: "cost_delete",
        data: {
          id: costRow,
          cost_list_id: costListRow,
        },
      };

      let result = await costDeleteOrganizationSettingAttempt(
        JSON.stringify(data)
      );
      if (result.status === 200) {
        setOrganizationSetting(result.data);
        setCostRow(0);
        setCostListRow(0);
        setShowModalThirteen(false);
        toast.success(Constants.update_message);
      } else {
        if (localStorage.getItem("expire")) {
          setIsPaymentShowing(true);
          console.log("expire", localStorage.getItem("expire"));
        }
      }
      setDisabled(false);
    }
  };
  const handleOtherCost = async (index, row = "", list = 0, item = "") => {
    if (
      index === "add_payrolling_cost" ||
      index === "add_manage_service_provider" ||
      index === "add_vendor_management_system" ||
      index === "add_custom_cost" ||
      index === "add_custom_cost_list" ||
      index === "add_liability_insurance" ||
      index === "add_interest"
    ) {
      validator.hideMessages();
      forceUpdate(0);
      setOtherCostModalLabel4(false);
      setOtherCostModalLabel5(false);
      setOtherCostModalLabel3(true);
      setIndustryValue(null);
      if (index === "add_interest") {
        setOtherCostModalTitle("Add Interest");
        setOtherCostModalLabel1("Interest cost name");
        setOtherCostModalLabel2("Percent");
      } else if (index === "add_payrolling_cost") {
        setOtherCostModalTitle("Add Payrolling Cost");
        setOtherCostModalLabel1("Payrolling Cost name");
        setOtherCostModalLabel2("Percent");
      } else if (index === "add_manage_service_provider") {
        setOtherCostModalTitle("Add Managed Service Provider");
        setOtherCostModalLabel1("Managed Service Provider name");
        setOtherCostModalLabel2("Percent");
      } else if (index === "add_vendor_management_system") {
        setOtherCostModalTitle("Add Vendor Management System");
        setOtherCostModalLabel1("VMS name");
        setOtherCostModalLabel2("Percent");
      } else if (index === "add_liability_insurance") {
        setOtherCostModalTitle("Add Liability Insurance");
        setOtherCostModalLabel1("Liability Insurance name");
        setOtherCostModalLabel2("Percent");
        setOtherCostModalLabel5(true);
        setIndustryValue(industriesOptions[0]);
      } else if (index === "add_custom_cost") {
        setOtherCostModalTitle("Create custom other cost");
        setOtherCostModalLabel1("Cost title");
        setOtherCostModalLabel2("");
        setOtherCostModalLabel3(false);
      } else if (index === "add_custom_cost_list") {
        setOtherCostModalTitle("Add " + row.name);
        setOtherCostModalLabel1(row.name + " name");
        setOtherCostModalLabel4(true);
        setOtherCostModalLabel2("Cost");
        setOtherCostModalLabel3(false);
        setOtherCostModalLabel5(true);
        setIndustryValue(industriesOptions[0]);
      }

      if (row === "" || (list === 1 && row.percent === null && item === "")) {
        setFormInput({
          ["name"]: "",
        });
        setFormInput({
          ["percent"]: "",
        });
        setRadioValue("1");
      } else {
        setFormInput({
          ["name"]: row.name,
        });
        setFormInput({
          ["percent"]: row.percent,
        });
        setRadioValue(row.from);
        setCostRow(row.id);
        setIndustryValue(
          industriesOptions.find(({ value }) => value === row.industry_id)
        );
      }

      if (list === 1 && row.percent === null) {
        setCostRow(row.id);
      }
      if (item !== "") {
        setFormInput({
          ["name"]: item.name,
        });
        setFormInput({
          ["percent"]: item.percent,
        });

        setOptionValue(item.cost_option);
        if (item.cost_option !== "flat") {
          setOtherCostModalLabel3(true);
        }
        setRadioValue(item.from);
        setCostRow(row.id);
        setCostListRow(item.id);
        setIndustryValue(
          industriesOptions.find(({ value }) => value === item.industry_id)
        );
      }
      console.log(item);
      setCostType(index.replace("add_", ""));
      setShowModalNine(true);
    } else if (
      index === "add_background_check_cost" ||
      index === "add_drug_screen_cost"
    ) {
      validator.hideMessages();
      forceUpdate(0);
      setOtherCostModalLabel4(false);
      setOtherCostModalLabel5(false);
      setIndustryValue(null);
      if (index === "add_background_check_cost") {
        setOtherCostModalTitle("Add background check cost");
        setOtherCostModalLabel1("BC name");
        setOtherCostModalLabel2("Cost");
      } else if (index === "add_drug_screen_cost") {
        setOtherCostModalTitle("Add drug screen cost");
        setOtherCostModalLabel1("Drug screen name");
        setOtherCostModalLabel2("Cost");
      }
      setOtherCostModalLabel3(false);
      if (row === "") {
        setFormInput({
          ["name"]: "",
        });
        setFormInput({
          ["percent"]: "",
        });
      } else {
        setFormInput({
          ["name"]: row.name,
        });
        setFormInput({
          ["percent"]: row.percent,
        });
        setCostRow(row.id);
      }
      setCostType(index.replace("add_", ""));
      setShowModalNine(true);
    } else if (index === "update_cost") {
      setDisabled(true);
      var valid = 1;
      if (costType === "custom_cost" && !validator.fieldValid("name")) {
        valid = 0;
        console.log("here1", costType);
      } else if (otherCostModalLabel2 === "Cost") {
        if (
          costType !== "custom_cost" &&
          (!validator.fieldValid("name") || !validator.fieldValid("cost"))
        ) {
          valid = 0;
        }
      } else if (
        costType !== "custom_cost" &&
        (!validator.fieldValid("name") || !validator.fieldValid("percent"))
      ) {
        valid = 0;
        console.log(
          "here2233",
          costType,
          !validator.fieldValid("percent"),
          !validator.fieldValid("cost")
        );
      }
      if (!valid) {
        validator.showMessages();
        forceUpdate(1);
        setDisabled(false);
      } else {
        let organization = settings.data.organization_users[0].organization;
        let industryData = null;
        if (otherCostModalLabel5) {
          industryData = industryValue.value;
        }
        let data = {
          organization: organization.id,
          // option: "update_cost",
          data: {
            id: costRow,
            cost_list_id: costListRow,
            cost_name: formInput.name,
            percent: formInput.percent,
            is_from: otherCostModalLabel3,
            from: radioValue,
            cost_option: optionValue,
            cost_type: costType,
            industry: industryData,
          },
        };

        let result = await updateCostOrganizationSettingAttempt(
          JSON.stringify(data)
        );
        if (result.status === 200) {
          setOrganizationSetting(result.data);
          setShowModalNine(false);

          setCostRow(0);
          setIndustryValue(null);
          setCostListRow(0);
          toast.success(Constants.update_message);
        } else {
          toast.error("Cost name already exists");

          if (localStorage.getItem("expire")) {
            setIsPaymentShowing(true);
            console.log("expire", localStorage.getItem("expire"));
          }
        }
        setDisabled(false);
      }
    } else if (index === "delete") {
      setCostRow(row.id);
      if (item !== "") setCostListRow(item.id);
      setDeleteItem("cost");
      setShowModalThirteen(true);
    }
    console.log(index);
  };
  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const edit_package = (i) => {
    if (
      organizationSetting &&
      organizationSetting.packages &&
      organizationSetting.packages[i]
    ) {
      setPackageRow(organizationSetting.packages[i].id);
      setFormInput({
        ["package_name"]: organizationSetting.packages[i].package_name,
      });
      setFormInput({
        ["employee_only"]: organizationSetting.packages[i].employee_only,
      });
      setFormInput({
        ["employee_spouse"]: organizationSetting.packages[i].employee_spouse,
      });
      setFormInput({
        ["employee_dependent"]:
          organizationSetting.packages[i].employee_dependent,
      });

      setFormInput({
        ["family"]: organizationSetting.packages[i].family,
      });
      setShowModalSix(true);
    }
  };

  const delete_package = (i) => {
    if (
      organizationSetting &&
      organizationSetting.packages &&
      organizationSetting.packages[i]
    ) {
      setPackageRow(organizationSetting.packages[i].id);
      setDeleteItem("healthcare package");
      setShowModalThirteen(true);
    }
  };
  const confirm_delete_package = async () => {
    if (organizationSetting && organizationSetting.packages) {
      let organization = settings.data.organization_users[0].organization;

      let data = {
        organization: organization.id,
        // option: "package_delete",
        data: {
          id: packageRow,
        },
      };

      let result = await packageDeleteOrganizationSettingAttempt(
        JSON.stringify(data)
      );
      if (result.status === 200) {
        setOrganizationSetting(result.data);
        setShowModalThirteen(false);
        toast.success(Constants.update_message);
      } else {
        if (localStorage.getItem("expire")) {
          setIsPaymentShowing(true);
          console.log("expire", localStorage.getItem("expire"));
        }
      }

      setDisabled(false);
    }
  };
  const handleTaxStatus = async (evt, row, index) => {
    const list = [...sptoList];
    let index2 =  list.findIndex((item) => item.id === row.id)
    list[index2].tax_status = evt.target.checked;
    setSPTOList(list);   
    
    let organization = settings.data.organization_users[0].organization;

    let data = {
      organization: organization.id,
      option: "tax_status",
      id: row.id,
      value: evt.target.checked,
    };

    let result = await taxStatusOrganizationSettingAttempt(
      JSON.stringify(data)
    );
    if (result.status === 200) {
      setOrganizationSetting(result.data);
      toast.success(Constants.update_message);
    } else {
      const list = [...sptoList];
      list[index].tax_status = !evt.target.checked;
      setSPTOList(list);

      if (localStorage.getItem("expire")) {
        setIsPaymentShowing(true);
        console.log("expire", localStorage.getItem("expire"));
      }
    }
  };
  const handleShow = async (evt, index, st = "", i = 0) => {
    validator.hideMessages();
    forceUpdate(0);
    if (index === "add_package") {
      setDisabled(true);
      var valid = 1;

      if (
        !validator.fieldValid("package_name") ||
        !validator.fieldValid("employee_only") ||
        !validator.fieldValid("employee_spouse") ||
        !validator.fieldValid("employee_dependent") ||
        !validator.fieldValid("family")
      ) {
        valid = 0;
      }
      if (!valid) {
        validator.showMessages();
        forceUpdate(1);
        setDisabled(false);
      } else {
        let organization = settings.data.organization_users[0].organization;

        let data = {
          organization: organization.id,
          // option: "package",
          data: {
            id: packageRow,
            package_name: formInput.package_name,
            employee_only: formInput.employee_only,
            employee_spouse: formInput.employee_spouse,
            employee_dependent: formInput.employee_dependent,
            family: formInput.family,
          },
        };

        let result = await packageOrganizationSettingAttempt(
          JSON.stringify(data)
        );
        if (result.status === 200) {
          setOrganizationSetting(result.data);
          setShowModalSix(false);
          toast.success(Constants.update_message);

        } else {
          if (localStorage.getItem("expire")) {
            setIsPaymentShowing(true);
            console.log("expire", localStorage.getItem("expire"));
          }
        }
        setDisabled(false);
      }
    } else if (index === "update_spto") {
      setDisabled(true);
      var valid = 1;

      if (
        !validator.fieldValid("accural_rate") ||
        !validator.fieldValid("accural_cap_per_year") ||
        !validator.fieldValid("total_cap")
      ) {
        valid = 0;
      }
      if (!valid) {
        validator.showMessages();
        forceUpdate(1);
        setDisabled(false);
      } else {
        let organization = settings.data.organization_users[0].organization;

        let data = {
          organization: organization.id,
          // option: "update_spto",
          data: {
            id: sptoRow.id,
            accural_rate: formInput.accural_rate,
            accural_cap_per_year: formInput.accural_cap_per_year,
            total_cap: formInput.total_cap,
          },
        };

        let result = await updateSptoOrganizationSettingAttempt(
          JSON.stringify(data)
        );
        if (result.status === 200) {
          setOrganizationSetting(result.data);
          setShowModalSeven(false);
          toast.success(Constants.update_message);
        } else {
          if (localStorage.getItem("expire")) {
            setIsPaymentShowing(true);
            console.log("expire", localStorage.getItem("expire"));
          }
        }

        setDisabled(false);
      }
    } else if (index === "import_wc") {
      setDisabledCSV(true);
      let organization = settings.data.organization_users[0].organization;

      let data = {
        organization: organization.id,
        // option: "import_wc",
        data: formInput.import_data,
      };

      let result = await importWcOrganizationSettingAttempt(
        JSON.stringify(data)
      );
      if (result.status === 200) {
        setOrganizationSetting(result.data);
        setShowModalFour(false);
        toast.success(Constants.update_message);
      } else {
        evt.target.checked = organization.always_accrue;

        if (localStorage.getItem("expire")) {
          setIsPaymentShowing(true);
          console.log("expire", localStorage.getItem("expire"));
        }
      }
      setDisabledCSV(false);
    } else if (index === "import_suta") {
      setDisabledCSV(true);
      let organization = settings.data.organization_users[0].organization;

      let data = {
        organization: organization.id,
        // option: "import_suta",
        data: formInput.import_data,
      };

      let result = await importSutaOrganizationSettingAttempt(
        JSON.stringify(data)
      );
      if (result.status === 200) {
        setOrganizationSetting(result.data);
        setShowModalTwo(false);
        setStep("");
        toast.success(Constants.update_message);
      } else {
        if (localStorage.getItem("expire")) {
          setIsPaymentShowing(true);
          console.log("expire", localStorage.getItem("expire"));
        }
      }

      setDisabledCSV(false);
    } else if (index === "update_wc_settings") {
      setDisabled(true);
      var valid = 1;

      inputList.forEach((element, ind) => {
        if (
          !validator.fieldValid("code_" + ind) ||
          !validator.fieldValid("nick_name_" + ind) ||
          !validator.fieldValid("rate_" + ind)
        ) {
          valid = 0;
        }
      });

      if (!valid) {
        validator.showMessages();
        forceUpdate(1);
        setDisabled(false);
      } else {
        let organization = settings.data.organization_users[0].organization;

        let data = {
          organization: organization.id,
          state: activeState,
          // option: "wc_settings",
          wc_codes: inputList,
        };
        let result = await wcSettingsOrganizationSettingAttempt(
          JSON.stringify(data)
        );
        if (result.status === 200) {
          setOrganizationSetting(result.data);
          setShowModalFour(false);
          toast.success(Constants.update_message);
        } else {
          evt.target.checked = organization.always_accrue;

          if (localStorage.getItem("expire")) {
            setIsPaymentShowing(true);
            console.log("expire", localStorage.getItem("expire"));
          }
        }

        setDisabled(false);
      }
    } else if (index === "update_sales_tax") {
      setDisabled(true);
      var valid = 1;

      if (!validator.fieldValid("tax")) {
        valid = 0;
      }
      if (!valid) {
        validator.showMessages();
        forceUpdate(1);
        setDisabled(false);
      } else {
        let organization = settings.data.organization_users[0].organization;

        let data = {
          organization: organization.id,
          id: activeSalesTax,
          state: activeState,
          tax: formInput.tax,
          tax_type: formInput.tax_type,
          // option: "sales_tax",
        };
        let result = await salesTaxOrganizationSettingAttempt(
          JSON.stringify(data)
        );
        if (result.status === 200) {
          setOrganizationSetting(result.data);
          setShowModalFive(false);
          toast.success(Constants.update_message);
        } else {
          if (localStorage.getItem("expire")) {
            setIsPaymentShowing(true);
            console.log("expire", localStorage.getItem("expire"));
          }
        }
        setDisabled(false);
      }
    } else if (index === "edit_sales_tax") {
      console.log("edit_sales_tax", st.rate);
      setStep(index);
      setActiveState(st);

      if (taxOptions.length > 0) {
        setFormInput({ ["tax_type"]: taxOptions[0].value });
        setTaxSelect(taxOptions[0]);
      }
      if (st && st.id !== null) {
        console.log("st", st);
        setStateTitle(st.state);
        setActiveSalesTax(st.id);
        setFormInput({ ["city"]: st.city });
        if (st.rate !== null) {
          setFormInput({ ["tax"]: st.rate.replace("%", "") });
        } else {
          setFormInput({ ["tax"]: "" });
        }
        if (st.tax_type !== null) {
          let row = {
            label: st.tax_type,
            value: st.tax_type,
          };
          setFormInput({
            ["tax_type"]: row,
          });
          setTaxSelect(row);
        }
      } else {
        setFormInput({ ["tax"]: "" });
        setFormInput({ ["tax_type"]: taxOptions[0].value });
        setTaxSelect(taxOptions[0]);
      }

      setShowModalFive(true);
    } else if (index === "skip_wc") {
      setDisabledCSV(true);
      setStep(index);
      setShowModalFour(true);
    } else if (index === "wc_settings") {
      var i = statesList.indexOf(st);
      if (i !== -1) {
        if (sutaList[i] && sutaList[i].wc_settings) {
          const list = [];
          sutaList[i].wc_settings.forEach((element, index) => {
            list[index] = {
              nick_name: element.nick_name,
              industry_label: industriesOptions.find(
                ({ value }) => value === element.industry
              ).label,
              industry: element.industry,
              code: element.code,
              rate: element.rate,
            };
          });
          setInputList(list);
        } else {
          setInputList([]);
        }
      }
      console.log("sutaList[i]");
      if (
        sutaList[i] &&
        sutaList[i].state &&
        statesInfo[sutaList[i].state] &&
        statesInfo[sutaList[i].state].name
      )
        setStateTitle(statesInfo[sutaList[i].state].name);
      setActiveState(st);
      setStep(index);
      setShowModalFour(true);
    } else if (index === "wc_codes_import") {
      setStep(index);
      setShowModalFour(true);
    } else if (index === "submit_states") {
      setDisabled(true);
      var valid = 1;

      statesList.forEach((element, ind) => {
        if (!validator.fieldValid("suta_rate_" + ind)) {
          valid = 0;
        }
      });

      console.log("sutaList", valid, sutaList);

      if (!valid) {
        validator.showMessages();
        forceUpdate(1);
        setDisabled(false);
      } else {
        let organization = settings.data.organization_users[0].organization;
        let tempSutaList = [];
        var count = 0;
        sutaList.map((st) => {
          if (statesList.includes(st.state)) {
            tempSutaList[count] = st;
            count = count + 1;
          }
        });
        let data = {
          organization: organization.id,
          sutaList: tempSutaList,
          option: "suta",
        };
        let result = await sutaOrganizationSettingAttempt(JSON.stringify(data));
        if (result.status === 200) {
          setOrganizationSetting(result.data);
          toast.success(Constants.update_message);
          handleTabChange();
          setTabActiveKey("workercomcodes");
        } else {
          if (localStorage.getItem("expire")) {
            setIsPaymentShowing(true);
            console.log("expire", localStorage.getItem("expire"));
          }
        }
        setDisabled(false);
        if (sutaList.length === 0) setTab("");
        if(localStorage.getItem("calculatorDemo") === "true"){
          localStorage.setItem("getStarted", true);
          // window.location = '/settings/calculation-settings';
        }
      }
    } else if (index === "add_states") {
      setTab(index);
      setShowModalOne(false);
    } else if (index === "skip") {
      setDisabledCSV(true);
      setStep(index);
    } else {
      setStep(index);
      setShowModalTwo(true);
    }
  };
  const handleStatesChange = (e, index) => {
    let value = e.target.value;
    const list = [...statesList];
    const slist = [...sutaList];

    if (list.indexOf(parseInt(value)) !== -1) {
      let newArray = list.filter((ar) => {
        return ar !== parseInt(value);
      });
      const suta = [];
      var i = 0;
      slist.forEach((element, index) => {
        if (element && element.state && element.state !== parseInt(value)) {
          suta[i] = element;
          i++;
        }
      });
      setSutaList(suta);
      setStatesList(newArray);
    } else {
      list.push(parseInt(value));
      setStatesList(list);
    }
    // validator.purgeFields();
  };

  const handleTabChange = async () => {
    if (
      settings.data &&
      settings.data.organization_users &&
      settings.data.organization_users.length !== 0 &&
      settings.data.organization_users[0].organization
    ) {
      let organization = settings.data.organization_users[0].organization;

      let tempSutaList = [];
      var count = 0;
      sutaList.map((st) => {
        if (statesList.includes(st.state)) {
          tempSutaList[count] = st;
          count = count + 1;
        }
      });

      if (
        settings.data &&
        settings.data.organization_users &&
        settings.data.organization_users.length !== 0 &&
        settings.data.organization_users[0].organization
      ) {
        let organization = settings.data.organization_users[0].organization;
        let data = {
          organization: organization.id,
        };

        let result = await getOrganizationSettingAttempt(JSON.stringify(data));
        if (result.status === 200) {
          setOrganizationSetting(result.data);
        } else {
          if (localStorage.getItem("expire")) {
            setIsPaymentShowing(true);
            console.log("expire", localStorage.getItem("expire"));
          }
        }
      }
    }
  };
  const handleAllStatesChange = () => {
    if (checkedAll) {
      setStatesList([]);
      setCheckedAll(false);
    } else {
      let newArray = states.map((ar) => {
        return ar.id;
      });
      setCheckedAll(true);
      setStatesList(newArray);
    }
  };
  useEffect(() => {
    if (localStorage.getItem("expire")) {
      setIsPaymentShowing(true);
      console.log("expire", localStorage.getItem("expire"));
    }
    let params = queryString.parse(window.location.search);
    if(params?.tutorial === "1")
    {
      setShowModalTutorial(true);
    }
    console.log('tutorial',params?.tutorial)
  });
  useEffect(() => {
    if (settings.expire && settings.expire === true) {
      setIsPaymentShowing(true);
      console.log("expire", localStorage.getItem("expire"));
    }
  }, [settings]);
  useEffect(() => {
    async function fetchStates() {
      setStates(await getStatesAttempt(Constants.default_county.value));
    }
    fetchStates();

    async function fetchCities() {
      setCities(await getCitiesAttempt());
    }
    fetchCities();

    async function fetchSettings() {
      setSettings(await getSettings());
    }
    fetchSettings();
    async function fetchIndustries() {
      setIndustries(await getIndustriesAttempt());
    }
    fetchIndustries();
    async function fetchBillSettings() {
      setBillSettings(await getBillSettings());
    }
    fetchBillSettings();
  }, []);
  useEffect(() => {
    if (states && states.length !== 0) {
      let newArray = [];
      states.map((st) => {
        newArray[st.id] = st;
      });
      setStatesInfo(newArray);
    }
  }, [states]);

  useEffect(() => {
    if (sutaList && sutaList.length !== 0) {
      let organization = settings.data.organization_users[0].organization;
      // sutaList.map((st) => {
        let data = {
          organization: organization.id,
          // state_id: st.state,
        };

        async function fetchSPTO() {
          let spto = await getSPTOSettings(JSON.stringify(data));
          if (spto && spto.spto_settings) {
            setSPTOList(spto.spto_settings);
          }
        }
        if(tabActiveKey === "spto" || tabActiveKey === "stax")
          fetchSPTO();
      // });
    }
  }, [sutaList]);
  
  useEffect(() => {
    if (cities && cities.length !== 0) {
      let newArray = [];
      cities.map((ct) => {
        if (newArray[ct.state_id]) {
          newArray[ct.state_id].push({ label: ct.name, value: ct.id });
        } else {
          newArray[ct.state_id] = [{ label: ct.name, value: ct.id }];
        }
      });
      setCitiesInfo(newArray);
    }
  }, [cities]);

  useEffect(() => {
    if (
      organizationSetting &&
      organizationSetting.organization_settings &&
      organizationSetting.organization_settings.length > 0
    ) {
      const list = [];
      const suta = [];
      organizationSetting.organization_settings.map((state, index) => {
        list.push(parseInt(state.state_id));
        suta[index] = {
          state: state.state_id,
          value: state.suta_rate,
          tax: state.sales_tax,
          tax_type: state.sales_tax_type,
          wc_settings: state.wc_settings,
        };
      });
      setStatesList(list);
      setSutaList(suta);
      setTab("add_states");
    }
  }, [organizationSetting]);
  useEffect(() => {
    async function fetchOrganizationSetting() {
      if (
        settings.data &&
        settings.data.organization_users &&
        settings.data.organization_users.length !== 0 &&
        settings.data.organization_users[0].organization
      ) {
        let organization = settings.data.organization_users[0].organization;
        let data = {
          organization: organization.id,
        };
        let result = await getOrganizationSettingAttempt(JSON.stringify(data));
        if (result.status === 200) {
          setOrganizationSetting(result.data);
        } else {
          if (localStorage.getItem("expire")) {
            setIsPaymentShowing(true);
            console.log("expire", localStorage.getItem("expire"));
          }
        }
      }
    }

    fetchOrganizationSetting();
  }, [settings]);

  const handleFocus = (e) => {
    e.target.parentNode.classList.add("active");
  };
  const handleBlur = (e) => {
    e.target.parentNode.classList.remove("active");
  };
  const handleSelect = async (info, selectName) => {
    const name = selectName;
    const newValue = info.value;
    setFormInput({ [name]: newValue });
    setTaxSelect({ label: newValue, value: newValue });
  };

  const renderHealthcare = () => {
    let packageListItems = "";
    let btn_text = "Add Package";
    if (packageRow !== 0) {
      btn_text = "Update Package";
    }
    // console.log("organizationSetting.packages", organizationSetting);
    if (
      organizationSetting &&
      organizationSetting.packages &&
      organizationSetting.packages.length > 0
    ) {
      packageListItems = organizationSetting.packages.map((element, index) => {
        return (
          <div key={index} className="p-list">
            <ul className="list-unstyled d-flex align-items-center">
              <li className="d-flex">{element.package_name}</li>
              <li>
                <Button
                  variant="outline-dark"
                  className="edit-package"
                  onClick={() => edit_package(index)}
                >
                  <div className="icon">
                    <img
                      src={EditIcon}
                      alt="Edit Icon"
                      className="icon-black"
                    />
                    <img
                      src={EditWhiteIcon}
                      alt="Edit Icon"
                      className="icon-white"
                    />
                  </div>
                  Edit
                </Button>
                <Button
                  onClick={() => delete_package(index)}
                  variant="outline-dark"
                  className="edit-package-close ml-2"
                >
                  <RiCloseLine />
                </Button>
              </li>
            </ul>
          </div>
        );
      });
    }

    return (
      <div>
        <div className="added-states healthcare-packages">
          <div className="p-lists">{packageListItems}</div>
        </div>

        <Modal
          animation={false}
          className="add-states-modal"
          size="sm"
          centered
          show={showModalSix}
          onHide={handleCloseSix}
        >
          <Modal.Header closeButton className="with-title normal">
            <Modal.Title>Add Healthcare Package</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Package Name</Form.Label>
              <FormControl
                id="package_name"
                name="package_name"
                value={formInput.package_name}
                onChange={handleInput}
              />
              {validator.message(
                "package_name",
                formInput.package_name,
                "required"
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label>Employee Only Monthly Premium</Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>$</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  id="employee_only"
                  name="employee_only"
                  value={formInput.employee_only}
                  onChange={handleInput}
                  onFocus={(e) => e.target.parentNode.classList.add("active")}
                  onBlur={(e) => {
                    e.target.parentNode.classList.remove("active");
                  }}
                />
              </InputGroup>
              {validator.message(
                "employee_only",
                formInput.employee_only,
                "required|numeric|min:0,num"
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label>Employee + Spouse Monthly Premium</Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>$</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  id="employee_spouse"
                  name="employee_spouse"
                  value={formInput.employee_spouse}
                  onChange={handleInput}
                  onFocus={(e) => e.target.parentNode.classList.add("active")}
                  onBlur={(e) => {
                    e.target.parentNode.classList.remove("active");
                  }}
                />
              </InputGroup>
              {validator.message(
                "employee_spouse",
                formInput.employee_spouse,
                "required|numeric|min:0,num"
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label>Employee + Dependent Monthly Premium</Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>$</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  id="employee_dependent"
                  name="employee_dependent"
                  value={formInput.employee_dependent}
                  onChange={handleInput}
                  onFocus={(e) => e.target.parentNode.classList.add("active")}
                  onBlur={(e) => {
                    e.target.parentNode.classList.remove("active");
                  }}
                />
              </InputGroup>
              {validator.message(
                "employee_dependent",
                formInput.employee_dependent,
                "required|numeric|min:0,num"
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label>Family Monthly Premium</Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>$</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  id="family"
                  name="family"
                  value={formInput.family}
                  onChange={handleInput}
                  onFocus={(e) => e.target.parentNode.classList.add("active")}
                  onBlur={(e) => {
                    e.target.parentNode.classList.remove("active");
                  }}
                />
              </InputGroup>
              {validator.message(
                "family",
                formInput.family,
                "required|numeric|min:0,num"
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-light" onClick={handleCloseSix}>
              Cancel
            </Button>
            <Button
              variant="primary"
              disabled={disabled}
              onClick={(e) => handleShow(e, "add_package")}
            >
              {btn_text}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  const renderSTPOAlwaysAccrue = () => {
    if (
      settings.data &&
      settings.data.organization_users &&
      settings.data.organization_users.length !== 0 &&
      settings.data.organization_users[0].organization
    ) {
      let organization = settings.data.organization_users[0].organization;

      return (
        <>
          <div className="toggle-switch">
            <div className="text pr-4">Always Accrue</div>
            <label className="switch m-0">
              <input
                type="checkbox"
                defaultChecked={organization.always_accrue ? true : false}
                onChange={(e) => handleAlwaysAccrue(e)}
              />
              <span className="slider"></span>
            </label>
          </div>
        </>
      );
    }
  };
  const renderSalesTaxExemptAll = () => {
    if (
      settings.data &&
      settings.data.organization_users &&
      settings.data.organization_users.length !== 0 &&
      settings.data.organization_users[0].organization
    ) {
      let organization = settings.data.organization_users[0].organization;

      return (
        <>
          <div className="toggle-switch">
            <div className="text pr-4">Exempt All</div>
            <label className="switch m-0">
              <input
                type="checkbox"
                defaultChecked={organization.exempt_sales_tax ? true : false}
                onChange={(e) => handleSalesTaxExemptAll(e)}
              />
              <span className="slider"></span>
            </label>
          </div>
        </>
      );
    }
  };
  const renderSTPOModalOptions = () => {
    return (
      <Modal
        animation={false}
        className="add-states-salestax-modal add-spto-modal"
        size="lg"
        centered
        show={showModalSeven}
        backdrop="static"
        onHide={handleCloseSeven}
      >
        <Modal.Header closeButton className="with-title normal">
          <Modal.Title>{stateTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <Table responsive="sm">
            <thead>
              <tr>
                <th>State:</th>
                <th>City:</th>
                <th>Accrual Rate:</th>
                <th>Accrual Cap Per Year:</th>
                <th>Total Cap:</th>
              </tr>
            </thead>
            <tbody>
              {sptoRow ? (
                <tr>
                  <td class="py-4">{sptoRow.state}</td>
                  <td class="py-4">{sptoRow.city}</td>
                  <td>
                    <Form.Group className="m-0">
                      <FormControl
                        id="accural_rate"
                        name="accural_rate"
                        value={formInput.accural_rate}
                        onChange={handleInput}
                      />
                      <div className="suta_error">
                        {validator.message(
                          "accural_rate",
                          formInput.accural_rate,
                          "required|numeric|min:0,num|max:100,num"
                        )}
                      </div>
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group className="m-0">
                      <FormControl
                        id="accural_cap_per_year"
                        name="accural_cap_per_year"
                        value={formInput.accural_cap_per_year}
                        onChange={handleInput}
                      />
                      <div className="suta_error">
                        {validator.message(
                          "accural_cap_per_year",
                          formInput.accural_cap_per_year,
                          "required|numeric|min:0,num|max:100,num"
                        )}
                      </div>
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group className="m-0">
                      <FormControl
                        id="total_cap"
                        name="total_cap"
                        value={formInput.total_cap}
                        onChange={handleInput}
                      />
                      <div className="suta_error">
                        {validator.message(
                          "total_cap",
                          formInput.total_cap,
                          "required|numeric|min:0,num|max:100,num"
                        )}
                      </div>
                    </Form.Group>
                  </td>
                </tr>
              ) : null}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-light" onClick={handleCloseSeven}>
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={disabled}
            onClick={(e) => handleShow(e, "update_spto")}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  // When States Changes -> SptoStates Are Captured
  React.useEffect(() => {
    setSptoStatesData(
      statesList.map((s1, index) => {
        return states.find((s2) => s2.id === s1);
      })
    );
  }, [statesList]);

  React.useEffect(() => {
    // console.log(`here`)
    let temp = [];
    temp = sptoList.filter((c, index) => {
      if (c.state == sptoStatesSelect.value) {
        return c;
      }
    });
    let temp2 = temp.map((c, index) => {
      return { value: c.city, label: c.city };
    });
    setSptoStateCitiesName((prev) => [...temp2]);
    // console.log("sptoStateCitiesName => ", sptoStateCitiesName)
  }, [sptoStatesSelect]);

  const renderSelectMenu = () => {
    const sptoStatesName = sptoStatesData.map((std, index) => {
      return { value: std.name, label: std.name };
    });
    const handleSptoStateChange = (e) => {
      if (e == null) setSptoStatesSelect({});
      else setSptoStatesSelect(e);
      handleSptoStateCityChange({});
    };

    const handleSptoStateCityChange = (e) => {
      setSptoStateCitySelect(e);
    };
    const animatedComponents = makeAnimated();

    return (
      <>
        <Container fluid className="d-flex justify-content-between">
          <div className="select-menu-container">
            <Select
              className="select-menu"
              isClearable
              components={animatedComponents}
              name="sptoStatesName"
              options={sptoStatesName}
              styles={customControlStyles}
              classNamePrefix="select State"
              onChange={handleSptoStateChange}
            />
          </div>
          {sptoStatesSelect && sptoStatesSelect.value ? (
            <>
              <div className="select-menu-container ml-3">
                {/* {    console.log("sptoStateCitiesName => ", sptoStateCitiesName)} */}
                <Select
                  className="select-menu"
                  isClearable
                  components={animatedComponents}
                  styles={customControlStyles}
                  name="sptoStateCitiesName"
                  options={sptoStateCitiesName ? sptoStateCitiesName : null}
                  classNamePrefix="select City"
                  onChange={handleSptoStateCityChange}
                />
              </div>
            </>
          ) : null}
        </Container>
      </>
    );
  };

  const renderSptoStatesBasedOnSelection = () => {
    for (let i = 0; i < sptoList.length; i++) {
      if (sptoStateCitySelect && sptoStateCitySelect.value) {
        if (
          sptoList[i].state === sptoStatesSelect.value &&
          sptoList[i].city === sptoStateCitySelect.value
        ) {
          // console.log("1=> ", sptoList[i])
          sptoDataBasedOnSelection.push(sptoList[i]);
        }
      } else {
        if (sptoList[i].state === sptoStatesSelect.value) {
          // console.log("2=> ", sptoList[i])
          sptoDataBasedOnSelection.push(sptoList[i]);
        }
      }
    }
    // console.log("SptoDataBasedOnSelection => ", sptoDataBasedOnSelection)
  };
  const renderSTPOOptions = () => {
    if (Object.keys(sptoStatesSelect).length) {
      renderSptoStatesBasedOnSelection();
    }
    const columns = [
      {
        name: "State:",
        selector: "state",
        sortable: true,
      },
      {
        name: "City:",
        selector: "city",
        sortable: true,
      },
      {
        name: "Accrual Rate:",
        selector: "accural_rate",
        sortable: true,
      },
      {
        name: "Accrual Cap Per Year:",
        selector: "accural_cap_per_year",
        sortable: true,
      },
      {
        name: "Total Cap:",
        selector: "total_cap",
        sortable: true,
      },
      {
        cell: (row) => (
          <Button
            className="btn-icon btn-spto custom-option-cell"
            variant="outline-dark"
            onClick={() => handleShowSeven(row)}
            block
          >
            <div className="icon">
              <img src={EditIcon} alt="Edit Icon" className="icon-black" />
              <img src={EditWhiteIcon} alt="Edit Icon" className="icon-white" />
            </div>
            Edit SPTO
          </Button>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ];
    var msg = Constants.no_data;
    if (statesList && statesList.length !== 0) {
      msg = <div className="pb-5">{Constants.fetching_data}</div>;
    }
    return (
      <DataTable
        columns={columns}
        data={
          Object.keys(sptoStatesSelect).length
            ? sptoDataBasedOnSelection
            : sptoList
        }
        customStyles={customTableStyles}
        responsive
        fixedHeader={true}
        pagination
        noDataComponent={msg}
        paginationPerPage={5}
        paginationRowsPerPageOptions={[5, 15, 25, 50]}
        paginationComponentOptions={{
          rowsPerPageText: "Records per page:",
          rangeSeparatorText: "out of",
        }}
      />
    );
    // if (sptoList && sptoList.length !== 0) {
    //   return sptoList.map((st) => {
    //     return st.map((spto) => {
    //       return (
    //         <tr>
    //           <td>{statesInfo[spto.state_id].name}</td>
    //           <td>
    //             {
    //               citiesInfo[spto.state_id].find(
    //                 ({ value }) => value === spto.city_id
    //               ).label
    //             }
    //           </td>
    //           <td>{spto.accural_rate}</td>
    //           <td>{spto.accural_cap_per_year}</td>
    //           <td>{spto.total_cap}</td>
    //           <td>
    //             <Button
    //               className="btn-icon"
    //               variant="secondary"
    //               onClick={handleShowSeven}
    //               block
    //             >
    //               Edit SPTO
    //             </Button>
    //           </td>
    //         </tr>
    //       );
    //     });
    //   });
    // }
  };
  const renderStatesOptions = () => {
    if (states && states.length !== 0) {
      return states.map((state, index) => {
        return (
          <li key={index}>
            <div className="custom-checkbox">
              <input
                id={"state_" + state.id}
                name="state[]"
                type="checkbox"
                value={state.id}
                checked={statesList.includes(state.id) ? "checked" : ""}
                onChange={(e) => handleStatesChange(e)}
              />
              <label for={"state_" + state.id}>{state.name}</label>
            </div>
          </li>
        );
      });
    }
  };
  const renderWCOptions = () => {
    if (statesList && statesList.length !== 0) {
      return statesList.map((st, index) => {
        return (
          <div key={index} className="s-list">
            <ul className="list-unstyled d-flex align-items-center">
              <li className="d-flex">{statesInfo[st]?.name}</li>
              <li>
                <Button
                  variant="outline-light"
                  onClick={(e) => handleShow(e, "wc_settings", st)}
                >
                  WC Settings
                </Button>
              </li>
            </ul>
          </div>
        );
      });
    }
  };
  const renderSalesOptions = () => {
    const columns = [
      {
        name: "State:",
        selector: "state",
        sortable: true,
      },
      {
        name: "City:",
        selector: "city",
        sortable: true,
      },
      {
        name: "Rate:",
        selector: "rate",
        sortable: true,
      },
      {
        name: "Tax Type:",
        selector: "tax_type",
        sortable: true,
      },
      {
        name: "Exempt Tax:",
        cell: (row, index) => (
          <div className="d-flex align-items-center custom-option-cell">
            <div className="toggle-switch pl-3 mr-3">
              <label className="switch m-0">
                <input
                  type="checkbox"
                  defaultChecked={row.tax_status ? true : false}
                  onChange={(e) => handleTaxStatus(e, row, index)}
                />
                <span className="slider"></span>
              </label>
            </div>
          </div>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
      {
        cell: (row, index) => (
          <div className="d-flex align-items-center custom-option-cell">
            <Button
              className="btn-icon btn-edit-tax"
              variant="outline-dark"
              onClick={(e) => handleShow(e, "edit_sales_tax", row)}
              block
            >
              <div className="icon">
                <img src={EditIcon} alt="Edit Icon" className="icon-black" />
                <img
                  src={EditWhiteIcon}
                  alt="Edit Icon"
                  className="icon-white"
                />
              </div>
              Edit Tax
            </Button>
          </div>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ];
    var msg = Constants.no_data;
    if (statesList && statesList.length !== 0) {
      msg = <div className="pb-5">{Constants.fetching_data}</div>;
    }
    return (
      <DataTable
        columns={columns}
        data={
          Object.keys(sptoStatesSelect).length
            ? sptoDataBasedOnSelection
            : sptoList
        }
        customStyles={customTableStyles}
        responsive
        fixedHeader={true}
        pagination
        noDataComponent={msg}
        paginationPerPage={5}
        paginationRowsPerPageOptions={[5, 15, 25, 50]}
        paginationComponentOptions={{
          rowsPerPageText: "Records per page:",
          rangeSeparatorText: "out of",
        }}
      />
    );
    // if (statesList && statesList.length !== 0) {
    //   // let sutaState =
    //   //             sutaList[index] && sutaList[index].value
    //   //               ? sutaList[index].value
    //   //               : "";
    //   return statesList.map((st, index) => {
    //     let sutaTax =
    //       sutaList[index] && sutaList[index].tax ? sutaList[index].tax : "";
    //     let sutaType =
    //       sutaList[index] && sutaList[index].tax_type
    //         ? sutaList[index].tax_type
    //         : "";
    //     return (
    //       <tr>
    //         <td>{statesInfo[st].name}</td>
    //         <td>City</td>
    //         <td>
    //           {sutaTax}% {sutaType}
    //         </td>
    //         <td>
    //           <div className="d-flex align-items-center">
    //             <Button
    //               variant="secondary"
    //               onClick={(e) => handleShow(e, "edit_sales_tax", st, index)}
    //             >
    //               Edit Tax
    //             </Button>
    //             <div className="toggle-switch pl-3">
    //               <label className="switch m-0">
    //                 <input type="checkbox" />
    //                 <span className="slider"></span>
    //               </label>
    //             </div>
    //           </div>
    //         </td>
    //       </tr>
    //     );
    //   });
    // }
  };

  let content = getStepContent();
  let tabContent = getTabContent();

  function getTabContent() {
    switch (tab) {
      case "add_states":
        return (
          <div className="added-states">
            <div className="top-section d-flex justify-content-between align-items-center">
              <div className="title">Added Location</div>
              <div className="button-holder">
                <Button
                  variant="outline-dark"
                  onClick={(e) => handleShow(e, "import")}
                >
                  <div className="icon d-inline-block">
                    <img
                      src={UploadDocIcon}
                      className="icon-black"
                      alt="Icon Image"
                    />
                    <img
                      src={UploadDocWhiteIcon}
                      className="icon-white"
                      alt="Icon Image"
                    />
                  </div>{" "}
                  Upload <span className="remove-text">SUTA Rates</span> via CSV
                  {
                    // console.log("sutaList => ", sutaList)
                    // console.log("sptoList => ", sptoList)
                    // console.log("states => ", states, " statesInfo => ", statesInfo, "statesList => ", statesList, "stateTitle => ", stateTitle)
                  }
                </Button>
                <Button
                  onClick={handleShowOne}
                  variant="outline-light"
                  className="ml-3"
                >
                  Manage location
                </Button>
              </div>
            </div>
            <div className="s-list-title">
              <ul className="list-unstyled d-flex align-items-center m-0">
                <li>State:</li>
                <li>Rate:</li>
                <li>Wage Cap:</li>
              </ul>
            </div>
            <div className="s-lists">
              {statesList.map((st, index) => {
                let sutaValue =
                  sutaList[index] && sutaList[index].value
                    ? sutaList[index].value
                    : "";
                if (statesInfo[st]) {
                  return (
                    <div className="s-list">
                      <ul className="list-unstyled d-flex align-items-center">
                        <li className="d-flex">{statesInfo[st].name}</li>
                        <li>
                          <InputGroup>
                            <InputGroup.Prepend>
                              <InputGroup.Text>%</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              type="number"
                              name={"suta_rate"}
                              min="0"
                              max="100"
                              value={sutaValue}
                              onFocus={(e) =>
                                e.target.parentNode.classList.add("active")
                              }
                              onChange={(e) =>
                                handleSutaRateChange(e, index, st, "suta_rates")
                              }
                              onBlur={(e) => {
                                validator.showMessageFor("suta_rate");
                                e.target.parentNode.classList.remove("active");
                              }}
                            />
                          </InputGroup>
                          <div className="suta_error">
                            {validator.message(
                              "suta_rate_" + index,
                              sutaValue,
                              "required|numeric|min:0,num|max:100,num"
                            )}
                          </div>
                        </li>
                        <li>
                          <span className="text-success">$</span>{" "}
                          {statesInfo[st].wage_cap}
                        </li>
                      </ul>
                    </div>
                  );
                }
              })}
            </div>
            <Tab.Pane eventKey="othercost">
              <div className="bottom-button-holder d-flex align-items-center justify-content-center">
                <Button
                  variant="primary"
                  block
                  disabled={disabled}
                  onClick={(e) => handleShow(e, "submit_states")}
                >
                  Update
                </Button>
              </div>
            </Tab.Pane>
          </div>
        );
      default:
        return (
          <div>
            <div className="button-top-holder suta-rates-add-buttons">
              <Button
                variant="light"
                onClick={handleShowOne}
                block
                className="btn-add-states"
              >
                <BsPlus /> Add States
              </Button>
              <Button
                variant="outline-dark"
                onClick={(e) => handleShow(e, "import")}
                block
                className="btn-upload"
              >
                <div className="icon d-inline-block">
                  <img
                    src={UploadDocIcon}
                    className="icon-black"
                    alt="Icon Image"
                  />
                  <img
                    src={UploadDocWhiteIcon}
                    className="icon-white"
                    alt="Icon Image"
                  />
                </div>{" "}
                Upload SUTA Rates via CSV
              </Button>
            </div>
            <div className="bottom-button-holder d-flex align-items-center justify-content-center">
              <Button
                variant="primary"
                block
                onClick={() => setTabActiveKey("workercomcodes")}
              >
                Continue
              </Button>
            </div>
          </div>
        );
    }
  }
  function getStepContent() {
    switch (step) {
      case "wc_settings":
        return (
          <Modal
            animation={false}
            className="import-states-code-modal wc-settings-modal"
            size="lg"
            centered
            show={showModalFour}
            onHide={handleCloseFour}
            backdrop="static"
          >
            <Modal.Header className="with-title normal" closeButton>
              <Modal.Title>{stateTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Table responsive="sm" className="wc-codes-setting-table">
                <thead>
                  <tr>
                    <th>Nickname</th>
                    <th>Industry</th>
                    <th>Code</th>
                    <th>Rate</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {inputList.map((x, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          <Form.Group className="m-0">
                            <FormControl
                              name="nick_name"
                              value={x.nick_name}
                              onChange={(e) => handleInputChange(e, i)}
                            />
                            {validator.message(
                              "nick_name_" + i,
                              x.nick_name,
                              "required",
                              {
                                className: "text-danger",
                              }
                            )}
                          </Form.Group>
                        </td>
                        <td>
                          <Select
                            styles={customControlStyles}
                            placeholder="Select"
                            options={industriesOptions}
                            value={{
                              label: x.industry_label,
                              value: x.industry,
                            }}
                            name="industry"
                            onChange={(e) => {
                              handleInputChange(e, i, "industry");
                            }}
                          />
                        </td>
                        <td>
                          <Form.Group className="m-0">
                            <FormControl
                              name="code"
                              value={x.code}
                              onChange={(e) => handleInputChange(e, i)}
                            />
                            {validator.message(
                              "code_" + i,
                              x.code,
                              "required|numeric",
                              {
                                className: "text-danger",
                              }
                            )}
                          </Form.Group>
                        </td>
                        <td>
                          <InputGroup className="m-0">
                            <FormControl
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              name="rate"
                              value={x.rate}
                              onChange={(e) => handleInputChange(e, i)}
                            />
                            <InputGroup.Append>
                              <InputGroup.Text>%</InputGroup.Text>
                            </InputGroup.Append>

                            {validator.message(
                              "rate_" + i,
                              x.rate,
                              "required|numeric|min:0,num|max:100,num",
                              {
                                className: "text-danger",
                              }
                            )}
                          </InputGroup>
                        </td>
                        <td>
                          <Button
                            className="btn-icon"
                            variant="outline-dark"
                            onClick={() => handleRemoveClick(i)}
                          >
                            <RiCloseLine />
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <Button
                block
                variant="light"
                className="p-3 mb-2 add-code-button"
                onClick={handleAddClick}
              >
                <HiOutlinePlusSm /> Add code
              </Button>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-light" onClick={handleCloseFour}>
                Cancel
              </Button>
              <Button
                variant="primary"
                disabled={disabled}
                onClick={(e) => handleShow(e, "update_wc_settings")}
              >
                OK
              </Button>
            </Modal.Footer>
          </Modal>
        );
      case "edit_sales_tax":
        return (
          <Modal
            animation={false}
            className="add-states-salestax-modal"
            size="sm"
            centered
            show={showModalFive}
            onHide={handleCloseFive}
            backdrop="static"
          >
            <Modal.Header closeButton className="with-title normal">
              <Modal.Title>{stateTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col lg={12}>
                  <Form.Group>
                    <Form.Label>City</Form.Label>
                    <FormControl
                      id="city"
                      value={formInput.city}
                      name="city"
                      type="tax"
                      disabled={true}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Label>Tax</Form.Label>
                  <InputGroup>
                    <FormControl
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      id="tax"
                      value={formInput.tax}
                      name="tax"
                      type="number"
                      onChange={handleInput}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>%</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                  {validator.message(
                    "tax",
                    formInput.tax,
                    "required|numeric|min:0,num|max:100,num",
                    {
                      className: "text-danger",
                    }
                  )}
                </Col>
                <Col lg={6}>
                  <Form.Label>&nbsp;</Form.Label>
                  <Form.Group>
                    <Select
                      name="tax_type"
                      placeholder=""
                      value={taxSelect}
                      options={taxOptions}
                      onChange={(value) => {
                        handleSelect(value, "tax_type");
                      }}
                      styles={customControlStyles}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-light" onClick={handleCloseFive}>
                Cancel
              </Button>
              <Button
                variant="primary"
                disabled={disabled}
                onClick={(e) => handleShow(e, "update_sales_tax")}
              >
                OK
              </Button>
            </Modal.Footer>
          </Modal>
        );
      case "skip_wc":
        return (
          <Modal
            animation={false}
            className="import-states-code-modal"
            size="md"
            centered
            show={showModalFour}
            onHide={handleCloseFour}
          >
            <Modal.Body>
              <div className="details text-center">
                <div className="icon">
                  <img src={ImportImageTwo} alt="Icon Image" />
                </div>
                <div className="title text-center">
                  Upload the CSV file <br /> with the WC Codes info <br /> you
                  want to import.
                </div>
                <p>
                  Your file must follow the ReCalc CSV format. Don’t have it?{" "}
                </p>
                <div className="button-holder d-flex flex-column align-items-center m-0">
                  <NavLink
                    className="p-0 mb-4 primary download_csv_template"
                    to={WCTemplate}
                    target="_blank"
                    download
                  >
                    Download CSV Template
                  </NavLink>
                  <Form.File
                    id="import_file"
                    name="import_file"
                    accept=".csv"
                    onChange={(e) => handleFileInput(e, "import_file_wc")}
                    label="Choose File"
                    custom
                  />
                  {validator.message(
                    "import_file",
                    formInput.import_file,
                    "required"
                  )}
                  {sutaImportError.length > 0 ? (
                    <div className="mt-3">{ErrorlistItems}</div>
                  ) : null}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-light" onClick={handleCloseFour}>
                Cancel
              </Button>
              <Button
                variant="primary"
                disabled={disabledCSV}
                onClick={(e) => handleShow(e, "import_wc")}
              >
                Continue
              </Button>
            </Modal.Footer>
          </Modal>
        );
      case "wc_codes_import":
        return (
          <Modal
            animation={false}
            className="import-states-code-modal"
            size="md"
            centered
            show={showModalFour}
            onHide={handleCloseFour}
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <div className="details">
                <div className="icon">
                  <img src={ImportImageOne} alt="Icon Image" />
                </div>
                <div className="title">Import WC Codes</div>
                <p>
                  Start by using the ReCalc CSV template <br /> to import WC
                  Codes.
                </p>
              </div>
              <div className="button-holder d-flex align-items-center flex-column">
                <NavLink
                  className="btn btn-primary download_csv_template"
                  to={WCTemplate}
                  target="_blank"
                  download
                >
                  Download CSV Template
                </NavLink>

                <Button
                  className="mt-3"
                  variant="outline-primary arrow"
                  onClick={(e) => handleShow(e, "skip_wc")}
                >
                  Skip This Step
                </Button>
              </div>
            </Modal.Body>
          </Modal>
        );
      case "skip":
        return (
          <div>
            <Modal.Body>
              <div className="details text-center">
                <div className="icon">
                  <img src={ImportImageTwo} alt="Icon Image" />
                </div>
                <div className="title text-center">
                  Upload the CSV file <br /> with the States &amp; SUTA Rates
                  info
                  <br />
                  you want to import.
                </div>
                <p>
                  Your file must follow the ReCalc CSV format. Don’t have it?{" "}
                </p>
                <div className="button-holder d-flex flex-column align-items-center m-0">
                  <NavLink
                    className="mb-3 primary download_csv_template"
                    to={SutaTemplate}
                    target="_blank"
                    download
                  >
                    Download CSV Template
                  </NavLink>

                  <Form.File
                    id="import_file"
                    name="import_file"
                    accept=".csv"
                    onChange={(e) => handleFileInput(e, "import_file")}
                    label="Choose File"
                    custom
                  />
                  {validator.message(
                    "import_file",
                    formInput.import_file,
                    "required"
                  )}
                  {sutaImportError.length > 0 ? (
                    <div>{ErrorlistItems}</div>
                  ) : null}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-light" onClick={handleCloseTwo}>
                Cancel
              </Button>
              <Button
                variant="primary"
                disabled={disabledCSV}
                onClick={(e) => handleShow(e, "import_suta")}
              >
                Continue
              </Button>
            </Modal.Footer>
          </div>
        );
      case "import":
        return (
          <Modal.Body>
            <div className="details">
              <div className="icon">
                <img src={ImportImageOne} alt="Icon Image" />
              </div>
              <div className="title">Import States &amp; Suta Rates</div>
              <p>
                Start by using the ReCalc CSV template <br /> to import States
                &amp; Suta Rates.
              </p>
            </div>
            <div className="button-holder d-flex align-items-center flex-column">
              <NavLink
                className="btn btn-primary download_csv_template"
                to={SutaTemplate}
                target="_blank"
                download
              >
                Download CSV Template
              </NavLink>

              <Button
                onClick={(e) => handleShow(e, "skip")}
                variant="outline-primary arrow"
                className="mt-3"
              >
                Skip This Step
              </Button>
            </div>
          </Modal.Body>
        );

      default:
        return "";
    }
  }

  return (
    <div className="calculation-settings">
      <Modal
        animation={false}
        className="add-states-modal"
        size="lg"
        centered
        show={showModalTutorial}
        onHide={handleCloseTutorial}
      >
        <Modal.Header closeButton className="with-title normal">
          <Modal.Title>Setting up your Calculator</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0 h-350">
        <iframe className="w-100 h-350" src="https://www.youtube.com/embed/5V-dhyB2z7c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </Modal.Body>
        
      </Modal>
      <div className="main-tabs">
        <Container fluid>
          <h2 className="title">
            <NavLink to="/settings">
              <img src={chevLeft} alt="icon" />
            </NavLink>
            Calculation Settings
          </h2>
          <div className="custom-tabs-holder">
            <Tab.Container activeKey={tabActiveKey}>
              <div className="custom-tabs-nav">
                <Nav>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="srates"
                      onClick={() => {
                        handleTabChange();
                        setTabActiveKey("srates");
                      }}
                    >
                      <HiCheck />
                      SUTA Rates
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="workercomcodes"
                      onClick={() => {
                        handleTabChange();
                        setTabActiveKey("workercomcodes");
                      }}
                    >
                      <HiCheck />
                      WC Codes
                    </Nav.Link>
                  </Nav.Item>
                  { billSettings?.plan?.name !== "Essential" ?
                    <>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="stax"
                          onClick={() => {
                            handleTabChange();
                            setTabActiveKey("stax");
                          }}
                        >
                          <HiCheck />
                          Sales Tax
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="spto"
                          onClick={() => {
                            handleTabChange();
                            setTabActiveKey("spto");
                          }}
                        >
                          <HiCheck />
                          SPTO
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="hcareplans"
                          onClick={() => {
                            handleTabChange();
                            setTabActiveKey("hcareplans");
                          }}
                        >
                          <HiCheck />
                          Healthcare Plans
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="othercost"
                          onClick={() => {
                            handleTabChange();
                            setTabActiveKey("othercost");
                          }}
                        >
                          <HiCheck />
                          Other Costs
                        </Nav.Link>
                      </Nav.Item>
                    </>
                  :
                    <></>
                  }
                </Nav>
              </div>
              <Tab.Content>
                <Tab.Pane eventKey="srates">
                  {" "}
                  <Modal
                    animation={false}
                    className="add-states-modal"
                    size="sm"
                    centered
                    show={showModalOne}
                    backdrop="static"
                  >
                    <Modal.Header className="with-title">
                      <Modal.Title>Add location</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="check-all">
                        <div className="custom-checkbox">
                          <input
                            id="state"
                            type="checkbox"
                            checked={checkedAll ? "checked" : ""}
                            onChange={handleAllStatesChange}
                          />
                          <label for="state">All States</label>
                        </div>
                      </div>
                      <ul className="states-list">
                        {renderStatesOptions()}
                      </ul>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="outline-light"
                        onClick={(e) => handleCloseOne()}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="primary"
                        onClick={(e) => handleShow(e, "add_states")}
                      >
                        Done
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  <Modal
                    animation={false}
                    className="import-states-modal"
                    size="md"
                    centered
                    show={showModalTwo}
                    onHide={handleCloseTwo}
                    backdrop="static"
                  >
                    <Modal.Header closeButton></Modal.Header>
                    {content}
                  </Modal>
                  {tabContent}
                </Tab.Pane>
                <Tab.Pane eventKey="workercomcodes">
                  <div className="added-states worker-codes">
                    <div className="top-section d-flex justify-content-between align-items-center">
                      <div className="title">Locations</div>
                      <div className="button-holder">
                        <Button
                          variant="outline-dark"
                          onClick={(e) => handleShow(e, "wc_codes_import")}
                        >
                          <div className="icon">
                            <img
                              src={UploadDocIcon}
                              className="icon-black"
                              alt="Icon Image"
                            />
                            <img
                              src={UploadDocWhiteIcon}
                              className="icon-white"
                              alt="Icon Image"
                            />
                          </div>{" "}
                          Upload{" "}
                          <span className="remove-text">WC Codes</span> via
                          CSV
                        </Button>
                        {content}
                      </div>
                    </div>
                    <div className="s-lists">{renderWCOptions()}</div>
                  </div>
                  <div className="bottom-button-holder d-flex align-items-center justify-content-between">
                    <Button
                      variant="outline-light"
                      block
                      onClick={() => setTabActiveKey("srates")}
                    >
                      Back
                    </Button>
                    {billSettings?.plan?.name !== "Essential" ?
                      <Button
                        variant="primary"
                        block
                        onClick={() => {
                          setTabActiveKey("stax");
                          handleTabChange();
                        }}
                      >
                        Continue
                      </Button>
                    :
                      ""
                    }
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="stax">
                  <div className="added-states sales-tax">
                    <div className="top-section d-flex justify-content-between align-items-center">
                      <div className="title">Sales Tax</div>
                      {renderSelectMenu()}
                      {renderSalesTaxExemptAll()}
                    </div>
                    <div className="table-holder">
                      {renderSalesOptions()}
                    </div>
                  </div>
                  <div className="bottom-button-holder d-flex align-items-center justify-content-between">
                    <Button
                      variant="outline-light"
                      block
                      onClick={() => setTabActiveKey("workercomcodes")}
                    >
                      Back
                    </Button>
                    <Button
                      variant="primary"
                      block
                      onClick={() => setTabActiveKey("spto")}
                    >
                      Continue
                    </Button>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="spto">
                  <div className="added-states spto">
                    {/* <div className="button-top-holder">
                    <Button
                      variant="secondary"
                      block
                      onClick={handleShowSeven}
                      className="btn-add-states"
                    >
                      <BsPlus /> Add SPTO
                    </Button>
                    </div> */}
                    {/* {renderSTPOModalOptions()} */}
                    <div className="top-section d-flex justify-content-between align-items-center">
                      <div className="title">SPTO</div>
                      {renderSelectMenu()}
                      {renderSTPOAlwaysAccrue()}
                    </div>
                    {renderSTPOModalOptions()}
                    {renderSTPOOptions()}
                    {/* {console.log("SPTOLIST => ", sptoList)} */}
                  </div>
                  <div className="bottom-button-holder d-flex align-items-center justify-content-between">
                    <Button
                      variant="outline-light"
                      block
                      onClick={() => setTabActiveKey("stax")}
                    >
                      Back
                    </Button>
                    <Button
                      variant="primary"
                      block
                      onClick={() => setTabActiveKey("hcareplans")}
                    >
                      Continue
                    </Button>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="hcareplans">
                  <div className="added-states sales-tax healthcare-plans">
                    <div className="top-section d-flex justify-content-between align-items-center">
                      <div className="title">Healthcare Plans</div>
                    </div>
                    <div className="table-holder">{renderHealthcare()}</div>
                  </div>
                  <div className="button-top-holder">
                    <Button
                      variant="light"
                      onClick={handleShowSix}
                      block
                      className="btn-add-packages"
                    >
                      <BsPlus /> Add Packages
                    </Button>
                  </div>
                  <div className="bottom-button-holder d-flex align-items-center  justify-content-between">
                    <Button
                      variant="outline-light"
                      block
                      onClick={() => setTabActiveKey("spto")}
                    >
                      Back
                    </Button>
                    <Button
                      variant="primary"
                      block
                      onClick={() => setTabActiveKey("othercost")}
                    >
                      Continue
                    </Button>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="othercost">
                  <div className="added-states other-cost">
                    <Modal
                      animation={false}
                      className="add-states-modal"
                      size="sm"
                      centered
                      show={showModalThirteen}
                      onHide={handleCloseThirteen}
                    >
                      <Modal.Header
                        closeButton
                        className="with-title normal"
                      >
                        <Modal.Title>Are you sure?</Modal.Title>
                      </Modal.Header>
                      <Modal.Body className="pt-1 pb-4">
                        Are you sure you want to delete this {deleteItem}?
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant="outline-light"
                          onClick={handleCloseThirteen}
                        >
                          No
                        </Button>
                        {deleteItem === "cost" ? (
                          <Button
                            variant="primary"
                            disabled={disabled}
                            onClick={() => confirm_delete_cost()}
                          >
                            Yes
                          </Button>
                        ) : (
                          <Button
                            variant="primary"
                            disabled={disabled}
                            onClick={() => confirm_delete_package()}
                          >
                            Yes
                          </Button>
                        )}
                      </Modal.Footer>
                    </Modal>

                    <Modal
                      animation={false}
                      className="add-states-salestax-modal other-cost-modal"
                      size="lg"
                      centered
                      show={showModalNine}
                      onHide={handleCloseNine}
                    >
                      <Modal.Header
                        closeButton
                        className="with-title normal"
                      >
                        <Modal.Title>{otherCostModalTitle}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Row className="align-items-start mb-4">
                          <Col lg={12}>
                            <Form.Group>
                              <Form.Label>
                                {otherCostModalLabel1}
                              </Form.Label>
                              <FormControl
                                id="name"
                                name="name"
                                value={formInput.name}
                                onChange={handleInput}
                              />
                              {validator.message(
                                "name",
                                formInput.name,
                                "required"
                              )}
                            </Form.Group>
                          </Col>
                          {otherCostModalLabel5 ? (
                            <Col lg={4}>
                              <Form.Label>Industry</Form.Label>

                              <Select
                                styles={customControlStyles}
                                className="no-margin"
                                placeholder="Select"
                                options={industriesOptions}
                                value={industryValue}
                                name="industry"
                                onChange={(value) => {
                                  setIndustryValue(value);
                                }}
                              />
                            </Col>
                          ) : null}

                          {otherCostModalLabel4 ? (
                            <Col lg={8}>
                              <Form.Label className="mb-2">Type</Form.Label>

                              <ButtonGroup
                                className="w-100 custom-btn-holder"
                                toggle
                              >
                                {costOptions.map((option, idx) => (
                                  <ToggleButton
                                    key={idx}
                                    type="radio"
                                    variant="light"
                                    name="radio"
                                    value={option.value}
                                    checked={optionValue === option.value}
                                    onChange={(e) =>
                                      changeOptionValue(
                                        e.currentTarget.value
                                      )
                                    }
                                  >
                                    {option.name}
                                  </ToggleButton>
                                ))}
                              </ButtonGroup>
                            </Col>
                          ) : null}
                        </Row>
                        <Row className="align-items-start mb-4">
                          {otherCostModalLabel2 !== "" ? (
                            <Col lg={4}>
                              <Form.Label>
                                {otherCostModalLabel2}
                              </Form.Label>
                              <InputGroup>
                                <FormControl
                                  id="percent"
                                  name="percent"
                                  type="number"
                                  value={formInput.percent}
                                  onChange={handleInput}
                                  onFocus={handleFocus}
                                  onBlur={handleBlur}
                                />
                                <InputGroup.Append>
                                  <InputGroup.Text className="pl-0">
                                    {otherCostModalLabel2 === "Cost"
                                      ? "$"
                                      : "%"}
                                  </InputGroup.Text>
                                </InputGroup.Append>
                              </InputGroup>
                              {otherCostModalLabel2 === "Cost"
                                ? validator.message(
                                    "cost",
                                    formInput.percent,
                                    "required|numeric|min:0,num"
                                  )
                                : validator.message(
                                    "percent",
                                    formInput.percent,
                                    "required|numeric|min:0,num|max:100,num"
                                  )}
                            </Col>
                          ) : null}
                          {otherCostModalLabel3 ? (
                            <Col lg={8}>
                              <Form.Label>&nbsp;</Form.Label>
                              <ButtonGroup
                                className="w-100 custom-btn-holder"
                                toggle
                              >
                                {radios.map((radio, idx) => (
                                  <ToggleButton
                                    key={idx}
                                    type="radio"
                                    variant="light"
                                    name="radio"
                                    value={radio.value}
                                    checked={radioValue === radio.value}
                                    onChange={(e) =>
                                      setRadioValue(e.currentTarget.value)
                                    }
                                  >
                                    {radio.name}
                                  </ToggleButton>
                                ))}
                              </ButtonGroup>
                            </Col>
                          ) : null}
                        </Row>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant="outline-light"
                          onClick={handleCloseNine}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="primary"
                          disabled={disabled}
                          onClick={() => handleOtherCost("update_cost")}
                        >
                          OK
                        </Button>
                      </Modal.Footer>
                    </Modal>

                    <div className="top-section d-flex justify-content-between align-items-center">
                      <div className="title">Other Costs</div>
                    </div>
                    <div className="button-top-holder">
                      <div className="button-options">
                        <div className="sub-title">Interest</div>
                        {renderOtherCost("interest")}
                        <Button
                          variant="light"
                          block
                          className="btn-add-states"
                          onClick={() => handleOtherCost("add_interest")}
                        >
                          <BsPlus /> Add Interest
                        </Button>
                      </div>

                      <div className="button-options">
                        <div className="sub-title">
                          Background Check Cost
                        </div>
                        {renderOtherCost("background_check_cost")}
                        <Button
                          variant="light"
                          block
                          className="btn-add-states"
                          onClick={() =>
                            handleOtherCost("add_background_check_cost")
                          }
                        >
                          <BsPlus /> Add Background Check Costs
                        </Button>
                      </div>

                      <div className="button-options">
                        <div className="sub-title">Drug Screen Cost</div>
                        {renderOtherCost("drug_screen_cost")}
                        <Button
                          variant="light"
                          block
                          className="btn-add-states"
                          onClick={() =>
                            handleOtherCost("add_drug_screen_cost")
                          }
                        >
                          <BsPlus /> Add Drug Screen Costs
                        </Button>
                      </div>

                      <div className="button-options">
                        <div className="sub-title">Payrolling Cost</div>
                        {renderOtherCost("payrolling_cost")}
                        <Button
                          variant="light"
                          block
                          className="btn-add-states"
                          onClick={() =>
                            handleOtherCost("add_payrolling_cost")
                          }
                        >
                          <BsPlus /> Add Payrolling Costs
                        </Button>
                      </div>

                      <div className="button-options">
                        <div className="sub-title">
                          Managed Service Provider (MSP)
                        </div>
                        {renderOtherCost("manage_service_provider")}

                        <Button
                          variant="light"
                          block
                          className="btn-add-states"
                          onClick={() =>
                            handleOtherCost("add_manage_service_provider")
                          }
                        >
                          <BsPlus /> Add Managed Service Provider
                        </Button>
                      </div>

                      <div className="button-options">
                        <div className="sub-title">
                          Vendor Management System
                        </div>
                        {renderOtherCost("vendor_management_system")}

                        <Button
                          variant="light"
                          block
                          onClick={() =>
                            handleOtherCost("add_vendor_management_system")
                          }
                          className="btn-add-states"
                        >
                          <BsPlus /> Add Vendor Management System
                        </Button>
                      </div>

                      <div className="button-options">
                        <div className="sub-title">Liability Insurance</div>
                        {renderOtherCost("liability_insurance")}
                        <Button
                          variant="light"
                          block
                          onClick={() =>
                            handleOtherCost("add_liability_insurance")
                          }
                          className="btn-add-states"
                        >
                          <BsPlus /> Add Liability Insurance
                        </Button>
                      </div>
                      {renderOtherCost("custom_cost")}
                    </div>
                  </div>
                  <div className="bottom-button-holder d-flex align-items-center justify-content-between">
                    <Button
                      block
                      variant="outline-dark"
                      onClick={() => handleOtherCost("add_custom_cost")}
                    >
                      Create Custom Cost
                    </Button>
                    <Button
                      variant="outline-light"
                      block
                      onClick={() => setTabActiveKey("hcareplans")}
                    >
                      Back
                    </Button>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </Container>
      </div>
      {isPaymentShowing ? (
        <Payment onClose={handleClosePayment} isShowing={isPaymentShowing} />
      ) : null}
      <AfterLogin />
    </div>
  );
}
