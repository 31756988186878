import React, { useState, useEffect, useReducer, useCallback } from "react";
import { connect } from "react-redux";
import {
  Modal,
  Button,
  Form,
  FormControl,
  Container,
  ProgressBar,
  Tabs,
  Tab,
  Row,
  Col,
  Card,
  Alert,
  Spinner,
} from "react-bootstrap";
import TrashIcon from "./assets/images/icons/trash.svg";
import { BsPlus } from "react-icons/bs";
import { RiCloseLine } from "react-icons/ri";
import {
  getSettings,
  getTeams,
  saveOrganizationTeamAttempt,
  updateOrganizationTeamAttempt,
  getRolesAttempt,
  setTeamLogo,
  deleteTeam,
  getQuotations,
  getApprovedQuotations,
} from "./data/globalAction";
import DataTable from "react-data-table-component";
import * as Constants from "../core/Constants";
import SimpleReactValidator from "simple-react-validator";
import TagsInput from "react-tagsinput";
import MusicImage from "./assets/images/music-band.png";
import "react-tagsinput/react-tagsinput.css"; // If using WebPack and style-loader.
import Select from "react-select";
import { toast } from "react-toastify";
import Payment from "./Payment";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useHistory } from "react-router-dom";
import AfterLogin from "./middleware/AfterLogin";
import { RiArrowUpSLine } from "react-icons/ri";
import BadgeIcon from "../component/assets/images/icons/badge.svg";
import GraphIcon from "../component/assets/images/icons/graph-icon.svg";
import PersentIcon from "../component/assets/images/icons/persent-icon.svg";
import { isAdmin, isManager, isOwner } from "../core/UserRoleCheck";
import { confirmAlert } from "react-confirm-alert";
import Slider from "react-slick";
import V1 from '../component/assets/images/v1.png';
import DashImage from '../component/assets/images/learning/dashboard.png';
import TeamPopup from '../component/assets/images/learning/team-popup.png';

const customRoleControlStyles = {
  control: (base, state) => ({
    ...base,
    minHeight: 30,
    minWidth: 100,
    background: state.isFocused ? "transparent" : "transparent",
    borderWidth: 1,
    borderColor: state.isFocused ? "transparent" : "transparent",
    boxShadow: state.isFocused ? null : null,
    fontSize: "12px",
    "&:hover": {
      borderColor: state.isFocused ? null : null,
    },
  }),
  dropdownIndicator: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  menu: (base) => ({
    ...base,
    fontSize: "13px",
    border: "1px solid #f1f3f6",
    boxShadow: "none",
  }),
  option: (base, state) => ({
    ...base,
    background: state.isSelected ? "#f1f3f6" : "#fff",
    color: "#151B26",
    "&:hover": {
      background: "#f1f3f6",
      color: "#151B26",
    },
  }),
  clearIndicator: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
};
const customControlStyles = {
  control: (base, state) => ({
    ...base,
    minHeight: 44,
    marginBottom: "15px",
    background: state.isFocused ? "#fff" : "#f1f3f6",
    borderWidth: 1,
    borderColor: state.isFocused ? "#f1f3f6" : "#fff",
    boxShadow: state.isFocused ? null : null,
    fontSize: "13px",
    "&:hover": {
      borderColor: state.isFocused ? null : null,
    },
  }),
  dropdownIndicator: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  menu: (base) => ({
    ...base,
    fontSize: "13px",
    border: "1px solid #f1f3f6",
    boxShadow: "none",
  }),
  option: (base, state) => ({
    ...base,
    background: state.isSelected ? "#f1f3f6" : "#fff",
    color: "#151B26",
    "&:hover": {
      background: "#f1f3f6",
      color: "#151B26",
    },
  }),
  clearIndicator: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
};
const customGraphControlStyles = {
  control: (base, state) => ({
    ...base,
    minHeight: 40,
    marginBottom: "15px",
    background: "#fff",
    borderRadius: "8px",
    borderWidth: 1,
    borderColor: state.isFocused ? "#bbc0d3" : "#E5E8F5",
    boxShadow: "none",
    fontSize: "13px",
    "&:hover": {
      borderColor: state.isFocused ? null : null,
    },
  }),
  dropdownIndicator: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  menu: (base) => ({
    ...base,
    fontSize: "13px",
    border: "1px solid #f1f3f6",
    boxShadow: "none",
  }),
  option: (base, state) => ({
    ...base,
    background: state.isSelected ? "#f1f3f6" : "#fff",
    color: "#151B26",
    "&:hover": {
      background: "#f1f3f6",
      color: "#151B26",
    },
  }),
  clearIndicator: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
};
const customTableStyles = {
  header: {
    style: {
      padding: "0",
      minHeight: "inherit",
    },
  },
  headRow: {
    style: {
      color: "#43536D",
      borderColor: "#E5E8F5",
      borderStyle: "solid",
      borderWidth: "1px",
      borderLeftWidth: "0",
      borderRightWidth: "0",
      backgroundColor: "#FCFCFF",
    },
  },
  headCells: {
    style: {
      fontSize: "13px",
      fontWeight: "normal",
      color: "#767778",
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "#fff",
      },
    },
  },
  rows: {
    style: {
      minHeight: "65px",
      cursor: "pointer",
      "&:not(:last-of-type)": {
        borderBottomStyle: "solid",
        borderBottomWidth: "1px",
        borderBottomColor: "#E5E8F5",
      },
    },
  },
  cells: {
    style: {
      minHeight: "65px",
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "#fff",
      },
    },
  },
  pagination: {
    style: {
      borderTopStyle: "solid",
      borderTopWidth: "1px",
      borderTopColor: "#E5E8F5",
    },
  },
};

const CustomTooltip = ({ payload, active }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <div>
          <span>Revenue </span>
          {`$ ${payload[0].value.toLocaleString()}`}
        </div>
        <div>
          <span>Gross Profit</span>
          {`$ ${(parseInt(payload[0].value) * parseInt(10)) / parseInt(100)
            } (10%)`}
        </div>
      </div>
    );
  }

  return null;
};
function Dashboard(props) {
  const [isShowing, setIsShowing] = useState(false);
  const [roles, setRoles] = useState([]);
  const [quotations, setQuotations] = useState([]);
  const [revenue, setRevenue] = useState(null);
  const [grossProfit, setGrossProfit] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [updateMember, setUpdateMember] = useState(false);
  const [showModalTeam, setShowModalTeam] = useState(false);
  const handleCloseTeam = () => setShowModalTeam(false);
  const [key, setKey] = useState("actual-revenue");
  const [settings, setSettings] = useState([]);
  const history = useHistory();
  const [formInput, setFormInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      team_name: "",
      emails: [],
    }
  );
  const [inputList, setInputList] = useState([]);
  const [validator] = useState(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const [teams, setTeams] = useState([]);
  const [rowTeam, setRowTeam] = useState(0);
  const [teamMembers, setTeamMembers] = useState([]);
  const [isPaymentShowing, setIsPaymentShowing] = useState(false);
  const [teamLogoApplied, setTeamLogoApplied] = useState(false);
  const [logoData, setLogoData] = useState({});
  const handleTeamComponent = useCallback((state) => handleRowClick(state));
  const [filterSelectedOption, setFilterSelectedOption] = useState({ value: 'total', label: 'Total' });
  const [revenueFilter, setRevenueFilter] = useState('total');
  const [demoModalShow, setDemoModalShow] = useState(false);
  const [showFinishedButton, setShowFinishedButton] = useState(false);

  const filter_options = [
    { value: '7', label: 'Last 7 days' },
    { value: '30', label: 'Last 30 days' },
    { value: 'year', label: 'Year To Date' },
    { value: 'total', label: 'Total' },
  ];
  const handleSelect = async (info) => {
    setFilterSelectedOption(info);
    const newValue = info.value;
    setRevenueFilter(newValue);
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}
      >Next</div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}
      >Back</div>
    );
  }
  const surveySettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    afterChange: function (currentSlide) {
      if (currentSlide == 2) {
        setShowFinishedButton(true)
      } else {
        setShowFinishedButton(false)
      }
    }
  };

  const handleClose = () => {
    setDemoModalShow(false);
    setShowFinishedButton(false)
    localStorage.setItem("dashboardDemo", false);
  }

  const NumberFormat = (number) => {
    let nf = new Intl.NumberFormat('en-US');
    return nf.format(number);
    // return number.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  };


  useEffect(() => {
    document.querySelector('html').classList.remove('calculation-details-page');
    async function fetchActualRevenue() {
      const approved_quotes = await getApprovedQuotations({ filter: revenueFilter });
      setQuotations(approved_quotes.data.quotation);
      calculateRevenue(approved_quotes.data.quotation);
      console.log('revenueFilter', revenueFilter)
    }
    fetchActualRevenue();
  }, [revenueFilter]);


  const handleClosePayment = () => {
    localStorage.removeItem("expire");
    setIsPaymentShowing(false);
  };
  const handleRowClick = (row) => {
    history.push({
      pathname: `/dashboard/team/${row.info.id}`,
      state: {
        data: row,
      },
    });
  };
  const [invitationError, setInvitationError] = useState([]);
  const columns = [
    {
      name: "Name",
      selector: "name",
      sortable: true,
      cell: (row) => (
        <div className="d-flex align-items-center">
          {row && row.info && row.info.image ? (
            <div className="user-pic">
              <img src={row.info.image} alt="Image" />
            </div>
          ) : (
            <div className="team-name-short mr-2">
              {row.name.replace(/[^a-zA-Z- ]/g, "").match(/\b\w/g)}
            </div>
          )}
          {row.name}
        </div>
      ),
    },
    {
      name: "Members",
      selector: "members",
      sortable: true,
    },
    {
      name: "Calcs",
      selector: "calcs",
      sortable: true,
    },
    {
      name: "Quotes",
      selector: "invoices",
      sortable: true,
    },
    {
      name: "Revenue",
      selector: "revenue",
      sortable: true,
    },
    {
      name: "Gross Profit",
      selector: "grossprofit",
      sortable: true,
    },
    {
      name: "Team performance",
      selector: "teamperformance",
      cell: (row) => (
        <div className="d-flex align-items-center w-100">
          <div className="persent mr-3">{row?.teamperformance ? NumberFormat((row.teamperformance).toFixed(0)) : 0} %</div>
          <ProgressBar now={row?.teamperformance ? NumberFormat((row.teamperformance).toFixed(0)) : 0} />
        </div>
      ),
    },
    {
      name: "",
      cell: (row) => (
        <Button
          className="m-1"
          variant="outline-light"
          size="sm"
          onClick={() => handleShow("manage_team", row)}
        >
          Manage Team
        </Button>
      ),
    },
  ];
  let rolesOptions = [];
  {
    roles &&
      roles.map((role) =>
        rolesOptions.push({ label: role.name, value: role.id })
      );
  }
  const setIsShowingClose = () => {
    localStorage.setItem("isShowing", false);
    setIsShowing(false);
    // history.push({
    //   pathname: `/settings/calculation-settings?tutorial=1`,
    // });    
    toast.info("Enter your companies rates.");
    localStorage.setItem("dashboardDemo", true);
    window.open(`/settings/calculation-settings?tutorial=1`, "_self")
  };
  const handleRemoveClick = (index) => {
    if (rowTeam === 0) {
      const list = [...inputList];
      console.log(list, index);
      list.splice(index, 1);
      console.log(list, index);
      setInputList(list);
    } else {
      const list = [...teamMembers];
      list.splice(index, 1);
      setTeamMembers(list);
    }
  };
  const handleInput = (evt) => {
    const name = evt.target.name;
    const newValue = evt.target.value;
    setFormInput({ [name]: newValue });
  };
  const handleTags = (emails) => {
    console.log("emails", emails);
    let emailErrors = [];
    emails.forEach((email, i) => {
      emails[i] = email.trim();
      if (
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          email
        )
      ) {
        setInputList([...inputList, { email: email, role: rolesOptions[0] }]);
      } else {
        emailErrors.push(
          "You have entered '" + email + "' an invalid email address!"
        );
      }
    });
    if (emailErrors.length === 0) {
      setInvitationError([]);
      setFormInput({ ["emails"]: [] });
      if (rowTeam !== 0) {
        const list = [...teamMembers];
        list.push({
          team_id: rowTeam,
          user_id: 0,
          team_user_role: 2,
          email: emails[0],
          team_name: "",
          user_name: "",
          user_image: "",
        });
        setTeamMembers(list);
      }
    } else {
      setInvitationError(emailErrors);
    }

    console.log("emails", emails);
  };

  useEffect(() => {
    document.querySelector("html").classList.remove("homepage");
    if (props.login && props.login.headers) {
      localStorage.setItem("headers", JSON.stringify(props.login.headers));
      async function fetchSettings() {
        setSettings(await getSettings());
      }
      fetchSettings();
      async function fetchTeams() {
        let team = await getTeams();
        if (
          team &&
          team.data &&
          team.data.organization_teams &&
          team.data.organization_teams.length !== 0
        ) {
          setTeams(team.data.organization_teams);
        }
      }
      fetchTeams();
      if (
        props.login.data.data.login_count < 2 &&
        (localStorage.getItem("isShowing") === true ||
          localStorage.getItem("isShowing") === null)
      ) {
        setIsShowing(true);
        localStorage.setItem("isShowing", true);
        localStorage.setItem("calculatorDemo", true);
        localStorage.setItem("calculationDemo", true);
        localStorage.setItem("quotationDemo", true);        
        localStorage.setItem("calculationDetailDemo", true);
      }
    }
    async function fetchRoles() {
      setRoles(await getRolesAttempt());
    }
    fetchRoles();

    if (localStorage.getItem("dashboardDemo") === "true") {
      setTimeout(() => {
        setDemoModalShow(true);
      }, 500);
    }
  }, []);

  useEffect(() => {
    async function fetchQuotations() {
      const quotes = await getQuotations();
      setQuotations(quotes.data.quotation);
      calculateRevenue(quotes.data.quotation);
    }
    async function fetchApprovedQuotations() {
      const approved_quotes = await getApprovedQuotations({ filter: revenueFilter });
      setQuotations(approved_quotes.data.quotation);
      calculateRevenue(approved_quotes.data.quotation);
    }
    if (key === "actual-revenue")
      fetchApprovedQuotations();
    else if (key === "projected-form")
      fetchQuotations();
  }, [key]);

  useEffect(() => {
    if (localStorage.getItem("expire")) {
      setIsPaymentShowing(true);
      console.log("expire", localStorage.getItem("expire"));
    }
  });
  useEffect(() => {
    if (settings.expire && settings.expire === true) {
      setIsPaymentShowing(true);
      console.log("expire", localStorage.getItem("expire"));
    }
  }, [settings]);

  const demoGraphData = [
    {
      x: Date.UTC(2018, 4, 1),
      y: 300000,
      total: 30000
    },
    {
      x: Date.UTC(2018, 4, 2),
      y: 350000,
      total: 35000
    },
    {
      x: Date.UTC(2018, 4, 3),
      y: 300000,
      total: 30000
    },
    {
      x: Date.UTC(2018, 4, 4),
      y: 450000,
      total: 45000
    },
    {
      x: Date.UTC(2018, 4, 5),
      y: 320000,
      total: 32000
    },
    {
      x: Date.UTC(2018, 4, 6),
      y: 440000,
      total: 44000
    },
    {
      x: Date.UTC(2018, 4, 7),
      y: 380000,
      total: 38000
    },
    {
      x: Date.UTC(2018, 4, 8),
      y: 480000,
      total: 48000
    },
    {
      x: Date.UTC(2018, 4, 9),
      y: 310000,
      total: 31000
    },
    {
      x: Date.UTC(2018, 4, 10),
      y: 400000,
      total: 40000
    },
    {
      x: Date.UTC(2018, 4, 11),
      y: 310000,
      total: 31000
    },
    {
      x: Date.UTC(2018, 4, 12),
      y: 290000,
      total: 29000
    }
  ]

  const graphData = () => {
    var data = [];
    if (quotations?.length > 0) {
      quotations?.forEach((quote) => {
        data.push(
          {
            x: Date.parse(quote.issue_date),
            y: quote.revenue,
            total: quote.gross_profit
          }
        )
      })
      return data;
    } else {
      return demoGraphData;
    }
  }

  const options = {
    chart: {
      height: 320,
      renderTo: "container",
      type: "spline",
    },
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      type: "datetime",
      month: "%e. %b",
      year: "%b",
      tickWidth: 0,
      lineWidth: 0,
      crosshair: {
        width: 2,
        color: "#7848FF",
        dashStyle: "shortdot",
      },
      labels: {
        style: {
          color: "#43536D",
          textTransform: "uppercase",
          fontWeight: "300",
          fontFamily: "Formular",
        },
      },
    },
    yAxis: {
      gridLineColor: "#E5E8F5",
      gridLineDashStyle: "longdash",
      title: {
        text: "",
      },
      labels: {
        style: {
          color: "#43536D",
          textTransform: "uppercase",
          fontWeight: "300",
          fontFamily: "Formular",
        },
      },
    },
    tooltip: {
      shadow: false,
      backgroundColor: "none",
      borderColor: "none",
      padding: 0,
      borderRadius: "8px",
      className: "chart-tooltip",
      useHTML: true,
      formatter: function () {
        if (this.total) {
          return (
            "<div class='custom-graph-tooltip'><div class='px-3 py-2 d-flex'><div><span>Revenue</span>$" +
            NumberFormat(this.y.toFixed(2)) +
            "</div></div><div class='px-3 py-2 d-flex align-items-end'><div><span>Gross Profit</span>$" +
            NumberFormat(this.total.toFixed(2)) +
            "</div><small class='percent-up ml-3'>" + NumberFormat(((this.total / this.y) * 100).toFixed(2)) + " %</small></div></div>"
          );
        }
      },
    },
    noData: {
      attr: null,
      position: { x: 0, y: 0, align: "center", verticalAlign: "middle" },
      style: { fontSize: "12px", fontWeight: "bold", color: "#60606a" },
    },
    plotOptions: {
      series: {
        marker: {
          fillColor: "#7848FF",
          shadow: false,
          lineWidth: 2,
          width: 30,
          height: 30,
          lineColor: "#fff",
          enabled: false,
        },
      },
    },
    series: [
      {
        showInLegend: false,
        type: "area",
        lineWidth: 2,
        lineColor: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, "#e80556"], // start
            [0.5, "#95009a"], // middle
            [1, "#6c00dd"], // end
          ],
        },
        fillColor: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, "rgba(149, 0, 154, 0.2)"], // start
            [0.5, "rgba(149, 0, 154, 0.03)"], // middle
            [1, "rgba(255, 255, 255, 0.01)"], // end
          ],
        },
        data: graphData(),
      },
    ],
  };

  const calculateRevenue = (quotes) => {
    const revenue = quotes?.reduce((a, b) => {
      return a + b.revenue;
    }, 0)
    setRevenue(revenue);

    const gross_profit = quotes?.reduce((a, b) => {
      return a + b.gross_profit;
    }, 0)
    setGrossProfit(gross_profit);
  }

  const deleteTeamRequest = async (row) => {
    if (row.info.id) {
      let result = await deleteTeam({ id: row.info.id });
      if (result.status === 200) {
        toast.success("Team Deleted Successfully");
      } else {
        toast.error("Error, Please try again");
      }
    }
  };

  const handleShow = async (index, row = "", opt = false) => {
    if (index === "create_team") {
      setInputList([]);
      setRowTeam(0);
      setFormInput({ ["emails"]: [] });
      setFormInput({ ["team_name"]: "" });
      setShowModalTeam(true);
    } else if (index === "manage_team") {
      setLogoData({});
      setTeamLogoApplied(false);
      let users = [];
      row.info.organization_team_user.forEach((element) => {
        // console.log("element", row.info, element);
        let user_name = "";
        if (
          element.user.first_name !== null ||
          element.user.last_name !== null
        ) {
          user_name = element.user.first_name + " " + element.user.last_name;
        }
        users.push({
          team_id: row.info.id,
          user_id: element.user.id,
          team_user_role: element.role_id,
          email: element.user.email,
          team_name: row.info.name,
          user_name: user_name,
          user_image: element.user.image,
        });
      });
      console.log("team", users);
      setRowTeam(row.info.id);
      setInputList([]);
      setFormInput({ ["emails"]: [] });
      setFormInput({ ["team_name"]: row.info.name });
      setTeamMembers(users);
      setShowModalTeam(true);
    } else if (index === "add_team") {
      var valid = 1;
      setDisabled(true);
      if (!validator.fieldValid("team_name")) {
        valid = 0;
      }
      if (!valid) {
        validator.showMessages();
        forceUpdate(1);
        setDisabled(false);
      } else {
        let invitationList = inputList.map((x, i) => {
          return { email: x.email, role: x.role.value };
        });
        let force_update = updateMember;
        if (opt) {
          force_update = true;
        }

        let data = {
          data: {
            team_name: formInput.team_name,
            invitation: invitationList,
            force_update: force_update,
          },
        };

        let teamRequst = await saveOrganizationTeamAttempt(
          JSON.stringify(data)
        );
        if (teamRequst.status === 200) {
          let imageResult = { status: 400 };
          if (teamLogoApplied) {
            let teamLogoForm = new FormData();
            teamLogoForm.append("team_id", logoData.team_id);
            teamLogoForm.append("image", logoData.image);
            imageResult = await setTeamLogo(teamLogoForm);
          }
          if (imageResult.status === 200) {
            setTeams(imageResult.data.organization_teams);
          } else {
            setTeams(teamRequst.data.organization_teams);
          }
          setDisabled(false);
          setShowModalTeam(false);
          setTeamLogoApplied(false);
          toast.success(Constants.update_message);
        } else {
          if (localStorage.getItem("expire")) {
            setIsPaymentShowing(true);
            console.log("expire", localStorage.getItem("expire"));
          } else {
            if (
              teamRequst &&
              teamRequst.data &&
              teamRequst.data.status &&
              teamRequst.data.status === "member_full" &&
              !opt
            ) {
              setShowModalTeam(false);
              confirmAlert({
                title: "Member Limit fill",
                message: (
                  <p className="mb-0">
                    Are you sure you want to update subscription members?
                  </p>
                ),
                buttons: [
                  {
                    label: "Yes",
                    className: "btn btn-primary",
                    onClick: () => {
                      setShowModalTeam(true);
                      setUpdateMember(true);
                      handleShow("add_team", "", true);
                    },
                  },
                  {
                    label: "No",
                    className: "btn btn-outline-light",
                    onClick: () => {
                      setShowModalTeam(true);
                    },
                  },
                ],
              });
            }
          }
        }
      }
    } else if (index === "update_team") {
      var valid = 1;
      setDisabled(true);
      if (!validator.fieldValid("team_name")) {
        valid = 0;
      }
      if (!valid) {
        validator.showMessages();
        forceUpdate(1);
        setDisabled(false);
      } else {
        let invitationList = teamMembers.map((x, i) => {
          return { email: x.email, role: x.team_user_role };
        });
        let force_update = updateMember;
        if (opt) {
          force_update = true;
        }
        let data = {
          data: {
            team_id: rowTeam,
            team_name: formInput.team_name,
            invitation: invitationList,
            force_update: force_update,
          },
        };

        let teamRequst = await updateOrganizationTeamAttempt(
          JSON.stringify(data)
        );
        if (teamRequst.status === 200) {
          let imageResult = { status: 400 };
          if (teamLogoApplied) {
            console.log(logoData);
            let teamLogoForm = new FormData();
            teamLogoForm.append("team_id", logoData.team_id);
            teamLogoForm.append("image", logoData.image);
            imageResult = await setTeamLogo(teamLogoForm);
          }
          if (imageResult.status === 200) {
            setTeams(imageResult.data.organization_teams);
          } else {
            setTeams(teamRequst.data.organization_teams);
          }

          setUpdateMember(false);
          setShowModalTeam(false);
          setTeamLogoApplied(false);
          setRowTeam(0);
          toast.success(Constants.update_message);
        } else {
          console.log("teamRequst", teamRequst.data.status);
          if (localStorage.getItem("expire")) {
            setIsPaymentShowing(true);
            console.log("expire", localStorage.getItem("expire"));
          } else {
            if (
              teamRequst &&
              teamRequst.data &&
              teamRequst.data.status &&
              teamRequst.data.status === "member_full" &&
              !opt
            ) {
              setShowModalTeam(false);
              confirmAlert({
                title: "Member Limit fill",
                message: (
                  <p className="mb-0">
                    Are you sure you want to update subscription members?
                  </p>
                ),
                buttons: [
                  {
                    label: "Yes",
                    className: "btn btn-primary",
                    onClick: () => {
                      setShowModalTeam(true);
                      setUpdateMember(true);
                      handleShow("update_team", "", true);
                    },
                  },
                  {
                    label: "No",
                    className: "btn btn-outline-light",
                    onClick: () => {
                      setShowModalTeam(true);
                    },
                  },
                ],
              });
            }
          }
        }
        setDisabled(false);
      }
    }
    console.log("index", index);
  };
  const handleInputChange = (e, index, opt = "") => {
    let name, value;

    name = opt;
    value = e;

    if (rowTeam === 0) {
      const list = [...inputList];
      list[index][name] = value;
      setInputList(list);
    } else {
      const list = [...teamMembers];
      console.log("list", value.value, list[index]["team_user_role"]);
      list[index]["team_user_role"] = value.value;
      setTeamMembers(list);
    }
  };
  const handleTeamLogo = async (e) => {
    setLogoData({
      team_id: rowTeam,
      image: e.target.files[0],
      url: URL.createObjectURL(e.target.files[0]),
    });
    console.log("logoData=> ", logoData);
    setTeamLogoApplied(true);
  };
  const renderCreateTeam = () => {
    let ErrorlistItems = "";
    if (invitationError) {
      let errors = invitationError;
      ErrorlistItems = errors.map((err) => (
        <Alert variant="danger">{err}</Alert>
      ));
    }
    var initials = formInput.team_name
      .replace(/[^a-zA-Z- ]/g, "")
      .match(/\b\w/g);

    let selectedTeam;
    if (teams) {
      selectedTeam = teams.filter((t) => t.info.id === rowTeam);
    }
    // console.log("rowTeam => ", rowTeam)
    // console.log("selected team => ", selectedTeam)

    return (
      <Modal
        animation={false}
        className="add-states-modal create-team-modal"
        size="md"
        centered
        show={showModalTeam}
      >
        <Modal.Header className="with-title normal">
          <Modal.Title>
            {rowTeam === 0 ? "Create Team" : "Manage Members"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-1 pb-3">
          <div className="team-name d-flex justify-content-between">
            <div>
              <label htmlFor="team_logo">
                {teamLogoApplied ? (
                  <div className="user-pic">
                    <img src={logoData.url} alt="Image" />
                  </div>
                ) : rowTeam > 0 &&
                  teams &&
                  teams.length > 0 &&
                  selectedTeam[0] &&
                  selectedTeam[0].info.image ? (
                  <div className="user-pic">
                    <img src={selectedTeam[0].info.image} alt="Image" />
                  </div>
                ) : (
                  <div className="team-name-short">
                    {formInput.team_name ? initials.join("") : "TN"}
                  </div>
                )}
              </label>
              {formInput.team_name ? formInput.team_name : "Team Name"}
            </div>
            {rowTeam > 0 && (
              <Button
                className="btn-icon m-0"
                variant="outline-light"
                onClick={() => deleteTeamRequest(selectedTeam[0])}
              >
                <img src={TrashIcon} alt="Trash Icon" className="icon-black" />
              </Button>
            )}
          </div>
          <input
            type="file"
            id="team_logo"
            style={{ display: "none" }}
            onChange={handleTeamLogo}
          />
          <div className="team-name-field">
            <Form.Group>
              <FormControl
                id="team_name"
                name="team_name"
                placeholder="Team name"
                value={formInput.team_name}
                onChange={(e) => handleInput(e)}
              />
              {validator.message("team_name", formInput.team_name, "required", {
                className: "text-danger",
              })}
            </Form.Group>
          </div>
          <div className="invite-teamates">
            <div className="invite-title">Invite teammates</div>

            <TagsInput
              value={formInput.emails}
              onChange={handleTags}
              inputProps={{
                className: "react-tagsinput-input w-100",
                placeholder: "Add team members by name or email...",
              }}
            />
            {invitationError ? <div>{ErrorlistItems}</div> : null}
          </div>
          <div className="added-team-members">
            <div className="added-members-title">Team members</div>
            <div className="added-members-lists">
              {rowTeam === 0
                ? inputList.map((x, i) => {
                  return (
                    <div className="member-list d-flex justify-content-between">
                      <div className="member-name">
                        <div>
                          <span>{x.email}</span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <Select
                          className="role_dropdown"
                          options={rolesOptions}
                          styles={customRoleControlStyles}
                          value={x.role}
                          name="role"
                          onChange={(e) => {
                            handleInputChange(e, i, "role");
                          }}
                        />
                        <Button
                          className="custommargin btn-icon"
                          variant="secondary"
                          onClick={() => handleRemoveClick(i)}
                        >
                          <RiCloseLine />
                        </Button>
                      </div>
                    </div>
                  );
                })
                : teamMembers.map((x, i) => {
                  console.log("x", x);
                  return (
                    <div className="member-list d-flex justify-content-between">
                      <div className="member-name">
                        {x.user_name ? (
                          <div className="member-name-short">
                            {x.user_name
                              .replace(/[^a-zA-Z- ]/g, "")
                              .match(/\b\w/g)}
                          </div>
                        ) : null}
                        <div>
                          {x.user_name ? (
                            <strong>{x.user_name}</strong>
                          ) : null}
                          <span>{x.email}</span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <Select
                          className="role_dropdown"
                          options={rolesOptions}
                          styles={customRoleControlStyles}
                          value={rolesOptions.find(
                            ({ value }) => value === x.team_user_role
                          )}
                          name="role"
                          onChange={(e) => {
                            handleInputChange(e, i, "role");
                          }}
                        />
                        <Button
                          className="custommargin btn-icon"
                          variant="secondary"
                          onClick={() => handleRemoveClick(i)}
                        >
                          <RiCloseLine />
                        </Button>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div hidden={!disabled}>
            <Alert variant="info">
              <Spinner animation="border" size="sm" className="mr-2" />
              Please wait while invitation(s) are being sent.
            </Alert>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-light" onClick={handleCloseTeam}>
            Cancel
          </Button>
          {rowTeam === 0 ? (
            <Button
              variant="primary"
              disabled={disabled}
              onClick={() => handleShow("add_team")}
            >
              Done
            </Button>
          ) : (
            <Button
              variant="primary"
              disabled={disabled}
              onClick={() => handleShow("update_team")}
            >
              Update
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <div>
      {localStorage.getItem("expire") !== null &&
        localStorage.getItem("expire") === true ? (
        <Payment isShowing={true} />
      ) : null}

      <Modal
        animation={false}
        className="import-states-modal trial-modal"
        size="lg"
        centered
        show={isShowing}
        backdrop="static"
      >
        <Modal.Body>
          <div className="details">
            <div className="icon">
              <img src={MusicImage} alt="Icon Image" />
            </div>
            <div className="title">Your 3-day trial starts today</div>
            <p>
              You’re in! Your trial gives you <br /> access to all ReCalc
              features.
            </p>
            <p>
              Once your trial ends, you will need to pay for the selected plan
            </p>
          </div>
          <div className="button-holder d-flex align-items-center flex-column">
            <Button variant="primary" onClick={setIsShowingClose}>
              Get Started Now
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={demoModalShow}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="survey-modal"
      >
        <Modal.Body>
          <Slider {...surveySettings}>
            <div>
              <div className="image-holder">
                <img src={DashImage} />
              </div>
              <div className="text-holder">
                <strong>This is where a company can check their progress.</strong>
                Actual revenue is quotes that have been approved. Projected revenue is quotes out that have yet to be approved. You can filter
                by total, year to date, the last 30 days or the last 7 days.
              </div>
            </div>
            <div>
              <div className="image-holder">
                <img src={TeamPopup} />
              </div>
              <div className="text-holder">
                <strong>You can create a team to track your recruiters.</strong>
                Enter in the name of the team in Team Name. You can invite co-workers to the team by entering their email or if they already have a setup account; you can use their name. Inviting team members will change the number of users to your subscription if they are new. It may take a minute to verify that members have been added.
              </div>
            </div>
            <div>
              <div className="image-holder">
                <img src={V1} />
              </div>
              <div className="text-holder">
                <strong>You can check your team's progress</strong>
                Once a team has been created you will be able to see their progress here. You can click on the team and drill down from team, all members, or a single member. You can see calculations and quotes associated with the selections made.
              </div>
            </div>
          </Slider>
          {showFinishedButton ?
            <div className='btn-finish' onClick={handleClose}>Finish</div>
            : ""}
        </Modal.Body>
      </Modal>

      {1 ?
        <div className="dashboard-main pb-5">
          <div className="company-info mb-4">
            <Container fluid>
              <div className="d-flex align-items-center mb-4">
                {settings.data &&
                  settings.data.organization_users &&
                  settings.data.organization_users.length !== 0 &&
                  settings.data.organization_users[0].organization ? (
                  <div className="company-name">
                    {settings &&
                      settings.data.organization_users[0].organization.image ? (
                      <div className="user-pic">
                        <img
                          src={
                            settings.data.organization_users[0].organization.image
                          }
                          alt="Image"
                        />
                      </div>
                    ) : (
                      <div className="company-name-short">
                        {settings.data.organization_users[0].organization.name
                          .replace(/[^a-zA-Z- ]/g, "")
                          .match(/\b\w/g)}
                      </div>
                    )}
                    <div>
                      {settings.data.organization_users[0].organization.name}
                      <small className="text-primary"></small>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="company-revenue-tabs">
                <Card>
                  <Card.Body>
                    {quotations && quotations.length > 0 ? "" : <span class="text-note">*Data displayed is just for Demo.</span>}
                    <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
                      <Tab eventKey="actual-revenue" title="Actual Revenue">
                        <Row>
                          <Col lg={4}>
                            <div className="graph-box-holder border d-flex flex-column h-100">
                              <div className="graph-box d-flex align-items-center">
                                <div className="icon-image primary-icon">
                                  <img src={BadgeIcon} alt="Icon" />
                                </div>
                                <div>
                                  <span className="title">Revenue</span>
                                  <div className="g-value">
                                    <span className="text-success mr-1">$</span>
                                    {revenue ? NumberFormat(revenue.toFixed(2)) : <span class="text-muted">{NumberFormat("1000000")}</span>}
                                  </div>
                                </div>
                              </div>
                              <div className="graph-box d-flex align-items-center">
                                <div className="icon-image info-icon">
                                  <img src={GraphIcon} alt="Icon" />
                                </div>
                                <div>
                                  <span className="title">Gross Profit</span>
                                  <div className="g-value">
                                    <span className="text-success mr-1">$</span>
                                    {grossProfit ? NumberFormat(grossProfit.toFixed(2)) : <span class="text-muted">{NumberFormat("100000")}</span>}
                                  </div>
                                </div>
                              </div>
                              <div className="graph-box d-flex align-items-center">
                                <div className="icon-image success-icon">
                                  <img src={PersentIcon} alt="Icon" />
                                </div>
                                <div>
                                  <span className="title">Gross Profit %</span>
                                  <div className="g-value">
                                    {grossProfit ? NumberFormat(((grossProfit / revenue) * 100).toFixed(2)) : <span class="text-muted">10</span>}<span className="text-info"> %</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col lg={8}>
                            <div className="filter-graph">
                              <Select
                                styles={customGraphControlStyles}
                                isSearchable={false}
                                defaultValue={filterSelectedOption}
                                onChange={(value) => {
                                  handleSelect(value);
                                }}
                                options={filter_options}
                              />
                            </div>
                            <div className="graph-box h-100">
                              <HighchartsReact
                                highcharts={Highcharts}
                                options={options}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Tab>
                      <Tab eventKey="projected-form" title="Projected Revenue">
                        <Row>
                          <Col lg={4}>
                            <div className="graph-box d-flex align-items-center">
                              <div className="icon-image primary-icon">
                                <img src={BadgeIcon} alt="Icon" />
                              </div>
                              <div>
                                <span className="title">Revenue</span>
                                <div className="g-value">
                                  <span className="text-success mr-1">$</span>
                                  {revenue ? NumberFormat(revenue.toFixed(2)) : <span class="text-muted">{NumberFormat("1000000")}</span>}
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="graph-box d-flex align-items-center">
                              <div className="icon-image info-icon">
                                <img src={GraphIcon} alt="Icon" />
                              </div>
                              <div>
                                <span className="title">Gross Profit</span>
                                <div className="g-value">
                                  <span className="text-success mr-1">$</span>
                                  {grossProfit ? NumberFormat(grossProfit.toFixed(2)) : <span class="text-muted">{NumberFormat("100000")}</span>}
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="graph-box d-flex align-items-center">
                              <div className="icon-image success-icon">
                                <img src={PersentIcon} alt="Icon" />
                              </div>
                              <div>
                                <span className="title">Gross Profit %</span>
                                <div className="g-value">
                                  {grossProfit ? NumberFormat(((grossProfit / revenue) * 100).toFixed(2)) : <span class="text-muted">10</span>}<span className="text-info"> %</span>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Tab>
                    </Tabs>
                  </Card.Body>
                </Card>
              </div>
            </Container>
          </div>

          {(isAdmin || isOwner || isManager) ? (
            <Container fluid>
              {renderCreateTeam()}
              <div className="datatable-box">
                <div className="datatable-holder">
                  <div className="team-list py-3 px-4">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-block team-info">
                        {" "}
                        {teams && teams.length ? teams.length : "0"} Teams
                      </div>
                      {!isManager && (
                        <Button
                          variant="light"
                          size="sm"
                          onClick={() => handleShow("create_team")}
                        >
                          <BsPlus /> Create Team
                        </Button>
                      )}
                    </div>
                  </div>
                  <DataTable
                    columns={columns}
                    data={teams}
                    customStyles={customTableStyles}
                    responsive
                    fixedHeader={true}
                    onRowClicked={handleTeamComponent}
                    pagination
                    paginationPerPage={5}
                    paginationRowsPerPageOptions={[5, 15, 25, 50]}
                    paginationComponentOptions={{
                      rowsPerPageText: "Records per page:",
                      rangeSeparatorText: "out of",
                    }}
                  />
                </div>
              </div>
            </Container>
          ) : null}
        </div>
        :
        <Card className="text-center">
          <Card.Body>
            <div className="details">
              <Card.Title className="title mt-5 mb-4">Dashboard Tracking</Card.Title>
              <p>
                You’re currently using the Essential Plan.<br />
                To use the Dashboard Tracking feature, kindly upgrade your Subscription Plan.
              </p>
              <p>
                To upgrade your Plan, click on the link provided below:
              </p>
            </div>
            <div className="button-holder d-flex align-items-center flex-column m-5">
              <Button variant="primary" onClick={() => {
                history.push({
                  pathname: `/change-plan`,
                });
              }}>
                Upgrade Plan
              </Button>
            </div>
          </Card.Body>
        </Card>
      }
      {isPaymentShowing ? (
        <Payment onClose={handleClosePayment} isShowing={isPaymentShowing} />
      ) : null}
      <AfterLogin />
    </div>
  );
}
const mapDispatchToProps = (dispatch) => {
  return {};
};
const mapStateToProps = (state) => {
  return {
    login: state.login.login,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
