import React, { useState, useEffect, useReducer } from "react";
import { connect } from "react-redux";
import { Modal, Button, Form, FormControl } from "react-bootstrap";
import {
  getPlans,
  getSettings,
  updateSubscription,
  simpleSubscription,
} from "./data/globalAction";
import * as Constants from "../core/Constants";
import TrialEndImage from "./assets/images/trial-end.png";
import { CheckoutForm } from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import EditIcon from "./assets/images/icons/edit-pencil.svg";
import EditWhiteIcon from "./assets/images/icons/edit-pencil-white.svg";
import { toast } from "react-toastify";
import Moment from "react-moment";
import { NavLink } from "react-router-dom";
function Payment(props) {
  const [stripePromise, setStripePromise] = useState(() =>
    loadStripe(process.env.REACT_APP_STRIPE_PKEY)
  );
  const [isShowing, setIsShowing] = useState(false);
  const [showModalForm, setShowModalForm] = useState(false);
  const handleCloseModalForm = () => setShowModalForm(false);
  const [plans, setPlans] = useState([]);
  const [rowPlans, setRowPlans] = useState([]);
  const [settings, setSettings] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [tab, setTab] = useState("");
  const [changeYearly, setChangeYearly] = useState(true);

  const [cardInfo, setCardInfo] = useState([]);
  const [formInput, setFormInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      pm_id: "",
      plan_id: "",
      plan_name: "",
      plan_amount: "",
      plan_members: 1,
      promocode: "",
    }
  );

  useEffect(() => {
    setIsShowing(props.isShowing);
    async function fetchPlans() {
      setPlans(await getPlans());
    }
    fetchPlans();
    async function fetchSettings() {
      setSettings(await getSettings());
    }
    fetchSettings();
  }, []);

  const handleInput = (evt) => {
    const name = evt.target.name;
    const newValue = evt.target.value;
    setFormInput({ [name]: newValue });
  };
  const addition = () => {
    setFormInput({ ["plan_members"]: formInput.plan_members + 1 });
  };
  const subtraction = () => {
    if (formInput.plan_members - 1 > 0)
      setFormInput({ ["plan_members"]: formInput.plan_members - 1 });
  };
  const handleShow = async (index) => {
    if (index === "change_yearly") {
      setRowPlans(plans[5]);
      setFormInput({ ["plan_id"]: plans[5].stripe_id });
      setFormInput({ ["plan_name"]: plans[5].name });
      setFormInput({ ["plan_amount"]: plans[5].amount });
      setChangeYearly(false);
    } else if (index === "form") {
      // if (
      //   settings &&
      //   settings.data &&
      //   settings.data.organization_users &&
      //   settings.data.organization_users.length > 0 &&
      //   settings.data.organization_users[0].organization &&
      //   settings.data.organization_users[0].organization.subscription
      // ) {
      // } else {
      setRowPlans(plans[2]);
      setFormInput({ ["plan_id"]: plans[2].stripe_id });
      setFormInput({ ["plan_name"]: plans[2].name });
      setFormInput({ ["plan_amount"]: plans[2].amount });
      // }
      console.log("form", settings, plans);
      setTab(index);
    } else if (index === "add_plan") {
      setTab(index);
    }

    console.log("index", index);
  };
  const verifyCard = async (error, info) => {
    console.log("verifyCard", error, info);
    if (error && error.message) {
      toast.error(error.message);
      setDisabled(false);
    } else {
      const name = "pm_id";
      const newValue = info.id;
      setFormInput({ [name]: newValue });
      let data = {
        pm_id: info.id,
        plan_id: formInput.plan_id,
        plan_members: formInput.plan_members,
      };
      let result = await simpleSubscription(JSON.stringify(data));
      if (result.status === 200) {
        props.onClose();
        toast.success(Constants.update_message);
      }
      setDisabled(false);
    }
  };
  function getTabContent() {
    switch (tab) {
      case "add_plan":
        return (
          <div>
            <Modal.Header className="with-title normal text-center middle-modal-content">
              <Modal.Title className="w-100">
                <div className="d-flex align-items-center flex-column justify-content-between p-3">
                  Invoice from Recalc
                  <span>
                    {settings &&
                    settings.data &&
                    settings.data.organization_users &&
                    settings.data.organization_users.length > 0 &&
                    settings.data.organization_users[0].organization &&
                    settings.data.organization_users[0].organization.name ? (
                      <>
                        Billed to{" "}
                        {settings.data.organization_users[0].organization.name}
                      </>
                    ) : null}
                  </span>
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="text-center font-weight-light px-3 pb-5">
                <span className="text-success ">$</span>{" "}
                {formInput.plan_amount.toFixed(2)} due to{" "}
                <Moment format="DD MMMM, YYYY">
                  {Date().toLocaleString()}
                </Moment>
              </div>
              <div className="stripe-card-trial-info">
                <Elements stripe={stripePromise}>
                  <CheckoutForm
                    verifyCard={verifyCard}
                    buttonText="Pay Invoice"
                    disabled={disabled}
                    setDisabled={setDisabled}
                    setCardInfo={setCardInfo}
                  />
                </Elements>
              </div>
            </Modal.Body>
          </div>
        );
      case "form":
        return (
          <div>
            <Modal.Header className="with-title normal">
              <Modal.Title>
                Your plan{" "}
                <NavLink
                  variant="outline-dark"
                  to="/change-plan"
                  className="btn btn-outline-dark btn-edit-custom ml-2"
                >
                  <div className="icon">
                    <img
                      src={EditIcon}
                      alt="Edit Icon"
                      className="icon-black"
                    />
                    <img
                      src={EditWhiteIcon}
                      alt="Edit Icon"
                      className="icon-white"
                    />
                  </div>
                  Edit
                </NavLink>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="payment-plan-details">
              <Form.Group>
                <Form.Label>Plan</Form.Label>
                <FormControl
                  id="package_name"
                  name="package_name"
                  value={formInput.plan_name}
                  disabled={true}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Billing</Form.Label>
                <div className="d-flex justify-content-between align-items-center">
                  <FormControl
                    id="plan_amount"
                    name="plan_amount"
                    value={
                      rowPlans && rowPlans.frequency === "1"
                        ? "Monthly"
                        : "Yearly"
                    }
                    disabled={true}
                  />
                  {changeYearly ? (
                    <small>
                      <a onClick={(e) => handleShow("change_yearly")}>
                        Switch to annual and save{" "}
                        <strong>
                          {plans[2].amount * 12 - plans[5].amount}{" "}
                          <span className="text-success">$</span> / yr
                        </strong>
                      </a>
                    </small>
                  ) : null}
                </div>
              </Form.Group>
              <Form.Group>
                <Form.Label>Member</Form.Label>
                <div className="d-flex align-items-center justify-content-between">
                  <FormControl
                    type="number"
                    id="plan_members"
                    name="plan_members"
                    disabled
                    value={formInput.plan_members}
                    onChange={handleInput}
                  />
                  <div className="d-flex align-items-center">
                    <Button variant="outline-light" onClick={subtraction}>
                      -
                    </Button>
                    <div className="member-value">{formInput.plan_members}</div>
                    <Button variant="outline-light" onClick={addition}>
                      +
                    </Button>
                  </div>
                </div>
              </Form.Group>
              <Form.Group>
                <Form.Label>Add promocode</Form.Label>
                <FormControl
                  id="promocode"
                  name="promocode"
                  value={formInput.promocode}
                  onChange={handleInput}
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer className="p-4">
              <Button
                variant="primary"
                block
                className="my-2"
                disabled={disabled}
                onClick={(e) => handleShow("add_plan")}
              >
                Confirm plan
              </Button>
              <small className="text-muted">
                By continuing, you are agreeing to our subscriber terms. You
                will be automatically charged for your subscription at the end
                the trial period, and after one year, your monthly per seat
                price will increase to 13,49 $. Your subscription will
                automatically renew until you cancel.
              </small>
            </Modal.Footer>
          </div>
        );
      default:
        return (
          <>
            <Modal.Header></Modal.Header>
            <Modal.Body>
              <div className="details">
                <div className="icon">
                  <img src={TrialEndImage} alt="Icon Image" />
                </div>
                <div className="title">Trial periodis over</div>
                <p>
                  To continue using the platform, you need <br /> to confirm
                  &amp; pay for the plan.
                </p>
              </div>
              <div className="button-holder d-flex align-items-center flex-column">
                <Button
                  variant="primary"
                  className="px-5"
                  onClick={(e) => handleShow("form")}
                >
                  Confirm plan
                </Button>
              </div>
            </Modal.Body>
          </>
        );
    }
  }
  return (
    <div>
      <Modal
        animation={false}
        className="import-states-modal trial-modal"
        size="md"
        centered
        show={isShowing}
        backdrop="static"
      >
        {getTabContent()}
      </Modal>
    </div>
  );
}

export default Payment;
