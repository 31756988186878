import React, { useEffect } from "react";

import Container from "@material-ui/core/Container";
import { connect } from "react-redux";
import { signOutAttempt } from "./data/loginAction";

function SignOut(props) {
  useEffect(() => {
    localStorage.removeItem("headers");
    localStorage.removeItem("isShowing");
    props.signOutAttempt();

    window.location.href = process.env.REACT_APP_URL + "/signin";
  });

  return <Container />;
}

const mapDispatchToProps = (dispatch) => {
  return {
    signOutAttempt: (data) => dispatch(signOutAttempt()),
  };
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SignOut);
