import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import Logo from "../assets/images/logo.svg";
import LogoSmall from "../assets/images/logo-small.svg";
import { connect } from "react-redux";
import PlusIcon from "../assets/images/icons/plusicon.svg";
import InboxIcon from "../assets/images/icons/inbox.svg";
import {
  Container,
  Col,
  Row,
  Modal,
  Button,
  Form,
  FormControl,
} from "react-bootstrap";
import { ReactComponent as DashIcon } from '../assets/images/icons/dashboard.svg';
import { ReactComponent as PlusCIcon } from '../assets/images/icons/plus-circle.svg';
import { ReactComponent as QuotesIcon } from '../assets/images/icons/quotes.svg';
import { ReactComponent as InboxNIcon } from '../assets/images/icons/inboxNav.svg';
import NavToggle from "../assets/images/icons/nav-toggle.svg";
import { IoCloseCircleSharp } from "react-icons/io5";
import { HashLink } from 'react-router-hash-link';
import {
  getSettings,
  getNotifications,
  readNotification,
  readAllNotification,
} from "../data/globalAction";
import moment from "moment";
import SignUp from "../SignUp";

function Navigation(props) {
  let history = useHistory();
  const [login, setLogin] = useState([]);
  const [settings, setSettings] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [formattedNotif, setFormattedNotif] = useState([]);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [mainNavToggle, setMainNavToggle] = useState(false);


  const handleOverlays = () => setToggle(!toggle);
  const handleToggleClick = () => setToggle(!toggle);
  const handleNavToggleClick = () => {
    setMainNavToggle(!mainNavToggle);
    document.querySelector('html').classList.toggle('collapsed');
  };

  const handleNotifications = async () => {
    let result = await getNotifications();
    if (result.status === 200) {
      // console.log("notf result", result.data.notification);
      setNotifications(result.data.notification);
    }
  };

  const handleNotifClick = async (link, id) => {
    let result = await readNotification({ id: id });
    if (result.status === 200) {
      // window.open(link).focus();
      history.push(link);
      handleNotifications();
    }
  };

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  }


  const handleReadAllNotif = async () => {
    let result = await readAllNotification();
    if (result.status === 200) {
      handleNotifications();
    }
  };

  useEffect(() => {
    if (props.login && props.login.data && props.login.data.length !== 0) {
      setLogin(props.login);
      setTimeout(() => {
        handleNotifications();

        async function fetchSettings() {
          setSettings(await getSettings());
        }
        fetchSettings();
      }, 2000);
    }
  }, []);
  useEffect(() => {
    console.log("navigation");
    setLogin(props.login);
  }, [props.login]);

  useEffect(() => {
    let formattedNotifCopy = [];
    let readAllOption = (
      <div className="d-flex justify-content-between p-4">
        <strong>Messages</strong>
        <NavDropdown.Item className="notif_item p-0 d-inline-flex justify-content-end" onClick={handleReadAllNotif}>
          <div>Mark all as Read</div>
        </NavDropdown.Item>
      </div>
    );
    formattedNotifCopy.push(readAllOption);
    if (notifications.length > 0) {
      let notifCount = 0;
      notifications.forEach((n, idx) => {
        if (!n.read) {
          notifCount++;
        }
        if (
          n.notification_type == "calculation_status" ||
          n.notification_type == "quotation_status"
        ) {
          let notif_option = (
            <div className="mess-list">
              <NavDropdown.Item
                className={`notif_item ${!n.read ? "not_read" : "read"}`}
                onClick={() => handleNotifClick(n.link, n.id)}
              >
                <p>{n.message}</p>
                <p className="light-text">{moment(n.created_at).fromNow()}</p>
              </NavDropdown.Item>

              <NavDropdown.Divider></NavDropdown.Divider>
            </div>
          );
          if (!n.read)
            formattedNotifCopy.push(notif_option);
        }
      });
      setNotificationsCount(notifCount);
    }
    setFormattedNotif([...formattedNotifCopy]);
  }, [notifications]);


  const startCalculation = () => {
    if (localStorage.getItem("getStarted") === "true") {
      localStorage.setItem("getStarted", false)
      window.location = '/calculator';
    }

  }

  return (
    <div className="header-main">
      {login && login.data && login.data.length !== 0 ? (
        <Navbar collapseOnSelect id="header-dashboard" className={`fixed-nav ${mainNavToggle ? 'collapsed' : 'not-collapsed'}`} expand="lg">
          <div className="logo">
            {
              mainNavToggle ?
                <Nav.Link as={NavLink} eventKey="1" to="/" className="p-0">
                  <img src={LogoSmall} />
                </Nav.Link> :
                <Nav.Link as={NavLink} eventKey="1" to="/" className="p-0">
                  <img src={Logo} />
                </Nav.Link>
            }
            <button className="btn btn-link toggle-nav" onClick={handleNavToggleClick}>
              <img src={NavToggle} />
            </button>
          </div>
          <div className="toggle-nav-mobile">
            <Nav>
              <NavDropdown
                className="nav-option user-dropdown"
                title={
                  <div className="d-flex align-items-center">
                    {login.data &&
                      login.data.data &&
                      login.data.data.image ? (
                      <div className="user-pic">
                        <img src={login.data.data.image} alt="Image" />
                      </div>
                    ) : (
                      <div className="name-char">
                        {login.data.data.first_name
                          .charAt(0)
                          .toUpperCase() +
                          login.data.data.last_name.charAt(0).toUpperCase()}
                      </div>
                    )}
                  </div>
                }
              >
                <NavDropdown.Item as={NavLink} eventKey="8" to="/settings">
                  Settings
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} eventKey="9" to="/learning-center">
                  Learning Center
                </NavDropdown.Item>
                <NavDropdown.Item href="/logout">Logout</NavDropdown.Item>
              </NavDropdown>
            </Nav>
            <button className="btn btn-link " onClick={handleToggleClick}>
              <img src={NavToggle} />
            </button>
          </div>
          <div
            id="basic-navbar-nav"
            className={`${toggle
              ? "navbar-collapse collapse show"
              : "navbar-collapse collapse"
              }`}
          >
            <div className="d-flex justify-content-between align-items-center flex-column w-100 nav-holder">
              <Nav>
                <Nav.Link as={NavLink} eventKey="2" to="/dashboard">
                  <div className="icon other"><DashIcon /></div>
                  Dashboard
                </Nav.Link>
                <Nav.Link as={NavLink} eventKey="3" to="/calculations">
                  <div className="icon"><PlusCIcon /></div>
                  Calculations
                </Nav.Link>
                {settings?.plan_name !== "Essential" ?
                  <Nav.Link as={NavLink} eventKey="4" to="/quotes">
                    <div className="icon"><QuotesIcon /></div>
                    Quotes
                  </Nav.Link>
                  :
                  ""
                }
                <Nav.Link
                  as={NavLink}
                  eventKey="5"
                  to={localStorage.getItem("getStarted") === "true" ? "#" : '/calculator'}
                  onClick={startCalculation}
                >
                  <div className="icon"><PlusCIcon /></div>
                  Calculator {settings?.data?.organization_users.length > 0 && (settings?.data?.organization_users[0]?.organization?.business_domain === "demo" || localStorage.getItem("getStarted") === "true") ? (<div className="get-started" style={{position:"absolute",right:"-18px",top:"1px",marginLeft:"125px"}}>Click here to get started</div>) : null}
                </Nav.Link>

                <Nav className="d-flex align-items-center dropright messages-dropdown">
                  <NavDropdown
                    as="a"
                    eventKey="7"
                    className="dropdownAfter"
                    onClick={handleNotifications}
                    title={
                      <a className="inbox">
                        <div className="icon position-relative">
                        {notificationsCount > 0 &&
                          <div className="position-absolute mb-4 text-white d-flex justify-content-center align-items-center" 
                          style={{top:"-4px" ,left:"-6px",height:"15px",width:"15px",borderRadius:"50%",background:"red"
                            }}>{notificationsCount}</div>}
                          <InboxNIcon />
                        </div>
                        Messages
                      </a>
                    }
                  >
                    <div>
                      {formattedNotif}
                      {formattedNotif.length <= 1 ? (
                        <NavDropdown.Item className="no-notification"><IoCloseCircleSharp /> No Unread Notifications</NavDropdown.Item>
                      ) : null}
                    </div>
                    {/* <NavDropdown.Item>notf 1</NavDropdown.Item>
                  <NavDropdown.Divider></NavDropdown.Divider>
                  <NavDropdown.Item>notf 2</NavDropdown.Item> */}
                    <Link to="/notifications" className="view-all-notification">View all Notifications</Link>
                  </NavDropdown>
                </Nav>
               
                <Nav.Link
                  as={NavLink}
                  className="mob-nav"
                  eventKey="6"
                  to="/settings"
                >
                  <div className="icon other"><DashIcon /></div>
                  Settings
                </Nav.Link>

                <Nav.Link href="/logout" className="mob-nav">
                  Logout
                </Nav.Link>
              </Nav>
              <Nav>
                <div className="d-flex align-items-center">
                  <NavDropdown
                    className="nav-option user-dropdown"
                    title={
                      <div className="d-flex align-items-center">
                        {login.data &&
                          login.data.data &&
                          login.data.data.image ? (
                          <div className="user-pic">
                            <img src={login.data.data.image} alt="Image" />
                          </div>
                        ) : (
                          <div className="name-char">
                            {login.data.data.first_name
                              .charAt(0)
                              .toUpperCase() +
                              login.data.data.last_name.charAt(0).toUpperCase()}
                          </div>
                        )}
                        <div className="d-flex flex-column">
                          {login.data.data.first_name +
                            " " +
                            login.data.data.last_name}
                          {login.data.data.user_role ? (
                            <small>{login.data.data.user_role}</small>
                          ) : null}
                        </div>
                      </div>
                    }
                  >
                    <NavDropdown.Item as={NavLink} eventKey="8" to="/settings">
                      Settings
                    </NavDropdown.Item>
                    <NavDropdown.Item as={NavLink} eventKey="9" to="/learning-center">
                      Learning Center
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/logout">Logout</NavDropdown.Item>
                  </NavDropdown>
                </div>
              </Nav>
            </div>
          </div>
        </Navbar>
      ) : (
        <Navbar collapseOnSelect id="header" expand="lg" fixed="top">
          <div className="logo">
            <Nav.Link as={NavLink} eventKey="1" to="/">
              <img src={Logo} />
            </Nav.Link>
          </div>
          <Navbar.Toggle
            onClick={handleToggleClick}
            aria-controls="basic-navbar-nav"
          />
          <div
            id="basic-navbar-nav"
            className={`${toggle
              ? "navbar-collapse collapse show"
              : "navbar-collapse collapse"
              }`}
          >
           <div className="d-flex justify-content-between align-items-center w-100">
  <Nav>
    <HashLink
      smooth
      to="/#platform"
      className="ml-4"
      scroll={scrollWithOffset}
    >
      Platform
    </HashLink>
    <HashLink
      className="ml-4"
      smooth
      to="/#pricing"
      scroll={scrollWithOffset}
    >
      Pricing
    </HashLink>
    <HashLink
      smooth
      to="/#contact"
      className="ml-4"
      scroll={scrollWithOffset}
    >
      Contact
    </HashLink>
  </Nav>
  <Nav className="d-flex align-items-center">
    <Nav.Link as={NavLink} eventKey="5" to="/signin">
      Sign in
    </Nav.Link>
    <Nav.Link
      as={NavLink}
      eventKey="6"
      className="btn btn-primary ml-3 px-4"
      to="/signup"
    >
      Sign up
    </Nav.Link>
    
  </Nav>
</div>

          </div>


        </Navbar>
      )}
      <div
        className={`overlay ${toggle ? "active" : ""}`}
        onClick={handleOverlays}
      ></div>
    </div>
  );
}
const mapDispatchToProps = (dispatch) => {
  return {};
};
const mapStateToProps = (state) => {
  return {
    login: state.login.login,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
