import React, { useEffect, useState, useCallback, useReducer } from "react";
import {
  getSettings,
  getTeam,
  saveOrganizationTeamAttempt,
  updateOrganizationTeamAttempt,
  getRolesAttempt,
  setTeamLogo,
  getQuotations,
  sendQuoteViaEmail,
  getCalculations
} from "./data/globalAction";
import {
  Container,
  Button,
  Form,
  Modal,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import moment from "moment";
import DataTable from "react-data-table-component";
import { useHistory, useParams, NavLink } from "react-router-dom";
import ExcelImage from "../component/assets/images/xlsx.png";
import DownloadIcon from "../component/assets/images/icons/download.svg";
import SearchIcon from "../component/assets/images/icons/search.svg";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import Payment from "./Payment";
import QuoteTab from "../component/assets/images/learning/learning-new/quote-tab.png";
import Slider from "react-slick";


function QuotesPage({ member_id }) {
  const [calculations, setCalculations] = useState([]);
  const { id } = useParams();
  const [teamData, setTeamData] = useState([]);
  const [settings, setSettings] = useState([]);
  const [isPaymentShowing, setIsPaymentShowing] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [qStatus, setQstatus] = useState("");
  const [email, setEmail] = useState(null);
  const [link, setLink] = useState(null);
  const [activeClass, setActiveClass] = useState(true);
  const [filterText, setFilterText] = React.useState("");
  const [filterVal, setFilterVal] = useState("");
  const handleTeamComponent = useCallback((state) => handleRowClick(state));

  let history = useHistory();
  const [uuid, setUuid] = useState("");
  const [downloadId, setDownloadId] = useState("");
  const [totals, setTotals] = useState([]);
  const [clients, setClients] = useState([]);
  const [members, setMembers] = useState([]);
  const [quotations, setQuotations] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [showSendToClientModal, setShowSendToClientModal] = useState(false);
  const [demoModalShow, setDemoModalShow] = useState(false);
  const [showFinishedButton, setShowFinishedButton] = useState(true);

  const handleDownloadModal = () => {
    setDisabled(!disabled);
    // setShowDownloadModal(!showDownloadModal);
  };
  const handleSendToClientModal = () => {
    setShowSendToClientModal(!showSendToClientModal);
    setEmail(null);
    setLink(null);
  };

  const handleRowClick = (row) => {
    history.push(`/quote/${row.id}`);
  };
  const customTableStyles = {
    header: {
      style: {
        padding: "0",
        minHeight: "inherit",
      },
    },
    subHeader: {
      style: {
        padding: "0",
        justifyContent: "flex-start",
        display: "block",
        border: "1px solid #E5E8F5",
        borderBottom: "none",
        borderRadius: "6px 6px 0 0",
      },
    },
    tableWrapper: {
      style: {
        border: "1px solid #E5E8F5",
        borderTop: "none",
      },
    },
    headRow: {
      style: {
        color: "#43536D",
        borderColor: "#E5E8F5",
        borderStyle: "solid",
        borderWidth: "1px",
        borderLeftWidth: "0",
        borderRightWidth: "0",
        backgroundColor: "#FCFCFF",
      },
    },
    headCells: {
      style: {
        fontSize: "13px",
        fontWeight: "normal",
        color: "#767778",
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: "#fff",
        },
      },
    },
    rows: {
      style: {
        minHeight: "65px",
        "&:not(:last-of-type)": {
          borderBottomStyle: "solid",
          borderBottomWidth: "1px",
          borderBottomColor: "#E5E8F5",
        },
      },
    },
    cells: {
      style: {
        minHeight: "65px",
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: "#fff",
        },
      },
    },
    pagination: {
      style: {
        border: "1px solid #E5E8F5",
        borderTop: "none",
        borderRadius: "0 0 6px 6px",
      },
    },
  };
  const customControlStyles = {
    control: (base, state) => ({
      ...base,
      minHeight: 40,
      background: state.isFocused ? "#fff" : "#fff",
      borderWidth: 1,
      borderRadius: "8px",
      borderColor: state.isFocused ? "#7848FF" : "#E5E8F5",
      boxShadow: state.isFocused ? null : null,
      fontSize: "14px",
      "&:hover": {
        borderColor: state.isFocused ? "#7848FF" : null,
      },
    }),
    dropdownIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
      width: "32px",
      color: "#142433",
    }),
    menu: (base) => ({
      ...base,
      fontSize: "13px",
      border: "1px solid #f1f3f6",
      boxShadow: "none",
    }),
    option: (base, state) => ({
      ...base,
      background: state.isSelected ? "#f1f3f6" : "#fff",
      color: "#151B26",
      "&:hover": {
        background: "#f1f3f6",
        color: "#151B26",
      },
    }),
    placeholder: (base) => ({
      ...base,
      color: "#142433",
    }),
    clearIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
  };

  const handleDatepickerFocus = (e) => {
    e.target.parentNode.parentNode.parentNode.classList.add("active");
  };
  const handleDatepickerBlur = (e) => {
    e.target.parentNode.parentNode.parentNode.classList.remove("active");
  };
  const handleDateChange = (date, e) => {
    setStartDate(date);
    e.target.closest(".input-group").classList.remove("active");
  };
  const handleClosePayment = () => {
    localStorage.removeItem("expire");
    setIsPaymentShowing(false);
  };

  const copyLink = () => {
    var copyText = document.getElementById("copyLink");
    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    document.execCommand("copy");
    document.getElementById("copyLinkButton").textContent = "Copied!";
    setTimeout(() => {
      document.getElementById("copyLinkButton").textContent = "Copy link";
    }, 2000);
  };

  const downloadRequest = (id, type) => {
    let data = {
      id: id,
      type: type,
    };
    async function getDownload() {
      window
        .open(
          `${process.env.REACT_APP_API_URL}/quotations/download/${data.id}/${data.type}`
        )
        .focus();
    }
    getDownload();
  };


  const columns = [
    {
      name: "Quote#",
      selector: "invoice",
      sortable: true,
    },
    {
      name: "Client:",
      selector: "client_name",
      sortable: true,
    },
    {
      name: "Member:",
      selector: "user",
      sortable: true,
      cell: (row) => (
        <div>
          <div>{row.user.first_name + " " + row.user.last_name}</div>
          <small className="text-muted">{row.user.email}</small>
        </div>
      ),
    },
    {
      name: "Total:",
      selector: "total",
      sortable: true,
      cell: (row) => (
        <div>
          <span className="text-success">$</span> {(row.revenue + row.vat).toFixed(2)}
        </div>
      ),
    },
    {
      name: "Date",
      selector: "issue_date",
      sortable: true,
      cell: (row) => moment(row.issue_date).format("MM/DD/YYYY"),
    },
    {
      name: "Status:",
      selector: "status",
      cell: (row) => (
        <div className="q-status-section">
          <div
            className={
              row.status === "New quotations" ||
                row.status === "Waiting for approval"
                ? "badge badge-info mr-2"
                : row.status === "Not Approved"
                  ? "badge badge-danger mr-2"
                  : "badge badge-success mr-2"
            }
          >
            {row.status}
          </div>
          <Button className="btn-icon" variant="outline-light">
            <img
              src={DownloadIcon}
              alt="Icon"
              onClick={() => {
                setDownloadId(row.id);
                downloadRequest(row.id, "pdf");
              }}
            />
          </Button>
          <Button
            className="ml-2 btn-send"
            variant="outline-light"
            onClick={() => {
              setUuid(row.uuid);
              handleSendToClientModal();
              setLink(`${window.location.origin}/quote/approve/${row.uuid}`);
            }}
          >
            Send
          </Button>
        </div>
      ),
    },
  ];

  const [filters, setFilters] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      status: "",
      client: "",
      member: "",
      total: "",
      date: "",
      search: "",
    }
  );

  const statuses = [
    { label: "Approved", value: "Approved" },
    { label: "Not approved", value: "Not approved" },
    { label: "New quotations", value: "New quotations" },
    { label: "Sent to approval", value: "Sent to approval" },
  ];

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}
      >Next</div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}
      >Back</div>
    );
  }
  const surveySettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    // afterChange: function (currentSlide) {
    //   if(currentSlide == 1) {
    //     setShowFinishedButton(true)
    //   } else {
    //     setShowFinishedButton(false)
    //   }
    // }
  };

  const handleFilter = (index, e, eTarget = null) => {
    let filtersCopy = filters;
    if (index === "date") {
      filtersCopy[index] = e;
      eTarget.target.closest(".input-group").classList.remove("active");
    } else {
      if (e) {
        filtersCopy[index] = e.value;
      } else {
        filtersCopy[index] = "";
      }
    }
    setFilters({ ...filtersCopy });
  };

  const filtersIsSet = () => {
    let set = false;
    Object.keys(filters).forEach((k, idx) => {
      if (filters[k]) {
        set = true;
      }
    });
    return set;
  };

  const isUnique = (data, revenue) => {
    let valueIsUnique = true;
    data.forEach((r, idx) => {
      if (r.value == revenue) {
        valueIsUnique = false;
      }
    });
    return valueIsUnique;
  };

  const handleClose = () => {
    setDemoModalShow(false);
    setShowFinishedButton(false);
    localStorage.setItem("quotationDemo", false);
  };

  useEffect(() => {
    document.querySelector('html').classList.remove('calculation-details-page');
    document.querySelector("html").classList.remove("homepage");  
    fetchCalculations();

    async function fetchSettings() {
      setSettings(await getSettings());
    }
    fetchSettings();

    if (localStorage.getItem("quoteDemo") === "true") {
      setTimeout(() => {
        setDemoModalShow(true);
      }, 500);
    }
  }, []);

  async function fetchCalculations() {
    // console.log("props.id", id);
    setFilterVal(id);
    let data = [];
    let data2 = {};
    if (member_id != null) {
      data2 = {
        member_id: member_id,
      };
    }
    let result = await getCalculations(data2);
    if (
      result &&
      result.data &&
      result.data.calculation &&
      result.data.calculation.length > 0
    ) {
      result.data.calculation.forEach((element) => {
        // console.log("result", element.id);
        data.push({
          id: element.id,
          title: element.name,
          revenue: element.revenue,
          date: element.created_at,
          organization_team: element.organization_team,
          user: element.user,
          status: element.status,
        });
      });
      setCalculations(data);
    }
  }

  useEffect(() => {
    async function fetchTeam() {
      console.log("props.id", id);
      setFilterVal(id);

      let team = await getTeam(
        JSON.stringify({
          team_id: id,
        })
      );
      if (
        team &&
        team.data &&
        team.data.organization_team_users &&
        team.data.organization_team_users.length > 0
      ) {
        setTeamData(team.data.organization_team_users);
        // console.log(
        //   "team.data.organization_team_users",
        //   team.data.organization_team_users
        // );
      }
    }
    fetchTeam();

    async function fetchQuotations() {
      let data = {};
      if (member_id != null) {
        data = {
          member_id: member_id,
        };
      }
      let result = await getQuotations(data);
      if (result.status === 200) {
        setQuotations([...result.data.quotation]);
        console.log("quotations", result.data.quotation);
      }
    }
    fetchQuotations();

    async function fetchSettings() {
      setSettings(await getSettings());
    }
    fetchSettings();
    if (localStorage.getItem("quotationDemo") === "true") {
      setTimeout(() => {
        setDemoModalShow(true);
      }, 500);
    }
  }, []);

  useEffect
    (() => {
      if (settings.expire && settings.expire === true) {
        setIsPaymentShowing(true);
      }
    }, [settings]);

  useEffect(() => {
    if (quotations.length > 0) {
      let totalsCopy = [];
      let clientsCopy = [];
      let membersCopy = [];
      quotations.forEach(async (q, idx) => {
        if (q.revenue + q.vat != null) {
          if (isUnique(totalsCopy, (q.revenue + q.vat))) {
            totalsCopy.push({ value: (q.revenue + q.vat), label: `$ ${q.revenue + q.vat}` });
          }
        }
        if (q.client_name != null) {
          if (isUnique(clientsCopy, q.client_name)) {
            clientsCopy.push({ value: q.client_name, label: q.client_name });
          }
        }
        if (q.user != null) {
          if (
            isUnique(membersCopy, q.user.first_name + " " + q.user.last_name)
          ) {
            membersCopy.push({
              value: q.user.first_name + " " + q.user.last_name,
              label: q.user.first_name + " " + q.user.last_name,
            });
          }
        }
      });
      setMembers([...membersCopy]);
      setTotals([...totalsCopy]);
      setClients([...clientsCopy]);
    }
  }, [quotations]);

  useEffect(() => {
    console.log("filters", filters);
    let quotationsCopy = quotations;
    let filteredDataCopy = [];
    // Filtering based on status
    if (filters.status) {
      quotationsCopy.forEach((c, idx) => {
        if (c.status == filters.status) {
          filteredDataCopy.push(c);
        }
      });
      quotationsCopy = [...filteredDataCopy];
      filteredDataCopy = [];
    }
    if (filters.date) {
      quotationsCopy.forEach((c, idx) => {
        if (
          moment(c.issue_date).format("MM/DD/YYYY") ==
          moment(filters.date).format("MM/DD/YYYY")
        ) {
          filteredDataCopy.push(c);
        }
      });
      quotationsCopy = [...filteredDataCopy];
      filteredDataCopy = [];
    }
    if (filters.client) {
      quotationsCopy.forEach((c, idx) => {
        if (c.client_name == filters.client) {
          filteredDataCopy.push(c);
        }
      });
      quotationsCopy = [...filteredDataCopy];
      filteredDataCopy = [];
    }
    if (filters.total) {
      quotationsCopy.forEach((c, idx) => {
        if ((c.revenue + c.vat) == filters.total) {
          filteredDataCopy.push(c);
        }
      });
      quotationsCopy = [...filteredDataCopy];
      filteredDataCopy = [];
    }
    if (filters.member) {
      quotationsCopy.forEach((c, idx) => {
        let name = c.user.first_name + " " + c.user.last_name;
        if (name == filters.member) {
          filteredDataCopy.push(c);
        }
      });
      quotationsCopy = [...filteredDataCopy];
      filteredDataCopy = [];
    }
    if (filters.search) {
      let searchField = filters.search.toLowerCase();
      // console.log("searchField", searchField);
      quotationsCopy.forEach((c, idx) => {
        let check = false;
        let name = c.user.first_name + " " + c.user.last_name;
        if (c.client_name) {
          if (c.client_name.toLowerCase().includes(searchField)) {
            check = true;
          }
        }
        if (c.total) {
          if (c.total.toString().startsWith(searchField)) {
            check = true;
          }
        }
        if (c.status) {
          if (c.status.toString().toLowerCase().includes(searchField)) {
            check = true;
          }
        }
        if (c.invoice) {
          if (c.invoice.toLowerCase().includes(searchField)) {
            check = true;
          }
        }
        if (name) {
          if (name.toLowerCase().includes(searchField)) {
            check = true;
          }
        }
        // if (c.organization_team) {
        //   if (c.organization_team.name.includes(searchField)) {
        //     check = true;
        //   }
        // }
        // if (c.user) {
        //   if (c.user.first_name) {
        //     let full_name = (
        //       c.user.first_name +
        //       " " +
        //       c.user.last_name
        //     ).toLowerCase();
        //     if (full_name.includes(filters.search)) {
        //       check = true;
        //     }
        //   }
        // }
        if (check) {
          filteredDataCopy.push(c);
        }
      });
      quotationsCopy = [...filteredDataCopy];
      filteredDataCopy = [];
    }
    setFilteredData([...quotationsCopy]);
  }, [filters]);

  const sendQuoteEmail = () => {
    setDisabled(true);
    async function sendQuote() {
      let result = await sendQuoteViaEmail(
        JSON.stringify({
          email: email,
          quote_link: link
        })
      );
      if (result.status === 200) {
        toast.success(result.data.message);
        setDisabled(false);
        handleSendToClientModal();
      } else {
        toast.error("Error, Please try again.");
      }
    }
    sendQuote();
  }

  const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <div className="d-flex align-items-center justify-content-between tableHead">
      <div className="table-filters">
        <Form.Group className="mb-0">
          <Select
            styles={customControlStyles}
            options={statuses}
            placeholder="Status"
            isClearable={true}
            onChange={(e) => handleFilter("status", e)}
            value={statuses.find((s) => {
              return s.value === filters.status;
            })}
          />
        </Form.Group>
        <Form.Group className="mb-0">
          <Select
            styles={customControlStyles}
            options={clients}
            placeholder="Client"
            isClearable={true}
            onChange={(e) => handleFilter("client", e)}
            value={clients.find((t) => {
              return t.value === filters.client;
            })}
          />
        </Form.Group>
        <Form.Group className="mb-0">
          <Select
            styles={customControlStyles}
            options={members}
            placeholder="Member"
            isClearable={true}
            onChange={(e) => handleFilter("member", e)}
            value={members.find((t) => {
              return t.value === filters.member;
            })}
          />
        </Form.Group>
        <Form.Group className="mb-0">
          <Select
            styles={customControlStyles}
            options={totals}
            placeholder="Total"
            isClearable={true}
            onChange={(e) => handleFilter("total", e)}
            value={totals.find((r) => {
              return r.value === filters.total;
            })}
          />
        </Form.Group>
        <Form.Group className="datepicker mb-0">
          <InputGroup className={`${activeClass ? "" : "input-group"}`}>
            <InputGroup.Prepend>
              <InputGroup.Text className="pr-0">
                <span className="icon"></span>
              </InputGroup.Text>
            </InputGroup.Prepend>
            <DatePicker
              selected={filters.date}
              isClearable={true}
              // minDate={new Date()}
              className="datepicker-form-control"
              onChange={(date, e) => handleFilter("date", date, e)}
              onFocus={handleDatepickerFocus}
              onBlur={handleDatepickerBlur}
            />
          </InputGroup>
        </Form.Group>
      </div>
      <div className="table-filters justify-content-end">
        <InputGroup>
          <Form.Control
            type="text"
            autoFocus={true}
            id="employee_only"
            name="employee_only"
            placeholder="Search"
            value={filters.search}
            onFocus={(e) => e.target.parentNode.classList.add("active")}
            onBlur={(e) => {
              e.target.parentNode.classList.remove("active");
            }}
            onChange={(e) => setFilters({ ...filters, search: e.target.value })}
          />
          <InputGroup.Append>
            <InputGroup.Text>
              <img src={SearchIcon} />
            </InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
      </div>
    </div>
  );
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, filters, totals, clients, members]);

  return (
    <div className="quotes-page mt-2 mb-4">
      <Container fluid>
        <h1 className="h4 mb-4">Quotes</h1>
        <DataTable
          customStyles={customTableStyles}
          responsive
          fixedHeader={true}
          columns={columns}
          data={filtersIsSet() ? filteredData : quotations}
          subHeader
          onRowClicked={handleTeamComponent}
          subHeaderComponent={subHeaderComponentMemo}
          pagination
          persistTableHead
        />
        {/* Download Modal */}
        <Modal
          animation={false}
          className="import-states-code-modal"
          size="md"
          centered
          show={showDownloadModal}
          onHide={handleDownloadModal}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="details">
              <div className="title">Download Invoice</div>
              <div className="download-btns">
                <a
                  className="download-btn-content"
                  onClick={() => downloadRequest("pdf")}
                >
                  <svg
                    width="90"
                    height="101"
                    viewBox="0 0 90 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M64.2103 1H5C2.79086 1 1 2.79086 1 5V96C1 98.2091 2.79086 100 5 100H76.5C78.7091 100 80.5 98.2091 80.5 96V16.3677C80.5 15.8142 80.2706 15.2854 79.8663 14.9072L65.5767 1.53946C65.2061 1.19284 64.7177 1 64.2103 1Z"
                      stroke="#142433"
                      stroke-width="2"
                    />
                    <path
                      d="M80.5 15.5H66.5C65.3954 15.5 64.5 14.6046 64.5 13.5V1"
                      stroke="#142433"
                      stroke-width="2"
                    />
                    <rect
                      x="47"
                      y="67.5"
                      width="43"
                      height="20"
                      rx="2"
                      fill="#FF4859"
                    />
                    <path
                      d="M52.328 72.076H57.544C58.856 72.076 59.8693 72.396 60.584 73.036C61.3093 73.676 61.672 74.5667 61.672 75.708C61.672 76.8707 61.3093 77.7773 60.584 78.428C59.8693 79.0787 58.856 79.404 57.544 79.404H54.712V83.5H52.328V72.076ZM54.712 77.308H57.208C57.9227 77.308 58.4347 77.18 58.744 76.924C59.064 76.6573 59.224 76.2627 59.224 75.74C59.224 75.2173 59.064 74.828 58.744 74.572C58.4347 74.3053 57.9227 74.172 57.208 74.172H54.712V77.308ZM63.7655 72.076H68.5335C70.2508 72.076 71.5628 72.588 72.4695 73.612C73.3762 74.636 73.8295 76.028 73.8295 77.788C73.8295 79.548 73.3762 80.94 72.4695 81.964C71.5628 82.988 70.2508 83.5 68.5335 83.5H63.7655V72.076ZM66.1495 81.372H68.2295C69.3175 81.372 70.1122 81.0467 70.6135 80.396C71.1255 79.7347 71.3815 78.8653 71.3815 77.788C71.3815 76.7107 71.1255 75.8467 70.6135 75.196C70.1122 74.5347 69.3175 74.204 68.2295 74.204H66.1495V81.372ZM84.3694 74.204H78.3214V76.828H83.0894V78.908H78.3214V83.5H75.9374V72.076H84.3694V74.204Z"
                      fill="white"
                    />
                  </svg>
                  <p>Download pdf.</p>
                </a>
                <a
                  className="download-btn-content"
                  onClick={() => downloadRequest("xlsx")}
                >
                  <img
                    src={ExcelImage}
                    width="90"
                    height="101"
                    viewBox="0 0 90 101"
                    fill="none"
                  />
                  {/* <svg
                    width="90"
                    height="101"
                    viewBox="0 0 90 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M64.2103 1H5C2.79086 1 1 2.79086 1 5V96C1 98.2091 2.79086 100 5 100H76.5C78.7091 100 80.5 98.2091 80.5 96V16.3677C80.5 15.8142 80.2706 15.2854 79.8663 14.9072L65.5767 1.53946C65.2061 1.19284 64.7177 1 64.2103 1Z"
                      stroke="#142433"
                      stroke-width="2"
                    />
                    <path
                      d="M80.5 15.5H66.5C65.3954 15.5 64.5 14.6046 64.5 13.5V1"
                      stroke="#142433"
                      stroke-width="2"
                    />
                    <rect
                      x="47"
                      y="67.5"
                      width="43"
                      height="20"
                      rx="2"
                      fill="#2CD58E"
                    />
                    <path
                      d="M61.176 83.5L58.792 78.796H58.296L55.864 83.5H53.304L56.456 77.5L53.736 72.076H56.184L58.312 76.268H58.808L61 72.076H63.416L60.648 77.436L63.8 83.5H61.176ZM67.7734 83.5L64.3974 72.076H66.8774L69.4054 81.196H70.1414L72.6694 72.076H75.1174L71.7414 83.5H67.7734ZM79.1183 81.324H85.1663V83.5H76.7343V72.076H79.1183V81.324Z"
                      fill="white"
                    />
                  </svg> */}

                  <p>Download excel.</p>
                </a>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-light" onClick={handleDownloadModal}>
              Cancel
            </Button>
            <Button
              variant="primary"
              // onClick={sendToApproval}
              disabled={disabled}
            >
              Download
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Send to Client Modal */}
        <Modal
          animation={false}
          className="send-to-client-modal"
          size="md"
          centered
          show={showSendToClientModal}
          onHide={handleSendToClientModal}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="content">
              <div className="title mb-3">Send quote</div>
              Copy the link
              <Form className="mt-2">
                <Form.Group className="d-flex justify-content-between align-items-center">
                  <FormControl
                    id="copyLink"
                    value={`${window.location.origin}/quote/approve/${uuid}`}
                  />
                  <Button id="copyLinkButton" className="ml-3" onClick={copyLink}>
                    Copy link
                  </Button>
                </Form.Group>
              </Form>
              <div
                className="share-boxes d-flex align-items-center p-1 px-2"
              // onClick={() =>
              //   window
              //     .open(
              //       `mailto:?subject=Quote Approve Request&body=${window.location.origin}/quote/approve/${uuid}`
              //     )
              //     .focus()
              // }
              >
                <svg
                  className="mr-3"
                  width="44"
                  height="44"
                  viewBox="0 0 44 44"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="44" height="44" rx="4" fill="#7848FF" />
                  <g clip-path="url(#clip0)">
                    <rect
                      x="11"
                      y="13"
                      width="22"
                      height="18"
                      rx="3"
                      stroke="white"
                      stroke-width="1.6"
                    />
                    <path
                      d="M15 18L20.5116 21.1495C21.4339 21.6765 22.5661 21.6765 23.4884 21.1495L29 18"
                      stroke="white"
                      stroke-width="1.6"
                      stroke-linecap="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="translate(10 10)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                {/* <p>Send to email</p> */}
                <Form className="py-1 w-100">
                  <Form.Group className="d-flex justify-content-between align-items-center mb-0 ml-2">
                    <FormControl
                      id="sendQuote"
                      placeholder="Send to email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                    <Button id="sendQuoteViaEmail" className="ml-3" onClick={sendQuoteEmail} disabled={disabled}>
                      Send
                    </Button>
                  </Form.Group>
                </Form>
              </div>
              <div
                className="share-boxes d-flex align-items-center"
                onClick={() => {
                  window
                    .open(
                      `whatsapp://send?text=${window.location.origin}/quote/approve/${uuid}`
                    )
                    .focus();
                }}
              >
                <svg
                  className="mr-3"
                  width="44"
                  height="44"
                  viewBox="0 0 44 44"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="44" height="44" rx="4" fill="#2CD58E" />
                  <g clip-path="url(#clip0)">
                    <path
                      d="M22.0005 10C15.3935 10 10.0005 15.3925 10.0005 22C10.0005 24.0854 10.6169 26.2 11.7864 28.1359L10.0408 33.062C9.95082 33.3165 10.0141 33.5996 10.2039 33.7914C10.338 33.9273 10.5189 34 10.7036 34C10.78 34 10.8569 33.9878 10.9314 33.962L15.9077 32.2586C17.8202 33.4145 19.8681 34 22.0005 34C25.1931 34 28.2034 32.7484 30.4759 30.4754C32.7489 28.2029 34.0005 25.1926 34.0005 22C34.0005 15.393 28.6079 10 22.0005 10ZM22.0005 32.5937C20.0322 32.5937 18.1394 32.0214 16.3741 30.8926C16.2597 30.8195 16.128 30.782 15.9953 30.782C15.9189 30.782 15.8416 30.7946 15.7675 30.82L11.8525 32.1606L13.225 28.2868C13.2972 28.0825 13.2714 27.8561 13.1547 27.6732C12.011 25.885 11.4068 23.9228 11.4068 22C11.4068 16.1584 16.1589 11.4062 22.0005 11.4062C27.842 11.4062 32.5942 16.1584 32.5942 22C32.5942 27.8415 27.842 32.5937 22.0005 32.5937Z"
                      fill="white"
                    />
                    <path
                      d="M29.3455 24.8789L27.1119 22.6457C26.8302 22.3635 26.4552 22.2084 26.0567 22.2084C25.6578 22.2084 25.2828 22.3635 25.0011 22.6457L24.4428 23.204C24.2769 23.3695 24.057 23.4604 23.8231 23.4604C23.5892 23.4604 23.3689 23.3695 23.2034 23.204L20.9699 20.9704C20.6286 20.6287 20.6286 20.0732 20.9699 19.7315L21.5286 19.1732C21.8103 18.891 21.9655 18.516 21.9655 18.1176C21.9655 17.7187 21.8103 17.3437 21.5286 17.062L19.295 14.8284C18.7128 14.2467 17.766 14.2467 17.1838 14.8284L16.6255 15.3867C15.8263 16.1859 15.56 17.8734 15.9303 19.7906C16.3306 21.8648 17.3708 23.827 18.8591 25.3153C20.3469 26.8031 22.3091 27.8432 24.3838 28.244C25.7769 28.5131 27.7911 28.5445 28.7872 27.5484L29.3455 26.9901C29.6277 26.7084 29.7828 26.3334 29.7828 25.9345C29.7829 25.536 29.6277 25.161 29.3455 24.8789ZM28.3513 25.9959L27.793 26.5542C27.4114 26.9357 26.2091 27.1645 24.65 26.8631C23.6066 26.6615 21.5961 26.0634 19.8533 24.3206C18.1105 22.5778 17.5124 20.5673 17.3108 19.5238C17.2142 19.0237 17.172 18.5606 17.172 18.1495C17.172 17.2781 17.361 16.6406 17.6197 16.3813L18.1785 15.8231C18.1953 15.8062 18.2174 15.7978 18.2394 15.7978C18.2614 15.7978 18.2839 15.8062 18.3008 15.8231L20.5339 18.0562C20.5508 18.0731 20.5592 18.0937 20.5592 18.1176C20.5592 18.1415 20.5508 18.1617 20.5339 18.1785L19.9756 18.7373C19.086 19.627 19.086 21.0749 19.9756 21.9646L22.2092 24.1982C22.64 24.6295 23.2133 24.8667 23.8231 24.8667C24.4325 24.8667 25.0058 24.6295 25.437 24.1982L25.9953 23.6399C26.0291 23.6062 26.0839 23.6062 26.1177 23.6399L28.3513 25.8735C28.3681 25.8904 28.3766 25.911 28.3766 25.9345C28.3766 25.9584 28.3682 25.979 28.3513 25.9959Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="translate(10 10)"
                      />
                    </clipPath>
                  </defs>
                </svg>

                <p>Send to whatsapp</p>
              </div>
              <div className="share-boxes d-flex align-items-center"
                onClick={() => {
                  window
                    .open(
                      `tg://msg?text=${window.location.origin}/quote/approve/${uuid}`
                    )
                    .focus();
                }}>
                <svg
                  className="mr-3"
                  width="44"
                  height="44"
                  viewBox="0 0 44 44"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="44" height="44" rx="4" fill="#519DFC" />
                  <path
                    d="M17.3636 24.8229L25.7273 19.25M17.3636 24.8229V29.266C17.3636 30.0568 18.238 30.5347 18.9036 30.1077L21.9766 28.1364M17.3636 24.8229L11.8392 23.2135C10.9207 22.9459 10.8661 21.6654 11.7585 21.3206L30.2019 14.1947C30.9579 13.9026 31.729 14.5839 31.5325 15.37L28.0444 29.3224C27.6927 30.7291 26.0078 31.3058 24.868 30.4096L21.9766 28.1364M17.3636 24.8229L21.9766 28.1364"
                    stroke="white"
                    stroke-width="1.6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <p>Send to telegram</p>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </Container>
      {isPaymentShowing ? (
        <Payment onClose={handleClosePayment} isShowing={isPaymentShowing} />
      ) : null}


      <Modal
        size="lg"
        show={demoModalShow}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="survey-modal"
      >
        <Modal.Body>
          <Slider {...surveySettings}>

            <div>
              <div className="image-holder">
                <img src={QuoteTab} />
              </div>
              <div className="text-holder">
                This is where all the quotes will be stored. Can filter by status, team, team member, revenue, or date. Can even search use any custom criteria. Quote status will define waiting for approval, approved, and declined. You can download or send quotes from here. Click a quote and it will bring you to the quote to show more details.                </div>
            </div>

          </Slider>
          {showFinishedButton ?
            <div className='btn-finish' onClick={handleClose}>Finish</div>
            : ""}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default QuotesPage;
