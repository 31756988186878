import React, { useReducer, useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Nav,
  Tab,
  Button,
  Modal,
  Form,
  FormControl,
  Table,
  InputGroup,
  Alert,
  ButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import {
    getSettings,
    getStatesAttempt,
    getCitiesAttempt,
    updateCompanyAttempt,
    getBillSettings,
    setCompanyImage,
  } from "./data/globalAction";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import SutaTemplate from "../component/assets/templates/SUTA-Rate-Template.csv";
import WCTemplate from "../component/assets/templates/Workers-Compensation-Template.csv";
import { BsPlus } from "react-icons/bs";
import UploadDocIcon from "../component/assets/images/icons/upload-document.svg";
import UploadDocWhiteIcon from "../component/assets/images/icons/upload-document-white.svg";
import chevLeft from "../component/assets/images/icons/cheveron-left.svg";
import ImportImageOne from "../component/assets/images/import-one.png";
import ImportImageTwo from "../component/assets/images/import-two.png";
import EditIcon from "./assets/images/icons/edit-pencil.svg";
import EditWhiteIcon from "./assets/images/icons/edit-pencil-white.svg";
import * as Constants from "../core/Constants";
import { HiOutlinePlusSm, HiCheck } from "react-icons/hi";
import { RiCloseLine } from "react-icons/ri";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import { useHistory, useParams, NavLink } from "react-router-dom";
import CSVFileValidator from "csv-file-validator";
import DataTable from "react-data-table-component";
import { render } from "@testing-library/react";
import Payment from "./Payment";
import AfterLogin from "./middleware/AfterLogin";
import queryString from "query-string";
import { isAdmin, isManager, isOwner } from "../core/UserRoleCheck";

export default function CompanyInformation() {

    const [formInputCompany, setFormInputCompany] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
          company_id: "",
          company_name: "",
          country: Constants.default_county.value,
          state: "",
          city: "",
          address: "",
          employees: "",
          zip_code: "",
        }
      );
      const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);



  let statesOptions = [];
  let stateDisabe = true;
  let stateSelected = "";
  if (states && states.length !== 0) {
    {
      states &&
        states.map((state) => {
          let row = { label: state.name, value: state.id };
          if (state.id === formInputCompany.state) {
            stateSelected = row;
          }
          statesOptions.push(row);
        });
    }
    stateDisabe = false;
  }


  let citiesOptions = [];
  let citiesDisabe = true;
  let citiesSelected = "";
  if (cities && cities.length !== 0) {
    {
      cities &&
        cities.map((city) => {
          let row = { label: city.name, value: city.id };
          if (city.id === formInputCompany.city) {
            citiesSelected = row;
          }
          citiesOptions.push(row);
        });
    }
    citiesDisabe = false;
  }

  let employeeSelected = "";
  Constants.employees_options.forEach((element) => {
    if (element.value === formInputCompany.employees) {
      employeeSelected = element;
    }
  });



  const handleCompanyImage = async (e) => {
    let formImage = new FormData();
    formImage.append("image", e.target.files[0]);

    let result = await setCompanyImage(formImage);
    if (result.status === 200) {
      async function fetchSettings() {
        setSettings(await getSettings());
      }
      await fetchSettings();
      toast.success(Constants.update_message);
    }
  };

  const handleCompanySave = async () => {
    console.log("here", formInputCompany);
   
      let result = await updateCompanyAttempt(JSON.stringify(formInputCompany));
      if (result.status === 200) {
        setSettings(result.data);
        toast.success(Constants.update_message);
      }
  };

    const [settings, setSettings] = useState([]);

    useEffect(() => {
        async function fetchSettings() {
          setSettings(await getSettings());
        }
        fetchSettings();
      }, []);


      useEffect(() => {
        if (
            settings.data &&
            settings.data.organization_users &&
            settings.data.organization_users.length !== 0 &&
            settings.data.organization_users[0].organization
          ) {
            let organization = settings.data.organization_users[0].organization;
    
            setFormInputCompany({ ["company_id"]: organization.id });
    
            setFormInputCompany({ ["company_name"]: organization.name });
    
            setFormInputCompany({ ["state"]: organization.state_id });
    
            async function fetchStates() {
              setStates(await getStatesAttempt(Constants.default_county.value));
            }
            fetchStates();
    
            setFormInputCompany({ ["city"]: organization.city_id });
    
            if (organization.city_id !== "" && cities !== "") {
              async function fetchCities() {
                setCities(await getCitiesAttempt(organization.state_id));
              }
              fetchCities();
            }
    
            setFormInputCompany({ ["employees"]: organization.employees });
    
            setFormInputCompany({ ["address"]: organization.address });
    
            setFormInputCompany({ ["zip_code"]: organization.zip });
          }
      }, [settings]);



      const handleInputCompany = (evt) => {
        const name = evt.target.name;
        const newValue = evt.target.value;
        setFormInputCompany({ [name]: newValue });
      };
      const handleSelectCompany = async (info, selectName) => {
        const name = selectName;
        const newValue = info.value;
        setFormInputCompany({ [name]: newValue });
        if (selectName === "state") {
          setCities(await getCitiesAttempt(newValue));
        }
      };











    const customControlStyles = {
        control: (base, state) => ({
          ...base,
          minHeight: 50,
          width: '100%',
          background: state.isFocused ? "#fff" : "#fff",
          borderWidth: 1,
          borderRadius: "8px",
          borderColor: state.isFocused ? "#7848FF" : "#E5E8F5",
          boxShadow: state.isFocused ? null : null,
          fontSize: "14px",
          "&:hover": {
            borderColor: state.isFocused ? "#7848FF" : null,
          },
        }),
        dropdownIndicator: (base) => ({
          ...base,
          paddingTop: 0,
          paddingBottom: 0,
          marginRight: "5px",
        }),
        menu: (base) => ({
          ...base,
          fontSize: "13px",
          border: "1px solid #f1f3f6",
          boxShadow: "none",
        }),
        option: (base, state) => ({
          ...base,
          background: state.isSelected ? "#f1f3f6" : "#fff",
          color: "#151B26",
          "&:hover": {
            background: "#f1f3f6",
            color: "#151B26",
          },
        }),
        placeholder: (base) => ({
          ...base,
          color: "#A8B9CD",
        }),
        clearIndicator: (base) => ({
          ...base,
          paddingTop: 0,
          paddingBottom: 0,
        }),
        indicatorSeparator: (base) => ({
          ...base,
          display: "none",
        }),
      };

    return (
        <div className="account-security-page company-information-page">
            <div className="grey-box pt-0">
                <Container fluid>
                <h2 className="title">
                    <Link to="/settings">
                    <img src={chevLeft} alt="icon" />
                    </Link>
                    Company Information
                </h2>
                </Container>
            </div>
            <div>
                <Container fluid>
                    <div className="details">
                        <div class="personal-info">
                            <label htmlFor="company_image">
                            <div className="" >

                                {settings?.data?.organization_users[0]?.organization
                                .image ? (
                                <div className="user-pic">
                                  <img
                                    src={
                                      settings?.data?.organization_users[0]
                                        .organization.image
                                    }
                                    alt="Image"
                                  />
                                </div>
                              ) : (
                                <div className="name-char">
                                  {settings?.data?.organization_users[0]?.organization.name
                                    .charAt(0)
                                    .toUpperCase()}
                                </div>
                              )}
                                </div>
                            </label>
                            <input
                              type="file"
                              id="company_image"
                              style={{ display: "none" }}
                              onChange={handleCompanyImage}
                            />
                            <div className="text-details">
                                <strong>{
                                settings?.data?.organization_users[0]?.organization
                                  ?.name
                              }</strong>
                            </div>
                        </div>
                        <div className="other-info">
                            <div className="list mb-2">
                                <div className="form-group">
                                    <div className="form-label mb-2">Company Name</div>
                                    <FormControl
                                required
                                id="company_name"
                                value={formInputCompany?.company_name}
                                name="company_name"
                                onChange={handleInputCompany}
                              />
                                </div>
                            </div>
                            <Row>
                                <Col lg={6}>
                                    <div className="list">
                                        <div className="form-group">
                                            <div className="form-label mb-2">State</div>
                                            <div className="select-holder">
                                            <Select
                                    name="state"
                                    placeholder="Alaska"
                                    value={stateSelected}
                                    onChange={(value) => {
                                      handleSelectCompany(value, "state");
                                    }}
                                    options={statesOptions}
                                    isDisabled={stateDisabe}
                                    styles={customControlStyles}
                                  />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="list">
                                        <div className="form-group">
                                            <div className="form-label mb-2">City</div>
                                            <div className="select-holder">
                                            <Select
                                    name="city"
                                    placeholder="Chevak"
                                    value={citiesSelected}
                                    onChange={(value) => {
                                      handleSelectCompany(value, "city");
                                    }}
                                    options={citiesOptions}
                                    isDisabled={citiesDisabe}
                                    styles={customControlStyles}
                                  />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={9}>
                                    <div className="list">
                                        <div className="form-group">
                                            <div className="form-label mb-2">Companny Address</div>
                                            <FormControl
                                    required
                                    value={formInputCompany?.address}
                                    name="address"
                                    type="text"
                                    id="address"
                                    placeholder="33 3rd Ave, New York, NY 10003, USA"
                                    onChange={handleInputCompany}
                                  />
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={3}>
                                    <div className="list">
                                        <div className="form-group">
                                            <div className="form-label mb-2">Zip Code</div>
                                            <FormControl
                                    required
                                    value={formInputCompany?.zip_code}
                                    name="zip_code"
                                    placeholder="10017"
                                    type="text"
                                    id="zip_code"
                                    onChange={handleInputCompany}
                                  />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <div className="list">
                                <div className="form-group">
                                    <div className="form-label mb-2">No of Employees</div>
                                    <div className="select-holder">
                                    <Select
                                name="employees"
                                menuPlacement="top"
                                placeholder="More than 50"
                                value={employeeSelected}
                                onChange={(value) => {
                                  handleSelectCompany(value, "employees");
                                }}
                                options={Constants?.employees_options}
                                styles={customControlStyles}
                              />
                                    </div>
                                </div>
                            </div>
                            <div className="list button-holder">
                           
                            <Button
                            variant="primary"
                            onClick={handleCompanySave}
                          >
                            Save
                          </Button>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div> 
    )
}