
import { combineReducers } from "redux";


const login = (state = null, action) => {
    const { type, payload } = action;
    switch (type) {
        case 'SIGNIN_ATTEMPT_RESPONSE':
            return payload;
        default:
            return state;
    }
};
const loginError = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {
        case 'SIGNIN_SET_ERROR':
            return payload;   
        default:
            return [];
    }
};
const loginMessage = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {
        case 'SIGNIN_SET_MESSAGE':
            return payload;   
        default:
            return [];
    }
};
const loginReducer = combineReducers({
    login,
    loginError,
    loginMessage
});
export default loginReducer;