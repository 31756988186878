import { combineReducers } from "redux";

const forgot = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case "FORGOT_PASSWORD_ATTEMPT_RESPONSE":
      return payload;
    default:
      return state;
  }
};
const reset = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case "RESET_PASSWORD_ATTEMPT_RESPONSE":
      return payload;
    default:
      return state;
  }
};
const resetError = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case "RESET_PASSWORD_SET_ERROR":
      return payload;
    default:
      return [];
  }
};
const forgotError = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case "FORGOT_PASSWORD_SET_ERROR":
      return payload;
    default:
      return [];
  }
};
const forgotMessage = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case "FORGOT_PASSWORD_SET_MESSAGE":
      return payload;
    default:
      return [];
  }
};
const forgotReducer = combineReducers({
  forgot,
  reset,
  resetError,
  forgotError,
  forgotMessage,
});
export default forgotReducer;
