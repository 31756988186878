import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  Form,
  FormControl,
} from "react-bootstrap";
import moment from "moment";
import { isManager, isTeamMember } from "../core/UserRoleCheck";
import * as Constants from "../core/Constants";
import Select from "react-select";
import DataTable from "react-data-table-component";
import { NavLink, useHistory, useParams } from "react-router-dom";
import Logo from "./assets/images/logo.svg";
import DownloadIcon from "./assets/images/icons/download.svg";
import DownloadWhiteIcon from "./assets/images/icons/download-white.svg";
import ExcelImage from "../component/assets/images/xlsx.png";
import chevLeft from "../component/assets/images/icons/cheveron-left.svg";
import { toast } from "react-toastify";
import {
  getQuotation,
  getQuotationApprove,
  updateQuoteStatus,
  updateQuoteStatusApprove,
  getSettings,
  sendQuoteViaEmail,
} from "./data/globalAction";
import Payment from "./Payment";

export default function QuotationDetail() {
  const { id } = useParams();
  const history = useHistory();
  const [email, setEmail] = useState(null);
  const [link, setLink] = useState(null);
  const [settings, setSettings] = useState([]);
  const [organizationSettings, setOrganizationSettings] = useState([]);
  const [isPaymentShowing, setIsPaymentShowing] = useState(false);
  const [companyImage, setCompanyImage] = useState();
  const [fromCompany, setFromCompany] = useState("");
  const [quoteData, setQuoteData] = useState({});
  const [duration, setDuration] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [showSendToClientModal, setShowSendToClientModal] = useState(false);
  const handleDownloadModal = () => {
    setDisabled(!disabled);
    setShowDownloadModal(!showDownloadModal);
  };
  const handleSendToClientModal = () => {
    setShowSendToClientModal(!showSendToClientModal);
    setEmail(null);
    setLink(null);
  };

  const changeStatus = async (e) => {
    let result;
    let data = {};
    if (window.location.pathname.startsWith(Constants.path_for_approve_quote)) {
      data = {
        uuid: quoteData.uuid,
        status: e.value,
      };
      result = await updateQuoteStatusApprove(JSON.stringify(data));
    } else {
      data = {
        id: quoteData.id,
        status: e.value,
      };
      if (!isTeamMember) {
        result = await updateQuoteStatus(JSON.stringify(data));
      } else {
        result = { status: 404 };
      }
    }
    if (result.status == 200) {
      console.log("status change result", result);
      setQuoteData({ ...result.data.quotation });
    }
  };

  const copyLink = () => {
    var copyText = document.getElementById("copyLink");
    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    document.execCommand("copy");
    document.getElementById("copyLinkButton").textContent = "Copied!";
    setTimeout(() => {
      document.getElementById("copyLinkButton").textContent = "Copy link";
    }, 2000);
  };

  const handleClosePayment = () => {
    localStorage.removeItem("expire");
    setIsPaymentShowing(false);
  };

  useEffect(() => {
    document.querySelector('html').classList.remove('calculation-details-page');
    async function fetchSettings() {
      setSettings(await getSettings());
    }
    if(!window.location.pathname.startsWith(Constants.path_for_approve_quote))
      fetchSettings();

    async function fetchQuotation() {
      let result;
      let data = {};
      if (
        window.location.pathname.startsWith(Constants.path_for_approve_quote)
      ) {
        data = {
          uuid: id,
        };
        result = await getQuotationApprove(JSON.stringify(data));
      } else {
        data = {
          id: id,
        };
        result = await getQuotation(JSON.stringify(data));
      }
      if (result.status == 200) {
        setQuoteData({ ...result.data.quotation });
        setDuration({ ...result.data.duration });
        setOrganizationSettings({ ...result.data.organization });
        // console.log("result.data.organization", result.data.organization);
        // console.log("result.data.quotation", result.data.quotation);
      }
    }
    fetchQuotation();
  }, []);

  useEffect(() => {
    if (Object.keys(organizationSettings).length > 0) {
      if (organizationSettings.image !== null) {
        setCompanyImage(organizationSettings.image);
      }
      if (organizationSettings.name !== null) {
        setFromCompany(organizationSettings.name);
      }
    }
  }, [organizationSettings]);

  useEffect(() => {
    if (settings.expire && settings.expire === true) {
      setIsPaymentShowing(true);
    }
  }, [settings]);

  const customTableStyles = {
    header: {
      style: {
        padding: "0",
        minHeight: "inherit",
      },
    },
    subHeader: {
      style: {
        padding: "0",
        justifyContent: "flex-start",
        display: "block",
        border: "1px solid #E5E8F5",
        borderBottom: "none",
        borderRadius: "6px 6px 0 0",
      },
    },
    tableWrapper: {
      style: {
        border: "1px solid #E5E8F5",
        borderTop: "none",
      },
    },
    headRow: {
      style: {
        color: "#43536D",
        borderColor: "#E5E8F5",
        borderStyle: "solid",
        borderWidth: "1px",
        borderLeftWidth: "0",
        borderRightWidth: "0",
        backgroundColor: "#FCFCFF",
        paddingLeft: "14px",
        paddingRight: "14px",
      },
    },
    headCells: {
      style: {
        fontSize: "13px",
        fontWeight: "normal",
        color: "#767778",
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: "#fff",
        },
      },
    },
    rows: {
      style: {
        minHeight: "65px",
        "&:not(:last-of-type)": {
          borderBottomStyle: "solid",
          borderBottomWidth: "1px",
          borderBottomColor: "#E5E8F5",
        },
        paddingLeft: "14px",
        paddingRight: "14px",
      },
    },
    cells: {
      style: {
        minHeight: "65px",
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: "#fff",
        },
      },
    },
    pagination: {
      style: {
        border: "1px solid #E5E8F5",
        borderTop: "none",
        borderRadius: "0 0 6px 6px",
      },
    },
  };
  const customControlStyles = {
    control: (base, state) => ({
      ...base,
      minHeight: 50,
      marginBottom: "15px",
      background: state.isFocused ? "#fff" : "#fff",
      borderWidth: 1,
      borderRadius: "8px",
      borderColor: state.isFocused ? "#7848FF" : "#E5E8F5",
      boxShadow: state.isFocused ? null : null,
      fontSize: "14px",
      "&:hover": {
        borderColor: state.isFocused ? "#7848FF" : null,
      },
    }),
    dropdownIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
      marginRight: "5px",
    }),
    menu: (base) => ({
      ...base,
      fontSize: "13px",
      border: "1px solid #f1f3f6",
      boxShadow: "none",
    }),
    option: (base, state) => ({
      ...base,
      background: state.isSelected ? "#f1f3f6" : "#fff",
      color: "#151B26",
      "&:hover": {
        background: "#f1f3f6",
        color: "#151B26",
      },
    }),
    placeholder: (base) => ({
      ...base,
      color: "#A8B9CD",
    }),
    clearIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
  };

  const customLabel = (status, color) => (
    <div className="d-flex justify-content-start align-items-center">
      <span class="color-circle mr-2" style={{ background: color }}></span>
      <span> {status}</span>
    </div>
  );
  const statusOptions = [
    {
      value: "Waiting for approval",
      label: customLabel("Waiting for approval", "#7848ff"),
    },
    {
      value: "Approved",
      label: customLabel("Approved", "#2CD48E"),
    },
    {
      value: "Not Approved",
      label: customLabel("Not Approved", "#FF4859"),
    },
    // {
    //   value: "New quotations",
    //   label: customLabel("New quotations", "#519DFC"),
    // },
  ];

  const columns = [
    {
      name: "Job Position:",
      selector: "title",
      sortable: true,
    },
    {
      name: "Quantity:",
      selector: "qty",
      sortable: true,
    },
    {
      name: "Created at:",
      selector: "issue_date",
      sortable: true,
      cell: (row) => moment().format("YYYY-MM-DD"),
    },
    {
      name: "Project Base Cost:",
      selector: "project_cost",
      sortable: true,
      cell: (row) => (
        <div>
          <span className="text-success">$</span> {row.project_cost}
        </div>
      ),
    },
  ];

  const downloadRequest = (type) => {
    let data = {
      id: id,
      type: type,
    };
    async function getDownload() {
      window
        .open(
          `${process.env.REACT_APP_API_URL}/quotations/download/${data.id}/${data.type}`
        )
        .focus();
    }
    getDownload();
  };

  const sendQuoteEmail = () => {
    setDisabled(true);
    async function sendQuote() {
      let result = await sendQuoteViaEmail(
        JSON.stringify({
          email: email,
          quote_link: link
        })
        );
        if (result.status === 200) {
        setDisabled(false);
        toast.success(result.data.message);
        handleSendToClientModal();
      } else {
        toast.error("Error, Please try again.");
      }
    }
    sendQuote();
  }

  return (
    <>
      {Object.keys(quoteData).length > 0 ? (
        <div className="calculation-settings quote-detail">
          <Container fluid>
            <div className="top-details d-flex justify-content-between align-items-center">
              <h2 className="title">
                {window.location.pathname.startsWith(
                  Constants.path_for_approve_quote
                ) ? (
                  ""
                ) : (
                  <NavLink to="/quotes">
                    <img src={chevLeft} alt="icon" />

                  </NavLink>
                )}
                Quote #{quoteData.invoice}
              </h2>
              {window.location.pathname.startsWith(
                Constants.path_for_approve_quote
              ) ? (
                <>
                  {quoteData.status === "Approved" ? (
                    <Button variant="outline-dark">Approved</Button>
                  ) : (
                    <Button
                      onClick={() => changeStatus({ value: "Approved" })}
                    >
                      Approve and Sign
                    </Button>
                  )}
                </>
              ) : (
                <div>
                  <Button
                    className="btn-edit-custom mr-3"
                    variant="outline-dark"
                    // onClick={handleDownloadModal}
                    onClick={()=>{downloadRequest("pdf")}}
                  >
                    <div className="icon mr-2">
                      <img
                        src={DownloadIcon}
                        alt="Trash Icon"
                        className="icon-black"
                      />
                      <img
                        src={DownloadWhiteIcon}
                        alt="Trash Icon"
                        className="icon-white"
                      />
                    </div>
                    Download
                  </Button>
                  <Button
                    className="btn-edit-custom mr-3"
                    onClick={() => {
                      handleSendToClientModal();
                      setLink(`${window.location.origin}/quote/approve/${quoteData.uuid}`);
                    }}
                  >
                    Send to Client
                  </Button>
                </div>
              )}
            </div>
            {/* Download Modal */}
            <Modal
              animation={false}
              className="import-states-code-modal"
              size="md"
              centered
              show={showDownloadModal}
              onHide={handleDownloadModal}
            >
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                <div className="details">
                  <div className="title">Download Invoice</div>
                  <div className="download-btns">
                    <a
                      className="download-btn-content"
                      onClick={() => downloadRequest("pdf")}
                    >
                      <svg
                        width="90"
                        height="101"
                        viewBox="0 0 90 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M64.2103 1H5C2.79086 1 1 2.79086 1 5V96C1 98.2091 2.79086 100 5 100H76.5C78.7091 100 80.5 98.2091 80.5 96V16.3677C80.5 15.8142 80.2706 15.2854 79.8663 14.9072L65.5767 1.53946C65.2061 1.19284 64.7177 1 64.2103 1Z"
                          stroke="#142433"
                          stroke-width="2"
                        />
                        <path
                          d="M80.5 15.5H66.5C65.3954 15.5 64.5 14.6046 64.5 13.5V1"
                          stroke="#142433"
                          stroke-width="2"
                        />
                        <rect
                          x="47"
                          y="67.5"
                          width="43"
                          height="20"
                          rx="2"
                          fill="#FF4859"
                        />
                        <path
                          d="M52.328 72.076H57.544C58.856 72.076 59.8693 72.396 60.584 73.036C61.3093 73.676 61.672 74.5667 61.672 75.708C61.672 76.8707 61.3093 77.7773 60.584 78.428C59.8693 79.0787 58.856 79.404 57.544 79.404H54.712V83.5H52.328V72.076ZM54.712 77.308H57.208C57.9227 77.308 58.4347 77.18 58.744 76.924C59.064 76.6573 59.224 76.2627 59.224 75.74C59.224 75.2173 59.064 74.828 58.744 74.572C58.4347 74.3053 57.9227 74.172 57.208 74.172H54.712V77.308ZM63.7655 72.076H68.5335C70.2508 72.076 71.5628 72.588 72.4695 73.612C73.3762 74.636 73.8295 76.028 73.8295 77.788C73.8295 79.548 73.3762 80.94 72.4695 81.964C71.5628 82.988 70.2508 83.5 68.5335 83.5H63.7655V72.076ZM66.1495 81.372H68.2295C69.3175 81.372 70.1122 81.0467 70.6135 80.396C71.1255 79.7347 71.3815 78.8653 71.3815 77.788C71.3815 76.7107 71.1255 75.8467 70.6135 75.196C70.1122 74.5347 69.3175 74.204 68.2295 74.204H66.1495V81.372ZM84.3694 74.204H78.3214V76.828H83.0894V78.908H78.3214V83.5H75.9374V72.076H84.3694V74.204Z"
                          fill="white"
                        />
                      </svg>
                      <p>Download pdf.</p>
                    </a>
                    <a
                      className="download-btn-content"
                      onClick={() => downloadRequest("xlsx")}
                    >
                      <img 
                        src={ExcelImage}
                        width="90"
                        height="101"
                        viewBox="0 0 90 101"
                        fill="none"
                      />
                      {/* <svg
                        width="90"
                        height="101"
                        viewBox="0 0 90 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M64.2103 1H5C2.79086 1 1 2.79086 1 5V96C1 98.2091 2.79086 100 5 100H76.5C78.7091 100 80.5 98.2091 80.5 96V16.3677C80.5 15.8142 80.2706 15.2854 79.8663 14.9072L65.5767 1.53946C65.2061 1.19284 64.7177 1 64.2103 1Z"
                          stroke="#142433"
                          stroke-width="2"
                        />
                        <path
                          d="M80.5 15.5H66.5C65.3954 15.5 64.5 14.6046 64.5 13.5V1"
                          stroke="#142433"
                          stroke-width="2"
                        />
                        <rect
                          x="47"
                          y="67.5"
                          width="43"
                          height="20"
                          rx="2"
                          fill="#2CD58E"
                        />
                        <path
                          d="M61.176 83.5L58.792 78.796H58.296L55.864 83.5H53.304L56.456 77.5L53.736 72.076H56.184L58.312 76.268H58.808L61 72.076H63.416L60.648 77.436L63.8 83.5H61.176ZM67.7734 83.5L64.3974 72.076H66.8774L69.4054 81.196H70.1414L72.6694 72.076H75.1174L71.7414 83.5H67.7734ZM79.1183 81.324H85.1663V83.5H76.7343V72.076H79.1183V81.324Z"
                          fill="white"
                        />
                      </svg> */}

                      <p>Download excel.</p>
                    </a>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="outline-light"
                  onClick={handleDownloadModal}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  // onClick={sendToApproval}
                  disabled={disabled}
                >
                  Download
                </Button>
              </Modal.Footer>
            </Modal>

            {/* Send to Client Modal */}
            <Modal
              animation={false}
              className="send-to-client-modal"
              size="md"
              centered
              show={showSendToClientModal}
              onHide={handleSendToClientModal}
            >
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                <div className="content">
                  <div className="title mb-3">Send quote</div>
                  Copy the link
                  <Form className="mt-2">
                    <Form.Group className="d-flex justify-content-between align-items-center">
                      <FormControl
                        id="copyLink"
                        value={`${window.location.origin}/quote/approve/${quoteData.uuid}`}
                      />
                      <Button id="copyLinkButton" className="ml-3" onClick={copyLink}>
                        Copy link
                      </Button>
                    </Form.Group>
                  </Form>
                  <div
                    className="share-boxes d-flex align-items-center p-1 px-2"
                    // onClick={() =>
                    //   window
                    //     .open(
                    //       `mailto:?subject=Quote Approve Request&body=${window.location.origin}/quote/approve/${quoteData.uuid}`
                    //     )
                    //     .focus()
                    // }
                  >
                    <svg
                      className="mr-3"
                      width="44"
                      height="44"
                      viewBox="0 0 44 44"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="44" height="44" rx="4" fill="#7848FF" />
                      <g clip-path="url(#clip0)">
                        <rect
                          x="11"
                          y="13"
                          width="22"
                          height="18"
                          rx="3"
                          stroke="white"
                          stroke-width="1.6"
                        />
                        <path
                          d="M15 18L20.5116 21.1495C21.4339 21.6765 22.5661 21.6765 23.4884 21.1495L29 18"
                          stroke="white"
                          stroke-width="1.6"
                          stroke-linecap="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0">
                          <rect
                            width="24"
                            height="24"
                            fill="white"
                            transform="translate(10 10)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    {/* <p>Send to email</p> */}
                    <Form className="py-1 w-100">
                      <Form.Group className="d-flex justify-content-between align-items-center mb-0 ml-2">
                        <FormControl
                          id="sendQuote"
                          placeholder="Send to email"
                          value={email}
                          onChange={(e)=>{
                            setEmail(e.target.value);
                          }}
                        />
                        <Button id="sendQuoteViaEmail" className="ml-3" onClick={sendQuoteEmail} disabled={disabled}>
                          Send
                        </Button>
                      </Form.Group>
                    </Form>
                  </div>
                  <div
                    className="share-boxes d-flex align-items-center"
                    onClick={() => {
                      window
                        .open(
                          `whatsapp://send?text=${window.location.origin}/quote/approve/${quoteData.uuid}`
                        )
                        .focus();
                    }}
                  >
                    <svg
                      className="mr-3"
                      width="44"
                      height="44"
                      viewBox="0 0 44 44"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="44" height="44" rx="4" fill="#2CD58E" />
                      <g clip-path="url(#clip0)">
                        <path
                          d="M22.0005 10C15.3935 10 10.0005 15.3925 10.0005 22C10.0005 24.0854 10.6169 26.2 11.7864 28.1359L10.0408 33.062C9.95082 33.3165 10.0141 33.5996 10.2039 33.7914C10.338 33.9273 10.5189 34 10.7036 34C10.78 34 10.8569 33.9878 10.9314 33.962L15.9077 32.2586C17.8202 33.4145 19.8681 34 22.0005 34C25.1931 34 28.2034 32.7484 30.4759 30.4754C32.7489 28.2029 34.0005 25.1926 34.0005 22C34.0005 15.393 28.6079 10 22.0005 10ZM22.0005 32.5937C20.0322 32.5937 18.1394 32.0214 16.3741 30.8926C16.2597 30.8195 16.128 30.782 15.9953 30.782C15.9189 30.782 15.8416 30.7946 15.7675 30.82L11.8525 32.1606L13.225 28.2868C13.2972 28.0825 13.2714 27.8561 13.1547 27.6732C12.011 25.885 11.4068 23.9228 11.4068 22C11.4068 16.1584 16.1589 11.4062 22.0005 11.4062C27.842 11.4062 32.5942 16.1584 32.5942 22C32.5942 27.8415 27.842 32.5937 22.0005 32.5937Z"
                          fill="white"
                        />
                        <path
                          d="M29.3455 24.8789L27.1119 22.6457C26.8302 22.3635 26.4552 22.2084 26.0567 22.2084C25.6578 22.2084 25.2828 22.3635 25.0011 22.6457L24.4428 23.204C24.2769 23.3695 24.057 23.4604 23.8231 23.4604C23.5892 23.4604 23.3689 23.3695 23.2034 23.204L20.9699 20.9704C20.6286 20.6287 20.6286 20.0732 20.9699 19.7315L21.5286 19.1732C21.8103 18.891 21.9655 18.516 21.9655 18.1176C21.9655 17.7187 21.8103 17.3437 21.5286 17.062L19.295 14.8284C18.7128 14.2467 17.766 14.2467 17.1838 14.8284L16.6255 15.3867C15.8263 16.1859 15.56 17.8734 15.9303 19.7906C16.3306 21.8648 17.3708 23.827 18.8591 25.3153C20.3469 26.8031 22.3091 27.8432 24.3838 28.244C25.7769 28.5131 27.7911 28.5445 28.7872 27.5484L29.3455 26.9901C29.6277 26.7084 29.7828 26.3334 29.7828 25.9345C29.7829 25.536 29.6277 25.161 29.3455 24.8789ZM28.3513 25.9959L27.793 26.5542C27.4114 26.9357 26.2091 27.1645 24.65 26.8631C23.6066 26.6615 21.5961 26.0634 19.8533 24.3206C18.1105 22.5778 17.5124 20.5673 17.3108 19.5238C17.2142 19.0237 17.172 18.5606 17.172 18.1495C17.172 17.2781 17.361 16.6406 17.6197 16.3813L18.1785 15.8231C18.1953 15.8062 18.2174 15.7978 18.2394 15.7978C18.2614 15.7978 18.2839 15.8062 18.3008 15.8231L20.5339 18.0562C20.5508 18.0731 20.5592 18.0937 20.5592 18.1176C20.5592 18.1415 20.5508 18.1617 20.5339 18.1785L19.9756 18.7373C19.086 19.627 19.086 21.0749 19.9756 21.9646L22.2092 24.1982C22.64 24.6295 23.2133 24.8667 23.8231 24.8667C24.4325 24.8667 25.0058 24.6295 25.437 24.1982L25.9953 23.6399C26.0291 23.6062 26.0839 23.6062 26.1177 23.6399L28.3513 25.8735C28.3681 25.8904 28.3766 25.911 28.3766 25.9345C28.3766 25.9584 28.3682 25.979 28.3513 25.9959Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0">
                          <rect
                            width="24"
                            height="24"
                            fill="white"
                            transform="translate(10 10)"
                          />
                        </clipPath>
                      </defs>
                    </svg>

                    <p>Send to whatsapp</p>
                  </div>
                  <div className="share-boxes d-flex align-items-center" 
                  onClick={() => {
                    window
                      .open(
                        `tg://msg?text=${window.location.origin}/quote/approve/${quoteData.uuid}`
                        )
                      .focus();
                  }}
                  >
                    <svg
                      className="mr-3"
                      width="44"
                      height="44"
                      viewBox="0 0 44 44"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="44" height="44" rx="4" fill="#519DFC" />
                      <path
                        d="M17.3636 24.8229L25.7273 19.25M17.3636 24.8229V29.266C17.3636 30.0568 18.238 30.5347 18.9036 30.1077L21.9766 28.1364M17.3636 24.8229L11.8392 23.2135C10.9207 22.9459 10.8661 21.6654 11.7585 21.3206L30.2019 14.1947C30.9579 13.9026 31.729 14.5839 31.5325 15.37L28.0444 29.3224C27.6927 30.7291 26.0078 31.3058 24.868 30.4096L21.9766 28.1364M17.3636 24.8229L21.9766 28.1364"
                        stroke="white"
                        stroke-width="1.6"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>

                    <p>Send to telegram</p>
                  </div>
                </div>
              </Modal.Body>
            </Modal>

            <div className="quote-data">
              <div className="top-details d-flex justify-content-between align-items-center">
                {/* <img
                  src={Logo}
                  alt="ReCalc Logo"
                  onClick={() => {
                    history.push("/");
                  }}
                /> */}
                <label htmlFor="company_image">
                  {companyImage ? (
                    <div className="user-pic">
                      <img src={companyImage} alt="Image" />
                    </div>
                  ) : (
                    <div className="name-char">
                      {fromCompany?.charAt(0).toUpperCase()}
                    </div>
                  )}
                </label>
                {window.location.pathname.startsWith(
                  Constants.path_for_approve_quote
                ) ? (
                  ""
                ) : (
                  <Select
                    placeholder="status"
                    className="dropdown"
                    // isClearable={true}
                    options={statusOptions}
                    styles={customControlStyles}
                    onChange={changeStatus}
                    value={statusOptions.find((s) => {
                      return s.value === quoteData.status;
                    })}
                  />
                )}
              </div>
              <div className="mt-4">
                <h6>Quote #{quoteData.invoice}</h6>
                <div className="mt-4 d-flex divide-content">
                  <div>
                    <span className="light-text">Issue Date</span>
                    <p>
                      {moment(quoteData.issue_date).format("YYYY-MM-DD")}
                    </p>
                  </div>
                  <div>
                    <span className="light-text">From</span>
                    <p>
                      {duration?.start_date} to {duration?.end_date}
                    </p>
                  </div>
                </div>
                <div className="mt-4 d-flex divide-content">
                  <div>
                    <span className="light-text">For</span>
                    <p>{quoteData.client_name}</p>
                  </div>
                  <div>
                    <span className="light-text">From</span>
                    <p>{fromCompany}</p>
                  </div>
                </div>
              </div>
            </div>
            <DataTable
              customStyles={customTableStyles}
              responsive
              fixedHeader={true}
              columns={columns}
              data={quoteData.items}
              persistTableHead
            />
            <div className="quote-data-bottom">
              <div className="d-flex divide-content align-items-end">
                <div>
                  <svg
                    width="64"
                    height="64"
                    viewBox="0 0 64 64"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mb-3"
                  >
                    <circle
                      cx="32"
                      cy="32"
                      r="31.5"
                      fill="white"
                      stroke="#E5E8F5"
                    />
                    <path
                      d="M31.384 26.368C32.5467 26.368 33.448 26.6667 34.088 27.264C34.7387 27.8507 35.064 28.624 35.064 29.584C35.064 30.096 34.9627 30.5493 34.76 30.944C34.568 31.3387 34.36 31.6373 34.136 31.84C33.9227 32.0427 33.5813 32.32 33.112 32.672C32.7067 32.9707 32.4347 33.2213 32.296 33.424C32.1573 33.6267 32.088 33.9307 32.088 34.336V34.768H30.248V34.336C30.248 33.7067 30.3653 33.2107 30.6 32.848C30.8347 32.4853 31.2773 32.048 31.928 31.536C32.376 31.216 32.7013 30.9227 32.904 30.656C33.1067 30.3787 33.208 30.0213 33.208 29.584C33.208 29.136 33.048 28.7573 32.728 28.448C32.408 28.128 31.9707 27.968 31.416 27.968C30.1787 27.968 29.5387 28.5973 29.496 29.856H27.656C27.6773 28.8107 28.0133 27.968 28.664 27.328C29.3253 26.688 30.232 26.368 31.384 26.368ZM32.184 38H30.12V35.856H32.184V38Z"
                      fill="#142433"
                    />
                  </svg>
                  <p>Any questions? Please let us know</p>
                  <p className="purple-text">{quoteData.user.email}</p>
                </div>
                <div className="outline-dark">
                  <h6>Quote Summary</h6>
                  <div className="divider"></div>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="summary-title">Sub-Total:</p>
                    <span>
                      <span className="text-success">$</span>{" "}
                      {Number(quoteData?.sub_total).toFixed(2)} USD
                    </span>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="summary-title">VAT:</p>
                    <span>                          
                      <span className="text-success">$</span>{" "}
                      {(quoteData.vat).toFixed(2)} USD</span>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="summary-title">Sub-Total + VAT:</p>
                    <span>
                      <span className="text-success">$</span>{" "}
                      {(Number(quoteData?.sub_total) + quoteData.vat).toFixed(2)} USD
                    </span>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="summary-title">Amount Due:</p>
                    <span>
                      <span className="text-success">$</span>{" "}
                      {(Number(quoteData?.balance) + quoteData.vat).toFixed(2)} USD
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {isPaymentShowing ? (
              <Payment onClose={handleClosePayment} isShowing={isPaymentShowing} />
            ) : null}
          </Container>
        </div>
      ) : (
        <div className="calculation-settings quote-detail">Loading...</div>
      )}
    </>
  );
}
