import React, { useState, useEffect, useReducer } from "react";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Card,
  FormControl,
} from "react-bootstrap";
import Select from "react-select";
import { NavLink, useHistory } from "react-router-dom";
import {
  getBillingInfo,
  updateBillingInfo,
  getCitiesAttempt,
  getStatesAttempt,
} from "./data/globalAction";
import SimpleReactValidator from "simple-react-validator";
import InputMask from "react-input-mask";
import * as Constants from "../core/Constants";
import { toast } from "react-toastify";
import { billing_info } from "../core/Service";
import AfterLogin from "./middleware/AfterLogin";
import { SettingsPhone } from "@material-ui/icons";

function BillingInformation() {
  const history = useHistory();
  const [changeState, setChangeState] = useState(false);
  const [changeCity, setChangeCity] = useState(false);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [validator] = useState(new SimpleReactValidator());
  const [forceShow, forceUpdate] = useState();
  const [phone, setPhone] = useState("");

  const [billInfo, setbillInfo] = useState([]);
  const [disabled, setDisabled] = useState(false);
  let statesOptions = [];
  let stateDisabe = true;

  if (states && states.length !== 0) {
    {
      states &&
        states.map((state) =>
          statesOptions.push({ label: state.name, value: state.id })
        );
    }
    stateDisabe = false;
  }
  let citiesOptions = [];
  let citiesDisabe = true;
  if (cities && cities.length !== 0) {
    {
      cities &&
        cities.map((city) =>
          citiesOptions.push({ label: city.name, value: city.id })
        );
    }
    citiesDisabe = false;
  }
  const handleSelect = async (info, selectName) => {
    setFormInput({ [selectName]: info });
    if (selectName === "state") {
      setFormInput({ ["city"]: "" });
      setCities(await getCitiesAttempt(info.value));
    }
  };
  const [formInput, setFormInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      city: "",
      line1: "",
      line2: "",
      postal_code: "",
      state: "",
      email: "",
      phone: "",
      name: "",
    }
  );

  useEffect(() => {
    async function fetchBillSettings() {
      setbillInfo(await getBillingInfo());
    }
    fetchBillSettings();
    async function fetchStates() {
      setStates(await getStatesAttempt(Constants.default_county.value));
    }
    fetchStates();
  }, []);

  useEffect(() => {
    if (
      billInfo &&
      billInfo.billing_information &&
      billInfo.billing_information.address
    ) {
      validator.hideMessages();
      forceUpdate(0);

      if (billInfo.billing_information.address.state === null)
        setFormInput({ ["state"]: "" });
      else {
        setFormInput({
          ["state"]: billInfo.billing_information.address.state,
        });
        setChangeState(!changeState);
      }

      if (billInfo.billing_information.address.line1 === null)
        setFormInput({ ["line1"]: "" });
      else
        setFormInput({
          ["line1"]: billInfo.billing_information.address.line1,
        });

      if (billInfo.billing_information.address.line2 === null)
        setFormInput({ ["line2"]: "" });
      else
        setFormInput({
          ["line2"]: billInfo.billing_information.address.line2,
        });

      if (billInfo.billing_information.address.postal_code === null)
        setFormInput({ ["postal_code"]: "" });
      else
        setFormInput({
          ["postal_code"]: billInfo.billing_information.address.postal_code,
        });

      if (billInfo.billing_information.email === null)
        setFormInput({ ["email"]: "" });
      else
        setFormInput({
          ["email"]: billInfo.billing_information.email,
        });

      if (billInfo.billing_information.name === null)
        setFormInput({ ["name"]: "" });
      else
        setFormInput({
          ["name"]: billInfo.billing_information.name,
        });

      if (billInfo.billing_information.phone === null)
        setFormInput({ ["phone"]: "" });
      else
        setFormInput({
          ["phone"]: billInfo.billing_information.phone,
        });

      if (billInfo.billing_information.address.city === null)
        setFormInput({ ["city"]: "" });
      else {
        setFormInput({ ["city"]: billInfo.billing_information.address.city });

        setChangeCity(!changeCity);
      }
    }

    // console.log(billInfo.billing_information.address.city);
  }, [billInfo]);

  useEffect(() => {
    let st = statesOptions.find(
      ({ label }) => label === billInfo.billing_information.address.state
    );
    console.log("st", st);
    setFormInput({
      ["state"]: st,
    });
    if (st && st.value) {
      async function fetchCities() {
        setCities(await getCitiesAttempt(st.value));
        setChangeCity(!changeCity);
      }
      fetchCities();
    }
  }, [changeState]);

  useEffect(() => {
    setFormInput({
      ["city"]: citiesOptions.find(
        ({ label }) => label === billInfo.billing_information.address.city
      ),
    });
    console.log("formInput.city", citiesOptions, formInput.city);
  }, [changeCity]);

  const handleInputPhone = (evt) => {
    const name = evt.target.name;
    const newValue = evt.target.value;
    setFormInput({ [name]: newValue });
    setPhone(
      newValue
        .replaceAll("(", "")
        .replaceAll(")", "")
        .replaceAll("_", "")
        .replaceAll("-", "")
        .replaceAll(" ", "")
    );
  };
  const handleInput = (evt) => {
    const name = evt.target.name;
    const newValue = evt.target.value;
    setFormInput({ [name]: newValue });
  };

  const handleUpdateBillingAddress = async () => {
    setDisabled(true);
    var valid = 1;

    if (
      !validator.fieldValid("city") ||
      !validator.fieldValid("line1") ||
      !validator.fieldValid("postal_code") ||
      !validator.fieldValid("email") ||
      !validator.fieldValid("phone") ||
      !validator.fieldValid("name") ||
      !validator.fieldValid("state")
    ) {
      valid = 0;
    }
    if (!valid) {
      validator.showMessages();
      forceUpdate(1);
      setDisabled(false);
    } else {
      let domain = (window.location.origin).replace(/^[^.]+\./g, "");
      let data = {
        country: Constants.default_county.value,
        state: formInput.state.value,
        city: formInput.city.value,
        line1: formInput.line1,
        line2: formInput.line2,
        email: formInput.email,
        phone: formInput.phone,
        name: formInput.name,
        postal_code: formInput.postal_code,
        link: `http://${domain}/support`
      };
      let result = await updateBillingInfo(JSON.stringify(data));
      console.log("result", result);
      if (result.status === 200) {
        toast.success(Constants.update_message);
        history.push("/settings/billing-payment");
      }
    }
    setDisabled(false);
  };
  const customControlStyles = {
    control: (base, state) => ({
      ...base,
      minHeight: 50,
      marginBottom: "15px",
      background: state.isFocused ? "#fff" : "#fff",
      borderWidth: 1,
      borderRadius: "8px",
      borderColor: state.isFocused ? "#7848FF" : "#E5E8F5",
      boxShadow: state.isFocused ? null : null,
      fontSize: "14px",
      "&:hover": {
        borderColor: state.isFocused ? "#7848FF" : null,
      },
    }),
    dropdownIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
      marginRight: "5px",
    }),
    menu: (base) => ({
      ...base,
      fontSize: "13px",
      border: "1px solid #f1f3f6",
      boxShadow: "none",
    }),
    option: (base, state) => ({
      ...base,
      background: state.isSelected ? "#f1f3f6" : "#fff",
      color: "#151B26",
      "&:hover": {
        background: "#f1f3f6",
        color: "#151B26",
      },
    }),
    placeholder: (base) => ({
      ...base,
      color: "#A8B9CD",
    }),
    clearIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
  };

  return (
    <div className="billing-information-page">
      <Container>
        <Row className="justify-content-md-center">
          <Col lg={6}>
            <h2 className="title">Billing information</h2>
            <Card className="mb-4">
              <Card.Body className="p-4">
                <Form.Group>
                  <Form.Label>Email address</Form.Label>
                  <FormControl
                    placeholder="my@email.com"
                    name="email"
                    value={formInput.email}
                    onChange={handleInput}
                  ></FormControl>
                  {validator.message(
                    "email",
                    formInput.email,
                    "required|email"
                  )}
                </Form.Group>
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <FormControl
                    placeholder="Allen Iverson"
                    name="name"
                    value={formInput.name}
                    onChange={handleInput}
                  ></FormControl>
                  {validator.message("name", formInput.name, "required")}
                </Form.Group>
                <Form.Group>
                  <Form.Label>Phone</Form.Label>
                  <InputMask
                    className="form-control"
                    placeholder="+1 (999) 999-99-99"
                    name="phone"
                    value={formInput.phone}
                    onChange={handleInputPhone}
                    mask="+1 (999) 999-99-99"
                  />
                  {validator.message("phone", phone, "required|size:12")}
                </Form.Group>
                <Form.Group>
                  <Form.Label>Address</Form.Label>

                  <FormControl
                    className="mb-3"
                    placeholder="Address line 1"
                    name="line1"
                    value={formInput.line1}
                    onChange={handleInput}
                  ></FormControl>
                  {validator.message("line1", formInput.line1, "required")}
                  <FormControl
                    className="mb-3"
                    placeholder="Address line 2"
                    name="line2"
                    value={formInput.line2}
                    onChange={handleInput}
                  ></FormControl>
                  <Select
                    name="state"
                    placeholder="Select State"
                    onChange={(value) => {
                      handleSelect(value, "state");
                    }}
                    value={formInput.state}
                    options={statesOptions}
                    isDisabled={stateDisabe}
                    styles={customControlStyles}
                  />
                  {validator.message("state", formInput.state, "required")}

                  <Select
                    name="city"
                    placeholder="Apple Valley"
                    onChange={(value) => {
                      handleSelect(value, "city");
                    }}
                    value={formInput.city}
                    options={citiesOptions}
                    isDisabled={citiesDisabe}
                    styles={customControlStyles}
                  />
                  {validator.message("city", formInput.city, "required")}

                  <FormControl
                    className="mb-3"
                    placeholder="Postal Code"
                    name="postal_code"
                    value={formInput.postal_code}
                    onChange={handleInput}
                  ></FormControl>
                  {validator.message(
                    "postal_code",
                    formInput.postal_code,
                    "required"
                  )}
                </Form.Group>
                <div className="card-bottom">
                  <Button
                    variant="primary"
                    block
                    className="mb-3"
                    disabled={disabled}
                    onClick={() => handleUpdateBillingAddress()}
                  >
                    Save
                  </Button>
                  <NavLink
                    to="/settings/billing-payment"
                    className="btn btn-light btn-block"
                  >
                    Back
                  </NavLink>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <AfterLogin />
    </div>
  );
}

export default BillingInformation;
