import React, { useReducer, useState, useEffect } from "react";
import {
  Row,
  Col,
  Container,
  Card,
  Button,
  Modal,
  Form,
  FormControl,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import BadgeIcon from "../component/assets/images/icons/badge.svg";
import CalculatorIcon from "../component/assets/images/icons/calculator.svg";
import { connect } from "react-redux";
import * as Constants from "../core/Constants";
import {
  getSettings,
  getNotifications,
  readNotification,
} from "./data/globalAction";
import SimpleReactValidator from "simple-react-validator";
import UserImage from "./assets/images/user.png";
import { toast } from "react-toastify";
import Payment from "./Payment";
import moment from "moment";
import AfterLogin from "./middleware/AfterLogin";
import { isAdmin, isManager, isOwner } from "../core/UserRoleCheck";

const customTableStyles = {
  header: {
    style: {
      padding: "0",
      minHeight: "inherit",
    },
  },
  subHeader: {
    style: {
      padding: "0",
      justifyContent: "flex-start",
      display: "block",
      border: "1px solid #E5E8F5",
      borderBottom: "none",
      borderRadius: "6px 6px 0 0",
    },
  },
  tableWrapper: {
    style: {
      border: "1px solid #E5E8F5",
    },
  },
  headRow: {
    style: {
      display: "none",
      color: "#43536D",
      borderColor: "#E5E8F5",
      borderStyle: "solid",
      borderWidth: "1px",
      borderLeftWidth: "0",
      backgroundColor: "#FCFCFF",
    },
  },
  headCells: {
    style: {
      fontSize: "13px",
      fontWeight: "normal",
      color: "#767778",
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "#fff",
      },
    },
  },
  rows: {
    style: {
      minHeight: "65px",
      "&:not(:last-of-type)": {
        borderBottomStyle: "solid",
        borderBottomWidth: "1px",
        borderBottomColor: "#E5E8F5",
      },
    },
  },
  cells: {
    style: {
      minHeight: "65px",
      flex: "0 0 15% !important",
      "&:first-child": {
        flex: "0 0 70% !important"
      },
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "#fff",
      },
    },
  },
  pagination: {
    style: {
      border: "1px solid #E5E8F5",
      borderTop: "none",
      borderRadius: "0 0 6px 6px",
    },
  },
};

const customControlStyles = {
  control: (base, state) => ({
    ...base,
    minHeight: 50,
    marginBottom: "15px",
    background: state.isFocused ? "#fff" : "#fff",
    borderWidth: 1,
    borderRadius: "8px",
    borderColor: state.isFocused ? "#7848FF" : "#E5E8F5",
    boxShadow: state.isFocused ? null : null,
    fontSize: "14px",
    "&:hover": {
      borderColor: state.isFocused ? "#7848FF" : null,
    },
  }),
  dropdownIndicator: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
    marginRight: "5px",
  }),
  menu: (base) => ({
    ...base,
    fontSize: "13px",
    border: "1px solid #f1f3f6",
    boxShadow: "none",
  }),
  option: (base, state) => ({
    ...base,
    background: state.isSelected ? "#f1f3f6" : "#fff",
    color: "#151B26",
    "&:hover": {
      background: "#f1f3f6",
      color: "#151B26",
    },
  }),
  placeholder: (base) => ({
    ...base,
    color: "#A8B9CD",
  }),
  clearIndicator: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
};

function NotificationsPage(props) {
  let history = useHistory();
  const [settings, setSettings] = useState([]);
  const [isPaymentShowing, setIsPaymentShowing] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const handleNotifications = async () => {
    let result = await getNotifications();
    if (result.status === 200) {
      setNotifications(result.data.notification);
    }
  };
  const handleNotifClick = async (link, id) => {
    let result = await readNotification({ id: id });
    if (result.status === 200) {
      // window.open(link).focus();
      history.push(link);
      handleNotifications();
    }
  };
  useEffect(() => {
    handleNotifications();

    async function fetchSettings() {
      setSettings(await getSettings());
    }
    fetchSettings();
  }, []);

  const handleClosePayment = () => {
    localStorage.removeItem("expire");
    setIsPaymentShowing(false);
  };

  useEffect(() => {
    if (settings.expire && settings.expire === true) {
      setIsPaymentShowing(true);
    }
  }, [settings]);

  const columns = [
    
    {
      name: "Message:",
      selector: "message",
      sortable: true,
    },
    {
      name: "Date:",
      selector: "created_at",
      sortable: false,
      cell: (row) => moment(row.created_at).fromNow(),
    },
    {
      name: "",
      selector: "link",
      cell: (row) => (
        <div className="q-status-section">
          
          <Button className="btn-icon" variant="outline-light" onClick={() => handleNotifClick(row.link, row.id)}>
            View
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="notification-table mt-4 mb-4">
      <Container>
        <DataTable
          customStyles={customTableStyles}
          responsive
          fixedHeader={true}
          columns={columns}
          data={notifications}
          // subHeader
          // onRowClicked={handleTeamComponent}
          // subHeaderComponent={subHeaderComponentMemo}
          pagination
          persistTableHead
        />
      </Container>
      {isPaymentShowing ? (
        <Payment onClose={handleClosePayment} isShowing={isPaymentShowing} />
      ) : null}
    </div>
  );
}

export default NotificationsPage;
