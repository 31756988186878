import React, { useReducer, useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  FormControl,
  Card,
  Button,
  Form,
  Alert,
} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import * as Constants from "../core/Constants";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import "./assets/css/signup.css";
import { IoCheckmarkOutline } from "react-icons/io5";
import {
  signUpAttempt,
  signUpInvitationAttempt,
  signUpAuthInvitationAttempt,
  signUpError,
  signUpMessage,
  companyAttempt,
  teamAttempt,
  teamAttemptResponse,
  signUpAttemptByUUID,
  signUpAttemptResend,
  signUpAttemptResendResponse,
} from "./data/authAction";
import {
  getRolesAttempt,
  getStatesAttempt,
  getCitiesAttempt,
} from "./data/globalAction";
import { signOutAttempt } from "./data/loginAction";

import queryString from "query-string";
import SimpleReactValidator from "simple-react-validator";

import Select from "react-select";
import { CheckoutForm } from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import InputAdornment from "@material-ui/core/InputAdornment";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";

import UserIcon from "./assets/images/icons/user.svg";
import EnterpriseIcon from "./assets/images/icons/enterprise.svg";
import EmailSentIcon from "./assets/images/icons/email-sent.svg";
import Logo from "./assets/images/logo.svg";
import { HiArrowNarrowRight, HiOutlinePlusSm } from "react-icons/hi";
import { RiFacebookFill, RiLinkedinFill, RiCloseLine } from "react-icons/ri";
import { FcGoogle } from "react-icons/fc";
import LoginSlider from "./layout/LoginSlider";

const customControlStyles = {
  control: (base, state) => ({
    ...base,
    minHeight: 50,
    marginBottom: "15px",
    background: state.isFocused ? "#fff" : "#fff",
    borderWidth: 1,
    borderRadius: "8px",
    borderColor: state.isFocused ? "#7848FF" : "#E5E8F5",
    boxShadow: state.isFocused ? null : null,
    fontSize: "14px",
    "&:hover": {
      borderColor: state.isFocused ? "#7848FF" : null,
    },
  }),
  dropdownIndicator: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
    marginRight: "5px",
  }),
  menu: (base) => ({
    ...base,
    fontSize: "13px",
    border: "1px solid #f1f3f6",
    boxShadow: "none",
  }),
  option: (base, state) => ({
    ...base,
    background: state.isSelected ? "#f1f3f6" : "#fff",
    color: "#151B26",
    "&:hover": {
      background: "#f1f3f6",
      color: "#151B26",
    },
  }),
  placeholder: (base) => ({
    ...base,
    color: "#A8B9CD",
  }),
  clearIndicator: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
};

function SignUp(props) {
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [roles, setRoles] = useState([]);
  const [suggestion, setSuggestion] = useState([Math.floor(Math.random() * 100), Math.floor(Math.random() * 222), Math.floor(Math.random() * 212)]);
  const [loader, setLoader] = useState(false);
  const [validator] = useState(new SimpleReactValidator());
  const [disabled, setDisabled] = useState(false);
  const [facebook, setFacebook] = useState(false);
  const [linkedin, setLinkedin] = useState(false);
  const [google, setGoogle] = useState(false);
  let params = queryString.parse(window.location.search);

  let rolesOptions = [];
  {
    roles &&
      roles.map((role) =>
        rolesOptions.push({ label: role.name, value: role.id })
      );
  }

  const [inputList, setInputList] = useState([
    { email: "", role: Constants.default_role },
  ]);

  const [step, setStep] = useState("");
  useEffect(() => {
    if (params.error) {
      toast.error(params.error);
      history.replace({
        search: "",
      });
    }
    console.log("props.invitation", props.invitation);

    if (
      props.user &&
      props.user.data &&
      props.user.data.organization_users.length !== 0 &&
      props.user.data.organization_users[0].organization
    ) {
      toast.success(Constants.signup_complete_message);
      document.querySelector("html").classList.remove("signup-page");
      history.push("/signin");
    }
    if (
      params.invitation_token &&
      props.authInvitation &&
      props.authInvitation.success
    ) {
      toast.success(Constants.signup_complete_message);
      document.querySelector("html").classList.remove("signup-page");
      history.push("/signin");
    }
    if (
      params.invitation_token &&
      props.invitation &&
      props.invitation.length !== 0 &&
      step === ""
    ) {
      console.log("invitation", props.invitation.data);
      formInput.email = props.invitation.data.email;
      formInput.invitation_token = params.invitation_token;
      setStep("email");
    }
    if (params.id && formInput.user_id === "") {
      let data = { uuid: params.id };
      async function fetchStates() {
        setStates(await getStatesAttempt(Constants.default_county.value));
      }
      fetchStates();
      props.signUpAttemptByUUID(JSON.stringify(data));
      setFormInput({ ["user_id"]: params.id });
      if (
        props.company &&
        props.company.length !== 0 &&
        props.company.data &&
        props.company.data.user
      ) {
        isEnterprise();
      } else {
        setStep("company");
      }
    }
  });
  useEffect(() => {
    if (params.invitation_token) {
      let data = { invitation_token: params.invitation_token };

      props.signUpInvitationAttempt(JSON.stringify(data));
      console.log("invitation", props.invitation);
    }
    if (props.login && props.login.data && props.login.data.data) {
      history.push("/dashboard");
    }
  }, []);

  useEffect(() => {
    if (
      props.company &&
      props.company.length !== 0 &&
      props.company.data &&
      props.company.data.user &&
      step === "url"
    ) {
      console.log("props.company.data", props.company.data.user);

      isEnterprise();
    }
  }, [props.company]);

  const [, forceUpdate] = useState();
  useEffect(() => {
    if (props.auth && props.auth.data && formInput.user_id === "") {
      history.push("/signup?id=" + props.auth.data.uuid);

      if (
        props.company &&
        props.company.length !== 0 &&
        props.company.data &&
        props.company.data.user
      ) {
        isEnterprise();
      } else {
        async function fetchStates() {
          setStates(await getStatesAttempt(Constants.default_county.value));
        }
        fetchStates();
        setStep("confirm");
      }
    }
    if (disabled && props.error.length > 0) setDisabled(false);
    if (
      disabled &&
      props.error &&
      props.error.full_messages &&
      props.error.full_messages.length > 0
    )
      setDisabled(false);
    if (
      props.authInvitation &&
      props.authInvitation.length !== 0 &&
      props.authInvitation.data
    ) {
      toast.success(Constants.signup_complete_message);
      history.push("/signin");
    }

    if (
      props.team &&
      props.team.length !== 0 &&
      props.team.status &&
      props.team.status === "success"
    ) {
      props.teamAttemptResponse([]);
      toast.success(Constants.signup_complete_message);
      history.push("/signin");
    }

    if (props.resend && props.resend.length !== 0 && props.resend.success) {
      toast.success(props.resend.message);
      props.signUpAttemptResendResponse([]);
    }
  });

  const history = useHistory();
  const [formInput, setFormInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      password_confirmation: "",
      is_enterprise: false,
      user_id: "",
      business_domain: "",
      company_name: "",
      country: Constants.default_county.value,
      state: "",
      city: "",
      address: "",
      employees: "",
      invitation_token: "",
    }
  );

  const isEnterprise = async () => {
    if (props.company.data.user.is_enterprise === "1") {
      setRoles(await getRolesAttempt());
      setDisabled(false);
      setStep("team");
    } else {
      toast.success(Constants.signup_complete_message);
      console.log('herrre', props.company.data.user.provider)
      if (props.company.data.user.provider === "email") {
        history.push("/signin");

      }
      else {
        history.push("/signin?id=" + props.company.data.user.uuid);

      }
    }
  };
  const handleSubmit = (evt) => {
    if (typeof evt !== "undefined" && evt != null) {
      evt.preventDefault();
    }
    props.signUpError([]);

    if (!validator.allValid()) {
      validator.showMessages();
      forceUpdate(1);
      setDisabled(false);
    } else {
      setDisabled(true);
      let data = { formInput };
      if (params.invitation_token) {
        data = {
          first_name: formInput.first_name,
          last_name: formInput.last_name,
          password: formInput.password,
          password_confirmation: formInput.password_confirmation,
          invitation_token: formInput.invitation_token,
        };
        props.signUpAuthInvitationAttempt(JSON.stringify(data));
        console.log("data", data);
        setDisabled(false);
      } else {
        props.signUpAttempt(JSON.stringify(data.formInput));
      }
    }

    if (disabled && props.error.length > 0) setDisabled(false);
  };

  const handleCompanySubmit = (evt) => {
    if (typeof evt !== "undefined" && evt != null) {
      evt.preventDefault();
    }
    props.signUpError([]);

    if (!validator.allValid()) {
      validator.showMessages();
      forceUpdate(1);
      setDisabled(false);
    } else {
      setDisabled(true);
      let data = { formInput };
      props.companyAttempt(JSON.stringify(data.formInput));
    }

    if (disabled && props.error.length > 0) setDisabled(false);
  };

  const handleInput = (evt) => {
    const name = evt.target.name;
    const newValue = evt.target.value;
    setFormInput({ [name]: newValue });
  };
  const handleSelect = async (info, selectName) => {
    const name = selectName;
    const newValue = info.value;
    setFormInput({ [name]: newValue });
    if (selectName === "state") {
      setCities(await getCitiesAttempt(newValue));
    }
  };

  const selectAccountType = async (evt, index) => {
    props.signUpError([]);
    validator.hideMessages();

    if (index === "resend") {
      let data = { email: props.user.data.email };
      props.signUpAttemptResend(JSON.stringify(data));
    } else if (index === "enterprise") {
      setFormInput({ ["is_enterprise"]: true });
      setStep(index);
    } else if (index === "google") {
      setGoogle(true);
      console.log("formInput.is_enterprise", formInput.is_enterprise);
      window.location.href =
        process.env.REACT_APP_API_URL +
        "/auth/google_oauth2?is_enterprise=" +
        formInput.is_enterprise;
    } else if (index === "facebook") {
      setFacebook(true);
      window.location.href =
        process.env.REACT_APP_API_URL +
        "/auth/facebook?is_enterprise=" +
        formInput.is_enterprise;
    } else if (index === "linkedin") {
      setLinkedin(true);
      window.location.href =
        process.env.REACT_APP_API_URL +
        "/auth/linkedin?is_enterprise=" +
        formInput.is_enterprise;
    } else if (index === "addTeam") {
      setDisabled(true);
      let data = { data: inputList, user_id: formInput.user_id };
      props.teamAttempt(JSON.stringify(data));

      console.log("addTeam", data);
    } else if (!validator.allValid()) {
      validator.showMessages();
      forceUpdate(1);
    } else {
      setStep(index);
      setDisabled(false);
    }
  };

  let ErrorlistItems = "";
  if (props.error) {
    console.log("props.error", props.error);
    let errors = props.error;
    if (errors.full_messages) errors = errors.full_messages;
    ErrorlistItems = errors.map((err) => <Alert variant="danger">{err}</Alert>);
  }

  let statesOptions = [];
  let stateDisabe = true;

  if (states && states.length !== 0) {
    {
      states &&
        states.map((state) =>
          statesOptions.push({ label: state.name, value: state.id })
        );
    }
    stateDisabe = false;
  }
  let citiesOptions = [];
  let citiesDisabe = true;
  if (cities && cities.length !== 0) {
    {
      cities &&
        cities.map((city) =>
          citiesOptions.push({ label: city.name, value: city.id })
        );
    }
    citiesDisabe = false;
  }

  // handle input change
  const handleInputChange = (e, index, opt = "") => {
    let name, value;
    if (opt !== "") {
      name = opt;
      value = e;
    } else {
      name = e.target.name;
      value = e.target.value;
    }

    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { email: "", role: rolesOptions[0] }]);
  };
  function getStepContent() {
    switch (step) {
      case "team":
        document.querySelector("html").classList.add("signup-page");
        return (
          <div className="add-teammates">
            <Container fluid>
              <Row>
                <Col lg={6}>
                  <div className="login-holder">
                    <div className="logo">
                      <img
                        src={Logo}
                        alt="ReCalc Logo"
                        onClick={() => {
                          history.push("/");
                        }}
                      />
                    </div>
                    <h2 className="title">Add Teammates</h2>
                    {props.error ? <div>{ErrorlistItems}</div> : null}
                    <Form.Label>Email address</Form.Label>
                    {inputList.map((x, i) => {
                      return (
                        <Row>
                          <Col className="pr-0 col-left">
                            <Form.Group>
                              <FormControl
                                id="email"
                                name="email"
                                placeholder="member@gmail.com"
                                value={x.email}
                                onChange={(e) => handleInputChange(e, i)}
                              />
                            </Form.Group>
                          </Col>
                          <Col className="col-right">
                            <Select
                              options={rolesOptions}
                              styles={customControlStyles}
                              placeholder="Manager"
                              value={x.role}
                              name="role"
                              onChange={(e) => {
                                handleInputChange(e, i, "role");
                              }}
                            />
                          </Col>
                          {i !== 0 ? (
                            <Col className="pl-0">
                              <Button
                                className="btn-icon"
                                variant="outline-dark"
                                onClick={() => handleRemoveClick(i)}
                              >
                                <RiCloseLine />
                              </Button>
                            </Col>
                          ) : null}
                        </Row>
                      );
                    })}
                    <Row>
                      <Col lg={12}>
                        <Button
                          block
                          variant="light"
                          className="mb-3"
                          onClick={handleAddClick}
                        >
                          <HiOutlinePlusSm /> Add more teammates
                        </Button>
                      </Col>
                    </Row>
                    <Button
                      type="submit"
                      block
                      variant="primary"
                      disabled={disabled}
                      onClick={(e) => selectAccountType(e, "addTeam")}
                    >
                      Get Started
                    </Button>
                  </div>
                </Col>
                <Col lg={6}>
                  <LoginSlider />
                </Col>
              </Row>
            </Container>
          </div>
        );
      case "url":
        document.querySelector("html").classList.add("signup-page");
        return (
          <div className="domain-selection">
            <Container fluid>
              <Row>
                <Col lg={6}>
                  <form
                    onSubmit={handleCompanySubmit}
                    noValidate
                    className="form h-100"
                  >
                    <div className="login-holder">
                      <div className="logo">
                        <img
                          src={Logo}
                          alt="ReCalc Logo"
                          onClick={() => {
                            history.push("/");
                          }}
                        />
                      </div>

                      <div className="d-flex ">
                        <div
                          className={`bg-active d-flex justify-content-center align-items-center steps-div`}
                        >
                          <IoCheckmarkOutline className="mr-2 bg-step-icon" />
                          <p className="mb-0">Personal Information</p>
                        </div>
                        <div
                          className={`bg-active d-flex justify-content-center align-items-center steps-div`}
                        >
                          <IoCheckmarkOutline className="mx-2 bg-step-icon" />
                          <p className="mb-0">Company Info</p>
                        </div>

                        <div
                          className={`bg-active d-flex justify-content-center align-items-center steps-div`}
                        >
                          <IoCheckmarkOutline className="mx-2 bg-step-icon" />
                          <p className="mb-0">Domain</p>
                        </div>
                      </div>
                      <h2 className="title">Give us your business domain</h2>
                      <p className="mb-4">
                        Make it something clear. <br /> For example, the name of
                        your team or company
                      </p>
                      {props.error ? <div>{ErrorlistItems}</div> : null}
                      <Form.Group>
                        <Form.Label>Business Domain</Form.Label>
                        <div className="form-holder">
                          <FormControl
                            required
                            id="business_domain"
                            name="business_domain"
                            value={formInput.business_domain}
                            onChange={handleInput}
                          />
                          <small>
                            .{""}
                            {process.env.REACT_APP_URL.replace(
                              /(^\w+:|^)\/\//,
                              ""
                            )}
                          </small>
                        </div>
                        {validator.message(
                          "business_domain",
                          formInput.business_domain,
                          "required|alpha_num_dash",
                          {
                            className: "text-danger",
                          }
                        )}

                        <div className="">
                          <p className="btn" onClick={() => setFormInput({ ["business_domain"]: formInput.company_name.replace(/\s+/g, '') + suggestion[0] })}>
                            {formInput.company_name.replace(/\s+/g, '') + suggestion[0]}
                          </p>
                          <p className="btn" onClick={() => setFormInput({ ["business_domain"]: formInput.company_name.replace(/\s+/g, '') + suggestion[1] })}>
                            {formInput.company_name.replace(/\s+/g, '') + suggestion[1]}
                          </p>
                          <p className="btn" onClick={() => setFormInput({ ["business_domain"]: formInput.company_name.replace(/\s+/g, '') + suggestion[2] })}>
                            {formInput.company_name.replace(/\s+/g, '') + suggestion[2]}
                          </p>
                        </div>

                      </Form.Group>
                      <Button
                        type="submit"
                        block
                        variant="primary"
                        disabled={disabled}
                      >
                        Continue
                      </Button>
                    </div>
                  </form>
                </Col>
                <Col lg={6}>
                  <LoginSlider />
                </Col>
              </Row>
            </Container>
          </div>
        );
      case "company":
        document.querySelector("html").classList.add("signup-page");
        return (
          <div className="company-detail-form">
            <Container fluid>
              <Row className="form">
                <Col lg={6}>
                  <div className="login-holder">
                    <div className="logo">
                      <img
                        src={Logo}
                        alt="ReCalc Logo"
                        onClick={() => {
                          history.push("/");
                        }}
                      />
                    </div>

                    <div className="d-flex ">
                      <div
                        className={`bg-active d-flex justify-content-center align-items-center steps-div`}
                      >
                        <IoCheckmarkOutline className="mr-2 bg-step-icon" />
                        <p className="mb-0">Personal Information</p>
                      </div>
                      <div
                        className={`bg-active d-flex justify-content-center align-items-center steps-div`}
                      >
                        <IoCheckmarkOutline className="mx-2 bg-step-icon" />
                        <p className="mb-0">Company Info</p>
                      </div>

                      <div
                        className={`d-flex justify-content-center align-items-center steps-div`}
                      >
                        <IoCheckmarkOutline className="mx-2 bg-step-icon" />
                        <p className="mb-0">Domain</p>
                      </div>
                    </div>
                    {props.error ? <div>{ErrorlistItems}</div> : null}
                    <h2 className="title">Company Info</h2>
                    {props.user && props.user.data ? (
                      <div className="personal-info">
                        <div className="name-spell">
                          {props.user.data.first_name.charAt(0).toUpperCase()}
                          {props.user.data.last_name.charAt(0).toUpperCase()}
                        </div>
                        <div className="details">
                          <strong>
                            {props.user.data.first_name}{" "}
                            {props.user.data.last_name}
                          </strong>
                          <p> {props.user.data.email}</p>
                        </div>
                      </div>
                    ) : null}
                    <Form.Group>
                      <Form.Label>Company Name</Form.Label>
                      <FormControl
                        required
                        id="company_name"
                        value={formInput.company_name}
                        name="company_name"
                        placeholder="Ex: ReCalc etc"
                        onChange={handleInput}
                      />
                      {validator.message(
                        "company_name",
                        formInput.company_name,
                        "required",
                        { className: "text-danger" }
                      )}
                    </Form.Group>
                    <Row>
                      <Col lg={`${formInput.state !== "" ? 6 : 12}`}>
                        <Form.Group>
                          <Form.Label>State (optional)</Form.Label>
                          <Select
                            name="state"
                            placeholder="Select State"
                            onChange={(value) => {
                              handleSelect(value, "state");
                            }}
                            options={statesOptions}
                            isDisabled={stateDisabe}
                            styles={customControlStyles}
                          />
                        </Form.Group>
                      </Col>
                      {formInput.state !== "" ? (
                        <Col lg={6}>
                          <Form.Group>
                            <Form.Label>City (optional)</Form.Label>
                            <Select
                              name="city"
                              placeholder="Apple Valley"
                              onChange={(value) => {
                                handleSelect(value, "city");
                              }}
                              options={citiesOptions}
                              isDisabled={citiesDisabe}
                              styles={customControlStyles}
                            />
                          </Form.Group>
                        </Col>
                      ) : null}
                      {formInput.state !== "" ? (
                        <Col lg={12}>
                          <Row>
                            <Col lg={8}>
                              <Form.Group>
                                <Form.Label>
                                  Company Address (optional)
                                </Form.Label>
                                <FormControl
                                  required
                                  value={formInput.address}
                                  name="address"
                                  type="text"
                                  placeholder="33 3rd Ave, New York, NY 10003, USA"
                                  id="address"
                                  onChange={handleInput}
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={4}>
                              <Form.Group>
                                <Form.Label>Zip Code (optional)</Form.Label>
                                <FormControl
                                  required
                                  value={formInput.zip_code}
                                  name="zip_code"
                                  placeholder="10017"
                                  type="text"
                                  id="zip_code"
                                  onChange={handleInput}
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        </Col>
                      ) : null}
                    </Row>
                    <Form.Group>
                      <Form.Label>No of employees (optional)</Form.Label>
                      <Select
                        name="employees"
                        menuPlacement="top"
                        placeholder=""
                        onChange={(value) => {
                          handleSelect(value, "employees");
                        }}
                        options={Constants.employees_options}
                        styles={customControlStyles}
                      />
                    </Form.Group>
                    <Button
                      block
                      className="mt-4"
                      variant="primary"
                      onClick={(e) => selectAccountType(e, "url")}
                    >
                      Continue
                    </Button>
                  </div>
                </Col>
                <Col lg={6}>
                  <LoginSlider />
                </Col>
              </Row>
            </Container>
          </div>
        );
      case "confirm":
        document.querySelector("html").classList.add("signup-page");
        return (
          <div className="confirm-email">
            <Container>
              <h2 className="title">
                Confirm your email to <br /> create a Company
              </h2>
              <div className="image-holder">
                <img src={EmailSentIcon} alt="Sent Email Icon" />
              </div>
              <small>
                We sent an email to <b>{formInput.email}. </b>
                Follow the link from the letter to confirm your mail. So we will
                be sure that you can recover, if anything.
              </small>
              <div className="d-flex justify-content-center mt-4">
                <Button
                  variant="primary"
                  onClick={(e) => selectAccountType(e, "resend")}
                >
                  Resend Email
                </Button>
                <Button
                  variant="light"
                  className="ml-3"
                  onClick={(e) => selectAccountType(e, "company")}
                >
                  Continue
                </Button>
              </div>
            </Container>
          </div>
        );

      case "email":
        document.querySelector("html").classList.add("signup-page");
        return (
          <Container fluid>
            <Row>
              <Col lg={6}>
                <div className="login-holder">
                  <div className="logo">
                    <img
                      src={Logo}
                      alt="ReCalc Logo"
                      onClick={() => {
                        history.push("/");
                      }}
                    />
                  </div>
                  <div className="sign-up">
                    <div className="d-flex ">
                      <div
                        className={`bg-active d-flex justify-content-center align-items-center steps-div`}
                      >
                        <IoCheckmarkOutline className="mr-2 bg-step-icon" />
                        <p className="mb-0">Personal Information</p>
                      </div>
                      <div
                        className={`d-flex justify-content-center align-items-center steps-div`}
                      >
                        <IoCheckmarkOutline className="mx-2 bg-step-icon" />
                        <p className="mb-0">Company Info</p>
                      </div>

                      <div
                        className={`d-flex justify-content-center align-items-center steps-div`}
                      >
                        <IoCheckmarkOutline className="mx-2 bg-step-icon" />
                        <p className="mb-0">Domain</p>
                      </div>
                    </div>
                    <form onSubmit={handleSubmit} noValidate className="form">
                      {props.error ? <div>{ErrorlistItems}</div> : null}
                      <h2 className="title">Tell us about yourself?</h2>
                      <Row>
                        <Col lg={6}>
                          <Form.Group>
                            <Form.Label>First Name</Form.Label>
                            <FormControl
                              name="first_name"
                              value={formInput.first_name}
                              required
                              id="first_name"
                              placeholder="Allen"
                              label="First Name"
                              autoComplete="first_name"
                              onChange={handleInput}
                            />
                            {validator.message(
                              "first_name",
                              formInput.first_name,
                              "required",
                              {
                                className: "text-danger",
                              }
                            )}
                          </Form.Group>
                        </Col>
                        <Col lg={6}>
                          <Form.Group>
                            <Form.Label>Last Name</Form.Label>
                            <FormControl
                              required
                              id="last_name"
                              label="Last Name"
                              name="last_name"
                              placeholder="Iverson"
                              value={formInput.last_name}
                              autoComplete="last_name"
                              onChange={handleInput}
                            />
                            {validator.message(
                              "last_name",
                              formInput.last_name,
                              "required",
                              {
                                className: "text-danger",
                              }
                            )}
                          </Form.Group>
                        </Col>
                        <Col lg={12}>
                          <Form.Group>
                            <Form.Label>Email Address</Form.Label>
                            <FormControl
                              required
                              id="email"
                              label="Email Address"
                              name="email"
                              placeholder="AllenIverson@gmail.com"
                              value={formInput.email}
                              autoComplete="email"
                              onChange={handleInput}
                            />
                            {validator.message(
                              "email",
                              formInput.email,
                              "required|email",
                              {
                                className: "text-danger",
                              }
                            )}
                          </Form.Group>
                        </Col>
                        <Col lg={12}>
                          <Form.Group>
                            <Form.Label>Create Password</Form.Label>
                            <FormControl
                              required
                              value={formInput.password}
                              name="password"
                              label="Password"
                              type="password"
                              placeholder="Min. 8 Character"
                              id="password"
                              autoComplete="password"
                              onChange={handleInput}
                            />
                            {validator.message(
                              "password",
                              formInput.password,
                              "required",
                              {
                                className: "text-danger",
                              }
                            )}
                          </Form.Group>
                        </Col>
                        <Col lg={12}>
                          <Form.Group>
                            <Form.Label>Confirm Password</Form.Label>
                            <FormControl
                              required
                              value={formInput.password_confirmation}
                              name="password_confirmation"
                              label="Confirm Password"
                              type="password"
                              placeholder="Min. 8 Character"
                              id="password_confirmation"
                              autoComplete="password_confirmation"
                              onChange={handleInput}
                            />
                            {validator.message(
                              "password_confirmation",
                              formInput.password_confirmation,
                              "required",
                              { className: "text-danger" }
                            )}
                          </Form.Group>
                        </Col>
                        <Col lg={12}>
                          <Button
                            type="submit"
                            block
                            variant="primary"
                            className="mt-3"
                            disabled={disabled}
                          >
                            Continue
                          </Button>
                        </Col>
                      </Row>
                      <div className="login-option">
                        Already have an account?{" "}
                        <NavLink to="/signin">Log In</NavLink>
                      </div>
                    </form>
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <LoginSlider />
              </Col>
            </Row>
          </Container>
        );
      case "single":
      // case "enterprise":
      default:
        document.querySelector("html").classList.add("signup-page");
        return (
          <div className="sign-up">
            <Container fluid>
              <Row>
                <Col lg={6}>
                  <div className="login-holder">
                    <div className="logo">
                      <img
                        src={Logo}
                        alt="ReCalc Logo"
                        onClick={() => {
                          history.push("/");
                        }}
                      />
                    </div>
                    <h2 className="title">Try ReCalc for free</h2>
                    <Button
                      variant="light"
                      block
                      disabled={google}
                      onClick={(e) => selectAccountType(e, "google")}
                    >
                      <FcGoogle /> Continue with Google
                    </Button>
                    <Button
                      variant="light"
                      className="btn-facebook"
                      block
                      disabled={facebook}
                      onClick={(e) => selectAccountType(e, "facebook")}
                    >
                      <RiFacebookFill /> Continue with Facebook
                    </Button>
                    <Button
                      variant="light"
                      className="btn-linkedin"
                      block
                      disabled={linkedin}
                      onClick={(e) => selectAccountType(e, "linkedin")}
                    >
                      <RiLinkedinFill /> Continue with Linkedin
                    </Button>
                    <div className="heading">
                      <span>Oldschool?</span>
                    </div>
                    <Button
                      variant="outline-dark"
                      className="m-0"
                      block
                      onClick={(e) => selectAccountType(e, "email")}
                    >
                      Continue with Email address
                    </Button>
                    <div className="login-option">
                      Already have an account?{" "}
                      <NavLink to="/signin">Log In</NavLink>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <LoginSlider />
                </Col>
              </Row>
            </Container>
          </div>
        );
      // default:
      //   document.querySelector("html").classList.remove("signup-page");
      //   return (
      //     <div className="sign-up">
      //       <Container>
      //         {props.error ? <div>{ErrorlistItems}</div> : null}
      //         <div className="main-section-text">
      //           <p>
      //             Want to create a new company <br /> for yourself or for an
      //             enterprise?
      //           </p>
      //         </div>
      //         <div className="main-options d-flex justify-content-center">
      //           <Card className="single-user">
      //             <div>
      //               <div className="image-holder">
      //                 <Card.Img variant="top" src={UserIcon} alt="Icon" />
      //               </div>
      //               <Card.Body>
      //                 <Card.Title>Single user</Card.Title>
      //                 <Card.Text>
      //                   Lorem ipsum dolor sit amet, consectetur adipiscing elit
      //                 </Card.Text>
      //               </Card.Body>
      //             </div>
      //             <Button
      //               variant="primary"
      //               block
      //               onClick={(e) => selectAccountType(e, "single")}
      //             >
      //               Create Company
      //             </Button>
      //           </Card>
      //           <Card className="ml-3">
      //             <div>
      //               <div className="image-holder">
      //                 <Card.Img variant="top" src={EnterpriseIcon} alt="Icon" />
      //               </div>
      //               <Card.Body>
      //                 <Card.Title>Enterprise Team</Card.Title>
      //                 <Card.Text>
      //                   Lorem ipsum dolor sit amet, consectetur adipiscing elit
      //                 </Card.Text>
      //               </Card.Body>
      //             </div>
      //             <Button
      //               variant="primary"
      //               block
      //               onClick={(e) => selectAccountType(e, "enterprise")}
      //             >
      //               Create Company
      //             </Button>
      //           </Card>
      //         </div>
      //         <div className="logo bottom">
      //           <img
      //             src={Logo}
      //             alt="ReCalc Logo"
      //             onClick={() => {
      //               history.push("/");
      //             }}
      //           />
      //         </div>
      //       </Container>
      //     </div>
      //   );
    }
  }
  let content = getStepContent();
  return <div className="content-div">{content}</div>;
}

const mapDispatchToProps = (dispatch) => {
  return {
    signUpAttempt: (data) => dispatch(signUpAttempt(data)),
    signOutAttempt: (data) => dispatch(signOutAttempt()),
    signUpInvitationAttempt: (data) => dispatch(signUpInvitationAttempt(data)),

    signUpAuthInvitationAttempt: (data) =>
      dispatch(signUpAuthInvitationAttempt(data)),
    signUpAttemptByUUID: (data) => dispatch(signUpAttemptByUUID(data)),
    signUpAttemptResend: (data) => dispatch(signUpAttemptResend(data)),
    signUpAttemptResendResponse: (data) =>
      dispatch(signUpAttemptResendResponse(data)),

    teamAttemptResponse: (data) => dispatch(teamAttemptResponse(data)),
    companyAttempt: (data) => dispatch(companyAttempt(data)),
    teamAttempt: (data) => dispatch(teamAttempt(data)),
    signUpError: (data) => dispatch(signUpError(data)),
    signUpMessage: (data) => dispatch(signUpMessage(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    error: state.auth.authError,
    message: state.auth.authMessage,
    auth: state.auth.auth,
    user: state.auth.user,
    invitation: state.auth.invitation,
    authInvitation: state.auth.authInvitation,
    company: state.auth.company,
    team: state.auth.team,
    resend: state.auth.resend,
    login: state.login.login,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
