import { combineReducers } from "redux";

const auth = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case "SIGNUP_ATTEMPT_RESPONSE":
      return payload;
    default:
      return state;
  }
};
const authError = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case "SIGNUP_SET_ERROR":
      return payload;
    default:
      return state;
  }
};
const authMessage = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case "SIGNUP_SET_MESSAGE":
      return payload;
    default:
      return [];
  }
};
const company = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case "COMPANY_ATTEMPT_RESPONSE":
      return payload;
    default:
      return state;
  }
};
const team = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case "TEAM_ATTEMPT_RESPONSE":
      return payload;
    default:
      return state;
  }
};
const invitation = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case "SIGNUP_INVITATION_ATTEMPT_RESPONSE":
      return payload;
    default:
      return state;
  }
};
const authInvitation = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case "SIGNUP_AUTH_INVITATION_ATTEMPT_RESPONSE":
      return payload;
    default:
      return state;
  }
};
const user = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case "SIGNUP_ATTEMPT_BY_UUID_RESPONSE":
      return payload;
    default:
      return state;
  }
};
const resend = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case "SIGNUP_ATTEMPT_RESEND_RESPONSE":
      return payload;
    default:
      return state;
  }
};
const authReducer = combineReducers({
  auth,
  authInvitation,
  company,
  invitation,
  team,
  authError,
  authMessage,
  user,
  resend,
});
export default authReducer;
